import React from "react";
import styles from "./styles/OrdersFiltersForm.module.scss";
import { Button, Collapse, Form, Icon } from "antd";
import { CheckBoxGroupe } from "../components/checkBoxGroupe/CheckBoxGroupe";
import { CountriesRadioButtons } from "../components/countriesRadioButtons/CountriesRadioButtons";
import { DatePickerF } from "../../../../components/forms/datePickerF/DatePickerF";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { Moment } from "moment";
import { resources } from "../../../../common/Resources";
import { Right } from "../../../../components/controls/right/Right";
import { TextInputF } from "../../../../components/forms/textInput/TextInputF";

interface IOrdersFiltersFormProps {
  loading: boolean;
  countriesD: Array<IDictionaryValue>;
  selectedCountry: number;
  couriersD: Array<IDictionaryValue>;
  couriers: Array<number>;
  salesChannelsD: Array<IDictionaryValue>;
  salesChannels: Array<number>;
  paymentMethodsD: Array<IDictionaryValue>;
  paymentMethods: number[];
  statusesD: Array<IDictionaryValue>;
  statuses: number[];
  productsD: Array<IDictionaryValue>;
  products: number[];
  optionsD: Array<IDictionaryValue>;
  options: number[];
  dateFrom: Moment;
  dateTo: Moment;
  reference: string;
  onChangeTextBox: (name: string, value: any) => void;
  onChangeDate: (name: string, value: any) => void;
  onChange: (event: any, value?: any) => void;
  onCountryChange: (value: number) => void;
  getOrdersList: () => void;
}

export class OrdersFiltersForm extends React.Component<IOrdersFiltersFormProps> {
  public render() {
    const { Panel } = Collapse;
    const {
      loading,
      countriesD,
      selectedCountry,
      couriersD,
      couriers,
      salesChannelsD,
      salesChannels,
      paymentMethodsD,
      paymentMethods,
      statusesD,
      statuses,
      productsD,
      products,
      optionsD,
      options,
      dateFrom,
      dateTo,
      reference,
      onChangeTextBox,
      onChangeDate,
      onChange,
      onCountryChange,
      getOrdersList,
    } = this.props;

    let productsForCountry = Array<IDictionaryValue>();
    let couriersForCountry = Array<IDictionaryValue>();
    if (selectedCountry) {
      productsForCountry = productsD.filter(
        (x) => x.country_id === selectedCountry
      );
      couriersForCountry = couriersD.filter(
        (x) => x.country_id === selectedCountry
      );
    }

    return (
      <div className={styles.OrdersFiltersForm} onSubmit={getOrdersList}>
        <Collapse
          bordered={true}
          defaultActiveKey={["0"]}
          expandIcon={() => <Icon type="search" />}
          className="marginBottom marginTop"
        >
          <Panel header={resources.title.filters} key="2">
            <Form className={styles.formStyle}>
              <section>
                {countriesD.length > 0 && (
                  <div className="marginBottom marginTop">
                    <div className={styles.checkBoxStyles}>Country</div>
                    <CountriesRadioButtons
                      loading={loading}
                      countriesD={countriesD}
                      onChange={onCountryChange}
                      defaultValue={selectedCountry}
                    />
                  </div>
                )}
                <div className="marginBottom marginTop">
                  <div className={styles.checkBoxStyles}>Couriers</div>
                  <CheckBoxGroupe
                    name="couriers"
                    loading={loading}
                    options={couriersForCountry.map((el: IDictionaryValue) => ({
                      name: el.name,
                      value: el.id,
                    }))}
                    onChange={onChangeTextBox}
                    value={couriers}
                  />
                </div>
                <div className="marginBottom marginTop">
                  <div className={styles.checkBoxStyles}>Options</div>
                  <CheckBoxGroupe
                    name="options"
                    loading={loading}
                    options={optionsD.map((el: IDictionaryValue) => ({
                      name: el.name,
                      value: el.id,
                    }))}
                    onChange={onChangeTextBox}
                    value={options}
                  />
                </div>
                <div className="marginBottom marginTop">
                  <div className={styles.checkBoxStyles}>Sales Channels</div>
                  <CheckBoxGroupe
                    name="salesChannels"
                    loading={loading}
                    options={salesChannelsD.map((el: IDictionaryValue) => ({
                      name: el.name,
                      value: el.id,
                    }))}
                    onChange={onChangeTextBox}
                    value={salesChannels}
                  />
                </div>
                <div className="marginBottom marginTop">
                  <div className={styles.checkBoxStyles}>Payment methods</div>
                  <CheckBoxGroupe
                    name="paymentMethods"
                    loading={loading}
                    options={paymentMethodsD.map((el: IDictionaryValue) => ({
                      name: el.name,
                      value: el.id,
                    }))}
                    onChange={onChangeTextBox}
                    value={paymentMethods}
                  />
                </div>
                <div className="marginBottom marginTop">
                  <div className={styles.checkBoxStyles}>Statuses</div>
                  <CheckBoxGroupe
                    name="statuses"
                    loading={loading}
                    options={statusesD.map((el: IDictionaryValue) => ({
                      name: el.name,
                      value: el.id,
                    }))}
                    onChange={onChangeTextBox}
                    value={statuses}
                  />
                </div>
                <div className="marginBottom marginTop">
                  <div className={styles.checkBoxStyles}>Products</div>
                  <CheckBoxGroupe
                    name="products"
                    loading={loading}
                    options={productsForCountry.map((el: IDictionaryValue) => ({
                      name: el.name,
                      value: el.id,
                    }))}
                    onChange={onChangeTextBox}
                    value={products}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <div className={styles.checkBoxStyles}>Date</div>
                  <DatePickerF
                    name="dateFrom"
                    label="Created at"
                    value={dateFrom.format()}
                    onChange={onChangeDate}
                    width="186.25px"
                    disabled={loading}
                  />
                  <DatePickerF
                    name="dateTo"
                    label=" to"
                    value={dateTo.format()}
                    onChange={onChangeDate}
                    width="186.25px"
                    disabled={loading}
                  />
                  <Right>
                    <Button
                      className={styles.buttonStyle}
                      style={{ float: "right" }}
                      type="primary"
                      htmlType="submit"
                      disabled={loading}
                    >
                      {resources.buttons.submit}
                    </Button>
                    <TextInputF
                      value={reference}
                      name="reference"
                      label="Find"
                      onChange={onChange}
                      width={200}
                      disabled={loading}
                      allowClear
                    />
                  </Right>
                </div>
              </section>
            </Form>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
