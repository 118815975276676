import React from 'react';
import styles from './styles/PersonalDataForm.module.scss';
import { ITimezone, timezones } from '../../../../helpers/timezones';
import { IUpdateUserInf } from '../../../../models/IUpdateUserInf';
import { IUserPersonalData } from '../../../../models/IUserPersonalData';
import { openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { Select } from 'antd';
import { updateUser } from '../../../../services/m29_users_management/GeneralUserService';

interface IUser {
    user: IUserPersonalData;
    id?: number;
}

export class PersonalDataForm extends React.Component<IUser> {
    state = {
        id: '',
        email: '',
        name: '',
        phoneNo: '',
        telegram: '',
        skype: '',
        timeZone: '',
        validationWarningDisplay: 'none',
        id_referral: '',
        loading: false,
    }

    componentDidMount = () => {
        const { id, email, name, phoneNo, telegram, skype, timeZone } = this.generateInitialState();

        this.setState({
            id,
            email,
            name,
            phoneNo,
            telegram,
            skype,
            timeZone
        });
    }

    render() {
        return (
            <>
                <p style={{ display: this.state.validationWarningDisplay, color: 'red' }}>Incorrect form data</p>
                <form className={styles.grid} onSubmit={this.handleSubmit}>
                    <div className={styles.formItem}>
                        <label className={`h6 ${styles.formItem__label}`} htmlFor="email">{resources.profileView.email}</label>
                        <div className={`${styles.formItem__input} ${styles.formItem__input__disabled}`}>{this.state.email}</div>
                    </div>
                    <div className={styles.formItem}>
                        <label className={`h6 ${styles.formItem__label}`} htmlFor="name">{resources.profileView.name}</label>
                        <input className={styles.formItem__input} name="name" id="name" value={this.state.name} type="text" required onChange={this.handleChange} />
                    </div>
                    <div className={styles.formItem}>
                        <label className={`h6 ${styles.formItem__label}`} htmlFor="phoneNo">{resources.profileView.phone}</label>
                        <input className={styles.formItem__input} name="phoneNo" id="phoneNo" value={this.state.phoneNo} type="number" onChange={this.handleChange} />
                    </div>
                    <div className={styles.formItem}>
                        <label className={`h6 ${styles.formItem__label}`} htmlFor="telegram">{resources.profileView.telegram}</label>
                        <input className={styles.formItem__input} name="telegram" id="telegram" value={this.state.telegram} type="text" onChange={this.handleChange} />
                    </div>
                    <div className={styles.formItem}>
                        <label className={`h6 ${styles.formItem__label}`} htmlFor="skype">{resources.profileView.skype}</label>
                        <input className={styles.formItem__input} name="skype" id="skype" value={this.state.skype} type="text" onChange={this.handleChange} />
                    </div>
                    <div className={styles.formItem}>
                        <label className={`h6 ${styles.formItem__label}`}>{resources.profileView.timeZone}</label>
                        <div className='customAnt--userEditForm'>
                            <Select onChange={this.handleSelectChange} placeholder={'UTC +0'} value={this.state.timeZone}>
                                {
                                    timezones.map((timezone: ITimezone) => {
                                        return (
                                            <Select.Option
                                                key={timezone.id}
                                                value={timezone.value}
                                            >
                                                {timezone.value}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className={styles.formFooter}>
                        <button
                            className={styles.formItem__submit}
                            type='submit'
                            disabled={this.state.loading}
                        >
                            {resources.profileView.change}
                        </button>

                        <button
                            className={styles.formItem__reset}
                            type='reset'
                            onClick={this.handleReset}
                            disabled={this.state.loading}
                        >
                            {resources.profileView.cancel}
                        </button>
                    </div>
                </form>
            </>
        );
    }

    generateInitialState = () => {
        const user = this.props.user;

        const initialState = {
            id: user.id ? user.id : '',
            email: user.email ? user.email : '',
            name: user.name ? user.name : '',
            phoneNo: user.phone ? user.phone : '',
            telegram: user.telegram ? user.telegram : '',
            skype: user.skype ? user.skype : '',
            timeZone: user.timezone ? user.timezone : ''
        }

        return initialState
    }

    handleChange = (e: any): void => {
        if (e.target.type === 'number') {
            // it allows digits-only in input type="number"
            const re = /^[0-9\b]+$/;
            if (re.test(e.target.value)) {
                this.setState({
                    [e.target.name]: e.target.value,
                })
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            })
        }
    }

    handleSelectChange = (e: any): void => {
        this.setState({
            timeZone: e,
        })
    }

    handleSubmit = (e: any): void => {
        e.preventDefault();
        if (this.validation()) {
            this.updateUserData();
        } else {
            this.displayValidationAlert();
        }
    }

    updateUserData = async () => {
        this.setState({ loading: true });

        const object: IUpdateUserInf = {
            name: this.state.name,
            phone: this.state.phoneNo,
            telegram: this.state.telegram,
            skype: this.state.skype,
            timezone: this.state.timeZone
        }

        await updateUser(this.props.id!, object)
            .then((response) => {
                if (response) {
                    this.hideValidationAlert();
                    openSuccessNotification(resources.statement.updated);
                }
            })
            .finally(() => { this.setState({ loading: false }); });
    }

    createFormDataObject = () => {
        const keys = Object.keys(this.state);
        const values = Object.values(this.state);

        let obj = this.generateInitialState();

        for (let i = 0; i <= keys.length; i++) {
            if (keys[i] === 'validationVaringDisplay' || keys[i] === 'loading') {
                return
            } else {
                obj = { ...obj, [keys[i]]: values[i] };
            }
        }

        return obj;
    }

    displayValidationAlert = () => {
        this.setState({ validationWarningDisplay: 'block' })
    }

    hideValidationAlert = () => {
        this.setState({ validationWarningDisplay: 'none' })
    }

    handleReset = (): void => {
        const { email, name, phoneNo, telegram, skype, timeZone } = this.generateInitialState();

        this.setState({
            email,
            name,
            phoneNo,
            telegram,
            skype,
            timeZone
        });

        this.hideValidationAlert();
    }

    validation = (): boolean => {
        const { email, name } = this.state;

        if (this.checkLength(email, 3) && this.checkLength(name, 3)) {
            return true;
        }
        else {
            return false;
        }
    }

    checkLength = (el: (string | [any]), howLong: number) => {
        return el.length >= howLong ? true : false;
    }
}