import logoImg from "../../../common/images/full-logo-light.svg";
import logoCCSimplifiedImg from "../../../common/images/logo-cc-light.svg";
import React from "react";
import SubMenu from "antd/lib/menu/SubMenu";
import { connect } from "react-redux";
import {
  createAbsoluteUrl,
  createEfirstUrl,
} from "../../../helpers/UrlCreator";
import { getMenuItems } from "../../../views/MenuItems";
import { IAppState } from "../../../store";
import { Icon, Menu } from "antd";
import { INavigationProps } from "./interfaces/INavigationProps";
import { Link } from "react-router-dom";
import { NavigationItem } from "../navigationItem/NavigationItem";
import { SubNavigationItem } from "../subNavigationItem/SubNavigationItem";
import { USER_ROLES } from "../../../common/enums/UserRoles";
import { resources } from "../../../common/Resources";

class NavigationMenuInner extends React.Component<INavigationProps> {
  state = {
    navigationItems: Array<any>(),
  };

  componentDidMount = () => {
    const permissions = this.props.permissions ? this.props.permissions : [];

    this.setState({ navigationItems: getMenuItems(permissions) });
  };

  UNSAFE_componentWillReceiveProps = (props: INavigationProps) => {
    const permissions = this.props.permissions ? this.props.permissions : [];

    this.setState(
      {
        navigationItems: getMenuItems(permissions),
      },
      () => this.forceUpdate()
    );
  };

  public render() {
    return (
      <nav
        className={"customAnt--menu"}
        style={{
          backgroundColor: this.props.isCCSimplified ? "#12487C" : "#1e253b",
        }}
      >
        {this.props.isCCSimplified ? (
          <Link
            to={createAbsoluteUrl("cc_instances")}
            style={{ width: "180px" }}
          >
            <img src={logoCCSimplifiedImg} alt="logo" />
          </Link>
        ) : (
          <Link to={createAbsoluteUrl("dashboard")} className="link_logo">
            <img src={logoImg} alt="logo" />

            <div>v 1.0.1</div>
          </Link>
        )}

        <Menu
          onClick={this.props.handleMenuClick}
          selectedKeys={[this.props.activeMenuItem]}
          mode="inline"
          theme="dark"
          style={{
            backgroundColor: this.props.isCCSimplified ? "#12487C" : "#1e253b",
          }}
        >
          {this.state.navigationItems.map((navigationItem) =>
            navigationItem.isMenuSubItem
              ? this.renderSubMenu(navigationItem)
              : this.renderMenuItem(navigationItem)
          )}
        </Menu>

        <Menu
          mode="inline"
          theme="dark"
          style={{
            backgroundColor: this.props.isCCSimplified ? "#12487C" : "#1e253b",
          }}
        >
          <Menu.Item
            className="navMenuItem"
            style={{
              backgroundColor: this.props.isCCSimplified
                ? "#12487C"
                : "#1e253b",
            }}
          >
            <a
              href={createEfirstUrl("admin/orders/create")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                type="plus"
                style={{ margin: "0 17px 0 24px", fontSize: "20px" }}
              />
              {resources.navigationItems.create_new_order}
            </a>
          </Menu.Item>

          <Menu.Item
            className="navMenuItem"
            style={{
              backgroundColor: this.props.isCCSimplified
                ? "#12487C"
                : "#1e253b",
            }}
          >
            <a
              href={createEfirstUrl("admin/orders?page=1")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                type="bell"
                style={{ margin: "0 17px 0 24px", fontSize: "20px" }}
              />
              {resources.navigationItems.orders}
            </a>
          </Menu.Item>
        </Menu>
      </nav>
    );
  }

  private renderSubMenu(subnavigationItems: SubNavigationItem) {
    const { optionalParams } = subnavigationItems;
    return (
      <SubMenu
        key={subnavigationItems.title + "key"}
        style={{
          backgroundColor: this.props.isCCSimplified ? "#12487C" : "#1e253b",
        }}
        title={
          <span className="submenu-title-wrapper">
            <Icon
              style={{ fontSize: "20px" }}
              type={optionalParams && optionalParams.icon}
              rotate={optionalParams && optionalParams.rotate}
            />
            {subnavigationItems.title}
          </span>
        }
      >
        {subnavigationItems.navigationItems.map((navigationItem) =>
          this.renderMenuItem(navigationItem)
        )}
      </SubMenu>
    );
  }

  private renderMenuItem(navigationItem: NavigationItem) {
    const { optionalParams } = navigationItem;
    return (
      <Menu.Item
        key={navigationItem.key}
        className="navMenuItem"
        style={{
          backgroundColor: this.props.isCCSimplified ? "#12487C" : "#1e253b",
        }}
      >
        <Link to={`${navigationItem.link}`}>
          {optionalParams!.component ? (
            <img src={optionalParams!.component} alt="" />
          ) : (
            <Icon
              type={optionalParams!.icon}
              style={{ margin: "0 17px 0 24px", fontSize: "20px" }}
            />
          )}
          {navigationItem.name}
        </Link>
      </Menu.Item>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const userRole = state.commonState.appUserInfo.id_users_role;

  return {
    activeMenuItem: state.commonState.activeMenuItem,
    permissions: state.commonState.appUserInfo.permissions,
    isCCSimplified: userRole === USER_ROLES.CC_SIMPLIFIED,
  };
};

const NavigationMenu = connect(mapStateToProps)(NavigationMenuInner);

export default NavigationMenu;
