import CampaignsAdminForm from '../forms/CampaignsAdminForm';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import React, { Component } from 'react';
import SelectOnChange from '../../../components/forms/select/SelectOnChange';
import { CampaingsAdminTable } from '../tables/CampaingsAdminTable';
import { getCcInstancesD, getListFromLibrary } from '../../../services/m40_libraries/LibrariesService';
import { getInstancesDictionaryWithCountries } from '../../../helpers/InstancesDictionaryHelper';
import { ICampaigns } from './interfaces/ICampaignsAdminContent';
import { IDictionaryValue } from '../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { PageTitle } from '../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../common/Resources';
import { Right } from '../../../components/controls/right/Right';
import { TableContainer } from '../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';

export class CampaignsAdminContent extends Component {
    _isMounted = false;

    state = {
        id_instance: 0,
        campaignsList: Array<ICampaigns>(),
        instancesWithCountryD: Array<IDictionaryValue>(),
        instancesD: Array<IDictionaryValue>(),
        loading: false
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getCampaignsList();
        this.getInstancesD();
        this.getInstancesWithCountryD();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    render() {
        const { id_instance, campaignsList, loading, instancesWithCountryD, instancesD } = this.state;
        let campaignsData: ICampaigns[] = campaignsList;

        if (id_instance > 0) {
            campaignsData = campaignsData.filter(x => x.id_instance === Number(id_instance));
        }

        return (
            <TableContainer>
                <PageTitle>{resources.pageTitle.campaigns_admin}</PageTitle>
                <RefreshTableButton
                    className='marginRigth marginBottom'
                    loading={this.state.loading}
                    onClick={this.getCampaignsList}
                />
                <SelectOnChange
                    onChange={this.onChangeSelect}
                    name='id_instance'
                    value={id_instance}
                    label={resources.labels.campaigns}
                    items={instancesWithCountryD}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    allowClear
                    showSearch
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.AddNewCampaignModalButton}>
                        <InformationModalDialog
                            buttonClassName='addNew'
                            modalTitle={resources.title.addNewCampaign}
                            icon={"form"}
                            buttonTitle={resources.modalButtonsTitle.addNewCampaign}
                            disabled={loading}
                            withHideMethod={true}
                            buttonType='primary'
                        >
                            <CampaignsAdminForm
                                addObjToArray={this.addObjToArray}
                                instancesD={instancesD}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <CampaingsAdminTable
                    loading={loading}
                    data={campaignsData}
                    instancesWithCountryD={instancesWithCountryD}
                    instancesD={instancesD}
                    updateArray={this.getCampaignsList}
                    deleteCampaign={this.deleteCampaign}
                    changeCampaignStatus={this.changeCampaignStatus}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    }

    onChangeSelect = (name: string, value?: number): void => {
        value ? this.setState({ [name]: value }) : this.setState({ [name]: 0 });
    }

    getCampaignsList = async () => {
        this.setState({ loading: true });

        const object = {
            name: 'cc_campaigns',
            column: "id, name, id_instance, id_external, is_active, fields, created_at, updated_at"
        };

        getListFromLibrary(object)
            .then(response => {
                const campaignsList = response.map((item: any) => {
                    return { ...item, key: item.id }
                });

                this._isMounted && this.setState({ campaignsList });
            })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    getInstancesWithCountryD = async () => {
        await getInstancesDictionaryWithCountries()
            .then(instancesWithCountryD => { this._isMounted && this.setState({ instancesWithCountryD }); });
    }

    getInstancesD = async () => {
        await getCcInstancesD()
            .then(instancesD => { this._isMounted && this.setState({ instancesD }); });
    }

    deleteCampaign = async () => {
        // const query = `DELETE FROM cc_campaigns WHERE id = ${id}`;

        // await deleteCampaign(query)
        //     .then(response => {
        //         if (response) {
        //             this.deleteCampaignFromList(id);
        //         }
        //         else {
        //             openErrorNotification(resources.statement.there_was_a_problem_removing_campaign);
        //         }
        //     })
        //     .catch(() => {
        //         openErrorNotification(resources.statement.databaseConnectionError);
        //     });
    }

    deleteCampaignFromList = () => {
        // let campaignsList = this.state.campaignsList.filter(x => Number(x.id)! !== id);

        // this.setState({ campaignsList });

        // openSuccessNotification(resources.statement.campaign_deleted);
    }

    changeCampaignStatus = async () => {
        // const id = Number(event.target.value);
        // const query = `UPDATE cc_campaigns SET is_active = !is_active WHERE id = ${id}`;

        // await updateCampaign(query)
        //     .then(() => {
        //         this.state.mounted && this.changeStatus(id);
        //         openSuccessNotification(resources.statement.status_updated);
        //     })
        //     .catch(() => {
        //         openErrorNotification(resources.statement.databaseConnectionError);
        //     });
    }

    changeStatus = () => {
        // const { campaignsList } = this.state;
        // const index = campaignsList.findIndex(x => Number(x.id) === id);

        // campaignsList[index].is_active = !campaignsList[index].is_active;
        // campaignsList[index].updated_at = new Date();
        // this.setState({ campaignsList });
    }

    addObjToArray = () => {
        // this.setState({ campaignsList: [...this.state.campaignsList, campaign] });
    }

    updateObjInArray = (): void => {
        // const { campaignsList } = this.state;
        // const index = campaignsList.findIndex(x => x.id === campaign.id);

        // campaignsList[index].name = campaign.name;
        // campaignsList[index].is_active = campaign.is_active;
        // campaignsList[index].id_external = campaign.id_external;
        // campaignsList[index].id_instance = campaign.id_instance;
        // campaignsList[index].fields = campaign.fields;
        // campaignsList[index].updated_at = campaign.updated_at;

        // this.setState({ campaignsList });
    }
}