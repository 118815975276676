import moment from 'moment';
import React from 'react';
import styles from './styles/SingleNews.module.scss';
import { ISingleNews } from './interfaces/ISingleNews';

export const SingleNews = (props: ISingleNews) => {
    return (
        <div className={`${styles.card}`}>
            <div className={styles.head}>
                <h6 className={`h6__bold ${styles.h6}`}>{props.title}</h6>
                <p className={`h6 ${styles.date}`}>{moment(new Date(props.date_from)).format('YYYY/MM/DD')}</p>
            </div>
            <div className={styles.body}>
                <p className={`h6 ${styles.para}`}>{props.body}</p>
            </div>
        </div>
    )
}
