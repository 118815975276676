import PixelsForm from '../forms/PixelsForm';
import React, { Component } from 'react';
import { ILoading } from '../../../../../../../../common/interfaces/ILoading';
import { IPixelsListProps } from '../interfaces/IPixelsListProps';
import { IPixelsMethods } from '../interfaces/IPixelsMethods';
import { List } from 'antd';

export class PixelsList extends Component<IPixelsListProps & IPixelsMethods & ILoading> {
    render() {
        return (
            <List
                dataSource={this.props.data}
                loading={this.props.loading}

                renderItem={
                    item => (
                        <List.Item>
                            <PixelsForm
                                {...item}
                                usersD={this.props.usersD}
                                changePixelStatus={this.props.changePixelStatus}
                                deletePixel={this.props.deletePixel}
                                updatePixel={this.props.updatePixel}
                            />
                        </List.Item>
                    )
                }
            />
        );
    }
}