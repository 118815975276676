import * as React from "react";
import SelectOnChange from "../../../../components/forms/select/SelectOnChange";
import { Button } from "antd";
import { ClearBoth } from "../../../../components/controls/clearBoth/ClearBoth";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IProduct } from "../../productDetails/contents/interfaces/IProduct";
import { PageInf } from "../../../../components/common/pageInf/PageInf";
import { ProductsTable } from "../tables/ProductsTable";
import { ProtectedLink } from "../../../../components/authorizations/protectedComponents/ProtectedLink";
import { RefreshTableButton } from "../../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../../common/Resources";
import { Right } from "../../../../components/controls/right/Right";
import { TableContainer } from "../../../../components/containers/tableContainer/TableContainer";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";

const values = [
  { id: 1, name: "Active" },
  { id: 0, name: "Inactive" },
];

const affbay_visibility = [
  { id: 1, name: "Available in Affbay" },
  { id: 0, name: "Unavailable in Affbay" },
];

export class ProductsContent extends React.Component {
  _isMounted = false;

  state = {
    loading: false,
    productsList: Array<IProduct>(),
    countriesNameD: Array<IDictionaryValue>(),
    country_id: undefined,
    is_active: 1,
    is_visible_affbay: 1,
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.setState({ countriesNameD: getDictionary("countriesNameD") });

    this.getProductsFromDb();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const {
      is_active,
      is_visible_affbay,
      productsList,
      loading,
      country_id,
      countriesNameD,
    } = this.state;

    let productsListData = productsList;

    if (country_id) {
      productsListData = productsListData.filter(
        (x) => x.country_id === country_id
      );
    }

    if (is_active !== undefined) {
      productsListData = productsListData.filter(
        (x) => Boolean(x.is_active) === Boolean(is_active)
      );
    }

    if (is_visible_affbay !== undefined) {
      productsListData = productsListData.filter(
        (x) => Boolean(x.is_visible_affbay) === Boolean(is_visible_affbay)
      );
    }

    return (
      <TableContainer>
        <PageInf
          title={resources.pageTitle.products}
          subTitle={this.generateSuptitle()}
        />
        <RefreshTableButton
          className="marginBottom marginRigth"
          loading={loading}
          onClick={this.getProductsFromDb}
        />
        <SelectOnChange
          value={country_id}
          name="country_id"
          items={getDictionary("countriesNameD")}
          onChange={this.onChangeSelect}
          allowClear
          placeholder={resources.select.select_country}
          width={180}
          className="marginBottom marginRigth"
        />
        <SelectOnChange
          value={is_active}
          name="is_active"
          items={values}
          onChange={this.onChangeSelect}
          placeholder={resources.select.select_status}
          allowClear
          width={180}
          className="marginBottom marginRigth"
        />
        <SelectOnChange
          value={is_visible_affbay}
          name="is_visible_affbay"
          items={affbay_visibility}
          onChange={this.onChangeSelect}
          placeholder={resources.select.select_available}
          allowClear
          width={180}
          className="marginBottom"
        />
        <Right>
          <ProtectedLink
            to="product_details"
            permission={UserPermissionType.AddNewProductLinkButton}
          >
            <Button
              className="addNew marginBottom"
              type="primary"
              icon="plus"
              loading={loading}
            >
              {resources.buttons.addNewProduct}
            </Button>
          </ProtectedLink>
        </Right>
        <ClearBoth />
        <section>
          <ProductsTable
            data={productsListData}
            loading={loading}
            countries={countriesNameD}
          />
        </section>
      </TableContainer>
    );
  }

  getProductsFromDb = () => {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id",
        "name",
        "country_id",
        "is_visible_affbay",
        "is_active",
        "is_available_sale",
        "default_price",
        "is_visible_partners",
        "categories",
        "product_sku",
        "production_cost_local",
      ],
    };
    getProducts(object)
      .then((response) => {
        const productsList = response.map((item: any) => {
          return { ...item, key: item.id };
        });

        this._isMounted && this.setState({ productsList });
      })
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };

  onChangeSelect = (name: string, value?: number) => {
    this.setState({ [name]: value });
  };

  generateSuptitle = () => {
    let subtitle = "";

    switch (this.state.is_active) {
      case 1:
        subtitle = resources.labels.active_products;
        break;
      case 0:
        subtitle = resources.labels.inactive_products;
        break;
      default:
        subtitle = resources.labels.all_products;
    }

    return subtitle;
  };
}
