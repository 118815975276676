import * as React from 'react';

import img from './images/arrow.png';

export const LandingSliderArrow = (props: any) => {
    const {className, onClick, dir} = props;
    const rotateDeg = dir === 'prev' ? 180 : 0;
    return (
        <div onClick={onClick} className={className}>
            <img src={img} alt={props.dir} style={{transform: `rotate(${rotateDeg}deg)`}}/>
        </div>
    );
}