import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import PostCodeDictionaryForm from '../forms/PostCodeDictionaryForm';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { deletePostCodesDictionary, getPostCodesDictionaryList } from '../../../../services/dictionaries/PostCodesDivtionaryService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IPostCode } from '../interfaces/IPostCode';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { PostCodesDictionaryTable } from '../table/PostCodesDictionaryTable';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class PostCodesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        selected_country: undefined,
        postCodsList: Array<IPostCode>(),
        countriesD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getPostCodesList();

        this.setState({ countriesD: getDictionary('countriesNameD') });
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { postCodsList, loading, selected_country, countriesD } = this.state;

        let filteredData = postCodsList;

        if (selected_country)
            filteredData = filteredData.filter(x => x.country_id === selected_country);

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.postCodeDictionary} />
                <Left>
                    <RefreshTableButton
                        className='marginBottom marginTopRefreshButton marginRigth'
                        loading={loading}
                        onClick={this.getPostCodesList}
                    />
                </Left>
                <FieldInfo>{resources.labels.country}</FieldInfo>
                <SelectOnChange
                    name='selected_country'
                    labelClassName='marginLeft marginBottom'
                    onChange={this.onChangeSelect}
                    value={selected_country}
                    placeholder={resources.select.select}
                    items={getDictionary('countriesNameD')}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    allowClear
                />
                <ProtectedModal permission={UserPermissionType.AddNewPostCodeModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewPostCode}
                            buttonTitle={resources.modalButtonsTitle.addNewPostCode}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewPostCode}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={loading}
                        >
                            <PostCodeDictionaryForm
                                addObjToArray={this.addObjToArray}
                                countriesD={countriesD}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <PostCodesDictionaryTable
                    loading={loading}
                    data={filteredData}
                    deletePostCode={this.deletePostCode}
                    updateObjInArray={this.updateObjInArray}
                    countriesD={countriesD}
                />
            </TableContainer>
        )
    };

    getPostCodesList = () => {
        this.setState({ loading: true });

        getPostCodesDictionaryList()
            .then(postCodsList => { this._isMounted && this.setState({ postCodsList }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    deletePostCode = async (id: number) => {
        const query = `DELETE FROM li_post_codes WHERE id = ${id}`;

        await deletePostCodesDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_post_code);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const postCodsList = this.state.postCodsList.filter(x => x.id !== id);

        this.setState({
            postCodsList,
            loading: false
        });

        openSuccessNotification(resources.statement.post_code_deleted);
    }

    addObjToArray = (postCode: IPostCode) =>
        this.setState({ postCodsList: [...this.state.postCodsList, postCode] });

    updateObjInArray = (obj: IPostCode): void => {
        const { postCodsList } = this.state;
        const index = postCodsList.findIndex(x => x.id === obj.id);

        postCodsList[index].name = obj.name;
        postCodsList[index].country_id = obj.country_id;
        postCodsList[index].updated_at = obj.updated_at;

        this.setState({ postCodsList });
    }

    onChangeSelect = (name: string, value: number): void =>
        this.setState({ [name]: value });
}