import React from 'react';
import TextInputF from '../../../../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IBasePriceFormProps } from './interfaces/IBasePriceFormProps';
import { IHideModal } from '../../../../../../../common/interfaces/IHideModal';
import { IId } from '../../../../../../../common/interfaces/IId';
import { IUpdateProductsList } from './interfaces/IUpdateProductsList';
import { openAlertNotification, openSuccessNotification } from '../../../../../../../helpers/NotificationHelper';
import { resources } from '../../../../../../../common/Resources';
import { updateProduct } from '../../../../../../../services/m30_products/ProductsService';

export class TemplateBasePriceForm extends React.Component<IBasePriceFormProps & IHideModal & IId & IUpdateProductsList & FormikProps<IBasePriceFormProps>> {
    public render() {
        return (
            <Form>
                <TextInputF
                    {...this.props}
                    name="default_price"
                    label='Default price'
                    type='number'
                    allowClear
                    labelTextAlignLeft
                    required
                    boldLabel
                />
                <Button className='saveButton' type="primary" htmlType="submit">
                    {resources.buttons.save}
                </Button>
            </Form>
        );
    };
}

const BasePriceForm = withFormik<IBasePriceFormProps & IUpdateProductsList, IBasePriceFormProps>({
    mapPropsToValues: (props: IBasePriceFormProps) => {
        return {
            default_price: props.default_price
        };
    },

    validate: (values: IBasePriceFormProps) => {
        const errors: FormikErrors<IBasePriceFormProps> = {};

        if (!values.default_price) {
            errors.default_price = resources.validation.required;
        }
        else if (values.default_price.toString().length > 9) {
            errors.default_price = 'Max 9 numbers';
        }
        return errors;
    },

    handleSubmit: (values: IBasePriceFormProps, bag: FormikBag<IBasePriceFormProps & IHideModal & IId & IUpdateProductsList, IBasePriceFormProps>) => {
        if (values.default_price === bag.props.default_price)
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else
            updateProductPrice(values, bag.props);
    }

})(TemplateBasePriceForm);

export default BasePriceForm;

const updateProductPrice = async (obj: IBasePriceFormProps, bag: IId & IUpdateProductsList & IHideModal) => {
    const object = {
        default_price: obj.default_price! * 100
    };

    updateProduct(Number(bag.id), object)
        .then(response => {
            if (response) {
                bag.updateProductsList(obj.default_price! * 100)
                bag.hideModal!();
                openSuccessNotification(resources.statement.updated);
            }
        })
}