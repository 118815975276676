import * as React from 'react';
import ProtectedComponent from './ProtectedComponent';
import { IProtectedComponentProps } from '../../../authorizations/interfaces/IProtectedComponentProps';
import { Link, LinkProps } from 'react-router-dom';

export const ProtectedLink = (props: LinkProps & IProtectedComponentProps) => {
    const { permission, ...linkProps } = props;

    return (
        <ProtectedComponent permission={permission}>
            <Link {...linkProps} />
        </ProtectedComponent>
    )
}