import React, { Component } from "react";
import SelectOnChange from "../../../components/forms/select/SelectOnChange";
import styles from "../styles/Styles.module.scss";
import { CampaignsPartnerTable } from "../tables/CampaignsPartnerTable";
import {
  deleteTargetUrlCampaigns,
  getTargetUrlCampaigns,
} from "../../../services/TargetUrlCamaigns";
import { getDictionary } from "../../../helpers/DisctionariesHelper";
import { getListOfAllProductPages } from "../../../services/m30_products/pages/ProductPagesService";
import { getProductsD } from "../../../services/m30_products/ProductsService";
import { IAllProductPagesFilters } from "../../../services/m30_products/pages/interfaces/IAllProductPagesFilters";
import { ICampaignsContent } from "../interfaces/ICampaignsContent";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { Input } from "antd";
import { openSuccessNotification } from "../../../helpers/NotificationHelper";
import { resources } from "../../../common/Resources";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";

export interface IPagesD {
  id: number;
  name: string;
  is_prelanding: boolean;
}

export class CampaignsPartnerContent extends Component {
  _isMounted = false;

  state = {
    loading: false,
    name_filter: undefined,
    offer_filter: undefined,
    country_filter: undefined,
    landing_filter: undefined,
    prelanding_filter: undefined,
    campaigns_list: Array<ICampaignsContent>(),
    productsD: Array<IDictionaryValue>(),
    countriesD: Array<IDictionaryValue>(),
    pagesD: [] as Array<IPagesD>,
    country_list: [] as number[],
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.getCampaigns();
    this.getCountriesD();
    this.getPagesD();
    this.getProductsD();
  };

  componentWillUnmount = () => (this._isMounted = false);

  render() {
    const {
      loading,
      campaigns_list,
      pagesD,
      productsD,
      countriesD,
      name_filter,
      offer_filter,
      country_filter,
      landing_filter,
      prelanding_filter,
    } = this.state;

    let filteredData = campaigns_list;

    if (name_filter)
      filteredData = filteredData.filter((x) =>
        (x.name ? x.name.toLocaleLowerCase() : "").includes(name_filter!)
      );
    if (offer_filter)
      filteredData = filteredData.filter((x) => x.lp.includes === offer_filter);
    if (country_filter) {
      const filtred_products = productsD
        .filter((x) => x.country_id === country_filter)
        .map((x) => x.id);

      filteredData = filteredData.filter((x) =>
        filtred_products.includes(x.product_id!)
      );
    }
    if (landing_filter)
      filteredData = filteredData.filter((x) => x.lp.includes(landing_filter!));
    if (prelanding_filter)
      filteredData = filteredData.filter((x) =>
        x.pre.includes(prelanding_filter!)
      );

    return (
      <>
        <ViewHeader title={resources.pageTitle.campaigns} />
        <div
          className={`customAnt--newsSelect customAnt--campaignsFilters ${styles.filters}`}
        >
          <Input
            placeholder="Name"
            name="name_filter"
            onChange={this.onGlobalSearch}
            disabled={this.state.loading}
            allowClear
          />
          <SelectOnChange
            onChange={this.onChangeSelect}
            name="offer_filter"
            placeholder="Offer"
            value={offer_filter}
            items={productsD}
            loading={loading}
            allowClear
            showSearch
          />
          <SelectOnChange
            onChange={this.onChangeSelect}
            name="country_filter"
            placeholder="Country"
            value={country_filter}
            items={countriesD}
            loading={loading}
            allowClear
            showSearch
          />
          <SelectOnChange
            onChange={this.onChangeSelect}
            name="prelanding_filter"
            placeholder="Prelanding"
            value={prelanding_filter}
            items={pagesD.filter((x) => x.is_prelanding)}
            loading={loading}
            allowClear
            showSearch
          />
          <SelectOnChange
            onChange={this.onChangeSelect}
            name="landing_filter"
            placeholder="Landing"
            value={landing_filter}
            items={pagesD.filter((x) => !x.is_prelanding)}
            loading={loading}
            allowClear
            showSearch
          />
        </div>
        <CampaignsPartnerTable
          data={filteredData}
          loading={loading}
          productsD={productsD}
          pagesD={pagesD}
          deleteTargetUrlCampaigns={this.deleteTargetUrlCampaigns}
        />
      </>
    );
  }

  getPagesD = async () => {
    const object: IAllProductPagesFilters = {
      columns: ["id", "name", "is_prelanding"],
      is_visible: true,
    };

    getListOfAllProductPages(object).then((pagesD) => {
      this.setState({ pagesD });
    });
  };

  getProductsD = () => {
    getProductsD().then((productsD) => {
      this._isMounted && this.setState({ productsD });
    });
  };

  getCountriesD = () => {
    this.setState({ countriesD: getDictionary("countriesNameD") });
  };

  getCampaigns = async () => {
    this.setState({ loading: true });

    await getTargetUrlCampaigns("")
      .then((response) => {
        const campaigns_list = response.map((el: any, index: number) =>
          this.generateCampaignData(el, index)
        );

        this._isMounted && this.setState({ campaigns_list });
      })
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };

  generateCampaignData = (campaign: any, index: number) => {
    return {
      id: campaign.uuid,
      product_id: campaign.productId,
      key: index,
      name: campaign.name,
      lp: campaign.config.landingPages,
      pre: campaign.config.preLandingPages,
      url: campaign.urls.tracker,
    };
  };

  deleteTargetUrlCampaigns = (id: string) => {
    deleteTargetUrlCampaigns(id).then((response) => {
      if (response) {
        this.getCampaigns();
        openSuccessNotification(resources.statement.deleted);
      }
    });
  };

  onChangeSelect = (name: string, value?: number): void => {
    this.setState({ [name]: value });
  };

  onGlobalSearch = (event: any) => {
    const value = event.target.value.toLowerCase();

    this.setState({ name_filter: value });
  };
}
