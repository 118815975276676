import * as React from 'react';
import { ISelectFProps } from './interfaces/ISelectFProps';
import { Select } from 'antd';

export class SelectF extends React.Component<ISelectFProps> {
    onChangeSelect = (result: number): void => {
        this.props.onChange(this.props.name, result);
    }

    render() {
        const { label, items, size, disabled, value, error, required, width, showSearch, loading, allowClear, placeholder = undefined, margin, labelClassName } = this.props;
        const { Option } = Select;
        return (

            <>
                {required ? <label style={{ color: 'red' }}> * </label> : null}
                {label ? <label className={labelClassName} htmlFor={label}><b>{label}: </b></label> : null}
                <Select
                    showSearch={showSearch}
                    disabled={disabled}
                    size={size}
                    allowClear={allowClear}
                    value={value}
                    placeholder={placeholder ? placeholder : 'Select'}
                    onChange={this.onChangeSelect}
                    style={{ margin: margin, width: width }}
                    loading={loading}
                >
                    {items.map(item =>
                        <Option key={item.id} value={item.id} >{item.name}</Option>
                    )}
                </Select>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </>
        );
    }
}