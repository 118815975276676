import React from 'react';
import ReactQuill from 'react-quill';
import { BaseForm } from '../base/BaseForm';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';

export interface ITextInputFProps {
    isNumeric?: boolean;
    isPassword?: boolean;
    iconType?: string;
    placeholder?: string;
}

const HtmlEditorF: React.SFC<IBaseFormikProps & ITextInputFProps> = (props) => {
    function onChange(value: string) {
        return value;
    }

    const toolBarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        ['link']

    ]
    const inputValue = props.values[props.name];

    return (
        <BaseForm {...props}>
            <ReactQuill
                style={{ backgroundColor: 'white' }}
                value={inputValue}
                onChange={value => props.setFieldValue(props.name, onChange(value))}
                modules={{ toolbar: toolBarOptions }}
            />
        </BaseForm>
    )
};

export default HtmlEditorF;