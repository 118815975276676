import * as React from 'react';
import styles from './styles/LandingNav.module.scss';
import { Anchor } from 'antd';
import { resources } from '../../../../common/Resources';

export class LandingNav extends React.Component {
    render() {
        return (
            <nav className={`${'cutomAnt'} ${'customAnt--landingNav'} ${styles.nav}`}>
                <Anchor affix={false}>
                    <div className={styles.navBarGrid}>
                        <Anchor.Link href="#about" title={resources.landingPage.about} className={'h4__semi'} />
                        <Anchor.Link href="#topOffers" title={resources.landingPage.topOffers} className={'h4__semi'} />
                        <Anchor.Link href="#contact" title={resources.landingPage.contact} className={'h4__semi'} />
                    </div>
                </Anchor>
            </nav>
        )
    }
}