import React from "react";

import styles from "./approve-statistic-item.module.scss";

type Props = {
  icon: string;
  value: string;
  title: string;
};

export const ApproveStatisticItem = ({ icon, value, title }: Props) => {
  return (
    <div className={styles.item}>
      <img src={icon} alt="approve-icon" className={styles.icon} />

      <div className={styles.statistic}>
        <span className={styles.title}>{title}</span>

        <div className={styles.rate}>
          <span className={styles["rate__value"]}>{value}</span>
          <span className={styles["rate__percentage"]}>%</span>
        </div>
      </div>
    </div>
  );
};
