import React from 'react';
import styles from './styles/CustomVariablesSettingsContnet.module.scss';
import { ICustomVariablesSettings, ICustomVariablesSettingsState } from './interfaces/ICustomVariablesSettings';
import { FormikErrors, FormikBag, withFormik, FormikProps, FieldArray, Form, Field } from 'formik';
import { Right } from '../../../../components/controls/right/Right';
import { Button } from 'antd';
import { resources } from '../../../../common/Resources';


class CustomVariablesSettingsTemplate extends React.Component<ICustomVariablesSettings & FormikProps<ICustomVariablesSettingsState>> {

    render() {
        const submitButtonStyle = this.props.isValid ? styles.green : styles.gray;
        return (
            <div className={styles.container}>
                <Form>
                    <FieldArray
                        name='customVariables'
                        render={arrayHelpers => (
                            <div>
                                <div className={styles.header}>
                                    <div className={styles.title}>
                                        {resources.profileView.customVariablesList}
                                    </div>
                                    {this.props.values.customVariables.length < this.props.maxVariablesCount ?
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push('')}
                                                className={styles.addButton}
                                            >
                                                {resources.buttons.addVariable}
                                            </button>
                                        </div> : null}
                                </div>
                                <div className={styles.inputs}>
                                    {this.props.values.customVariables && this.props.values.customVariables.map((customVariables: string, index: number) => {
                                        return (
                                            <div key={index} className={styles.formItem}>
                                                    <Field
                                                        name={`customVariables.${index}`}
                                                    />
                                                    <Button
                                                        icon='delete'
                                                        className={styles.controlButton}
                                                        onClick={() => arrayHelpers.remove(index)}>
                                                    </Button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>)}
                    />
                    <Right>
                        <button
                            type='submit'
                            className={`${styles.submitBtn}  ${submitButtonStyle}`}
                            disabled={!this.props.isValid}
                        >
                            {resources.buttons.save}
                    </button>
                    </Right>
                </Form>
            </div>
        );
    };
}

const CustomVariablesSettings = withFormik<ICustomVariablesSettings, ICustomVariablesSettingsState>({
    enableReinitialize: true,

    mapPropsToValues: (props: ICustomVariablesSettings): ICustomVariablesSettingsState => {
        return {
            customVariables: props.customVariables,
        };
    },

    validate: (values: ICustomVariablesSettingsState): FormikErrors<ICustomVariablesSettingsState> => {
        let errors: FormikErrors<ICustomVariablesSettingsState> = {};
        const customVariablesErrors = [] as FormikErrors<Array<string>>;
        values.customVariables.forEach((customVariable: string, idx: number) => {
            if (customVariable.trim() === '') {
                customVariablesErrors[idx] = resources.validation.required;
            }
        })
        if (customVariablesErrors.length > 0) {
            errors.customVariables = customVariablesErrors;
        }
        return errors;
    },

    handleSubmit: (values: ICustomVariablesSettingsState, bag: FormikBag<ICustomVariablesSettings, ICustomVariablesSettingsState>) => {

        bag.props.onUpsert && bag.props.onUpsert(values.customVariables);

    }

})(CustomVariablesSettingsTemplate);

export default CustomVariablesSettings;
