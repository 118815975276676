import * as React from 'react';
import styles from './styles/PopUpForm.module.scss';
import { IPopUpFormMethods } from './interfaces/IPopUpFormMethods';
import { IPopUpFormProps } from './interfaces/IPopUpFormProps';
import { Modal } from 'antd';

const bodyStyle = {
    width: '100%',
    background: '#fff',
    padding: '40px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
}

export class PopUpForm extends React.Component<IPopUpFormProps & IPopUpFormMethods> {
    render() {
        return (
            <Modal
                centered={true}
                footer={null}
                bodyStyle={bodyStyle}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                width={this.props.width ? this.props.width : '592px'}
                destroyOnClose={this.props.destroy ? this.props.destroy : true}
            >
                <section className={styles.children}>
                    {this.props.children}
                </section>
            </Modal>
        );
    }
}