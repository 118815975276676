import React from 'react';
import moment from 'moment';
import { Table, Tooltip } from 'antd';
import { DataTable } from '../../../../../components/others/data/DataTable';
import { resources } from '../../../../../common/Resources';
import { spanWithTitle } from '../../../../../components/others/SpanWithTitle';
import { convertNumberToDateTimeString } from '../../../../../helpers/DateHelpers';
import { getYesOrNoFilters } from '../../../../../common/dictionaries/Filters';
import { convertBooleanYesOrNoReactElement } from '../../../../../helpers/BooleanHelper';

const { Column, ColumnGroup } = Table;

export class ExpandedPostbackTable extends DataTable {
    render() {
        const columns = [
            <ColumnGroup
                key='title'
                className='columnGroupStyle'
                title={`Attached postbacks ( Lead ID: ${this.props.id_lead} )`}
            >
                <Column
                    title={spanWithTitle(resources.columnTitle.response_status, resources.columnTitle.response_status_short)}
                    dataIndex='response_status'
                    key='response_status'
                    width={100}
                    align='center'
                    sorter={(a, b) => a.response_status - b.response_status}
                    {...this.getColumnSearchProps('response_status')}
                />,
            <Column
                    title={spanWithTitle(resources.columnTitle.response_text, resources.columnTitle.response_text_short)}
                    key='response_text'
                    width={150}
                    align='center'
                    sorter={(a, b) => (a.response_text ? a.response_text : "").localeCompare(b.response_text ? b.response_text : "")}
                    {...this.getColumnSearchProps('response_text')}
                    render={(record) => (
                        <Tooltip title={record.response_text}>
                            <label className='marginLeft'>
                                {record.response_text !== null && record.response_text.length > 28 ? record.response_text.substring(0, 25) + '...' : record.response_text}
                            </label>
                        </Tooltip>
                    )}
                />,
            <Column
                    title={resources.columnTitle.type}
                    dataIndex='type'
                    key='type'
                    width={100}
                    align='center'
                    sorter={(a, b) => (a.type ? a.type : "").localeCompare(b.type ? b.type : "")}
                    {...this.getColumnSearchProps('type')}
                />,
            <Column
                    title='Method'
                    dataIndex='method'
                    key='method'
                    width={100}
                    align='center'
                    sorter={(a, b) => (a.method ? a.method : "").localeCompare(b.method ? b.method : "")}
                    {...this.getColumnSearchProps('method')}
                />,
            <Column
                    title={spanWithTitle(resources.columnTitle.url, resources.columnTitle.url_short)}
                    key='url'
                    width={350}
                    align='center'
                    sorter={(a, b) => (a.url ? a.url : "").localeCompare(b.url ? b.url : "")}
                    {...this.getColumnSearchProps('url')}
                    render={(record) => (
                        <Tooltip title={record.url}>
                            <label className='marginLeft'>
                                {record.url !== null && record.url.length > 48 ? record.url.substring(0, 45) + '...' : record.url}
                            </label>
                        </Tooltip>
                    )}
                />,
            <Column
                    title={spanWithTitle(resources.columnTitle.response_time, resources.columnTitle.response_time_short)}
                    dataIndex='respond_time'
                    key='respond_time'
                    width={100}
                    align='center'
                    sorter={(a, b) => a.respond_time - b.respond_time}
                    {...this.getColumnSearchProps('respond_time')}
                />,
            <Column
                    title={spanWithTitle(resources.columnTitle.is_successful, resources.columnTitle.is_successful_short)}
                    key='is_successful'
                    align='center'
                    width={120}
                    sorter={(a, b) => a.is_successful - b.is_successful}
                    filters={getYesOrNoFilters()}
                    onFilter={(value, record) => record.is_successful === value}
                    render={(record) => (
                        convertBooleanYesOrNoReactElement(record.is_successful)
                    )}
                />,
            <Column
                    title={spanWithTitle(resources.columnTitle.is_manual, resources.columnTitle.is_manual_short)}
                    key='is_manual'
                    align='center'
                    width={120}
                    sorter={(a, b) => a.is_manual - b.is_manual}
                    filters={getYesOrNoFilters()}
                    onFilter={(value, record) => record.is_manual === value}
                    render={(record) => (
                        convertBooleanYesOrNoReactElement(record.is_manual)
                    )}
                />,
            <Column
                    title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                    key='updated_at'
                    align='center'
                    sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                    render={(record) => (
                        convertNumberToDateTimeString(record.updated_at)
                    )}
                />
            </ColumnGroup>
        ]

        return (
            <DataTable
                tableName='ExpandedPostbackTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                defaultPageSize={50}
                pagination={false}
            />
        )
    }
}