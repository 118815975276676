import DatePickerF from "../../../../components/templateForm/form/datePicker/DatePickerF";
import DictionaryF from "../../../../components/templateForm/form/dropDown/DictionaryF";
import moment from "moment";
import React from "react";
import styles from "./styles/CompanieForm.module.scss";
import TextAreaF from "../../../../components/templateForm/form/textArea/TextAreaF";
import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";
import { Button } from "antd";
import {
  createNews,
  updateNews,
} from "../../../../services/m61_news/NewsService";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { getBooleanValues } from "../../../../common/dictionaries/Boolean";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { getLanguageVersion } from "../../../../helpers/LocaleHelper";
import { IAddObjToArray } from "../../../../common/interfaces/IAddObjToArray";
import { IDefaultProps } from "../../../dictionaries/countries/interfaces/IDefaultProps";
import { IHideModal } from "../../../../common/interfaces/IHideModal";
import { INewsCreate } from "../../../../services/m61_news/interfaces/INewsCreate";
import { INewsFormProps } from "./interfaces/INewsFormProps";
import { INewsUpdate } from "../../../../services/m61_news/interfaces/INewsUpdate";
import { IUpdateObjInArray } from "../../../../common/interfaces/IUpdateObjInArray";
import { resources } from "../../../../common/Resources";

class NewsFormInner extends React.Component<
  INewsFormProps & IDefaultProps & IHideModal & FormikProps<INewsFormProps>
> {
  public static defaultProps = {
    labelTextAlignLeft: true,
    boldLabel: true,
    allowClear: true,
  };

  state = {
    selected_country_id: undefined,
  };

  componentDidMount = () => {
    if (this.props.values.id_product) {
      if (this.props.productsD) {
        const index = this.props.productsD.findIndex(
          (x) => x.id === this.props.values.id_product
        );

        const selected_country_id =
          index > -1 ? this.props.productsD[index].country_id : undefined;

        this.setState({ selected_country_id });
      }
    }
  };

  public render() {
    const { productsD } = this.props;

    // let filtered_productsD: any[] = [];

    // if (selected_country_id && productsD) {
    //     filtered_productsD = productsD.filter(x => x.country_id === selected_country_id);
    // }

    const title =
      getLanguageVersion() === 1 ? this.props.title_en : this.props.title_ru;

    return (
      <Form>
        <section>
          {title && (
            <>
              <div className={styles.companieName}>{title}</div>
              <div className={styles.sectionDivider} />
            </>
          )}
          <div className={styles.grid_col_2}>
            <div>
              <TextInputF
                {...this.props}
                name="title_en"
                label={resources.labels.title + " EN"}
                required
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="title_ru"
                label={resources.labels.title + " RU"}
                required
              />
            </div>
          </div>
          <div className={styles.grid_col_3}>
            <div>
              <DictionaryF
                {...this.props}
                name="id_news_type"
                label={resources.labels.news_type}
                dictionaryValues={getDictionary("newsTypesD")}
                allowClear
                required
              />
            </div>
            <div>
              <DatePickerF
                {...this.props}
                name="date_from"
                label={resources.labels.dateFrom}
                required
              />
            </div>
            <div>
              <DatePickerF
                {...this.props}
                name="date_to"
                label={resources.labels.dateTo}
                required
              />
            </div>
          </div>
          <div className={styles.grid_col_2}>
            <div>
              <DictionaryF
                {...this.props}
                name="is_home_page"
                label={resources.labels.visible_on_home_page}
                dictionaryValues={getBooleanValues()}
              />
            </div>
            {/* <div>
                            <DictionaryF
                                {...this.props}
                                {...this.props.values.id_country = this.state.selected_country_id}
                                name="id_country"
                                label={resources.labels.country}
                                dictionaryValues={getDictionary('countriesNameD')}
                                customOnChange={item => this.onCountryChange("id_country", item.id)}
                            />
                        </div> */}
            <div>
              <DictionaryF
                {...this.props}
                name="id_product"
                label={resources.labels.product}
                dictionaryValues={productsD}
                allowClear
                // disabled={!this.props.values.id_country}
              />
            </div>
          </div>
          <TextAreaF
            {...this.props}
            name="body_en"
            textAreaRows={6}
            label={resources.labels.description + " EN"}
            required
          />
          <TextAreaF
            {...this.props}
            name="body_ru"
            textAreaRows={6}
            label={resources.labels.description + " RU"}
            required
          />
        </section>
        <div className={styles.sectionDivider}></div>
        <Button className="saveButton" htmlType="submit" type="primary">
          {this.props.id ? resources.buttons.save : resources.buttons.create}
        </Button>
      </Form>
    );
  }
}

const NewsForm = withFormik<
  INewsFormProps & IDefaultProps & IUpdateObjInArray & IAddObjToArray,
  INewsFormProps
>({
  mapPropsToValues: (props: INewsFormProps) => {
    return {
      title_en: props.title_en,
      title_ru: props.title_ru,
      body_en: props.body_en,
      body_ru: props.body_ru,
      id_news_type: props.id_news_type,
      id_product: props.id_product,
      is_home_page: Boolean(props.is_home_page) ? "1" : "0",
      date_from: props.date_from,
      date_to: props.date_to,
      id: props.id,
    };
  },

  validate: (values: INewsFormProps) => {
    const errors: FormikErrors<INewsFormProps> = {};

    if (!values.title_en) errors.title_en = resources.validation.nameIsRequired;
    else if (values.title_en.length > 191)
      errors.title_en = resources.validation.requiredMax191Characters;

    if (!values.title_ru) errors.title_ru = resources.validation.nameIsRequired;
    else if (values.title_ru.length > 191)
      errors.title_ru = resources.validation.requiredMax191Characters;

    if (!values.body_en) errors.body_en = resources.validation.required;
    else if (values.body_en.length > 191)
      errors.body_en = resources.validation.requiredMax191Characters;

    if (!values.body_ru) errors.body_ru = resources.validation.required;
    else if (values.body_ru.length > 191)
      errors.body_ru = resources.validation.requiredMax191Characters;

    if (!values.id_news_type)
      errors.id_news_type = resources.validation.nameIsRequired;

    if (!values.date_from)
      errors.date_from = resources.validation.nameIsRequired;

    if (!values.date_to) errors.date_to = resources.validation.nameIsRequired;

    return errors;
  },

  handleSubmit: (
    values: INewsFormProps,
    bag: FormikBag<
      INewsFormProps & IHideModal & IUpdateObjInArray & IAddObjToArray,
      INewsFormProps
    >
  ) => {
    values.id
      ? updateNewsAction(values, bag.props)
      : createNewsAction(values, bag.props);
  },
  validateOnChange: true,
  enableReinitialize: true,
})(NewsFormInner);

export default NewsForm;

const createNewsAction = (
  values: INewsFormProps,
  bag: INewsFormProps & IHideModal & IAddObjToArray
) => {
  const object: INewsCreate = {
    id_news_type: values.id_news_type!,
    id_product: values.id_product ? values.id_product : null,
    title_en: values.title_en!,
    title_ru: values.title_ru!,
    body_en: values.body_en!,
    body_ru: values.body_ru!,
    is_home_page: values.is_home_page === "1" ? true : false,
    date_from: moment(new Date(values.date_from!))
      .startOf("day")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString(),
    date_to: moment(new Date(values.date_to!))
      .endOf("day")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString(),
  };

  createNews(object).then((response) => {
    bag.addObjToArray!(response.data);
    bag.hideModal!();
  });
};

const updateNewsAction = (
  values: INewsFormProps,
  bag: INewsFormProps & IHideModal & IUpdateObjInArray
) => {
  const object: INewsUpdate = {
    id_news_type: values.id_news_type!,
    id_product: values.id_product ? values.id_product : null,
    title_en: values.title_en!,
    title_ru: values.title_ru!,
    body_en: values.body_en!,
    body_ru: values.body_ru!,
    is_home_page: values.is_home_page === "1" ? true : false,
    date_from: moment(new Date(values.date_from!))
      .startOf("day")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString(),
    date_to: moment(new Date(values.date_to!))
      .endOf("day")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString(),
  };

  updateNews(object, Number(values.id)).then(() => {
    bag.updateObjInArray!(values);
    bag.hideModal!();
  });
};
