import React, { Component } from 'react';
import styles from '../../lists/styles/OrdersList.module.scss';
import { Checkbox } from 'antd';
import { getPossibleStatuses } from '../../../../../helpers/OrderStatusHelper';
import { IDictionaryValue } from '../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IOrder } from '../../../interfaces/IOrder';
import { IOrderStatusesMap } from '../../../../../helpers/interfaces/IOrderStatusesMap';
import { OrderActions } from '../orderActions/OrderActions';
import { OrderDetails } from '../orderDetails/OrderDetails';
import { OrderInfo } from '../orderInfo/OrderInfo';
import { QrStatus } from '../qrStatus/QrStatus';
import { StatusesHistory } from '../statusesHistory/StatusesHistory';


interface IProps {
    order: IOrder;
    productsD: IDictionaryValue[];
    loading?: boolean;
    onOrderSelected: (orderIds: number) => void;
    selected: boolean;
    statusesMap: IOrderStatusesMap;
    onStatusChange: (orderId: number, statusId: number, comment: string) => Promise<any>;
}

export class OrderInformations extends Component<IProps> {
    state = {
        showDetails: false,
        loading: false,
    }

    showDetailsHandler = () => {
        this.setState({ showDetails: !this.state.showDetails });
    }

    render() {
        const order: IOrder = this.props.order;
        const { qr_print, qr_scan } = this.props.order;
        const possibleStatuses = getPossibleStatuses([order], this.props.statusesMap)
            .sort((a, b) => a.id - b.id)
        return (
            <>
                <section style={{ width: '100%' }}>
                    <div className={styles.grid_col_6}>
                        {order.histories.length > 0 ?
                        <Checkbox
                            onChange={() => this.props.onOrderSelected(this.props.order.id)}
                            checked={this.props.selected}
                        /> : <div></div>}
                        <QrStatus
                            printed={qr_print != null && qr_print > 0}
                            scaned={qr_scan != null && qr_scan > 0}
                        />
                        <OrderInfo
                            order={order}
                            productsD={this.props.productsD}
                        />
                        <OrderActions
                            orderId={order.id}
                            possibleStatuses={possibleStatuses}
                            onDetailsShow={this.showDetailsHandler}
                            showDetails={this.state.showDetails}
                            onStatusChange={this.props.onStatusChange}
                        />
                    </div>
                    <label><b>Comments: </b> {order.note}</label>
                    <StatusesHistory
                        possibleStatuses={possibleStatuses}
                        statuses={order.histories}
                    />
                    {this.state.showDetails &&
                        <OrderDetails 
                            order={order}
                        />
                    }
                </section>
            </>
        );
    }
}