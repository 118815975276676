import React, { Component } from 'react';
import styles from './LeadsScrollingList.module.scss';
import { LeadWithFccId } from '../leadWithFccId/LeadWithFccId';
import CreateNewOrderContext from '../../../../contexts/createNewOrderContext';

export class LeadsScrollingList extends Component {
    render() {
        return (
            <CreateNewOrderContext.Consumer>
                {
                    value => {
                        return <div className={styles.list}>
                            {
                                value.loading ?
                                    <div className={styles.loaderContainer}>
                                        <div className={styles.loader}></div>
                                    </div>
                                    :
                                    (
                                        value.leadsList.length > 0 ?
                                            value.leadsList.map(lead => {
                                                return (
                                                    <LeadWithFccId key={lead.id} data={lead} handleSelect={value.chooseLead} checked={value.selectedLeadId === lead.id} />
                                                )
                                            })
                                            :
                                            value.fcc_id ? <p>no lead for this fcc id</p> : <p></p>
                                    )
                            }
                        </div>
                    }
                }
            </CreateNewOrderContext.Consumer>
        )
    }
}
