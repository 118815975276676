import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const provincesQuery = 'SELECT id, id as "key", name, country_id, name_eng, deleted_at, created_at, updated_at FROM li_provinces';
const provincesListQuery = 'SELECT id, name, country_id FROM li_provinces';

export const getProvincesDictionaryList = () => axios.post(sourceUrl, { data: { sql: provincesQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getProvincesDictionaryList');
        return [];
    });

export const getProvincesListD = () => axios.post(sourceUrl, { data: { sql: provincesListQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getProvincesListD');
        return [];
    });

export const updateProvincesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateProvincesDictionary');
        return false;
    });

export const createProvincesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createProvincesDictionary');
        return false;
    });

export const deleteProvincesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createProvincesDictionary');
        return false;
    });