import React from 'react';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button, Tooltip } from 'antd';
import { copyClipboard } from '../../../../helpers/CopyHelpers';
import { Form, FormikProps, withFormik } from 'formik';
import { IUserToken } from './interfaces/IUserToken';
import { Left } from '../../../../components/controls/left/Left';

export class ApiTokenFormInner extends React.Component<IUserToken & FormikProps<IUserToken>> {
    public render() {
        return (
            <Form className='customAnt--registrationLinkForm'>
                <div className='grid'>
                    <Left>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='user_token'
                                label='API Token'
                                labelTextAlignLeft
                                boldLabel
                                allowClear
                                disabled
                            />
                        </div>
                    </Left>
                    <div>
                        <Tooltip title='Copy link'>
                            <Button
                                icon='copy'
                                className='copy_button'
                                onClick={(event: any) => { copyClipboard(event, this.props.values.user_token) }}
                            />
                        </Tooltip>
                    </div>
                </div>
            </Form>
        );
    };
}

const ApiTokenForm = withFormik<IUserToken, IUserToken>({
    mapPropsToValues: (props: IUserToken) => {
        return {
            user_token: props.user_token ? props.user_token : ''
        };
    },

    validate: () => { },
    handleSubmit: () => { },
    enableReinitialize: true
})(ApiTokenFormInner);

export default ApiTokenForm;