import axios from "axios";
import { resourceDef } from "../common/ResourceDef";
import { openErrorNotification } from "../helpers/NotificationHelper";
import { createGatewayAbsoluteUrl } from "../helpers/UrlCreator";
import { store } from "../store";

const tablesSettingsUrl = resourceDef.Tables;

type SuccessResponseGetSettings = {
  columnOrder: string[];
};
export const getTablesSettings =
  async (): Promise<SuccessResponseGetSettings> => {
    const userId = store.getState().commonState.appUserInfo.id;
    return axios
      .get(createGatewayAbsoluteUrl(tablesSettingsUrl), { params: { userId } })
      .then((response) => response.data.settings)
      .catch(() => openErrorNotification("Can't get tables settings"));
  };

type SuccessResponseSetOrder = {
  msg: string;
  success: true;
};
export const setTablesOrder = async (
  columnOrder: string[]
): Promise<SuccessResponseSetOrder> => {
  const userId = store.getState().commonState.appUserInfo.id;
  return axios
    .post(
      createGatewayAbsoluteUrl(tablesSettingsUrl),
      { columnOrder },
      { params: { userId } }
    )
    .then((response) => response.data)
    .catch(() => openErrorNotification("Can't set table columns order"));
};
