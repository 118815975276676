import * as React from 'react';
import styles from './styles/AuthenticationContainer.module.scss';
import { LogoAsLinkToLandingPage } from '../../logoAsLinkToLandingPage/LogoAsLinkToLandingPage';

export const AuthenticationContainer = (props: any) => {
    return (
        <section className={styles.grid}>
            <LogoAsLinkToLandingPage />
            {props.children}
        </section>
    );
}