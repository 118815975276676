import { useEffect } from "react";
import { useMutation } from "react-query";

import { getLeadsStatistics } from "../../../../services/m55_leads_statistics/LeadsStatisticsService";
import { createApiFiltersData } from "../filters-data";
import { SET_LOADING, UPDATE_TABLE_DATA, useTable } from "../table-state";

export const useStatisticMutation = () => {
  const { dispatch } = useTable();
  const statisticMutation = useMutation(
    (filters: any) => getLeadsStatistics(filters),
    {
      onSuccess: (data) =>
        dispatch({ type: UPDATE_TABLE_DATA, payload: { statisticData: data } }),
    }
  );

  useEffect(() => {
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoadingData: statisticMutation.isLoading,
      },
    });
  }, [dispatch, statisticMutation.isLoading]);

  return (filters: any) => {
    const filtersData = createApiFiltersData(filters);
    statisticMutation.mutate(filtersData);
  };
};
