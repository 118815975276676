import React, { createContext, useReducer } from "react";
import { Moment } from "moment";

import { INITIAL_DATE_RANGE } from "../filters-data";
import {
  GROUP_BY_OPTIONS,
  GROUP_BY_PICKER_MAP,
} from "../table-data/group-by-data";

export const RESET_FILTERS = "RESET_FILTERS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_QTY_FILTER_ENTITIES = "UPDATE_QTY_FILTER_ENTITIES";

export type FilterItem = {
  name: string;
  value: number | string | boolean | undefined;
  filter: string;
};

type Action = {
  type: string;
  payload?: {
    [key: string]:
      | FilterItem
      | FilterItem[]
      | Moment
      | number
      | string
      | null
      | boolean
      | undefined;
  };
};
type Dispatch = (action: Action) => void;
export type FiltersType = {
  status: FilterItem[];
  groupBy: string;
  endDate: Moment;
  timezone: number;
  countryId: FilterItem | undefined;
  productId: FilterItem[];
  partnerId: FilterItem[];
  api_pub_id: FilterItem[];
  api_lp_url: FilterItem[];
  companyId: FilterItem[];
  startDate: Moment;
  isContacted: FilterItem | undefined;
  isQualified: FilterItem | undefined;
  payoutScheme: FilterItem | undefined;
  trafficSource: FilterItem[];

  pickerMode: string;
  qtyFilterEntities: {
    [key: string]: number;
  };
};

const FiltersContext = createContext<{
  state: FiltersType;
  dispatch: Dispatch;
} | null>(null);

const filtersReducer = (state: FiltersType, action: Action) => {
  switch (action.type) {
  case UPDATE_FILTERS: {
    return Object.assign({}, state, action.payload);
  }
  case RESET_FILTERS: {
    return Object.assign({}, initialFiltersState);
  }
  case UPDATE_QTY_FILTER_ENTITIES: {
    return Object.assign({}, state, {
      qtyFilterEntities: {
        ...state.qtyFilterEntities,
        [action.payload?.filter as string]: action.payload?.qty,
      },
    });
  }
  default: {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  }
};

type FiltersProviderProps = { children: React.ReactNode };

export const initialFiltersState = Object.freeze({
  status: [],
  timezone: 20,
  endDate: INITIAL_DATE_RANGE.endDate,
  groupBy: GROUP_BY_OPTIONS.day,
  startDate: INITIAL_DATE_RANGE.startDate,
  api_pub_id: [],
  api_lp_url: [],
  productId: [],
  partnerId: [],
  companyId: [],
  countryId: undefined,
  isContacted: undefined,
  isQualified: undefined,
  payoutScheme: undefined,
  trafficSource: [],

  pickerMode: GROUP_BY_PICKER_MAP[GROUP_BY_OPTIONS.day],
  qtyFilterEntities: {
    api_pub_id: 0,
    api_lp_url: 0,
    status: 0,
    productId: 0,
    partnerId: 0,
    companyId: 0,
    trafficSource: 0,
  },
});

const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const [state, dispatch] = useReducer(filtersReducer, initialFiltersState);
  return (
    <FiltersContext.Provider value={{ state, dispatch }}>
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => {
  const context = React.useContext(FiltersContext);
  if (!context) {
    throw new Error("useFilters must be used within the Filters Provider");
  }
  return context;
};

export { FiltersProvider, useFilters };
