import * as React from "react";
import { DataTable } from "../../../../../components/others/data/DataTable";
import { resources } from "../../../../../common/Resources";
import { spanWithTitle } from "../../../../../components/others/SpanWithTitle";
import { Table, Tooltip, Typography } from "antd";
import InformationModalDialog from "../../../../../components/others/modal/InformationModalDialog";
import {
  findInDictionaryAndReturnValue,
  findStringInDictionaryAndReturnValue,
} from "../../../../../helpers/DisctionariesHelper";
import ProtectedModal from "../../../../../components/authorizations/protectedComponents/ProtectedModal";
import { UserPermissionType } from "../../../../../models/IAppUserInfoModel";
import PayoutAccessForm from "../forms/PayoutAccessForm";
import {
  getPayoutAccessVisibilityF,
  getPaymentSchemeFilters,
} from "../../../../../common/dictionaries/Filters";

const { Column } = Table;
const { Paragraph } = Typography;

export class PayoutAccessTable extends DataTable {
  render() {
    const columns = [
      <Column
        title={resources.columnTitle.id}
        dataIndex="product_id"
        key="product_id"
        align="center"
        width={90}
        sorter={(a, b) => a.product_id - b.product_id}
        {...this.getColumnSearchProps("product_id")}
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.product_sku,
          resources.columnTitle.product_sku_short
        )}
        key="product_sku"
        align="center"
        width={250}
        {...this.getColumnSearchProps("product_sku")}
        sorter={(a, b) =>
          (a.product_sku ? a.product_sku : "").localeCompare(
            b.product_sku ? b.product_sku : ""
          )
        }
        render={(record) => (
          <Tooltip
            title={
              <Paragraph
                style={{ color: "white", textAlign: "center" }}
                copyable
              >
                {record.product_sku}
              </Paragraph>
            }
          >
            <label className="marginLeft">
              {record.product_sku !== null && record.product_sku.length > 28
                ? record.product_sku.substring(0, 25) + "..."
                : record.product_sku}
            </label>
          </Tooltip>
        )}
      />,
      <Column
        title={resources.columnTitle.name}
        dataIndex="name"
        key="name"
        align="center"
        width={250}
        sorter={(a, b) => a.name.localeCompare(b.name)}
        {...this.getColumnSearchProps("name")}
      />,
      <Column
        title={resources.columnTitle.country}
        key="country_id"
        align="center"
        width={130}
        sorter={(a, b) => a.country_id - b.country_id}
        render={(record) =>
          findInDictionaryAndReturnValue(
            Number(record.country_id),
            this.props.countriesNameD
          )
        }
      />,
      <Column
        title="Guaranteed approve"
        key="guaranteed_approve"
        align="center"
        width={130}
        render={(record) => (record.guaranteed_approve ? "YES" : "NO")}
      />,
      <Column
        title="CPA [ $ ]"
        key="payout_cpa"
        align="center"
        width={120}
        sorter={(a, b) => a.payout_cpa - b.payout_cpa}
        {...this.getColumnSearchProps("payout_cpa")}
        render={(record) => (record.payout_cpa ? record.payout_cpa / 100 : 0)}
      />,
      <Column
        title="CPL [ $ ]"
        key="payout_cpl"
        align="center"
        width={120}
        sorter={(a, b) => a.payout_cpl - b.payout_cpl}
        {...this.getColumnSearchProps("payout_cpl")}
        render={(record) => (record.payout_cpl ? record.payout_cpl / 100 : 0)}
      />,
      <Column
        title="GA value"
        key="ga_value"
        align="center"
        width={130}
        render={(record) => (record.ga_value ? record.ga_value : "-")}
      />,
      <Column
        title="Visibility"
        key="is_visible"
        align="center"
        width={120}
        sorter={(a, b) => a.is_visible - b.is_visible}
        filters={getPayoutAccessVisibilityF()}
        onFilter={(value, record) => record.is_visible === value}
        render={(record) => this.displayVisibility(record.is_visible)}
      />,
      <Column
        title={resources.columnTitle.scheme}
        key="payout_scheme"
        align="center"
        width={120}
        filters={getPaymentSchemeFilters()}
        onFilter={(value, record) => record.payout_scheme === value}
        sorter={(a, b) =>
          (a.payout_scheme ? a.payout_scheme : "").localeCompare(
            b.payout_scheme ? b.payout_scheme : ""
          )
        }
        render={(record) =>
          findStringInDictionaryAndReturnValue(
            record.payout_scheme,
            this.props.payoutSchemaD
          )
        }
      />,
      <Column
        title={resources.columnTitle.options}
        key="options"
        width={60}
        align="center"
        fixed="right"
        render={(record) => (
          <>
            <ProtectedModal
              permission={UserPermissionType.EditUserAdministrationModalButton}
            >
              <InformationModalDialog
                modalTitle={resources.modalTitle.edit}
                buttonTooltip={resources.modalButtonsTitle.edit}
                icon="edit"
                disabled={this.props.loading || record.deleted_at}
                withHideMethod={true}
                width={800}
              >
                <PayoutAccessForm
                  {...this.props.data.find(
                    (item) => item.product_id === record.product_id
                  )}
                  user_id={this.props.user_id}
                  countryNameD={this.props.countriesNameD}
                />
              </InformationModalDialog>
            </ProtectedModal>
          </>
        )}
      />,
    ];

    return (
      <DataTable
        tableName="AppUsersTable"
        columns={columns}
        data={this.props.data}
        loading={this.props.loading}
        pagination={true}
        columnNameToCheck="deleted_at"
        valueToCheck={null}
      />
    );
  }

  displayVisibility = (visibility) => {
    switch (visibility) {
      case 2:
        return "Default";
      case 1:
        return "Visible";
      default:
        return "Hidden";
    }
  };
}
