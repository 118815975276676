import * as Yup from 'yup';
import AddressInformation from '../addressInformation/AddressInformation';
import addressInformationInitialValues from '../addressInformation/AddressInformationInitialValues';
import CourierInformation from '../courierInformation/CourierInformation';
import courierInformationInitialValues from '../courierInformation/CourierInformationInitialValues';
import courierValidation from '../courierInformation/validation';
import CustomerInformation from '../customerInformation/CustomerInformation';
import customerInformationInitialValues from '../customerInformation/CustomerInformationInitialValues';
import customerValidation from '../customerInformation/validation';
import getAddressValidation from '../addressInformation/validation';
import OrderDescription from '../orderDescription/OrderDescription';
import orderDescriptionInitialValeus from '../orderDescription/OrderDescriptionInitialValues';
import orderDescriptionValidation from '../orderDescription/validation';
import PaymentInformation from '../paymentInformation/PaymentInformation';
import paymentInformationInitialValues from '../paymentInformation/PaymentInformationInitialValues';
import paymentInformationValidation from '../paymentInformation/validation';
import React from 'react';
import styles from '../../../contents/styles/CreateNewOrderContent.module.scss';
import { Button } from 'antd';
import {
    Form,
    FormikBag,
    FormikProps,
    withFormik
    } from 'formik';
import { FormSectionHeader } from '../../formSectionHeader/FormSectionHeader';
import { getCourierInfo } from '../../../../../services/LocationService';
import { ICountry } from '../../../../../common/interfaces/ICountry';
import { ICustomerInfoFromLead } from './ICustomerInfoFromLead';
import { INewOrderForm, INewOrderSubmit } from './INewOrderForm';
import { openErrorNotification, openSuccessNotification } from '../../../../../helpers/NotificationHelper';
import { Right } from '../../../../../components/controls/right/Right';


export class NewOrderFormTemplate extends React.Component<ICountry & ICustomerInfoFromLead & INewOrderSubmit & FormikProps<INewOrderForm>> {

    addressChangeHandler = (locationId: number): void => {
        const courierInfo = getCourierInfo(locationId);
        this.props.setFieldValue('courierName', courierInfo.courierName);
        this.props.setFieldValue('destinationCode', courierInfo.destinationCode);
        this.props.setFieldValue('leadTime', courierInfo.leadTime);
        this.props.setFieldValue('sourceWarehouse', courierInfo.sourceWarehouse);
        this.props.setFieldValue('locationId', locationId);
    }

    render() {
        return (
            <Form>
                <section className={`${styles.columns}`}>
                    <div className={`${styles.column} ${styles.column__mr20}`}>
                        <div className={styles.column__box}>
                            <FormSectionHeader>Customer Information</FormSectionHeader>
                            <CustomerInformation {...this.props} />
                        </div>
                        <div className={styles.column__box}>
                            <FormSectionHeader>Order Description</FormSectionHeader>
                            <OrderDescription {...this.props} />
                        </div>
                        <div className={styles.column__box}>
                            <PaymentInformation {...this.props} />
                        </div>
                    </div>
                    <div className={`${styles.column}`}>
                        <div className={styles.column__box}>
                            <FormSectionHeader>Address Information for {this.props.countryName}</FormSectionHeader>
                            <AddressInformation {...this.props} onAddressChange={this.addressChangeHandler}/>
                        </div>
                        <div className={styles.column__box}>
                            <FormSectionHeader>Courier Information</FormSectionHeader>
                            <CourierInformation {...this.props} />
                        </div>
                    </div>
                </section>
                <Right>
                    <Button
                        title={this.props.isSubmitting ? 'Creating order...' : 'Create'}
                        className={styles.addButton}
                        disabled={this.props.isSubmitting}
                        type='primary'
                        htmlType='submit'
                    >
                        {this.props.isSubmitting ? 'Creating order...' : 'Create'}
                    </Button>
                </Right>
            </Form >
        );
    }

}

const validationSchema = {
    ...customerValidation,
    ...orderDescriptionValidation,
    ...paymentInformationValidation,
    ...courierValidation,
};

export const NewOrderForm = withFormik<ICountry & ICustomerInfoFromLead & INewOrderSubmit, INewOrderForm>({
    enableReinitialize: true,

    mapPropsToValues: (props) => {
        const propsObject = {
            ...customerInformationInitialValues,
            ...orderDescriptionInitialValeus,
            ...courierInformationInitialValues,
            ...addressInformationInitialValues,
            ...paymentInformationInitialValues,
        };
        if (props.name && props.phone) {
            // Set customer info received in lead
            propsObject.name = props.name;
            propsObject.phone = props.phone;
            if (props.phone2) {
                propsObject.phone2 = props.phone2;
            }
        }
        return propsObject;
    },

    validationSchema: (props: ICountry & ICustomerInfoFromLead & INewOrderForm) => Yup.object().shape({
        ...validationSchema,
        ...getAddressValidation(props.countryId),
    }),

    handleSubmit: async (values: INewOrderForm, bag: FormikBag<ICountry & ICustomerInfoFromLead & INewOrderSubmit, INewOrderForm>) => {
        bag.setSubmitting(true);
        bag.props.onSubmit(values).then(() => {
            bag.resetForm();
            openSuccessNotification('Order created!')
        }).catch((errMsg) => {
            openErrorNotification(errMsg);
        }).finally(() => {
            bag.setSubmitting(false);
        });
    }

})(NewOrderFormTemplate);
