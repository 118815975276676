import * as React from "react";
import moment from "moment";
import { Select } from "antd";

import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import PartnersForm from "../forms/PartnersForm";
import ProtectedModal from "../../../components/authorizations/protectedComponents/ProtectedModal";
import { ClearBoth } from "../../../components/controls/clearBoth/ClearBoth";
import {
  denyPartner,
  grantPartner,
} from "../../../services/m29_users_management/PartnerService";
import { getCompaniesListD } from "../../../services/m29_users_management/CompaniesService";
import { getDictionary } from "../../../helpers/DisctionariesHelper";
import { getUsersList } from "../../../services/m29_users_management/GeneralUserService";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IId } from "../../../common/interfaces/IId";
import { IUser } from "../../../models/IUsers";
import { openSuccessNotification } from "../../../helpers/NotificationHelper";
import { PageInf } from "../../../components/common/pageInf/PageInf";
import { PartnersTable } from "../tables/PartnersTable";
import { RefreshTableButton } from "../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../common/Resources";
import { Right } from "../../../components/controls/right/Right";
import { TableContainer } from "../../../components/containers/tableContainer/TableContainer";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";

const { Option } = Select;

const ACTIVE_FILTER = Object.freeze({
  ACTIVE: "active",
  INACTIVE: "inactive",
  AWAITING_ACTIVATION: "awaiting_activation",
});

const ACTIVE_FILTER_DATA = Object.freeze({
  [ACTIVE_FILTER.ACTIVE]: { is_activated: "1" },
  [ACTIVE_FILTER.INACTIVE]: {
    is_activated: "0",
    dateTo: moment().subtract(30, "days").utcOffset(0, true).format(),
  },
  [ACTIVE_FILTER.AWAITING_ACTIVATION]: {
    is_activated: "0",
    dateFrom: moment().subtract(30, "days").utcOffset(0, true).format(),
  },
});

export class PartnersContent extends React.Component<IId> {
  _isMounted = false;

  state = {
    partnersList: Array<IUser>(),
    countriesNameD: Array<IDictionaryValue>(),
    managersD: Array<IDictionaryValue>(),
    companiesD: Array<IDictionaryValue>(),
    company_id: undefined,
    loading: false,
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.setState({
      countriesNameD: getDictionary("countriesNameD"),
      company_id: this.props.id,
    });

    this.getPartnersList();
    this.getAccountManagers();
    this.getCompaniesD();
  };

  componentWillUnmount = () => (this._isMounted = false);

  getAccountManagers = () => {
    const object = {
      columns: ["id", "name"],
      id_users_role: 7,
    };

    getUsersList(object).then((managersD) => {
      this._isMounted && this.setState({ managersD });
    });
  };

  getCompaniesD = () => {
    getCompaniesListD().then((companiesD) => {
      this._isMounted && this.setState({ companiesD });
    });
  };

  handlePartnersFiltration = (filter: string) => {
    this.getPartnersList(ACTIVE_FILTER_DATA[filter]);
  };

  public render() {
    const { partnersList, loading, managersD } = this.state;

    return (
      <TableContainer>
        <PageInf
          title={resources.pageTitle.partners}
          subTitle={this.getSubtitle(this.state.company_id)}
        />
        {!this.props.id && (
          <>
            <RefreshTableButton
              className="marginBottom"
              loading={this.state.loading}
              onClick={this.getPartnersList}
            />

            <Select
              style={{ width: "150px", marginLeft: "10px" }}
              onChange={this.handlePartnersFiltration}
              placeholder="Filter by active"
            >
              <Option value={ACTIVE_FILTER.ACTIVE}>Active</Option>
              <Option value={ACTIVE_FILTER.INACTIVE}>Inactive</Option>
              <Option value={ACTIVE_FILTER.AWAITING_ACTIVATION}>
                Awaiting activation
              </Option>
            </Select>

            <Right>
              <ProtectedModal
                permission={UserPermissionType.AddNewPartnerModalButton}
              >
                <InformationModalDialog
                  modalTitle={resources.title.addNewPartner}
                  buttonTitle={resources.modalButtonsTitle.addNewPartner}
                  buttonType="primary"
                  buttonTooltip={resources.title.addNewPartner}
                  icon={"user-add"}
                  disabled={this.state.loading}
                  withHideMethod={true}
                  width={800}
                  buttonClassName="addNew"
                >
                  <PartnersForm addObjToArray={this.addObjToArray} />
                </InformationModalDialog>
              </ProtectedModal>
            </Right>
            <ClearBoth />
            <PartnersTable
              data={partnersList}
              width={150}
              loading={loading}
              managers={managersD}
              tableName="Partners"
              countries={this.state.countriesNameD}
              companies={this.state.companiesD}
              acceptPartner={this.acceptPartner}
              rejectPartner={this.rejectPartner}
              updateObjInArray={this.updateObjInArray}
            />
          </>
        )}
      </TableContainer>
    );
  }

  getPartnersList = async (params: any = {}) => {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id",
        "email",
        "name",
        "second_name",
        "partner_token",
        "id_account_manager",
        "id_company",
        "skype",
        "telegram",
        "created_at",
        "is_activated",
        "id_country",
      ],
      id_users_role: 3,
      id_company: this.props.id,
      ...params,
    };

    await getUsersList(object)
      .then((response) => {
        if (response) {
          const partnersList = response.map((item: any) => {
            return { ...item, key: item.id };
          });

          this._isMounted && this.setState({ partnersList });
        }
      })
      .finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
  };

  acceptPartner = (id_partner: number) => {
    grantPartner(id_partner).then((response) => {
      if (response) {
        this.changeStatusInArray(id_partner);

        openSuccessNotification(resources.statement.accepted);
      }
    });
  };

  rejectPartner = (id_partner: number) => {
    denyPartner(id_partner).then((response) => {
      if (response) {
        this.deleteFromArray(id_partner);

        openSuccessNotification(resources.statement.rejected);
      }
    });
  };

  changeStatusInArray = (id: number) => {
    const { partnersList } = this.state;
    const index = partnersList.findIndex((x) => x.id === id);

    partnersList[index].is_activated = !partnersList[index].is_activated;

    this.setState({ partnersList });
  };

  addObjToArray = (partner: IUser) => {
    this.setState({ partnersList: [...this.state.partnersList, partner] });
  };

  updateObjInArray = (partner: IUser): void => {
    const { partnersList } = this.state;
    const index = partnersList.findIndex((x) => x.id === partner.id);

    partnersList[index].name = partner.name;
    partnersList[index].second_name = partner.second_name;
    partnersList[index].id_company = partner.id_company;
    partnersList[index].email = partner.email;
    partnersList[index].id_country = partner.id_country;
    partnersList[index].id_account_manager = partner.id_account_manager;
    partnersList[index].skype = partner.skype;
    partnersList[index].telegram = partner.telegram;

    this.setState({ partnersList });
  };

  deleteFromArray = (id_partner: number) => {
    const partnersList = this.state.partnersList.filter(
      (x) => x.id !== id_partner
    );
    this.setState({ partnersList });
  };

  getSubtitle = (company_id?: number) => {
    if (company_id) {
      const product = this.state.companiesD.filter(
        (x) => x.id === Number(company_id)
      );
      return product[0] ? product[0].name : undefined;
    }
  };
}
