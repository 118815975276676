import * as React from 'react';
import InformationModalDialog from '../../../../../components/others/modal/InformationModalDialog';
import styles from './styles/OrderActions.module.scss';
import { Button } from 'antd';
import { ChangeMultipleStatusesDialog } from '../changeMultipleStatusesDialog/ChangeMultipleStatusesDialog';
import { IOrderActions } from './interfaces/IOrderActions';
import { IOrderStatus } from '../../../interfaces/IOrderStatus';

// bit hacky - hardcoded IDs of statuses
enum STATUSES {
    CANCEL = 18,
    DELETE = 17,
}

export class OrderActions extends React.Component<IOrderActions> {

    render() {
        const { possibleStatuses, showDetails } = this.props;
        const possibleStatusesIds = new Set(possibleStatuses.map((status: IOrderStatus) => status.id));

        const disableCancelButton = !possibleStatusesIds.has(STATUSES.CANCEL);
        const disableDeleteButton = !possibleStatusesIds.has(STATUSES.DELETE);

        possibleStatusesIds.delete(STATUSES.CANCEL);
        possibleStatusesIds.delete(STATUSES.DELETE);
        return (
            <div className={styles.container}>
                <div className={styles.changeToStatusContainer}>
                    {possibleStatuses
                        .filter((status: IOrderStatus) => possibleStatusesIds.has(status.id))
                        .map((status: IOrderStatus) => <InformationModalDialog
                            key={status.id}
                            buttonTooltip={status.name}
                            buttonTitle={`Change status to "${status.name}"`}
                            buttonClassName={styles.button}
                            withHideMethod={true}
                            width={500}
                        >
                            <ChangeMultipleStatusesDialog
                                ordersIds={[this.props.orderId]}
                                targetStatusId={status.id}
                                onStatusChange={this.props.onStatusChange}
                            />
                        </InformationModalDialog>)}
                </div>
                <div className={styles.otherActionsContainer}>
                    <div className={styles.buttonRow}>
                        <Button className={styles.button}>Edit</Button>
                        <Button className={showDetails ? styles.activeButton : styles.button} onClick={this.props.onDetailsShow}>Details</Button>
                    </div>
                    <div className={styles.buttonRow}>
                        <InformationModalDialog
                            key={STATUSES.CANCEL}
                            buttonTooltip={'Cancel'}
                            buttonTitle={'Cancel'}
                            buttonClassName={styles.button}
                            withHideMethod={true}
                            disabled={disableCancelButton}
                            width={500}
                        >
                            <ChangeMultipleStatusesDialog
                                ordersIds={[this.props.orderId]}
                                targetStatusId={STATUSES.CANCEL}
                                onStatusChange={this.props.onStatusChange}
                            />
                        </InformationModalDialog>
                        <InformationModalDialog
                            key={STATUSES.DELETE}
                            buttonTooltip={'Delete'}
                            buttonTitle={'Delete'}
                            buttonClassName={styles.button}
                            withHideMethod={true}
                            disabled={disableDeleteButton}
                            width={500}
                        >
                            <ChangeMultipleStatusesDialog
                                ordersIds={[this.props.orderId]}
                                targetStatusId={STATUSES.DELETE}
                                onStatusChange={this.props.onStatusChange}
                            />
                        </InformationModalDialog>
                    </div>
                    <div className={styles.buttonRow}>
                        <Button className={styles.button}>Generate invoice</Button>
                    </div>
                    <div className={styles.buttonRow}>
                        <Button className={styles.button}>Print AWB (QR Code)</Button>
                    </div>
                </div>
            </div>
        )
    }
};