import * as React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { IDatePickerFProps } from './interfaces/IDatePickerFProps';
import { IOnChange } from '../../common/interfaces/IOnChange';

export class DatePickerF extends React.Component<IDatePickerFProps & IOnChange> {
    render() {
        const { name, label, required, value, error, onChange, allowClear, disabled, width } = this.props;

        return (
            <>
                {required ? <label style={{ color: 'red' }}> * </label> : null}
                {label ? <label htmlFor={name}>{label}: </label> : null}

                <DatePicker
                    allowClear={allowClear}
                    name={name}
                    onChange={(value) => onChange(name, value)}
                    value={value ? moment(new Date(value)) : undefined}
                    disabled={disabled}
                    style={{ width: width }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </>
        );
    }
}