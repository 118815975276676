import * as React from "react";
import styles from "./styles/JsonParams.module.scss";
import { Button } from "antd";
import { resources } from "../../../../common/Resources";

interface IJsonParams {
  jsonString: string;
}

export class JsonParams extends React.Component<IJsonParams> {
  _isMounted = false;
  state = {
    visible: false,
    error: false,
  };

  componentDidMount = () => {
    this._isMounted = true;
    try {
      JSON.parse(this.props.jsonString);
    } catch {
      this._isMounted &&
        this.setState({
          error: true,
        });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  toggle = () => {
    this._isMounted &&
      this.setState({
        visible: !this.state.visible,
      });
  };

  render() {
    const { visible, error } = this.state;
    if (error) {
      return <p>{resources.statement.noDataToDisplay}</p>;
    }
    if (!visible) {
      return <Button onClick={this.toggle}>{resources.buttons.show}</Button>;
    }
    const obj = JSON.parse(this.props.jsonString);
    return (
      <div className={styles.container}>
        <Button onClick={this.toggle}>{resources.buttons.hide}</Button>
        {Object.keys(obj).map((key, idx) => (
          <div className={styles.row} key={idx}>
            <div style={{ flex: "1", textAlign: "left" }}>{key}</div>
            <div style={{ flex: "0 0 100px", textAlign: "right" }}>
              {obj[key]}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
