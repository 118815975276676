import React, { Component } from 'react';
import styles from './CouriersStatusCreateForm.module.scss';
import { Button, Input, Select } from 'antd';
import {
    ICountry,
    ICourier,
    ICreateStatusObj,
    IStatus
    } from '../contents/CourierStatusMappingContent';
import { resources } from '../../../common/Resources';

interface ICouriersStatusCreateFormProps {
    countriesList: ICountry[];
    couriersList: ICourier[];
    statusesList: IStatus[];
    createNewStatus: (obj: ICreateStatusObj) => void;
    selectedCountry: number | undefined;
    selectedCourier: number | undefined;
}

interface ICouriersStatusCreateFormState {
    f_country?: number;
    f_courier?: number;
    f_status?: number;
    f_courier_status?: string;
    f_description?: string;
    errors: IErrors;
    filteredCouriersList: ICourier[];
    fields: string[];
}

interface IErrors {
    f_country: string;
    f_courier: string;
    f_status: string;
    f_courier_status: string;
}

export class CouriersStatusCreateForm extends Component<ICouriersStatusCreateFormProps, ICouriersStatusCreateFormState> {
    state = {
        f_country: undefined,
        f_courier: undefined,
        f_status: undefined,
        f_courier_status: undefined,
        f_description: undefined,
        errors: {
            f_country: '',
            f_courier: '',
            f_status: '',
            f_courier_status: '',
        },
        filteredCouriersList: [],
        fields: ['f_country', 'f_courier', 'f_status', 'f_courier_status'],
    }

    componentDidMount = () => {
        this.mapPropsTostate();

        this.props.selectedCountry !== undefined && this.filterCourierList(this.props.selectedCountry);
    }

    mapPropsTostate = () => {
        this.setState({
            f_country: this.props.selectedCountry ? this.props.selectedCountry : undefined,
            f_courier: this.props.selectedCountry && this.props.selectedCourier ? this.props.selectedCourier : undefined,
        })
    }

    selectCountry = (e: any) => {
        this.filterCourierList(e);
        this.setState({
            f_country: e,
            f_courier: undefined,
        })
    }

    selectCourier = (e: any) => {
        this.setState({
            f_courier: e
        })
    }

    selectStatus = (e: any) => {
        this.setState({
            f_status: e
        })
    }

    handleTextInputChange = (e: any) => {
        if (e.target.name === 'f_courier_status') {
            e.nativeEvent.data !== ' ' &&
                this.setState({
                    f_courier_status: e.target.value
                })
        } else if (e.target.name === 'f_description') {
            this.setState({
                f_description: e.target.value
            })
        }
    }

    filterCourierList = (countryId: number) => {
        const filtered = this.props.couriersList.filter((courier: ICourier) => {
            return courier.country_id === countryId
        })

        this.setState({
            filteredCouriersList: filtered
        })
    }

    validateFormElement = (fieldName: string) => {
        let errors = this.state.errors;

        switch (fieldName) {
            case 'f_country':
                if (this.state.f_country === '' || this.state.f_country === undefined) {
                    errors.f_country = 'This field is required';
                } else {
                    errors.f_country = '';
                }
                break;
            case 'f_courier':
                if (this.state.f_courier === '' || this.state.f_courier === undefined) {
                    errors.f_courier = 'This field is required';
                } else {
                    errors.f_courier = '';
                }
                break;
            case 'f_status':
                if (this.state.f_status === '' || this.state.f_status === undefined) {
                    errors.f_status = 'This field is required';
                } else {
                    errors.f_status = '';
                }
                break;
            case 'f_courier_status':
                if (this.state.f_courier_status === '' || this.state.f_courier_status === undefined) {
                    errors.f_courier_status = 'This field is required';
                } else {
                    errors.f_courier_status = '';
                }
                break;
        }

        this.setState({
            errors
        })
    }

    validateForm = () => {
        this.state.fields.forEach(el => {
            this.validateFormElement(el);
        });

        let notValid = Object.values(this.state.errors).filter((el: any) => {
            return el !== '';
        });

        const obj = {
            countryId: this.state.f_country,
            courier: this.state.f_courier,
            statusId: this.state.f_status,
            courierStatus: this.state.f_courier_status,
            description: this.state.f_description,
            notes: '',
        }

        notValid.length === 0 && this.props.createNewStatus(obj);
    }

    render() {
        return (
            <>
                <h3 className={styles.header}>Create new mapping</h3>
                <form className={styles.form}>
                    <section className={`${styles.wrapper} ${styles.wrapper__col}`}>

                        <div className={styles.item33}>
                            <label className={`${styles.tbLeftRadius} ${styles.label2}`}>Country</label>
                            <Select
                                placeholder={resources.select.select_country}
                                value={this.state.f_country}
                                onSelect={this.selectCountry}
                            >
                                {this.props.countriesList.map((country: ICountry) => {
                                    return <Select.Option
                                        key={country.id}
                                        value={country.id}
                                    >
                                        {country.name}
                                    </Select.Option>
                                })}
                            </Select>
                            <p className={styles.errorMessage} style={{ opacity: this.state.errors.f_country === '' ? 0 : 1 }}>{this.state.errors.f_country}</p>
                        </div>
                        <div className={styles.item33} style={{ marginLeft: '15px' }}>
                            <label className={`${styles.tbRightRadius} ${styles.label2}`}>Courier</label>
                            <Select
                                placeholder='Select courier'
                                value={this.state.f_courier}
                                onSelect={this.selectCourier}
                            >
                                {this.state.filteredCouriersList.map((courier: ICourier) => {
                                    return <Select.Option
                                        key={courier.id}
                                        value={courier.name}
                                    >
                                        {courier.name}
                                    </Select.Option>
                                })}
                            </Select>
                            <p className={styles.errorMessage} style={{ opacity: this.state.errors.f_courier === '' ? 0 : 1 }}>{this.state.errors.f_courier}</p>
                        </div>
                    </section>
                    <section className={styles.wrapper}>
                        <div className={styles.item33}>
                            <label className={`${styles.tbLeftRadius} ${styles.label}`}>eFirst</label>
                            <Select
                                placeholder='Select status'
                                value={this.state.f_status}
                                onSelect={this.selectStatus}
                            >
                                {this.props.statusesList.map((status: IStatus) => {
                                    return <Select.Option
                                        key={status.id}
                                        value={status.id}
                                    >
                                        {status.name}
                                    </Select.Option>
                                })}
                            </Select>
                            <p className={styles.errorMessage} style={{ opacity: this.state.errors.f_status === '' ? 0 : 1 }}>{this.state.errors.f_status}</p>
                        </div>
                        <div className={styles.item33}>
                            <label className={styles.label}>Courier status</label>
                            <Input
                                name='f_courier_status'
                                value={this.state.f_courier_status}
                                onChange={this.handleTextInputChange}
                                placeholder='Courier status'
                            />
                            <p className={styles.errorMessage} style={{ opacity: this.state.errors.f_courier_status === '' ? 0 : 1 }}>{this.state.errors.f_courier_status}</p>
                        </div>
                        <div className={styles.item33}>
                            <label className={`${styles.tbRightRadius} ${styles.label}`}>Description</label>
                            <Input
                                name='f_description'
                                value={this.state.f_description}
                                onChange={this.handleTextInputChange}
                                placeholder='Description'
                            />
                        </div>
                    </section>
                    <Button onClick={this.validateForm}>Save</Button>
                </form>
            </>
        )
    }
}

