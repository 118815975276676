import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { Table, Button, Tooltip } from 'antd';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../helpers/DisctionariesHelper';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

const { Column } = Table;

export class RolePermissionsTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id_permission'
                key='id_permission'
                align='center'
                width='70px'
                sorter={(a, b) => a.id_permission - b.id_permission}
                {...this.getColumnSearchProps('id_permission')}
            />,
            <Column
                title={resources.columnTitle.name}
                key='name'
                align='center'
                width='300px'
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_permission, this.props.permissionsInf)
                )}
            />,
            <Column
                title={resources.columnTitle.view}
                key='id_permission_view'
                align='center'
                width='200px'
                sorter={(a, b) => a.id_permission_view - b.id_permission_view}
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_permission_view, this.props.viewsD)
                )}
            />,
            <Column
                title={resources.columnTitle.type}
                key='id_permission_type'
                align='center'
                width='120px'
                sorter={(a, b) => a.id_permission_type - b.id_permission_type}
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_permission_type, this.props.typesD)
                )}
            />,
            <Column
                title={resources.columnTitle.description}
                key='description'
                align='center'
                width='400px'
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_permission, this.getPermissionsDescriptionsD())
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='200px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='70px'
                align='center'
                fixed='right'
                render={(record) => (
                    <ProtectedComponent permission={UserPermissionType.ChangePermissionStatusAdministrationButton}>
                        <Tooltip placement='rightTop' title={resources.statement.changePermissionStatus}>
                            <Button
                                value={record.id}
                                onClick={() => this.props.changeStatus(record.id, record.is_active)}
                                icon={record.is_active ? "check" : "close"}
                                style={{ color: record.is_active ? 'green' : 'red' }}
                            />
                        </Tooltip>
                    </ProtectedComponent>
                )}
            />
        ];

        return (
            <DataTable
                tableName='AppUsersPermissionsTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }

    getPermissionsDescriptionsD = () => {
        let list = [];

        this.props.permissionsInf.map(item => {
            list = [...list, { id: item.id, name: item.description }];
            return null;
        });

        return list;
    }
}