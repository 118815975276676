import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { resourceDef } from '../../common/ResourceDef';

const createUrl = createGatewayAbsoluteUrl(resourceDef.SupportCreate);

export const sendSupportMessage = (object: any) => axios.put(createUrl, { ...object })
    .then(() => { return true; })
    .catch(error => {
        console.log('sendSupportMessage M61');
        console.log(error.response);
        return false;
    });