import * as React from "react";
import styles from "./ToolsContent.module.scss";
import { resources } from "../../../common/Resources";
import { Row } from "antd";
import { Tool } from "../components/tool/Tool";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";

const longpageDocumentationLink =
  "https://drive.google.com/drive/folders/1mxgeWAER0B10v1n1GOwjBP0qP7FADyqR";
const apiDocumentationLink =
  "https://docs.google.com/document/d/1z6i7C-KokkFQbz0zrfEGYpbRhnNRlw5PipJPJxrGhmA/edit";
const faqLink = "/faq";
const inProgressLink = "/in-progress";
const verificationToolLink = "/verification-tool";

export class ToolsContent extends React.Component {
  public render() {
    return (
      <>
        <header>
          <ViewHeader title={resources.toolsView.tools} />
        </header>
        <section className={styles.tools}>
          <Tool
            title={resources.toolsView.faq}
            description={resources.toolsView.answersToFAQ}
            buttonText={resources.toolsView.readMore}
            hyperlink={faqLink}
          ></Tool>
          <Tool
            title={resources.toolsView.apiDocs}
            description={resources.toolsView.readOurDocs}
            buttonText={resources.toolsView.readDocs}
            hyperlink={apiDocumentationLink}
          ></Tool>
          <Tool
            title={resources.toolsView.postbackDocs}
            description={resources.toolsView.postbacksNotify}
            buttonText={resources.toolsView.readDocs}
            hyperlink={inProgressLink}
          ></Tool>
          <Tool
            title={resources.toolsView.integrationArchiModel}
            description={resources.toolsView.hardToUnderstand}
            buttonText={resources.toolsView.readMore}
            hyperlink={inProgressLink}
          ></Tool>
          <Tool
            title={resources.toolsView.numberValidation}
            description={resources.toolsView.checkingTheNumber}
            buttonText={resources.toolsView.open as string}
            hyperlink={verificationToolLink}
          ></Tool>
          <Tool
            title={resources.toolsView.longpageFromWidget}
            description={resources.toolsView.longpageFromWidgetDescription}
            buttonText={resources.toolsView.readMore}
            hyperlink={longpageDocumentationLink}
          ></Tool>
        </section>
        <Row></Row>
      </>
    );
  }
}
