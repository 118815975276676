import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getFinanceList = createGatewayAbsoluteUrl(resourceDef.FinanceGet);
const getTotalBalanceUrl = createGatewayAbsoluteUrl(resourceDef.TotalBalanceGet);
const getAcceptedBalanceUrl = createGatewayAbsoluteUrl(resourceDef.AcceptedBalanceGet);

export const getFinance = (data: any) => axios.post(getFinanceList, { ...data })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getFinance');
        return [];
    });

export const getTotalBalance = (value?: string) => axios.get(getTotalBalanceUrl + value)
    .then((response) => { return response.data.balance; })
    .catch(() => {
        openErrorNotification(' getTotalBalance');
        return 0;
    });

export const getBalance = (data?: any) => axios.post(getAcceptedBalanceUrl, { ...data })
    .then((response) => { return response.data.balance; })
    .catch(() => {
        openErrorNotification(' getActualBalance');
        return { withdrawals: 0, deposits: 0 };
    });