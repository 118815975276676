import React from "react";
import { DatePicker } from "antd";
import { Moment } from "moment";
import { RangePickerValue } from "antd/lib/date-picker/interface";

import { PICKER_MODE_FORMAT_MAP } from "../../../lib/table-data/group-by-data";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

const { RangePicker } = DatePicker;

export const DateFilter = () => {
  const { state, dispatch } = useFilters();

  const onChangeDate = (newDates: RangePickerValue | undefined) => {
    const [startDate, endDate] = newDates as [Moment, Moment];
    dispatch({
      type: UPDATE_FILTERS,
      payload: { startDate, endDate },
    });
  };

  return (
    <RangePicker
      mode={[state.pickerMode, state.pickerMode]}
      size="small"
      value={[state.startDate, state.endDate]}
      format={PICKER_MODE_FORMAT_MAP[state.pickerMode]}
      onChange={onChangeDate}
      className="statistic-date-filter"
      onPanelChange={onChangeDate}
    />
  );
};
