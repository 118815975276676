import DatePickerF from '../../../../../../../components/templateForm/form/datePicker/DatePickerF';
import DictionaryF from '../../../../../../../components/templateForm/form/dropDown/DictionaryF';
import ProtectedComponent from '../../../../../../../components/authorizations/protectedComponents/ProtectedComponent';
import React from 'react';
import styles from '../../styles/ProductPricing.module.scss';
import TextInputF from '../../../../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createProductPricings, updateProductPricings } from '../../../../../../../services/m30_products/pricings/ProductPricingsService';
import { findInDictionaryAndReturnValue } from '../../../../../../../helpers/DisctionariesHelper';
import {
    Form,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { formStatusClassName } from '../../../../../../../helpers/StylesHelper';
import { IProductPriceForm } from './interfaces/IProductPriceForm';
import { IProductPriceFormDefaultProps } from './interfaces/IProductPriceFormDefaultProps';
import { IProductPriceFormMethods } from './interfaces/IProductPriceFormMethods';
import { IProductPricingCreate } from '../../../../../../../services/m30_products/pricings/interfaces/IProductPricingCreate';
import { IProductPricingUpdate } from '../../../../../../../services/m30_products/pricings/interfaces/IProductPricingUpdate';
import { resources } from '../../../../../../../common/Resources';
import { UserPermissionType } from '../../../../../../../models/IAppUserInfoModel';

export class ProductPriceFormInner extends React.Component<IProductPriceForm & IProductPriceFormMethods & IProductPriceFormDefaultProps & FormikProps<IProductPriceForm>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        allowClear: true,
        labelTextAlignLeft: true
    }

    render() {
        return (
            <Form className={styles.form_style}>
                <section>
                    <div className={styles.grid_col_4}>
                        <div>
                            <DatePickerF
                                {...this.props}
                                name='for_sale_from'
                                label={resources.labels.dateFrom}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='product_name'
                                label='First product'
                                disabled
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name="id_nd_product"
                                label='Second product'
                                dictionaryValues={this.props.productsD}
                                allowOfflineSearch={true}
                                customOnChange={item => this.onProductChange("id_nd_product", item.id)}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name="id_rd_product"
                                label='Third product'
                                dictionaryValues={this.props.productsD}
                                allowOfflineSearch={true}
                                customOnChange={item => this.onProductChange("id_rd_product", item.id)}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <DatePickerF
                                {...this.props}
                                name='for_sale_to'
                                label="Date to"
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='default_price'
                                label='First product price'
                                disabled
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.p2_price = this.props.values.id_nd_product ? this.props.values.p2_price : undefined}
                                name='p2_price'
                                label='Second product price'
                                disabled
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.p3_price = this.props.values.id_rd_product ? this.props.values.p3_price : undefined}
                                name='p3_price'
                                label='Third product price'
                                disabled
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='name'
                                label='Name'
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='quantity_st_product'
                                label='First product quantity'
                                type='number'
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.quantity_nd_product = this.props.values.id_nd_product ? this.props.values.quantity_nd_product : undefined}
                                name='quantity_nd_product'
                                label='Second product quantity'
                                disabled={this.props.values.id_nd_product ? false : true}
                                required={this.props.values.id_nd_product ? true : false}
                                type='number'
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.quantity_rd_product = this.props.values.id_rd_product ? this.props.values.quantity_rd_product : undefined}
                                name='quantity_rd_product'
                                label='Third product quantity'
                                disabled={this.props.values.id_rd_product ? false : true}
                                required={this.props.values.id_rd_product ? true : false}
                                type='number'
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='description'
                                label='Description'
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='p1_discount'
                                label='P1 Discount [%]'
                                type='number'
                                required
                                allowClear={false}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.p2_discount = this.props.values.id_nd_product ? this.props.values.p2_discount : undefined}
                                name='p2_discount'
                                label='P2 Discount [%]'
                                disabled={this.props.values.id_nd_product ? false : true}
                                required={this.props.values.id_nd_product ? true : false}
                                type='number'
                                allowClear={false}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.p3_discount = this.props.values.id_rd_product ? this.props.values.p3_discount : undefined}
                                name='p3_discount'
                                label='P3 Discount [%]'
                                disabled={this.props.values.id_rd_product ? false : true}
                                required={this.props.values.id_rd_product ? true : false}
                                type='number'
                                allowClear={false}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_3}>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.total_order_value = this.calculateTotalOrderValue()}
                                name='total_order_value'
                                label='Total order value'
                                disabled
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.total_discount_value = this.calculateTotalDiscount()}
                                name='total_discount_value'
                                label='Total discount value'
                                disabled
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                {...this.props.values.total_customer_price = this.calculateTotalPriceForCustomer()}
                                name='total_customer_price'
                                label='Total price for customer'
                                disabled
                            />
                        </div>
                    </div>
                    <div />
                    <div />
                    <div />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 21 }}>
                        <ProtectedComponent permission={UserPermissionType.SaveProductPricingChangesButton}>
                            <Button
                                icon='save'
                                htmlType="submit"
                                className={formStatusClassName(this.props.form_status)}
                            />
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.DeleteProductPricingButton}>
                            <Button
                                style={{ marginLeft: 2 }}
                                icon='delete'
                                onClick={() => this.props.deleteProductPricing!(this.props.row_key, Number(this.props.id))}
                            />
                        </ProtectedComponent>
                    </div>
                </section >
            </Form >
        );
    };

    onProductChange = (name: string, value: number) => {
        this.props.setFieldValue(name, value);

        switch (name) {
            case 'id_nd_product':
                this.props.setFieldValue('p2_price', findProductAndReturnHisDefaultPrice(value, this.props.productsD!));
                break;
            case 'id_rd_product':
                this.props.setFieldValue('p3_price', findProductAndReturnHisDefaultPrice(value, this.props.productsD!));
                break;
            default:
                break;
        }
    }

    calculateTotalOrderValue = () => {
        const p1Quantity = this.props.values.quantity_st_product ? this.props.values.quantity_st_product : 0;
        const p2Quantity = this.props.values.quantity_nd_product ? this.props.values.quantity_nd_product : 0;
        const p3Quantity = this.props.values.quantity_rd_product ? this.props.values.quantity_rd_product : 0;

        const p1Value = this.props.values.default_price ? p1Quantity * this.props.values.default_price! : 0;
        const p2Value = this.props.values.p2_price ? p2Quantity! * this.props.values.p2_price! : 0;
        const p3Value = this.props.values.p3_price ? p3Quantity! * this.props.values.p3_price! : 0;

        const result = Number((p1Value + p2Value + p3Value).toFixed(2));

        return result;
    }

    calculateTotalDiscount = (): number => {
        const p1Quantity = this.props.values.quantity_st_product ? this.props.values.quantity_st_product : 0;
        const p2Quantity = this.props.values.quantity_nd_product ? this.props.values.quantity_nd_product : 0;
        const p3Quantity = this.props.values.quantity_rd_product ? this.props.values.quantity_rd_product : 0;

        const p1_Discount = this.props.values.p1_discount ? this.props.values.p1_discount : 0;
        const p2_Discount = this.props.values.p2_discount ? this.props.values.p2_discount : 0;
        const p3_Discount = this.props.values.p3_discount ? this.props.values.p3_discount : 0;

        const p1Value = this.props.values.default_price ? p1Quantity * p1_Discount / 100 * this.props.values.default_price! : 0;
        const p2Value = this.props.values.p2_price ? p2Quantity * p2_Discount / 100 * this.props.values.p2_price! : 0;
        const p3Value = this.props.values.p3_price ? p3Quantity * p3_Discount / 100 * this.props.values.p3_price! : 0;

        const result = Number((p1Value + p2Value + p3Value).toFixed(2));

        return result;
    }

    calculateTotalPriceForCustomer = (): number => {
        const p1Quantity = this.props.values.quantity_st_product ? this.props.values.quantity_st_product : 0;
        const p2Quantity = this.props.values.quantity_nd_product ? this.props.values.quantity_nd_product : 0;
        const p3Quantity = this.props.values.quantity_rd_product ? this.props.values.quantity_rd_product : 0;

        const p1_Discount = this.props.values.p1_discount ? this.props.values.p1_discount : 0;
        const p2_Discount = this.props.values.p2_discount ? this.props.values.p2_discount : 0;
        const p3_Discount = this.props.values.p3_discount ? this.props.values.p3_discount : 0;

        const p1Value = this.props.values.default_price ? p1Quantity * (100 - p1_Discount) / 100 * this.props.values.default_price : 0;
        const p2Value = this.props.values.p2_price ? p2Quantity * (100 - p2_Discount) / 100 * this.props.values.p2_price : 0;
        const p3Value = this.props.values.p3_price ? p3Quantity * (100 - p3_Discount) / 100 * this.props.values.p3_price : 0;

        const result = Number((p1Value + p2Value + p3Value).toFixed(2));

        return result;
    }
}

const ProductPriceForm = withFormik<IProductPriceForm & IProductPriceFormMethods & IProductPriceFormDefaultProps, IProductPriceForm>({
    mapPropsToValues: (props: IProductPriceForm) => {
        return {
            id: props.id,
            row_key: props.row_key,
            id_product: props.id_product,
            for_sale_from: props.for_sale_from,
            for_sale_to: props.for_sale_to,
            id_nd_product: props.id_nd_product !== 0 ? props.id_nd_product : undefined,
            id_rd_product: props.id_rd_product !== 0 ? props.id_rd_product : undefined,
            product_name: findInDictionaryAndReturnValue(props.id_product, props.productsD!),
            p1_discount: props.p1_discount,
            p2_discount: props.p2_discount,
            p3_discount: props.p3_discount,
            quantity_st_product: props.quantity_st_product,
            quantity_nd_product: props.quantity_nd_product,
            quantity_rd_product: props.quantity_rd_product,
            name: props.name,
            description: props.description,
            default_price: findProductAndReturnHisDefaultPrice(Number(props.id_product), props.productsD!),
            p2_price: findProductAndReturnHisDefaultPrice(Number(props.id_nd_product), props.productsD!),
            p3_price: findProductAndReturnHisDefaultPrice(Number(props.id_rd_product), props.productsD!),
        };
    },

    validate: (values: IProductPriceForm) => {
        const errors: FormikErrors<IProductPriceForm> = {};

        if (!values.name)
            errors.name = resources.validation.required;

        if (values.p1_discount && values.p1_discount.toString() === "")
            errors.p1_discount = resources.validation.required;
        if (!values.quantity_st_product)
            errors.quantity_st_product = resources.validation.required;
        else if (values.quantity_st_product <= 0)
            errors.quantity_st_product = resources.validation.required;

        if (values.id_nd_product) {
            if (values.p2_discount === undefined)
                errors.p2_discount = resources.validation.required;
            if (!values.quantity_nd_product)
                errors.quantity_nd_product = resources.validation.required;
            else if (values.quantity_nd_product <= 0)
                errors.quantity_nd_product = resources.validation.required;
        }

        if (values.id_rd_product) {
            if (values.p3_discount === undefined)
                errors.p3_discount = resources.validation.required;
            if (!values.quantity_rd_product)
                errors.quantity_rd_product = resources.validation.required;
            else if (values.quantity_rd_product <= 0)
                errors.quantity_rd_product = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: IProductPriceForm, bag: any) => {
        values.id ? updatePricing(values, bag.props) : createPricing(values, bag.props);
    },
    enableReinitialize: true,
    validateOnChange: true
})(ProductPriceFormInner);

export default ProductPriceForm;

const findProductAndReturnHisDefaultPrice = (id: number, productsD: any[]) => {
    const result = productsD.filter((x: any) => x.id === id);

    return result.length > 0 ? result[0].value / 100 : undefined;
}

const createPricing = (obj: IProductPriceForm, bag: any) => {
    const createProductPricing: IProductPricingCreate = {
        name: obj.name!,
        description: obj.description,
        quantity_st_product: Number(obj.quantity_st_product),
        quantity_nd_product: obj.quantity_nd_product && Number(obj.quantity_nd_product),
        quantity_rd_product: obj.quantity_rd_product && Number(obj.quantity_rd_product),
        p1_discount: Number(obj.p1_discount),
        p2_discount: obj.p2_discount && Number(obj.p2_discount),
        p3_discount: obj.p3_discount && Number(obj.p3_discount),
        id_nd_product: obj.id_nd_product,
        id_rd_product: obj.id_rd_product,
        id_product: obj.id_product,
        for_sale_from: obj.for_sale_from!,
        for_sale_to: obj.for_sale_to!,
        total_order_value: Number((obj.total_order_value! * 100).toFixed()),
        total_discount_value: Number((obj.total_discount_value! * 100).toFixed()),
        total_customer_price: Number((obj.total_customer_price! * 100).toFixed()),
        st_product_base_price: obj.default_price! * 100,
        nd_product_base_price: obj.p2_price && obj.p2_price * 100,
        rd_product_base_price: obj.p3_price && obj.p3_price * 100
    };

    createProductPricings(obj.id_product!, createProductPricing)
        .then(response => { response && bag.updateProductPricingList({ ...obj, id: response.id }); });
}

const updatePricing = (obj: IProductPriceForm, bag: any) => {
    const updateProductPricing: IProductPricingUpdate = {
        name: obj.name!,
        description: obj.description,
        quantity_st_product: Number(obj.quantity_st_product),
        quantity_nd_product: obj.quantity_nd_product ? Number(obj.quantity_nd_product) : 0,
        quantity_rd_product: obj.quantity_rd_product ? Number(obj.quantity_rd_product) : 0,
        p1_discount: Number(obj.p1_discount),
        p2_discount: obj.p2_discount ? Number(obj.p2_discount) : 0,
        p3_discount: obj.p3_discount ? Number(obj.p3_discount) : 0,
        id_nd_product: obj.id_nd_product ? obj.id_nd_product : 0,
        id_rd_product: obj.id_rd_product ? obj.id_rd_product : 0,
        for_sale_from: obj.for_sale_from ? obj.for_sale_from : null,
        for_sale_to: obj.for_sale_to ? obj.for_sale_to : null,
        total_order_value: Number((obj.total_order_value! * 100).toFixed()),
        total_discount_value: Number((obj.total_discount_value! * 100).toFixed()),
        total_customer_price: Number((obj.total_customer_price! * 100).toFixed()),
        st_product_base_price: obj.default_price! * 100,
        nd_product_base_price: obj.p2_price ? obj.p2_price * 100 : 0,
        rd_product_base_price: obj.p3_price ? obj.p3_price * 100 : 0
    };

    updateProductPricings(obj.id_product!, Number(obj.id), updateProductPricing)
        .then(response => { response && bag.updateProductPricingList(obj); });
}