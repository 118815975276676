export interface ITimezone {
    id: number;
    value: string;
}

export const timezones: ITimezone[] = [
    {
        id: 1,
        value: 'UTC-12:00'
    },
    {
        id: 2,
        value: 'UTC-11:00'
    },
    {
        id: 3,
        value: 'UTC-10:00'
    },
    {
        id: 4,
        value: 'UTC-09:00'
    },
    {
        id: 5,
        value: 'UTC-08:00'
    },
    {
        id: 6,
        value: 'UTC-07:00'
    },
    {
        id: 7,
        value: 'UTC-06:00'
    },
    {
        id: 8,
        value: 'UTC-05:00'
    },
    {
        id: 9,
        value: 'UTC-04:00'
    },
    {
        id: 10,
        value: 'UTC-03:00'
    },
    {
        id: 11,
        value: 'UTC-02:00'
    },
    {
        id: 12,
        value: 'UTC-01:00'
    },
    {
        id: 13,
        value: 'UTC±00:00'
    },
    {
        id: 14,
        value: 'UTC+01:00'
    },
    {
        id: 15,
        value: 'UTC+02:00'
    },
    {
        id: 16,
        value: 'UTC+03:00'
    },
    {
        id: 17,
        value: 'UTC+04:00'
    },
    {
        id: 18,
        value: 'UTC+05:00'
    },
    {
        id: 19,
        value: 'UTC+06:00'
    },
    {
        id: 20,
        value: 'UTC+07:00'
    },
    {
        id: 21,
        value: 'UTC+08:00'
    },
    {
        id: 22,
        value: 'UTC+09:00'
    },
    {
        id: 23,
        value: 'UTC+10:00'
    },
    {
        id: 24,
        value: 'UTC+11:00'
    },
    {
        id: 25,
        value: 'UTC+12:00'
    }
];
