import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';
import { StatusesDictionaryContent } from './contents/StatusesDictionaryContent';

class StatusesDictionaryViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.statuses_dictionary);
    }

    render() {
        return (
            <StatusesDictionaryContent />
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const StatusesDictionaryView: React.ComponentClass = connect(null, mapDispatchToProps)(StatusesDictionaryViewInner);