const contactedData = [
  {
    id: undefined,
    name: "Select all",
  },
  {
    id: true,
    name: "Contacted",
  },
  {
    id: false,
    name: "Not contacted",
  },
];

export const useContactedEntity = () => ({
  entity: contactedData,
  isLoading: false,
});
