import React from "react";

import { AddFilters } from "../add-filters/add-filters";
import { DateFilter } from "../date-filter/date-filter";

import { HourFilter } from "../hour-filter/hour-filter";
import { useFilters } from "../../../lib/filters-state";
import { ExportData } from "../../export-data/export-data";
import { TimezoneFilter } from "../timezone-filter/timezone-filter";
import { GROUP_BY_OPTIONS } from "../../../lib/table-data/group-by-data";
import { FilterIndicators } from "../filter-indicators/filter-indicators";

import styles from "./statistic-controls.module.scss";

export const StatisticControls = () => {
  const { state } = useFilters();
  return (
    <>
      <div className={styles.controls}>
        <div className={styles.filters}>
          <AddFilters />

          {state.groupBy === GROUP_BY_OPTIONS.hour ? (
            <HourFilter />
          ) : (
            <DateFilter />
          )}

          <TimezoneFilter />
        </div>

        <ExportData />
      </div>

      <div className={styles.indicators}>
        <FilterIndicators />
      </div>
    </>
  );
};
