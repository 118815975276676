import DictionaryF from '../../../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from '../styles/UserInformationsForm.module.scss';
import TextAreaF from '../../../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { copyClipboard } from '../../../../../../helpers/CopyHelpers';
import { emailIsValid } from '../../../../../../helpers/ValidationHelpers';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../../../common/dictionaries/Boolean';
import { getDictionary } from '../../../../../../helpers/DisctionariesHelper';
import { IId } from '../../../../../../common/interfaces/IId';
import { IUpdateUserInf } from '../../../../../../models/IUpdateUserInf';
import { IUserInformationsProps } from '../interfaces/IUserInformationsProps';
import { openAlertNotification, openSuccessNotification } from '../../../../../../helpers/NotificationHelper';
import { resources } from '../../../../../../common/Resources';
import { updateUser } from '../../../../../../services/m29_users_management/GeneralUserService';

export class TemplateUserInformationsForm extends React.Component<IUserInformationsProps & IId & FormikProps<IUserInformationsProps>> {
    public static defaultProps = {
        labelTextAlignLeft: true,
        boldLabel: true,
        allowClear: true
    }

    public render() {
        return (
            <Form className='formStyle'>
                <section style={{ textAlign: 'left' }}>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='name'
                                label={resources.labels.name}
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='second_name'
                                label={resources.labels.second_name}
                                required={false}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='short_name'
                                label={resources.labels.short_name}
                                required={false}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='signature'
                                label={resources.labels.signature}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='id_users_role'
                                label={resources.labels.role}
                                dictionaryValues={this.props.rolesD}
                                required
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='id_account_manager'
                                label={resources.labels.accountManager}
                                dictionaryValues={this.props.managersD}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='id_country'
                                label={resources.labels.country}
                                dictionaryValues={getDictionary('countriesNameD')}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='id_company'
                                label={resources.labels.company}
                                dictionaryValues={this.props.companiesD}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='partner_token'
                                label={resources.labels.apiToken}
                                disabled
                            />
                        </div>
                        <div style={{ marginTop: 37 }}>
                            <Button
                                style={{ width: '100%' }}
                                onClick={(event: any) => copyClipboard(event, this.props.partner_token)}
                            >
                                Copy token
                             </Button>
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='is_activated'
                                label={resources.labels.isActive}
                                dictionaryValues={getBooleanValues()}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='email'
                                label={resources.labels.email}
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='skype'
                                label={resources.labels.skype}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='telegram'
                                label={resources.labels.telegram}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='phone'
                                label={resources.labels.phone}
                            />
                        </div>
                        <div />
                    </div>
                    <div className={styles.sectionDivider} />
                    <TextAreaF
                        {...this.props}
                        name='description'
                        label={resources.labels.description}
                        textAreaRows={5}
                    />
                    <div className={styles.sectionDivider} />
                    <Button
                        className='saveButton'
                        htmlType="submit"
                        type="primary"
                    >
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                </section>
            </Form >
        );
    };
}

const UserInformationsForm = withFormik<IUserInformationsProps, IUserInformationsProps>({
    enableReinitialize: true,

    mapPropsToValues: (props: IUserInformationsProps) => {
        return {
            id_users_role: props.id_users_role,
            name: props.name,
            second_name: props.second_name,
            short_name: props.short_name,
            email: props.email,
            id_account_manager: props.id_account_manager,
            id_company: props.id_company,
            id_country: props.id_country,
            skype: props.skype,
            telegram: props.telegram,
            is_activated: Boolean(props.is_activated) ? '1' : '0',
            description: props.description,
            partner_token: props.partner_token,
            phone: props.phone,
            signature: props.signature
        };
    },

    validate: (values: IUserInformationsProps) => {
        const errors: FormikErrors<IUserInformationsProps> = {};

        if (!values.id_users_role) {
            errors.id_users_role = resources.validation.required;
        }
        if (!values.email) {
            errors.email = resources.validation.emailIsRequired;
        }
        if (!values.name) {
            errors.name = resources.validation.required;
        }
        else if (values.email && !emailIsValid(values.email)) {
            errors.email = resources.validation.incorrectEmailAdres;
        }
        else if (values.email && values.email.length > 255) {
            errors.email = resources.validation.requiredMax255Characters;
        }
        // if (!values.id_account_manager) {
        //     errors.id_account_manager = resources.validation.required;
        // }

        return errors;
    },

    handleSubmit: (values: IUserInformationsProps, bag: FormikBag<IUserInformationsProps & IId, IUserInformationsProps>) => {
        const object: IUserInformationsProps = {
            id_users_role: values.id_users_role,
            is_activated: values.is_activated,
            name: values.name,
            second_name: values.second_name,
            short_name: values.short_name,
            email: values.email,
            id_account_manager: values.id_account_manager,
            id_company: values.id_company ? values.id_company : null,
            skype: values.skype,
            telegram: values.telegram,
            id_country: values.id_country,
            signature: values.signature,
            description: values.description,
            phone: values.phone
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.id_users_role === bag.props.id_users_role
            && values.name === bag.props.name
            && values.second_name === bag.props.second_name
            && values.short_name === bag.props.short_name
            && values.email === bag.props.email
            && values.is_activated === bag.props.is_activated
            && values.id_account_manager === bag.props.id_account_manager
            && values.id_company === bag.props.id_company
            && values.id_account_manager === bag.props.id_account_manager
            && values.skype === bag.props.skype
            && values.telegram === bag.props.telegram
            && values.id_country === bag.props.id_country
            && values.signature === bag.props.signature
            && values.description === bag.props.description
            && values.phone === bag.props.phone
        )
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else {
            createQuery(object, bag.props.id);
        }
    }

})(TemplateUserInformationsForm);

export default UserInformationsForm;

const createQuery = async (obj: IUserInformationsProps, id?: string) => {
    const object: IUpdateUserInf = {
        name: obj.name,
        id_users_role: obj.id_users_role,
        is_activated: Number(obj.is_activated) === 1 ? true : false,
        second_name: obj.second_name,
        short_name: obj.short_name,
        email: obj.email,
        id_account_manager: obj.id_account_manager ? obj.id_account_manager : null,
        id_company: obj.id_company ? obj.id_company : null,
        skype: obj.skype,
        telegram: obj.telegram,
        id_country: obj.id_country,
        description: obj.description,
        signature: obj.signature,
        phone: obj.phone
    }

    await updateUser(Number(id), object)
        .then(response => response && openSuccessNotification(resources.statement.updated));
}