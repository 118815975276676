import DictionaryF from "../../../../components/templateForm/form/dropDown/DictionaryF";
import React from "react";
import styles from "./styles/AppUserRoleForm.module.scss";
import TextAreaF from "../../../../components/templateForm/form/textArea/TextAreaF";
import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";
import { Button } from "antd";
import {
  createUser,
  updateUser,
} from "../../../../services/m29_users_management/GeneralUserService";
import { emailIsValid } from "../../../../helpers/ValidationHelpers";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { getBooleanValues } from "../../../../common/dictionaries/Boolean";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { IAddObjToArray } from "../../../../common/interfaces/IAddObjToArray";
import { IAppUserFormProps } from "./interfaces/IAppUserFormProps";
import { IHideModal } from "../../../../common/interfaces/IHideModal";
import { IId } from "../../../../common/interfaces/IId";
import { IUpdateObjInArray } from "../../../../common/interfaces/IUpdateObjInArray";
import {
  openAlertNotification,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/NotificationHelper";
import { resources } from "../../../../common/Resources";
import { stringToBool } from "../../../../helpers/BooleanHelper";

export class TemplateAppUserForm extends React.Component<
  IAppUserFormProps &
    IHideModal &
    IId &
    IAddObjToArray &
    IUpdateObjInArray &
    FormikProps<IAppUserFormProps>
> {
  public static defaultProps = {
    labelTextAlignLeft: true,
    boldLabel: true,
    allowClear: true,
  };

  public render() {
    return (
      <Form>
        {this.props.name && (
          <div className={styles.userName}>{this.props.name}</div>
        )}
        <section>
          <div className={styles.grid_col_2}>
            <div>
              <TextInputF
                {...this.props}
                name="email"
                label={resources.labels.email}
                required
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="name"
                label={resources.labels.name}
                required
              />
            </div>
          </div>
          <div className={styles.grid_col_2}>
            <div>
              <DictionaryF
                {...this.props}
                name="id_users_role"
                label={resources.labels.role}
                dictionaryValues={this.props.rolesD}
                required
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                type="password"
                name="password"
                label={resources.labels.password}
                required={!this.props.id}
              />
            </div>
          </div>
          <div className={styles.grid_col_2}>
            <div>
              <TextInputF
                {...this.props}
                name="second_name"
                label={resources.labels.second_name}
                required={false}
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="short_name"
                label={resources.labels.short_name}
                required={false}
              />
            </div>
          </div>
          <div className={styles.grid_col_2}>
            <div>
              <DictionaryF
                {...this.props}
                name="id_account_manager"
                label={resources.labels.accountManager}
                dictionaryValues={this.props.accountManagersD}
              />
            </div>
            <div>
              <DictionaryF
                {...this.props}
                name="id_company"
                label={resources.labels.company}
                dictionaryValues={this.props.companiesD}
              />
            </div>
          </div>
          <div className={styles.grid_col_2}>
            <div>
              <DictionaryF
                {...this.props}
                name="is_activated"
                label={resources.labels.isActive}
                dictionaryValues={getBooleanValues()}
              />
            </div>
            <div>
              <DictionaryF
                {...this.props}
                name="id_country"
                label={resources.labels.country}
                dictionaryValues={getDictionary("countriesNameD")}
              />
            </div>
          </div>
          <div className={styles.grid_col_2}>
            <div>
              <TextInputF
                {...this.props}
                name="skype"
                label={resources.labels.skype}
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="telegram"
                label={resources.labels.telegram}
              />
            </div>
          </div>
          <TextAreaF
            {...this.props}
            name="description"
            label={resources.labels.description}
            textAreaRows={5}
          />
        </section>
        <Button className="saveButton" htmlType="submit" type="primary">
          {this.props.id ? resources.buttons.save : resources.buttons.create}
        </Button>
      </Form>
    );
  }
}

const AppUserForm = withFormik<
  IAppUserFormProps & IAddObjToArray & IUpdateObjInArray & IId,
  IAppUserFormProps & IAddObjToArray & IUpdateObjInArray
>({
  mapPropsToValues: (props: IAppUserFormProps) => {
    return {
      id_users_role: props.id_users_role,
      name: props.name,
      second_name: props.second_name,
      short_name: props.short_name,
      email: props.email,
      id_account_manager: props.id_account_manager,
      id_company: props.id_company ? props.id_company : null,
      id_country: props.id_country,
      skype: props.skype,
      telegram: props.telegram,
      is_activated: Boolean(props.is_activated) ? "1" : "0",
      password: undefined,
      description: props.description,
      id: props.id,
    };
  },

  validate: (values: IAppUserFormProps) => {
    const errors: FormikErrors<IAppUserFormProps> = {};
    const reg = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    const specChar = new RegExp(`[!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]`, "g");
    const specMatches = values.password!.match(specChar);

    if (!values.id_users_role) {
      errors.id_users_role = resources.validation.required;
    }
    if (!values.email) {
      errors.email = resources.validation.emailIsRequired;
    }
    if (!values.name) {
      errors.name = resources.validation.required;
    } else if (values.email && !emailIsValid(values.email)) {
      errors.email = resources.validation.incorrectEmailAdres;
    } else if (values.email && values.email.length > 255) {
      errors.email = resources.validation.requiredMax255Characters;
    }
    if (!values.id && !values.password) {
      errors.password = resources.validation.required;
    } else if (
      values.password &&
      (!reg.test(values.password!) ||
        !Array.isArray(specMatches) ||
        specMatches.length < 2)
    ) {
      errors.password = resources.validation.passwordCondition;
    }

    return errors;
  },

  handleSubmit: (
    values: IAppUserFormProps,
    bag: FormikBag<
      IAppUserFormProps & IHideModal & IId & IAddObjToArray & IUpdateObjInArray,
      IAppUserFormProps
    >
  ) => {
    const object: IAppUserFormProps = {
      id_users_role: values.id_users_role,
      is_activated: values.is_activated,
      name: values.name,
      second_name: values.second_name,
      short_name: values.short_name,
      email: values.email,
      id_account_manager: values.id_account_manager,
      id_company: values.id_company ? values.id_company : null,
      skype: values.skype,
      telegram: values.telegram,
      id_country: values.id_country,
      description: values.description,
      password: values.password,
    };

    // Sprawdzenie, czy te same vartości
    if (
      object.id &&
      values.id_users_role === bag.props.id_users_role &&
      values.name === bag.props.name &&
      values.second_name === bag.props.second_name &&
      values.short_name === bag.props.short_name &&
      values.email === bag.props.email &&
      values.is_activated === bag.props.is_activated &&
      values.id_account_manager === bag.props.id_account_manager &&
      values.id_company === bag.props.id_company &&
      values.skype === bag.props.skype &&
      values.telegram === bag.props.telegram &&
      values.id_country === bag.props.id_country &&
      values.description === bag.props.description
    )
      openAlertNotification(resources.statement.thePropsAreTheSame);
    else {
      action(object, bag.props);
    }
  },
})(TemplateAppUserForm);

export default AppUserForm;

const action = async (
  obj: IAppUserFormProps,
  bag: IHideModal & IId & IAddObjToArray & IUpdateObjInArray
) => {
  const updateUserObject = {
    name: obj.name,
    second_name: obj.second_name,
    short_name: obj.short_name,
    email: obj.email,
    id_country: obj.id_country,
    description: obj.description,
    id_users_role: obj.id_users_role,
    is_activated: stringToBool(obj.is_activated),
    id_account_manager: obj.id_account_manager,
    id_company: obj.id_company,
    telegram: obj.telegram,
    skype: obj.skype,
    password: obj.password,
  };

  const createUserObject = {
    name: obj.name,
    email: obj.email,
    password: obj.password,
  };

  if (bag.id) {
    await updateUser(Number(bag.id), updateUserObject).then((response) => {
      if (response) {
        const obj = {
          ...updateUserObject,
          id: bag.id,
          key: bag.id,
          updated_at: new Date(),
        };

        bag.updateObjInArray!(obj);

        openSuccessNotification(resources.statement.app_user_updated);
      } else {
        openErrorNotification(resources.statement.databaseConnectionError);
      }
    });
  } else {
    await createUser(createUserObject).then((response) => {
      if (response) {
        const id = response.data.id;

        if (id) {
          updateUser(id, updateUserObject).then((response) => {
            if (response) {
              const obj = {
                ...updateUserObject,
                id: id,
                key: id,
                updated_at: new Date(),
                created_at: new Date(),
              };

              bag.addObjToArray!(obj);

              openSuccessNotification(resources.statement.app_user_created);
            }
          });
        }
      }
    });
  }

  bag.hideModal!();
};
