import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';
import { UsersRoleContent } from './contents/UsersRoleContent';

class UsersRoleViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = async () => {
        this.props.setActiveMenuItem(navPath.administrationRole);
    }

    public render() {
        return (
            <UsersRoleContent />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const UsersRoleView: React.ComponentClass = connect(null, mapDispatchToProps)(UsersRoleViewInner);
