import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { DictionaryItem } from '../../types/DictionaryItem';
import { generateUpdateKey } from '../../../../helpers/generateUpdateKey';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { IDictionaryFProps } from './interfaces/IDictionaryFProps';
import { IDictionaryFState } from './interfaces/IDictionaryFState';
import { IDictionaryValue } from '../../types/dictionaries/IDictionaryValue';
import { Select } from 'antd';

export default class DictionaryF extends React.Component<IBaseFormikProps & IDictionaryFProps, IDictionaryFState> {
  state = {
    dropdownItems: [],
    isError: false,
    placeholder: (<span>Select...</span>),
    loading: false,
    forceUpdateKey: undefined
  };

  componentDidMount = async () => {
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);

    this.setState({ forceUpdateKey: generateUpdateKey() });
    this.setDictionaryItems(this.props.dictionaryValues);
  }

  UNSAFE_componentWillReceiveProps = (nextProps: IDictionaryFProps) => {
    if (JSON.stringify(this.props.dictionaryValues) !== JSON.stringify(nextProps.dictionaryValues)) {
      this.setDictionaryItems(nextProps.dictionaryValues);
    }
  }

  public render() {
    return (
      <BaseForm {...this.props} forceUpdateKey={this.state.forceUpdateKey}>
        <Select
          disabled={this.props.disabled}
          onChange={this.onChange}
          placeholder={this.props.placeholder ? this.props.placeholder : this.state.placeholder}
          value={this.props.values[this.props.name] !== 0 ? this.props.values[this.props.name] : undefined}
          onDropdownVisibleChange={this.onFocus}
          optionFilterProp="children"
          allowClear={this.props.allowClear}
          loading={this.props.loading !== undefined ? this.props.loading : this.state.loading}
          onSearch={this.onSearch}
          showSearch
        >
          {this.state.dropdownItems.map(dropdownItem => (
            this.renderOption(dropdownItem)
          ))}
        </Select>
      </BaseForm>
    )
  };

  onFocus = (isOpen: boolean) => {
    if (!isOpen)
      this.props.setFieldTouched(this.props.name);
  }

  setDictionaryItems = async (dictionaryValues?: IDictionaryValue[]) => {
    let result: DictionaryItem[] = [];
    const dictionaryValuesList = dictionaryValues ? dictionaryValues : [];

    dictionaryValuesList.map((dictValue) => {
      if (dictValue.name && dictValue.name !== '' && dictValue.name !== null)
        result = [...result, { id: dictValue.id, value: dictValue.name, code: undefined }];

      return null;
    });

    this.setState({
      dropdownItems: result,
      forceUpdateKey: generateUpdateKey()
    });
  }

  onChange = (value: number) => {
    this.props.setFieldValue(this.props.name, value);

    if (this.props.customOnChange) {
      const selectedElement = this.state.dropdownItems.find((el: DictionaryItem) => el.id === value);
      if (selectedElement) {
        this.props.customOnChange(selectedElement);
      }
    }
  }

  onSearch = async (value: string) => {
    if (this.props.dictionaryValuesProvider) {
      this.setState({
        loading: true,
      });
      const dictionaryValuesList = await this.props.dictionaryValuesProvider(value);
      let result: DictionaryItem[] = [];

      dictionaryValuesList.map((dictValue) => {
        if (dictValue.name && dictValue.name !== '' && dictValue.name !== null)
          result = [...result, { id: dictValue.id, value: dictValue.name, code: undefined }];
        return null;
      });
      this.setState({
        dropdownItems: result,
        loading: false,
      });
    }
  }

  renderOption = (dropdownItem: DictionaryItem) => {
    if (dropdownItem) {
      return (
        <Select.Option
          value={dropdownItem.id}
          key={dropdownItem.id.toString()}
        >
          {dropdownItem.value}
        </Select.Option>
      );
    }

    return null;
  }
}
