import React, { Component } from 'react'
import { IMapping, IStatus, IUpdateStatusObj } from '../contents/CourierStatusMappingContent'
import { Select, Input, Button } from 'antd';
import styles from './CouriersStatusEditForm.module.scss';
import { getDictionary } from '../../../helpers/DisctionariesHelper';

interface ICouriersStatusEditFormProps {
    data?: IMapping;
    statusesList: IStatus[];
    updateStatus: (obj: IUpdateStatusObj) => void;
}

interface ICouriersStatusEditFormState {
    f_status?: string;
    f_courier_status?: string;
    f_status_description?: string;
    errors: IErrors;
    fields: string[];
}

interface IErrors {
    f_status: string;
    f_courier_status: string;
}

export class CouriersStatusEditForm extends Component<ICouriersStatusEditFormProps, ICouriersStatusEditFormState> {
    state = {
        f_status: undefined,
        f_courier_status: '',
        f_status_description: '',
        errors: {
            f_status: '',
            f_courier_status: '',
        },
        fields: ['f_status', 'f_courier_status']
    }

    componentDidMount = () => {
        this.mapPropsToState();
    }

    mapPropsToState = () => {
        const { status, courier_status, status_description } = this.props.data!;
        this.setState({
            f_status: status ? status : undefined,
            f_courier_status: courier_status ? courier_status : '',
            f_status_description: status_description ? status_description : '',
        })
    }

    handleTextInputChange = (e: any) => {
        if (e.target.name === 'f_courier_status') {
            this.setState({
                f_courier_status: e.target.value
            })
        } else if (e.target.name === 'f_status_description') {
            this.setState({
                f_status_description: e.target.value
            })
        }
    }

    handleSelectChange = (e: any) => {
        this.setState({
            f_status: e
        })
    }

    encodeStatus = (givenStatus: string): number | undefined => {
        const list = this.props.statusesList;

        const result = list.find((status: IStatus) => {
            return status.name === givenStatus;
        })

        return result ? result.id : undefined;
    }

    decodeCountry = (countryId: number): string => {
        return getDictionary('countriesNameD').find((el: any) => el.id === countryId).name;
    }

    validateFormElement = (fieldName: string): any => {
        let errors = this.state.errors;

        switch (fieldName) {
            case 'f_courier_status':
                if (this.state.f_courier_status === '' || this.state.f_courier_status === undefined) {
                    errors.f_courier_status = 'This field is required';
                } else {
                    errors.f_courier_status = '';
                }
                break;
            case 'f_status':
                if (this.state.f_status === '' || this.state.f_status === undefined) {
                    errors.f_status = 'This field is required';
                } else {
                    errors.f_status = '';
                }
        }

        this.setState({
            errors
        })
    }

    validateForm = () => {
        this.state.fields.forEach(el => {
            this.validateFormElement(el);
        });

        let notValid = Object.values(this.state.errors).filter((el: any) => {
            return el !== '';
        });

        const obj = {
            id: this.props.data!.id,
            courier: this.props.data!.courier_name,
            statusId: this.encodeStatus(this.state.f_status!) !== undefined ? this.encodeStatus(this.state.f_status!)! : -1,
            courierStatus: this.state.f_courier_status,
            description: this.state.f_status_description,
            notes: '',
            statusName: this.encodeStatus(this.state.f_status!) === undefined ? this.state.f_status : '',
        }

        notValid.length === 0 && this.props.updateStatus(obj);
    }

    render() {
        return (
            <>
                <h3 className={styles.header}>You are editing {this.props.data!.courier_name.toUpperCase()} status in {this.decodeCountry(this.props.data!.country_id)}</h3>
                <form className={styles.form}>
                    <div className={styles.formFlex}>
                        <div className={styles.item}>
                            <label className={styles.tbLeftRadius}>eFirst</label>
                            <Select value={this.state.f_status} onChange={this.handleSelectChange}>
                                {this.props.statusesList.map((status: IStatus) => {
                                    return <Select.Option key={status.id} value={status.name}>
                                        {status.name}
                                    </Select.Option>
                                })}
                            </Select>
                            <p className={styles.errorMessage} style={{ opacity: this.state.errors.f_status === '' ? 0 : 1 }}>{this.state.errors.f_status}</p>
                        </div>
                        <div className={styles.item}>
                            <label>Courier</label>
                            <Input
                                name='f_courier_status'
                                value={this.state.f_courier_status}
                                onChange={this.handleTextInputChange}
                            />
                            <p className={styles.errorMessage} style={{ opacity: this.state.errors.f_courier_status === '' ? 0 : 1 }}>{this.state.errors.f_courier_status}</p>
                        </div>
                        <div className={styles.item}>
                            <label className={styles.tbRightRadius}>Description</label>
                            <Input
                                name='f_status_description'
                                value={this.state.f_status_description}
                                onChange={this.handleTextInputChange}
                            />
                        </div>
                    </div>
                    <Button onClick={this.validateForm}>Save</Button>
                </form>
            </>
        )
    }
}
