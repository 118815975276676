import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button, Tooltip } from 'antd';
import { resources } from '../../../../common/Resources';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { findInDictionaryAndReturnValueWithSpin, getDictionary } from '../../../../helpers/DisctionariesHelper';
import CouriersStatuseDictionaryForm from '../forms/CouriersStatusDictionaryForm';

const { Column } = Table;

export class CouriersStatusesDictionaryTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='100px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.courier_name, resources.columnTitle.courier_name_short)}
                key='courier_id'
                width='120px'
                align='center'
                sorter={(a, b) => a.courier_id - b.courier_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.courier_id, this.props.couriersD)
                    )}
            />,
            <Column
                title={resources.columnTitle.status}
                key='status_id'
                width='120px'
                align='center'
                sorter={(a, b) => a.status_id - b.status_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.status_id, getDictionary('statusesD'))
                    )}
            />,
            <Column
                title={resources.columnTitle.courier_status}
                dataIndex='courier_status'
                key='courier_status'
                width='250px'
                align='center'
                sorter={(a, b) => (a.courier_status ? a.courier_status : "").localeCompare(b.courier_status ? b.courier_status : "")}
                {...this.getColumnSearchProps('courier_status')}
            />,
            <Column
                title={resources.columnTitle.description}
                dataIndex='description'
                key='description'
                width='450px'
                align='center'
                sorter={(a, b) => (a.description ? a.description : "").localeCompare(b.description ? b.description : "")}
                {...this.getColumnSearchProps('description')}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.additional_inf, resources.columnTitle.additional_inf_short)}
                dataIndex='additional_info'
                key='additional_info'
                width='450px'
                align='center'
                sorter={(a, b) => (a.additional_info ? a.additional_info : "").localeCompare(b.additional_info ? b.additional_info : "")}
                {...this.getColumnSearchProps('additional_info')}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='80px'
                align='center'
                fixed= 'right'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditCouriersStatusModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editCouriersStatus}
                                buttonTooltip={resources.modalTooltips.editCouriersStatus}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={800}
                            >
                                <CouriersStatuseDictionaryForm
                                    id={record.id}
                                    courier_id={record.courier_id}
                                    status_id={record.status_id}
                                    courier_status={record.courier_status}
                                    description={record.description}
                                    additional_info={record.additional_info}
                                    updateObjInArray={this.props.updateObjInArray}
                                    couriersD={this.props.couriersD}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.DeleteCouriersApiMappingButton}>
                            <Tooltip placement='rightTop' title={'Delete category'}>
                                <Button icon='delete' onClick={() => this.props.deleteCouriersStatuses(record.id)} />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='CouriersStatusesDictionaryTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }
}