export const ResourceDef = {
  TemplateGateway: `https://api.${process.env.REACT_APP_DOMAIN}/select-sql/sql/`,
};

export enum resourceDef {
  // ---!!! M0 - Authentication !!!---
  Authenticate = "auth/authenticate/",
  CheckTokenStatus = "auth/authorize",
  Logout = "auth/logout",
  RefreshToken = "auth/refresh",
  Impersonate = "auth/impersonate/",
  // ---!!! M29 - USERS !!!---
  // M29 - General User
  UserInfo = "users-management-service/users/me",
  GetUsersList = "users-management-service/users/list",
  CreateUser = "users-management-service/users",
  UpdateUser = "users-management-service/users",
  Users = "users-management-service/users",
  // M121 - Remarketing settings
  RemarketingSettings = "remarketing-settings/",
  // M29 - Postbacks
  PostbacksInfo = "users-management-service/users/settings/postbacks",
  CustomVariables = "users-management-service/users/settings/variables",
  Tables = "users-management-service/users/settings/tables",
  // M29 - Roles
  Role = "users-management-service/roles",
  // M29 - Permissions
  Permissions = "users-management-service/permissions",
  // M29 - Companies
  Companies = "users-management-service/companies",
  // M29 - Companiy Update
  CompanyUpdate = "users-management-service/companies",
  // M29 - Companiy Create
  CompanyCreate = "users-management-service/companies",
  // M29 - Reset Password
  ResetPassword = "users-management-service/users/reset-password",
  // M29 - Activate Account
  ActivateAccount = "users-management-service/users/activate/",
  // M29 - Grant Partner
  GrantPartner = "users-management-service/users",
  // M29 - Grant Partner
  DenyPartner = "users-management-service/users",
  // M29 - Surveys GET
  SurveysGet = "users-management-service/users/surveys/questions",
  // M29 - Surveys PUT
  SurveysPUT = "users-management-service/users/surveys",

  // ---!!! M30 - PRODUCTS !!!---
  // M30 - Products
  Products = "products/",
  // M30 - Product Images
  Images = "products/",
  // M30 - Product Pages
  Pages = "products/",
  // M30 - Product Pixels
  Pixels = "products/",
  // M30 - Product Pricings
  Pricings = "products/",
  // M30 - Top Offers
  TopOffers = "products/top-offers",

  // ---!!! M47 - Balance Operations !!!---
  // M47 - Deposit Transaction
  DepositTransaction = "balance-operations/operations/deposit",
  // M47 - Withdrawal Transaction
  WithdrawalTransaction = "balance-operations/operations/withdraw",
  // M47 - Get Finances List
  FinanceGet = "balance-operations/operations",
  // M47 - Clear Transaction
  ClearTransaction = "balance-operations/operations",
  // M47 - Reject Transaction
  RejectTransaction = "balance-operations/operations",
  // M47 - Get Finance Chart Data
  GetFinanceChartData = "balance-operations/balance/chart",

  // M55 - Leads Statistics
  LeadsStatistics = "leads-statistics/statistics",
  LeadsStatisticsNew = "leads-statistics/statistics-2",
  OrderTotal = "leads-statistics/order-total",
  CompaniesList = "leads-statistics/companies",
  CountriesList = "leads-statistics/countries",
  PartnersList = "leads-statistics/parters",

  // ---!!! M61 - News !!!---
  NewsGet = "news/",
  NewsCreate = "news/",
  NewsUpdate = "news/",
  DeleteUpdate = "news/",
  ActiveNewsGet = "news/active",

  // ---!!! M62 - Utilities !!!---
  SupportCreate = "utilities/support",

  // ---!!! M66 - Sales !!!---
  // M66 - Get Products list
  ResaleP2GetProducts = "resale/p2/info",
  ResaleP3GetProducts = "resale/p3/info",
  // M66 - Get Products list
  ResaleP2Running = "resale/p2/running",
  ResaleP3Running = "resale/p3/running",
  // M66 - Set product rules for resale
  SetP2ProductRulesForResale = "resale/p2/resale",
  SetP3ProductRulesForResale = "resale/p3/resale",

  // ---!!! K8 !!!---
  K8MicroInf = "servicesinfo-k8s/micro_inf",
  K8HealthPodsCheck = "servicesinfo-k8s/health_pods_check",

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Focus Classifiers
  FocusClassifiersUpdate = "focus-get-classifiers/update",
  FocusClassifiersGet = "focus-get-classifiers/get",
  FocusClassifiersLib = "focus-get-classifiers/lib",
  FocusClassifiersReset = "focus-get-classifiers/reset",
  // Lib
  ClassifiersInsert = "focus-get-classifiers/li_classifiers_cc/insert",
  // Focus Campaigns
  FocusCampaignsUpdate = "focus-get-campaigns/update",
  FocusCampaignsGet = "focus-get-campaigns/get/",
  FocusCampaignsLib = "focus-get-campaigns/lib",
  FocusCampaignsReset = "focus-get-campaigns/reset",
  // Campaign - target url
  TargetUrlCreate = "api/campaigns/",
  TargetUrlGetAll = "api/campaigns/",
  TargetUrlDelete = "api/campaigns/",
  // Orders
  GetOrders = "order-list/m24/read",
  GetOrdersDetails = "orders/pricings/",
  CreateNewOrder = "order-create/m21/create",
  GetOrdersStatusesMap = "/update-order-status/statuses/tree",
  ChangeOrderStatus = "/update-order-status/statuses/update",
  // Leads redirection
  LeadsRedirectionCountries = "lead-redirect-to-focus-campaign/countries",
  LeadsRedirectionPartners = "lead-redirect-to-focus-campaign/partner",
  LeadsRedirectionProducts = "lead-redirect-to-focus-campaign/products_all",
  LeadsRedirectionProductsWithId = "lead-redirect-to-focus-campaign/products/",
  LeadsRedirectionCampaigns = "lead-redirect-to-focus-campaign/call_center_campaigns_all",
  LeadsRedirectionCampaignsWithId = "lead-redirect-to-focus-campaign/call_center_campaigns/",
  LeadsRedirectionList = "lead-redirect-to-focus-campaign/list",
  LeadsRedirectionUpdate = "lead-redirect-to-focus-campaign/list/update",
  LeadsRedirectionInsert = "lead-redirect-to-focus-campaign/list/insert",
  LeadsRedirectionDelete = "lead-redirect-to-focus-campaign/list/delete/",
  LeadsRedirectionChangeStatus = "lead-redirect-to-focus-campaign/list/active/",
  // Create New Order
  GetLeadsWithFccId = "leads/",
  GetProductPricings = "creating-orders-product-pricing/",
  // Couriers Status Mapping
  GetCouriers = "courier-status-mapping/couriers",
  GetMappings = "courier-status-mapping/mappings",
  GetStatuses = "courier-status-mapping/statuses",
  UpdateStatus = "courier-status-mapping/status",
  // System Classifiers
  SystemClassifiersGet = "focus-get-classifiers/li_classifiers_system_cc/get",
  SystemClassifiersCreate = "focus-get-classifiers/li_classifiers_system_cc/insert",
  SystemClassifiersUpdate = "focus-get-classifiers/li_classifiers_system_cc/update",
  // Affbay Classifiers
  AffbayClassifiersGet = "focus-get-classifiers/li_classifiers_cc/get",
  AffbayClassifiersCreate = "focus-get-classifiers/li_classifiers_cc/insert",
  AffbayClassifiersUpdate = "focus-get-classifiers/li_classifiers_cc/update",
  // Mega Update
  MegaUpdate = "focus-get-campaigns/megaupdate",
  // Payout
  Payouts = "payouts/proxies",
  UpdatePayouts = "payouts/fproxies",
  PayoutsHistory = "payouts/history",
  PayoutsFutureProxy = "payouts/fproxy",
  PayoutsActualProxy = "payouts/proxy",
  // Finance
  AcceptedBalanceGet = "balance-operations/balance",
  TotalBalanceGet = "balance-operations/balance/total",
  TotalBalanceIdGet = "balance-operations/balance/total",
  LiTablesGet = "libraries/lib/show",
  // Leads
  LeadsGet = "leads-data/list.json",
  LeadGetById = "leads-data/",
  LeadSearch = "leads-data/search",
  LeadValidateById = "validation/validate/",
  // Libraries
  LibrariesGet = "libraries/lib/show",
  // M3 Postbacks
  Postbacks = "postbacks-service/",
  // M4 Send to CC
  SendToCC = "focus-api-send/send/",
  // M6 Check status
  CheckStatus = "focus-check-status/update/",
  // WebSocket
  WebSocket = "web-socket",
  SendMessageToUsers = "web-socket/users",
  SendMessageToRoles = "web-socket/roles",
  // Settings
  ClickIdCheckDouble = "/redis-client/clickid-double-enabled",
  ClickIdCheckDoubleDays = "/redis-client/clickid-double-days",
  ExampleCodePhp = "print-awb/example/php",
  VerificationTool = "lead-receive-and-save/api/validation-tool",
}

export enum navPath {
  dashboard = "dashboard",
  offers = "offers",
  offersId = "offers/:id",
  statistics = "statistics/:param?",
  leads = "leads",
  campaigns_partner = "campaigns_partner",
  campaigns_admin = "campaigns_admin",
  finance = "finance",
  financeAdmin = "finance_admin",
  financeAdminId = "finance_admin/:id",
  employees = "employees",
  account_managers = "account_managers",
  products = "products",
  product_details = "product_details",
  product_details_id = "product_details/:id",
  pages = "pages",
  companies = "companies",
  partners = "partners",
  partners_id = "partners/:id",
  administrationUsers = "administration/users",
  administrationRole = "administration/role",
  administrationWebSocket = "administration/web-socket",
  administrationPermissionNameId = "administration/permission/:name/:id",
  tools = "tools",
  news = "news",
  news_admin = "news_admin",
  support = "support",
  contact = "contact",
  faq = "faq",
  verificationTool = "verification-tool",
  cc_instances = "cc_instances",
  cc_campaigns = "cc_campaigns",
  cc_campaignsId = "cc_campaigns/:id",
  cc_classifiers = "cc_classifiers",
  cc_classifiersInstanceInstance_idCampaignCampaign_id = "cc_classifiers/instance/:instance_id/campaign/:campaign_id",
  cc_classifiersInstanceInstance_id = "cc_classifiers/instance/:instance_id",
  cc_classifiersCampaignCampaign_id = "cc_classifiers/campaign/:campaign_id",
  li_classifiers_cc = "li_classifiers_cc",
  cc_webhooks = "cc_webhooks",
  cc_leads_direction = "cc_leads_direction",
  profile = "profile",
  createNewOrder = "createNewOrder",
  createNewOrder_cc_id = "createNewOrder/:fcc_id",
  orders = "orders",
  order_details_id = "order_details/:id",
  courierStatusMapping = "courierStatusMapping",
  order_details = "order_details/:id",
  countries_dictionary = "countries_dictionary",
  cities_dictionary = "cities_dictionary",
  districts_dictionary = "districts_dictionary",
  couriers_dictionary = "couriers_dictionary",
  statuses_dictionary = "statuses_dictionary",
  locations_dictionary = "locations_dictionary",
  posto_codes_dictionary = "posto_codes_dictionary",
  payment_methods_dictionary = "payment_methods_dictionary",
  products_categories_dictionary = "products_categories_dictionary",
  sales_channels_dictionary = "sales_channels_dictionary",
  subdistricts_dictionary = "subdistricts_dictionary",
  provinces_dictionary = "provinces_dictionary",
  notFound = "notFound",
  users_information_id = "users_information/:id",
  system_variables = "system_variables",
  traffic_sources = "traffic_sources",
  couriers_api_mapping = "couriers_api_mapping",
  couriers_statuses = "couriers_statuses",
  system_classifiers = "system_classifiers",
  salesRemarketing = "sales/remarketing",
  sales_p2 = "sales/p2",
  sales_resale = "sales/p3/resale",
  sales_winback = "sales/winback",
  k8_services = "administration/k8_services",
  settings = "administration/settings",
}
