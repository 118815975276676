import * as React from 'react';
import styles from './styles/SessionExpiredContent.module.scss';
import { createAbsoluteUrl } from '../../../helpers/UrlCreator';
import { LogoAsLinkToLandingPage } from '../../../components/logoAsLinkToLandingPage/LogoAsLinkToLandingPage';
import { resources } from '../../../common/Resources';

export class SessionExpiredContent extends React.PureComponent {
    render() {
        return (
            <section className={styles.grid}>
                <div
                    className={styles.sessionExpired}
                >
                    {resources.statement.session_expired}
                </div>
                <div
                    className={styles.sessionExpiredDescription}
                >
                    {resources.statement.session_expired_description}
                </div>
                <LogoAsLinkToLandingPage />
                <a className={styles.btn} href={createAbsoluteUrl('login')}>{resources.submitSuccess.logIn}</a>
            </section>
        );
    }
}