export function capitalizeFirstLetter(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export function countLettersWithoutWhitespaces(input: string | undefined): number {
    return input ? input.replace(/\s/g, "").length : 0;
}

export function convertStringToEmptyIfUndefined(input: string | undefined): string {
    return input ? input : "";
}

export function trimAndRemoveMiddleWhitespaces(input: string): string {
    return input.trim().replace(/\s\s+/g, ' ');
}

export function isEmptyString(input: string): boolean {
    return input === null || input === undefined || input === '' || input.trim() === ''; 
}