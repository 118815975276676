import React from 'react';
import styles from './styles/Statistic.module.scss';
import { Icon, Spin, Tooltip } from 'antd';
import { ILoading } from '../../../../../../common/interfaces/ILoading';
import { IStatisticProps } from './interfaces/IStatisticProps';
import './styles/Statistic.scss';

export class Statistic extends React.Component<IStatisticProps & ILoading> {
    render() {
        const { stat, name, hint, loading } = this.props;

        return (
            <div className={` ${styles.statistic} ${this.showHover()} ${this.showError()} `}>
                <div className={` ${styles.statistic__digit} ${this.showStatisticColors()} `}>{loading ? <Spin /> : stat}</div>
                <div className={styles.statistic__descr}>
                    <h4>
                        <span>
                            {name} &nbsp;
                                <Tooltip title={<div className={styles.tooltip_style}>{hint}</div>}>
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    </h4>
                </div>
            </div>
        )
    }

    showStatisticColors = () => {
        if (this.props.showStatisticColors && this.props.stat && !this.props.loading) {
            const state = Number(this.props.stat.replace("%", ""));

            if (state <= 5) {
                return styles.low_status;
            }
            if (state > 5 && state <= 15) {
                return styles.middle_status;
            }
            else {
                return styles.high_status;
            }
        }
    }

    showHover = () => {
        return this.props.showHover ? styles.showHover : '';
    }

    showError = () => {
        return this.props.showError ? styles.showError : ''
    }
}



