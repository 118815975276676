import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/PaymentMethodDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createPaymentMethodsDictionary, updatePaymentMethodsDictionary } from '../../../../services/dictionaries/PaymentMethodsDivtionaryService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IPaymentMethods } from '../interfaces/IPaymentMethods';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class TemplatePaymentMethodDictionaryForm extends React.Component<IPaymentMethods & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<IPaymentMethods>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='is_active'
                    label={resources.labels.isActive}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                    labelTextAlignLeft
                    boldLabel
                />
                 <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const PaymentMethodDictionaryForm = withFormik<IPaymentMethods & IUpdateObjInArray & IAddObjToArray & IId, IPaymentMethods>({
    mapPropsToValues: (props: IPaymentMethods) => {
        return {
            name: props.name,
            is_active: Boolean(props.is_active) ? '1' : '0',
        };
    },

    validate: (values: IPaymentMethods) => {
        const errors: FormikErrors<IPaymentMethods> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }

        return errors;
    },

    handleSubmit: (values: IPaymentMethods, bag: FormikBag<IPaymentMethods & IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId, IPaymentMethods>) => {
        const object: IPaymentMethods = {
            name: values.name,
            is_active: values.is_active,
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.name === bag.props.name
            && values.is_active === bag.props.is_active
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(TemplatePaymentMethodDictionaryForm);

export default PaymentMethodDictionaryForm;

const createQuery = async (obj: IPaymentMethods, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId) => {
    const createQuery =
        `INSERT INTO li_payment_methods
            (
                name,
                is_active
            )
        VALUES
            (
                "${obj.name}",
                ${obj.is_active}
            )`;

    const updateQuery =
        `UPDATE li_payment_methods SET
                name = "${obj.name}",
                is_active = ${obj.is_active}
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updatePaymentMethodsDictionary(updateQuery)
            .then(() => {
                obj.is_active = Number(obj.is_active);
                obj.id = Number(bag.id!);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);
                openSuccessNotification(resources.statement.payment_method_updated);
            });
    }
    else {
        await createPaymentMethodsDictionary(createQuery)
            .then((response) => {
                obj.id = response;
                obj.key = response;
                obj.is_active = Number(obj.is_active);
                obj.created_at = new Date();
                obj.updated_at = new Date();

                bag.addObjToArray!(obj);
                openSuccessNotification(resources.statement.payment_method_created);
            });
    }

    bag.hideModal!();
}