import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { ProfileContent } from './content/ProfileContent';
import { setActiveMenuItem } from '../../reducers/CommonReducer';

class ProfileViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem('');
    }
    
    public render() {
        return (
            <ProfileContent />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const ProfileView: React.ComponentClass = connect(null, mapDispatchToProps)(ProfileViewInner);