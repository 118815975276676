import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrlP2 = createGatewayAbsoluteUrl(resourceDef.ResaleP2GetProducts)
const getUrlP3 = createGatewayAbsoluteUrl(resourceDef.ResaleP3GetProducts);
const runningP2StatusUrl = createGatewayAbsoluteUrl(resourceDef.ResaleP2Running);
const runningP3StatusUrl = createGatewayAbsoluteUrl(resourceDef.ResaleP3Running);
const setP2ProductRulesForResaleURL = createGatewayAbsoluteUrl(resourceDef.SetP2ProductRulesForResale);
const setP3ProductRulesForResaleURL = createGatewayAbsoluteUrl(resourceDef.SetP3ProductRulesForResale);


export const getP3ResalesProducts = () => axios.get(getUrlP3)
    .then(response => { return response.data; })
    .catch(error => {
        console.log('getResalesProducts M66');
        console.log(error.response);
        return undefined;
    });

export const changeP3ResaleRunningStatus = () => axios.post(runningP3StatusUrl)
    .then(() => { return true; })
    .catch(error => {
        console.log('changeResaleRunningStatus M66');
        console.log(error.response);
        return false;
    });

export const setP3ProductRulesForResale = (obj: any) => axios.post(setP3ProductRulesForResaleURL, { ...obj })
    .then(() => { return true; })
    .catch(error => {
        console.log('setProductRulesForResale M66');
        console.log(error.response);
        openErrorNotification(error.response.data.err)
        return false;
    });

export const getP2ResalesProducts = () => axios.get(getUrlP2)
    .then(response => { return response.data; })
    .catch(error => {
        console.log('getResalesProducts M66');
        console.log(error.response);
        return undefined;
    });

export const changeP2ResaleRunningStatus = () => axios.post(runningP2StatusUrl)
    .then(() => { return true; })
    .catch(error => {
        console.log('changeResaleRunningStatus M66');
        console.log(error.response);
        return false;
    });

export const setP2ProductRulesForResale = (obj: any) => axios.post(setP2ProductRulesForResaleURL, { ...obj })
    .then(() => { return true; })
    .catch(error => {
        console.log('setProductRulesForResale M66');
        console.log(error.response);
        openErrorNotification(error.response.data.err)
        return false;
    });