import * as React from "react";
import LoginForm from "../forms/login/LoginForm";
import logoCCSimplified from "../../../common/images/logo-cc.svg";
import banner from "../../../common/images/banner.png";
import { resources } from "../../../common/Resources";

export class LoginContent extends React.Component {
  public render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "100px",
          backgroundColor: "white",
        }}
      >
        <img src={logoCCSimplified} alt="logo" style={{ maxWidth: "230px" }} />

        <span
          style={{
            display: "block",
            marginBottom: "25px",
            color: "#22b74b",
            fontSize: "19px",
          }}
        >
          {resources.loginPage.logIn}
        </span>

        <LoginForm />

        <img src={banner} alt="banner" style={{ marginTop: "30px" }} />
      </div>
    );
  }
}
