import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { ICreateTransaction } from './interfaces/ICreateTransaction';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.DepositTransaction);

export const createDepositTransaction = (object: ICreateTransaction) => axios.post(getUrl, { ...object })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification("M49 service Error -> createDepositTransaction()");
        return false;
    });