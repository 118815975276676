import * as React from "react";
import styles from "./styles/NewsContent.module.scss";
import { getActiveNews } from "../../../../services/m61_news/NewsService";
import { INews } from "../../newsAdmin/interfaces/INews";
import { NewsList } from "../../../_common/components/newsList/NewsList";
import { resources } from "../../../../common/Resources";
import { Select } from "antd";
import { ViewHeader } from "../../../../components/viewHeader/ViewHeader";

const { Option } = Select;

export class NewsContent extends React.Component {
  _isMounted = false;

  state = {
    loading: false,
    news_list: [] as INews[],
    news_type_filter: 1,
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.getLatestNews();
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    const { news_type_filter, news_list, loading } = this.state;

    let filtered_news_list: INews[] = news_list;

    if (news_type_filter === 2)
      filtered_news_list = news_list.filter((x: INews) => x.id_news_type === 1);
    if (news_type_filter === 3)
      filtered_news_list = news_list.filter((x: INews) => x.id_news_type === 2);
    if (news_type_filter === 4)
      filtered_news_list = news_list.filter((x: INews) => x.id_news_type === 3);

    return (
      <>
        <header className={`customAnt--newsSelect ${styles.header}`}>
          <ViewHeader title={resources.newsView.news} />
          <Select
            style={{ width: 105 }}
            onChange={this.onChangeFilterType}
            defaultValue={news_type_filter}
          >
            {/* {
                            news_type.map(item => {
                                return ( */}
            <Option key={1} value={1}>
              {resources.news_type.all_news}
            </Option>
            <Option key={2} value={2}>
              {resources.news_type.new}
            </Option>
            <Option key={3} value={3}>
              {resources.news_type.important}
            </Option>
            <Option key={4} value={4}>
              {resources.news_type.info}
            </Option>
            {/* )
                            }) */}
            {/* } */}
          </Select>
        </header>
        <section className={styles.list}>
          <NewsList
            data={filtered_news_list}
            pagination={true}
            loading={loading}
          />
        </section>
      </>
    );
  }

  onChangeFilterType = (event: any) => {
    this.setState({ news_type_filter: event });
  };

  getLatestNews = () => {
    this.setState({ loading: true });

    const object = {
      columns: [
        "date_from",
        "id_news_type",
        "title_en",
        "title_ru",
        "body_en",
        "body_ru",
      ],
    };

    getActiveNews(object)
      .then((news_list) => {
        this._isMounted && this.setState({ news_list });
      })
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };
}
