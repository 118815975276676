import React from "react";
import { Icon } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { queryClient } from "../../../../App";

import { useFilters } from "../../lib/filters-state";
import { dowloadStatistics } from "../../../../services/m55_leads_statistics/LeadsStatisticsService";
import { createApiFiltersData } from "../../lib/filters-data";

export const ExportData = () => {
  const { param } = useParams<{ param: string }>();
  const { state } = useFilters();
  const { mutate, isLoading } = useMutation(
    ({ columnOrder, filters }: { columnOrder: string[]; filters: any }) =>
      dowloadStatistics(filters, columnOrder)
  );

  const onExportData = () => {
    const tableSettings = queryClient.getQueryData("table-settings");
    const filtersData = createApiFiltersData({ ...state, findBy: param });
    mutate({
      filters: filtersData,
      columnOrder: (tableSettings as any).columnOrder,
    });
  };

  return (
    <div
      style={
        isLoading
          ? { pointerEvents: "none", color: "#d9d9d9" }
          : { cursor: "pointer", color: "#009eed" }
      }
      onClick={onExportData}
    >
      <span style={{ color: "inherit", marginRight: "5px" }}>Export XLSX</span>

      <Icon type="file-excel" style={{ color: "#inherit" }} />
    </div>
  );
};
