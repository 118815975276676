import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { IClassifierFilters } from './interfaces/IClassifierFilters';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const updateUrl = createGatewayAbsoluteUrl(resourceDef.FocusClassifiersUpdate);
const getUrl = createGatewayAbsoluteUrl(resourceDef.FocusClassifiersGet);
const libUrl = createGatewayAbsoluteUrl(resourceDef.FocusClassifiersLib);
const resetUrl = createGatewayAbsoluteUrl(resourceDef.FocusClassifiersReset);

export const updateFocusClassifiers = (obj?: IClassifierFilters) => axios.post(updateUrl, { ...obj })
    .then((response) => {
        if (response.data.status === 208) {
            openAlertNotification(response.data.message);

            return undefined;
        }
        else if (response.data.status === 200) {
            openSuccessNotification('Classifiers updated!');
            
            return response.data.data;
        }

        return [];
    })
    .catch((error) => {
        if (error.response && error.response.status === 409)
            openAlertNotification('Another process is running')
        else
            openErrorNotification('Timeout expired')

        return false;
    });

export const getFocusClassifiers = (obj: IClassifierFilters) => axios.post(getUrl, { ...obj })
    .then((response) => {
        return response.data.data;
    })
    .catch((error) => {
        if (error.response && error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            openErrorNotification('Timeout expired');
        }

        return [];
    });

export const libFocusClassifiers = () => axios.post(libUrl)
    .then(() =>
        openSuccessNotification('Classifiers libs updated!')
    )
    .catch((error) => {
        if (error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            openErrorNotification(' libFocusClassifiers undefined');
        }
    });

export const resetFocusClassifiers = () => axios.post(resetUrl)
    .then(() =>
        openSuccessNotification('Classifiers locked microservice reseted!')
    )
    .catch((error) => {
        if (error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            openErrorNotification(' resetFocusClassifiers undefined');
        }
    });