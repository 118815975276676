import RadioGroup from 'antd/lib/radio/group';
import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { IRadioButtonFProps } from './interfaces/IRadioButtonFProps';
import { IRadioButtonFState } from './interfaces/IRadioButtonFState';
import { Radio } from 'antd';
import { RadioButtonItem } from '../../types/RadioButtonItem';
import { RadioChangeEvent } from 'antd/lib/radio';
import './styles/RadioButtonListF.scss';

export default class RadioButtonF extends React.Component<IBaseFormikProps & IRadioButtonFProps, IRadioButtonFState> {
	constructor(props: IBaseFormikProps & IRadioButtonFProps) {
		super(props)

		this.state = {
			radioButtonItems: [],
			isError: false,
		};

		this.onChange = this.onChange.bind(this);
	}

	public componentDidMount() {
		this.setState({ radioButtonItems: this.props.radioButtonItems ? this.props.radioButtonItems : [] });
	}

	public render() {
		return (
			<BaseForm {...this.props}>
				<RadioGroup
					onChange={this.onChange}
					disabled={this.props.readOnly}
					value={this.props.values[this.props.name] !== 0 ? this.props.values[this.props.name] : undefined}
					style={{width: '100%'}}
					defaultValue={this.props.values[this.props.name]}
				>
					<div className={`radioGrid ${this.props.flexDirectionColumn ? 'flexDirectionColumn' : null}`}>
						{this.props.radioButtonItems.map(radioButtonItem => (
							this.renderOption(radioButtonItem)
						))}
					</div>
				</RadioGroup>
			</BaseForm>
		)
	};

	private onChange(value: RadioChangeEvent) {
		this.props.setFieldTouched(this.props.name);
		this.props.setFieldValue(this.props.name, value.target.value);
	}

	private renderOption(radioButtonItem: RadioButtonItem) {
		return (
			<Radio className="radioStyle" value={radioButtonItem.key} key={radioButtonItem.key.toString()}>{radioButtonItem.label}</Radio>
		);
	}
}
