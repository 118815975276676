import React from 'react';
import styles from '../styles/UserInformationsForm.module.scss';
import TextInputF from '../../../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { copyClipboard } from '../../../../../../helpers/CopyHelpers';
import {
  Form,
  FormikBag,
  FormikErrors,
  FormikProps,
  withFormik
} from 'formik';
import { IUserPasswordProps } from '../interfaces/IUserPasswordProps';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../../../../helpers/NotificationHelper';
import { resources } from '../../../../../../common/Resources';
import { updateUserPassword } from '../../../../../../services/m29_users_management/GeneralUserService';
import { generateRandomStringUniversal } from '../../../../../../helpers/RandomStringGeneratorUniversal';

export class TemplateUserPasswordForm extends React.Component<IUserPasswordProps & FormikProps<IUserPasswordProps>> {
  public render() {
    return (
      <Form className='formStyle'>
        <div className={styles.sectionDivider} />
        <section style={{ textAlign: 'left' }}>
          <div className={styles.grid_col_4}>
            <div style={{ marginTop: 2 }}>
              <TextInputF
                {...this.props}
                id={undefined}
                name='password'
                label={resources.labels.password}
                boldLabel
                labelTextAlignLeft
                required
              />
            </div>
            <div style={{ marginTop: 39 }}>
              <Button
                style={{ width: '100%' }}
                type='primary'
                htmlType="submit"
              >
                Save password
              </Button>
            </div>
            <div style={{ marginTop: 39 }}>
              <Button
                style={{ width: '100%' }}
                type='danger'
                onClick={this.forcePasswordChange}
                disabled
              >
                Force password change
              </Button>
            </div>
            <div style={{ marginTop: 39 }}>
              <Button
                style={{ width: '100%' }}
                onClick={this.generatePassword}
              >
                Generate password
              </Button>
            </div>
          </div>
        </section>
      </Form >
    );
  };

  forcePasswordChange = () =>
    openAlertNotification("Force password change action => do podpięcia!");

  generatePassword = (event: any) => {
    const password = generateRandomStringUniversal(30);

    this.props.setFieldValue('password', password);

    copyClipboard(event, password);
  }
}

const UserPasswordForm = withFormik<IUserPasswordProps, IUserPasswordProps>({
  mapPropsToValues: () => {
    return {
      password: undefined
    };
  },

  validate: (values: IUserPasswordProps) => {
    const errors: FormikErrors<IUserPasswordProps> = {};

    if (!values.password) {
      errors.password = resources.validation.required;
    }
    else if (values.password.length < 6)
      errors.password = "Password ist too short";
    return errors;
  },

  handleSubmit: (values: IUserPasswordProps, bag: FormikBag<IUserPasswordProps, IUserPasswordProps>) => {
    const object: IUserPasswordProps = {
      password: values.password
    }

    changePassword(Number(bag.props.id), object);
  }

})(TemplateUserPasswordForm);

export default UserPasswordForm;

const changePassword = (id: number, object: IUserPasswordProps) => {
  updateUserPassword(id, object)
    .then(response => {
      !response.error ? openSuccessNotification(resources.statement.updated) : openErrorNotification(response.message);
    });
}