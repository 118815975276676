import * as React from 'react';
import { ActivateAccountContent } from './content/ActivateAccountContent';
import { IActivateToken } from './interfaces/IActiveToken';
import { RouteComponentProps } from 'react-router-dom';

export class ActivateAccountView extends React.Component<RouteComponentProps<IActivateToken>> {
    public render() {
        return (
            <ActivateAccountContent activate_token={this.props.match.params.activate_token} />
        )
    };
}