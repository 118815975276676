import { INavigationItem } from './interfaces/INavigationItem';
import { INavigationOptionalParams } from './interfaces/INavigationOptionalParams';

export class NavigationItem implements INavigationItem {
    public link: string;
    public name: string;
    public key: string;
    public isMenuSubItem: boolean;

    public optionalParams?: INavigationOptionalParams | undefined;

    constructor(link: string, name: string, key: string, optionalParams?: INavigationOptionalParams) {
        this.link = link;
        this.name = name;
        this.key = key;
        this.isMenuSubItem = false;

        this.optionalParams = optionalParams;
    }
}