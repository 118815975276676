import React from 'react';
import { checkHoursBetweenDates, getCurrentDateTime } from './DateHelpers';
import { getDictionaryNoAuth, getSelectedDictionaries } from '../services/m40_libraries/LibrariesService';
import { getToken } from './LocalStorageHelpers';
import { Icon, Spin } from 'antd';
import { IDictionaryValue } from '../components/templateForm/types/dictionaries/IDictionaryValue';
import { openErrorNotification, openSuccessNotification } from './NotificationHelper';
import { resources } from '../common/Resources';

const antIcon = <Icon type="loading" style={{ fontSize: 12 }} spin />;

let lastDictCheck = null as null | number;

type dictionariesE =
    // Dictionaries
    'countriesNameD' | 'countriesCodeD' | 'currencyD' | 'newsTypesD' | 'statusesD' | 'categoriesD' | 'orderStatuses' |
    // Filters
    'statusesF' | 'newsTypesF';

const li_array = [
    {
        "name": "li_countries",
        "column": "id, name, code, currency, timezone, is_active"
    },
    {
        "name": "li_product_cat",
        "column": "id, name, is_active"
    },
    {
        "name": "li_order_statuses",
        "column": "id, name"
    },
    {
        "name": "li_statuses",
        "column": "id, name"
    },
    {
        "name": "li_news_type",
        "column": "id, name"
    }
];

export const checkDictionariesInterval = () => {
    const token = getToken();

    if (token) {
        checkDictionaries();
        openSuccessNotification("Dictionary updated!");
    }
}

export const checkDictionaries = async () => {
    const date = new Date(localStorage.getItem("dictionaryDateUpdated")!);
    const currentDate = new Date(getCurrentDateTime());

    if (checkIfAllDictionariesExists()) {
        await getDictionariesFromDB();
    }
    else if (checkHoursBetweenDates(Number(currentDate), Number(date), 1)) {
        await getDictionariesFromDB();
    }
}

const getDictionariesFromDB = async () => {
    // force at least 5 seconds wait before retry
    if (lastDictCheck === null || (lastDictCheck && new Date().getTime() - (1000 * 5) > lastDictCheck)) {
        lastDictCheck = new Date().getTime();
        await getSelectedDictionaries(li_array)
            .then(response => {
                if (Object.keys(response).length !== li_array.length) {
                    openErrorNotification('M40 - Error getting dictionaries data');
                    return;
                }
                const countriesNameD = response['li_countries'].filter((x: any) => x.is_active === 1).map((item: any) => { return { id: item.id, name: item.name, timezone: item.timezone } });
    
                const countriesCodeD = response['li_countries'].filter((x: any) => x.is_active === 1).map((item: any) => { return { id: item.id, name: item.code } });
                const currencyD = response['li_countries'].filter((x: any) => x.is_active === 1).map((item: any) => { return { id: item.id, name: item.currency } });
                const statusesD = response['li_statuses'].map((item: any) => { return { id: item.id, name: item.name } });
                const categoriesD = response['li_product_cat'].filter((x: any) => x.is_active === 1).map((item: any) => { return { id: item.id, name: item.name } });
                const orderStatuses = response['li_order_statuses'].map((item: any) => { return { id: item.id, name: item.name } });
                const statusesFilters = response['li_statuses'].map((item: any) => { return { value: item.id, text: item.name } });
                const newsTypesFilters = response['li_news_type'].map((item: any) => { return { value: item.id, text: item.name } });
                const newsTypesD = response['li_news_type'].map((item: any) => { return { id: item.id, name: item.name } });
    
                localStorage.setItem("countriesNameD", JSON.stringify(countriesNameD));
                localStorage.setItem("countriesCodeD", JSON.stringify(countriesCodeD));
                localStorage.setItem("currencyD", JSON.stringify(currencyD));
                localStorage.setItem("statusesD", JSON.stringify(statusesD));
                localStorage.setItem("categoriesD", JSON.stringify(categoriesD));
                localStorage.setItem("newsTypesD", JSON.stringify(newsTypesD));
                localStorage.setItem("orderStatuses", JSON.stringify(orderStatuses));
                // Filters
                localStorage.setItem("statusesF", JSON.stringify(statusesFilters));
                localStorage.setItem("newsTypesF", JSON.stringify(newsTypesFilters));
                // Rest
                localStorage.setItem("dictionaryDateUpdated", getCurrentDateTime()); // TODO: Nazwa do zmiany po testach
            });
    }
}

const checkIfAllDictionariesExists = (): boolean => {
    return localStorage.getItem("dictionaryDateUpdated") === null
        || localStorage.getItem("countriesNameD") === null
        || localStorage.getItem("countriesCodeD") === null
        || localStorage.getItem("currencyD") === null
        || localStorage.getItem("statusesD") === null
        || localStorage.getItem("categoriesD") === null
        || localStorage.getItem("orderStatuses") === null
        // Filters
        || localStorage.getItem("statusesF") === null
        || localStorage.getItem("newsTypesF") === null;
}

export const getDictionary = (name: dictionariesE) => {
    const dict = localStorage.getItem(name);
    if (dict === null) return [];

    return JSON.parse(dict);
}

export const fetchDictNoAuth = async (data: any) => {
    return getDictionaryNoAuth(data);
}

export const findInDictionaryAndReturnValue = (id: number, array: Array<IDictionaryValue>) => {
    if (array) {
        const item = array.find(x => Number(x.id) === id);

        if (item) {
            return item.name;
        }

        return '-';
    }

    return resources.statement.refresh_page;
}

export const findStringInDictionaryAndReturnValue = (id: string, array: Array<IDictionaryValue>) => {
    if (array) {
        const item = array.find(x => x.id === id);

        if (item) {
            return item.name;
        }

        return '-';
    }

    return resources.statement.refresh_page;
}

export const findInDictionaryAndReturnValueWithSpin = (id: number, array: Array<IDictionaryValue>) => {
    return array && array.length > 0 ? findInDictionaryAndReturnValue(id, array) : <Spin indicator={antIcon} size='small' />;
}