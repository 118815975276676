import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const salesChannels = 'SELECT id, id as "key", name, created_at, updated_at, is_active FROM li_sales_channels';

export const getSalesChannelsDictionaryList = () => axios.post(sourceUrl, { data: { sql: salesChannels } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getSalesChannelsDictionaryList');
        return [];
    });

export const getSalesChannels = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getSalesChannels');
        return [];
    });


export const updateSalesChannelsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateSalesChannelsDictionary');
        return false;
    });

export const createSalesChannelsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createSalesChannelsDictionary');
        return false;
    });

export const deleteSalesChannelsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createSalesChannelsDictionary');
        return false;
    });