import React, { Component } from 'react';
import styles from './BasketItem.module.scss';

interface IBasketItemProps {
    name: string;
    deleteItemFromBasket: (pricing_idx: number | string) => void;
    idx: number | string;
    key: number | string;
    orderValue: number;
    discountValue: number;
    setStepTo: (step: number) => void;
    basketLength: number;
}

export class BasketItem extends Component<IBasketItemProps> {
    render() {
        return (
            <section className={styles.cartItem}>
                <div className={styles.cartItem__leftCol}>
                    <div className={styles.cartItem__nameRow}>
                        <p>{this.props.name}</p>
                        <p>{(this.props.orderValue + this.props.discountValue) / 100}</p>
                    </div>
                    <div className={styles.cartItem__discountRow}>
                        <p>Discount price</p>
                        <p>{this.props.discountValue / 100}</p>
                    </div>

                </div>
                <div className={styles.cartItem__rightCol}>
                    <button className={styles.deleteBtn} onClick={() => {
                        if (this.props.basketLength === 1) {
                            this.props.setStepTo(2);
                        }
                        this.props.deleteItemFromBasket(this.props.idx);
                    }}></button>
                </div>
            </section>

        )
    }
}


