import React from 'react';
import { Icon, Input } from 'antd';
import { IOnChange } from '../../common/interfaces/IOnChange';
import { ITextInputFProps } from './interfaces/ITextInputFProps';

export class TextInputF extends React.Component<ITextInputFProps & IOnChange> {
    render() {
        return (
            <>
                {this.props.label ? <label htmlFor={this.props.name}><b>{this.props.label} </b></label> : null}
                {this.props.required ? <label style={{ color: 'red' }}> * </label> : null}
                <Input
                    id={this.props.id}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    allowClear={this.props.allowClear}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    prefix={this.props.iconType ? this.generateIcon() : null}
                    type={this.props.type}
                    style={{ margin: '0 0 5px 0', width: this.props.width }}
                    disabled={this.props.disabled}
                    suffix={this.props.suffix}
                />
                {this.props.error && <div style={{ color: 'red' }}>{this.props.error}</div>}
            </>
        );
    }

    generateIcon = (): JSX.Element => {
        return (
            <Icon type={this.props.iconType} style={{ color: 'rgba(0,0,0,.25)' }} />
        )
    }
}