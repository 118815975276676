import React, { Fragment } from 'react'

export default class UnknownCountry extends React.Component {

    render() {
        return (
            <Fragment>
                {'This country is not supported.'}
            </Fragment>
        )
    }
}