import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { resourceDef } from '../../common/ResourceDef';

const getInfoMicroUrl = createGatewayAbsoluteUrl(resourceDef.K8MicroInf);
const getHealthPodsCheckUrl = createGatewayAbsoluteUrl(resourceDef.K8HealthPodsCheck);

export const getK8MikroInf = () => axios.get(getInfoMicroUrl)
    .then(response => { 
        return response.data.Namespaces;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getK8MikroInf K8');
        return [];
    });

export const getK8HealthPodsCheck = () => axios.get(getHealthPodsCheckUrl)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getK8HealthPodsCheck K8');
        return [];
    });