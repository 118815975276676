import React from 'react';
import { Table, Tooltip, Icon } from 'antd';
import { DataTable } from '../../../../components/others/data/DataTable';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { displayIconStatusWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { createAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { Link } from 'react-router-dom';
import { resources } from '../../../../common/Resources';
import { findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { getActivityFilters } from '../../../../common/dictionaries/Filters'

const { Column } = Table;

export class CampaignsTable extends DataTable {
    updateCountryDate = () => {
        let instances = this.props.instances.filter(x => x.id !== 0);

        if (this.props.data && this.props.data !== null) {
            const result = this.props.data.map(
                campaign => (
                    {
                        ...campaign,
                        id_country: instances.length > 0 ? (instances.find(x => x.id === campaign.id_instance) ? instances.find(x => x.id === campaign.id_instance).country_id : undefined) : undefined
                    })
            );
            
            return result;
        }
    }

    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                width='100px'
                align='center'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.focusId}
                dataIndex='id_external'
                key='id_external'
                width='120px'
                align='center'
                sorter={(a, b) => a.id_external - b.id_external}
                {...this.getColumnSearchProps('id_external')}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.active, resources.columnTitle.active_short)}
                dataIndex='is_active'
                key='is_active'
                width='100px'
                align='center'
                sorter={(a, b) => a.is_active - b.is_active}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                render={(record) => (
                    <span>
                        {displayIconStatusWithTooltip(record)}
                    </span>
                )}
            />,
            <Column
                title={resources.columnTitle.campaign_name}
                key='name'
                align='center'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
                render={(record, index) => (
                    <Tooltip placement="rightTop" title={resources.tooltips.viewCampaign}>
                        <Link to={createAbsoluteUrl(`cc_classifiers/instance/${index.id_instance}/campaign/${index.id}`)}>
                            {record.name} <Icon type="link" />
                        </Link>
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.country}
                key='id_country'
                width='140px'
                align='center'
                sorter={(a, b) => a.id_country - b.id_country}
                render={(record) => (
                    findInDictionaryAndReturnValue(record.id_country, getDictionary('countriesNameD'))
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    <span>
                        {convertNumberToDateTimeString(record.updated_at)}
                    </span >
                )}
            />
        ];

        return (
            <DataTable
                tableName={'CcCampaignsTable'}
                columns={columns}
                data={this.updateCountryDate()}
                loading={this.props.loading}
                defaultPageSize={100}
            />
        )
    }
}