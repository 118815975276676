import * as React from 'react';
import avatar from './images/manager_avatar.png';
import styles from './styles/YourPartner.module.scss';
import { connect } from 'react-redux';
import { IAppState } from '../../../../store';
import { IAppUserInfoModel } from '../../../../models/IAppUserInfoModel';
import { IRandomKey } from '../../interfaces/IRandomKey';
import { resources } from '../../../../common/Resources';

interface IYourPartnerInnerProps {
    appUserInfo?: IAppUserInfoModel;
    name?: string;
}

export class YourPartnerInner extends React.Component<IYourPartnerInnerProps & IRandomKey> {
    componentWillReceiveProps = (props: IRandomKey) => {
        props.random_key && this.forceUpdate();
    }

    render() {
        return (
            <div className={styles.grid}>
                <div className={styles.img}>
                    <img src={avatar} alt="manager avatar" />
                    <div style={{ opacity: 1 }} className={styles.statusDot}></div>
                </div>
                <div className={styles.text}>
                    <p className={`h6`}>{resources.labels.your_manager}</p>
                    <h6 className={`h6__bold`}>{this.props.name ? this.props.name : 'No manager'}</h6>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        appUserInfo: state.commonState.appUserInfo,
    }
}

export const YourPartner = connect(mapStateToProps)(YourPartnerInner);

