import * as React from 'react';
import img from '../../../../../common/images/success.svg';
import styles from './styles/AfterRegisterContent.module.scss';
import { createAbsoluteUrl } from '../../../../../helpers/UrlCreator';
import { resources } from '../../../../../common/Resources';

export const AfterRegisterContents = () => {
    return (
        <section className={styles.grid}>
            <img src={img} alt="success" />
            <h5 className={`h3`}>{resources.submitSuccess.congrats}</h5>
            <p className={`h5`}>{resources.submitSuccess.nowYouCan}</p>
            <a className={`h4__bold ${styles.btn}`} href={createAbsoluteUrl('login')}>{resources.submitSuccess.logIn}</a>
        </section>
    );
}