import * as React from 'react';
import styles from './styles/TableTitle.module.scss';
import { ITitleProps } from './interfaces/ITitleProps';

export class Title extends React.Component<ITitleProps> {
    render() {
        const { title } = this.props;
        return (
            <h2 className={styles.header}>{title}</h2>
        );
    };
}