import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import UsersRoleForm from '../forms/UsersRoleForm';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { deleteRole, getRolesListD, updateRole } from '../../../../services/m29_users_management/RoleService';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IUpdateRole } from '../../../../services/m29_users_management/interfaces/IUpdateRole';
import { IUsersRole } from '../../interfaces/IUsersRole';
import { IUsersRoleFormProps } from '../forms/interfaces/IUsersRoleFormProps';
import { openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageTitle } from '../../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import { UsersRolesTable } from '../table/UsersRolesTable';

export class UsersRoleContent extends React.Component {
    _isMounted = false;

    state = {
        rolesList: Array<IUsersRole>(),
        roleD: Array<IDictionaryValue>(),
        roleF: Array<IDictionaryValue>(),
        loading: false
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.setState({ loading: true });

        this.getUsersRole();
        this.getRolesD();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    public render() {
        return (
            <TableContainer>
                <PageTitle>{resources.pageTitle.administrationUsersRole}</PageTitle>
                <RefreshTableButton
                    className="marginBottom"
                    loading={this.state.loading}
                    onClick={this.getUsersRole}
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.AddNewRoleAdministrationModalButton}>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewRole}
                            buttonTitle={resources.modalButtonsTitle.addNewRole}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewRole}
                            icon={"plus"}
                            disabled={this.state.loading}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                        >
                            <UsersRoleForm
                                addObjToArray={this.addObjToArray}
                                roleD={this.state.roleD}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <ClearBoth />
                <UsersRolesTable
                    data={this.state.rolesList}
                    roleF={this.state.roleF}
                    loading={this.state.loading}
                    changeStatus={this.changeUserRoleStatus}
                    updateArray={this.updateArray}
                    deleteRole={this.deleteRole}
                />
            </TableContainer>
        )
    };

    getUsersRole = async () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                "id",
                "name",
                "description",
                "is_active",
                "created_at",
                "updated_at",
            ]
        };

        await getRolesListD(object)
            .then((response) => {
                const rolesList = response.map((item: any) => { return { ...item, key: item.id } });

                this._isMounted && this.setState({ rolesList });
            })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    getRolesD = async () => {
        const object = {
            columns: [
                "id",
                "name"
            ],
            is_activate: 1
        };

        await getRolesListD(object)
            .then(roleD => { this._isMounted && this.setState({ roleD }); });
    }

    changeUserRoleStatus = async (id: number, is_active: boolean) => {
        const object: IUpdateRole = {
            is_active: Number(!is_active)
        };

        await updateRole(id, object)
            .then(() => {
                this._isMounted && this.changeStatus(id);

                openSuccessNotification(resources.statement.status_updated);
            });
    }

    changeStatus = (id: number) => {
        const { rolesList } = this.state;
        const index = rolesList.findIndex(x => Number(x.id) === id);

        rolesList[index].is_active = !rolesList[index].is_active;
        rolesList[index].updated_at = new Date();

        this.setState({ rolesList });
    }

    updateArray = (id: number, obj: IUsersRoleFormProps) => {
        const { rolesList } = this.state;
        const index = rolesList.findIndex(x => Number(x.id) === id);

        rolesList[index].name = obj.name!;
        rolesList[index].description = obj.description!;
        rolesList[index].updated_at = new Date();

        this.setState({ rolesList });
    }

    deleteRole = async (id: number) => {
        deleteRole(id)
            .then((response) => {
                if (response) {
                    this.deleteRoleFromList(id);
                }
            });
    }

    deleteRoleFromList = (id: number) => {
        let rolesList = this.state.rolesList.filter(x => Number(x.id)! !== id);

        this.setState({ rolesList });

        openSuccessNotification(resources.statement.role_deleted);
    }

    addObjToArray = (role: IUsersRoleFormProps) => {
        this.setState({ rolesList: [...this.state.rolesList, role] });
    }
}