import * as React from 'react';
import { IReferrer } from './interfaces/IReferrer';
import { RegisterContent } from './contents/RegisterContent';
import { RouteComponentProps } from 'react-router-dom';

export class RegisterView extends React.Component<RouteComponentProps<IReferrer>> {
    public render() {
        return (
            <RegisterContent referrer={this.props.match.params.referrer} />
        )
    };
}