import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { LeadsRedirectionContent } from './contents/LeadsRedirectionContent';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class LeadsRedirectionViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.cc_leads_direction);
    }

    public render() {
        return (
            <LeadsRedirectionContent />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const LeadsRedirectionView: React.ComponentClass = connect(null, mapDispatchToProps)(LeadsRedirectionViewInner);