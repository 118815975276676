import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';
import { UsersContent } from './contents/UsersContent';

class UsersViewInner extends React.Component<ISetActiveMenuItem>  {
    componentDidMount = async () => {
        this.props.setActiveMenuItem(navPath.administrationUsers);
    }

    render() {
        return (
            <UsersContent />
        )
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const UsersView: React.ComponentClass = connect(null, mapDispatchToProps)(UsersViewInner);