import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { history } from '../../../../helpers/History';
import { Icon } from 'antd';
import { ILogoutAppUser } from '../../../../common/interfaces/ILogoutAppUser';
import { IRandomKey } from '../../interfaces/IRandomKey';
import { logout } from '../../../../services/AuthenticationServices';
import { logoutAppUser } from '../../../../reducers/CommonReducer';
import { resources } from '../../../../common/Resources';
import { setHeader } from '../../../../helpers/Authentication';

class LogoutInner extends React.PureComponent<ILogoutAppUser & IRandomKey> {
    componentWillReceiveProps = (props: IRandomKey) => {
        props.random_key && this.forceUpdate();
    }
    
    logout = async (e: any) => {
        if (e.key === 'profile') {
            history.push(`/${e.key}`);
        }
        else {
            setHeader();
            await logout();
            this.props.logoutAppUser();

            localStorage.clear();
            setHeader();

            history.push(`/`);
            window.scrollTo(0, 0);
        }
    }

    public render() {
        return (
            <div onClick={this.logout} style={{ cursor: 'pointer' }} >
                <Icon type="export" style={{ marginRight: '12px' }} />
                {resources.labels.log_out}
            </div>)
    }
}

const mapDispatchToProps: MapDispatchToProps<ILogoutAppUser, {}> = (
    dispatch: Dispatch,
) => ({
    logoutAppUser: () => {
        dispatch(logoutAppUser());
    }
});

const Logout: React.ComponentClass<IRandomKey> = connect(null, mapDispatchToProps)(LogoutInner);

export default Logout;