import React from 'react';
import styles from './CustomTabNameWithSaveIcon.module.scss';
import { Icon } from 'antd';

interface IProps {
    title: string;
    showIcon: boolean;
}

export const CustomTabNameWithSaveIcon = (props: IProps) => {
    return (
        <div className={styles.grid}>
            <span className={styles.text}>{props.title}</span>
            <span className={styles.icon}>{props.showIcon ? <Icon style={{ fontSize: '12px' }} type="save" theme="twoTone" twoToneColor="#FF3C30" /> : false}</span>
        </div>
    )
}
