import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.SystemClassifiersGet);
const createUrl = createGatewayAbsoluteUrl(resourceDef.SystemClassifiersCreate);
const updateUrl = createGatewayAbsoluteUrl(resourceDef.SystemClassifiersUpdate);

export const getSystemClassifiers = () => axios.post(getUrl)
    .then((response) => { return response.data.data; })
    .catch(() => {
        openErrorNotification(' getSystemClassifiers');
        return [];
    });

export const createSystemClassifiers = (obj: any) => axios.post(createUrl, { ...obj })
    .then((response) => {
        const result: any = response;

        if (result.status === 208) {
            openErrorNotification(result.data.message);
            return undefined;
        }
        else
            return response.data.data.insertId;
    })
    .catch(() => {
        openErrorNotification(' createSystemClassifiers');
        return undefined;
    });

export const updateSystemClassifiers = (obj: any) => axios.post(updateUrl, { ...obj })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateSystemClassifiers');
        return false;
    }); 