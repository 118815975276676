import { useQuery } from "react-query";

import { FILTERS } from "../filters-data";
import { getLeadSearch } from "../../../../services/m56_leads/LeadsService";
import { UPDATE_QTY_FILTER_ENTITIES, useFilters } from "../filters-state";

export const usePubIdEntity = (search: string) => {
  const { dispatch } = useFilters();
  const { data, isLoading } = useQuery(
    ["pub_id", search],
    () => getLeadSearch({ pub_id: search }),
    {
      enabled: Boolean(search),
      onSuccess: (data) =>
        dispatch({
          type: UPDATE_QTY_FILTER_ENTITIES,
          payload: { filter: FILTERS.PUB_ID, qty: data.length },
        }),
    }
  );
  
  return { entity: data, isLoading };
};
