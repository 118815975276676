import React, { ReactElement } from 'react';
import { Badge, Icon, Tooltip } from 'antd';
import { resources } from '../common/Resources';

export const displayIconStatusWithTooltip = (status: boolean): ReactElement => {
    return displayIconWithTooltip(status, resources.informations.active, resources.informations.inactive, 'check-circle', 'close-circle');
}

export const displayIconQualifiedWithTooltip = (status: boolean): ReactElement => {
    return displayIconWithTooltip(status, resources.informations.qualified, resources.informations.notQualified, 'check-circle', 'close-circle');
}

export const displayIconOnlineStatus = (status: boolean): ReactElement => {
    return displayIconWithTooltip(status, resources.informations.online, resources.informations.offline, 'bulb', 'bulb');
}

export const displayOnlineStatuswithBadge = (status: boolean): ReactElement => {
    return displayBadgeWithTooltip(status, resources.informations.online, resources.informations.offline);
}

export const displayIconStatus = (status: boolean): ReactElement => {
    if (!status)
        return <Icon type='close-circle' style={{ color: 'red' }} />

    else
        return <Icon type='check-circle' style={{ color: 'green' }} />
}

export const displayIconIsVisibleWithTooltip = (status: boolean): ReactElement => {
    return displayIconWithTooltip(status, 'Visible', 'Not visible', 'bulb', 'bulb')
}

const displayIconWithTooltip = (status: boolean, positiveMessage: string, negativeMessage: string, iconTypeTrue: string, iconTypeFalse: string): ReactElement => {
    if (status)
        return (
            <Tooltip title={positiveMessage}>
                <Icon type={iconTypeTrue} style={{ color: 'green' }} />
            </Tooltip>
        )

    else
        return (
            <Tooltip title={negativeMessage}>
                <Icon type={iconTypeFalse} style={{ color: 'red' }} />
            </Tooltip>
        )
}

const displayBadgeWithTooltip = (status: boolean, positiveMessage: string, negativeMessage: string): ReactElement => {
    if (status)
        return (
            <Tooltip title={positiveMessage}>
                <Badge status="processing" color='green' />
            </Tooltip>
        )

    else
        return (
            <Tooltip title={negativeMessage}>
                <Badge status="processing" color='red' />
            </Tooltip>
        )
}