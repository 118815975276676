import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';

interface IUnAuthenticatedViewProps {
    isAuthenticated: boolean;
}

class UnAuthenticatedViewInner extends React.Component<IUnAuthenticatedViewProps> {
    public render() {
        return (
            <>
                {this.props.isAuthenticated ? "" : this.props.children}
            </>
        )
    };
}

const mapStateToProps = (state: IAppState): IUnAuthenticatedViewProps => {
    return {
        isAuthenticated: state.commonState.isAuthenticated
    }
}

const UnAuthenticatedView = connect(mapStateToProps)(UnAuthenticatedViewInner);

export default UnAuthenticatedView;