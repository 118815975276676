import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/UsersRoleForm.module.scss';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createRole, updateRole } from '../../../../services/m29_users_management/RoleService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { ICreateRole } from '../../../../services/m29_users_management/interfaces/ICreateRole';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IUpdateArray } from '../../../../common/interfaces/IUpdateArray';
import { IUpdateRole } from '../../../../services/m29_users_management/interfaces/IUpdateRole';
import { IUsersRoleFormProps } from './interfaces/IUsersRoleFormProps';
import { openAlertNotification, openErrorNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { stringToBool } from '../../../../helpers/BooleanHelper';

export class TemplateUsersRoleForm extends React.Component<IUsersRoleFormProps & IHideModal & IId & IUpdateArray & IAddObjToArray & FormikProps<IUsersRoleFormProps>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.userName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    boldLabel
                    allowClear
                    required
                />
                <TextAreaF
                    {...this.props}
                    name="description"
                    label={resources.labels.description}
                    labelTextAlignLeft
                    boldLabel
                    allowClear
                    required
                />
                {!this.props.id &&
                    <>
                        <DictionaryF
                            {...this.props}
                            name="base_role_id"
                            label={resources.labels.basedOnRole}
                            dictionaryValues={this.props.roleD}
                            labelTextAlignLeft
                            boldLabel
                            allowClear={true}
                        />
                        <DictionaryF
                            {...this.props}
                            name="is_active"
                            label={resources.labels.isActive}
                            dictionaryValues={getBooleanValues()}
                            labelTextAlignLeft
                            boldLabel
                        />
                    </>
                }
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form>
        );
    };
}

const UsersRoleForm = withFormik<IUsersRoleFormProps & IUpdateArray & IAddObjToArray, IUsersRoleFormProps & IUpdateArray & IAddObjToArray>({
    mapPropsToValues: (props: IUsersRoleFormProps) => {
        return {
            name: props.name,
            description: props.description,
            is_active: Boolean(props.is_active) ? '1' : '0',
            base_role_id: 6
        };
    },

    validate: (values: IUsersRoleFormProps) => {
        const errors: FormikErrors<IUsersRoleFormProps> = {};

        if (!values.name) {
            errors.name = resources.validation.required;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (!values.description) {
            errors.description = resources.validation.required;
        }
        else if (values.description.length > 255) {
            errors.description = resources.validation.requiredMax255Characters;
        }

        return errors;
    },

    handleSubmit: (values: IUsersRoleFormProps, bag: FormikBag<IUsersRoleFormProps & IUpdateArray & IAddObjToArray & IHideModal & IId, IUsersRoleFormProps>) => {
        const object: IUsersRoleFormProps = {
            name: values.name,
            description: values.description,
            is_active: values.is_active,
            base_role_id: values.base_role_id,
            id: bag.props.id,
            key: Number(bag.props.id)
        }

        // Sprawdzenie, czy te same vartości
        if (values.name === bag.props.name && values.description === bag.props.description)
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else if (object.id) {
            updateUsersRole(object, bag.props);
        }
        else {
            createUsersRole(object, bag.props);
        }
    }

})(TemplateUsersRoleForm);

export default UsersRoleForm;

const updateUsersRole = async (obj: IUsersRoleFormProps, bag: IUpdateArray & IHideModal) => {
    const object: IUpdateRole = {
        name: obj.name!,
        description: obj.description!
    }

    await updateRole(Number(obj.id), object)
        .then(() => {
            bag.updateArray!(obj.id!, obj);
        })
        .catch(() => {
            openErrorNotification(resources.statement.databaseConnectionError + ' updateQuery UsersRole');
        });

    bag.hideModal!();
}

const createUsersRole = async (obj: IUsersRoleFormProps, bag: IAddObjToArray & IHideModal) => {
    const object: ICreateRole = {
        name: obj.name!,
        description: obj.description!,
        is_active: stringToBool(obj.is_active),
        base_role_id: obj.base_role_id!
    }

    await createRole(object)
        .then(() => {
            // obj.id = id;
            // obj.updated_at = new Date();
            // obj.created_at = new Date();

            // bag.addObjToArray!(obj);

            bag.hideModal!();
        });
}