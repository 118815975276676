import moment from "moment";
import { Modal } from "antd";
import * as React from "react";
import { timezones } from "../../../../common/dictionaries/Timezones";
import { DataTable } from "../../../../components/others/data/DataTable";
import { RemarketingForm } from "./RemarketingForm";
import { STATUSES_OPTIONS } from "../lib/constants";
import InformationModalDialog from "../../../../components/others/modal/InformationModalDialog";
import { Button, Table, Tooltip } from "antd";
import { convertNumberToDateTimeString } from "../../../../helpers/DateHelpers";

const { Column } = Table;
const { confirm } = Modal;

const labelStyles = {
  color: "#009EED",
  cursor: "pointer",
  padding: "2px 7px",
  marginRight: "5px",
  marginBottom: "5px",
  fontSize: "12px",
  borderRadius: "3px",
  backgroundColor: "#DFF4FF",
  whiteSpace: "nowrap",
};

export class RemarketingTable extends DataTable {
  showConfirm = (id, name) => {
    confirm({
      title: "Are you sure?",
      content: `Do you want to archive ${name}? `,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: () => this.props.archiveRemarketing(id),
      onCancel() {},
    });
  };

  render() {
    const columns = [
      <Column
        title="Id"
        dataIndex="id"
        key="id"
        width="100px"
        align="center"
        sorter={(a, b) => (a.id ? a.id : "").localeCompare(b.id ? b.id : "")}
      />,
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        width="100px"
        align="center"
        sorter={(a, b) =>
          (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
        }
      />,
      <Column
        title="Run at time"
        align="center"
        key="run_at_time"
        width="100px"
        render={(item) => {
          const timezone = timezones.find(
            (timezone) => timezone.id === item.run_at_timezone
          );

          if (item.run_now) {
            return (
              <div>
                <span style={{ display: "block" }}>
                  {convertNumberToDateTimeString(item.run_now_at)}
                </span>

                {timezone && <span>{timezone.name}</span>}
              </div>
            );
          }

          return (
            <div>
              <span style={{ display: "block" }}>{`${item.run_at_hour}:${
                item.run_at_minute < 10
                  ? "0" + item.run_at_minute
                  : item.run_at_minute
              }`}</span>

              {timezone && <span>{timezone.name}</span>}
            </div>
          );
        }}
      />,
      <Column
        title="Enabled"
        dataIndex="is_enabled"
        key="is_enabled"
        align="center"
        width="100px"
        render={(isEnabled) => (isEnabled ? "YES" : "NO")}
      />,
      <Column
        title="Mode"
        dataIndex="run_now"
        key="run_now"
        align="center"
        width="100px"
        render={(run_now) => (run_now ? "One time" : "Scheduled")}
      />,

      <Column
        title="Date created"
        key="createdAt"
        width="150px"
        align="center"
        sorter={(a, b) => moment.utc(a.createdAt).diff(moment.utc(b.createdAt))}
        render={(record) => convertNumberToDateTimeString(record.createdAt)}
      />,
      <Column
        title="Description"
        dataIndex="description"
        key="description"
        width="200px"
        align="center"
        sorter={(a, b) =>
          (a.description ? a.description : "").localeCompare(
            b.description ? b.description : ""
          )
        }
        render={(description) => (description ? description : "-")}
      />,
      <Column
        title="How many days after status occur?"
        dataIndex="days_after"
        key="days_after"
        align="center"
        width="150px"
        sorter={(a, b) => a - b}
      />,
      <Column
        title="How many items in order (MIN)?"
        dataIndex="orders_from"
        key="orders_from"
        align="center"
        width="150px"
        sorter={(a, b) => a - b}
      />,
      <Column
        title="How many items in order (MAX)?"
        dataIndex="orders_to"
        key="orders_to"
        align="center"
        width="150px"
        sorter={(a, b) => a - b}
      />,
      <Column
        title="Products"
        key="product_ids"
        align="center"
        width="150px"
        render={(remarketing) => {
          const products = remarketing.product_ids
            .map((productId) =>
              this.props.products.find((p) => p.id === productId)
            )
            .filter(Boolean)
            .map((product) => product.name);

          if (products.length === 0) {
            return "-";
          }

          if (products.length <= 3) {
            return products.join(", ");
          }

          return (
            <span>
              {products.slice(0, 3).join(", ")}

              <Tooltip
                title={() => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {products.map((product, index) => (
                      <span key={index} style={{ flex: "1" }}>
                        {product}
                      </span>
                    ))}
                  </div>
                )}
                trigger="click"
              >
                <Button>Show all products</Button>
              </Tooltip>
            </span>
          );
        }}
      />,
      <Column
        title={
          <span>
            Include orders created <br /> from campaigns
          </span>
        }
        dataIndex="traffic_sources"
        key="traffic_sources"
        align="center"
        width="300px"
        render={(trafficSources) =>
          trafficSources.map((source) => {
            const option = this.props.remarketingOptions.find(
              (option) => option.id === source || typeof source === "object"
            );

            return (
              <span key={option.id} style={labelStyles}>
                {option.name}
              </span>
            );
          })
        }
      />,
      <Column
        title={
          <span>
            Order status <br /> involved
          </span>
        }
        dataIndex="order_statuses"
        key="order_statuses"
        align="center"
        width="200px"
        render={(statuses) =>
          statuses.map((status) => {
            const option = STATUSES_OPTIONS.find(
              (option) => option.id === status
            );
            return (
              <span key={option.id} style={labelStyles}>
                {option.name}
              </span>
            );
          })
        }
      />,
      <Column
        title="Date archived"
        key="updated_at"
        width="150px"
        align="center"
        sorter={(a, b) =>
          moment.utc(a.archived_at).diff(moment.utc(b.archived_at))
        }
        render={(record) =>
          record.archived_at
            ? convertNumberToDateTimeString(record.archived_at)
            : "-"
        }
      />,
      <Column
        width="100px"
        title="Type"
        dataIndex="mode"
        key="mode"
        align="center"
      />,
      <Column
        title="Options"
        key="options"
        width="150px"
        align="center"
        fixed="right"
        render={(remarketing) => (
          <>
            <InformationModalDialog
              modalTitle="Edit remarketing"
              buttonTooltip="Edit remarketing"
              icon="edit"
              disabled={this.props.loading}
              withHideMethod={true}
              width={800}
            >
              <RemarketingForm
                remarketing={remarketing}
                countryId={this.props.countryId}
                onEditRemarketing={this.props.editRemarketing}
                remarketingOptions={this.props.remarketingOptions}
                refetchRemarketing={this.props.refetchRemarketing}
              />
            </InformationModalDialog>

            <Tooltip placement="rightBottom" title="Archive">
              <Button
                value={remarketing.id}
                onClick={() =>
                  this.showConfirm(remarketing.id, remarketing.name)
                }
                icon="delete"
              />
            </Tooltip>

            <InformationModalDialog
              modalTitle="SQL"
              buttonTooltip="Show SQL"
              icon="database"
              disabled={this.props.loading}
              withHideMethod={true}
              width={800}
            >
              <>{remarketing.sql}</>
            </InformationModalDialog>

            <InformationModalDialog
              modalTitle="Add remarketing"
              buttonTooltip="Add remarketing"
              icon="copy"
              disabled={this.props.loading}
              withHideMethod={true}
              width={800}
            >
              <RemarketingForm
                remarketing={{
                  ...remarketing,
                  name: `${remarketing.name} - copy`,
                  run_at_timezone: null,
                  run_at_hour: null,
                  run_at_minute: null,
                }}
                countryId={this.props.countryId}
                onCreateRemarketing={this.props.onCreateRemarketing}
                remarketingOptions={this.props.remarketingOptions}
                refetchRemarketing={this.props.refetchRemarketing}
                createWithRemarketing={true}
              />
            </InformationModalDialog>
          </>
        )}
      />,
    ];

    return (
      <DataTable
        tableName="RemarketingUsers"
        columns={columns}
        data={this.props.data}
        loading={this.props.loading}
        pagination={true}
        valueToCheck={null}
      />
    );
  }
}
