import React from 'react';
import { DataTable } from '../../../components/others/data/DataTable';
import { Table, Tag } from 'antd';
import moment from 'moment';
import { findInDictionaryAndReturnValueWithSpin, findStringInDictionaryAndReturnValue } from '../../../helpers/DisctionariesHelper';
import { statusTestD, typeTestD, schemeTestD } from '../contents/FinanceAdminContent';
import { getPaymentSchemeFilters } from '../../../common/dictionaries/Filters';
import { convertNumberToDateTimeString } from '../../../helpers/DateHelpers';
import { resources } from '../../../common/Resources';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import TransactionForm from '../components/transaction/TransactionForm';
import { LeadModal } from '../../finance/components/leadModal/LeadModal';

const { Column } = Table;

export const schemeTestF = [
    { value: 'cpa', text: 'CPA' },
    { value: 'cpl', text: 'CPL' }
];

export const statusTestF = [
    { value: 'pending', text: 'Pending' },
    { value: 'cleared', text: 'Cleared' },
    { value: 'rejected', text: 'Rejected' }
];

export const typeTestF = [
    { value: 'withdrawal', text: 'Withdrawal' },
    { value: 'deposit', text: 'Deposit' }
];

export class FinanceAdminTable extends DataTable {
    render() {
        const columns = [
            <Column
                title='Date'
                key='disposition_at'
                align='center'
                width={120}
                sorter={(a, b) => moment.utc(a.disposition_at).diff(moment.utc(b.disposition_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.disposition_at)
                )}
            />,
            <Column
                title='Accepted'
                key='settled_at'
                align='center'
                width={120}
                sorter={(a, b) => moment.utc(a.settled_at).diff(moment.utc(b.settled_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.settled_at)
                )}
            />,
            <Column
                title='User'
                key='id_user'
                align='center'
                width={200}
                sorter={(a, b) => a.id_user - b.id_user}
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_user, this.props.usersD)
                )}
            />,
            <Column
                title='Lead ID'
                key='id_lead'
                align='center'
                width={140}
                sorter={(a, b) => a.id_lead - b.id_lead}
                render={(record) => (
                    <LeadModal
                        leadId={record.id_lead}
                        product={record.product}
                        timezoneOffset={this.props.timezoneOffset}
                    />
                )}
            />,
            <Column
                title='Product'
                key='product'
                align='center'
                width={140}
                sorter={(a, b) => a.product.localeCompare(b.product)}
                {...this.getColumnSearchProps('product')}
                render={(record) => (
                    `${record.product}`
                )}
            />,
            <Column
                title='Amount'
                key='amount'
                align='center'
                width={105}
                sorter={(a, b) => a.amount - b.amount}
                {...this.getColumnSearchProps('amount')}
                render={(record) => (
                    `$${record.amount / 100}`
                )}
            />,
            <Column
                title='Type'
                key='type'
                align='center'
                width={85}
                sorter={(a, b) => (a.type ? a.type : "").localeCompare(b.type ? b.type : "")}
                filters={typeTestF}
                onFilter={(value, record) => record.type === value}
                render={(record) => (
                    findStringInDictionaryAndReturnValue(record.type, typeTestD)
                )}
            />,
            <Column
                title='Scheme'
                key='scheme'
                align='center'
                width={105}
                sorter={(a, b) => (a.scheme ? a.scheme : "").localeCompare(b.scheme ? b.scheme : "")}
                filters={getPaymentSchemeFilters()}
                onFilter={(value, record) => record.scheme === value}
                render={(record) => (
                    findStringInDictionaryAndReturnValue(record.scheme, schemeTestD)
                )}
            />,
            <Column
                title='Status'
                key='status'
                align='center'
                width={100}
                sorter={(a, b) => (a.status ? a.status : "").localeCompare(b.status ? b.status : "")}
                filters={statusTestF}
                onFilter={(value, record) => record.status === value}
                render={(record) => (
                    <Tag color={this.getStatusColor(record.status)}>
                        {findStringInDictionaryAndReturnValue(record.status, statusTestD)}
                    </Tag>
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                width={70}
                align='center'
                render={(record) => (
                    <>
                        {record.status === 'pending' &&
                            <ProtectedModal permission={UserPermissionType.EditPartnerModalButton}>
                                <InformationModalDialog
                                    modalTitle={resources.modalTitle.transaction}
                                    icon={"edit"}
                                    withHideMethod={true}
                                    width={400}
                                    disabled={this.state.loading}
                                >
                                    <TransactionForm
                                        amount={record.amount}
                                        description={record.description}
                                        id_transaction={record.id}
                                        transaction_type={record.type}
                                        refreshList={this.props.refreshList}
                                    />
                                </InformationModalDialog>
                            </ProtectedModal>
                        }
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName={'FinanceAdminTable'}
                pagination={true}
                columns={columns}
                defaultPageSize={200}
                data={this.props.data}
                loading={this.props.loading}
                columnNameToCheck='amount'
                valueToCheck={0}
            />
        )
    }

    dateFormat = (value) => {
        if (value === null)
            return '-';
        else {
            const date = new Date(value);
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
            const options = {
                hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false
            };
            const time = new Intl.DateTimeFormat('en', options).format(date);

            return `${da} ${mo}, ${ye} ${time}`;
        }
    }

    getStatusColor = (id) => {
        switch (id) {
            case 'pending':
                return 'orange';
            case 'cleared':
                return 'green';
            case 'rejected':
                return 'magenta';
            default:
                return 'geekblue';
        }
    }
}
