import * as React from "react";
import flagEN from "./images/flagEN.png";
import flagRU from "./images/flagRU.png";
import flagPL from "./images/flagPL.png";
import styles from "./styles/HeaderLangPicker.module.scss";
import { getLocale } from "../../../../helpers/LocaleHelper";
import { IChangeLang } from "../../../../models/IChangeLang";
import { IHandleUpdate } from "./interfaces/IHandleUpdate";
import { Select } from "antd";

const { Option } = Select;

export class HeaderLangPicker extends React.Component<
  IChangeLang & IHandleUpdate & { isCCSimplified: boolean }
> {
  render() {
    return (
      <div className="customAnt--headerLangPicker">
        <Select
          defaultValue={getLocale()}
          onChange={(value: string) => {
            this.props.changeLang(value);
            this.props.handleUpdate();
          }}
          showArrow={false}
        >
          <Option value="en_GB">
            <div className={styles.langPickerGrid}>
              <img src={flagEN} alt="EN flag" />
              <p>EN</p>
            </div>
          </Option>

          {!this.props.isCCSimplified && (
            <Option value="ru_RU">
              <div className={styles.langPickerGrid}>
                <img src={flagRU} alt="RU flag" />
                <p>RU</p>
              </div>
            </Option>
          )}

          {this.props.isCCSimplified && (
            <Option value="pl_PL">
              <div className={styles.langPickerGrid}>
                <img src={flagPL} alt="PL flag" style={{ maxWidth: "25px" }} />
                <p>PL</p>
              </div>
            </Option>
          )}
        </Select>
      </div>
    );
  }
}
