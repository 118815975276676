import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { IMessage } from './interfaces/IMessage';
import { resourceDef } from '../../common/ResourceDef';

const sendMessageToUsersURL = createGatewayAbsoluteUrl(resourceDef.SendMessageToUsers);
const sendMessageToRolesURL = createGatewayAbsoluteUrl(resourceDef.SendMessageToRoles);

export const sendMessageToUsers = (message: IMessage) => {
    return axios.post(sendMessageToUsersURL, message);
}

export const sendMessageToRoles = (message: IMessage) => {
    return axios.post(sendMessageToRolesURL, message);
}
