import * as React from "react";
import noImg from "../../../../../common/images/noImg.png";
import ProtectedComponent from "../../../../../components/authorizations/protectedComponents/ProtectedComponent";
import styles from "./styles/ProductCard.module.scss";
import {
  decodeCategoriesArray,
  removeLastCharIfNumber,
} from "../../../../../helpers/Encoders";
import { displayPrice } from "../../../../../helpers/DisplayHepler";
import {
  findInDictionaryAndReturnValue,
  findInDictionaryAndReturnValueWithSpin,
  getDictionary,
} from "../../../../../helpers/DisctionariesHelper";
import {
  IProductCardProps,
  IProductCardState,
} from "../../interfaces/IProductCardProps";
import { Link } from "react-router-dom";
import { resources } from "../../../../../common/Resources";
import { UserPermissionType } from "../../../../../models/IAppUserInfoModel";
import { openErrorNotification } from "../../../../../helpers/NotificationHelper";
import { Button } from "antd";
import axios from "axios";

import { createGatewayAbsoluteUrl } from "../../../../../helpers/UrlCreator";
import { resourceDef } from "../../../../../common/ResourceDef";
import { Spin } from "antd";

const downloadUrl = createGatewayAbsoluteUrl(resourceDef.ExampleCodePhp);

export class ProductCard extends React.Component<
  IProductCardProps,
  IProductCardState
> {
  state = {
    isDownloadLoading: false,
  };

  downloadExample = (id: any) => {
    this.setState({ isDownloadLoading: true });

    axios({ method: "get", url: `${downloadUrl}/${id}`, responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `code-example-product-${id}.zip`);
        link.click();

        setTimeout(() => window.URL.revokeObjectURL(url), 0);
        this.setState({ isDownloadLoading: false });
      })
      .catch((err) => {
        openErrorNotification("M55 service Error -> getLeadsStatistics");
        console.log(err);
        this.setState({ isDownloadLoading: false });
      });
  };

  findProductImg = () => {
    const obj: any[] = this.props.src_list.filter(
      (x) => x.id_product === this.props.id
    );
    const src = obj.length ? obj[0].src : undefined;

    return src ? src : noImg;
  };

  render() {
    const {
      name_short,
      default_landing_price,
      country_id,
      id,
      default_payout,
      default_payout_schema,
    } = this.props;

    let countryName = "-";
    if (country_id) {
      countryName = findInDictionaryAndReturnValueWithSpin(
        country_id,
        getDictionary("countriesNameD")
      ) as string;
    }
    let productCategories = [] as string[];
    const categories = getDictionary("categoriesD");
    if (this.props.categories) {
      productCategories = this.props.categories.split(",");
    }
    const categoriesString = decodeCategoriesArray(
      productCategories,
      categories
    );

    return (
      <div className={styles.productCard}>
        <img
          className={styles.productCard__img}
          src={this.findProductImg()}
          alt={name_short}
        />
        <h5 className={`h4__bold ${styles.productCard__title}`}>
          {name_short}
        </h5>
        <div className={styles.productCard__table}>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.sitePrice}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {displayPrice(
                default_landing_price,
                findInDictionaryAndReturnValue(
                  country_id!,
                  getDictionary("currencyD")
                )
              )}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.payouts}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {displayPrice(default_payout)}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.labels.payoutScheme}
            </p>
            <p
              className={`h6 ${styles.productCard__table__row__value} uppercase`}
            >
              {default_payout_schema}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersDetails.category}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {categoriesString}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.country}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {removeLastCharIfNumber(countryName)}
            </p>
          </div>
          <ProtectedComponent
            permission={UserPermissionType.SeeOriginalProductCountry}
          >
            <div
              className={`${styles.productCard__table__row} ${styles.admin_table_row}`}
            >
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersView.originalCountry}
              </p>
              <p className={`h6 ${styles.productCard__table__row__value}`}>
                {countryName}
              </p>
            </div>
          </ProtectedComponent>
          <div>
            <Button
              name="download"
              className={`${styles.productCard__downloadButton} ${
                this.state.isDownloadLoading &&
                styles.productCard__downloadButtonLoading
              }`}
              onClick={() => this.downloadExample(id)}
            >
              {this.state.isDownloadLoading ? (
                <Spin />
              ) : (
                resources.offersView.exampleCodePhp
              )}
            </Button>
          </div>
        </div>
        <Link
          className={`h5__bold ${styles.productCard__button}`}
          to={"offers/" + id!}
        >
          {resources.offersView.readMore}
        </Link>
      </div>
    );
  }
}
