import React from 'react';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { Button, Form } from 'antd';
import {
    getLeadsRedirectionCampaignsDById,
    getLeadsRedirectionProductsDById,
    insertLeadsRedirection,
    updateLeadsRedirection
    } from '../../../../services/leadsRedirection/LeadsRedirectionService';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { ILeadsRedirection } from '../interfaces/ILeadsRedirection';
import { ILeadsRedirectionFormProps } from './interfaces/ILeadsRedirectionFormProps';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';

export class LeadsRedirectionForm extends React.Component<IHideModal & IUpdateObjInArray & IAddObjToArray & ILeadsRedirectionFormProps> {
    _isMounted = false;

    state = {
        loading: false,
        id_product: undefined,
        id_country: undefined,
        id_partner: undefined,
        id_campaign: undefined,
        productsD: Array<IDictionaryValue>(),
        campaignsD: Array<IDictionaryValue>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.setState({
            id_product: this.props.id_product,
            id_country: this.props.id_country,
            id_partner: this.props.id_partner,
            id_campaign: this.props.id_campaign
        },
            () => {
                if (this.props.id_country) {
                    this.getProductsD();
                    this.getCampaignsD();
                }
            });
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    getProductsD = async () => {
        this.setState({ loading: true });

        await getLeadsRedirectionProductsDById(this.state.id_country!)
            .then(response => { this._isMounted && this.setState({ productsD: response }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    getCampaignsD = async () => {
        this.setState({ loading: true });

        await getLeadsRedirectionCampaignsDById(this.state.id_country!)
            .then(response => { this._isMounted && this.setState({ campaignsD: response }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    onChangeSelect = (name: string, value: number): void => {
        if (name === 'id_country') {
            this.setState({
                id_campaign: undefined,
                id_product: undefined,
                productsD: [],
                campaignsD: [],
                [name]: value
            }, () => {
                this.getProductsD();
                this.getCampaignsD();
            });

        }
        else {
            this.setState({ [name]: value });
        }
    }

    public render() {
        const {
            partnersD,
            countriesD,
        } = this.props;

        const {
            id_partner,
            id_country,
            id_product,
            id_campaign,
        } = this.state;

        return (
            <Form className='formStyle' onSubmit={this.createQuery}>
                <SelectOnChange
                    onChange={this.onChangeSelect}
                    name='id_partner'
                    value={id_partner}
                    label={resources.labels.partner}
                    items={partnersD}
                    allowClear
                    showSearch
                    required
                />
                <SelectOnChange
                    onChange={this.onChangeSelect}
                    name='id_country'
                    value={id_country}
                    label={resources.labels.country}
                    items={countriesD}
                    allowClear
                    showSearch
                    required
                />
                <SelectOnChange
                    onChange={this.onChangeSelect}
                    name='id_product'
                    value={id_product}
                    label={resources.labels.product}
                    items={this.state.productsD}
                    disabled={this.state.id_country === undefined}
                    allowClear
                    showSearch
                    required
                />
                <SelectOnChange
                    onChange={this.onChangeSelect}
                    name='id_campaign'
                    value={id_campaign}
                    label={resources.labels.campaigns}
                    items={this.state.campaignsD}
                    disabled={this.state.id_country === undefined}
                    allowClear
                    showSearch
                    required
                />
                <Right>
                    <Button type='primary' htmlType='submit' className='marginRigth formMarginTop'>
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                </Right>
            </Form>
        )
    }

    createQuery = async (event: any) => {
        event.preventDefault();

        const object = {
            "id_partner": this.state.id_partner,
            "id_country": this.state.id_country,
            "id_products": this.state.id_product,
            "id_campaign": this.state.id_campaign
        };

        if (this.props.id) {
            const update = { "id": this.props.id, ...object };

            await updateLeadsRedirection(update)
                .then((response) => {
                    if (response) {
                        this.props.updateObjInArray!(update);

                        openSuccessNotification("Lead direction updated!");
                    }
                    else {
                        openErrorNotification(resources.statement.databaseConnectionError);
                    }
                });
        }
        else {
            await insertLeadsRedirection(object)
                .then((response) => {
                    if (response) {

                        let leadDIrection: ILeadsRedirection = {
                            id: Number(response.id),
                            key: Number(response.id),
                            partner_id: Number(object.id_partner),
                            product_id: Number(object.id_products),
                            campaign_id: Number(object.id_campaign),
                            country_id: Number(object.id_country),
                            is_active: false,
                            created_at: new Date()
                        };

                        this.props.addObjToArray!(leadDIrection);

                        openSuccessNotification("Lead direction created!");
                    }
                    else {
                        openErrorNotification(resources.statement.databaseConnectionError);
                    }
                })
                .catch((response) => {
                    openErrorNotification(response.message);
                });
        }

        this.props.hideModal!();
    }
}