import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { MappingClassifiersContent } from './contents/MappingClassifiersContent';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class MappingClassifiersViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = async () => {
        this.props.setActiveMenuItem(navPath.li_classifiers_cc);
    }

    public render() {
        return (
            <MappingClassifiersContent />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const MappingClassifiersView: React.ComponentClass = connect(null, mapDispatchToProps)(MappingClassifiersViewInner);
