import React, { createContext, useReducer } from "react";

export const SET_LOADING = "SET_LOADING";
export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";

type Action = {
  type: string;
  payload?: {
    [key: string]: any;
  };
};
type Dispatch = (action: Action) => void;
export type TableType = {
  tableSettings: object;
  statisticData: object;
  isLoadingData: boolean;
};

const TableContext = createContext<{
  state: TableType;
  dispatch: Dispatch;
} | null>(null);

const tableReducer = (state: TableType, action: Action) => {
  switch (action.type) {
    case SET_LOADING: {
      return Object.assign({}, state, {
        isLoadingData: action.payload?.isLoadingData,
      });
    }
    case UPDATE_TABLE_DATA: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

type TableProviderProps = { children: React.ReactNode };

export const initialTableState = {
  tableSettings: { columnOrder: [] },
  statisticData: {},
  isLoadingData: false,
};

const TableProvider = ({ children }: TableProviderProps) => {
  const [state, dispatch] = useReducer(tableReducer, initialTableState);
  return (
    <TableContext.Provider value={{ state, dispatch }}>
      {children}
    </TableContext.Provider>
  );
};

const useTable = () => {
  const context = React.useContext(TableContext);
  if (!context) {
    throw new Error("useTable must be used within the Table Provider");
  }
  return context;
};

export { TableProvider, useTable };
