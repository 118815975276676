import React from "react";
import styles from "./leads-statistic.module.scss";

import {
  SoldIcon,
  TotalIcon,
  TrashIcon,
  DoubleIcon,
  RejectedIcon,
  ExcludedIcon,
  AwaitingIcon,
  SentToCcIcon,
  AwaitingAllIcon,
  VerifiedApproveRateIcon,
  PotentialApproveRateIcon,
} from "../../../lib/images";
import { MainStatisticItem } from "../main-statistic-item/main-statistic-item";
import { ApproveStatisticItem } from "../approve-statistic-item/approve-statistic-item";
import { ClassifiersStatisticItem } from "../classifiers-statistic-item/classifiers-statistic-item";

interface LeadsStatisticProps {
  isLoading: boolean;
  totalStatistic: any;
}

export const LeadsStatistic = ({
  isLoading,
  totalStatistic,
}: LeadsStatisticProps) => {
  const totalValue = totalStatistic?.total;
  const soldValue = totalStatistic?.sold;
  const verifiedApprovedRate =
    totalStatistic && totalValue !== 0
      ? ((soldValue / totalValue) * 100).toFixed(2)
      : "0.00";

  return (
    <div className={styles.statistics}>
      <div className={styles.approve}>
        <ApproveStatisticItem
          icon={VerifiedApproveRateIcon}
          value={isLoading ? "0" : `${verifiedApprovedRate}`}
          title="Verified Approve Rate"
        />

        <ApproveStatisticItem
          icon={PotentialApproveRateIcon}
          value={isLoading ? "0" : `${totalStatistic?.potential_approve_rate}`}
          title="Potential approve rate"
        />
      </div>

      <div className={styles["main-statistic"]}>
        <MainStatisticItem
          icon={TotalIcon}
          title="Total"
          value={isLoading ? "0" : `${totalValue || "0"}`}
        />

        <MainStatisticItem
          icon={SoldIcon}
          title="Sold"
          value={isLoading ? "0" : `${soldValue || "0"}`}
        />

        <MainStatisticItem
          icon={RejectedIcon}
          title="Rejected"
          value={isLoading ? "0" : `${totalStatistic?.rejected || "0"}`}
        />

        <MainStatisticItem
          icon={TrashIcon}
          title="Trash"
          value={isLoading ? "0" : `${totalStatistic?.trash || "0"}`}
        />

        <MainStatisticItem
          icon={DoubleIcon}
          title="Double"
          value={isLoading ? "0" : `${totalStatistic?.double || "0"}`}
        />

        <MainStatisticItem
          icon={ExcludedIcon}
          title="Excluded"
          value={isLoading ? "0" : `${totalStatistic?.excluded || "0"}`}
        />

        <MainStatisticItem
          icon={AwaitingIcon}
          title="Awaiting"
          value={isLoading ? "0" : `${totalStatistic?.awaiting || "0"}`}
        />

        <MainStatisticItem
          icon={SentToCcIcon}
          title="Sent to CC"
          value={isLoading ? "0" : `${totalStatistic?.sentcc || "0"}`}
        />

        <MainStatisticItem
          icon={AwaitingAllIcon}
          title="Awaiting all"
          value={isLoading ? "0" : `${totalStatistic?.awaiting_all || "0"}`}
        />
      </div>

      <div className={styles.classifiers}>
        <span className={styles["classifiers__title"]}>Cc classifiers</span>

        <ClassifiersStatisticItem
          title="Order created"
          value={isLoading ? "0" : `${totalStatistic?.order_created || "0"}`}
        />

        <ClassifiersStatisticItem
          title="No money"
          value={isLoading ? "0" : `${totalStatistic?.no_money || "0"}`}
        />
      </div>
    </div>
  );
};
