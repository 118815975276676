import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const grantPartnerUrl = createGatewayAbsoluteUrl(resourceDef.GrantPartner);
const denyPartnerUrl = createGatewayAbsoluteUrl(resourceDef.DenyPartner);

export const grantPartner = (id_partner: number) => axios.post(`${grantPartnerUrl}/${id_partner}/admission/grant`)
    .then(() => { return true })
    .catch(error => {
        if (error.response.status === 410)
            openErrorNotification("User was rejected earlier!");
        console.log(error, 'grantPartner M29');
        console.log(error.response);

        return false;
    });

export const denyPartner = (id_partner: number) => axios.post(`${denyPartnerUrl}/${id_partner}/admission/deny`)
    .then(() => { return true })
    .catch(error => {
        if (error.response.status === 410)
            openErrorNotification("User was rejected earlier!");
        console.log(error, 'denyPartner M29');
        console.log(error.response);
        return false;
    });