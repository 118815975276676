import React, { useEffect, useState } from "react";
import { navPath } from "../../../common/ResourceDef";
import { useMutation, useQuery } from "react-query";
import { PageTitle } from "../../../components/common/pageTitle/PageTitle";
import { useDispatch } from "react-redux";
import { TableContainer } from "../../../components/containers/tableContainer/TableContainer";
import { RemarketingForm } from "./components/RemarketingForm";
import { RemarketingTable } from "./components/RemarketingTable";
import { useCountryOptions } from "./lib/hooks/country-options";
import { setActiveMenuItem } from "../../../reducers/CommonReducer";
import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import { useRemarketingOptions } from "./lib/hooks/remarketing-options";
import {
  getRemarketingSettings,
  editRemarketingSetting,
  createRemarketingSetting,
  archiveRemarketingSetting,
} from "../../../services/m121_remarketing_settings/RemarketingService";
import { getProducts } from "../../../services/m30_products/ProductsService";

export const RemarketingView = () => {
  // eslint-disable-next-line
  const [country, setCountry] = useState(1);
  // eslint-disable-next-line
  const [archived, setArchived] = useState(0);
  const dispatch = useDispatch();
  const products = useQuery(["remarketing-products"], () =>
    getProducts({
      columns: ["id", "name", "is_available_sale"],
    }).then((products: any) =>
      products.filter((product: any) => product.is_available_sale)
    )
  );
  const countries = useCountryOptions();
  const remarketing = useQuery(["users-remarketing", country, archived], () =>
    getRemarketingSettings(country, archived)
  );
  const archiveMutation = useMutation((id: any) =>
    archiveRemarketingSetting(id)
  );
  const remarketingOptions = useRemarketingOptions();
  const onCreateRemarketing = useMutation(createRemarketingSetting);
  const onEditRemarketing = useMutation(editRemarketingSetting);

  const onArchiveRemarketing = async (id: any) => {
    await archiveMutation.mutateAsync(id);
    remarketing.refetch();
  };

  useEffect(() => {
    dispatch(setActiveMenuItem(navPath.salesRemarketing));
  }, [dispatch]);

  if (
    products.isLoading ||
    countries.isLoading ||
    remarketing.isLoading ||
    remarketingOptions.isLoading
  )
    return null;

  return (
    <TableContainer>
      <PageTitle>Remarketing settings</PageTitle>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <div>
          <Select
            value={country || undefined}
            style={{ width: 140, marginRight: "20px" }}
            placeholder="Select country"
            onChange={(country: any) => setCountry(country)}
          >
            {countries.data.map((option: any) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>

          <Select
            value={archived}
            style={{ width: 140 }}
            placeholder="Select archived"
            onChange={(archived: any) => setArchived(archived)}
          >
            <Option key={0} value={0}>
              Active
            </Option>

            <Option key={1} value={1}>
              Archived
            </Option>
          </Select>
        </div> */}

        <InformationModalDialog
          icon="plus"
          width={800}
          disabled={remarketing.isLoading}
          buttonType="primary"
          modalTitle="Add new remarketing"
          buttonTitle="Add item"
          buttonTooltip="Add new remarketing"
          withHideMethod={true}
          buttonClassName="addNew marginBottom"
        >
          <RemarketingForm
            countryId={country}
            remarketingOptions={remarketingOptions.data}
            refetchRemarketing={remarketing.refetch}
            onCreateRemarketing={onCreateRemarketing.mutateAsync}
          />
        </InformationModalDialog>
      </div>

      <RemarketingTable
        data={remarketing.data.map((item: any) => ({ key: item.id, ...item }))}
        loading={remarketing.isLoading}
        products={products.data}
        countryId={country}
        editRemarketing={onEditRemarketing.mutateAsync}
        onCreateRemarketing={onCreateRemarketing.mutateAsync}
        archiveRemarketing={onArchiveRemarketing}
        refetchRemarketing={remarketing.refetch}
        remarketingOptions={remarketingOptions.data}
      />
    </TableContainer>
  );
};
