import * as React from "react";
import styles from "../styles/OfferDetailsContent.module.scss";
import {
  deleteTargetUrlCampaigns,
  getTargetUrlCampaigns,
} from "../../../../services/TargetUrlCamaigns";
import { Description } from "../tab/Description";
import { getAllProductPages } from "../../../../services/m30_products/pages/ProductPagesService";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { getProductImages } from "../../../../services/m30_products/images/ProductImagesService";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IId } from "../../../../common/interfaces/IId";
import { IOfferDetails } from "../interfaces/IOfferDetails";
import { IOfferDetailsCampaign } from "../interfaces/IOfferDetailsCampaign";
import { IProductImageFilters } from "../../../../services/m30_products/images/interfaces/IProductImageFilters";
import { IProductPagesFilters } from "../../../../services/m30_products/pages/interfaces/IProductPagesFilters";
import { News } from "../tab/News";
import { openSuccessNotification } from "../../../../helpers/NotificationHelper";
import { Postbacks } from "../tab/Postbacks";
import { ProductCardOffer } from "../components/productCardOffer/ProductCardOffer";
import { resources } from "../../../../common/Resources";
import { Spin, Tabs } from "antd";
import { TargetUrl } from "../tab/TargetUrl";
import { TargetUrlTable } from "../tables/TargetUrlTable";
import { TermsAndConditions } from "../tab/TermsAndConditions";
import { TrafficSources } from "../tab/TrafficSources";
import { PayoutSettings } from "../tab/PayoutSettings";

const { TabPane } = Tabs;

export class OfferDetailsContent extends React.Component<IId> {
  _isMounted = false;

  state = {
    img: undefined,
    showTargetUrlTable: false,
    productData: {} as IOfferDetails,
    countriesD: Array<IDictionaryValue>(),
    currencyD: Array<IDictionaryValue>(),
    categories: Array<IDictionaryValue>(),
    campaigns: Array<IOfferDetailsCampaign>(),
    loading_campaigns: false,
    landings: [],
    landings_archived: [],
    pagesList: [],
  };

  componentDidMount() {
    this._isMounted = true;

    this.getDictionaries();
    this.getProduct();
    this.getPagesList();
    this.getLandingsList();
    this.getCampaigns();
    this.getProductImg();
  }

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    return (
      <>
        {Object.keys(this.state.productData).length > 0 ? (
          <div className={styles.grid}>
            <ProductCardOffer
              img={this.state.img}
              productData={this.state.productData}
              countriesD={this.state.countriesD}
              currencyD={this.state.currencyD}
              categories={this.state.categories}
            />
            <div className={"customAnt--tabPanel"}>
              <Tabs
                defaultActiveKey="1"
                animated={false}
                onChange={(key) => this.changeFlag(key === "3" ? true : false)}
              >
                <TabPane key="1" tab={resources.offersDetails.description}>
                  <Description
                    description_en={this.state.productData.description_en}
                    description_ru={this.state.productData.description_ru}
                  />
                </TabPane>
                <TabPane key="2" tab={resources.offersDetails.trafficSources}>
                  <TrafficSources productData={this.state.productData} />
                </TabPane>
                <TabPane key="3" tab={resources.offersDetails.landings_tab}>
                  <TargetUrl
                    id={this.props.id}
                    reRenderTable={this.getCampaigns}
                    product={this.state.productData}
                    pages={this.state.pagesList}
                  />
                </TabPane>
                <TabPane key="4" tab={resources.offersDetails.postbacks}>
                  <Postbacks productId={Number(this.props.id)} />
                </TabPane>
                <TabPane key="5" tab={resources.offersDetails.news}>
                  <News id={this.props.id} />
                </TabPane>
                <TabPane
                  key="6"
                  tab={resources.offersDetails.terms_and_conditions}
                >
                  <TermsAndConditions
                    terms_en={this.state.productData.terms_en}
                    terms_ru={this.state.productData.terms_ru}
                    conditions_en={this.state.productData.conditions_en}
                    conditions_ru={this.state.productData.conditions_ru}
                  />
                </TabPane>
                <TabPane key="7" tab={resources.offersDetails.payout}>
                  <PayoutSettings />
                </TabPane>
              </Tabs>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spin tip={resources.spin.loading} />
          </div>
        )}
        {this.state.showTargetUrlTable && (
          <div className={"customAnt--tabPanel"} style={{ marginTop: "50px" }}>
            <Tabs defaultActiveKey="active" animated={false}>
              <TabPane key="active" tab="Active campaigns">
                <TargetUrlTable
                  reRenderTable={this.getCampaigns}
                  pagesList={this.state.pagesList}
                  data={this.state.campaigns.filter(
                    (x: any) => x.deleted_at === null
                  )}
                  deleteTargetUrlCampaigns={this.deleteTargetUrlCampaigns}
                  loading={this.state.loading_campaigns}
                  active
                />
              </TabPane>
              <TabPane key="archived" tab="Archived campaigns">
                <TargetUrlTable
                  reRenderTable={this.getCampaigns}
                  pagesList={this.state.pagesList}
                  data={this.state.campaigns.filter(
                    (x: any) => x.deleted_at !== null
                  )}
                  deleteTargetUrlCampaigns={this.deleteTargetUrlCampaigns}
                  loading={this.state.loading_campaigns}
                />
              </TabPane>
            </Tabs>
          </div>
        )}
      </>
    );
  }

  changeFlag = (value: boolean): void => {
    this.setState({ showTargetUrlTable: value });
  };

  deleteTargetUrlCampaigns = (id: string) => {
    deleteTargetUrlCampaigns(id).then((response) => {
      if (response) {
        this.markCampaignAsDelete(id);
        openSuccessNotification(resources.statement.deleted);
      }
    });
  };

  markCampaignAsDelete = (id: string) => {
    const { campaigns } = this.state;
    const index = campaigns.findIndex((x) => x.id === id);

    campaigns[index].deleted_at = new Date().toString();

    this.setState({ campaigns });
  };

  getProductImg = () => {
    const object: IProductImageFilters = {
      is_main: true,
    };

    getProductImages(Number(this.props.id), object).then((response) => {
      const obj = response !== null ? response : [];
      obj.length && this._isMounted && this.setState({ img: obj[0].src });
    });
  };

  getProduct() {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id",
        "categories",
        "name_short",
        "default_payout",
        "default_payout_schema",
        "default_landing_price",
        "country_id",
        "description_en",
        "description_ru",
        "traffic_sources_en",
        "traffic_sources_ru",
        "description_short_en",
        "description_short_ru",
        "terms_en",
        "terms_ru",
        "conditions_en",
        "conditions_ru",
        "product_sku",
      ],
      id: this.props.id,
    };

    getProducts(object)
      .then((response) => {
        if (response[0]) this.setState({ productData: response[0] });
      })
      .finally(() => this.setState({ loading: false }));
  }

  getDictionaries() {
    this.setState({ loading: true });

    this.setState({
      countriesD: getDictionary("countriesNameD"),
      categories: getDictionary("categoriesD"),
      currencyD: getDictionary("currencyD"),
      loading: false,
    });
  }

  getPagesList() {
    const object: IProductPagesFilters = {
      columns: [
        "id",
        "name",
        "url",
        "is_prelanding",
        "is_mobile",
        "is_desktop",
        "is_top",
        "deleted_at",
      ],
      is_visible: true,
    };

    getAllProductPages(Number(this.props.id), object)
      .then((response) => {
        const pagesList = response.filter((x: any) => x.deleted_at === null);
        this.setState({ pagesList });
      })
      .finally(() => this.setState({ loading: false }));
  }

  getLandingsList = () => {
    const object: IProductPagesFilters = {
      columns: ["id", "name", "url", "deleted_at"],
    };

    getAllProductPages(Number(this.props.id), object).then((response) => {
      const landings = response.filter((x: any) => x.deleted_at === null);
      this.setState({ landings });
    });
  };

  getCampaigns = async () => {
    this.setState({ loading_campaigns: true });
    await getTargetUrlCampaigns(`?productId=${this.props.id}`)
      .then((response) => {
        this.setState({
          campaigns: response.map((el: any, index: number) =>
            this.generateCampaignData(el, index)
          ),
        });
      })
      .finally(() => {
        this.setState({ loading_campaigns: false });
      });
  };

  generateCampaignData = (campaign: any, index: number) => {
    return {
      id: campaign.uuid,
      key: index,
      name: campaign.name,
      lp: campaign.config.landingPages,
      pre: campaign.config.preLandingPages,
      url: campaign.urls.tracker,
      deleted_at: campaign.deletedAt,
    };
  };
}
