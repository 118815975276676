import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button, Tooltip } from 'antd';
import { displayIconStatusWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import { resources } from '../../../../common/Resources';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProductCategoryDictionaryForm from '../forms/ProductCategoryDictionaryForm';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { getActivityFilters } from '../../../../common/dictionaries/Filters';

const { Column } = Table;

export class ProductCategoriesDictionaryTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='100px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.is_active}
                key='is_active'
                align='center'
                width='100px'
                sorter={(a, b) => a.is_active - b.is_active}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_active)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='110px'
                align='center'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditProductCategoryModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editCategory}
                                buttonTooltip={resources.modalTooltips.editCategory}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={400}
                            >
                                <ProductCategoryDictionaryForm
                                    id={record.id}
                                    name={record.name}
                                    is_active={record.is_active}
                                    updateObjInArray={this.props.updateObjInArray}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.ChangeProductCategoryStatusButton}>
                            <Tooltip placement="rightTop" title={resources.tooltips.change_activity_status}>
                                <Button
                                    value={record.id}
                                    onClick={this.props.changeStatus}
                                    icon={record.is_active ? 'close' : 'check'}
                                    style={{ color: record.is_active ? 'red' : 'green' }}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.DeleteProductCategoryButton}>
                            {this.displayDeleteButton(record.id) &&
                                <Tooltip placement='rightTop' title={resources.tooltips.deleteCategory}>
                                    <Button icon='delete' onClick={() => this.props.deleteProductCategory(record.id)} />
                                </Tooltip>
                            }
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='ProductCategoriesDictionaryTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }

    displayDeleteButton = (id) => {
        if (id > 4)
            return true;
        else
            return false;
    }
}