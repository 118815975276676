import ProductPricingForm from '../forms/productPrice/ProductPriceForm';
import React, { Component } from 'react';
import { ILoading } from '../../../../../../common/interfaces/ILoading';
import { IProductPricingListProps } from './interfaces/IProductPricingListProps';
import { List } from 'antd';

export class ProductPricingList extends Component<IProductPricingListProps & ILoading> {
    render() {
        return (
            <List
                dataSource={this.props.productPricingList}
                loading={this.props.loading}
                renderItem={
                    item => (
                        <List.Item>
                            <ProductPricingForm
                                {...item}
                                productsD={this.props.productsD}
                                deleteProductPricing={this.props.deleteProductPricing}
                                updateProductPricingList={this.props.updateProductPricingList}
                            />
                        </List.Item>
                    )
                }
            />
        );
    }
}