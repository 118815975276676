import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';
import { resourceDef } from '../common/ResourceDef';

export const getCouriers = () => {
    return axios.get(createGatewayAbsoluteUrl(resourceDef.GetCouriers))
        .then(res => res)
        .catch(error => error);
}

export const getStatuses = () => {
    return axios.get(createGatewayAbsoluteUrl(resourceDef.GetStatuses))
        .then(res => res)
        .catch(error => error)
}

export const getMappingData = (country_id: number, courier: string) => {
    return axios.get(createGatewayAbsoluteUrl(`${resourceDef.GetMappings}?countryId=${country_id}&courier=${courier}`))
        .then(res => res)
        .catch(error => error);
}

export const updateStatus = (obj: any) => {
    return axios.post(createGatewayAbsoluteUrl(resourceDef.UpdateStatus), { ...obj })
        .then(res => res)
        .catch(error => error);
}

export const createNewStatus = (obj: any) => {
    return axios.post(createGatewayAbsoluteUrl(resourceDef.UpdateStatus), { ...obj })
        .then(res => res)
        .catch(error => error);
}