import * as React from 'react';
import ProtectedComponent from '../../../components/authorizations/protectedComponents/ProtectedComponent';
import Tabs from 'antd/lib/tabs';
import { generateName } from '../../../helpers/UserHelper';
import { getUsersList } from '../../../services/m29_users_management/GeneralUserService';
import { IId } from '../../../common/interfaces/IId';
import { PageTitle } from '../../../components/common/pageTitle/PageTitle';
import { PayoutAccessContent } from './payoutAccess/contents/PayoutAccessContent';
import { PostbackSettingsContent } from '../postbacksSettings/contents/PostbackSettingsContent';
import { resources } from '../../../common/Resources';
import { RouteComponentProps } from 'react-router-dom';
import { Spin } from 'antd';
import { UserInformationsContent } from './userInfromations/contents/UserInformationsContent';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';
const { TabPane } = Tabs;

export class UsersInformationView extends React.Component<RouteComponentProps<IId>> {
    _isMounted = false;

    state = {
        loading: false,
        name: undefined,
        email: undefined
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.getUserInf();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    public render() {
        const id = this.props.match.params.id;

        return (
            <>
                <PageTitle>Account Informations</PageTitle>
                {this.state.loading ? <Spin tip={resources.spin.loading} /> : (id && (this.state.name || this.state.email)) && <div style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 16 }}>{`${this.state.name}  ${this.state.email}`}</div>}
                <Tabs
                    style={{ textAlign: 'center' }}
                    defaultActiveKey='1'
                    animated={false}
                    onChange={(key) => this.changeFlag(key === '2' ? true : false)}
                >
                    <TabPane key='1' tab='User informations'>
                        <ProtectedComponent permission={UserPermissionType.ViewUserInformationsContent}>
                            <UserInformationsContent id={id} />
                        </ProtectedComponent>
                    </TabPane>
                    <TabPane key='2' tab='Postback settings'>
                        <div style={{ width: '60%', margin: 'auto' }}>
                            <PostbackSettingsContent userId={Number(id)} />
                        </div>
                    </TabPane>
                    <TabPane key='3' tab='Payout access'>
                        <PayoutAccessContent id={id} />
                    </TabPane>
                </Tabs>
            </>
        )
    };

    changeFlag = (value: boolean): void => {
        this.setState({ showTargetUrlTable: value });
    }

    getUserInf = () => {
        if (this.props.match.params.id) {
            this.setState({ loading: false });

            const object = {
                columns: [
                    'name',
                    'second_name',
                    'email'
                ],
                id: this.props.match.params.id
            };

            getUsersList(object)
                .then(response => {
                    if (response)
                        this._isMounted && this.setState({
                            name: generateName(response[0].name, response[0].second_name),
                            email: response[0].email
                        });
                })
                .finally(() => this._isMounted && this.setState({ loading: false }));
        }
    }
}