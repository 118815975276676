import * as React from 'react';
import AffbayClassifiersForm from '../forms/MappingClassifiersForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getAffbayClassifiers } from '../../../../services/callCenter/AffbayClassifiersService';
import { IMappingClassifiers } from '../interfaces/IMappingClassifiers';
import { MappingClassifiersTable } from '../tables/MappingClassifiersTable';
import { megaUpdate } from '../../../../services/callCenter/MegaUpdateService';
import { MegaUpdateButton } from '../../../../components/megaUpdateButton/MegaUpdateButton';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { SearchElements } from '../../../../components/common/searchElements/SearchElements';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class MappingClassifiersContent extends React.Component {
    _isMounted = false;

    state = {
        mappingClassifiersList: Array<IMappingClassifiers>(),
        loading: true,
        search: undefined
    }

    componentDidMount = (): void => {
        this._isMounted = true;
        this.getMappingClassifiers();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { loading, mappingClassifiersList, search } = this.state;

        let filteredData = mappingClassifiersList;

        if (search)
            filteredData = filteredData.filter(x =>
                (x.name ? x.name.toLocaleLowerCase() : "").includes(search!)
            );

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.mappingClassifiers} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getMappingClassifiers}
                />
                <ProtectedComponent permission={UserPermissionType.SeeMegaUploadButton}>
                    <Right>
                        <MegaUpdateButton
                            buttonTitle='Mega upload'
                            className='marginBottom marginLeft'
                            loading={this.state.loading}
                            onClick={this.megaUpdate}
                        />
                    </Right>
                </ProtectedComponent>
                <ProtectedModal permission={UserPermissionType.AddNewMappingClassifierModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.title.createNewClassifier}
                            icon={"plus"}
                            buttonTitle={resources.modalButtonsTitle.createNewClassifier}
                            disabled={loading}
                            withHideMethod={true}
                            buttonType='primary'
                            buttonClassName='marginBottom'
                        >
                            <AffbayClassifiersForm
                                addObjToArray={this.addObjToArray}
                                setLoading={this.setLoading}
                            />
                        </InformationModalDialog>
                    </Right>
                    <Right>
                        <SearchElements
                            className='marginRigth'
                            onSearch={this.onGlobalSearch}
                            loading={loading}
                        />
                    </Right>
                    <Right>
                        <FoundRowsNumber
                            className="marginRigth"
                            count={count}
                        />
                    </Right>
                </ProtectedModal>
                <ClearBoth />
                <MappingClassifiersTable
                    data={filteredData}
                    loading={loading}
                    updateArray={this.updateArray}
                    setLoading={this.setLoading}
                />
            </TableContainer>
        )
    };

    updateArray = (id: number, obj: IMappingClassifiers): void => {
        const { mappingClassifiersList } = this.state;

        const index = mappingClassifiersList.findIndex(x => Number(x.id) === id);

        mappingClassifiersList[index].updated_at = new Date();
        mappingClassifiersList[index].is_qualified = Number(obj.is_qualified);
        mappingClassifiersList[index].is_contacted = Number(obj.is_contacted);
        mappingClassifiersList[index].name = obj.name!;
        mappingClassifiersList[index].status = obj.status!;
        mappingClassifiersList[index].postback_comment = obj.postback_comment;

        this._isMounted && this.setState({ mappingClassifiersList });
    }

    getMappingClassifiers = (): void => {
        this.setState({ loading: true });

        getAffbayClassifiers()
            .then((response) => this._isMounted && this.setState({ mappingClassifiersList: response }))
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    addObjToArray = (campaign: IMappingClassifiers) =>
        this.setState({ mappingClassifiersList: [...this.state.mappingClassifiersList, campaign] });

    onGlobalSearch = (event: any) =>
        this.setState({ [event.target.name]: event.target.value.toLocaleLowerCase() });

    setLoading = (loading: boolean) =>
        this.setState({ loading });

    megaUpdate = async () => {
        this.setState({ loading: true });

        await megaUpdate()
            .then((response) => response && this.getMappingClassifiers());
    }
}