import React, { Component } from "react";
import styles from "./styles/CourierStatusMappingContent.module.scss";
import { Button, Icon, Select } from "antd";
import { CouriersStatusCreateForm } from "../forms/CouriersStatusCreateForm";
import { CouriersStatusEditForm } from "../forms/CouriersStatusEditForm";
import { CourierStatusMappingTable } from "../tables/CourierStatusMappingTable";
import {
  createNewStatus,
  getCouriers,
  getMappingData,
  getStatuses,
  updateStatus,
} from "../../../services/CouriersStatusMappingServices";
import { getDictionary } from "../../../helpers/DisctionariesHelper";
import { LabeledValue } from "antd/lib/select";
import { PopUpForm } from "../../../components/popUpForm/PopUpForm";
import { resources } from "../../../common/Resources";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";

interface ICourierStatusMappingContentState {
  modalVisible: boolean;
  createNewStatusModalVisible: boolean;
  countriesList: ICountry[];
  couriersList: ICourier[];
  statusesList: IStatus[];
  mappingData: IMapping[];
  filteredCouriersList: ICourier[];
  selectedCountry?: number;
  selectedCourier?: string;
  rowInEdit?: IMapping;
  tableLoading: boolean;
  successModalVisible: boolean;
}

export interface ICountry {
  id: number;
  name: string;
}

export interface ICourier {
  id: number;
  country_id: number;
  name: string;
}

export interface IStatus {
  id: number;
  name: string;
}

export interface IMapping {
  id: number;
  country_id: number;
  courier_name: string;
  status: string;
  courier_status: string;
  created_at: string;
  updated_at: string;
  status_description?: string;
  nontes?: string;
  user_name: string;
  key?: number;
}

export interface IUpdateStatusObj {
  id: number;
  courier: string;
  statusId: number;
  courierStatus: string;
  description: string;
  notes: string;
  statusName?: string;
}

export interface ICreateStatusObj {
  countryId?: number;
  courier?: string;
  statusId?: number;
  courierStatus?: string;
  description?: string;
  notes?: string;
}

export class CourierStatusMappingContent extends Component<
  {},
  ICourierStatusMappingContentState
> {
  state = {
    modalVisible: false,
    createNewStatusModalVisible: false,
    countriesList: Array<ICountry>(),
    couriersList: Array<ICourier>(),
    statusesList: Array<IStatus>(),
    mappingData: Array<IMapping>(),
    filteredCouriersList: Array<ICourier>(),
    selectedCountry: undefined,
    selectedCourier: undefined,
    rowInEdit: undefined,
    tableLoading: false,
    successModalVisible: false,
  };

  componentDidMount = () => {
    this.setState({
      countriesList: getDictionary("countriesNameD"),
    });
    this.getAllCouriers();
    this.getAllStatuses();
  };

  // API CALLS
  getAllCouriers = async () => {
    await getCouriers()
      .then((res) => {
        if (res.status === 200) {
          this.setState({ couriersList: res.data.couriers });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllStatuses = async () => {
    await getStatuses()
      .then((res) => {
        if (res.status === 200) {
          this.setState({ statusesList: res.data.statuses });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getMappingData = async () => {
    this.setState({
      tableLoading: true,
    });
    const { selectedCountry, selectedCourier } = this.state;

    await getMappingData(selectedCountry!, selectedCourier!)
      .then((res) => {
        if (res.status === 200) {
          const idAsKey = res.data.mappings.map((el: IMapping) => {
            return {
              ...el,
              key: el.id,
            };
          });
          this.setState({
            mappingData: idAsKey,
            tableLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          tableLoading: false,
        });
        console.error(err);
      });
  };
  // ====

  handleCountrySelect = (e?: number | string | LabeledValue) => {
    this.setState({ selectedCountry: Number(e) }, () => {
      this.setState({
        filteredCouriersList: this.filterCouriersList(),
        selectedCourier: undefined,
        mappingData: [],
      });
    });
  };

  filterCouriersList = (): ICourier[] => {
    const { couriersList, selectedCountry } = this.state;

    return couriersList.filter((courier: ICourier) => {
      return courier.country_id === selectedCountry;
    });
  };

  handleCourierSelect = (e?: number | string | LabeledValue) => {
    this.setState(
      {
        selectedCourier: `${e}`,
      },
      () => {
        this.getMappingData();
      }
    );
  };

  toggleModal = (rowInEdit: IMapping) => {
    const { modalVisible } = this.state;
    this.setState({
      modalVisible: !modalVisible,
      rowInEdit,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      rowInEdit: undefined,
    });
  };

  closeCreateNewStatusModal = () => {
    this.setState({ createNewStatusModalVisible: false });
  };

  showCreateNewStatusModal = () => {
    this.setState({ createNewStatusModalVisible: true });
  };

  updateStatus = async (obj: IUpdateStatusObj) => {
    let data: any;

    if (obj.statusName === "") {
      const { statusName, ...rest } = obj;

      data = rest;
    } else {
      data = obj;
    }
    await updateStatus(data)
      .then((res) => {
        this.closeModal();
        this.getMappingData();
        this.openSuccessModal();
      })
      .catch((err) => console.error("err", err));
  };

  createNewStatus = async (obj: ICreateStatusObj) => {
    await createNewStatus(obj)
      .then((res) => {
        this.closeCreateNewStatusModal();
        this.getMappingData();
        this.openSuccessModal();
      })
      .catch((err) => console.error("err", err));
  };

  closeSuccessModal = () => {
    this.setState({
      successModalVisible: false,
    });
  };

  openSuccessModal = () => {
    this.setState({
      successModalVisible: true,
    });
  };

  render() {
    return (
      <>
        <header>
          <ViewHeader title={"Courier Status Mapping"} />
        </header>
        <section className={styles.filters}>
          <Select
            style={{ width: "200px" }}
            placeholder={resources.select.select_country}
            onSelect={this.handleCountrySelect}
            value={this.state.selectedCountry}
          >
            {this.state.countriesList.map((country: ICountry) => {
              return (
                <Select.Option key={country.id} value={country.id}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>
          <Select
            style={{ width: "200px", marginLeft: "15px" }}
            placeholder="Select courier"
            onSelect={this.handleCourierSelect}
            value={this.state.selectedCourier}
          >
            {this.state.filteredCouriersList.map((courier: ICourier) => {
              return (
                <Select.Option key={courier.id} value={courier.name}>
                  {courier.name}
                </Select.Option>
              );
            })}
          </Select>
          <Button
            style={{ width: "100px", marginLeft: "15px" }}
            onClick={this.showCreateNewStatusModal}
          >
            New status
          </Button>
        </section>
        <section className={styles.table}>
          <CourierStatusMappingTable
            data={this.state.mappingData}
            toggleModal={this.toggleModal}
            loading={this.state.tableLoading}
          />
          <PopUpForm
            visible={this.state.modalVisible}
            onCancel={this.toggleModal}
            width="900px"
          >
            <CouriersStatusEditForm
              data={this.state.rowInEdit}
              statusesList={this.state.statusesList}
              updateStatus={this.updateStatus}
            />
          </PopUpForm>
        </section>
        <div>
          <PopUpForm
            visible={this.state.createNewStatusModalVisible}
            onCancel={this.closeCreateNewStatusModal}
            width="900px"
          >
            <CouriersStatusCreateForm
              countriesList={this.state.countriesList}
              couriersList={this.state.couriersList}
              statusesList={this.state.statusesList}
              createNewStatus={this.createNewStatus}
              selectedCountry={this.state.selectedCountry}
              selectedCourier={this.state.selectedCourier}
            />
          </PopUpForm>
        </div>
        <div>
          <PopUpForm
            visible={this.state.successModalVisible}
            onCancel={this.closeSuccessModal}
            width="500px"
          >
            <div className={styles.successModal}>
              <Icon
                type="check-circle"
                style={{ fontSize: "24px", marginRight: "10px" }}
              />
              <h4 className={`h4__semi`} style={{ marginBottom: "0px" }}>
                Status Mapping list updated
              </h4>
            </div>
          </PopUpForm>
        </div>
      </>
    );
  }
}
