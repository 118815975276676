import * as React from 'react';
import EmployeeForm from '../forms/AccountManagerForm';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { AccountManagersTable } from '../tables/AccountManagersTable';
import { ClearBoth } from '../../../components/controls/clearBoth/ClearBoth';
import { getDictionary } from '../../../helpers/DisctionariesHelper';
import { getRolesListD } from '../../../services/m29_users_management/RoleService';
import { getUsersList, updateUser } from '../../../services/m29_users_management/GeneralUserService';
import { IDictionaryValue } from '../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IUser } from '../../../models/IUsers';
import { openErrorNotification, openSuccessNotification } from '../../../helpers/NotificationHelper';
import { PageTitle } from '../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../common/Resources';
import { Right } from '../../../components/controls/right/Right';
import { TableContainer } from '../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';

export class AccountManagersContent extends React.Component {
    _isMounted = false;
    state = {
        account_managers_List: Array<IUser>(),
        countriesNameD: Array<IDictionaryValue>(),
        loading: false
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.setState({ countriesNameD: getDictionary('countriesNameD') });

        this.getAccountManagersList();
    }

    componentWillUnmount = (): void => {
        this._isMounted = false;
    }

    public render() {
        const { account_managers_List, loading, countriesNameD } = this.state;

        return (
            <TableContainer>
                <PageTitle>{resources.pageTitle.account_managers}</PageTitle>
                <RefreshTableButton
                    loading={this.state.loading}
                    onClick={this.getAccountManagersList}
                    className='marginBottom'
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.AddNewAccountManagerModalButton}>
                        <InformationModalDialog
                            modalTitle={resources.title.addNew}
                            buttonTitle={resources.buttons.new}
                            buttonType='primary'
                            buttonTooltip={resources.title.addNew}
                            icon={'user-add'}
                            disabled={loading}
                            withHideMethod={true}
                            width={600}
                            buttonClassName='addNew marginBottom'
                        >
                            <EmployeeForm
                                addObjToArray={this.addObjToArray}
                                countries={countriesNameD}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <ClearBoth />
                <AccountManagersTable
                    data={account_managers_List}
                    loading={loading}
                    refreshList={this.getAccountManagersList}
                    countries={countriesNameD}
                    changeStatus={this.changeStatus}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getAccountManagersList = async () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                'id',
                'email',
                'name',
                'second_name',
                'short_name',
                'id_country',
                'description',
                'is_activated'
            ],
            id_users_role: [7]
        };

        await getUsersList(object)
            .then(
                (response) => {
                    const account_managers_List = response.map((item: any) => { return { ...item, key: item.id } });

                    this._isMounted && this.setState({ account_managers_List });
                })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    getUsersRolesD = async () => {
        const object = {
            columns: [
                'id',
                'name'
            ]
        };

        await getRolesListD(object)
            .then((response: any) => {
                const rolesD = response ? response.filter((x: any) => x.id === 4 || x.id === 5) : [];

                this._isMounted && this.setState({ rolesD });
            });
    }

    changeStatus = async (id: number, is_activated: boolean) => {
        const object = {
            is_activated: !is_activated
        }

        await updateUser(id, object)
            .then((response) => {
                if (response) {
                    this._isMounted && this.changeStatusInArray(id);
                    openSuccessNotification(resources.statement.status_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        const { account_managers_List } = this.state;
        const index = account_managers_List.findIndex(x => x.id === id);

        account_managers_List[index].is_activated = !account_managers_List[index].is_activated;

        this.setState({ account_managers_List });
    }

    addObjToArray = (employees: IUser) => {
        this.setState({ account_managers_List: [...this.state.account_managers_List, employees] });
    }

    updateObjInArray = (user: IUser): void => {
        const { account_managers_List } = this.state;
        const index = account_managers_List.findIndex(x => x.id === user.id);

        account_managers_List[index].name = user.name;
        account_managers_List[index].second_name = user.second_name;
        account_managers_List[index].short_name = user.short_name;
        account_managers_List[index].email = user.email;
        account_managers_List[index].id_country = user.id_country;
        account_managers_List[index].id_users_role = user.id_users_role;
        account_managers_List[index].description = user.description;

        this.setState({ account_managers_List });
    }
}