import React, { Component } from 'react';
import { Empty } from 'antd';
import { IUserPersonalData } from '../../../../models/IUserPersonalData';
import { Loading } from '../../../../components/loading/Loading';
import { OfferDetailsContainer } from '../../../../components/containers/offerDetailsContainer/OfferDetailsContainer';
import { PersonalDataForm } from '../../forms/personalData/PersonalDataForm';
import { resources } from '../../../../common/Resources';

interface IUser {
    user: IUserPersonalData;
    id?: number;
}

export class PersonalData extends Component<IUser> {
    render() {
        return (
            <OfferDetailsContainer>
                {
                    this.props.user
                        ?
                        Object.keys(this.props.user).length < 1
                            ?
                            <Loading />
                            :
                            <PersonalDataForm user={this.props.user} id={this.props.id} />
                        :
                        <Empty description={resources.statement.sorry_we_could_not_get_your_data} />
                }

            </OfferDetailsContainer>
        )
    }
}
