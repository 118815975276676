import * as React from "react";
import ApiTokenForm from "../components/apiToken/ApiTokenForm";
import RegistrationLinkForm from "../components/registrationLink/RegistrationLinkForm";
import { ChangePassword } from "../components/changePassword/ChangePassword";
import { connect } from "react-redux";
import { getUserInf } from "../../../services/m29_users_management/GeneralUserService";
import { IAppState } from "../../../store";
import { IProfileProps } from "../interfaces/IProfileProps";
import { PersonalData } from "../components/personalData/PersonalData";
import { PostbackSettingsContent } from "../../_common/postbacksSettings/contents/PostbackSettingsContent";
import { resources } from "../../../common/Resources";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export class ProfileContentInner extends React.Component<IProfileProps> {
  state = {
    user: {} as any,
  };

  componentDidMount = () => {
    this.getUsersInf();
  };

  public render() {
    const { user } = this.state;

    return (
      <div className={`customAnt--tabPanel`}>
        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane key="1" tab={resources.tabPane.personalData}>
            <PersonalData user={user} id={this.props.appUserInfo.id} />
          </TabPane>
          <TabPane key="2" tab={resources.tabPane.changePassword}>
            <ChangePassword userId={this.props.appUserInfo.id} />
          </TabPane>
          {/* <TabPane key="3" tab={resources.tabPane.notifications}>
                            <NotificationsForm />
                        </TabPane> */}
          <TabPane key="4" tab={resources.tabPane.apiToken}>
            <ApiTokenForm user_token={user ? user.partner_token : undefined} />
          </TabPane>
          <TabPane key="5" tab={resources.tabPane.globalPostbacks}>
            <PostbackSettingsContent userId={this.props.appUserInfo.id} />
          </TabPane>
          <TabPane key="6" tab={resources.tabPane.registrationLink}>
            <RegistrationLinkForm
              referral_url={user ? user.id_referral : undefined}
              referrals={user ? user.referrals : undefined}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }

  getUsersInf = () => {
    const object = {
      columns: [
        "id",
        "email",
        "name",
        "phone",
        "telegram",
        "skype",
        "timezone",
        "partner_token",
      ],
    };

    getUserInf(object).then((user) => {
      this.setState({ user });
    });
  };
}

const mapStateToProps = (state: IAppState): IProfileProps => {
  return {
    appUserInfo: state.commonState.appUserInfo,
  };
};

export const ProfileContent = connect(mapStateToProps)(ProfileContentInner);
