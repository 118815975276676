import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Result } from 'antd';
import { CreateNewOrderContent } from './contents/CreateNewOrderContent';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../reducers/CommonReducer';
import { IAppUserInfoModel } from '../../models/IAppUserInfoModel';
import { IAppState } from '../../store';

interface IurlParams {
    fcc_id?: string;
}

class CreateNewOrderViewInner extends React.Component<IAppUserInfoModel & ISetActiveMenuItem & RouteComponentProps<IurlParams>> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.createNewOrder);
    }

    public render() {
        console.log(this.props.acc);
        
        if (!this.props.acc || !this.props.acc.orders || this.props.acc.orders.length === 0) {
            return <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
            />;
        }
        return (
            <CreateNewOrderContent
                fcc_id={this.props.match.params.fcc_id}
                orderPermByCountry={this.props.acc.orders} />
        )
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

const mapStateToProps = (state: IAppState): IAppUserInfoModel => {
    if (state.commonState.appUserInfo) {
        return { ...state.commonState.appUserInfo }
    }

    return {};
}

export const CreateNewOrderView: React.ComponentClass<RouteComponentProps<IurlParams>> = connect(mapStateToProps, mapDispatchToProps)(CreateNewOrderViewInner);
