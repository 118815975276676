import moment, { Moment } from 'moment';
import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { DatePicker } from 'antd';
import { DatePickerMode } from './DatePickerMode';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { IDatePickerProps } from './interfaces/IDatePickerProps';
import { IDatePickerState } from './interfaces/IDatePickerState';

export class DatePickerF extends React.Component<IDatePickerProps & IBaseFormikProps, IDatePickerState> {
    public state: IDatePickerState = {
        isOpen: false
    }

    public render() {
        const mode: DatePickerMode = this.props.mode ? this.props.mode : "date";

        return (
            <BaseForm {...this.props} shouldUpdateComponentCheckInChildren={true}>
                <DatePicker
                    open={this.state.isOpen}
                    value={this.props.values[this.props.name] ? moment(this.props.values[this.props.name]) : this.props.values[this.props.name]}
                    onChange={(value) => { this.onChange(value === null ? undefined : value) }}
                    onPanelChange={(value) => { this.onPanelChange(value) }}
                    mode={getDatePickerMode(mode)}
                    format={getDatePickerFormat(mode)}
                    placeholder={getPlaceholder(this.props.disabled)}
                    onOpenChange={(isOpen) => { this.onOpenChange(isOpen) }}
                    disabled={this.props.disabled}
                    allowClear={this.props.allowClear}
                    showTime={isWithTimeMode(mode)}
                />
            </BaseForm>)
    }

    private onChange(value?: Moment) {
        this.props.setFieldValue(this.props.name, value != null ? value.startOf('day') : value);
    }

    private onOpenChange(isOpen: boolean) {
        if (!this.props.readOnly) {
            if (!isOpen) {
                this.props.setFieldTouched(this.props.name);
            }
            this.setState({ isOpen });
        }
    }

    private onPanelChange(value?: Moment) {
        if (this.props.mode === "year") {
            this.props.setFieldValue(this.props.name, value != null ? value.startOf('day') : value);
        }
        this.setState({ isOpen: false });
    }

};

type DatePickerBuildModeOptions = 'time' | 'date' | 'month' | 'year';

const getDatePickerFormat = (mode: DatePickerMode): string => {
    return isYearMode(mode) ? "YYYY" : isWithTimeMode(mode) ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY";
}

const getDatePickerMode = (mode: DatePickerMode): DatePickerBuildModeOptions => {
    return isDateMode(mode) ? "date" : "year";
}

const getPlaceholder = (disabled: boolean | undefined): string => {
    return disabled ? "" : "Select date";
}

const isDateMode = (mode: DatePickerMode): boolean => {
    return mode === "date";
}

const isWithTimeMode = (mode: DatePickerMode): boolean => {
    return mode === "withTime";
}

const isYearMode = (mode: DatePickerMode): boolean => {
    return mode === "year";
}

export default DatePickerF;