import moment from 'moment';
import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

interface IdatePickerFMethods {
    onChange(e: any, ee: any): void;
    value?: any[];
    disabled?: boolean;
    dateMode?: boolean;
    width?: number;
    className?: string;
    showTime?: boolean;
    mode?: Array<string>;
    ranges?: any;
}

const defaultRanges = {
    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
    'Today': [moment().startOf('day'), moment().endOf('day')],
    'Last 7 days': [moment().subtract(7, 'd').startOf('day'), moment().endOf('day')],
    'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
};

export class RangePickerF extends React.Component<IdatePickerFMethods> {

    state = {
        pickingEndDate: false,
    }

    panelChangeHandler = (value?: RangePickerValue, mode?: string | string[]) => {
        if (Array.isArray(mode)) {
            const pickingEndDate = mode[0] === null;
            this.setState({
                pickingEndDate,
            });
        }
        this.props.onChange(value, null);
    }

    public render() {
        const { RangePicker } = DatePicker;

        return (
            <RangePicker
                className={this.props.className}
                style={{ width: this.props.width }}
                disabled={this.props.disabled}
                allowClear={false}
                ranges={this.props.ranges ? this.props.ranges : defaultRanges}
                showTime={this.props.showTime}
                format={this.props.dateMode ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm:ss"}
                onChange={this.props.onChange}
                mode={this.props.mode ? this.props.mode : ['date']}
                value={this.props.value ? this.props.value : [moment().startOf('day'), moment().endOf('day')]}
                onPanelChange={this.panelChangeHandler}
            />
        )
    }
};
