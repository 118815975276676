import React from "react";
import { Divider, Spin } from "antd";

import { FilterCheckbox } from "../filter-checkbox/filter-checkbox";
import { createFilterData } from "../../../lib/filters-data";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

export const MultipleChoiceFilter = ({
  filter,
  entityHook,
}: {
  filter: string;
  entityHook: any;
}) => {
  const { state, dispatch } = useFilters();
  const { entity, isLoading } = entityHook();

  const onChangeEntity = (isChecked: boolean, entityItem: any) => {
    const updatedEntity = isChecked
      ? [
          ...state[filter],
          createFilterData(entityItem.name, entityItem.id, filter),
        ]
      : state[filter].filter((e: any) => e.value !== entityItem.id);
    dispatch({
      type: UPDATE_FILTERS,
      payload: { [filter]: updatedEntity },
    });
  };

  const onChangeSelectAll = (isChecked: boolean) =>
    dispatch({
      type: UPDATE_FILTERS,
      payload: {
        [filter]: isChecked
          ? entity.map((entityItem: any) =>
              createFilterData(entityItem.name, entityItem.id, filter)
            )
          : [],
      },
    });

  if (isLoading) {
    return (
      <Spin
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(calc(-50% + 40px), -50%)",
        }}
      />
    );
  }

  return (
    <div>
      <FilterCheckbox
        label="Select all"
        checked={entity.length === state[filter].length}
        onChange={(event) => onChangeSelectAll(event.target.checked)}
      />

      <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {entity.map((entityItem: any) => (
          <FilterCheckbox
            key={entityItem.id}
            label={entityItem.name}
            style={{ marginLeft: "0", marginBottom: "10px" }}
            checked={
              !!state[filter].find((e: any) => e.value === entityItem.id)
            }
            onChange={(event) =>
              onChangeEntity(event.target.checked, entityItem)
            }
          />
        ))}
      </div>
    </div>
  );
};
