import React from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";

import { PICKER_MODE_FORMAT_MAP } from "../../../lib/table-data/group-by-data";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

export const HourFilter = () => {
  const { state, dispatch } = useFilters();

  const onChangeDate = (date: Moment | null) => {
    dispatch({
      type: UPDATE_FILTERS,
      payload: {
        startDate: date as Moment,
        endDate: moment(date).endOf("day"),
      },
    });
  };

  return (
    <DatePicker
      size="small"
      value={state.startDate}
      format={PICKER_MODE_FORMAT_MAP[state.pickerMode]}
      onChange={onChangeDate}
      className="statistic-date-filter"
    />
  );
};
