import React from "react";
import styles from "./styles/ChangePasswordForm.module.scss";
import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";
import { Button } from "antd";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { IChangePasswordFormValues } from "./interfaces/IChangePasswordFormValues";
import { ILoading } from "../../../../common/interfaces/ILoading";
import { IPasswordChange } from "../../../../models/IPasswordChange";
import { IUserId } from "../../../../common/interfaces/IUserId";
import {
  openAlertNotification,
  openSuccessNotification,
} from "../../../../helpers/NotificationHelper";
import { resources } from "../../../../common/Resources";
import { updateUserPassword } from "../../../../services/m29_users_management/GeneralUserService";

export class ChangePasswordFormTemplate extends React.Component<
  IUserId & FormikProps<IChangePasswordFormValues & ILoading>
> {
  render() {
    return (
      <Form className={styles.grid}>
        <div className={styles.formItem}>
          <TextInputF
            {...this.props}
            name="currentPassword"
            label={resources.profileView.currentPass}
            isPassword
            required
          />
        </div>
        <div className={styles.formItem}>
          <TextInputF
            {...this.props}
            name="newPassword"
            label={resources.profileView.newPass}
            isPassword
            required
          />
        </div>
        <div className={`${styles.formItem}`}>
          <TextInputF
            {...this.props}
            name="repeatNewPassword"
            label={resources.profileView.repeatNewPass}
            isPassword
            required
          />
        </div>
        <div className={styles.formFooter}>
          <Button
            className={styles.submit}
            loading={this.props.values.loading}
            htmlType="submit"
            disabled={
              !this.props.isValid ||
              this.props.isSubmitting ||
              this.props.values.loading
            }
          >
            {resources.profileView.change}
          </Button>
          <Button
            className={styles.reset}
            loading={this.props.values.loading}
            htmlType="reset"
            disabled={this.props.isSubmitting || this.props.values.loading}
          >
            {resources.profileView.cancel}
          </Button>
        </div>
      </Form>
    );
  }
}

export const ChangePasswordForm = withFormik<
  IUserId,
  IChangePasswordFormValues & ILoading
>({
  enableReinitialize: true,

  validate: (
    values: IChangePasswordFormValues
  ): FormikErrors<IChangePasswordFormValues> => {
    let errors: FormikErrors<IChangePasswordFormValues> = {};
    const newPasswordError = validatePasswordRules(values.newPassword);
    if (newPasswordError) {
      errors.newPassword = newPasswordError;
    }
    const passwordMatchError = validateNewPasswordMatch(
      values.newPassword,
      values.repeatNewPassword
    );
    if (passwordMatchError) {
      errors.repeatNewPassword = passwordMatchError;
    }
    return errors;
  },

  handleSubmit: async (
    values: IChangePasswordFormValues,
    bag: FormikBag<IUserId, IChangePasswordFormValues>
  ) => {
    const obj: IPasswordChange = {
      currentPassword: values.currentPassword,
      password: values.newPassword,
    };

    await updateUserPassword(bag.props.userId!, obj)
      .then((response) => {
        if (!response.error) {
          openSuccessNotification(resources.statement.updated);
          bag.resetForm();
        } else {
          switch (response.message) {
            case "validation_error": {
              bag.setFieldError(
                "currentPassword",
                resources.validation.current_password_is_incorrect
              );
              break;
            }
            default: {
              openAlertNotification(resources.statement.unrecognized_error);
            }
          }
        }
      })
      .catch((error) => {
        bag.setErrors(error.response.data.errors);
      })
      .finally(() => {
        bag.setSubmitting(false);
      });
  },
})(ChangePasswordFormTemplate);

const validateNewPasswordMatch = (
  newPassword: string,
  repeatNewPassword: string
): string | undefined => {
  if (newPassword !== repeatNewPassword) {
    return resources.validation.requiredTheSamePasswords;
  }
  return undefined;
};

const validatePasswordRules = (newPassword: string): string | undefined => {
  const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32}$)");
  const specChar = new RegExp("[!@#$%^&*]", "g");
  const specMatches = newPassword.match(specChar);
  if (
    !reg.test(newPassword) ||
    !Array.isArray(specMatches) ||
    specMatches.length < 2
  ) {
    return resources.validation.passwordCondition;
  }
  return undefined;
};
