import * as React from 'react';
import { Icon } from 'antd';
import { IFoundRowsNumber } from './interfaces/IFoundRowsNumber';
import { resources } from '../../../common/Resources';
import './styles/FoundRowsNumberStyles.css';

export class FoundRowsNumber extends React.Component<IFoundRowsNumber> {
    public render() {
        return (
            <div className={`foundRowsNumber ${this.props.className}`}>
                {this.props.loading && <Icon type="loading" spin />} {resources.labels.found} <b className='numberCollor'>{this.props.count > 0 ? this.props.count : 0}</b> {resources.labels.rows}
            </div>
        )
    };
}