import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { ProductCategoriesDictionaryContent } from './contents/ProductCategoriesDictionaryContent';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

export class ProductCategoriesDictionaryViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.products_categories_dictionary);
    }

    render() {
        return (
            <ProductCategoriesDictionaryContent />
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const ProductCategoriesDictionaryView: React.ComponentClass = connect(null, mapDispatchToProps)(ProductCategoriesDictionaryViewInner);