import React from 'react';
import { Table, Tooltip, Button } from 'antd';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import { displayIconQualifiedWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ClassifiersForm from '../forms/ClassifiersForm';
import { findInDictionaryAndReturnValueWithSpin, getDictionary } from '../../../../helpers/DisctionariesHelper';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { getQualifiedFilters } from '../../../../common/dictionaries/Filters';

const { Column } = Table;

export class ClassifiersTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                width='80px'
                align='center'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.focusId}
                dataIndex='id_external'
                key='id_external'
                width='120px'
                align='center'
                sorter={(a, b) => a.id_external - b.id_external}
                {...this.getColumnSearchProps('id_external')}
            />,
            <Column
                title={resources.columnTitle.name}
                key='name'
                width='200px'
                align='center'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
                render={(record) => (
                    <Tooltip title={record.name}>
                        {record.name && record.name !== null && record.name.length > 24 ? record.name.substring(0, 18) + '...' : record.name}
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.affbay_status}
                key='id_affbay_status'
                width='150px'
                align='center'
                filters={getDictionary('statusesF')}
                onFilter={(value, record) => record.id_affbay_status === value}
                sorter={(a, b) => a.id_affbay_status - b.id_affbay_status}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.id_affbay_status, this.props.statusesD)
                    )
                }
            />,
            <Column
                title={resources.columnTitle.comment}
                key='postback_comment'
                width='140px'
                align='center'
                sorter={(a, b) => (a.postback_comment ? a.postback_comment : "").localeCompare(b.postback_comment ? b.postback_comment : "")}
                {...this.getColumnSearchProps('postback_comment')}
                render={(record) => (
                    record.postback_comment &&
                    <Tooltip title={record.postback_comment}>
                        <label className='marginLeft'>
                            {record.postback_comment && record.postback_comment.length > 28 ? record.postback_comment.substring(0, 25) + '...' : record.postback_comment}
                        </label>
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.qualified}
                width='130px'
                key='is_qualified'
                align='center'
                sorter={(a, b) => a.is_qualified - b.is_qualified}
                filters={getQualifiedFilters()}
                onFilter={(value, record) => record.is_qualified === value}
                render={(record) => (
                    displayIconQualifiedWithTooltip(record.is_qualified)
                )}
            />,
            <Column
                title={resources.columnTitle.instance}
                key='id_instance'
                width='200px'
                align='center'
                sorter={(a, b) => a.id_instance - b.id_instance}
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_instance, this.props.instancesD)
                )}
            />,
            <Column
                title={resources.columnTitle.campaign_name}
                key='id_campaign'
                width='200px'
                align='center'
                sorter={(a, b) => a.id_instance - b.id_instance}
                render={(record) => (
                    findInDictionaryAndReturnValueWithSpin(record.id_campaign, this.props.campaignsD)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='180px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    <>
                        {convertNumberToDateTimeString(record.created_at)}
                    </>
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                width='75px'
                align='center'
                fixed='right'
                render={(record) => (
                    <>
                        {record.id_affbay_status === 99 &&
                            <ProtectedModal permission={UserPermissionType.EditClassifierModalButton}>
                                <InformationModalDialog
                                    modalTitle={resources.title.editClassifier}
                                    buttonTooltip={resources.title.editClassifier}
                                    icon={"edit"}
                                    disabled={this.state.loading}
                                    withHideMethod={true}
                                >
                                    <ClassifiersForm
                                        name={record.name}
                                        id={record.id}
                                        is_qualified={record.is_qualified}
                                        status={record.id_affbay_status}
                                        affbayClassifiers={this.props.affbayClassifiers}
                                        updateArray={this.props.updateArray}
                                        refreshList={this.props.refreshList}
                                        affbayStatusId={record.id_affbay_status}
                                        setLoading={this.props.setLoading}
                                        id_instance={this.props.id_instance}
                                        id_campaign={this.props.id_campaign}
                                        postback_comment={record.postback_comment}
                                    />
                                </InformationModalDialog>
                            </ProtectedModal>
                        }
                        <ProtectedComponent permission={UserPermissionType.ChangeClassifierStatusButton}>
                            <Tooltip placement="rightTop" title={resources.tooltips.changeQualifiedStatus}>
                                <Button
                                    value={record.id}
                                    onClick={this.props.changeStatus}
                                    icon={record.is_qualified ? "close" : "check"}
                                    style={{ color: record.is_qualified ? 'red' : 'green' }}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='ClassifiersTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                columnNameToCheck='id_affbay_status'
                defaultPageSize={100}
                valueToCheck={99}
            />
        )
    }
}