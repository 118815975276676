import * as React from 'react';
import CouriersStatusDictionaryForm from '../forms/CouriersStatusDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { CouriersStatusesDictionaryTable } from '../table/CouriersStatusesDictionaryTable';
import { deleteCouriersStatusDictionary, getCouriersStatusesDictionaryList } from '../../../../services/dictionaries/CouriersStatusesDictionaryServic';
import { getCouriersListD } from '../../../../services/dictionaries/CouriersDivtionaryService';
import { ICouriersStatus } from '../interfaces/ICouriersStatus';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class CouriersStatusesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        couriersStatusesList: Array<ICouriersStatus>(),
        couriersD: Array<IDictionaryValue>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getCouriersStatusesList();
        this.getCouriersList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.couriers_api_mapping} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getCouriersStatusesList}
                />
                <ProtectedModal permission={UserPermissionType.AddNewCouriersStatuseModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewCouriersStatuses}
                            buttonTitle={resources.modalButtonsTitle.addNewCouriersStatuses}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewCouriersStatuses}
                            icon={"plus"}
                            withHideMethod={true}
                            width={800}
                            buttonClassName='addNew marginBottom'
                            disabled={this.state.loading}
                        >
                            <CouriersStatusDictionaryForm
                                addObjToArray={this.addObjToArray}
                                couriersD={this.state.couriersD}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <CouriersStatusesDictionaryTable
                    loading={this.state.loading}
                    data={this.state.couriersStatusesList}
                    couriersD={this.state.couriersD}
                    deleteCouriersStatuses={this.deleteCouriersStatuses}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getCouriersStatusesList = () => {
        this.setState({ loading: true });

        const couriersStatusesQuery = `SELECT 
                                            id, 
                                            id as "key", 
                                            courier_id, 
                                            status_id, 
                                            courier_status, 
                                            description,
                                            additional_info, 
                                            created_at, 
                                            updated_at
                                        FROM li_couriers_statuses`;

        getCouriersStatusesDictionaryList(couriersStatusesQuery)
            .then(couriersStatusesList => this._isMounted && this.setState({ couriersStatusesList }))
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    deleteCouriersStatuses = (id: number) => {
        const query = `DELETE FROM li_couriers_statuses WHERE id = ${id}`;

        deleteCouriersStatusDictionary(query)
            .then(response => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else
                    openErrorNotification(resources.statement.there_was_a_problem_removing_system_variable);

            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        let couriersStatusesList: ICouriersStatus[] = this.state.couriersStatusesList;

        couriersStatusesList = couriersStatusesList.filter(x => x.id !== id);

        this.setState({
            couriersStatusesList,
            loading: false
        });

        openSuccessNotification(resources.statement.system_variable_deleted);
    }

    addObjToArray = (variable: ICouriersStatus) => {
        this.setState({ couriersStatusesList: [...this.state.couriersStatusesList, variable] });
    }

    updateObjInArray = (obj: ICouriersStatus): void => {
        let couriersStatusesList: ICouriersStatus[] = this.state.couriersStatusesList;

        const index = couriersStatusesList.findIndex(x => x.id === obj.id);

        couriersStatusesList[index].courier_id = obj.courier_id;
        couriersStatusesList[index].status_id = obj.status_id;
        couriersStatusesList[index].courier_status = obj.courier_status;
        couriersStatusesList[index].description = obj.description;
        couriersStatusesList[index].additional_info = obj.additional_info;
        couriersStatusesList[index].updated_at = obj.updated_at;

        this.setState({ couriersStatusesList });
    }

    getCouriersList = () => {
        getCouriersListD()
            .then(couriersD => { this._isMounted && this.setState({ couriersD }); });
    }
}