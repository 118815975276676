import moment, { Moment } from 'moment';
import { isNullOrUndefined } from 'util';

export const convertDateToYear = (val: Date): string => {
    return isNullOrUndefined(val) ? "" : moment(val).format('YYYY');
}

export const formatDate = (val: Date): string => {
    return isNullOrUndefined(val) ? "" : moment(val).format('DD.MM.YYYY');
}

export const formatDateTime = (val: Date): string => {
    return isNullOrUndefined(val) ? "" : moment(val).format('YYYY/MM/DD HH:mm:ss');
}

export const getDateForLEFilter = (val: Moment): Date => {
    return val.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate();
}

export const convertDateToMoment = (val?: Date): Moment | undefined => {
    return isNullOrUndefined(val) ? undefined : moment(val);
}

export const intToTimezoneString = (val: number): string => {
    const sign = val >= 0 ? '+' : '-';
    const hour = Math.abs(val) > 9 ? Math.abs(val) : `0${Math.abs(val)}`;
    return `${sign}${hour}:00`;
}

export const intToHourString = (val: number): string => {
    const hour = Math.abs(val) > 9 ? Math.abs(val) : `0${Math.abs(val)}`;
    return `${hour}:00`;
}

export const momentToHour = (val: Moment): string => {
    return moment.utc(val).format('HH:mm');
}

export const momentToDayMonth = (val: Moment): string => {
    return moment.utc(val).format('DD MMM');
}

export const momentToMonthYear = (val: Moment): string => {
    return moment.utc(val).format('MMM YY');
}