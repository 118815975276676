import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/CountriesDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createCountriesDictionary, updateCountriesDictionary } from '../../../../services/dictionaries/CountriesDivtionaryService';
import { emailIsValid } from '../../../../helpers/ValidationHelpers';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { ICountryDictionary } from '../interfaces/ICountryDictionary';
import { IDefaultProps } from '../interfaces/IDefaultProps';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class TemplateCountriesDictionaryForm extends React.Component<ICountryDictionary & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & IDefaultProps & FormikProps<ICountryDictionary>> {
    public static defaultProps = {
        labelTextAlignLeft: true,
        boldLabel: true,
        allowClear: true
    }

    public render() {
        return (
            <Form>
                <section>
                    {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                    <div className={styles.grid_col_3}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="name"
                                label={resources.labels.name}
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="currency"
                                label={resources.labels.currency}
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="code"
                                label={resources.labels.code}
                            />
                        </div>
                    </div>
                    <div className={styles.sectionDivider}></div>
                    <div className={styles.grid_col_2}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="company_name"
                                label={resources.labels.company}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="address"
                                label={resources.labels.address}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_2}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="email"
                                label={resources.labels.email}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="phone"
                                label={resources.labels.phone}
                                isNumeric
                            />
                        </div>
                    </div>
                    <div className={styles.sectionDivider}></div>
                    <div className={styles.grid_col_2}>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='is_active'
                                label={resources.labels.isActive}
                                dictionaryValues={getBooleanValues()}
                                allowClear={false}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='is_operative'
                                label={resources.labels.operative}
                                dictionaryValues={getBooleanValues()}
                                allowClear={false}
                            />
                        </div>
                    </div>
                </section>
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const CountriesDictionaryForm = withFormik<ICountryDictionary & IUpdateObjInArray & IAddObjToArray & IId & IDefaultProps, ICountryDictionary>({
    mapPropsToValues: (props: ICountryDictionary) => {
        return {
            name: props.name,
            is_active: Boolean(props.is_active) ? '1' : '0',
            code: props.code,
            is_operative: Boolean(props.is_operative) ? '1' : '0',
            company_name: props.company_name,
            address: props.address,
            email: props.email,
            phone: props.phone,
            currency: props.currency
        };
    },

    validate: (values: ICountryDictionary) => {
        const errors: FormikErrors<ICountryDictionary> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (values.company_name && values.company_name.length > 255) {
            errors.company_name = resources.validation.requiredMax255Characters;
        }
        if (values.email && values.email.length > 255) {
            errors.email = resources.validation.requiredMax255Characters;
        }
        else if (values.email && !emailIsValid(values.email)) {
            errors.email = resources.validation.incorrectEmailAdres;
        }
        if (values.phone && values.phone.length > 255) {
            errors.phone = resources.validation.requiredMax255Characters;
        }
        if (values.code && values.code.length > 3) {
            errors.code = resources.validation.requiredMin3Characters;
        }
        else if (!values.currency) {
            errors.currency = resources.validation.required;
        }
        else if (values.currency && values.currency.length > 3) {
            errors.currency = resources.validation.requiredMin3Characters;
        }

        return errors;
    },

    handleSubmit: (values: ICountryDictionary, bag: FormikBag<ICountryDictionary & IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId, ICountryDictionary>) => {
        const object: ICountryDictionary = {
            name: values.name,
            is_active: values.is_active,
            is_operative: values.is_operative,
            code: values.code ? values.code : '',
            company_name: values.company_name ? values.company_name : '',
            address: values.address ? values.address : '',
            email: values.email ? values.email : '',
            phone: values.phone ? values.phone : '',
            currency: values.currency ? values.currency : ''
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.name === bag.props.name
            && values.code === bag.props.code
            && values.is_active === bag.props.is_active
            && values.is_operative === bag.props.is_operative
            && values.company_name === bag.props.company_name
            && values.address === bag.props.address
            && values.email === bag.props.email
            && values.phone === bag.props.phone
            && values.currency === bag.props.currency
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(TemplateCountriesDictionaryForm);

export default CountriesDictionaryForm;

const createQuery = async (obj: ICountryDictionary, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId) => {
    const createQuery =
        `INSERT INTO li_countries
            (
                name,
                code,
                is_active,
                is_operative,
                company_name,
                address,
                email,
                phone,
                currency
            )
        VALUES
            (
                "${obj.name}",
                "${obj.code}",
                ${obj.is_active},
                ${obj.is_operative},
                "${obj.company_name}",
                "${obj.address}",
                "${obj.email}",
                "${obj.phone}",
                "${obj.currency}"
            )`;

    const updateQuery =
        `UPDATE li_countries SET
                name = "${obj.name}",
                code = "${obj.code}",
                is_active = ${obj.is_active},
                is_operative = ${obj.is_operative},
                company_name = "${obj.company_name}",
                address = "${obj.address}",
                email = "${obj.email}",
                phone = "${obj.phone}",
                currency = "${obj.currency}"
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateCountriesDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.is_active = Number(obj.is_active);
                obj.is_operative = Number(obj.is_operative);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);
                openSuccessNotification(resources.statement.country_updated);
            });
    }
    else {
        await createCountriesDictionary(createQuery)
            .then((response) => {
                obj.id = response;
                obj.key = response;
                obj.updated_at = new Date();
                obj.is_active = Number(obj.is_active);
                obj.is_operative = Number(obj.is_operative);

                bag.addObjToArray!(obj);
                openSuccessNotification(resources.statement.country_created);
            });
    }

    bag.hideModal!();
}