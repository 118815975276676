import React from 'react';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { Switch } from 'antd';
import { changeP2ResaleRunningStatus, getP2ResalesProducts, setP2ProductRulesForResale } from '../../../../services/m66_sales/SalesService';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IResaleProduct } from '../interfaces/IResaleProduct';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { ResaleTimer } from '../../components/resaleTimer/ResaleTimer';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { getFocusCampaigns } from '../../../../services/focus/FocusCampaigns';
import { getCcInstancesD } from '../../../../services/m40_libraries/LibrariesService';
import { ICampaign } from '../../../callCenter/classifiers/interfaces/ICampaign';
import { IInstance } from '../../../../models/IInstance';
import { ResalesTable } from '../table/ResaleTable';

export class ResalesContent extends React.Component {
    _isMounted = false;
    instances = [] as Array<IInstance>;
    campaigns = [] as Array<ICampaign>;

    state = {
        loading: true,
        isRunning: false,
        partnerToken: '-',
        nextRunAt: '',
        countryId: 1,
        products: [] as IResaleProduct[],
        countriesNameD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getCountriesD();
        this.instances = await getCcInstancesD() as Array<IInstance>;
        const data = await Promise.all(this.instances.map((instance) => getFocusCampaigns(`${instance.id}`)));
        for (const el of data) {
            this.campaigns.push(...el);
        }
        await this.getList();
        window.scrollTo(0, 0);
        this._isMounted && this.setState({ loading: false });
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { products, countryId, loading } = this.state;

        let filteredProducts: IResaleProduct[] = products;
        let filteredCampaigns: Array<ICampaign> = this.campaigns;

        // Filter by country
        if (countryId) {
            filteredProducts = filteredProducts.filter(x => x.country_id === countryId);
            filteredCampaigns = filteredCampaigns.filter((campaign) => {
                const instance = this.instances.find((instance) => instance.country_id === this.state.countryId);
                if (instance) {
                    return instance.id === campaign.id_instance;
                }
                return false;
            });
        }

        return (
            <>
                <TableContainer>
                    <PageInf title={resources.pageTitle.resaleP2} />
                    <div style={{ textAlign: "justify" }} >
                        <ResaleTimer
                            nextRunTime={this.state.nextRunAt}
                            isRunning={this.state.isRunning}
                        />
                        <Left>
                            <RefreshTableButton
                                className='marginRigth marginBottom marginTopRefreshButton23px'
                                loading={this.state.loading}
                                onClick={this.getList}
                            />
                        </Left>
                        <Left>
                            <FieldInfo>{resources.labels.country}</FieldInfo>
                            <SelectOnChange
                                onChange={(_: string, value: number) => this.setState({ countryId: value })}
                                name='country_id'
                                className='marginRigth marginBottom'
                                value={this.state.countryId}
                                placeholder={resources.select.select_country}
                                items={this.state.countriesNameD}
                                disabled={this.state.loading}
                                width={200}
                                allowClear
                            />
                        </Left>
                        <Right>
                            <div className='marginBottom marginLeft marginRigth' style={{ marginTop: 29 }}>
                                {resources.labels.create_leads_on_order_delivered_status_global} <Switch checked={this.state.isRunning} onChange={value => this.onChangeIsRunning('is_running', value)} />
                            </div>
                        </Right>
                        <ClearBoth />
                    </div>
                    {!loading ? <div>
                        <ResalesTable 
                            data={filteredProducts}
                            allProducts={filteredProducts}
                            campaigns={filteredCampaigns}
                            toogleResale={this.toggleResale}
                            postConfig={this.postResale}
                        />
                    </div> : null}
                </TableContainer>
            </>
        )
    };

    getCountriesD = () => {
        this.setState({ countriesNameD: getDictionary('countriesNameD') });
    }

    onChangeIsRunning = (name: string, value: boolean) => {
        this.setState({ loading: true });

        changeP2ResaleRunningStatus()
            .then(response => {
                if (response) {
                    this._isMounted && this.setState({ [name]: value });
                    openSuccessNotification(resources.statement.updated);
                }
                else
                    openErrorNotification(resources.statement.databaseConnectionError);
            })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    getList = () => {
        this.setState({ loading: true });
        getP2ResalesProducts()
            .then(response => {
                this._isMounted && response && this.setState({
                    products: response.products,
                    isRunning: response.is_running,
                    nextRunAt: response.next_run_at,
                    partnerToken: response.partner_token,
                });
            })
            .catch(e => {
                openErrorNotification('Error getting resale data');
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    toggleResale = async (resaleProduct: IResaleProduct) => {
        try {
            await setP2ProductRulesForResale({
                products: [{
                    ...resaleProduct,
                    is_active: !resaleProduct.is_active,
                }],
            });
            this.getList();
        }
        catch (e) {
            openErrorNotification(e);
        }
    }

    postResale = (resaleProduct: IResaleProduct): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            try {
                await setP2ProductRulesForResale({
                    products: [{
                        ...resaleProduct,
                    }],
                });
                this.getList();
                resolve(true);
            }
            catch (e) {
                openErrorNotification(e);
                reject();
            }
        })
    }
}