import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IAppState } from '../../../store';
import { IAppUserInfoModel } from '../../../models/IAppUserInfoModel';
import { IId } from '../../../common/interfaces/IId';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { OfferDetailsContent } from './contents/OfferDetailsContent';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

interface IProps {
    appUserInfo?: IAppUserInfoModel;
}

class OfferDetailsViewInner extends React.Component<RouteComponentProps<IId> & ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.offers);
    }

    public render() {
        return (
            <OfferDetailsContent id={this.props.match.params.id}/>
        )
    };
}

const mapStateToProps = (state: IAppState): IProps => {
    return {
        appUserInfo: state.commonState.appUserInfo,
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const OffersDetailsView = connect(mapStateToProps, mapDispatchToProps)(OfferDetailsViewInner);