import * as React from "react";
import desktop from "./images/desktop.svg";
import mobile from "./images/mobile.svg";
import noImg from "../../../../../common/images/noImg.png";
import ProtectedComponent from "../../../../../components/authorizations/protectedComponents/ProtectedComponent";
import styles from "./styles/ProductCardOffer.module.scss";
import {
  decodeCategoriesArray,
  removeLastCharIfNumber,
} from "../../../../../helpers/Encoders";
import { displayPrice } from "../../../../../helpers/DisplayHepler";
import {
  findInDictionaryAndReturnValue,
  findInDictionaryAndReturnValueWithSpin,
  getDictionary,
} from "../../../../../helpers/DisctionariesHelper";
import { getLocale } from "../../../../../helpers/LocaleHelper";
import { IProductCardOffer } from "./interfaces/IProductCardOffer";
import { resources } from "../../../../../common/Resources";
import { Tooltip, Typography } from "antd";
import { UserPermissionType } from "../../../../../models/IAppUserInfoModel";

const { Text } = Typography;

export class ProductCardOffer extends React.Component<IProductCardOffer> {
  public render() {
    const { productData, categories, currencyD } = this.props;

    const categoriesList = productData.categories
      ? productData.categories.split(",")
      : [];

    let countryName = "-";
    if (productData.country_id) {
      countryName = findInDictionaryAndReturnValueWithSpin(
        productData.country_id,
        getDictionary("countriesNameD")
      ) as string;
    }

    return (
      <article className={styles.productCard}>
        <header className={styles.productCard__header}>
          <img
            src={this.props.img === undefined ? noImg : this.props.img}
            alt="No img"
          />
        </header>
        <section className={styles.productCard__body}>
          <h3 className={`h3 ${styles.heading}`}>{productData.name_short}</h3>
          <div className={styles.productCard__table}>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersDetails.sku}
              </p>
              <div className={`${styles.productCard__table__row__value}`}>
                <Tooltip title={productData.product_sku}>
                  {productData.product_sku &&
                  productData.product_sku.length > 24
                    ? productData.product_sku.substring(0, 20) + "..."
                    : productData.product_sku}{" "}
                  <Text
                    style={{
                      color: "white",
                      textAlign: "right",
                      minWidth: 400,
                    }}
                    copyable={{ text: productData.product_sku }}
                  />
                </Tooltip>
              </div>
            </div>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersDetails.payout}
              </p>
              <p className={styles.productCard__table__row__value}>
                {displayPrice(productData.default_payout)}
              </p>
            </div>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.labels.payoutScheme}
              </p>
              <p
                className={`h6 ${styles.productCard__table__row__value} uppercase`}
              >
                {productData.default_payout_schema}
              </p>
            </div>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersDetails.sitePrice}
              </p>
              <p className={`h6 ${styles.productCard__table__row__value}`}>
                {displayPrice(
                  productData.default_landing_price,
                  findInDictionaryAndReturnValue(
                    productData.country_id,
                    currencyD
                  )
                )}
              </p>
            </div>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersDetails.category}
              </p>
              <p className={`h6 ${styles.productCard__table__row__value}`}>
                {decodeCategoriesArray(categoriesList, categories)}
              </p>
            </div>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersDetails.country}
              </p>
              <p className={`h6 ${styles.productCard__table__row__value}`}>
                {removeLastCharIfNumber(countryName)}
              </p>
            </div>
            <ProtectedComponent
              permission={UserPermissionType.SeeOriginalProductCountry}
            >
              <div
                className={`${styles.productCard__table__row} ${styles.admin_table_row}`}
              >
                <p className={`h6 ${styles.productCard__table__row__label}`}>
                  {resources.offersView.originalCountry}
                </p>
                <p className={`h6 ${styles.productCard__table__row__value}`}>
                  {countryName}
                </p>
              </div>
            </ProtectedComponent>
            <div className={styles.productCard__table__row}>
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersDetails.devices}
              </p>
              <div
                className={`h6 ${styles.productCard__table__row__value}, ${styles.productCard__table__row__value__icons}`}
              >
                <img src={desktop} alt="desktop" />
                <img src={mobile} alt="mobile" />
              </div>
            </div>
          </div>
        </section>
      </article>
    );
  }

  content = () => {
    const description =
      getLocale() === "en_GB"
        ? this.props.productData.description_short_en
        : this.props.productData.description_short_ru;

    return description ? description : "";
  };
}
