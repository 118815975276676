import * as React from 'react';
import styles from './styles/NotificationForm.module.scss';
import { Button, Input, Select } from 'antd';
import {
    IMessage,
    INotificationBody,
    MessageReceiverType,
    MessageType,
    NotificationType
    } from '../../../../../services/m80_web_socket/interfaces/IMessage';
import { sendMessageToRoles, sendMessageToUsers } from '../../../../../services/m80_web_socket/WebSocketRequestService';

const { Option } = Select;

interface NotificationFormProps {
    receiverType: string;
    receivers: Array<number>;
    countries: Array<number>;
}


export class NotificationForm extends React.Component<NotificationFormProps> {
    state = {
        notificationType: undefined,
        message: ''
    }

    handleSubmit = () => {
        const { notificationType, message } = this.state;

        const eventBody = {
            type: MessageType.NOTIFICATION,
            data: {
                type: notificationType ? notificationType : NotificationType.ERROR,
                message: message,
            } as INotificationBody,
            to: this.props.receivers,
            countryIds: this.props.countries,
        } as IMessage;
        if (this.props.receiverType === MessageReceiverType.USERS) {
            sendMessageToUsers(eventBody);
        }
        else if (this.props.receiverType === MessageReceiverType.ROLES) {
            sendMessageToRoles(eventBody);
        }
    }

    public render() {

        const { notificationType, message } = this.state;
        const { countries, receivers, receiverType } = this.props;

        let submitButton;

        if (notificationType !== undefined
            && message
            && receivers.length > 0
            && (receiverType === MessageReceiverType.USERS || countries.length > 0)) {
            submitButton = <div className={styles.element}>
                <Button onClick={this.handleSubmit}>Submit</Button>
            </div>;
        }

        return (
            <div className={styles.container}>
                <div className={styles.element}>
                    <p>Notification type</p>
                    <Select
                        placeholder="Select notification type"
                        filterOption={false}
                        style={{ width: 300 }}
                        value={notificationType}
                        onChange={(notificationType: string) => this.setState({ notificationType })}
                    >
                        {Object.values(NotificationType).map((notificationType: string) =>
                            <Option key={notificationType} value={notificationType}>{notificationType}</Option>
                        )}
                    </Select>
                </div>
                <div className={styles.element}>
                    <p>Message</p>
                    <Input className={styles.inputContainer} value={message} onChange={(e: any) => this.setState({ message: e.target.value })} />
                </div>
                {submitButton}
            </div>
        )
    };
}