import * as React from "react";
import { IFieldInfoProps } from "./interfaces/IFieldInfoProps";
import "./styles/FieldInfoStyles.css";

export class FieldInfo extends React.Component<IFieldInfoProps> {
  public render() {
    return (
      <div className={`fieldInfo ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}
