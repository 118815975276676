import * as React from 'react';
import CitiesDictionaryForm from '../forms/StatusesDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { deleteStatusesDictionary, getStatusesDictionaryList } from '../../../../services/dictionaries/StatusesDivtionaryService';
import { IStatus } from '../interfaces/IStatus';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { StatusesDictionaryTable } from '../table/StatusesDictionaryTable';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class StatusesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        statusesList: Array<IStatus>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getStatusesList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.statusesDictionary} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getStatusesList}
                />
                <ProtectedModal permission={UserPermissionType.AddNewStatusModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewStatus}
                            buttonTitle={resources.modalButtonsTitle.addNewStatus}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewStatus}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={this.state.loading}
                        >
                            <CitiesDictionaryForm
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <StatusesDictionaryTable
                    loading={this.state.loading}
                    data={this.state.statusesList}
                    deleteStatus={this.deleteStatus}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getStatusesList = () => {
        this.setState({ loading: true });

        getStatusesDictionaryList()
            .then(statusesList => { this._isMounted && this.setState({ statusesList }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    deleteStatus = async (id: number) => {
        const query = `DELETE FROM li_statuses WHERE id = ${id}`;

        await deleteStatusesDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_status);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const statusesList = this.state.statusesList.filter(x => x.id !== id);

        this.setState({
            statusesList,
            loading: false
        });

        openSuccessNotification(resources.statement.status_deleted);
    }

    addObjToArray = (status: IStatus) => {
        this.setState({ statusesList: [...this.state.statusesList, status] });
    }

    updateObjInArray = (obj: IStatus): void => {
        const { statusesList } = this.state;
        const index = statusesList.findIndex(x => x.id === obj.id);

        statusesList[index].name = obj.name;
        statusesList[index].updated_at = obj.updated_at;

        this.setState({ statusesList });
    }
}