import React from 'react';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
} from 'formik';
import { IResaleForm } from './IResaleForm';
import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { IResaleProduct } from '../interfaces/IResaleProduct';
import { Button } from 'antd';
import { resources } from '../../../../common/Resources';

class TemplateP2Form extends React.Component<IResaleForm & FormikProps<IResaleProduct>> {

    public render() {
        const { campaigns, products } = this.props;
        return (
            <Form>
                <h2>{this.props.product.name}</h2>
                <div>
                    <DictionaryF
                        {...this.props}
                        labelTextAlignLeft
                        name='female_product_id'
                        label={'Female product'}
                        dictionaryValues={products.map((p) => ({
                            id: p.newId,
                            name: p.name, 
                        }))}
                        required
                    />
                </div>
                <div>
                    <DictionaryF
                        {...this.props}
                        labelTextAlignLeft
                        name='female_campaign_id'
                        label={'Female campaign'}
                        dictionaryValues={campaigns.map((c) => ({
                            id: c.id,
                            name: c.name, 
                        }))}
                        required
                    />
                </div>
                <div>
                    <DictionaryF
                        {...this.props}
                        labelTextAlignLeft
                        name='male_product_id'
                        label={'Male product'}
                        dictionaryValues={products.map((p) => ({
                            id: p.newId,
                            name: p.name, 
                        }))}
                        required
                    />
                </div>
                <div>
                    <DictionaryF
                        {...this.props}
                        labelTextAlignLeft
                        name='male_campaign_id'
                        label={'Male campaign'}
                        dictionaryValues={campaigns.map((c) => ({
                            id: c.id,
                            name: c.name, 
                        }))}
                        required
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='partner_token'
                        label={'Token'}
                        required
                    />
                </div>
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                    disabled={!this.props.isValid || this.props.isSubmitting}
                >{resources.buttons.submit}</Button>
            </Form>
        );
    };


}

const P2Form = withFormik<IResaleForm & IHideModal, IResaleProduct>({

    mapPropsToValues: props => ({
        ...(props.product),
    }),

    validate: (values: IResaleProduct, props: IResaleForm & IHideModal): FormikErrors<IResaleProduct> => {
        const errors = {} as FormikErrors<IResaleProduct>;
        if (values.partner_token === '' || values.partner_token === null) {
            errors.partner_token = 'Required!';
        }
        const productsIds = props.products.map(p => p.newId);
        const campaignsIds = props.campaigns.map(c => c.id);
        if (!productsIds.includes(values.female_product_id || 0)) {
            errors.female_product_id = 'Incorrect product!'
        }
        if (!productsIds.includes(values.male_product_id || 0)) {
            errors.male_product_id = 'Incorrect product!'
        }
        if (!campaignsIds.includes(values.female_campaign_id || 0)) {
            errors.female_campaign_id = 'Incorrect product!'
        }
        if (!campaignsIds.includes(values.male_campaign_id || 0)) {
            errors.male_campaign_id = 'Incorrect product!'
        }
        return errors;
    },

    handleSubmit: async (values: IResaleProduct, bag: FormikBag<IResaleForm & IHideModal, IResaleProduct>) => {
        await bag.props.submit(values);
        bag.props.hideModal && bag.props.hideModal();
    },
    enableReinitialize: true
})(TemplateP2Form);

export default P2Form;