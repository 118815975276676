import decode from "jwt-decode";
import React from "react";
import styles from "./styles/LoginForm.module.scss";
import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";
import { Button } from "antd";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { emailIsValid } from "../../../../helpers/ValidationHelpers";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { getInitialState } from "../../../../helpers/Authentication";
import { getToken } from "../../../../helpers/LocalStorageHelpers";
import { history } from "../../../../helpers/History";
import { IAppUserInfoModel } from "../../../../models/IAppUserInfoModel";
import {
  ICommonState,
  setAppUserInfo,
  setBlockUi,
  setIsAuthenticated,
} from "../../../../reducers/CommonReducer";
import { ILoginFormProps as IFormProps } from "./interfaces/ILoginFormProps";
import { ILoginFormValues as IFormValues } from "./interfaces/ILoginFormValues";
import { ISetAppUserInfo } from "../../../../common/interfaces/ISetAppUserInfo";
import { ISetBlockUi } from "../../../../common/interfaces/ISetBlockUi";
import { ISetIsAuthenticated } from "../../../../common/interfaces/ISetIsAuthenticated";
import { ITokenData } from "../../../../helpers/interfaces/ITokenData";
import { login } from "../../../../services/AuthenticationServices";
import { _onSetLanguage, resources } from "../../../../common/Resources";

type IFormDispatchProps = ISetAppUserInfo & ISetBlockUi & ISetIsAuthenticated;

const lang: string = localStorage.getItem("locale")
  ? localStorage.getItem("locale")!
  : "en_GB";

const formikTranslation: any = {
  en_GB: {
    email: "E-mail", //
    password: "Password", //
  },
  ru_RU: {
    email: "E-mail", //
    password: "Пароль", //
  },
  pl_PL: {
    email: "E-mail", //
    password: "Hasło", //
  },
};

class InnerLoginForm extends React.Component<
  IFormProps & IFormDispatchProps & FormikProps<IFormValues>
> {
  public static defaultProps = {
    readOnly: false,
    disabled: false,
    allowClear: true,
    labelTextAlignLeft: true,
  };

  public render() {
    return (
      <article className={styles.grid}>
        <Form>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="email"
              label={formikTranslation[lang].email}
              disabled={this.props.values.loading}
              required
            />
          </div>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="password"
              label={formikTranslation[lang].password}
              isPassword
              disabled={this.props.values.loading}
              required
            />
          </div>
          <div className={styles.formFooter}>
            <Button
              className={`h4__bold ${styles.submit}`}
              htmlType="submit"
              loading={this.props.values.loading}
            >
              {resources.loginPage.logIntoThePlatform}
            </Button>
          </div>
        </Form>
      </article>
    );
  }
}

const FormWithFormik = withFormik<IFormProps & IFormDispatchProps, IFormValues>(
  {
    mapPropsToValues: () => {
      return {
        email: undefined,
        password: undefined,
        loading: false,
      };
    },

    validate: (values: IFormValues) => {
      const errors: FormikErrors<IFormValues> = {};

      if (!values.email) {
        errors.email = resources.validation.emailIsRequired;
      } else if (values.email && !emailIsValid(values.email)) {
        errors.email = resources.validation.incorrectEmailAdres;
      }
      if (values.email && values.email.length > 255) {
        errors.email = resources.validation.requiredMax255Characters;
      }
      if (values.password && values.password.length > 255) {
        errors.password = resources.validation.requiredMax255Characters;
      }
      if (!values.password) {
        errors.password = resources.validation.passwordIsRequired;
      }

      return errors;
    },

    handleSubmit: (
      values: IFormValues,
      bag: FormikBag<IFormDispatchProps, IFormValues>
    ) => {
      if (!localStorage.getItem("locale")) {
        _onSetLanguage("pl_PL");
        localStorage.setItem("locale", "pl_PL");
      }

      loginAxios(
        values.email!,
        values.password!,
        bag.props.setIsAuthenticated,
        bag.props.setBlockUi,
        bag.props.setAppUserInfo,
        bag.setFieldError,
        bag.setFieldValue
      );
    },

    validateOnBlur: false,
    validateOnChange: false,
  }
)(InnerLoginForm);

const mapDispatchToProps: MapDispatchToProps<IFormDispatchProps, {}> = (
  dispatch: Dispatch
) => ({
  setIsAuthenticated: (isAuthenticated: boolean) => {
    dispatch(setIsAuthenticated(isAuthenticated));
  },
  setBlockUi: (blockUi: boolean) => {
    dispatch(setBlockUi(blockUi));
  },
  setAppUserInfo: (appUserInfoModel: IAppUserInfoModel) => {
    dispatch(setAppUserInfo(appUserInfoModel));
  },
});

const LoginForm: React.ComponentClass = connect(
  null,
  mapDispatchToProps
)(FormWithFormik);

export default LoginForm;

const loginAxios = (
  email: string,
  password: string,
  setIsAuthenticated: (iaAuthenticated: boolean) => void,
  setBlockUi: (blockUi: boolean) => void,
  setAppUserInfo: (userInfo: IAppUserInfoModel) => void,
  setLoginError: (field: any, errorMessage: string) => void,
  setFieldValue: (field: any, errorMessage: boolean) => void
) => {
  setFieldValue("loading", true);

  login(email, password)
    .then((response) => {
      if (!response.error) {
        let initialState: ICommonState = getInitialState();

        const token = getToken();
        const decoded: ITokenData = decode(token!);

        const aud: number[] = decoded.aud.map((item) => {
          return Number(item);
        });

        decoded.aud = aud;

        initialState.appUserInfo = {
          id: decoded.sub,
          permissions: decoded.aud,
          email: decoded.username,
          acc: { orders: decoded.acc.orders },
        };

        setAppUserInfo(initialState.appUserInfo);
        setIsAuthenticated(true);
        setBlockUi(false);

        history.push("/cc_instances");
      } else {
        switch (response.message) {
          case "user_deactivated":
            setLoginError(
              "password",
              `You account is not active. Please check email.`
            );
            break;
          case "incorrect_credentials":
          case "user_not_found":
          default:
            setLoginError(
              "password",
              resources.statement.incorrect_login_information
            );
        }

        setFieldValue("loading", false);
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
};
