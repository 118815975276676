import dashboardIcon from "../components/navigation/images/dashboard.svg";
import financeIcon from "../components/navigation/images/finance.svg";
import newsIcon from "../components/navigation/images/news.svg";
import offersIcon from "../components/navigation/images/offers.svg";
import statisticsIcon from "../components/navigation/images/statistics.svg";
import financeStatisticsIcon from "../components/navigation/images/finance-statistics.svg";
import supportIcon from "../components/navigation/images/support.svg";
import toolsIcon from "../components/navigation/images/tools.svg";
import {
  generateMenuItem,
  generateSubMenu,
} from "./../helpers/MenuItemGenerator";
import { hasPermission } from "../authorizations/AuthorizationLogic";
import { navPath } from "../common/ResourceDef";
import { resources } from "../common/Resources";
import { UserPermissionType } from "../models/IAppUserInfoModel";

// =========
// main menu
// =========
export const getMenuItems = (permissions: number[]): any[] => {
  let menuItems = [];

  permissions.includes(UserPermissionType.DashboardView) &&
    menuItems.push(
      generateMenuItem(
        navPath.dashboard,
        resources.navigationItems.dashboard,
        navPath.dashboard,
        { type: "custom", source: dashboardIcon }
      )
    );
  permissions.includes(UserPermissionType.OffersListView) &&
    menuItems.push(
      generateMenuItem(
        navPath.offers,
        resources.navigationItems.offers,
        navPath.offers,
        { type: "custom", source: offersIcon }
      )
    );
  permissions.includes(UserPermissionType.LeadsView) &&
    menuItems.push(
      generateMenuItem(
        navPath.leads,
        resources.navigationItems.leads,
        navPath.leads,
        { type: "ant", source: "area-chart" }
      )
    );
  hasPermission(UserPermissionType.StatisticsView) &&
    menuItems.push(
      generateMenuItem(
        "statistics",
        resources.navigationItems.statistics,
        "statistics",
        { type: "custom", source: statisticsIcon }
      )
    );
  hasPermission(UserPermissionType.FinanceStatisticsView) &&
    menuItems.push(
      generateMenuItem(
        "statistics/date_approved_at",
        resources.navigationItems.financeStatistics as string,
        "statistics/date_approved_at",
        { type: "custom", source: financeStatisticsIcon }
      )
    );
  permissions.includes(UserPermissionType.EmployeesView) &&
    menuItems.push(
      generateMenuItem(
        navPath.employees,
        resources.navigationItems.employees,
        navPath.employees,
        { type: "ant", source: "team" }
      )
    );
  permissions.includes(UserPermissionType.AccountManagersView) &&
    menuItems.push(
      generateMenuItem(
        navPath.account_managers,
        resources.navigationItems.account_managers,
        navPath.account_managers,
        { type: "ant", source: "book" }
      )
    );
  permissions.includes(UserPermissionType.ProductsView) &&
    menuItems.push(
      generateMenuItem(
        navPath.products,
        resources.navigationItems.products,
        navPath.products,
        { type: "ant", source: "barcode" }
      )
    );
  permissions.includes(UserPermissionType.PagesView) &&
    menuItems.push(
      generateMenuItem(
        navPath.pages,
        resources.navigationItems.pages,
        navPath.pages,
        { type: "ant", source: "star" }
      )
    );
  permissions.includes(UserPermissionType.CampaignsPartnerView) &&
    menuItems.push(
      generateMenuItem(
        navPath.campaigns_partner,
        resources.navigationItems.campaigns_partner,
        navPath.campaigns_partner,
        { type: "ant", source: "deployment-unit" }
      )
    );
  permissions.includes(UserPermissionType.CampaignsAdminView) &&
    menuItems.push(
      generateMenuItem(
        navPath.campaigns_admin,
        resources.navigationItems.campaigns_admin,
        navPath.campaigns_admin,
        { type: "ant", source: "deployment-unit" }
      )
    );
  permissions.includes(UserPermissionType.CompaniesView) &&
    menuItems.push(
      generateMenuItem(
        navPath.companies,
        resources.navigationItems.companies,
        navPath.companies,
        { type: "ant", source: "global" }
      )
    );
  permissions.includes(UserPermissionType.PartnersView) &&
    menuItems.push(
      generateMenuItem(
        navPath.partners,
        resources.navigationItems.partners,
        navPath.partners,
        { type: "ant", source: "idcard" }
      )
    );
  permissions.includes(UserPermissionType.ToolsView) &&
    menuItems.push(
      generateMenuItem(
        navPath.tools,
        resources.navigationItems.tools,
        navPath.tools,
        { type: "custom", source: toolsIcon }
      )
    );
  permissions.includes(UserPermissionType.FinanceView) &&
    menuItems.push(
      generateMenuItem(
        navPath.finance,
        resources.navigationItems.finance,
        navPath.finance,
        { type: "custom", source: financeIcon }
      )
    );
  permissions.includes(UserPermissionType.NewsView) &&
    menuItems.push(
      generateMenuItem(
        navPath.news,
        resources.navigationItems.news,
        navPath.news,
        { type: "custom", source: newsIcon }
      )
    );
  permissions.includes(UserPermissionType.NewsAdminView) &&
    menuItems.push(
      generateMenuItem(
        navPath.news_admin,
        resources.navigationItems.news_admin,
        navPath.news_admin,
        { type: "custom", source: newsIcon }
      )
    );
  permissions.includes(UserPermissionType.SupportView) &&
    menuItems.push(
      generateMenuItem(
        navPath.support,
        resources.navigationItems.support,
        navPath.support,
        { type: "custom", source: supportIcon }
      )
    );
  permissions.includes(UserPermissionType.FaqView) &&
    menuItems.push(
      generateMenuItem(
        navPath.faq,
        resources.navigationItems.faq,
        navPath.faq,
        { type: "ant", source: "info-circle" }
      )
    );
  (permissions.includes(UserPermissionType.UsersView) ||
    permissions.includes(UserPermissionType.UsersRoleView) ||
    permissions.includes(UserPermissionType.WebSocketView) ||
    permissions.includes(UserPermissionType.K8ServicesView)) &&
    menuItems.push(
      generateSubMenu(
        resources.subNavigationItem.administration,
        administrationSubmenu(permissions),
        { source: "fire" }
      )
    );
  (permissions.includes(UserPermissionType.LeadsRedirectionView) ||
    permissions.includes(UserPermissionType.InstancesView) ||
    permissions.includes(UserPermissionType.CallCenterCampaignsView) ||
    permissions.includes(UserPermissionType.ClassifiersVies) ||
    permissions.includes(UserPermissionType.MappingClassifiersView) ||
    permissions.includes(UserPermissionType.SystemClassifiersView) ||
    permissions.includes(UserPermissionType.WebhooksView)) &&
    menuItems.push(
      generateSubMenu(
        resources.subNavigationItem.callCenter,
        callcenterSubmenu(permissions),
        { source: "phone", rotate: -90 }
      )
    );
  (permissions.includes(UserPermissionType.CountriesDictionaryView) ||
    permissions.includes(UserPermissionType.CitiesDictionaryView) ||
    permissions.includes(UserPermissionType.CouriersDictionaryView) ||
    permissions.includes(UserPermissionType.DistrictsDictionaryView) ||
    permissions.includes(UserPermissionType.StatusesDictionaryView) ||
    permissions.includes(UserPermissionType.SubdistrictsDictionaryView) ||
    permissions.includes(UserPermissionType.LocationsDictionaryView) ||
    permissions.includes(UserPermissionType.PostCodesDictionaryView) ||
    permissions.includes(UserPermissionType.ProvincesDictionaryView) ||
    permissions.includes(UserPermissionType.PaymentMethodsDictionaryView) ||
    permissions.includes(UserPermissionType.ProductCategoriesDictionaryView) ||
    permissions.includes(UserPermissionType.SalesChannelsDictionaryView) ||
    permissions.includes(UserPermissionType.SystemVariablesDictionaryView) ||
    permissions.includes(
      UserPermissionType.CouriersApiMappingDictionaryView
    )) &&
    menuItems.push(
      generateSubMenu(
        resources.subNavigationItem.dictionaries,
        dictionariesSubmenu(permissions),
        { source: "read" }
      )
    );
  (hasPermission(UserPermissionType.SalesP2View) ||
    hasPermission(UserPermissionType.SalesResaleView) ||
    hasPermission(UserPermissionType.SalesWinbackView)) &&
    menuItems.push(
      generateSubMenu(resources.subNavigationItem.sales, salesSubmenu(), {
        source: "fire",
      })
    );
  // permissions.includes(UserPermissionType.ContactView) &&
  //     menuItems.push(
  //         generateMenuItem(navPath.contact, resources.navigationItems.contact, navPath.contact, { type: 'ant', source: 'mail' })
  //     );
  permissions.includes(UserPermissionType.CourierStatusMapping) &&
    menuItems.push(
      generateMenuItem(
        navPath.courierStatusMapping,
        resources.navigationItems.couriersStatusMapping,
        navPath.courierStatusMapping,
        { type: "ant", source: "gold" }
      )
    );

  return menuItems;
};

// ========
// submenus
// ========
const administrationSubmenu = (permissions: number[]): any => {
  let administrationSubmenu: any = [];

  permissions.includes(UserPermissionType.UsersView) &&
    administrationSubmenu.push(
      generateMenuItem(
        navPath.administrationUsers,
        resources.subNavigationItem.appUsers,
        navPath.administrationUsers,
        { type: "ant", source: "database" }
      )
    );
  permissions.includes(UserPermissionType.UsersRoleView) &&
    administrationSubmenu.push(
      generateMenuItem(
        navPath.administrationRole,
        resources.subNavigationItem.appUsersRole,
        navPath.administrationRole,
        { type: "ant", source: "global" }
      )
    );
  permissions.includes(UserPermissionType.WebSocketView) &&
    administrationSubmenu.push(
      generateMenuItem(
        navPath.administrationWebSocket,
        resources.subNavigationItem.webSocket,
        navPath.administrationWebSocket,
        { type: "ant", source: "api" }
      )
    );
  permissions.includes(UserPermissionType.K8ServicesView) &&
    administrationSubmenu.push(
      generateMenuItem(
        navPath.k8_services,
        resources.subNavigationItem.k8_services,
        navPath.k8_services,
        { type: "ant", source: "cluster" }
      )
    );

  permissions.includes(UserPermissionType.SettingsView) &&
    administrationSubmenu.push(
      generateMenuItem(
        navPath.settings,
        resources.subNavigationItem.setting,
        navPath.settings,
        { type: "ant", source: "setting" }
      )
    );

  return administrationSubmenu;
};

const salesSubmenu = () => {
  let salesSubmenu: any = [];

  hasPermission(UserPermissionType.UsersView) &&
    salesSubmenu.push(
      generateMenuItem(
        navPath.salesRemarketing,
        resources.subNavigationItem.appRemarketing,
        navPath.salesRemarketing,
        { type: "ant", source: "setting" }
      )
    );

  return salesSubmenu;
};

const callcenterSubmenu = (permissions: number[]): any => {
  let callcenterSubmenu: any = [];

  permissions.includes(UserPermissionType.LeadsRedirectionView) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.cc_leads_direction,
        resources.subNavigationItem.cc_leads_direction,
        navPath.cc_leads_direction,
        { type: "ant", source: "swap" }
      )
    );
  permissions.includes(UserPermissionType.InstancesView) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.cc_instances,
        resources.subNavigationItem.cc_instances,
        navPath.cc_instances,
        { type: "ant", source: "database" }
      )
    );
  permissions.includes(UserPermissionType.CallCenterCampaignsView) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.cc_campaigns,
        resources.subNavigationItem.cc_campaigns,
        navPath.cc_campaigns,
        { type: "ant", source: "global" }
      )
    );
  permissions.includes(UserPermissionType.ClassifiersVies) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.cc_classifiers,
        resources.subNavigationItem.cc_classifiers,
        navPath.cc_classifiers,
        { type: "ant", source: "tag" }
      )
    );
  permissions.includes(UserPermissionType.MappingClassifiersView) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.li_classifiers_cc,
        resources.subNavigationItem.li_classifiers_cc,
        navPath.li_classifiers_cc,
        { type: "ant", source: "tags" }
      )
    );
  permissions.includes(UserPermissionType.SystemClassifiersView) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.system_classifiers,
        resources.subNavigationItem.system_classifiers,
        navPath.system_classifiers,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.WebhooksView) &&
    callcenterSubmenu.push(
      generateMenuItem(
        navPath.cc_webhooks,
        resources.subNavigationItem.cc_webhooks,
        navPath.cc_webhooks,
        { type: "ant", source: "tags" }
      )
    );

  return callcenterSubmenu;
};

const dictionariesSubmenu = (permissions: number[]) => {
  let dictionariesSubmenu: any = [];

  permissions.includes(UserPermissionType.CountriesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.countries_dictionary,
        resources.subNavigationItem.countries,
        navPath.countries_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.CitiesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.cities_dictionary,
        resources.subNavigationItem.cities,
        navPath.cities_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.CouriersDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.couriers_dictionary,
        resources.subNavigationItem.couriers,
        navPath.couriers_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.DistrictsDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.districts_dictionary,
        resources.subNavigationItem.districts,
        navPath.districts_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.StatusesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.statuses_dictionary,
        resources.subNavigationItem.statuses,
        navPath.statuses_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.LocationsDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.locations_dictionary,
        resources.subNavigationItem.locations,
        navPath.locations_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.PostCodesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.posto_codes_dictionary,
        resources.subNavigationItem.postCodes,
        navPath.posto_codes_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.PaymentMethodsDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.payment_methods_dictionary,
        resources.subNavigationItem.paymentMethods,
        navPath.payment_methods_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.ProductCategoriesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.products_categories_dictionary,
        resources.subNavigationItem.productCategories,
        navPath.products_categories_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.SalesChannelsDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.sales_channels_dictionary,
        resources.subNavigationItem.salesChannels,
        navPath.sales_channels_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.SubdistrictsDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.subdistricts_dictionary,
        resources.subNavigationItem.subdistricts,
        navPath.subdistricts_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.ProvincesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.provinces_dictionary,
        resources.subNavigationItem.provinces,
        navPath.provinces_dictionary,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.SystemVariablesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.system_variables,
        resources.subNavigationItem.system_variables,
        navPath.system_variables,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.TrafficSourcesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.traffic_sources,
        resources.subNavigationItem.traffic_sources,
        navPath.traffic_sources,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.CouriersApiMappingDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.couriers_api_mapping,
        resources.subNavigationItem.couriers_api_mapping,
        navPath.couriers_api_mapping,
        { type: "ant", source: "experiment" }
      )
    );
  permissions.includes(UserPermissionType.CouriersStatusesDictionaryView) &&
    dictionariesSubmenu.push(
      generateMenuItem(
        navPath.couriers_statuses,
        resources.subNavigationItem.couriers_statuses,
        navPath.couriers_statuses,
        { type: "ant", source: "experiment" }
      )
    );

  return dictionariesSubmenu;
};
