import * as React from 'react';
import styles from './styles/SurveyForm.module.scss';
import { IFormAnswer } from './interfaces/IFormAnswer';
import { IQuestion } from './interfaces/IQuestion';
import { ISurveyFormProps } from './interfaces/ISurveyFormProps';
import { openAlertNotification } from '../../../../../helpers/NotificationHelper';
import { postSurveys } from '../../../../../services/m29_users_management/SurveysService';
import { resources } from '../../../../../common/Resources';
import { SurveyQuestion } from '../components/surveyQuestion/SurveyQuestion';

class Question {
    id: number;
    is_visible: number;
    question_ru: string;
    question_en: string;
    options_ru?: string;
    options_en?: string;
    question_type: string;
    answer?: string;
    isAnswered?: boolean;
    validAnswerLength?: boolean;

    constructor(id: number, question_type: string, is_visible: number, question_ru: string, question_en: string, options_ru?: string, options_en?: string, answer?: string, isAnswered?: boolean, validAnswerLength?: boolean) {
        this.id = id;
        this.is_visible = is_visible;
        this.question_ru = question_ru;
        this.question_en = question_en;
        this.options_en = options_en;
        this.options_ru = options_ru;
        this.question_type = question_type;
        this.answer = answer;
        this.isAnswered = isAnswered;
        this.validAnswerLength = validAnswerLength;
    }

    getQuestion = () => {
        return {
            id: this.id,
            is_visible: this.is_visible,
            question_ru: this.question_ru,
            question_en: this.question_en,
            options_ru: this.options_ru,
            options_en: this.options_en,
            question_type: this.question_type,
            isAnswered: this.isAnswered,
            validAnswerLength: this.validAnswerLength,
        }
    }

    saveAnswer = (input: string) => {
        this.answer = input;
    }

    getQuestionAndAnswer = () => {
        return {
            id: this.id,
            is_visible: this.is_visible,
            question_ru: this.question_ru,
            question_en: this.question_en,
            options_ru: this.options_ru,
            options_en: this.options_en,
            question_type: this.question_type,
            answer: this.answer,
            isAnswered: this.isAnswered,
            validAnswerLength: this.validAnswerLength,
        }
    }

    hasAnswer = (): boolean => {
        const result = this.answer ? true : false;
        this.isAnswered = result;
        return result;
    }

    validateAnswerLength = (): boolean => {
        if (this.isAnswered) {
            const result = this.answer!.length > 3 ? true : false;
            this.validAnswerLength = result;
            return result;
        } else {
            this.validAnswerLength = false;
            return false;
        }
    }
}

export class SurveyForm extends React.Component<ISurveyFormProps> {
    state = {
        locale: 'en_GB',
        questionsFromProps: Array<IQuestion>()
    }

    componentDidMount() {
        this.setLang();
        this.createQuestionsFromProps();
    }

    setLang = (): void => {
        this.setState({ locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en_GB' });
    }

    createQuestionsFromProps = (): void => {
        let questions: any = [];
        this.props.questions.map((q) => {
            return questions.push(new Question(q.id, q.question_type, q.is_visible, q.question_ru, q.question_en, q.options_ru, q.options_en));
        })
        this.setState({ questionsFromProps: questions });
    }

    handleChangeInput = (e: any): void => {
        const editedQuestion = this.findQuestionBasedOnId(e.target.name);
        editedQuestion!.saveAnswer(e.target.value);
        // TODO usunąć zakomentowany kod
        // this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeSelect = (e: any, v: any): void => {
        const editedQuestion = this.findQuestionBasedOnId(v.props.title);
        editedQuestion!.saveAnswer(e);
        // this.setState({ [v.props.title]: e });
    }

    findQuestionBasedOnId = (id: number): IQuestion => {
        const r = this.state.questionsFromProps.find((el: any) => {
            return el.getQuestion().id === Number(id);
        })
        return r!;
    }

    generateFormObject = (): any => {
        let form: IFormAnswer[] = [];
        this.state.questionsFromProps.forEach((q: IQuestion) => {
            const result = {
                question_id: q.id,
                question: [q.question_en, q.question_ru],
                answer: q.answer
            }

            form.push(result);
        });
        return form;
    }

    validate = (): boolean[] => {
        let results: boolean[] = [];
        this.state.questionsFromProps.forEach((q: IQuestion) => {
            if (q.hasAnswer()) {
                results.push(true);
            } else {
                results.push(false);
            }
            this.forceUpdate();
        });

        return results;
    }

    handleSubmit = (e: any): void => {
        e.preventDefault();

        const answers = this.generateFormObject();

        if (this.validate().indexOf(false) === -1) {
            axiosSurvey(this.props.id!, answers, this.props.nextStep);
        }
    }

    render() {
        let questions = this.state.questionsFromProps;

        return (
            <section className={`customAnt--surveyForm ${styles.grid}`}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    {questions.map((question: IQuestion) => {
                        question = question.getQuestionAndAnswer();
                        return <div key={question.id} className={styles.formItem}>
                            <SurveyQuestion
                                {...question}
                                id={question.id}
                                question_type={question.question_type}
                                question={
                                    this.state.locale === 'en_GB' ?
                                        question.question_en
                                        :
                                        question.question_ru
                                }
                                options={
                                    question.options_en ?
                                        (
                                            this.state.locale === 'en_GB' ?
                                                question.options_en
                                                :
                                                question.options_ru
                                        )
                                        :
                                        []
                                }
                                handleChangeInput={(e) => this.handleChangeInput(e)}
                                handleChangeSelect={(e, v) => { this.handleChangeSelect(e, v) }}
                            />
                        </div>
                    })}
                    <button
                        className={styles.submit}
                        type="submit"
                    >
                        {resources.registrationPage.submitMyApplication}
                    </button>
                </form>
            </section>
        );
    }
}

// send answers to users_survey table, after positive response call surveyFinished function
const axiosSurvey = async (id: number, data: string, nextStep: () => void) => {
    await postSurveys(data, id)
        .then(response => {
            if (response) {
                surveyFinished(nextStep);
            }
            else {
                openAlertNotification(resources.statement.databaseConnectionError);
            }
        });
}

// update user table - survey_finished column
const surveyFinished = (nextStep: () => void) => {
    nextStep();
}
