import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { FinanceContent } from './contents/FinanceContent';
import { IAppState } from '../../store';
import { IAppUserInfoModel } from '../../models/IAppUserInfoModel';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { Result, Spin } from 'antd';
import { setActiveMenuItem } from '../../reducers/CommonReducer';
import { resources } from '../../common/Resources';

class FinanceViewInner extends React.Component<ISetActiveMenuItem & IAppUserInfoModel> {
    componentDidMount = () =>
        this.props.setActiveMenuItem(navPath.finance);

    render() {
        if (!this.props.id_users_role) {
            return <div style={{display: 'flex', justifyContent: 'center', marginTop: '20vh'}}>
                <Spin size={'large'}/>
            </div>
        }
        if (!this.props.id_company) {
            return <Result
                title={resources.statement.noCompanyIdAssigned}
            />
        }
        return (
            <FinanceContent />
        )
    }
}

const mapStateToProps = (state: IAppState): IAppUserInfoModel => {
    return {
        ...state.commonState.appUserInfo
    }
}
const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) =>
        dispatch(setActiveMenuItem(view))
})

export const FinanceView: React.ComponentClass = connect(mapStateToProps, mapDispatchToProps)(FinanceViewInner);