import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import {
    changeStatusLeadsRedirection,
    deleteLeadsRedirection,
    getLeadsRedirectionCampaignsD,
    getLeadsRedirectionCountriesD,
    getLeadsRedirectionList,
    getLeadsRedirectionPartnersD,
    getLeadsRedirectionProductsD
    } from '../../../../services/leadsRedirection/LeadsRedirectionService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { ILeadsRedirection } from '../interfaces/ILeadsRedirection';
import { LeadsRedirectionForm } from '../forms/LeadsRedirectionForm';
import { LeadsRedirectionTable } from '../tables/LeadsRedirectionTable';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class LeadsRedirectionContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        leadsRedirectionsList: Array<ILeadsRedirection>(),
        productsD: Array<IDictionaryValue>(),
        partnersD: Array<IDictionaryValue>(),
        countriesD: Array<IDictionaryValue>(),
        campaignsD: Array<IDictionaryValue>(),
        selected_country: undefined,
        selected_partner: undefined,
        selected_campaign: undefined,
        selected_product: undefined
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getLeadsDictionaryList();
        this.getProductsD();
        this.getPartnersD();
        this.getCountriesD();
        this.getCampaignsD();
    }

    componentWillUnmount = (): void => {
        this._isMounted = false;
    }

    public render() {
        const {
            leadsRedirectionsList,
            productsD,
            partnersD,
            countriesD,
            campaignsD,
            selected_country,
            selected_partner,
            selected_campaign,
            selected_product,
            loading
        } = this.state;

        let data = leadsRedirectionsList;

        if (data.length > 0) {
            if (selected_country)
                data = data.filter(x => x.country_id === selected_country);
            if (selected_partner)
                data = data.filter(x => x.partner_id === selected_partner);
            if (selected_campaign)
                data = data.filter(x => x.campaign_id === selected_campaign);
            if (selected_product)
                data = data.filter(x => x.product_id === selected_product);
        }

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.leads_directions} />
                <Left>
                    <RefreshTableButton
                        className="marginTopRefreshButton marginRigth"
                        loading={loading}
                        onClick={this.getLeadsDictionaryList}
                    />
                </Left>
                <Left>
                    <FieldInfo>{resources.labels.country}</FieldInfo>
                    <SelectOnChange
                        className="marginRigth"
                        onChange={this.onChangeSelect}
                        name='selected_country'
                        value={selected_country}
                        placeholder={resources.select.select}
                        items={countriesD}
                        disabled={loading}
                        loading={loading}
                        width={200}
                        labelClassName='marginLeft marginBottom'
                        allowClear
                    />
                </Left>
                <Left>
                    <FieldInfo>{resources.labels.partner}</FieldInfo>
                    <SelectOnChange
                        className="marginRigth"
                        onChange={this.onChangeSelect}
                        name='selected_partner'
                        value={selected_partner}
                        placeholder={resources.select.select}
                        items={partnersD}
                        disabled={loading}
                        loading={loading}
                        width={200}
                        labelClassName='marginLeft marginBottom'
                        allowClear
                    />
                </Left>
                <Left>
                    <FieldInfo>{resources.labels.campaigns}</FieldInfo>
                    <SelectOnChange
                        className="marginRigth"
                        onChange={this.onChangeSelect}
                        name='selected_campaign'
                        value={selected_campaign}
                        placeholder={resources.select.select}
                        items={campaignsD}
                        disabled={loading}
                        loading={loading}
                        width={200}
                        labelClassName='marginLeft marginBottom'
                        allowClear
                    />
                </Left>
                <FieldInfo>{resources.labels.product}</FieldInfo>
                <SelectOnChange
                    className="marginRigth"
                    onChange={this.onChangeSelect}
                    name='selected_product'
                    value={selected_product}
                    placeholder={resources.select.select}
                    items={productsD}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    labelClassName='marginLeft marginBottom'
                    allowClear
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.AddNewLeadsRedirectionModalButton}>
                        <InformationModalDialog
                            buttonClassName='marginBottom'
                            modalTitle={resources.title.addNewLeadsRedirection}
                            icon={"plus"}
                            buttonTitle={resources.modalButtonsTitle.addNewLeadsRedirection}
                            disabled={loading}
                            withHideMethod={true}
                            buttonType='primary'
                        >
                            <LeadsRedirectionForm
                                partnersD={partnersD}
                                countriesD={countriesD}
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <LeadsRedirectionTable
                    loading={loading}
                    data={data}
                    partnersD={partnersD}
                    productsD={productsD}
                    countriesD={countriesD}
                    campaignsD={campaignsD}
                    updateObjInArray={this.updateObjInArray}
                    deleteLeadsDictionary={this.deleteLeadsDictionary}
                    changeStatus={this.changeStatus}
                />
            </TableContainer>
        )
    };

    getLeadsDictionaryList = async (): Promise<void> => {
        this.setState({ loading: true });

        await getLeadsRedirectionList()
            .then(
                response => {
                    const list: ILeadsRedirection[] = response;

                    const leadsRedirectionsList = list.map((item) => {
                        return item = { key: item.id, ...item }
                    });

                    this._isMounted && this.setState({ leadsRedirectionsList });
                })
            .finally(() => { this._isMounted && this.setState({ loading: false }) })
    }

    getProductsD = async () => {
        await getLeadsRedirectionProductsD()
            .then(response => { this._isMounted && this.setState({ productsD: response }); });
    }

    getPartnersD = async () => {
        await getLeadsRedirectionPartnersD()
            .then(response => { this._isMounted && this.setState({ partnersD: response }); });
    }

    getCountriesD = async () => {
        await getLeadsRedirectionCountriesD()
            .then(response => { this._isMounted && this.setState({ countriesD: response }); });
    }

    getCampaignsD = async () => {
        await getLeadsRedirectionCampaignsD()
            .then(response => { this._isMounted && this.setState({ campaignsD: response }); });
    }

    addObjToArray = (leadsDictionary: any): void => {
        this.setState({ leadsRedirectionsList: [...this.state.leadsRedirectionsList, leadsDictionary] });
    }

    updateObjInArray = (leadsDictionary: ILeadsRedirection): void => {
        openSuccessNotification("updateObjInArray");

        const { leadsRedirectionsList } = this.state;

        const index = leadsRedirectionsList.findIndex(x => x.id === Number(leadsDictionary.id));

        leadsRedirectionsList[index].partner_id = leadsDictionary.partner_id;
        leadsRedirectionsList[index].country_id = leadsDictionary.country_id;
        leadsRedirectionsList[index].product_id = leadsDictionary.product_id;
        leadsRedirectionsList[index].campaign_id = leadsDictionary.campaign_id;

        this.setState({ leadsRedirectionsList });
    }

    deleteLeadsDictionary = async (event: any) => {
        const id = Number(event.target.value);

        await deleteLeadsRedirection(id)
            .then((response) => {
                if (response) {
                    this.changeStatusInArray(id);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatus = async (event: any) => {
        const id = Number(event.target.value);

        await changeStatusLeadsRedirection(id)
            .then((response) => {
                if (response) {
                    this.changeStatusInArray(id);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        const { leadsRedirectionsList } = this.state;

        const index = leadsRedirectionsList.findIndex(x => x.id === id);

        leadsRedirectionsList[index].is_active = !(leadsRedirectionsList[index].is_active);

        this.setState({ leadsRedirectionsList });

        this.getLeadsDictionaryList().then(
            () => openSuccessNotification("Status changed!")
        );
    }

    onChangeSelect = (name: string, value: number): void => {
        this.setState({ [name]: value });
    }
} 