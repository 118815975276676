import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { resourceDef } from '../../common/ResourceDef';

const listUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionList);
const countriesUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionCountries);
const partnersUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionPartners);
const productsUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionProducts);
const productsUrlWithId = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionProductsWithId);
const campaignsUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionCampaigns);
const campaignsUrlWithId = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionCampaignsWithId);
const updateUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionUpdate);
const insertUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionInsert);
const deleteUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionDelete);
const changeStatusUrl = createGatewayAbsoluteUrl(resourceDef.LeadsRedirectionChangeStatus);

export const getLeadsRedirectionCountriesD = () => axios.post(countriesUrl)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const getLeadsRedirectionPartnersD = () => axios.post(partnersUrl)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const getLeadsRedirectionProductsD = () => axios.post(productsUrl)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const getLeadsRedirectionProductsDById = (id: number) => axios.post(productsUrlWithId + id)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const getLeadsRedirectionCampaignsD = () => axios.post(campaignsUrl)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const getLeadsRedirectionCampaignsDById = (id: number) => axios.post(campaignsUrlWithId + id)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const getLeadsRedirectionList = () => axios.post(listUrl)
    .then(response => {
        return response.data.data;
    })
    .catch(() => { return []; });

export const updateLeadsRedirection = (value: any) => axios.post(updateUrl, { value })
    .then(() => {
        return true;
    })
    .catch(() => { return false });

export const insertLeadsRedirection = (value: any) => axios.post(insertUrl, { value })
    .then(response => {
        return response.data;
    })
    .catch(() => {
        return [];
    });

export const deleteLeadsRedirection = (id: number) => axios.post(deleteUrl + id)
    .then(() => { return true; })
    .catch(() => { return false; });

export const changeStatusLeadsRedirection = (id: number) => axios.post(changeStatusUrl + id)
    .then(() => { return true; })
    .catch(() => { return false; });