import MainInformationsForm from "../forms/MainInformationsForm";
import React from "react";
import { IMainInformationsForm } from "../interfaces/IMainInformationsForm";
import { IMainInformationsFormMethods } from "../interfaces/IMainInformationsFormMethods";
import { useQuery } from "react-query";
import { getListFromLibrary } from "../../../../../../services/m40_libraries/LibrariesService";

export const MainInformationsContent = (
  props: IMainInformationsForm & IMainInformationsFormMethods
) => {
  const { data, isLoading } = useQuery("payouts-proxy-future", () =>
    getListFromLibrary({ name: "remarketing_campaigns", column: "id, name" })
  );
  return (
    <MainInformationsForm
      {...props}
      isLoadingCampaigns={isLoading}
      remarketingCampaigns={data}
    />
  );
};
