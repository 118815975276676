import DictionaryF from '../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/AccountManagerForm.module.scss';
import TextAreaF from '../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createUser, updateUser } from '../../../services/m29_users_management/GeneralUserService';
import { emailIsValid } from '../../../helpers/ValidationHelpers';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IAccountManagerFormProps } from './interfaces/IAccountManagerFormProps';
import { IAccountManagerFormValues } from './interfaces/IAccountManagerFormValues';
import { IAddObjToArray } from '../../../common/interfaces/IAddObjToArray';
import { ICountries } from './interfaces/ICountries';
import { IDefaultProps } from './interfaces/IDefaultProps';
import { IHideModal } from '../../../common/interfaces/IHideModal';
import { IId } from '../../../common/interfaces/IId';
import { IUpdateObjInArray } from '../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../helpers/NotificationHelper';
import { resources } from '../../../common/Resources';

class AccountManagerFormInner extends React.Component<IAccountManagerFormProps & IDefaultProps & IHideModal & IId & IAddObjToArray & IUpdateObjInArray & ICountries & FormikProps<IAccountManagerFormValues>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        labelTextAlignLeft: true,
        boldLabel: true,
        allowClear: true
    }

    public render() {
        return (
            <Form>
                <div className={styles.grid_col_2}>
                    <div>
                        <TextInputF
                            {...this.props}
                            name="email"
                            label={resources.labels.email}
                            required
                        />
                    </div>
                    <div>
                        <TextInputF
                            {...this.props}
                            name="name"
                            label={resources.labels.name}
                            required
                        />
                    </div>
                    <div>
                        <TextInputF
                            {...this.props}
                            type='password'
                            name='password'
                            label={resources.labels.password}
                            required={!this.props.id}
                        />
                    </div>
                    <div>
                        <TextInputF
                            {...this.props}
                            name="second_name"
                            label={resources.labels.second_name}
                        />
                    </div>
                    <div>
                        <TextInputF
                            {...this.props}
                            name="short_name"
                            label={resources.labels.short_name}
                        />
                    </div>
                    <div>
                        <DictionaryF
                            {...this.props}
                            name="id_country"
                            label={resources.labels.country}
                            dictionaryValues={this.props.countries}
                        />
                    </div>
                </div>
                <TextAreaF
                    {...this.props}
                    name='description'
                    label={resources.labels.description}
                    textAreaRows={5}
                />
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form>
        );
    };
}

const AccountManagerForm = withFormik<IAccountManagerFormProps & IDefaultProps & IAddObjToArray & IUpdateObjInArray & ICountries, IAccountManagerFormValues>({
    mapPropsToValues: (props: IAccountManagerFormProps) => {
        return {
            email: props.email,
            name: props.name,
            password: undefined,
            second_name: props.second_name,
            short_name: props.short_name,
            id_country: props.id_country,
            description: props.description,
            id: props.id
        };
    },

    validate: (values: IAccountManagerFormValues) => {
        const errors: FormikErrors<IAccountManagerFormValues> = {};
        const reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
        const specChar = new RegExp('[!@#$%^&*]', 'g');
        const specMatches = values.password!.match(specChar);

        if (!values.name) {
            errors.name = resources.validation.required;
        }
        if (!values.email) {
            errors.email = resources.validation.emailIsRequired;
        }
        else if (values.email && !emailIsValid(values.email)) {
            errors.email = resources.validation.incorrectEmailAdres;
        }
        if (values.email && values.email.length > 255) {
            errors.email = resources.validation.requiredMax255Characters;
        }
        if (values.password && values.password.length > 255) {
            errors.password = resources.validation.requiredMax255Characters;
        }
        if (values.password && (!reg.test(values.password!) || !Array.isArray(specMatches) || specMatches.length < 2)) {
            errors.password = resources.validation.passwordCondition;
        }

        return errors;
    },

    handleSubmit: (values: IAccountManagerFormValues, bag: FormikBag<IAccountManagerFormProps & IAddObjToArray & IUpdateObjInArray & IHideModal & IId & ICountries, IAccountManagerFormValues>) => {
        const object: IAccountManagerFormValues = {
            email: values.email,
            name: values.name,
            second_name: values.second_name,
            short_name: values.short_name,
            id_country: values.id_country,
            description: values.description,
            application_access: values.application_access,
            password: values.password,
            id: values.id
        }

        // Sprawdzenie, czy te same vartości
        if (
            values.email === bag.props.email
            && values.name === bag.props.name
            && values.second_name === bag.props.second_name
            && values.short_name === bag.props.short_name
            && values.id_country === bag.props.id_country
            && values.password === bag.props.password
            && values.description === bag.props.description
        )
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else
            action(object, bag.props);
    }

})(AccountManagerFormInner);

export default AccountManagerForm;

const action = async (obj: IAccountManagerFormValues, bag: IHideModal & IId & IAddObjToArray & IUpdateObjInArray) => {
    if (bag.id) {
        const updateUserObject = {
            email: obj.email,
            name: obj.name,
            password: obj.password,
            second_name: obj.second_name ? obj.second_name : null,
            short_name: obj.short_name ? obj.short_name : null,
            id_country: obj.id_country ? obj.id_country : null,
            description: obj.description ? obj.description : null
        };

        await updateUser(Number(bag.id), updateUserObject)
            .then((response) => {
                if (response) {
                    const obj = { ...updateUserObject, id: bag.id };

                    bag.updateObjInArray!(obj);

                    openSuccessNotification(resources.statement.employee_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError)
                }

                bag.hideModal!();
            });
    }
    else {
        const updateUserObject = {
            email: obj.email,
            name: obj.name,
            password: obj.password,
            second_name: obj.second_name ? obj.second_name : null,
            short_name: obj.short_name ? obj.short_name : null,
            id_country: obj.id_country ? obj.id_country : null,
            description: obj.description ? obj.description : null,
            id_users_role: 7
        };

        const createUserObject = {
            name: obj.name,
            email: obj.email,
            password: obj.password
        }

        await createUser(createUserObject)
            .then(response => {
                if (response) {
                    const id = response.data.id;

                    if (id) {
                        updateUser(id, updateUserObject)
                            .then(response => {
                                if (response) {
                                    const obj = { ...updateUserObject, id: id, key: id };

                                    bag.addObjToArray!(obj);

                                    openSuccessNotification(resources.statement.created);
                                }
                            });
                    }

                    bag.hideModal!();
                }
            });
    }
}