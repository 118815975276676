import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button, Tooltip } from 'antd';
import { displayIconStatusWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import { resources } from '../../../../common/Resources';
import { convertBooleanYesOrNoReactElement } from '../../../../helpers/BooleanHelper';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import CountriesDictionaryForm from '../forms/CountriesDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { getOperativeFilters, getActivityFilters } from '../../../../common/dictionaries/Filters';

const { Column } = Table;

export class CountriesDictionaryTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='70px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                width='250px'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.is_active}
                key='is_active'
                align='center'
                width='100px'
                sorter={(a, b) => a.is_active - b.is_active}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_active)
                )}
            />,
            <Column
                title={resources.columnTitle.is_operative}
                key='is_operative'
                align='center'
                width='120px'
                sorter={(a, b) => a.is_operative - b.is_operative}
                filters={getOperativeFilters()}
                onFilter={(value, record) => record.is_operative === value}
                render={(record) => (
                    convertBooleanYesOrNoReactElement(record.is_operative)
                )}
            />,
            <Column
                title={resources.columnTitle.company}
                dataIndex='company_name'
                key='company_name'
                align='center'
                width='300px'
                sorter={(a, b) => (a.company_name ? a.company_name : "").localeCompare(b.company_name ? b.company_name : "")}
                {...this.getColumnSearchProps('company_name')}
            />,
            <Column
                title={resources.columnTitle.address}
                dataIndex='address'
                key='address'
                align='center'
                width='400px'
                sorter={(a, b) => (a.address ? a.address : "").localeCompare(b.address ? b.address : "")}
                {...this.getColumnSearchProps('address')}
            />,
            <Column
                title={resources.columnTitle.email}
                key='email'
                align='center'
                width='200px'
                sorter={(a, b) => (a.email ? a.email : "").localeCompare(b.email ? b.email : "")}
                {...this.getColumnSearchProps('email')}
                render={(record) => (
                    <Tooltip title={record.email}>
                        {record.email !== null && record.email.length > 24 ? record.email.substring(0, 20) + '...' : record.email}
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.phone}
                dataIndex='phone'
                key='phone'
                width='140px'
                align='center'
                sorter={(a, b) => a.phone - b.phone}
                {...this.getColumnSearchProps('phone')}
            />,
            <Column
                title={resources.columnTitle.code}
                dataIndex='code'
                key='code'
                align='center'
                width='90px'
                sorter={(a, b) => (a.code ? a.code : "").localeCompare(b.code ? b.code : "")}
                {...this.getColumnSearchProps('code')}
            />,
            <Column
                title={resources.columnTitle.currency}
                dataIndex='currency'
                key='currency'
                align='center'
                width='120px'
                sorter={(a, b) => (a.currency ? a.currency : "").localeCompare(b.currency ? b.currency : "")}
                {...this.getColumnSearchProps('currency')}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='110px'
                align='center'
                fixed= 'right'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditCountryModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editCountry}
                                buttonTooltip={resources.modalTooltips.editCountry}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={800}
                            >
                                <CountriesDictionaryForm
                                    id={record.id}
                                    name={record.name}
                                    currency={record.currency}
                                    code={record.code}
                                    company_name={record.company_name}
                                    address={record.address}
                                    email={record.email}
                                    phone={record.phone}
                                    is_active={record.is_active}
                                    is_operative={record.is_operative}
                                    updateObjInArray={this.props.updateObjInArray}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.ChangeCountryActiveStatusButton}>
                            <Tooltip placement="rightTop" title={resources.title.partnerActivityStatus}>
                                <Button
                                    value={record.id}
                                    onClick={this.props.changeStatus}
                                    icon={record.is_active ? 'close' : 'check'}
                                    style={{ color: record.is_active ? 'red' : 'green' }}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.DeleteCountryButton}>
                            <Button
                                value={record.id}
                                onClick={this.props.deleteCountry}
                                icon="delete"
                            />
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='CountriesDictionaryTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }
}