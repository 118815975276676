import * as Yup from 'yup';
import indValidation from './addressInfoInd/validation';

const validation = {
    address: Yup.string()
        .required('Required'),
};

const getAddressValidation = (countryId: number) => {
    let countryValidation;
    // FIXME
    // kind of hacky, switch with countries ID from DB
    switch (countryId) {
        case 1: // Indonesia
            countryValidation = indValidation;
            break;
        default:
            countryValidation = {};
    }
    return {
        ...validation,
        ...countryValidation,
    }
}

export default getAddressValidation;