import { useEffect } from "react";
import { useQuery } from "react-query";

import { useFilters } from "../filters-state";
import { getTablesSettings } from "../../../../services/TablesSettingsService";
import { getLeadsStatistics } from "../../../../services/m55_leads_statistics/LeadsStatisticsService";
import { getTableColumnsKeys } from "../table-data/columns";
import { createApiFiltersData } from "../filters-data";
import { SET_LOADING, UPDATE_TABLE_DATA, useTable } from "../table-state";

export const useStatisticQuery = (findBy: string) => {
  const { state } = useFilters();
  const { dispatch } = useTable();
  const filterApiData = createApiFiltersData({ ...state, findBy });
  const tableData = useQuery("table-settings", getTablesSettings, {
    onSuccess: (data) => {
      // By default we have to use FE columns in specific order
      const columnOrder =
        data.columnOrder.length > 0 ? data.columnOrder : getTableColumnsKeys();
      dispatch({
        type: UPDATE_TABLE_DATA,
        payload: {
          tableSettings: {
            ...data,
            columnOrder,
          },
        } as any,
      });
    },
  });
  const statisticData = useQuery(
    [
      "statistics",
      state.groupBy,
      state.startDate,
      state.endDate,
      state.timezone,
      findBy,
    ],
    () => getLeadsStatistics(filterApiData),
    {
      onSuccess: (data) =>
        dispatch({ type: UPDATE_TABLE_DATA, payload: { statisticData: data } }),
    }
  );

  useEffect(() => {
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoadingData: statisticData.isFetching || tableData.isFetching,
      },
    });
  }, [dispatch, tableData.isFetching, statisticData.isFetching]);
};
