import * as React from "react";
import arrow from "../../../common/images/arrow-down.svg";
import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import Logout from "../components/logout/Logout";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import styles from "./styles/HeaderContent.module.scss";
import SwitchUser from "../components/switchUser/SwitchUser";
import {
  checkDictionaries,
  checkDictionariesInterval,
} from "../../../helpers/DisctionariesHelper";
import { connect, MapDispatchToProps } from "react-redux";
import { createAbsoluteUrl } from "../../../helpers/UrlCreator";
import { Dispatch } from "redux";
import { getTotalBalance } from "../../../services/finance/FinanceService";
import { getUserInf } from "../../../services/m29_users_management/GeneralUserService";
import { HeaderLangPicker } from "../components/headerLangPicker/HeaderLangPicker";
import { history } from "../../../helpers/History";
import { IAppState } from "../../../store";
import {
  IAppUserInfoModel,
  UserPermissionType,
} from "../../../models/IAppUserInfoModel";
import { IChangeLang } from "../../../models/IChangeLang";
import { Icon, Layout, Popover } from "antd";
import { IFormHeaderContent } from "./interfaces/IFormHeaderContent";
import { ILogoutAppUser } from "../../../common/interfaces/ILogoutAppUser";
import { ISetAppUserInfo } from "../../../common/interfaces/ISetAppUserInfo";
import { ISetBlockUi } from "../../../common/interfaces/ISetBlockUi";
import { Link } from "react-router-dom";
import {
  logoutAppUser,
  setAppUserInfo,
  setBlockUi,
} from "../../../reducers/CommonReducer";
import { PartnerHoverPopUpItem } from "../components/partnerHoverPopUpItem/PartnerHoverPopUpItem";
import { refreshTokenMethod, setHeader } from "../../../helpers/Authentication";
import { resources } from "../../../common/Resources";
import { SendFeedback } from "../components/sendFeedback/SendFeedback";
import { ToLongStringToDots } from "../../../components/toLongStringToDots/ToLongStringToDots";
import { YourPartner } from "../components/yourPartner/YourPartner";
import "../styles/HeaderStyle.css";
import { USER_ROLES } from "../../../common/enums/UserRoles";
import bannerImg from "../../../common/images/banner.png";

const { Header } = Layout;

class FormHeaderContent extends React.Component<
  IFormHeaderContent &
    IChangeLang &
    ISetAppUserInfo &
    ISetBlockUi &
    ILogoutAppUser
> {
  state = {
    current: "",
    rotate: 0,
    intervalDictionary: undefined,
    intervalToken: undefined,
    total_balance: 0,
    account_manager: {} as any,
    random_key: Math.random(),
  };

  componentDidMount = async () => {
    const intervalDictionary = setInterval(
      checkDictionariesInterval,
      60 * 60 * 1000
    );
    this.setState({ intervalDictionary: intervalDictionary });

    const intervalToken = setInterval(
      this.setTokenCheckInterval,
      10 * 60 * 1000
    );
    this.setState({ intervalToken: intervalToken });

    await this.getAppUserInfo();
    await checkDictionaries();

    refreshTokenMethod(
      this.props.setAppUserInfo,
      this.props.logoutAppUser,
      this.props.appUserInfo ? this.props.appUserInfo : {}
    );

    if (this.props.appUserInfo && this.props.appUserInfo.id_company) {
      this.getTotalBalance();
    }
    // initWebSocket();
  };

  componentWillUnmount = () => {
    // closeWebSocket();
    clearInterval(this.state.intervalDictionary);
    clearInterval(this.state.intervalToken);
  };

  public render() {
    return (
      <Header
        style={{
          height: this.props.isCCSimplified ? "120px" : "64px",
          backgroundColor: "white",
        }}
      >
        <section className={styles.grid}>
          <div className={styles.boxContainer}>
            <ProtectedComponent
              permission={UserPermissionType.SeeHoldValueComponent}
            >
              <div className={`${styles.box} ${styles.boxWidth}`}>
                <p>
                  {resources.labels.hold}:{" "}
                  <span className={styles.hold}>n/a</span>
                </p>
              </div>
            </ProtectedComponent>
            <ProtectedComponent
              permission={UserPermissionType.SeeBalanceValueComponent}
            >
              <div className={styles.box}>
                <p>
                  {resources.labels.balance}:{" "}
                  <span className={styles.balance}>
                    {this.displayBalance(false, this.state.total_balance)}
                  </span>
                </p>
              </div>
            </ProtectedComponent>
            <div className={`${styles.box} ${styles.box__lang}`}>
              <HeaderLangPicker
                changeLang={this.props.changeLang}
                handleUpdate={this.handleUpdate}
                isCCSimplified={this.props.isCCSimplified}
              />
            </div>
            {this.props.isCCSimplified && <img src={bannerImg} alt="banner" />}

            {!this.props.isCCSimplified && (
              <>
                <div className={`${styles.box}`}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/document/d/1z6i7C-KokkFQbz0zrfEGYpbRhnNRlw5PipJPJxrGhmA/edit?usp=sharing"
                  >
                    {resources.toolsView.apiDocs}
                  </a>
                </div>
                <div className={`${styles.box}`}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/document/d/1v1_ti9p54ZgUezS-_35-AMfK3AH6LSUh_dYA5aY3kRk/edit?usp=sharing"
                  >
                    {resources.toolsView.leadSendExample}
                  </a>
                </div>
              </>
            )}
          </div>
          <div className={styles.userContainer}>
            <ProtectedComponent
              permission={UserPermissionType.SeeUserManagerComponent}
            >
              <div
                className={`${styles.managerBox} customAnt--mainHeaderManager`}
              >
                {this.state.account_manager && (
                  <Popover
                    overlayClassName="customAnt--managerPopover"
                    trigger="hover"
                    placement="bottomLeft"
                    content={
                      <>
                        {this.state.account_manager.skype &&
                          this.state.account_manager.skype !== null && (
                            <PartnerHoverPopUpItem
                              manager={this.state.account_manager.skype}
                              type="skype"
                            />
                          )}
                        {this.state.account_manager.telegram &&
                          this.state.account_manager.telegram !== null && (
                            <PartnerHoverPopUpItem
                              manager={this.state.account_manager.telegram}
                              type="telegram"
                            />
                          )}
                        {this.state.account_manager.email &&
                          this.state.account_manager.email !== null && (
                            <PartnerHoverPopUpItem
                              manager={this.state.account_manager.email}
                              type="email"
                            />
                          )}
                      </>
                    }
                  >
                    <button className={styles.popOverHoverBtn}>
                      <YourPartner
                        name={this.state.account_manager.name}
                        random_key={this.state.random_key}
                      />
                    </button>
                  </Popover>
                )}
              </div>
            </ProtectedComponent>
            {/* //:TODO - Add new permision that widget */}
            <ProtectedComponent permission={UserPermissionType.SupportView}>
              <InformationModalDialog
                buttonTooltip={resources.modalTitle.sendFeedback}
                icon={"message"}
                withHideMethod={true}
                width={400}
                buttonClassName={styles.contactButton}
              >
                <SendFeedback {...this.props.appUserInfo} />
              </InformationModalDialog>
            </ProtectedComponent>
            <div className={styles.line}></div>
            <SwitchUser />
            <div className={styles.line}></div>
            <div className={`${styles.userBox}`}>
              <Popover
                overlayClassName={"customAnt--mainHeaderUser"}
                trigger="click"
                placement="bottomRight"
                content={
                  <>
                    <div className={`userBox__profile`}>
                      <Link to={createAbsoluteUrl("profile")}>
                        <Icon type="user" /> {resources.labels.profile}
                      </Link>
                    </div>
                    <div className={`userBox__logout`}>
                      <Logout random_key={this.state.random_key} />
                    </div>
                  </>
                }
              >
                <button className={`customAnt--mainHeaderUser`}>
                  <ToLongStringToDots limitTo={25}>
                    {this.props.appUserInfo!.email
                      ? this.props.appUserInfo!.email
                      : "-"}
                  </ToLongStringToDots>
                  <div className="after">
                    <img src={arrow} alt="arrow" />
                  </div>
                </button>
              </Popover>
            </div>
          </div>
        </section>
      </Header>
    );
  }

  setTokenCheckInterval = () => {
    refreshTokenMethod(
      this.props.setAppUserInfo,
      this.props.logoutAppUser,
      this.props.appUserInfo ? this.props.appUserInfo : {}
    );
  };

  handleClick = (e: any) => {
    if (e.key === "profile") {
      history.push(`/${e.key}`);
    }
  };

  handleUpdate = () => {
    this.forceUpdate();
    this.setState({ random_key: Math.random() });
  };

  getAppUserInfo = async () => {
    setHeader();

    await getUserInf().then((response) => {
      if (response) {
        const {
          id_country,
          id_account_manager,
          country_name,
          id_users_role,
          account_manager,
          id_company,
        } = response;

        const newState = {
          ...this.props.appUserInfo,
          id_country,
          id_account_manager,
          country_name,
          id_users_role: id_users_role,
          account_manager,
          id_company,
        };

        this.props.setAppUserInfo(newState);

        this.setState({ account_manager: response.account_manager });
      }
    });
  };

  getTotalBalance = () => {
    this.setState({ total_balance_loading: true });

    getTotalBalance("")
      .then((total_balance) => this.setState({ total_balance }))
      .finally(() => this.setState({ total_balance_loading: false }));
  };

  displayBalance = (loading: boolean, value: number) =>
    loading ? (
      <Icon type="sync" spin={loading} />
    ) : (
      <>{`${(value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`}</>
    );
}

const mapStateToProps = (state: IAppState): IFormHeaderContent => ({
  appUserInfo: state.commonState.appUserInfo,
  isAuthenticated: state.commonState.isAuthenticated,
  isCCSimplified:
    state.commonState.appUserInfo.id_users_role === USER_ROLES.CC_SIMPLIFIED,
});

const mapDispatchToProps: MapDispatchToProps<
  ISetAppUserInfo & ISetBlockUi & ILogoutAppUser,
  {}
> = (dispatch: Dispatch) => ({
  setAppUserInfo: (appUserInfoModel: IAppUserInfoModel) => {
    dispatch(setAppUserInfo(appUserInfoModel));
  },
  setBlockUi: (blockUi: boolean) => {
    dispatch(setBlockUi(blockUi));
  },
  logoutAppUser: () => {
    dispatch(logoutAppUser());
  },
});

const HeaderContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormHeaderContent);

export default HeaderContent;
