import * as React from 'react';
import moment, { Moment } from 'moment';
import styles from '../styles/OrdersContent.module.scss';
import { Affix, Result } from 'antd';
import { changeOrderStatus } from '../../../../services/m57_update_order_status/UpdateOrderStatusService';
import { getListFromLibrary } from '../../../../services/m40_libraries/LibrariesService';
import { getOrders, getStatusesMap } from '../../../../services/OrdersService';
import { getProductsD } from '../../../../services/m30_products/ProductsService';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IOrder } from '../../interfaces/IOrder';
import { IOrderStatusesMap } from '../../../../helpers/interfaces/IOrderStatusesMap';
import { IOrdersView } from '../interfaces/IOrdersView';
import { objectToUrlParams, urlParamsToObject } from '../../../../helpers/UrlStringHelpers';
import { OrdersFiltersForm } from '../form/OrdersFiltersForm';
import { OrdersList } from '../lists/OrdersList';
import { OrdersStateChanger } from '../components/ordersStateChanger/OrdersStateChanger';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { RouteComponentProps } from 'react-router-dom';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { ViewHeader } from '../../../../components/viewHeader/ViewHeader';

const PAGE_SIZE = 20;

const URL_SUPPORTED_FILTERS_SCHEMA = {
    couriers: {
        isArray: true,
        type: 'int'
    },
    options: {
        isArray: true,
        type: 'int'
    },
    salesChannels: {
        isArray: true,
        type: 'int'
    },
    paymentMethods: {
        isArray: true,
        type: 'int'
    },
    statuses: {
        isArray: true,
        type: 'int'
    },
    products: {
        isArray: true,
        type: 'int'
    },
    selectedCountry: {
        isArray: false,
        type: 'int'
    },
    dateFrom: {
        isArray: false,
        type: 'date'
    },
    dateTo: {
        isArray: false,
        type: 'date'
    },
    reference: {
        isArray: false,
        type: 'string'
    }
};

const optionsD = [
    { name: 'Not printed', id: 1 },
    { name: 'Printed', id: 2 },
    { name: 'Not scanned', id: 3 },
    { name: 'Scanned', id: 4 },
    { name: 'Returned', id: 5 }
];

export class OrdersContent extends React.Component<IOrdersView & RouteComponentProps> {
    _isMounted = false;

    state = {
        loading: true,
        ordersList: Array<any>(),
        ordersListTotalCount: 0,
        selectedOrders: new Set<number>(),
        statusesSet: new Set<number>(),
        statusesMap: {} as IOrderStatusesMap,

        // Filters options
        countriesD: Array<IDictionaryValue>(),
        couriersD: Array<IDictionaryValue>(),
        salesChannelsD: Array<IDictionaryValue>(),
        paymentMethodsD: Array<IDictionaryValue>(),
        statusesD: Array<IDictionaryValue>(),
        productsD: Array<IDictionaryValue>(),
        optionsD: Array<IDictionaryValue>(),

        // Filters values
        selectedCountry: 0,
        products: Array<number>(),
        couriers: Array<number>(),
        options: Array<number>(),
        salesChannels: Array<number>(),
        paymentMethods: Array<number>(),
        statuses: Array<number>(),
        dateFrom: moment(new Date()).subtract(90, 'days'),
        dateTo: moment(new Date()),
        reference: '',

    }

    componentDidMount = async () => {
        this._isMounted = true;

        const [countriesD, productsD, couriersD, statusesD, salesChannelsD, paymentMethodsD, statusesMap] = await Promise.all([
            this.getCountriesD(),
            getProductsD(),
            this.getCouriers(),
            this.getStatusesList(),
            this.getSalesChannelsList(),
            this.getPaymentMethods(),
            getStatusesMap(),
        ]);

        this.setState({
            selectedCountry: countriesD[0].id,
            countriesD,
            productsD,
            couriersD,
            statusesD,
            salesChannelsD,
            paymentMethodsD,
            optionsD,
            statusesMap
        });

        const { filtersParams } = this.props;

        if (filtersParams && filtersParams.length > 0) {
            this.setFiltersFromURL(filtersParams);
        }
        else {
            this.getOrdersList();
        }
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    setFiltersFromURL = (filtersParams: string) => {
        const filterObject = urlParamsToObject(filtersParams, URL_SUPPORTED_FILTERS_SCHEMA);
        this.setState({
            ...filterObject,
        }, this.getOrdersList);
    }

    setURLFromFilters = () => {
        const urlSearch = objectToUrlParams(this.state, URL_SUPPORTED_FILTERS_SCHEMA);
        this.props.history.replace({
            search: urlSearch,
        });
    }

    getCountriesD = () => {
        const countriesD = localStorage.getItem('countriesNameD')
        if (countriesD) {
            return JSON.parse(countriesD)
                .filter((el: IDictionaryValue) => this.props.countryPermisionsForOrders.includes(Number(el.id)));
        }
        return [];
    }

    getCouriers = async () => {
        return getListFromLibrary({
            name: 'li_couriers',
            column: 'name, id, country_id'
        });
    }

    getStatusesList = async () => {
        return getListFromLibrary({
            name: 'li_order_statuses',
            column: 'name, id'
        });
    }

    getSalesChannelsList = async () => {
        return getListFromLibrary({
            name: 'li_sales_channels',
            column: 'name, id'
        });
    }

    getPaymentMethods = async () => {
        return getListFromLibrary({
            name: 'li_payment_methods',
            column: 'name, id'
        });
    }

    getOrdersList = async (pageIndex?: number, pageSize?: number) => {
        this.setState({ loading: true });

        let pagination = {
            take: PAGE_SIZE.toString(),
            skip: "0",
        };
        if (pageSize && pageIndex) {
            pagination.take = pageSize.toString();
            pagination.skip = ((pageIndex - 1) * pageSize).toString();
        }

        const requestBody = {
            filters: {
                country_id: [this.state.selectedCountry],
                courier_name: this.state.couriers,
                qr_print: this.state.options.includes(1) ? true : false,
                qr_scan: this.state.options.includes(3) ? true : false,
                qr_return_scan: this.state.options.includes(5) ? true : false,
                id_sale_channel: this.state.salesChannels,
                id_payment_method: this.state.paymentMethods,
                id_order_status: this.state.statuses,
                id_product: [],
                id_product_pricing: [],
                date: {
                    from: this.state.dateFrom,
                    to: this.state.dateTo,
                    by: 'created_at',
                },

                ref: []
            },
            ...pagination,
        }

        this.setURLFromFilters();

        getOrders(requestBody)
            .then((response) => {
                this.setState({
                    ordersList: response.orders,
                    loading: false,
                    ordersListTotalCount: response.count,
                });
            });
    }

    onCountryChange = (value: any) => {
        this.setState({
            selectedCountry: value,
            couriers: [],
            products: [],
        },
            () => {
                this.getOrdersList();
            });
    }

    onChange = (event: any) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeTextBox = (name: string, value: any) => {
        this.setState({ [name]: value });
    }

    onChangeDate = (name: string, value: Moment) => {
        this.setState({
            [name]: value,
        });
    }

    handleOrderSelect = (orderId: number) => {
        const { selectedOrders } = this.state;
        if (selectedOrders.has(orderId)) {
            selectedOrders.delete(orderId);
        }
        else {
            selectedOrders.add(orderId);
        }

        this.setState({
            selectedOrders,
        }, this.setSelectedOrdersStatuses);
    }

    handleAllOrdersSelect = () => {
        const { selectedOrders, ordersList } = this.state;
        if (selectedOrders.size === ordersList.length) {
            selectedOrders.clear();
        }
        else {
            ordersList.forEach((order: IOrder) => {
                selectedOrders.add(order.id);
            });
        }
        this.setState({
            selectedOrders,
        }, this.setSelectedOrdersStatuses);
    }

    setSelectedOrdersStatuses = () => {
        const selectedOrders = this.state.ordersList.filter((order: IOrder) => this.state.selectedOrders.has(order.id));
        const statusesSet = new Set<number>();
        selectedOrders.forEach((order: IOrder) => {
            if (order.histories.length > 0) {
                statusesSet.add(order.histories[order.histories.length - 1].id);
            }
        });
        this.setState({ statusesSet });
    }

    statusChangeHandler = (ordersId: number, statusId: number, comment: string) => {
        return changeOrderStatus(ordersId, statusId, comment);
    }

    pageChangeHandler = (pageIndex: number, pageSize: number | undefined) => {
        this.getOrdersList(pageIndex, pageSize);
    }


    public render() {
        if (this.state.selectedCountry === undefined) {
            return <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
            />;
        }
        const { ordersList, selectedOrders, statusesMap } = this.state;
        return (
            <TableContainer>
                <ViewHeader title={'Orders'} />
                <OrdersFiltersForm
                    {...this.state}
                    getOrdersList={this.getOrdersList}
                    onChange={this.onChange}
                    onChangeTextBox={this.onChangeTextBox}
                    onChangeDate={this.onChangeDate}
                    onCountryChange={this.onCountryChange}
                />
                <Affix offsetTop={64}>
                    <div className={styles.controls}>
                        <RefreshTableButton
                            loading={this.state.loading}
                            onClick={this.getOrdersList}
                        />
                        <OrdersStateChanger
                            selectedOrders={ordersList.filter((el: IOrder) => selectedOrders.has(el.id))}
                            statusesMap={statusesMap}
                            onStatusChange={this.statusChangeHandler}
                        />
                    </div>
                </Affix>
                <OrdersList
                    ordersList={this.state.ordersList}
                    loading={this.state.loading}
                    productsD={this.state.productsD}
                    selectedOrders={this.state.selectedOrders}
                    onOrderSelected={this.handleOrderSelect}
                    onAllOrdersSelected={this.handleAllOrdersSelect}
                    statusesMap={statusesMap}
                    onStatusChange={this.statusChangeHandler}
                    ordersListTotalCount={this.state.ordersListTotalCount}
                    pageSize={PAGE_SIZE}
                    onPageChange={this.pageChangeHandler}
                />
            </TableContainer>
        )
    };
}