const preUrl = "";
// Pre URL dla bramek
const efirstUrl = `https://${process.env.REACT_APP_EFIRST_DOMAIN}`;
const gateAppUrl = `https://${process.env.REACT_APP_DOMAIN}`;
const gatePreUrl = `https://api.${process.env.REACT_APP_DOMAIN}`;
const trackerPreUrl = `https://track.${process.env.REACT_APP_DOMAIN}`;

export const createAbsoluteUrl = (relative: string): string => {
  return `${preUrl}/${relative}`;
};

export const createEfirstUrl = (relative: string): string => {
  return `${efirstUrl}/${relative}`;
};

export const createAppAbsoluteUrl = (relative: string): string => {
  return `${gateAppUrl}/${relative}`;
};

// Generator URL do bramek
export const createGatewayAbsoluteUrl = (relative: string): string => {
  return `${gatePreUrl}/${relative}`;
};

// Generator URL do bramek
export const createTrackerAbsoluteUrl = (relative: string): string => {
  return `${trackerPreUrl}/${relative}`;
};
