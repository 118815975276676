import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openAlertNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const insertUrl = createGatewayAbsoluteUrl(resourceDef.ClassifiersInsert);

export interface IInsertClassifierLib {
    name?: string;
    is_qualified?: Boolean | string;
    status?: string;
    id_instance?: number;
    id_campaign?: number;
}

export const insertClassifierLib = (obj: IInsertClassifierLib) => axios.post(insertUrl, { ...obj })
    .then(
        (response) => {
            return response.data.data;
        })
    .catch((error) => {
        if (error.response && error.response.status === 409) {
            openAlertNotification('Classifier exist');
        }
        else {
            console.log(error);
        }

        return false;
    });