import * as Yup from 'yup';

const validation = {
    courierName: Yup.string()
        .required('Required'),
    sourceWarehouse: Yup.string()
        .required('Required'),
    leadTime: Yup.string()
        .required('Required'),
    destinationCode: Yup.string()
        .required('Required'),
};

export default validation;