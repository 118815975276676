import React from "react";
import { Icon, Col, Tree } from "antd";

import styles from "./table-sidebar.module.scss";
import { TreeNodeNormal } from "antd/lib/tree/Tree";

type Props = {
  groupBy: Array<string>;
  groupByData: TreeNodeNormal[];
  onChangeGroupBy: (value: string) => void;
};

export const TableSidebar = ({
  groupBy,
  groupByData,
  onChangeGroupBy,
}: Props) => (
  <Col xs={6} sm={4} xxl={2}>
    <div className={styles["sidebar-title"]}>
      <Icon type="block" className={styles["sidebar-title__icon"]} />

      <span className={styles["sidebar-title__text"]}>Group by</span>
    </div>

    <Tree
      treeData={groupByData}
      onSelect={(i) => onChangeGroupBy(i[0])}
      selectedKeys={groupBy}
    />
  </Col>
);
