import * as React from 'react';
import styles from '../styles/WebSocketContent.module.scss';
import { MessageReceiverType, MessageType } from '../../../../services/m80_web_socket/interfaces/IMessage';
import { NotificationForm } from '../components/notificationForm/NotificationForm';
import { PageTitle } from '../../../../components/common/pageTitle/PageTitle';
import { RoleSelector } from '../components/roleSelector/RoleSelector';
import { Select } from 'antd';
import { UserSelector } from '../components/userSelector/UserSelector';

const { Option } = Select;


export class WebSocketContent extends React.Component {
    state = {
        mounted: false,
        selectedMessageType: undefined as undefined | string,
        selectedMessageToType: undefined as undefined | string,
        to: [] as Array<number>,
        countryIds: [] as Array<number>,
    }

    componentDidMount = async () => {
        this.setState({
            mounted: true,
        });
    }

    componentWillUnmount = () => {
        this.setState({
            mounted: false
        });
    }

    handleMessageTypeChange = (selectedMessageType: string) => {
        this.setState({ selectedMessageType });
    }

    handleMessageToTypeChange = (selectedMessageToType: string) => {
        this.setState({ 
            selectedMessageToType,
            to: [],
            countryIds: [],
        });
    }

    handleReceiversChange = (ids: Array<number>) => {
        this.setState({
            to: ids,
        });
    }

    handleCountriesChange = (ids: Array<number>) => {
        this.setState({
            countryIds: ids,
        })
    }

    public render() {
        const { selectedMessageType, selectedMessageToType, to, countryIds } = this.state;

        let receiverForm;

        if (selectedMessageToType === MessageReceiverType.USERS) {
            receiverForm = <UserSelector onUsersSelected={this.handleReceiversChange} />
        }
        else if (selectedMessageToType === MessageReceiverType.ROLES) {
            receiverForm = <RoleSelector onRoleSelected={this.handleReceiversChange} onCountrySelected={this.handleCountriesChange}/>
        }

        let eventForm;

        if (selectedMessageType === MessageType.NOTIFICATION && selectedMessageToType !== undefined) {
            eventForm = <NotificationForm
                receiverType={selectedMessageToType}
                receivers={to}
                countries={countryIds}
            />
        }

        return (
            <div className={styles.container}>
                <PageTitle>Send WebSocket Event</PageTitle>
                <div className={styles.rowElements}>
                    <div className={styles.selectContainer}>
                        <p>Event type</p>
                        <Select style={{ width: 300 }} onChange={this.handleMessageTypeChange} value={selectedMessageType}>
                            {Object.values(MessageType).map((messageType: string) => 
                                <Option key={messageType} value={messageType}>{messageType}</Option>
                            )}
                        </Select>
                    </div>
                    <div className={styles.selectContainer}>
                        <p>Send event to</p>
                        <Select style={{ width: 300 }} onChange={this.handleMessageToTypeChange} value={selectedMessageToType}>
                            {Object.values(MessageReceiverType).map((messageReceiverType: string) => 
                                <Option key={messageReceiverType} value={messageReceiverType}>{messageReceiverType}</Option>
                            )}
                        </Select>
                    </div>
                </div>
                {receiverForm}
                {eventForm}
            </div>
        )
    };
}