import moment from 'moment';
import React, { Component } from 'react';
import styles from './LeadWithFccId.module.scss';
import { ILeadWithFccId } from '../../contents/interfaces/ILeadWithFccId';

interface IProps {
    data: ILeadWithFccId;
    handleSelect: (e: any) => void;
    checked: boolean;
}


export class LeadWithFccId extends Component<IProps> {
    private fieldRef = React.createRef<HTMLDivElement>();

    componentDidMount = () => {
        this.fieldRef.current!.addEventListener('click', () => {
            this.props.handleSelect(JSON.stringify(this.props.data));
        })
    }

    render() {
        const { client_firstname: firstName, client_lastname: lastName, created_at: leadTime } = this.props.data;
        return (
            <section ref={this.fieldRef} className={`${styles.listElement} ${this.props.checked ? styles.checked : ''}`}>
                <div className={`${styles.leftCol}`}>
                    <div className={`${styles.row}`}>
                        <span className={styles.label}>Phone: </span>
                        <span className={styles.value}>{this.props.data ? this.props.data.client_phone1 : ''}</span>
                    </div>
                    <div className={`${styles.row}`}>
                        <span className={styles.label}>Name: </span>
                        <span className={styles.value}>{firstName.concat(' ').concat(lastName && lastName !== 'empty' ? lastName : '')}</span>
                    </div>
                    <div className={`${styles.row}`}>
                        <span className={styles.label}>Product: </span>
                        <span className={styles.value}>{this.props.data.product ? this.props.data.product.name : 'Unknown product'}</span>
                    </div>
                    <div className={`${styles.row}`}>
                        <span className={styles.label}>Lead Date: </span>
                        <span className={styles.value}>{moment(leadTime).format('YYYY-MM-DD, hh:mm')}</span>

                    </div>
                </div>
                <div className={`${styles.rightCol}`}>
                    <div className={styles.checkBox}>
                        <div className={styles.checkBox__dot} style={{ opacity: this.props.checked ? 1 : 0 }}></div>
                    </div>
                </div>
            </section>
        )
    }
}


