import * as React from 'react';
import { ExpandedPostbackTable } from '../table/ExpandedPostbackTable';
import { getPostbacks } from '../../../../../services/m3_postbacks/PostbacksService';
import { IExpandedPostback } from '../interfaces/IExpandedPostback';
import { ILeadId } from '../interfaces/ILeadId';

export class ExpandedPostbackComponent extends React.Component<ILeadId> {
    state = {
        postbacks_list: [] as Array<IExpandedPostback>,
        loading: false
    }

    componentDidMount = () => {
        this.getPostbackList();
    }

    public render() {
        const { postbacks_list, loading } = this.state;

        return (
            <div className={'customAnt--expandedPostbackTable'}>
                <ExpandedPostbackTable
                    id_lead={this.props.id_lead}
                    loading={loading}
                    data={postbacks_list}
                />
            </div>
        )
    };

    getPostbackList = () => {
        this.setState({ loading: true });

        getPostbacks(this.props.id_lead)
            .then(response => {
                const postbacks_list = response.map((item: IExpandedPostback) => { return { ...item, key: item.id } });

                this.setState({ postbacks_list });
            })
            .finally(() => this.setState({ loading: false }));
    }
}