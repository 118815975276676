import React from 'react';
import { DataTable } from '../../../components/others/data/DataTable';
import { Table, Icon, Tooltip } from 'antd';
import { openAlertNotification } from '../../../helpers/NotificationHelper';
import { copyClipboard } from '../../../helpers/CopyHelpers';
import { resources } from '../../../common/Resources';
import { findInDictionaryAndReturnValueWithSpin } from '../../../helpers/DisctionariesHelper';

const { Column } = Table;

export class CampaignsPartnerTable extends DataTable {
    render() {
        const columns = [
            <Column
                title='Name'
                dataIndex='name'
                key='name'
                align='center'
                width='150px'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
            />,
            <Column
                title='Offer'
                key='product_id'
                align='center'
                width='100px'
                sorter={(a, b) => a.product_id - b.product_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.product_id, this.props.productsD)
                    )}
            />,
            <Column
                title={resources.offersDetails.prelanding}
                key='prelanding'
                width='120px'
                align='center'
                render={(record) => {
                    return record.pre.map(item => <div key={item}>{findInDictionaryAndReturnValueWithSpin(item, this.props.pagesD)}</div>);
                }}
            />,
            <Column
                title={resources.offersDetails.landing}
                key='landing'
                width='120px'
                align='center'
                render={(record) => {
                    return record.lp.map(item => <div key={item}>{findInDictionaryAndReturnValueWithSpin(item, this.props.pagesD)}</div>);
                }}
            />,
            <Column
                title='URL'
                key='url'
                align='center'
                width='150px'
                render={(record) => (
                    <Tooltip title={record.url}>
                        {record.url !== null && record.url.length > 24 ? record.url.substring(0, 20) + '...' : record.url}
                        <Icon type="copy" style={{ fontSize: 20 }} onClick={(event) => copyClipboard(event, record.url)} />
                    </Tooltip>
                )}
            />,
            <Column
                title='Options'
                key='options'
                align='center'
                width='50px'
                render={(record) => {
                    return (
                        <Tooltip title={resources.tooltips.delete}>
                            <Icon type="delete" style={{ fontSize: 20 }} onClick={() => this.props.deleteTargetUrlCampaigns(record.id)} />
                        </Tooltip>
                    )
                }}
            />
        ];

        return (
            <div className={'customAnt--productDetailsTable'}>
                <DataTable
                    tableName={'CampaignsPartnerTable'}
                    columns={columns}
                    data={this.props.data}
                    loading={this.props.loading}
                    pagination={true}
                    showSizeChanger={false}
                    showQuickJumper={false}
                    showTotal={false}
                    position='top'
                />
            </div>
        )
    }

    deleteCampaign = (id) => {
        openAlertNotification(`Delete Campaign action ${id}`)
    }   
}
