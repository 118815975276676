import * as React from "react";
import noImg from "../../../common/images/noImg.png";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import styles from "./styles/ProductCard.module.scss";
import {
  decodeCategoriesArray,
  removeLastCharIfNumber,
} from "../../../helpers/Encoders";
import { displayPercent, displayPrice } from "../../../helpers/DisplayHepler";
import {
  findInDictionaryAndReturnValue,
  getDictionary,
} from "../../../helpers/DisctionariesHelper";
import { IProductCard } from "./interfaces/IProductCard";
import { Link } from "react-router-dom";
import { resources } from "../../../common/Resources";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";

export class ProductCard extends React.Component<IProductCard> {
  render() {
    const {
      id,
      name,
      default_landing_price,
      default_payout_schema,
      default_payout,
      country_name,
      approve_page,
      country_id,
    } = this.props;

    let productCategories = [] as string[];
    const categories = getDictionary("categoriesD");
    if (this.props.categories) {
      productCategories = this.props.categories.split(",");
    }
    const categoriesString = decodeCategoriesArray(
      productCategories,
      categories
    );

    return (
      <div className={styles.productCard}>
        <img
          className={styles.productCard__img}
          src={this.findProductImg()}
          alt={name}
        />
        <h5 className={`h4__bold ${styles.productCard__title}`}>{name}</h5>
        <div className={styles.productCard__table}>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.sitePrice}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {displayPrice(
                default_landing_price,
                findInDictionaryAndReturnValue(
                  country_id!,
                  getDictionary("currencyD")
                )
              )}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.landingPage.approve}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {displayPercent(approve_page)}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.payouts}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {displayPrice(default_payout)}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.labels.payoutScheme}
            </p>
            <p
              className={`h6 ${styles.productCard__table__row__value} uppercase`}
            >
              {default_payout_schema}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.category}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {categoriesString}
            </p>
          </div>
          <div className={styles.productCard__table__row}>
            <p className={`h6 ${styles.productCard__table__row__label}`}>
              {resources.offersView.country}
            </p>
            <p className={`h6 ${styles.productCard__table__row__value}`}>
              {removeLastCharIfNumber(country_name ? country_name : "-")}
            </p>
          </div>
          <ProtectedComponent
            permission={UserPermissionType.SeeOriginalProductCountry}
          >
            <div
              className={`${styles.productCard__table__row} ${styles.admin_table_row}`}
            >
              <p className={`h6 ${styles.productCard__table__row__label}`}>
                {resources.offersView.originalCountry}
              </p>
              <p className={`h6 ${styles.productCard__table__row__value}`}>
                {country_name ? country_name : "-"}
              </p>
            </div>
          </ProtectedComponent>
        </div>
        <Link className={styles.productCard__button} to={`offers/${id}`}>
          {resources.offersView.readMore}
        </Link>
      </div>
    );
  }

  findProductImg = () => {
    const obj: any[] = this.props.src_list.filter(
      (x) => x.id_product === this.props.id
    );
    const src = obj.length ? obj[0].src : undefined;

    return src ? src : noImg;
  };
}
