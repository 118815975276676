import * as React from 'react';
import './styles/LeftStyles.css';

export class Left extends React.Component {
    public render() {
        return (
            <div className="left">
                {this.props.children}
            </div>
        )
    };
}