import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { IcheckBoxProps } from './interfaces/IcheckBoxProps';

const CheckboxF: React.SFC<IBaseFormikProps & IcheckBoxProps> = (props) => {
    function onChange(event: CheckboxChangeEvent) {
        props.setFieldValue(props.name, event.target.checked);
    }
    
    return (
        <BaseForm {...props} >
            <Checkbox
                checked={props.values[props.name]}
                // defaultChecked={props.values[props.name]}
                onChange={onChange}
                onKeyPress={() => props.setFieldTouched(props.name)}
                disabled={props.disabled}
            >
                {props.checkBoxText}
            </Checkbox >
        </BaseForm>)
};

export default CheckboxF;