import DictionaryF from '../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/PartnersForm.module.scss';
import TextInputF from '../../../components/templateForm/form/textInput/TextInputF';
import { Button, Spin } from 'antd';
import { createUser, getManagersD, updateUser } from '../../../services/m29_users_management/GeneralUserService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getCompaniesListD } from '../../../services/m29_users_management/CompaniesService';
import { getDictionary } from '../../../helpers/DisctionariesHelper';
import { IAddObjToArray } from '../../../common/interfaces/IAddObjToArray';
import { IDictionaryValue } from '../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IHideModal } from '../../../common/interfaces/IHideModal';
import { IId } from '../../../common/interfaces/IId';
import { INullablePartner } from './interfaces/INullablePartner';
import { IPartnersFormProps } from './interfaces/IPartnersFormProps';
import { IUpdateObjInArray } from '../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../helpers/NotificationHelper';
import { resources } from '../../../common/Resources';

class TemplatePartnersForm extends React.Component<IPartnersFormProps & IHideModal & IId & IAddObjToArray & IUpdateObjInArray & FormikProps<INullablePartner>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        labelTextAlignLeft: true,
        boldLabel: true,
        allowClear: true
    }

    state = {
        companiesD: Array<IDictionaryValue>(),
        accountManagersD: Array<IDictionaryValue>(),
        loading: false
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const companiesD = await getCompaniesListD();
        const accountManagersD = await getManagersD();

        this.setState({
            companiesD,
            accountManagersD,
            loading: false
        });
    }

    public render() {
        return (
            <>
                {this.state.loading ? <div style={{ textAlign: "center" }}><Spin tip={resources.spin.loading} /></div> :
                    <Form>
                        <div className={styles.grid_col_2}>
                            <div>
                                <TextInputF
                                    {...this.props}
                                    name="email"
                                    label={resources.labels.email}
                                    required
                                />
                            </div>
                            <div>
                                <TextInputF
                                    {...this.props}
                                    name="name"
                                    label={resources.labels.name}
                                    required
                                />
                            </div>
                            <div>
                                <TextInputF
                                    {...this.props}
                                    type="password"
                                    name="password"
                                    label={resources.labels.password}
                                    required={!this.props.id}
                                />
                            </div>
                            <div>
                                <DictionaryF
                                    {...this.props}
                                    name="id_account_manager"
                                    label={resources.labels.accountManager}
                                    dictionaryValues={this.state.accountManagersD}
                                    required
                                />
                            </div>
                            <div>
                                <TextInputF
                                    {...this.props}
                                    name="second_name"
                                    label={resources.labels.second_name}
                                    required={false}
                                />
                            </div>
                            <div>
                                <TextInputF
                                    {...this.props}
                                    name="skype"
                                    label={resources.labels.skype}
                                />
                            </div>
                            <div>
                                <DictionaryF
                                    {...this.props}
                                    name="id_company"
                                    label={resources.labels.company}
                                    dictionaryValues={this.state.companiesD}
                                />
                            </div>
                            <div>
                                <DictionaryF
                                    {...this.props}
                                    name="id_country"
                                    label={resources.labels.country}
                                    dictionaryValues={getDictionary('countriesNameD')}
                                />
                            </div>
                            <div>
                                <TextInputF
                                    {...this.props}
                                    name="telegram"
                                    label={resources.labels.telegram}
                                />
                            </div>
                        </div>
                        <Button
                            className='saveButton'
                            htmlType="submit"
                            type="primary"
                        >
                            {this.props.id ? resources.buttons.save : resources.buttons.create}
                        </Button>
                    </Form>
                }
            </>
        );
    };
}

const PartnersForm = withFormik<IPartnersFormProps & IAddObjToArray & IUpdateObjInArray, INullablePartner>({
    mapPropsToValues: (props: IPartnersFormProps) => {
        return {
            email: props.email,
            name: props.name,
            second_name: props.second_name,
            id_account_manager: props.id_account_manager,
            id_company: props.id_company,
            skype: props.skype,
            telegram: props.telegram,
            id_country: props.id_country,
            password: undefined,
            id: props.id
        };
    },

    validate: (values: IPartnersFormProps) => {
        const errors: FormikErrors<INullablePartner> = {};
        const reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
        const specChar = new RegExp('[!@#$%^&*]', 'g');
        const specMatches = values.password!.match(specChar);
        
        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (!values.email) {
            errors.email = resources.validation.emailIsRequired;
        }
        else if (values.email.length > 255) {
            errors.email = resources.validation.requiredMax255Characters;
        }
        if (!values.id_account_manager) {
            errors.id_account_manager = resources.validation.required;
        }
        if (values.skype && values.skype.length > 250) {
            errors.skype = resources.validation.requiredMax250Characters;
        }
        else if (values.telegram && values.telegram.length > 250) {
            errors.telegram = resources.validation.requiredMax250Characters;
        }
        if (!values.id && !values.password) {
            errors.password = resources.validation.required;
        }
        else if (values.password && (!reg.test(values.password!) || !Array.isArray(specMatches) || specMatches.length < 2)) {
            errors.password = resources.validation.passwordCondition;
        }

        return errors;
    },

    handleSubmit: (values: INullablePartner, bag: FormikBag<IPartnersFormProps & IAddObjToArray & IUpdateObjInArray & IHideModal & IId, INullablePartner>) => {
        const object: INullablePartner = {
            id: values.id,
            email: values.email,
            name: values.name,
            second_name: values.second_name,
            id_account_manager: values.id_account_manager,
            id_company: values.id_company,
            skype: values.skype,
            telegram: values.telegram,
            id_country: values.id_country,
            rowKey: values.rowKey,
            password: values.password
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.email === bag.props.email
            && values.name === bag.props.name
            && values.second_name === bag.props.second_name
            && values.id_account_manager === bag.props.id_account_manager
            && values.id_company === bag.props.id_company
            && values.skype === bag.props.skype
            && values.telegram === bag.props.telegram
            && values.id_country === bag.props.id_country
        )
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else
            action(object, bag.props);
    }

})(TemplatePartnersForm);

export default PartnersForm;

const action = async (obj: INullablePartner, bag: IHideModal & IId & IAddObjToArray & IUpdateObjInArray) => {
    const updateUserObject = {
        email: obj.email,
        name: obj.name,
        second_name: obj.second_name,
        id_account_manager: obj.id_account_manager,
        id_company: obj.id_company,
        skype: obj.skype,
        telegram: obj.telegram,
        id_country: obj.id_country,
        password: obj.password,
        id_users_role: 3
    };

    const createUserObject = {
        email: obj.email,
        name: obj.name,
        password: obj.password
    };

    if (bag.id) {
        await updateUser(Number(obj.id), updateUserObject)
            .then((response) => {
                if (response) {
                    const obj = { ...updateUserObject, key: bag.id, id: bag.id }

                    bag.updateObjInArray!(obj);

                    openSuccessNotification(resources.statement.partner_updated);

                    bag.hideModal!();
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError)
                }
            });
    }
    else {
        await createUser(createUserObject)
            .then(response => {
                if (response) {
                    if (response.data.id) {
                        const id = response.data.id;

                        updateUser(id, updateUserObject)
                            .then(response => {
                                if (response) {
                                    const obj = { ...updateUserObject, key: id, id: id }

                                    bag.addObjToArray!(obj);

                                    openSuccessNotification(resources.statement.partner_created);

                                    bag.hideModal!();
                                }
                            });
                    }
                }
            });
    }
}