import * as React from 'react';
import { Checkbox } from 'antd';
import { ICheckBoxElement } from './interfaces/ICheckBoxElement';
import { ICheckBoxGroupeProps } from './interfaces/ICheckBoxGroupeProps';

export class CheckBoxGroupe extends React.Component<ICheckBoxGroupeProps> {
    state = {
        check_all: false
    }

    onCheckAll = (event: any) => {
        this.setState({ check_all: !event.target.value });

        if (!event.target.value) {
            let array = this.props.options ? this.props.options!.map(item => {
                return item.value;
            }) : null;

            this.props.onChange(this.props.name, array);
        }
        else {
            this.props.onChange(this.props.name, []);
        }
    }

    onChange = (value: any) => {
        if (this.state.check_all) {
            this.setState({ check_all: false });
        }

        this.props.onChange(this.props.name, value);
    }

    public render() {
        return (
            <div className='marginBottom marginTop'>
                <Checkbox
                    disabled={this.props.loading}
                    checked={this.state.check_all}
                    value={this.state.check_all}
                    onChange={this.onCheckAll}
                >
                    All
                </Checkbox >
                <Checkbox.Group
                    disabled={this.props.loading}
                    onChange={(any) => this.onChange(any)}
                    value={this.props.value}
                >
                    {this.props.options ? this.props.options.map(item => (
                        this.renderOption(item)
                    )) : null}
                </Checkbox.Group>
            </div>
        )
    }

    renderOption = (dropdownItem: ICheckBoxElement) => {
        return (
            <Checkbox
                style={{ minWidth: 100 }}
                value={dropdownItem.value}
                key={dropdownItem.value}
            >
                {dropdownItem.name}
            </Checkbox >
        );
    }
};