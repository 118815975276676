import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';
import { displayErrorMessage } from '../helpers/MessageHelper';
import { resourceDef } from '../common/ResourceDef';

const leads = createGatewayAbsoluteUrl(resourceDef.LeadsGet);
const pricings = createGatewayAbsoluteUrl(resourceDef.GetProductPricings);
const liTables = createGatewayAbsoluteUrl(resourceDef.LiTablesGet);
const createOrder = createGatewayAbsoluteUrl(resourceDef.CreateNewOrder);

export const getLeads = (countryId: number, fcc_id?: string, phoneNumber?: string) => axios.post(leads,
    {
        country_id: countryId,
        cc_record_id: fcc_id !== '' ? fcc_id : null,
        client_phone1: phoneNumber !== '' ? phoneNumber : null,
        columns: [
            'id',
            'id_product',
            'client_phone1',
            'api_click_id',
            'api_pub_id',
            'created_at',
            'date_approved_at',
            'client_firstname',
            'client_lastname',
        ]
    })
    .then(response => response)
    .catch(error => error.response.message)

export const getProductsPricings = (countryId: number | undefined) => axios.get(pricings, {
    params: {
        country_id: countryId,
    }})
    .then(response => response.data)
    .catch(error => {
        displayErrorMessage(' getProductsPricings')
        return [];
    });

export const getSalesChannels = (activeOnly = true) => {
    return axios.post(
        liTables,
        {
            name: 'li_sales_channels',
            column: 'id,name,is_active'
        }
    )
        .then(response => response.data.filter((el: any) => !activeOnly || el.is_active === 1))
        .catch(error => {
            displayErrorMessage(' getSalesChannels')
            return [];
        });
}

export const getPaymentMethods = (activeOnly = true) => {
    return axios.post(
        liTables,
        {
            name: 'li_payment_methods',
            column: 'id,name,is_active'
        }
    )
        .then(response => response.data.filter((el: any) => !activeOnly || el.is_active === 1))
        .catch(error => {
            displayErrorMessage(' getPaymentMethods')
            return [];
        });
}

export const createNewOrder =  (orderData: any) => {
    return axios.post(createOrder, orderData);
}