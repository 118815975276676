import * as React from 'react';
import { ITargetUrl } from './interfaces/ITargetUrl';
import { TargetUrlForm } from '../forms/targetUrl/TargetUrlForm';

export class TargetUrl extends React.Component<ITargetUrl> {
    public render() {
        return (
            <TargetUrlForm
                id={this.props.id}
                reRenderTable={this.props.reRenderTable}
                pages={this.props.pages}
            />
        )
    };
}