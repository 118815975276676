import axios from 'axios';
import { openErrorNotification } from '../helpers/NotificationHelper';
import { resourceDef } from '../common/ResourceDef';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';

const customVariableURL = resourceDef.CustomVariables;

export const getCustomVariables = async (userId?: number) => {
    const params = userId ? { userId } : {};
    return axios.get(createGatewayAbsoluteUrl(customVariableURL), { params })
    .then((response) => {
        return response.data;
    })
    .catch(() => {
        openErrorNotification(' getCustomVariables');
    });
}

export const updateCustomVariables = async (customVariables: Array<string>, userId?: number) => {
    const params = userId ? { userId } : {};
    return axios.patch(createGatewayAbsoluteUrl(customVariableURL), customVariables, { params })
    .then((response) => {
        return response.data;
    })
    .catch(() => {
        openErrorNotification(' updateCustomVariables');
    });
}