import React, { Fragment } from 'react'
import { FormikProps } from 'formik'
import styles from '../../../contents/styles/CreateNewOrderContent.module.scss';
import TextInputF from '../../../../../components/templateForm/form/textInput/TextInputF'
import { IOrderDescription } from './IOrderDescription'

export default class OrderDescription extends React.Component<FormikProps<IOrderDescription>> {

    render() {
        return (
            <Fragment>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='notes'
                            label={'Notes'}
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='reconciliationStatus'
                            label={'Reconciliation status'}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}
