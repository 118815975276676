import React, { useState } from "react";
import { Icon } from "antd";

import { useStatisticMutation } from "../../../lib/hooks";
import { FILTERS, FILTERS_INDICATORS } from "../../../lib/filters-data";
import {
  FilterItem,
  FiltersType,
  useFilters,
  UPDATE_FILTERS,
} from "../../../lib/filters-state";

const indicatorStyle = {
  color: "#009EED",
  cursor: "pointer",
  padding: "2px 7px",
  marginRight: "5px",
  marginBottom: "5px",
  fontSize: "12px",
  borderRadius: "3px",
  backgroundColor: "#DFF4FF",
};

// We have to filter our state because we don't need all filter items to indicate
const prepareFiltersToIndicate = (state: FiltersType) => {
  const filtersToIndicate = [
    FILTERS.STATUS,
    FILTERS.COMPANY,
    FILTERS.COUNTRY,
    FILTERS.PARTNER,
    FILTERS.PRODUCT,
    FILTERS.QUALIFIED,
    FILTERS.CONTACTED,
    FILTERS.PAYOUT_SCHEME,
    FILTERS.TRAFFIC_SOURCE,
  ];
  return Object.keys(state)
    .filter((key) => filtersToIndicate.includes(key))
    .reduce((obj, key) => {
      obj[key] = state[key];
      return obj;
    }, {});
};

export const FilterIndicators = () => {
  const { state, dispatch } = useFilters();
  const filters = prepareFiltersToIndicate(state);
  const statisticMutation = useStatisticMutation();

  const onRemoveOneChoiceFilter = (filter: FilterItem) => {
    const updatedFilters = Object.assign({}, state, {
      [filter.filter]: undefined,
    });
    statisticMutation(updatedFilters);
    dispatch({ type: UPDATE_FILTERS, payload: updatedFilters });
  };

  const onRemoveMultipleChoiceFilter = (filter: FilterItem) => {
    const filterArray = state[filter.filter] as FilterItem[];
    const updatedFilter = filterArray.filter((f) => f.name !== filter.name);
    const updatedFilters = Object.assign({}, state, {
      [filterArray[0].filter]: updatedFilter,
    });
    statisticMutation(updatedFilters);
    dispatch({ type: UPDATE_FILTERS, payload: updatedFilters });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {Object.entries<FilterItem | FilterItem[]>(filters).map((filter) => (
        <div key={filter[0]}>
          {/* Indicators from array filter */}
          {Array.isArray(filter[1]) && filter[1].length > 0 && (
            <IndicatorsRow>
              <IndicatorTitle text={filter[0]} />

              <IndicatorShowMore
                filter={filter[1]}
                onRemoveFilter={onRemoveMultipleChoiceFilter}
              />
            </IndicatorsRow>
          )}

          {/* Indicators from object filter */}
          {!Array.isArray(filter[1]) && filter[1] && (
            <IndicatorsRow>
              <IndicatorTitle text={filter[0]} />

              <Indicator
                filter={filter[1]}
                onRemoveFilter={onRemoveOneChoiceFilter}
              />
            </IndicatorsRow>
          )}
        </div>
      ))}
    </div>
  );
};

const IndicatorsRow = ({ children }: { children: React.ReactNode }) => (
  <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "3px" }}>
    {children}
  </div>
);

const IndicatorShowMore = ({
  filter,
  onRemoveFilter,
}: {
  filter: FilterItem[];
  onRemoveFilter: (filter: FilterItem) => void;
}) => {
  const MAX_INDICATORS_VISIBLE = 10;
  const { state, dispatch } = useFilters();
  const statisticMutation = useStatisticMutation();
  const [showItems, setShowItems] = useState(MAX_INDICATORS_VISIBLE);
  const filtersToShow = filter.slice(0, showItems);
  const moreIsVisible = filtersToShow.length !== filter.length;
  const leftToShow = filter.length - filtersToShow.length;
  const allIsVisible =
    state.qtyFilterEntities[filter[0].filter] === filter.length &&
    filter.length > MAX_INDICATORS_VISIBLE;

  const onResetFilter = (filter: FilterItem) => {
    const updatedFilters = Object.assign({}, state, {
      [filter.filter]: [],
    });
    statisticMutation(updatedFilters);
    dispatch({ type: UPDATE_FILTERS, payload: updatedFilters });
  };

  return (
    <>
      {allIsVisible && (
        <div style={indicatorStyle} onClick={() => onResetFilter(filter[0])}>
          <span style={{ marginRight: "5px" }}>All</span>
          <span>&#x2715;</span>
        </div>
      )}

      {!allIsVisible &&
        filtersToShow.map((f, index) => (
          <Indicator key={index} filter={f} onRemoveFilter={onRemoveFilter} />
        ))}

      {!moreIsVisible && (
        <Icon
          type="stop"
          style={{ cursor: "pointer", marginTop: "4px" }}
          onClick={() => onResetFilter(filter[0])}
        />
      )}

      {!allIsVisible && moreIsVisible && (
        <span
          style={indicatorStyle}
          onClick={() => setShowItems(filter.length)}
        >
          More {leftToShow}...
        </span>
      )}
    </>
  );
};

const IndicatorTitle = ({ text }: { text: string }) => (
  <span
    style={{
      fontSize: "14px",
      marginRight: "5px",
    }}
  >
    {FILTERS_INDICATORS[text]}:
  </span>
);

const Indicator = ({
  filter,
  onRemoveFilter,
}: {
  filter: FilterItem;
  onRemoveFilter: (filter: FilterItem) => void;
}) => {
  // For signle choise filter we will use default "All" name instead of "Select all" one
  const isDefaultValue = typeof filter.value === "undefined";
  return (
    <div style={indicatorStyle} onClick={() => onRemoveFilter(filter)}>
      <span style={{ marginRight: "5px" }}>
        {isDefaultValue ? "All" : filter.name}
      </span>
      <span>&#x2715;</span>
    </div>
  );
};
