import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import moment from "moment";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import ProtectedModal from "../../../components/authorizations/protectedComponents/ProtectedModal";
import React, { Component } from "react";
import SelectOnChange from "../../../components/forms/select/SelectOnChange";
import styles from "./styles/FinanceAdmin.module.scss";
import TransactionForm from "../components/transaction/TransactionForm";
import { Button, Descriptions, Icon } from "antd";
import { ClearBoth } from "../../../components/controls/clearBoth/ClearBoth";
import { FieldInfo } from "../../../components/common/fieldInfo/FieldInfo";
import { FinanceAdminTable } from "../tables/FinanceAdminTable";
import { findInDictionaryAndReturnValue } from "../../../helpers/DisctionariesHelper";
import {
  getBalance,
  getFinance,
  getTotalBalance,
} from "../../../services/finance/FinanceService";
import { getCompaniesListD } from "../../../services/m29_users_management/CompaniesService";
import {
  getCurrentDateTime,
  getDateTimeDayAgo,
} from "../../../helpers/DateHelpers";
import { getProductsD } from "../../../services/m30_products/ProductsService";
import { getUsersWithEmail } from "../../../services/m29_users_management/GeneralUserService";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IFinance } from "../interfaces/IFinance";
import { IId } from "../../../common/interfaces/IId";
import { Left } from "../../../components/controls/left/Left";
import { PageInf } from "../../../components/common/pageInf/PageInf";
import { RangePickerF } from "../../../components/forms/rangePicker/DatePicker";
import { RefreshTableButton } from "../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../common/Resources";
import { Right } from "../../../components/controls/right/Right";
import { TableContainer } from "../../../components/containers/tableContainer/TableContainer";
import { timezones } from "../../../common/dictionaries/Timezones";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";

export const statusTestD = [
  { id: "pending", name: "Pending" },
  { id: "cleared", name: "Cleared" },
  { id: "rejected", name: "Rejected" },
];

export const typeTestD = [
  { id: "withdrawal", name: "Withdrawal" },
  { id: "deposit", name: "Deposit" },
];

export const schemeTestD = [
  { id: "cpa", name: "CPA" },
  { id: "cpl", name: "CPL" },
];

const TIMEZONE_OFFSET = 13;

export class FinanceAdminContent extends Component<IId> {
  _isMounted = false;

  state = {
    loading: false,
    total_balance_loading: false,
    accepted_balance_loading: false,
    selected_company: undefined,
    id_user: undefined,
    transaction_type: "deposit",
    transaction_status: undefined,
    total_balance: 0,
    accepted_balance: 0,
    generated_at: undefined,
    disable_filter_button: true,
    financeList: Array<IFinance>(),
    usersD: Array<IDictionaryValue>(),
    companiesD: Array<IDictionaryValue>(),
    productsD: Array<IDictionaryValue>(),
    dateFrom: getDateTimeDayAgo(),
    dateTo: getCurrentDateTime(),
    time_zone: 20,
    filter_incorrect_amount: false,
  };

  componentDidMount = async () => {
    this._isMounted = true;

    this.getCompaniesD();
    this.getUsers();
    this.getProductsD();

    this.setState(
      {
        selected_company: Number(this.props.id),
        time_zone: 20,
      },
      () => this.getFinanceInf()
    );
  };

  componentWillUnmount = () => (this._isMounted = false);

  render() {
    const {
      loading,
      total_balance_loading,
      accepted_balance_loading,
      financeList,
      id_user,
      transaction_type,
      transaction_status,
      usersD,
      companiesD,
      selected_company,
      dateFrom,
      dateTo,
      accepted_balance,
      total_balance,
      generated_at,
      disable_filter_button,
      filter_incorrect_amount,
    } = this.state;

    let filtered_finance_list = financeList;

    const { length: transactions_with_0_ammount } = filtered_finance_list
      ? filtered_finance_list.filter((items) => items.amount === 0)
      : [];

    let filtered_usersD: IDictionaryValue[] = usersD.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );

    if (selected_company)
      filtered_usersD = usersD.filter(
        (x: any) => x.id_company === selected_company
      );

    if (filter_incorrect_amount) {
      filtered_finance_list = filtered_finance_list.filter(
        (x) => x.amount === 0
      );
    }

    return (
      <TableContainer>
        <Right>
          <ProtectedModal
            permission={UserPermissionType.CreateWithdrowModalButton}
          >
            <InformationModalDialog
              modalTitle={resources.modalTitle.withdraw}
              buttonTitle={resources.modalButtonsTitle.withdraw}
              buttonTooltip={resources.modalTooltips.withdraw}
              icon={"dollar"}
              withHideMethod={true}
              width={400}
              buttonClassName="marginBottom marginLeft"
              disabled={this.state.loading}
            >
              <TransactionForm
                transaction_type="withdrow"
                id_company={Number(this.state.selected_company)}
                refreshList={this.getFinanceInf}
              />
            </InformationModalDialog>
            <ProtectedModal
              permission={UserPermissionType.CreateDepositModalButton}
            >
              <InformationModalDialog
                modalTitle={resources.modalTitle.deposit}
                buttonTitle={resources.modalButtonsTitle.deposit}
                buttonType="primary"
                buttonTooltip={resources.modalTooltips.deposit}
                icon={"dollar"}
                withHideMethod={true}
                width={400}
                buttonClassName="marginBottom marginLeft"
                disabled={this.state.loading}
              >
                <TransactionForm
                  transaction_type="deposit"
                  id_company={Number(this.state.selected_company)}
                  refreshList={this.getFinanceInf}
                />
              </InformationModalDialog>
            </ProtectedModal>
          </ProtectedModal>
        </Right>
        <PageInf
          title={resources.pageTitle.finance_admin_view}
          subTitle={selected_company ? this.getTitle(selected_company!) : "-"}
        />
        <ClearBoth />
        <Descriptions style={{ textAlign: "center" }}>
          <Descriptions.Item
            className="descriptionStyle"
            label="Accepted Balance"
          >
            {this.displayBalance(accepted_balance_loading, accepted_balance)}
          </Descriptions.Item>
          <Descriptions.Item label="Actual Balance">
            {this.displayBalance(total_balance_loading, total_balance)}
          </Descriptions.Item>
          <Descriptions.Item label="Generated on">
            {this.displayDate(loading, generated_at)}
          </Descriptions.Item>
        </Descriptions>
        <Left>
          <RefreshTableButton
            className="marginBottom marginTopRefreshButton23px marginRigth"
            loading={this.state.loading}
            onClick={this.getFinanceInf}
          />
        </Left>
        <Left>
          <FieldInfo>{resources.labels.dateRange}</FieldInfo>
          <RangePickerF
            onChange={this.onDateChange}
            className="marginRigth marginBottom"
            value={[moment(new Date(dateFrom)), moment(new Date(dateTo))]}
            width={220}
            disabled={loading}
            dateMode
          />
        </Left>
        <Left>
          <FieldInfo>{resources.labels.timezone}</FieldInfo>
          <SelectOnChange
            onChange={this.onChangeSelect}
            className="marginBottom"
            value={this.state.time_zone}
            name="time_zone"
            items={timezones}
            loading={loading}
            width={160}
            showSearch
          />
        </Left>
        <Left>
          <FieldInfo className="marginLeft">
            {resources.labels.company}
          </FieldInfo>
          <SelectOnChange
            onChange={this.onChangeSelect}
            className="marginLeft marginBottom"
            name="selected_company"
            placeholder={resources.select.select}
            value={selected_company}
            items={companiesD}
            loading={loading}
            width={160}
            showSearch
          />
        </Left>
        <ProtectedComponent
          permission={UserPermissionType.SeeTransactionTypeSelect}
        >
          <Left>
            <FieldInfo className="marginLeft">
              {resources.labels.transactionType}
            </FieldInfo>
            <SelectOnChange
              onChange={this.onChangeSelect}
              className="marginLeft marginBottom"
              name="transaction_type"
              value={transaction_type}
              items={typeTestD}
              loading={loading}
              width={160}
              showSearch
            />
          </Left>
        </ProtectedComponent>
        <Left>
          <FieldInfo className="marginLeft">
            {resources.labels.transactionStatus}
          </FieldInfo>
          <SelectOnChange
            onChange={this.onChangeSelect}
            className="marginLeft marginBottom"
            name="transaction_status"
            value={transaction_status}
            items={statusTestD}
            loading={loading}
            width={160}
            allowClear
            showSearch
          />
        </Left>
        <Left>
          <FieldInfo className="marginLeft">{resources.labels.user}</FieldInfo>
          <SelectOnChange
            onChange={this.onChangeSelect}
            className="marginLeft marginBottom"
            name="id_user"
            value={id_user}
            items={filtered_usersD}
            loading={loading}
            width={150}
            allowClear
            showSearch
          />
        </Left>
        <Left>
          <Button
            className="marginLeft filterButton"
            disabled={loading || disable_filter_button}
            onClick={this.getFinanceInf}
            type="primary"
          >
            {resources.buttons.filter}
          </Button>
        </Left>
        <ClearBoth />
        {transactions_with_0_ammount > 0 && (
          <Button
            className={styles.incorrect_amount_button}
            onClick={this.filterTransactionsWithIncorrectAmount}
          >
            Found {transactions_with_0_ammount} transactions with $0 amount
          </Button>
        )}
        <FinanceAdminTable
          loading={loading}
          data={filtered_finance_list}
          usersD={usersD}
          timezoneOffset={this.state.time_zone - TIMEZONE_OFFSET}
          refreshList={this.getFinanceInf}
        />
      </TableContainer>
    );
  }

  filterTransactionsWithIncorrectAmount = () =>
    this.setState({
      filter_incorrect_amount: !this.state.filter_incorrect_amount,
    });

  onChangeSelect = (name: string, value?: number): void => {
    if (name === "selected_company") {
      this.setState({
        [name]: value,
        id_user: undefined,
        disable_filter_button: false,
      });
    } else {
      this.setState({
        [name]: value,
        disable_filter_button: false,
      });
    }
  };

  getFinanceInf = async () => {
    if (this.state.selected_company) {
      this.setState({ loading: true });

      this.setUrl();
      this.getBalance();
      this.getTotalBalance();

      const timezoneOffset = this.state.time_zone
        ? TIMEZONE_OFFSET - this.state.time_zone
        : 0;

      const data = {
        companyId: this.state.selected_company,
        id_user: this.state.id_user,
        status: this.state.transaction_status,
        type: this.state.transaction_type,
        dateFrom: moment
          .utc(this.state.dateFrom)
          .startOf("day")
          .add(timezoneOffset, "hours")
          .format("YYYY/MM/DD HH:mm:ss"),
        dateTo: moment
          .utc(this.state.dateTo)
          .endOf("day")
          .add(timezoneOffset, "hours")
          .format("YYYY/MM/DD HH:mm:ss"),
      };

      await getFinance(data)
        .then((response) => {
          const { operations } = response;
          const financeList: any[] = operations.map((item: any) => {
            return {
              ...item,
              key: item.id,
              disposition_at: moment
                .utc(item.disposition_at)
                .add(this.state.time_zone - TIMEZONE_OFFSET, "hours"),
              settled_at: moment
                .utc(item.settled_at)
                .add(this.state.time_zone - TIMEZONE_OFFSET, "hours"),
              product: findInDictionaryAndReturnValue(
                item.id_product,
                this.state.productsD
              ),
            };
          });

          this._isMounted &&
            this.setState({
              financeList,
              generated_at: new Date(Date.now()).toLocaleString(),
            });
        })
        .finally(
          () =>
            this._isMounted &&
            this.setState({
              loading: false,
              disable_filter_button: true,
            })
        );
    }
  };

  getUsers = async () =>
    await getUsersWithEmail().then((response) => {
      const filtered = response.filter((x: any) => x.id_company !== null);
      const usersD = filtered.map((item: any) => {
        return {
          id: item.id,
          name: `[${item.id}] ${item.email}`,
          id_company: item.id_company,
        };
      });

      this._isMounted && this.setState({ usersD });
    });

  setUrl = () => {
    let url = `/finance_admin`;

    if (this.state.selected_company)
      url = url + `/${this.state.selected_company}`;

    window.history.pushState("", "", url);
  };

  getCompaniesD = async (): Promise<void> =>
    this.setState({ companiesD: await getCompaniesListD() });

  getProductsD = async (): Promise<void> =>
    this.setState({ productsD: await getProductsD() });

  onDateChange = (date: any, dateTime: any) =>
    this.setState({
      dateFrom: moment(dateTime[0]).format("YYYY/MM/DD HH:mm:ss").toString(),
      dateTo: moment(dateTime[1]).format("YYYY/MM/DD HH:mm:ss").toString(),
      disable_filter_button: false,
    });

  getBalance = () => {
    this.setState({ accepted_balance_loading: true });
    const timezoneOffset = this.state.time_zone
      ? TIMEZONE_OFFSET - this.state.time_zone
      : 0;

    const data = {
      companyId: this.state.selected_company,
      id_user: this.state.id_user,
      status: this.state.transaction_status,
      type: this.state.transaction_type,
      dateFrom: moment
        .utc(this.state.dateFrom)
        .startOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
      dateTo: moment
        .utc(this.state.dateTo)
        .endOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
    };

    getBalance(data)
      .then((result) =>
        this.setState({
          accepted_balance:
            this.state.transaction_type === "withdrawal"
              ? result.withdrawals
              : result.deposits,
        })
      )
      .finally(() => this.setState({ accepted_balance_loading: false }));
  };

  getTotalBalance = () => {
    this.setState({ total_balance_loading: true });

    getTotalBalance(`/?companyId=${this.state.selected_company}`)
      .then((total_balance) => this.setState({ total_balance }))
      .finally(() => this.setState({ total_balance_loading: false }));
  };

  getTitle = (selected_company: number): string => {
    let title = "-";

    if (selected_company) {
      const company = this.state.companiesD.filter(
        (x) => x.id === Number(selected_company)
      );

      if (company.length > 0) title = company[0].name;
    }

    return title;
  };

  displayBalance = (loading: boolean, value: number) =>
    loading ? (
      <Icon type="sync" spin={loading} />
    ) : (
      <div
        style={{ color: value < 0 ? "red" : "green", fontWeight: "bold" }}
      >{`${(value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`}</div>
    );

  displayDate = (loading: boolean, value?: string) =>
    loading ? (
      <Icon type="sync" spin={loading} />
    ) : (
      <div style={{ color: "black", fontWeight: "bold" }}>{value}</div>
    );
}
