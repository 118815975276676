import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/SystemClassifiersDictionaryForm.module.scss';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createSystemClassifiers, updateSystemClassifiers } from '../../../../services/callCenter/SystemClassifiersService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { ISetLoading } from '../../../../common/interfaces/ISetLoading';
import { ISystemClassifiers } from '../interfaces/ISystemClassifiers';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class TemplateSystemClassifiersForm extends React.Component<ISystemClassifiers & IHideModal & IAddObjToArray & FormikProps<ISystemClassifiers>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    disabled={this.props.id ? true : false}
                    allowClear
                    boldLabel
                    required
                />
                <TextInputF
                    {...this.props}
                    name="id_external"
                    label={resources.labels.external}
                    labelTextAlignLeft
                    isNumeric
                    disabled={this.props.id ? true : false}
                    allowClear
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='status'
                    label={resources.labels.status}
                    dictionaryValues={this.removeUnrecognizeStatusFormStatusesD()}
                    allowClear={false}
                    labelTextAlignLeft
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='is_qualified'
                    label={resources.labels.is_qualified}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                    labelTextAlignLeft
                    boldLabel
                />
                <TextAreaF
                    {...this.props}
                    name='postback_comment'
                    label={resources.labels.comment}
                    labelTextAlignLeft
                    boldLabel
                    allowClear
                />
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };

    removeUnrecognizeStatusFormStatusesD = () => {
        const statusesD: IDictionaryValue[] = getDictionary('statusesD');
        const statuses = statusesD.filter(x => x.id !== 99);

        return statuses;
    }
}

const SystemClassifiersForm = withFormik<ISystemClassifiers & IAddObjToArray & ISetLoading, ISystemClassifiers>({
    mapPropsToValues: (props: ISystemClassifiers) => {
        return {
            id: props.id,
            name: props.name,
            status: props.status,
            id_external: props.id_external,
            is_qualified: Boolean(props.is_qualified) ? '1' : '0',
            postback_comment: props.postback_comment
        };
    },

    validate: (values: ISystemClassifiers) => {
        const errors: FormikErrors<ISystemClassifiers> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (!values.id_external) {
            errors.id_external = resources.validation.required;
        }
        else if (values.id_external.toString().length > 11) {
            errors.id_external = 'Max number 99999999999';
        }
        if (!values.status) {
            errors.status = resources.validation.required;
        }

        else if (values.postback_comment && values.postback_comment.length > 255) {
            errors.postback_comment = resources.validation.requiredMax255Characters;
        }
        
        return errors;
    },

    handleSubmit: (values: ISystemClassifiers, bag: FormikBag<ISystemClassifiers & IAddObjToArray & IHideModal & ISetLoading, ISystemClassifiers>) => {
        const object: any = {
            id: values.id,
            lib_id_external: values.id_external,
            lib_status: values.status,
            name: values.name,
            lib_is_qualified: Number(values.is_qualified),
            postback_comment: values.postback_comment ? values.postback_comment : ''
        }

        action(object, bag.props);
    }

})(TemplateSystemClassifiersForm);

export default SystemClassifiersForm;

const action = (obj: any, bag: IUpdateObjInArray & IAddObjToArray & IHideModal & ISetLoading) => {
    bag.setLoading!(true);

    if (obj.id) {
        updateSystemClassifiers(obj)
            .then(response => {
                const result: any = response;

                if (result.status === 409) {
                    openAlertNotification(result.message);
                }
                else {
                    const object = {
                        id: obj.id,
                        key: obj.id,
                        name: obj.name,
                        status: obj.lib_status,
                        id_external: obj.lib_id_external,
                        is_qualified: obj.lib_is_qualified,
                        postback_comment: obj.postback_comment,
                        updated_at: Date
                    };

                    bag.updateObjInArray!(object);

                    openSuccessNotification(resources.statement.system_classifier_updated);
                }

                bag.setLoading!(false);
            });
    }
    else {
        createSystemClassifiers(obj)
            .then((response) => {
                if (response) {
                    const object = {
                        id: response,
                        key: response,
                        name: obj.name,
                        status: obj.lib_status,
                        id_external: obj.lib_id_external,
                        is_qualified: obj.lib_is_qualified,
                        postback_comment: obj.postback_comment,
                        created_at: new Date(),
                        updated_at: new Date()
                    };

                    bag.addObjToArray!(object);

                    openSuccessNotification(resources.statement.system_classifier_created);
                }

                bag.setLoading!(false);
            });
    }

    bag.hideModal!();
}