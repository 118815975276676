import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.LibrariesGet);
const ccInstances = {
    name: "cc_instances",
    column: "id, name, country_id"
}
const ccCampaigns = {
    name: "cc_campaigns",
    column: "id, name, id_instance, id_external"
};

export const getListFromLibrary = (data: any) => axios.post(getUrl, { ...data })
    .then(response => { return response.data; })
    .catch(() => {
        openErrorNotification("M40 service Error");
        return [];
    });

export const getSelectedDictionaries = (data: any[]) => axios.post(getUrl, data)
    .then(response => { return response.data; })
    .catch(error => {
        console.log("M40 service Error");
        console.log(error);
        return [];
    });

export const getDictionaryNoAuth = (data: any) => axios.patch(getUrl, { ...data })
    .then(response => { return response.data; })
    .catch(error => {
        console.log("M40 service Error");
        console.log(error);
        return [];
    });

export const getCcInstancesD = () => axios.post(getUrl, { ...ccInstances })
    .then(response => { return response.data; })
    .catch(() => {
        openErrorNotification("M40 service Error");
        return [];
    });

export const getCcCampaignsD = () => axios.post(getUrl, { ...ccCampaigns })
    .then(response => { return response.data; })
    .catch(() => {
        openErrorNotification("M40 service Error");
        return [];
    });

export const getCcCampaignsNameAndIdInOneField = () => axios.post(getUrl, { ...ccCampaigns })
    .then(response => {
        const result: any[] = response.data;
        for (let i = 0, t = result.length; i < t; i++) {
            result[i].name = `[${result[i].id_external}] ${result[i].name}`;
        }

        return result;
    })
    .catch(() => {
        openErrorNotification("M40 service Error");
        return [];
    });