import axios from "axios";
import decode from "jwt-decode";
import {
  checkTokenStatus,
  getDifferentUserToken,
  refreshToken,
  refreshOriginalToken,
} from "../services/AuthenticationServices";
import { createAbsoluteUrl } from "./UrlCreator";
import {
  getOriginalToken,
  getToken,
  setOriginalToken,
  setToken,
} from "./LocalStorageHelpers";
import { history } from "../helpers/History";
import { IAppUserInfoModel } from "../models/IAppUserInfoModel";
import { ICommonState } from "../reducers/CommonReducer";
import { ITokenData } from "./interfaces/ITokenData";
import { openErrorNotification } from "./NotificationHelper";

export const getInitialState = (): ICommonState => {
  let initialState: ICommonState = {
    appUserInfo: {},
    blockUi: false,
    isAuthenticated: false,
    activeMenuItem: "",
  };

  const token = getToken();

  if (token === null) {
    initialState.blockUi = false;
  } else {
    initialState.blockUi = true;
  }

  initialState.isAuthenticated = false;

  return initialState;
};

export const checkIfTokenIsActive = (
  logoutAppUser: () => void,
  setAppUserInfo: (userInfo: IAppUserInfoModel) => void,
  setBlockUi: (blockUi: boolean) => void,
  setIsAuthenticated: (iaAuthenticated: boolean) => void
) => {
  if (getToken() && checkIfTokenExpired(logoutAppUser)) {
    setHeader();

    checkTokenStatus().then((response) => {
      if (response) {
        const token = getToken();
        const decoded: ITokenData = decode(token!);

        const aud: number[] = decoded.aud.map((item) => {
          return Number(item);
        });

        decoded.aud = aud;

        let appUserInfo: IAppUserInfoModel = {
          id: decoded.sub,
          permissions: decoded.aud,
          email: decoded.username,
          acc: decoded.acc,
        };

        setBlockUi(false);
        setIsAuthenticated(true);
        setAppUserInfo(appUserInfo);

        let local_url = localStorage.getItem("start_url");

        const url = local_url ? local_url : "/dashboard";

        history.push(url);
      } else {
        clearLocalAppUserInfo(logoutAppUser);
      }
    });
  } else {
    clearLocalAppUserInfo(logoutAppUser);
  }
};

const clearLocalAppUserInfo = (logoutAppUser: () => void) => {
  setHeader();

  // when token expired logout request gonna fail anyway
  // Clear session in DB
  // logout();

  // Clear storage
  localStorage.clear();

  // Clear store
  logoutAppUser();

  // Clear axios header
  setHeader();

  history.push(createAbsoluteUrl("sessionExpired"));
};

const checkIfTokenExpired = (logoutAppUser: () => void) => {
  const token = getToken();
  const decoded: ITokenData = decode(token!);

  if (decoded.exp < Date.now() / 1000) {
    clearLocalAppUserInfo(logoutAppUser);

    return false;
  }

  return true;
};

export const setHeader = () => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
};

export const refreshPageWithToken = (
  token: string,
  setAppUserInfo: (userInfo: IAppUserInfoModel) => void,
  logoutAppUser: () => void,
  appUserInfo: IAppUserInfoModel
) => {
  if (token) {
    const decoded: ITokenData = decode(token!);

    const aud: number[] = decoded.aud.map((item) => {
      return Number(item);
    });

    decoded.aud = aud;

    let updatedAppUserInfo: IAppUserInfoModel = {
      ...appUserInfo,
      id: decoded.sub,
      permissions: decoded.aud,
      email: decoded.username,
      acc: decoded.acc,
    };

    setAppUserInfo(updatedAppUserInfo);

    setHeader();

    console.log("Token updated!");
  } else {
    clearLocalAppUserInfo(logoutAppUser);
    history.push(`sessionExpired`);
  }
};

export const refreshTokenMethod = async (
  setAppUserInfo: (userInfo: IAppUserInfoModel) => void,
  logoutAppUser: () => void,
  appUserInfo: IAppUserInfoModel
) => {
  await refreshOriginalToken();
  setHeader();
  await refreshToken().then((token) => {
    refreshPageWithToken(token, setAppUserInfo, logoutAppUser, appUserInfo);
  });
};

export const switchToDifferentUser = async (userId: number) => {
  // get user token
  const userToken = await getDifferentUserToken(userId);
  if (!userToken) {
    return false;
  }

  // save original token in local storage
  const originalToken = getToken();
  if (!originalToken) {
    return false;
  }
  setOriginalToken(originalToken);

  // set new token in local storage and axios
  setToken(userToken);
  setHeader();

  history.push("/dashboard");
  return true;
};

export const switchToOriginalUser = async () => {
  // podmiana tokenu
  const originalToken = getOriginalToken();
  if (!originalToken) {
    openErrorNotification("Cannot get original token");
    return false;
  }

  // set new token in local storage and axios
  setToken(originalToken);
  setHeader();
  setOriginalToken("");
  return true;
};
