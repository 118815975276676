import React from 'react';
import styles from './ButtonCC.module.scss';

interface IProps {
    type: string;
    children?: React.ReactNode;
    width?: string | number;
    disabled?: boolean;
    onClick: (e:any) => void;
}

export const ButtonCC = (props: IProps) => {
    let buttonStyle = props.type === 'green' ? styles.button__green : styles.button__white;
    if (props.disabled) {
        buttonStyle = styles.button__disabled;
    }
    return (
        <button
            style={{ width: props.width ? props.width + 'px' : '' }}
            className={`${styles.button} ${buttonStyle} h6__semi`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}
