import moment, { Moment } from 'moment';

const requestDateFormat = "YYYY/MM/DD HH:mm:ss";

export const getDateNDaysAgo = (days: number): string => {
    return moment(new Date()).subtract(days, 'days').format(requestDateFormat).toString();
}

export const getDateTimeDayAgo = (): string => {
    return getDateNDaysAgo(1);
}

export const getDateTime7DayAgo = (): string => {
    return getDateNDaysAgo(7);
}

export const getCurrentDateTime = (): string => {
    return moment(new Date()).format(requestDateFormat).toString();
}

export const convertNumberToDateTimeString = (number: number, timezone?: number): string => {
    if (number === null || number === 0) {
        return '-';
    }
    else {
        return moment(new Date(number)).utcOffset(timezone ? timezone : 0).format(requestDateFormat);
    }
}

export const convertNumberToDateTimeString2 = (number: number, timezone?: number): string => {
    if (number === null || number === 0) {
        return '-';
    }
    else {
        console.log('NUMBER:', number)
        return moment(new Date(number)).utcOffset(timezone ? timezone : 0).format(requestDateFormat);
    }
}

export const getDateTimeDayAgoIfExists = (date: string[] | undefined): string => {
    return date ? moment(new Date(date[0])).format(requestDateFormat).toString() : getDateTimeDayAgo();
}

export const getCurrentDateTimeIfExists = (date: string[] | undefined): string => {
    return date ? moment(new Date(date[1])).format(requestDateFormat).toString() : getCurrentDateTime();
}

export const getDateTimeIfExistsOrDayAgo = (date: string | undefined): string => {
    return date ? moment(new Date(date)).format(requestDateFormat).toString() : getDateTimeDayAgo();
}

export const getDateTimeIfExistsOrCurrent = (date: string | undefined): string => {
    return date ? moment(new Date(date)).format(requestDateFormat).toString() : getCurrentDateTime();
}

export const getTime = (date: string | undefined): string => {
    return date ? moment(new Date(date)).format("HH:mm:ss").toString() : '';
}

// Metoda sprawdza czy upłyneła konkretna liczba minut pomiędzy dwoma dateTime
// start - data początkowa
// end - data koncowa
// minutesToCheck - sprawdzana ilość minut
export const checkHoursBetweenDates = (start: number, end: number, minutesToCheck: number): boolean => {
    var hours = moment(new Date(start)).diff(end, 'hours');

    return Number(hours) > minutesToCheck ? true : false;
}

export const urlStringToDate = (dateString: string): Moment => {
    return moment(dateString, requestDateFormat);
}

export const dateToUrlString = (date: Moment): string => {
    return date.format(requestDateFormat);
}