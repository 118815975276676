import React from 'react';
import styles from './styles/ConfirmForm.module.scss';
import { Button } from 'antd';
import { IHideModal } from '../../common/interfaces/IHideModal';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resources } from '../../common/Resources';

interface IConfirmCallback {
    title?: string;
    onConfirm: () => Promise<any>;
}

export class ConfirmForm extends React.Component<IConfirmCallback & IHideModal> {
    _isMounted = false;
    
    state = {
        loading: false,
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }
    
    onConfirm = () => {
        this._isMounted && this.setState({ loading: true });
        this.props.onConfirm()
        .then(() => this.props.hideModal && this.props.hideModal())
        .catch((errMsg) => openErrorNotification(errMsg))
        .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    render() {
        return (
            <>
                <h2>{this.props.title}</h2>
                <h4>{resources.labels.are_you_sure}</h4>
                <div className={styles.formFooter}>
                        <Button
                            className={styles.cancelBtn}
                            disabled={this.state.loading}
                            onClick={() => this.props.hideModal && this.props.hideModal()}
                        >
                            {resources.buttons.cancel}
                        </Button>
                        <Button
                            className={styles.confirmBtn}
                            disabled={this.state.loading}
                            onClick={this.onConfirm}
                        >
                            {resources.buttons.confirm}
                        </Button>
                </div>
            </>
        );
    }
}