import { FormikErrors } from 'formik';
import { IParam } from '../views/_common/postbacksSettings/postbackUrl/interfaces/IPostbackUrlForm';
import { resources } from '../common/Resources';

export const validateProtocol = (protocol: string): string | null => {
    if (protocol === '') {
        return resources.validation.required;
    }
    return null;
}

export const validatePath = (value: string): string | null => {
    const reg = /^[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

    if (!reg.test(value)) {
        return resources.validation.invalidDomain;
    } else {
        return null;
    }
}

export const validateParams = (parameters: IParam[]): FormikErrors<IParam>[] | null => {
    const errors: FormikErrors<IParam>[] = [];
    const paramsNames = new Set();
    for (let paramIdx in parameters) {
        const param: IParam = parameters[paramIdx];
        const paramError = validateParam(param);
        errors.push(paramError);
        if (paramsNames.has(param.parameterName)) {
            errors[errors.length - 1].parameterName = `${resources.validation.duplicatedParam} "${param.parameterName}"`;
        }
        else {
            paramsNames.add(param.parameterName);
        }
    }
    return errors.some(errorObject => Object.keys(errorObject).length > 0) ? errors : null;
}

const validateParam = (parameter: IParam): FormikErrors<IParam> => {
    const error: FormikErrors<IParam> = {};
    const paramNameReg = /^[(a-zA-Z0-9)+_?(a-zA-Z0-9)*]+$/;
    const paramValueReg = /^{?[(a-zA-Z0-9)+_?(a-zA-Z0-9)*]+}?$/;
    if (!paramNameReg.test(parameter.parameterName.trim())) {
        error.parameterName = resources.validation.invalidParamName;
    }
    else if (!paramValueReg.test(parameter.value.trim())) {
        error.value = resources.validation.invalidParamValue;
    }
    return error;
}