import moment from 'moment';
import React from 'react';
import styles from './styles/LeadModal.module.scss';
import { findInDictionaryAndReturnValue, getDictionary } from '../../../../helpers/DisctionariesHelper';
import { generateName } from '../../../../helpers/UserHelper';
import { getLeadById } from '../../../../services/m56_leads/LeadsService';
import { getPartnersByIds } from '../../../../services/m29_users_management/GeneralUserService';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { ILeadsContent } from '../../../leads/interfaces/ILeadsContent';
import { Modal, Spin } from 'antd';
import { resources } from '../../../../common/Resources';

interface ILeadModal {
    leadId: number;
    product?: string;
    timezoneOffset: number;
}

export class LeadModal extends React.Component<ILeadModal> {
    
    _isMounted = false;
    state = {
        visible: false,
        loading: false,
        lead: undefined as ILeadsContent | undefined,
        partners: [] as IDictionaryValue[],
    };

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    showModal = async () => {
        this._isMounted && this.setState({
            visible: true,
            loading: true,
        });
        let lead;
        if (this.props.leadId) {
            lead = await getLeadById(this.props.leadId);
        }
        if (lead) {
            const response = await getPartnersByIds([lead.id_partner]);
            const usersD = response.map((item: any) => {
                return {
                    id: item.id,
                    name: generateName(item.name, item.second_name),
                }
            });

            this._isMounted && this.setState({
                lead,
                partners: usersD,
                loading: false,
            });
        }
        else {
            this._isMounted && this.setState({
                loading: false,
            });
        }
    };

    render() {
        if (!this.props.leadId) {
            return (
                <div>
                    {'-'}
                </div>
            )
        }
        let modalContent = <Spin className={styles.spinner}/>;
        if (!this.state.loading) {
            if (this.state.lead) {
                const countriesD = getDictionary('countriesNameD');
                const statusesD = getDictionary('statusesD');
                modalContent = (
                    <div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.leadId}</h3>
                            <h4>{this.state.lead.id}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.status}</h3>
                            <h4>{findInDictionaryAndReturnValue(this.state.lead.id_lead_status, statusesD)}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.product}</h3>
                            <h4>{this.props.product}</h4>
                        </div>
                            <div className={styles.infoRow}>
                        <h3>{resources.columnTitle.partner}</h3>
                            <h4>{findInDictionaryAndReturnValue(this.state.lead.id_partner, this.state.partners)}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.country}</h3>
                            <h4>{findInDictionaryAndReturnValue(this.state.lead.country_id, countriesD)}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.payout_scheme}</h3>
                            <h4 style={{ textTransform: 'uppercase' }}>{this.state.lead.payout_scheme}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.click_id}</h3>
                            <h4>{this.state.lead.api_click_id}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.pub_id}</h3>
                            <h4>{this.state.lead.api_pub_id}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.created}</h3>
                            <h4>{moment.utc(this.state.lead.created_at).add(this.props.timezoneOffset, 'hours').format("YYYY/MM/DD HH:mm:ss")}</h4>
                        </div>
                        <div className={styles.infoRow}>
                            <h3>{resources.columnTitle.accepted}</h3>
                            <h4>{moment.utc(this.state.lead.date_approved_at).add(this.props.timezoneOffset, 'hours').format("YYYY/MM/DD HH:mm:ss")}</h4>
                        </div>
                    </div>)
            }
            else {
                modalContent = <h2>{resources.statement.noDataToDisplay}</h2>;
            }
        }
        return (
            <>
                <div className={styles.tag} onClick={this.showModal}>{this.props.leadId}</div>
                <Modal
                    title={resources.tabPane.details}
                    footer={null}
                    visible={this.state.visible}
                    onCancel={() => this.setState({
                        visible: false,
                    })}
                >
                    {modalContent}
                </Modal>
            </>
        );
    }
}
