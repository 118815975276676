import React from "react";
import { Icon, Spin, Table, Tooltip, Typography } from "antd";
import { DataTable } from "../../../components/others/data/DataTable";
import { resources } from "../../../common/Resources";
import { spanWithTitle } from "../../../components/others/SpanWithTitle";
import { convertNumberToDateTimeString } from "../../../helpers/DateHelpers";
import {
  findInDictionaryAndReturnValueWithSpin,
  getDictionary,
} from "../../../helpers/DisctionariesHelper";
import { ExpandedPostbackComponent } from "../components/expandedPostback/content/ExpandenPostbackComponent";
import { hasPermission } from "../../../authorizations/AuthorizationLogic";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";
import { JsonParams } from "../components/jsonParams/JsonParams";
import { displayIconStatus } from "../../../helpers/IconsGeneratorHelper";

const antIcon = <Icon type="loading" style={{ fontSize: 12 }} spin />;

const { Column } = Table;
const { Paragraph } = Typography;

const TIMEZONE_OFFSET = 13;

export class LeadsTable extends DataTable {
  render() {
    let columns = [
      <Column
        title={resources.columnTitle.id}
        dataIndex="id"
        key="id"
        width={170}
        align="center"
        sorter={(a, b) => a.id - b.id}
        {...this.getColumnSearchProps("id")}
      />,
      <Column
        title={resources.columnTitle.partner}
        key="id_partner"
        width={350}
        align="center"
        sorter={(a, b) => a.id_partner - b.id_partner}
        render={(record) =>
          findInDictionaryAndReturnValueWithSpin(
            record.id_partner,
            this.props.partnersD
          )
        }
      />,
      <Column
        title={resources.columnTitle.product}
        key="id_product"
        width={250}
        align="center"
        sorter={(a, b) => a.id_product - b.id_product}
        render={(record) =>
          findInDictionaryAndReturnValueWithSpin(
            record.id_product,
            this.props.productsD
          )
        }
      />,
      <Column
        title={resources.columnTitle.payout}
        key="payout_actual_local_usd"
        width={90}
        align="center"
        sorter={(a, b) => a.payout_actual_local_usd - b.payout_actual_local_usd}
        render={(record) =>
          record.payout_actual_local_usd
            ? Number(record.payout_actual_local_usd) / 100
            : "-"
        }
      />,
      <Column
        title={resources.columnTitle.country}
        key="country_id"
        width={120}
        align="center"
        sorter={(a, b) => a.country_id - b.country_id}
        render={(record) =>
          findInDictionaryAndReturnValueWithSpin(
            record.country_id,
            getDictionary("countriesNameD")
          )
        }
      />,
      <Column
        title={resources.columnTitle.status}
        key="id_lead_status"
        width={140}
        align="center"
        filters={getDictionary("statusesF")}
        onFilter={(value, record) => record.id_lead_status === value}
        sorter={(a, b) => a.id_lead_status - b.id_lead_status}
        render={(record) =>
          (record.id_lead_status || record.id_lead_status === 0) &&
          findInDictionaryAndReturnValueWithSpin(
            record.id_lead_status,
            getDictionary("statusesD")
          )
        }
      />,
    ];

    hasPermission(UserPermissionType.SeeCCClassifiersColumn) &&
      columns.push(
        <Column
          title={resources.columnTitle.classifier}
          key="cc_classifiers_id"
          width={200}
          align="center"
          sorter={(a, b) => a.cc_classifiers_id - b.cc_classifiers_id}
          render={(record) =>
            record.cc_classifiers_id
              ? findInDictionaryAndReturnValueWithSpin(
                record.cc_classifiers_id,
                this.props.ccClassifiersD
              )
              : "-"
          }
        />
      );

    hasPermission(UserPermissionType.SeeCommentColumn) &&
      columns.push(
        <Column
          title={resources.columnTitle.comment}
          key="comment"
          width={200}
          align="center"
          sorter={(a, b) => a.cc_classifiers_id - b.cc_classifiers_id}
          render={(record) => {
            const { ccCommentsD, ccClassifiersD } = this.props;

            if (ccCommentsD.length === 0 || ccClassifiersD.length === 0) {
              return <Spin indicator={antIcon} size="small" />;
            }

            const predicateToGetComment = (c) =>
              c.id === record.cc_classifiers_id;
            const comment = ccCommentsD.find(predicateToGetComment);
            const classifier = ccClassifiersD.find(predicateToGetComment);

            if (comment?.name === 'null') {
              return classifier?.name || '-'
            }

            return comment?.name || classifier?.name || "-";
          }}
        />
      );

    hasPermission(UserPermissionType.SeeCCClassifiersEndColumn) &&
      columns.push(
        <Column
          title={resources.columnTitle.classifier_end}
          key="cc_classifiers_id_end"
          width={200}
          align="center"
          sorter={(a, b) => a.cc_classifiers_id_end - b.cc_classifiers_id_end}
          render={(record) =>
            record.cc_classifiers_id_end
              ? findInDictionaryAndReturnValueWithSpin(
                record.cc_classifiers_id_end,
                this.props.ccClassifiersD
              )
              : "-"
          }
        />
      );

    hasPermission(UserPermissionType.SeeQualifiedStatus) &&
      columns.push(
        <Column
          title={resources.columnTitle.is_qualified}
          key="is_qualified"
          width={150}
          align="center"
          sorter={(a, b) => a.is_qualified - b.is_qualified}
          render={(record) => displayIconStatus(record.is_qualified)}
        />
      );

    hasPermission(UserPermissionType.SeeContactedStatus) &&
      columns.push(
        <Column
          title={resources.columnTitle.is_contacted}
          key="is_contacted"
          width={150}
          align="center"
          sorter={(a, b) => a.is_contacted - b.is_contacted}
          render={(record) => displayIconStatus(record.is_contacted)}
        />
      );

    columns = [
      ...columns,
      <Column
        title={spanWithTitle(
          resources.columnTitle.payout_scheme,
          resources.columnTitle.payout_scheme_short
        )}
        key="payout_scheme"
        width={140}
        align="center"
        sorter={(a, b) => a.payout_scheme - b.payout_scheme}
        render={(record) => (
          <div style={{ textTransform: "uppercase" }}>
            {record.payout_scheme}
          </div>
        )}
      />,
      <Column
        title={resources.columnTitle.phone_origin}
        dataIndex="client_phone1_original"
        key="client_phone1_original"
        width={140}
        align="center"
        sorter={(a, b) => a.client_phone1_original - b.client_phone1_original}
        {...this.getColumnSearchProps("client_phone1_original")}
      />,
      <Column
        title={resources.columnTitle.phone}
        dataIndex="client_phone1"
        key="client_phone1"
        width={140}
        align="center"
        sorter={(a, b) => a.client_phone1 - b.client_phone1}
        {...this.getColumnSearchProps("client_phone1")}
      />,
      <Column
        title={resources.columnTitle.click_id}
        key="api_click_id"
        width={220}
        align="center"
        sorter={(a, b) =>
          (a.api_click_id ? a.api_click_id : "").localeCompare(
            b.api_click_id ? b.api_click_id : ""
          )
        }
        {...this.getColumnSearchProps("api_click_id")}
        render={(record) => (
          <Tooltip title={record.api_click_id}>
            {record.api_click_id !== null && record.api_click_id.length > 24
              ? record.api_click_id.substring(0, 20) + "..."
              : record.api_click_id}
          </Tooltip>
        )}
      />,
      <Column
        title={resources.columnTitle.pub_id}
        dataIndex="api_pub_id"
        key="api_pub_id"
        width={160}
        align="center"
        sorter={(a, b) =>
          (a.api_pub_id ? a.api_pub_id : "").localeCompare(
            b.api_pub_id ? b.api_pub_id : ""
          )
        }
        {...this.getColumnSearchProps("api_pub_id")}
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.created,
          resources.columnTitle.created_short
        )}
        key="created_at"
        width={160}
        align="center"
        sorter={(a, b) => a.created_at - b.created_at}
        render={(record) =>
          convertNumberToDateTimeString(
            record.created_at,
            this.props.timezone - TIMEZONE_OFFSET
          )
        }
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.approvedAt,
          resources.columnTitle.approvedAt_short
        )}
        key="date_approved_at"
        width={160}
        align="center"
        sorter={(a, b) => a.date_approved_at - b.date_approved_at}
        render={(record) =>
          record.date_approved_at === null
            ? null
            : convertNumberToDateTimeString(
              record.date_approved_at,
              this.props.timezone - TIMEZONE_OFFSET
            )
        }
      />,
      <Column
        title="IP"
        dataIndex="client_ip"
        key="client_ip"
        width={140}
        align="center"
        sorter={(a, b) => a.client_ip - b.client_ip}
        {...this.getColumnSearchProps("client_ip")}
      />,
      <Column
        title="URL"
        key="lp_url"
        width={250}
        align="center"
        sorter={(a, b) => a.lp_url - b.lp_url}
        {...this.getColumnSearchProps("lp_url")}
        render={(record) =>
          record.lp_url && (
            <Tooltip
              title={
                <Paragraph
                  style={{ color: "white", textAlign: "center" }}
                  copyable
                >
                  {record.lp_url}
                </Paragraph>
              }
            >
              <label className="marginLeft">
                {record.lp_url.length > 28
                  ? record.lp_url.substring(0, 25) + "..."
                  : record.lp_url}
              </label>
            </Tooltip>
          )
        }
      />,
      <Column
        title={resources.columnTitle.params}
        dataIndex="api_params_json"
        key="api_params_json"
        align="center"
        width={300}
        render={(record) => <JsonParams jsonString={record} />}
      />,
    ];

    let sortedData = this.props.data
      ? this.props.data.sort((a, b) => (a.id < b.id ? 1 : -1))
      : [];

    sortedData = sortedData.map((item) => {
      return {
        ...item,
        key: item.id,
        created_at: new Date(item.created_at).getTime(),
        date_approved_at: new Date(item.date_approved_at).getTime(),
      };
    });

    return (
      <DataTable
        tableName="LeadsTable"
        columns={columns}
        data={sortedData}
        loading={this.props.loading}
        scrollY={1000}
        rowSelection
        actions={this.props.leadActions}
        defaultPageSize={200}
        expandRowByClick={false}
        expandedRowRender={(record) => (
          <ExpandedPostbackComponent id_lead={record.id} />
        )}
        expandTooltip={{
          hide: resources.tooltips.hide_postbacks,
          show: "Show postbacks",
        }}
      />
    );
  }
}
