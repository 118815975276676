export const generateName = (name?: any, second_name?: any) => {
    if (name && second_name) {
        if (name.includes(second_name))
            return name;
        else if (second_name === 'null') {
            return name;
        }
        else
            return `${name} ${second_name}`;
    }
    else if (name && !second_name)
        return name;
    else
        return second_name;
}