import React from 'react';
import styles from '../styles/PostbackUrlForm.module.scss';
import TextInputF from '../../../../../components/templateForm/form/textInput/TextInputF';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getUrlInformations } from './BasicUrlForm';
import {
    IAdvancedUrlFormValues,
    IChangeCurrentPostbackUrl,
    IChangePostbackFormMode,
    IPostbackUrlForm
    } from '../interfaces/IPostbackUrlForm';
import { IHideModal } from '../../../../../common/interfaces/IHideModal';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../../../helpers/NotificationHelper';
import { resources } from '../../../../../common/Resources';
import { validateParams, validatePath, validateProtocol } from '../../../../../helpers/PostbackUrlValidation';

let error = undefined as string | undefined;

class PostbackUrlContentTemplate extends React.Component<IPostbackUrlForm &
    IChangePostbackFormMode &
    IChangeCurrentPostbackUrl &
    FormikProps<IAdvancedUrlFormValues>> {

    componentDidMount = () => {
        if (this.props.wasChanged) {
            const values = { ...this.props.values };
            values.random = Math.random();
            this.props.setValues(values);
        }
    }

    render() {
        const submitButtonStyle = this.props.isValid ? styles.green : styles.gray;
        const isUrlValid = validURL(this.props.values.postbackUrl);
        return (
            <div className={styles.grid}>
                <h3 className={`h3 ${styles.title}`}>
                    {this.props.postbackId ? resources.offersDetails.updatePostback : resources.offersDetails.createPostback}
                </h3>
                {!this.props.postbackId ? <p className={`h6 ${styles.subtitle}`}>{resources.offersDetails.fillInTheData}</p> : null}
                <Form>
                    <section className={styles.grid}>
                        <div className={`${styles.row} ${styles.thirdRowGrid}`}>
                            <div className={styles.fakeFormItem}>
                                <h6 className={`h6 ${styles.label}`}>
                                    {resources.offersDetails.postbackURLPreview}
                                </h6>
                                <TextInputF
                                    {...this.props}
                                    name='postbackUrl'
                                    placeholder='example.com'
                                />
                            </div>
                        </div>
                        <div className={styles.customError}>
                            {error}
                        </div>
                        <div className={styles.btnContainer}>
                            <button
                                type='submit'
                                className={`${styles.submitBtn} ${submitButtonStyle}`}
                                disabled={!this.props.isValid}
                            >
                                {resources.offersDetails.save}
                            </button>
                            <div
                                className={`${styles.changeModeBtn} ${(isUrlValid || this.props.postbackUrl === '') ? styles.green : styles.gray}`}
                                onClick={() => (isUrlValid || this.props.postbackUrl === '') && this.props.onModeChange()}
                            >
                                {resources.offersDetails.basic}
                            </div>
                        </div>
                    </section>
                </Form>
            </div >
        );
    };
}

const AdvancedUrlForm = withFormik<IPostbackUrlForm & IChangePostbackFormMode & IChangeCurrentPostbackUrl, IAdvancedUrlFormValues>({
    enableReinitialize: false,

    mapPropsToValues: (props: IPostbackUrlForm): IAdvancedUrlFormValues => {
        return {
            postbackUrl: props.postbackUrl,
            random: Math.random(),
        };
    },

    validate: (values: IAdvancedUrlFormValues, props: IChangeCurrentPostbackUrl): FormikErrors<IAdvancedUrlFormValues> => {
        let errors: FormikErrors<IAdvancedUrlFormValues> = {};
        // :FIXME validation temporarily disabled
        const urlInfo = getUrlInformations(values.postbackUrl);
        const protocolError = validateProtocol(urlInfo.protocol);
        const pathError = validatePath(urlInfo.host);
        const paramsError = validateParams(urlInfo.parameters);
        if (urlInfo.host === '') {
            return errors;
        }
        if (!validURL(values.postbackUrl) || protocolError || pathError || paramsError) {
            // errors.postbackUrl = resources.validation.invalidUrl;
            error = resources.validation.customInvalidUrlError;
        }
        else {
            error = undefined;
        }
        props.onPostbackUrlChange(values.postbackUrl);
        return errors;
    },

    handleSubmit: (values: IAdvancedUrlFormValues, bag: FormikBag<IPostbackUrlForm & IHideModal, IAdvancedUrlFormValues>) => {
        if (bag.props.onUpsert && bag.props.status) {
            let url = '';
            const urlInfo = getUrlInformations(values.postbackUrl);
            if (urlInfo.host !== '') {
                url = values.postbackUrl;
            }
            bag.props.onUpsert(url, bag.props.status, bag.props.postbackId)
                .then(() => {
                    bag.props.hideModal && bag.props.hideModal();
                    openSuccessNotification(resources.offersDetails.postbackSavedSuccessfully);
                })
                .catch((err: any) => {
                    const { status } = err.response;
                    if (status === 400) {
                        // postback saved with error
                        const { message } = err.response.data;
                        bag.props.hideModal && bag.props.hideModal();
                        openAlertNotification(`${resources.offersDetails.postbackSavedWithWarning}. CODE:${message}`);
                    }
                    else if (status === 403) {
                        // unauthorized
                        openErrorNotification(resources.offersDetails.postbackNotSaved);
                    }
                    else if (status === 422) {
                        // postback not saved
                        openErrorNotification(resources.offersDetails.postbackNotSaved);
                    }
                    else {
                        openErrorNotification(err.response.message);
                    }
                });
        }
    }

})(PostbackUrlContentTemplate);

export default AdvancedUrlForm;

const validURL = (url: string): boolean => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'  // OR ip (v4) address
        , 'i'
    );

    return !!pattern.test(url);
}