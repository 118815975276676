import React from 'react';
import { Table, Icon, Tooltip, Button, Modal } from 'antd';
import { DataTable } from '../../../components/others/data/DataTable';
import { resources } from '../../../common/Resources';
import moment from 'moment';
import { spanWithTitle } from '../../../components/others/SpanWithTitle';
import { convertNumberToDateTimeString } from '../../../helpers/DateHelpers';
import { findInDictionaryAndReturnValueWithSpin, getDictionary } from '../../../helpers/DisctionariesHelper';
import { displayIconStatusWithTooltip } from '../../../helpers/IconsGeneratorHelper';
import { convertBooleanYesOrNoReactElement } from '../../../helpers/BooleanHelper';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../components/authorizations/protectedComponents/ProtectedComponent';
import { getVisibleFilters, getYesOrNoFilters } from '../../../common/dictionaries/Filters';
import PageForm from '../../_common/components/pages/forms/PageForm';

const { Column } = Table;
const { confirm } = Modal;

export class PagesTable extends DataTable {
    showConfirm = (product_id, id_page, name) => {
        confirm({
            title: 'Are you sure?',
            content: `You want to delete ${name}? `,
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            onOk: () => { this.props.deletePage(product_id, id_page); },
            onCancel() { },
        });
    }

    delete = (product_id, id_page) => {
        this.props.deletePage(Number(product_id), Number(id_page));
    }

    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='70px'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                width='350px'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.country}
                key='country_id'
                width='120px'
                align='center'
                sorter={(a, b) => a.country_id - b.country_id}
                render={record => (
                    findInDictionaryAndReturnValueWithSpin(record.country_id, getDictionary('countriesNameD'))
                )}
            />,
            <Column
                title={resources.columnTitle.landing}
                key='is_prelanding'
                align='center'
                width='105px'
                sorter={(a, b) => a.is_prelanding - b.is_prelanding}
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_prelanding === value}
                render={record => (
                    convertBooleanYesOrNoReactElement(!record.is_prelanding)
                )}
            />,
            <Column
                title={resources.columnTitle.preview}
                key='preview'
                align='center'
                width='80px'
                render={record => { return <a href={record.url}>View&nbsp;<Icon type="link" /></a> }}
            />,
            <Column
                title={resources.columnTitle.product}
                key='product_id'
                width='250px'
                align='center'
                sorter={(a, b) => a.product_id - b.product_id}
                render={record => (
                    findInDictionaryAndReturnValueWithSpin(record.product_id, this.props.productsD)
                )}
            />,
            <Column
                title={resources.columnTitle.is_visible}
                key='is_visible'
                align='center'
                width='100px'
                sorter={(a, b) => a.is_visible - b.is_visible}
                filters={getVisibleFilters()}
                onFilter={(value, record) => record.is_visible === value}
                render={record => (
                    displayIconStatusWithTooltip(record.is_visible)
                )}
            />,
            <Column
                title={resources.columnTitle.is_top}
                key='is_top'
                align='center'
                width='80px'
                sorter={(a, b) => a.is_top - b.is_top}
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_top === value}
                render={record => (
                    convertBooleanYesOrNoReactElement(record.is_top)
                )}
            />,
            <Column
                title={resources.columnTitle.is_mobile}
                key='is_mobile'
                align='center'
                width='100px'
                sorter={(a, b) => a.is_mobile - b.is_mobile}
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_mobile === value}
                render={record => (
                    convertBooleanYesOrNoReactElement(record.is_mobile)
                )}
            />,
            <Column
                title={resources.columnTitle.is_desktop}
                key='is_desktop'
                align='center'
                width='110px'
                sorter={(a, b) => a.is_desktop - b.is_desktop}
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_desktop === value}
                render={record => (
                    convertBooleanYesOrNoReactElement(record.is_desktop)
                )}
            />,
            < Column
                title={resources.columnTitle.deleted_at}
                key='deleted_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.deleted_at).diff(moment.utc(b.deleted_at))}
                render={record => (
                    convertNumberToDateTimeString(record.deleted_at)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={record => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={record => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                align='center'
                width='75px'
                fixed='right'
                render={record => {
                    return (
                        <>
                            <ProtectedModal permission={UserPermissionType.EditPageModalButton}>
                                <Tooltip placement="rightTop" title={resources.tooltips.editPage}>
                                    <InformationModalDialog
                                        modalTitle={resources.modalTitle.editPage}
                                        icon={"form"}
                                        withHideMethod={true}
                                        disabled={record.deleted_at}
                                    >
                                        <PageForm
                                            {...record}
                                            showSelectProduct
                                            product_id={record.product_id}
                                            updatePage={this.props.updatePage}
                                            productsD={this.props.productsD}
                                        />
                                    </InformationModalDialog>
                                </Tooltip>
                            </ProtectedModal>
                            <ProtectedComponent permission={UserPermissionType.DeletePageButton}>
                                <Tooltip
                                    placement='rightBottom'
                                    title={resources.tooltips.deletePage}
                                >
                                    <Button
                                        value={record.id}
                                        onClick={() => this.showConfirm(record.product_id, record.id, record.name)}
                                        disabled={record.deleted_at}
                                        icon='delete'
                                    />
                                </Tooltip>
                            </ProtectedComponent>
                        </>
                    )
                }} />
        ];

        let sortedData = this.props.data ? this.props.data.sort((a, b) => (a.id < b.id ? 1 : -1)) : [];

        return (
            <DataTable
                tableName='PagesTable'
                columns={columns}
                data={sortedData}
                loading={this.props.loading}
            />
        )
    }
}