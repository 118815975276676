import axios from 'axios';
import { openErrorNotification } from '../helpers/NotificationHelper';
import { ResourceDef } from '../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;

export const createInstance = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => {
        return response.data.insertId;
    })
    .catch(() => {
        openErrorNotification(' createInstance');
        return undefined;
    });