import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SystemClassifiersForm from '../forms/SystemClassifiersForm';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getSystemClassifiers } from '../../../../services/callCenter/SystemClassifiersService';
import { ISystemClassifiers } from '../interfaces/ISystemClassifiers';
import { megaUpdate } from '../../../../services/callCenter/MegaUpdateService';
import { MegaUpdateButton } from '../../../../components/megaUpdateButton/MegaUpdateButton';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { SearchElements } from '../../../../components/common/searchElements/SearchElements';
import { SystemClassifiersTable } from '../table/SystemClassifiersTable';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class SystemClassifiersContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        search: undefined,
        systemClassifiersList: Array<ISystemClassifiers>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getSystemClassifiers();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const {
            systemClassifiersList,
            loading,
            search
        } = this.state;

        let filteredData = systemClassifiersList;

        if (search)
            filteredData = filteredData.filter(x =>
                (x.name ? x.name.toLocaleLowerCase() : "").includes(search!)
            );

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.system_classifiers} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={loading}
                    onClick={this.getSystemClassifiers}
                />
                <ProtectedComponent permission={UserPermissionType.SeeMegaUploadButton}>
                    <Right>
                        <MegaUpdateButton
                            buttonTitle='Mega upload'
                            className='marginBottom marginLeft'
                            loading={this.state.loading}
                            onClick={this.megaUpdate}
                        />
                    </Right>
                </ProtectedComponent>
                <ProtectedModal permission={UserPermissionType.AddNewSystemClassifierModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewSystemClassifier}
                            buttonTitle={resources.modalButtonsTitle.addNewSystemClassifier}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewSystemClassifier}
                            icon={"plus"}
                            withHideMethod={true}
                            buttonClassName='marginBottom'
                            disabled={loading}
                        >
                            <SystemClassifiersForm
                                addObjToArray={this.addObjToArray}
                                setLoading={this.setLoading}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <SearchElements
                        className='marginRigth'
                        onSearch={this.onGlobalSearch}
                        loading={loading}
                    />
                </Right>
                <Right>
                    <FoundRowsNumber
                        className='marginRigth'
                        count={count}
                    />
                </Right>
                <SystemClassifiersTable
                    loading={loading}
                    data={filteredData}
                    updateObjInArray={this.updateObjInArray}
                    setLoading={this.setLoading}
                />
            </TableContainer>
        )
    };

    getSystemClassifiers = () => {
        this.setState({ loading: true });

        getSystemClassifiers()
            .then(response => {
                const list = response.map((item: ISystemClassifiers) => {
                    return { ...item, key: item.id }
                });

                this._isMounted && this.setState({ systemClassifiersList: list });
            })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    updateObjInArray = (systemClassifier: ISystemClassifiers) => {
        const { systemClassifiersList } = this.state;

        const index = systemClassifiersList.findIndex(x => Number(x.id) === Number(systemClassifier.id));

        systemClassifiersList[index].name = systemClassifier.name;
        systemClassifiersList[index].status = systemClassifier.status;
        systemClassifiersList[index].id_external = systemClassifier.id_external;
        systemClassifiersList[index].is_qualified = systemClassifier.is_qualified;
        systemClassifiersList[index].updated_at = systemClassifier.updated_at;
        systemClassifiersList[index].postback_comment = systemClassifier.postback_comment;

        this.setState({ systemClassifiersList });
    }

    addObjToArray = (systemClassifier: ISystemClassifiers) =>
        this.setState({ systemClassifiersList: [...this.state.systemClassifiersList, systemClassifier] });

    setLoading = (loading: boolean) =>
        this.setState({ loading });

    onGlobalSearch = (event: any) =>
        this.setState({ [event.target.name]: event.target.value.toLocaleLowerCase() });

    megaUpdate = async () => {
        this.setState({ loading: true });

        await megaUpdate()
            .then(response => { response && this.getSystemClassifiers(); });
    }
}