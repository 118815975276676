import React from 'react';
import styles from './styles/SupportForm.module.scss';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IAppUserInfoModel } from '../../../models/IAppUserInfoModel';
import { ISupportFormProps } from './interfaces/ISupportFormProps';
import { openErrorNotification } from '../../../helpers/NotificationHelper';
import { resources } from '../../../common/Resources';
import { sendSupportMessage } from '../../../services/m62_support/SupportService';

export interface IProps {
    appUserInfo: IAppUserInfoModel;
}

export interface IMethods {
    toggleModal: () => void;
}

export class SupportFormInner extends React.Component<IProps & IMethods, ISupportFormProps> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    handleChange = (event: any): void => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event: any): void => {
        event.preventDefault();

        const { object, message } = this.state;

        const obj = {
            id_user: this.props.appUserInfo.id,
            name: this.props.appUserInfo.id?.toString(),
            email: this.props.appUserInfo.email,
            subject: object,
            message: message
        };


        sendSupportMessage(obj)
            .then(response => {
                if (response) {
                    this.setState({ object: '', message: '' });
                    this.props.toggleModal();
                }
                else {
                    openErrorNotification('Connection error. Please try again late.');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    public render() {
        return (
            <form className={styles.contactForm} onSubmit={this.handleSubmit}>
                <input type="hidden" value="something" />
                <div className={styles.formItem}>
                    <label
                        className={`h6 ${styles.formItem__label}`}
                        htmlFor="object">
                        {resources.supportView.subject}
                    </label>
                    <input
                        name="object"
                        id="object"
                        value={this.state.object || ''}
                        onChange={this.handleChange}
                        className={styles.formItem__input}
                        type="text"
                        autoComplete="off"
                        required />
                </div>
                <div className={`${styles.formItem} ${styles.nomargin}`}>
                    <label
                        className={`h6 ${styles.formItem__label}`}
                        htmlFor="message">
                        {resources.supportView.message}
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        value={this.state.message || ''}
                        onChange={this.handleChange}
                        className={styles.formItem__textarea}
                        autoComplete="off"
                        required />
                </div>
                <button
                    className={styles.formItem__submit}
                    type="submit">
                    {resources.supportView.send}
                </button>
            </form>
        );
    };
}


const mapStateToProps = (state: IAppState): IProps => {
    return {
        appUserInfo: state.commonState.appUserInfo
    }
}

const SupportForm = connect(mapStateToProps)(SupportFormInner);

export default SupportForm;
