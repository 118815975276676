import * as React from 'react';
import CompanieForm from '../forms/CompanieForm';
import SelectOnChange from '../../../components/forms/select/SelectOnChange';

import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { ClearBoth } from '../../../components/controls/clearBoth/ClearBoth';
import { CompaniesTable } from '../tables/CompaniesTable';
import { getCompaniesList } from '../../../services/m29_users_management/CompaniesService';
import { getDictionary } from '../../../helpers/DisctionariesHelper';
import { getUsersList } from '../../../services/m29_users_management/GeneralUserService';
import { ICompanies } from '../interfaces/ICompanies';
import { IDictionaryValue } from '../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { PageInf } from '../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../common/Resources';
import { Right } from '../../../components/controls/right/Right';
import { TableContainer } from '../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';

const values = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Inactive' }
]

export class CompaniesContent extends React.Component {
    _isMounted = false;

    state = {
        companiesList: Array<ICompanies>(),
        countriesD: Array<IDictionaryValue>(),
        accounts: Array<number>(),
        loading: false,
        loading_accounts: false,
        is_active: 1
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.setState({ countriesD: getDictionary('countriesNameD') });
        this.getData();
    }

    componentWillUnmount = (): void => {
        this._isMounted = false;
    }

    public render() {
        const { is_active, companiesList, loading, loading_accounts, accounts } = this.state;

        let companiesData = companiesList
        if (is_active !== undefined) {
            companiesData = companiesData.filter(x => Boolean(x.is_active) === Boolean(is_active))
        }

        return (
            <TableContainer>
                <PageInf  title={resources.pageTitle.companies} />
                <SelectOnChange
                    value={is_active}
                    name='is_active'
                    items={values}
                    onChange={this.onChangeSelect}
                    placeholder={resources.select.select_status}
                    allowClear
                    width={180}
                    className='marginBottom marginRigth'
                />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getData}
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.AddNewCompanyModalButton}>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewCompany}
                            buttonTitle={resources.modalButtonsTitle.addNewCompany}
                            buttonType='primary'
                            buttonTooltip={resources.title.addNewCompany}
                            icon={"plus"}
                            disabled={loading}
                            withHideMethod={true}
                            width={800}
                            buttonClassName='addNew marginBottom'
                        >
                            <CompanieForm
                                refreshList={this.getCampaignsList}
                                countriesD={this.state.countriesD}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <ClearBoth />
                <CompaniesTable
                    data={companiesData}
                    accounts={accounts}
                    loading={loading}
                    loading_accounts={loading_accounts}
                    tableName='CompaniesTable'
                    countriesD={this.state.countriesD}
                    refreshList={this.getCampaignsList}
                />
            </TableContainer>
        )
    };

    getData = () => {
        this.getAccountsList();
        this.getCampaignsList();
    }

    getAccountsList = async () => {
        this.setState({ loading_accounts: true });

        const object = {
            columns: [
                "id",
                "name",
                "id_company"
            ]
        };

        await getUsersList(object)
            .then(response => {
                const accounts = response.filter((x: any) => x.id_company !== null);
                this._isMounted && this.setState({ accounts });
            })
            .finally(() => this._isMounted && this.setState({ loading_accounts: false }));
    }

    getCampaignsList = async () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                "id",
                "name",
                "description",
                "internal",
                "address_line1",
                "address_line2",
                "city",
                "state",
                "zip",
                "id_country",
                "is_active"
            ]
        };

        await getCompaniesList(object)
            .then((response) => {
                const companiesList = response.map((item: any) => { return { ...item, key: item.id } });

                this._isMounted && this.setState({ companiesList });
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    onChangeSelect = (name: string, value?: number) => {
        this.setState({ [name]: value });
    }
}