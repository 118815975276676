import React, { Component } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { ProductsContent } from './contents/ProductsContent';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class ProductsViewInner extends Component<ISetActiveMenuItem> {
    componentDidMount = () =>
        this.props.setActiveMenuItem(navPath.products);

    render() {
        return (
            <ProductsContent />
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) =>
        dispatch(setActiveMenuItem(view))
});

export const ProductsView: React.ComponentClass = connect(null, mapDispatchToProps)(ProductsViewInner);