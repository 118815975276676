export interface IMessage {
    type: MessageType;
    data: IMessageBody;
    to: Array<number>;
    countryIds?: Array<number>;
}

export enum MessageType {
    NOTIFICATION = 'notification',
}

export enum MessageReceiverType {
    USERS = 'users',
    ROLES = 'roles',
}

export interface IMessageBody {}


export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
}

export interface INotificationBody extends IMessageBody {
    type: NotificationType;
    message: string;
}