import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';
import { IOrderStatusesMap } from '../helpers/interfaces/IOrderStatusesMap';
import { resourceDef, ResourceDef } from '../common/ResourceDef';

const orders = createGatewayAbsoluteUrl(resourceDef.GetOrders);
const ordersDetails = createGatewayAbsoluteUrl(resourceDef.GetOrdersDetails);
const statusesMap = createGatewayAbsoluteUrl(resourceDef.GetOrdersStatusesMap);
const sourceUrl = ResourceDef.TemplateGateway;

export const getOrders = (requestBody: any) => axios.post(orders, {
    ...requestBody,
})
    .then(response => response.data.data)
    .catch(
        (error) => {
            console.log('getOrders error');
            console.log(error);
            
            return {
                orders: [],
                count: 0,
            };
        });

export const getOrderInformations = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(response => { return response.data[0]; })
    .catch(() => {
        return [];
    });

export const getOrderDerails = (id: number) => axios.get(ordersDetails + id)
    .then(response => {
        return response.data;
    })
    .catch(
        (error) => {
            console.log(error);
            console.log('getOrders error');

            return [];
        });

export const getStatusesMap = (): Promise<Array<IOrderStatusesMap>> => axios.get(statusesMap)
    .then(response => {
        return response.data;
    })
    .catch(
        (error) => {
            console.log(error);
            console.log('getStatusesMap error');

            return {};
        });