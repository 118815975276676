import DictionaryF from "../../../../../../components/templateForm/form/dropDown/DictionaryF";
import IInputColumnOptions from "../../../../../../components/_baseView/interfaces/IInputColumnOptions";
import MultipleSelectF from "../../../../../../components/templateForm/form/multipleSelect/MultipleSelectF";
import React from "react";
import styles from "../../../styles/ProductDetails.module.scss";
import SwitchF from "../../../../../../components/templateForm/form/switch/SwitchF";
import TextInputF from "../../../../../../components/templateForm/form/textInput/TextInputF";
import { Button, Tooltip } from "antd";
import { copyClipboard } from "../../../../../../helpers/CopyHelpers";
import {
  createProduct,
  updateProduct,
} from "../../../../../../services/m30_products/ProductsService";
import {
  findInDictionaryAndReturnValue,
  getDictionary,
} from "../../../../../../helpers/DisctionariesHelper";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import {
  getCcCampaignsNameAndIdInOneField,
  getCcInstancesD,
} from "../../../../../../services/m40_libraries/LibrariesService";
import { history } from "../../../../../../helpers/History";
import { IDictionaryValue } from "../../../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IMainInformationsForm } from "../interfaces/IMainInformationsForm";
import { IMainInformationsFormIDefaultProds } from "../interfaces/IMainInformationsFormIDefaultProds";
import { IMainInformationsFormMethods } from "../interfaces/IMainInformationsFormMethods";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../../../../helpers/NotificationHelper";
import { resources } from "../../../../../../common/Resources";
import { CallCenter } from "./CallCenters";
import { Select } from "antd";

const res = resources.productsFormMain;
const standardColumnOptionsForFilters: IInputColumnOptions = {
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 },
};
const columnOptionsForSwitch: IInputColumnOptions = {
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 },
};

export class MainInformationsFormInner extends React.Component<
  {
    remarketingCampaigns: any;
    isLoadingCampaigns: boolean;
  } & IMainInformationsForm &
    IMainInformationsFormMethods &
    IMainInformationsFormIDefaultProds &
    FormikProps<IMainInformationsForm>
> {
  public static defaultProps = {
    readOnly: false,
    disabled: false,
    allowOfflineSearch: false,
    allowClear: false,
    labelTextAlignLeft: true,
    boldLabel: true,
  };

  state = {
    loading: false,
    loading_campaigns: false,
    selected_country_id: undefined,
    instancesD: Array<IDictionaryValue>(),
    campaignsD: Array<IDictionaryValue>(),
  };

  componentDidMount = () => {
    this.getCampaignsD();
    this.getInstancesD();

    this.props.country_id &&
      this.setState({ selected_country_id: this.props.country_id });
  };

  UNSAFE_componentWillReceiveProps = (props: IMainInformationsForm) => {
    this.props.country_id &&
      this.setState({ selected_country_id: this.props.country_id });
  };

  public render() {
    const { loading_campaigns, selected_country_id, instancesD, campaignsD } =
      this.state;
    const { isLoadingCampaigns, remarketingCampaigns } = this.props;

    let filtered_instancesD: IDictionaryValue[] = [];
    let filtered_campaignsD: any[] = [];

    if (selected_country_id) {
      filtered_instancesD = instancesD.filter(
        (x) => x.country_id === selected_country_id
      );
      filtered_campaignsD = campaignsD.filter((x: any) =>
        filtered_instancesD.some((item) => item.id === x.id_instance)
      );
    }

    filtered_campaignsD = filtered_campaignsD
      ? filtered_campaignsD
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .sort((a, b) =>
            a.id_external > b.id_external
              ? 1
              : b.id_external > a.id_external
              ? -1
              : 0
          )
      : [];

    const callCentersD = [
      { id: null, name: "[Not set]" },
      ...filtered_campaignsD,
    ];

    return (
      <article style={{ paddingBottom: "30px" }}>
        <Form>
          <div className={styles.grid_col_4_switch}>
            <div>
              <SwitchF
                {...this.props}
                name="is_visible_affbay"
                label={resources.productsFormMain.availableInAffbay}
                columnOptions={columnOptionsForSwitch}
                labelTextAlignLeft={true}
              />
            </div>
            <div>
              <SwitchF
                {...this.props}
                name="is_test_product"
                label={resources.productsFormMain.testProduct}
                columnOptions={columnOptionsForSwitch}
                labelTextAlignLeft={true}
              />
            </div>
            <div>
              <SwitchF
                {...this.props}
                name="is_available_sale"
                label={resources.productsFormMain.availableForSale}
                columnOptions={columnOptionsForSwitch}
                labelTextAlignLeft={true}
              />
            </div>
            <div>
              <SwitchF
                {...this.props}
                name="is_active"
                label={resources.productsFormMain.active}
                columnOptions={columnOptionsForSwitch}
                labelTextAlignLeft={true}
              />
            </div>
          </div>
          <div className={styles.sectionDivider} />
          <div className={styles.grid_col_4}>
            <div>
              <DictionaryF
                {...this.props}
                {...(this.props.values.country_id = this.state
                  .selected_country_id
                  ? this.state.selected_country_id
                  : undefined)}
                name="country_id"
                label={resources.productsFormMain.country}
                dictionaryValues={getDictionary("countriesNameD")}
                columnOptions={standardColumnOptionsForFilters}
                required={!this.props.id}
                disabled={this.props.id ? true : false}
                customOnChange={(selectedCountry) =>
                  this.onCountryChange(Number(selectedCountry.id))
                }
              />
            </div>
            <div>
              <DictionaryF
                {...this.props}
                name="default_price_currency_id"
                label={res.currency}
                dictionaryValues={getDictionary("currencyD")}
                columnOptions={standardColumnOptionsForFilters}
                disabled={true}
              />
            </div>
            <div>
              <DictionaryF
                {...this.props}
                name="call_center_id"
                label={res.callCenter}
                dictionaryValues={this.state.instancesD}
                columnOptions={standardColumnOptionsForFilters}
                disabled={true}
              />
            </div>
            {this.props.product_sku && (
              <div className="skuInputContainer">
                <div className={styles.grid_with_sku_button}>
                  <div>
                    <TextInputF
                      {...this.props}
                      name="product_sku"
                      label={res.sku}
                      columnOptions={standardColumnOptionsForFilters}
                      disabled={true}
                    />
                  </div>
                  <div style={{ marginTop: 37 }}>
                    <Tooltip title="Copy SKU">
                      <Button
                        icon="copy"
                        onClick={(event: any) => {
                          copyClipboard(event, this.props.product_sku);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.grid_col_4}>
            <div>
              <TextInputF
                {...this.props}
                name="name"
                label={res.englishName}
                columnOptions={standardColumnOptionsForFilters}
                required
                allowClear
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="name_origin"
                label={res.originalName}
                columnOptions={standardColumnOptionsForFilters}
                allowClear
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="name_short"
                label={res.shortName}
                columnOptions={standardColumnOptionsForFilters}
                allowClear
              />
            </div>
            <div>
              <TextInputF
                {...this.props}
                name="name_print"
                label={res.printName}
                columnOptions={standardColumnOptionsForFilters}
                allowClear
              />
            </div>
          </div>

          <div style={{ display: "flex", gap: "5px" }}>
            <div style={{ flex: "1" }}>
              <MultipleSelectF
                {...this.props}
                name="categories"
                label={res.categories}
                readOnly={false}
                dictionaryValues={getDictionary("categoriesD")}
              />
            </div>

            <div style={{ flex: "1" }}>
              <TextInputF
                {...this.props}
                name="parent_id"
                label="Parent ID"
                columnOptions={standardColumnOptionsForFilters}
                allowClear
              />
            </div>

            <div style={{ flex: "1" }}>
              <TextInputF
                {...this.props}
                name="parent_sku"
                label="Parent SKU"
                columnOptions={standardColumnOptionsForFilters}
                allowClear
              />
            </div>
          </div>

          <div className={styles.sectionDivider}></div>
          <div className={styles.grid_col_2}>
            <div className={styles.border}>
              <h2>{res.callCenter}</h2>
              <div>
                <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Button
                    type="primary"
                    className={styles.updateCampaignsButton}
                    onClick={this.updateCampaigns}
                    disabled={this.state.loading_campaigns}
                  >
                    {res.updateCampaigns}
                  </Button>
                </div>

                <div>
                  <DictionaryF
                    {...this.props}
                    name="call_center_main"
                    label={resources.productsFormMain.mainCamapignId}
                    dictionaryValues={filtered_campaignsD}
                    loading={loading_campaigns}
                    required
                  />

                  {!isLoadingCampaigns &&
                    filtered_campaignsD.length > 0 &&
                    remarketingCampaigns
                      .sort((a: any, b: any) => a.name.localeCompare(b.name))
                      .map((campaign: any) => (
                        <CallCenter
                          key={campaign.id}
                          label={`${resources.productsFormMain.resellCampaignId} - ${campaign.name}`}
                          campaigns={callCentersD}
                          callCenters={this.props.values.call_centers}
                          trafficSource={campaign}
                          setFieldValue={this.props.setFieldValue}
                        />
                      ))}
                </div>
              </div>
            </div>
            <div className={styles.border}>
              <h2>{res.sales}</h2>
              <div className={`${styles.grid_col_2}`}>
                <div>
                  <TextInputF
                    {...this.props}
                    name="production_cost"
                    type="number"
                    label={res.productionCost}
                    required
                  />

                  <>
                    <div className="form-control-column-label">
                      <label className="label-control label-text-align-left font-weight-bold">
                        Call center validation campaign
                      </label>
                    </div>

                    <Select
                      value={this.props.values.call_center_validation || null}
                      onChange={(value: any) =>
                        this.props.setFieldValue(
                          "call_center_validation",
                          value
                        )
                      }
                      placeholder="Select validation"
                    >
                      {callCentersD.map((callCenter: any) => (
                        <Select.Option
                          value={callCenter.id}
                          key={callCenter.id}
                        >
                          {callCenter.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                </div>
                <div>
                  <TextInputF
                    {...this.props}
                    name="production_cost_local"
                    type="number"
                    label={res.productionCostLocalCurrency}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              className="saveButton marginTop"
            >
              {this.props.id ? res.save : res.create}
            </Button>
          </div>
        </Form>
      </article>
    );
  }

  getInstancesD = () => {
    getCcInstancesD().then((instancesD) => this.setState({ instancesD }));
  };

  getCampaignsD = () => {
    this.setState({ loading_campaigns: true });

    getCcCampaignsNameAndIdInOneField()
      .then((campaignsD) => {
        this.setState({ campaignsD });
      })
      .finally(() => this.setState({ loading_campaigns: false }));
  };

  updateCampaigns = () => {
    this.setState({ loading_campaigns: true });

    getCcCampaignsNameAndIdInOneField()
      .then((campaignsD) => {
        this.setState({ campaignsD });
        openSuccessNotification("Campaigns updated");
      })
      .catch(() => {
        openErrorNotification(resources.statement.databaseConnectionError);
      })
      .finally(() =>
        setTimeout(() => this.setState({ loading_campaigns: false }), 1000)
      );
  };

  onCountryChange = (selected_country_id: number) => {
    this.setState({ selected_country_id: selected_country_id });

    const call_center_id = this.state.instancesD.filter(
      (x) => x.country_id === selected_country_id
    )[0]
      ? this.state.instancesD.filter(
          (x) => x.country_id === selected_country_id
        )[0].id
      : undefined;

    this.props.setFieldValue("call_center_main", undefined);
    this.props.setFieldValue("call_center_resell", undefined);
    this.props.setFieldValue("default_price_currency_id", selected_country_id);
    this.props.setFieldValue("call_center_id", call_center_id);
  };
}

const MainInformationsForm = withFormik<
  {
    remarketingCampaigns: any;
    isLoadingCampaigns: boolean;
  } & IMainInformationsForm &
    IMainInformationsFormMethods &
    IMainInformationsFormIDefaultProds,
  IMainInformationsForm
>({
  mapPropsToValues: (props: IMainInformationsForm) => {
    return {
      is_visible_affbay: props.is_visible_affbay,
      is_test_product: props.is_test_product,
      is_available_sale: props.is_available_sale,
      is_active: props.is_active,
      country_id: props.country_id,
      default_price_currency_id: props.default_price_currency_id,
      call_center_id: props.call_center_id,
      product_sku: props.product_sku,
      name: props.name,
      name_origin: props.name_origin,
      name_short: props.name_short,
      name_print: props.name_print,
      parent_id: props.parent_id,
      parent_sku: props.parent_sku,
      categories: props.categories && props.categories.split(",").map(Number),
      call_centers: props.call_centers,
      call_center_main: props.call_center_main,
      call_center_validation: props.call_center_validation,
      call_center_resell: props.call_center_resell,
      production_cost: props.production_cost
        ? Number((props.production_cost / 100).toFixed(2))
        : undefined,
      production_cost_local: props.production_cost_local
        ? Number((props.production_cost_local / 100).toFixed(2))
        : undefined,
      id: props.id,
    };
  },

  validate: (values: IMainInformationsForm) => {
    const errors: FormikErrors<IMainInformationsForm> = {};

    if (!values.id && !values.country_id)
      errors.country_id = resources.validation.required;
    if (!values.name) errors.name = resources.validation.required;
    if (!values.call_center_main)
      errors.call_center_main = resources.validation.required;
    if (!values.production_cost)
      errors.production_cost = resources.validation.required;
    else if (values.production_cost > 99999999)
      errors.production_cost = "Max 99999999";
    if (!values.production_cost_local)
      errors.production_cost_local = resources.validation.required;
    else if (values.production_cost_local > 99999999)
      errors.production_cost_local = "Max 99999999";
    if (!values.call_center_id)
      errors.call_center_id = resources.validation.required;

    return errors;
  },

  handleSubmit: (
    values: IMainInformationsForm,
    bag: FormikBag<
      IMainInformationsForm &
        IMainInformationsFormMethods &
        IMainInformationsFormIDefaultProds,
      IMainInformationsForm
    >
  ) => {
    const productObject = {
      is_visible_affbay: Boolean(values.is_visible_affbay),
      is_test_product: Boolean(values.is_test_product),
      is_available_sale: Boolean(values.is_available_sale),
      is_active: Boolean(values.is_active),
      name: values.name,
      parent_id: Number(values.parent_id) === 0 ? null : values.parent_id,
      parent_sku: values.parent_sku ? values.parent_sku : null,
      name_origin: !values.name_origin ? values.name : values.name_origin,
      name_short: !values.name_short ? values.name : values.name_short,
      name_print: !values.name_print ? values.name : values.name_print,
      call_center_validation: values.call_center_validation,
      call_centers: values.call_centers
        ? values.call_centers.filter((center) => center[1] !== null)
        : [],
      call_center_main: values.call_center_main,
      call_center_resell: values.call_center_resell
        ? values.call_center_resell
        : 0,
      production_cost: Number((values.production_cost! * 100).toFixed()),
      production_cost_local: Number(
        (values.production_cost_local! * 100).toFixed()
      ),
      categories: values.categories === "" ? [] : values.categories,
    };

    if (bag.props.id) {
      updateProductInDB(Number(bag.props.id), productObject);
    } else {
      const createProductObject = {
        ...productObject,
        call_center_id: values.call_center_id,
        country_id: values.country_id,
        default_price_currency: findInDictionaryAndReturnValue(
          values.default_price_currency_id!,
          getDictionary("currencyD")
        ),
        default_price_currency_id: values.default_price_currency_id,
      };

      createNewProduct(createProductObject, bag.props);
      window.scrollTo(0, 0);
    }
  },
  validateOnChange: true,
  enableReinitialize: true,
})(MainInformationsFormInner);

export default MainInformationsForm;

const createNewProduct = (
  object: any,
  bag: IMainInformationsFormMethods
): void => {
  createProduct(object).then((response) => {
    if (response.data) {
      const obj = {
        ...response.data,
        ...{
          call_centers: response.data.call_centers.map((cc: any) =>
            cc.map((cc_item: any) => parseInt(cc_item))
          ),
        },
        categories: response.data.categories.join(),
      };

      bag.setNewCreatedProductDetails!(obj);
      history.push(`product_details/${response.data.id}`);
      openSuccessNotification(resources.statement.created);
    }
  });
};

const updateProductInDB = (id: number, object: any): void => {
  updateProduct(id, object).then((response) => {
    response && openSuccessNotification(resources.statement.updated);
  });
};
