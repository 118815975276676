import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const districtsQuery = 'SELECT id, id as "key", name, country_id, name_eng, deleted_at, created_at, updated_at FROM li_districts';
const districtsListQuery = 'SELECT id, name, country_id FROM li_districts';

export const getDistrictsDictionaryList = () => axios.post(sourceUrl, { data: { sql: districtsQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getDistrictsDictionaryList');
        return [];
    });

export const getDistrictsListD = () => axios.post(sourceUrl, { data: { sql: districtsListQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getDistrictsList');
        return [];
    });

export const updateDistrictsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => {
        return true;
    })
    .catch(() => {
        openErrorNotification(' updateDistrictsDictionary');
        return false;
    });

export const createDistrictsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createDistrictsDictionary');
        return false;
    });

export const deleteDistrictsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createDistrictsDictionary');
        return false;
    });