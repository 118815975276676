import * as Yup from 'yup';

const validation = {
    paymentMethod: Yup.number()
        .integer()
        .required('Required'),
    salesChannel: Yup.number()
        .integer()
        .required('Required'),
};

export default validation;