import box from "./images/box.svg";
import cart from "./images/cart.svg";
import CreateNewOrderContext from "../../../contexts/createNewOrderContext";
import React from "react";
import styles from "./styles/CreateNewOrderContent.module.scss";
import { ArrowHeader } from "../components/arrowHeader/ArrowHeader";
import { CreateNewOrderLogic } from "./CreateNewOrderLogic";
import { NewOrderForm } from "../components/forms/mainForm/NewOrderForm";
import { OrderStepBox } from "../components/orderStepBox/OrderStepBox";
import { SelectF } from "../../../components/forms/selectF/SelectF";
import { StepOne } from "../components/stepOne/StepOne";
import { StepThree } from "../components/stepThree/StepThree";
import { StepTwo } from "../components/stepTwo/StepTwo";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";

export class CreateNewOrderContent extends CreateNewOrderLogic {
  render() {
    let customerInfoFromLead = {};
    if (
      this.state.selectedLeadId &&
      (this.state.activeStep === 2 || this.state.activeStep === 3)
    ) {
      const selectedLead = this.state.leadsList.find(
        (el) => el.id === this.state.selectedLeadId
      );
      if (selectedLead) {
        customerInfoFromLead = {
          name:
            selectedLead.client_firstname +
            (selectedLead.client_lastname
              ? ` ${selectedLead.client_lastname}`
              : ""),
          phone: selectedLead.client_phone1,
          phone2: selectedLead.client_phone2,
        };
      }
    }
    let addressForm = this.state.countryId ? (
      <NewOrderForm
        countryId={this.state.countryId}
        countryName={
          this.state.countriesList.filter(
            (el) => el.id === this.state.countryId
          )[0].name
        }
        onSubmit={this.submitOrder}
        {...customerInfoFromLead}
      />
    ) : null;
    return (
      <CreateNewOrderContext.Provider
        value={{
          ...this.state,
          handleFccIdInput: this.handleFccIdChange,
          handlePhoneNumberInput: this.handlePhoneNumberInput,
          chooseLead: this.chooseLead,
          nextStep: this.changeActiveStepTo,
          chooseProduct: this.chooseProduct,
          resetState: this.resetState,
          findProductWithLead: this.findProductWithLead,
          choosePricing: this.choosePricing,
          addToBasket: this.addToBasket,
          removeFromBasket: this.removeFromBasket,
          isPricingChecked: this.isPricingChecked,
          calculateBasket: this.calculateBasket,
        }}
      >
        <>
          <div className={styles.headerContainer}>
            <ViewHeader title={"Create New Order"} />
            {this.state.countriesList && this.state.countriesList.length > 1 ? (
              <div className={`${styles.column} ${styles.column}`}>
                <div className={"customAnt--newOrderForm"}>
                  <SelectF
                    name="country"
                    value={this.state.countryId}
                    onChange={this.countryChangeHandler}
                    items={this.state.countriesList}
                    label={"Country"}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <section className={styles.stages}>
            <article className={styles.stages__stage}>
              <ArrowHeader
                activeStep={this.getStepActivity(1)}
                text="Step 1 - Fill Focus Record ID"
              />
              <OrderStepBox active={this.getStepVisibility(1)}>
                <StepOne />
              </OrderStepBox>
            </article>
            <article className={styles.stages__stage}>
              <ArrowHeader
                activeStep={this.getStepActivity(2)}
                text="Step 2 - Choose a product"
              />
              <OrderStepBox image={box} active={this.getStepVisibility(2)}>
                <StepTwo data={this.state.productsList} />
              </OrderStepBox>
            </article>
            <article className={styles.stages__stage}>
              <ArrowHeader
                activeStep={this.getStepActivity(3)}
                text="Step 3 - Fill the information below"
                noArrow={true}
              />
              <OrderStepBox image={cart} active={this.getStepVisibility(3)}>
                <StepThree />
              </OrderStepBox>
            </article>
          </section>
          {addressForm}
        </>
      </CreateNewOrderContext.Provider>
    );
  }
}
