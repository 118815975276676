import * as React from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { InstancesContent } from "./contents/InstancesContent";
import { ISetActiveMenuItem } from "../../../common/interfaces/ISetActiveMenuItem";
import { navPath } from "../../../common/ResourceDef";
import { setActiveMenuItem } from "../../../reducers/CommonReducer";
import { IAppState } from "../../../store";
import { USER_ROLES } from "../../../common/enums/UserRoles";

class InstancesViewInner extends React.Component<
  ISetActiveMenuItem & { isCCSimplified: boolean }
> {
  componentDidMount = async () => {
    this.props.setActiveMenuItem(navPath.cc_instances);
  };

  public render() {
    return <InstancesContent isCCSimplified={this.props.isCCSimplified} />;
  }
}

const mapStateToProps = (state: IAppState): { isCCSimplified: boolean } => {
  const userRole = state.commonState.appUserInfo.id_users_role;

  return {
    isCCSimplified: userRole === USER_ROLES.CC_SIMPLIFIED,
  };
};

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (
  dispatch: Dispatch
) => ({
  setActiveMenuItem: (view: string) => {
    dispatch(setActiveMenuItem(view));
  },
});

export const InstancesView: React.ComponentClass = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstancesViewInner);
