import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const paymentMethodsQuery = 'SELECT id, id as "key", name, created_at, updated_at, is_active FROM li_payment_methods';

export const getPaymentMethodsDictionaryList = () => axios.post(sourceUrl, { data: { sql: paymentMethodsQuery } })
    .then((response) => {  return response.data; })
    .catch(() => {
        openErrorNotification(' getPaymentMethodsDictionaryList');
        return [];
    });

export const updatePaymentMethodsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => {  return true; })
    .catch(() => {
        openErrorNotification(' updatePaymentMethodsDictionary');
        return false;
    });

export const createPaymentMethodsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => {  return response.data.insertId;  })
    .catch(() => {
        openErrorNotification(' createPaymentMethodsDictionary');
        return false;
    });

export const deletePaymentMethodsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createPaymentMethodsDictionary');
        return false;
    });