import axios from 'axios';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const systemVariables = 'SELECT id, id as "key", name, value, created_at, updated_at, is_active FROM li_system_variables';

export const getSystemVariablesDictionaryList = () => axios.post(sourceUrl, { data: { sql: systemVariables } })
    .then(response => {  return response.data;  })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getSystemVariablesDictionaryList');
        return [];
    });

export const createSystemVariableDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => response.data.insertId)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'createSystemVariableDictionary');
        return undefined;
    });

export const updateSystemVariableDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true )
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'updateSystemVariableDictionary');
        return false;
    });

export const deleteSystemVariableDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'deleteSystemVariableDictionary');
        return false;
    });