import React, { Component } from 'react'
import { PartnerViewCheckbox } from '../../../../components/partnerViewCheckbox/PartnerViewCheckbox';
import styles from './PricingItem.module.scss';

interface IPricingItemProps {
    name: string;
    id: number;
    total_order_value: number;
    total_customer_price: number;
    isChecked: (e: string) => boolean;
    choosePricing: (e: any) => void;
}

export class PricingItem extends Component<IPricingItemProps> {
    private labelRef = React.createRef<HTMLDivElement>();

    componentDidMount = () => {
        this.labelRef.current!.addEventListener('click', () => {
            this.props.choosePricing(this.props.id);
        })
    }
    render() {
        return (
            <div className={styles.item}>
                <PartnerViewCheckbox name="pricing" value={this.props.id} onChange={() => this.props.choosePricing(this.props.id)} checked={this.props.isChecked(`${this.props.id}`)} />
                <span ref={this.labelRef} className={styles.labelLike}>{this.props.name}</span>
            </div>
        )
    }
}
