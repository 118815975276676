import * as Yup from 'yup';

const validation = {
    city: Yup.string()
        .required('Required'),
    province: Yup.string()
        .required('Required'),
    district: Yup.string()
        .required('Required'),
    subdistrict: Yup.string()
        .required('Required'),
    postalCode: Yup.string()
        .required('Required'),
};

export default validation;