import React, { Component } from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { IId } from "../../../common/interfaces/IId";
import { ISetActiveMenuItem } from "../../../common/interfaces/ISetActiveMenuItem";
import { navPath } from "../../../common/ResourceDef";
import { ProductDetailsContent } from "./contents/ProductDetailsContent";
import { RouteComponentProps } from "react-router-dom";
import { setActiveMenuItem } from "../../../reducers/CommonReducer";

class ProductDetailsViewInner extends Component<
  ISetActiveMenuItem & RouteComponentProps<IId>
> {
  componentDidMount = (): void =>
    this.props.setActiveMenuItem(navPath.products);

  public render() {
    return <ProductDetailsContent id={this.props.match.params.id} />;
  }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (
  dispatch: Dispatch
) => ({
  setActiveMenuItem: (view: string) => {
    dispatch(setActiveMenuItem(view));
  },
});

export const ProductDetailsView: React.ComponentClass<
  RouteComponentProps<IId>
> = connect(null, mapDispatchToProps)(ProductDetailsViewInner);
