import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const locationsQuery = `SELECT
                            id,
                            id as 'key',
                            country_id,
                            city_id,
                            province_id,
                            district_id,
                            subdistrict_id,
                            post_code_id,
                            courier_id,
                            bank_transfer,
                            deleted_at,
                            created_at,
                            updated_at,
                            lead_time,
                            destination_code,
                            island,
                            lead_time_text,
                            delivery_cost,
                            return_cost,
                            insurance_fee,
                            cod_fee,
                            admin_fee
                        FROM li_locations`;

export const getLocationsDictionaryList = () => axios.post(sourceUrl, { data: { sql: locationsQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getLocationsDictionaryList');
        return [];
    });

export const updateLocationsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateLocationsDictionary');
        return false;
    });

export const createLocationsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createLocationsDictionary');
        return false;
    });

export const deleteLocationsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createLocationsDictionary');
        return false;
    });