import * as React from 'react';
import styles from './styles/OrderDetailsContent.module.scss';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { findInDictionaryAndReturnValue, getDictionary } from '../../../../helpers/DisctionariesHelper';
import { getCompanyInformations } from '../../../../services/dictionaries/CompanieInformationsService';
import { getOrderDerails } from '../../../../services/OrdersService';
import { getProductsD } from '../../../../services/m30_products/ProductsService';
import { IBasket } from '../interfaces/IBasket';
import { ICompanyInf } from '../interfaces/ICompanyInf';
import { IId } from '../../../../common/interfaces/IId';
import { IOrder } from '../interfaces/IOrder';
import { IPricingDetails } from '../interfaces/IPricingDetails';
import { Link } from 'react-router-dom';
import { OrderDetailsTable } from '../tables/OrderDetailsTable';
import { resources } from '../../../../common/Resources';
import { Spin } from 'antd';

const expiredDate = '06-2-2020 08:20:37';
const orderDerails: IOrder = {
    id: undefined
}

const company_inf: ICompanyInf = {
    company_name: '----',
    address: '----',
    email: '----',
    phone: '----',
}

export class OrderDetailsContent extends React.Component<IId> {
    _isMounted = false;

    state = {
        order_details: orderDerails,
        company_inf: company_inf,
        basket: [],
        productsD: [],
        total_price: 0,
        loading: false
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.getProductsD();
        this.getOrderDetails();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { order_details } = this.state;
        const currency = this.state.order_details.country_id ? findInDictionaryAndReturnValue(this.state.order_details.country_id, getDictionary("currencyD")) : '-';

        return (
            this.state.loading ? <div style={{ textAlign: "center", marginTop: '20%' }}><Spin tip={resources.spin.loading} /></div> :
                <>
                    <div className={styles.grid_col_2}>
                        <div><b>Order Ref:</b> {order_details.ref}</div>
                        <div style={{ textAlign: 'right' }}><b>Order date:</b> {order_details.created_at}</div>
                    </div>
                    <div className={styles.grid_col_3}>
                        <div>
                            From:
                        <div><b><Link to={order_details.payment_url ? order_details.payment_url : ''}> {order_details.payment_url} </Link></b></div>
                            <div> {this.state.company_inf.company_name} </div>
                            <div> {this.state.company_inf.address} </div>
                            <div> Phone: <b>{this.state.company_inf.phone}</b></div>
                            <div> Email: <b>{this.state.company_inf.email}</b></div>
                        </div>
                        <div>
                            To:
                        <div><b>{order_details.client_name}</b></div>
                            <div> {order_details.address_address} </div>
                            <div> {order_details.address_postcode}, {order_details.address_city} </div>
                            <div> {order_details.address_province} </div>
                            <div> {order_details.address_district}, {order_details.address_subdistrict} </div>
                            <br />
                            <div> Phone: <b>{order_details.client_phone1}</b></div>
                            <div> Email: <b>{order_details.client_email}</b></div>
                        </div>
                        <div>
                            <div className={styles.grid_col_2_ligth}>
                                <div>
                                    <div>Order Ref: </div>
                                    <div>Order ID (from database): </div>
                                    <div>Focus CC ID: </div>
                                    <div>Order status: </div>
                                </div>
                                <div>
                                    <div><b>{order_details.ref}</b></div>
                                    <div><b>{order_details.id}</b></div>
                                    <div><b>{order_details.focus_lead_id}</b></div>
                                    <div><b>{order_details.courier_status}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrderDetailsTable
                        loading={this.state.loading}
                        data={this.getOrdersDetailsPricingList()}
                        productsD={this.state.productsD}
                        currency={currency}
                    />
                    <div className={styles.grid_col_2}>
                        <div>
                            <div style={{ padding: 15 }}>
                                {this.displayElement('Payment link', order_details.payment_url)}
                            </div>
                            <div style={{ padding: 15 }}>
                                {this.displayElement('Expiration date', expiredDate)}
                            </div>
                            <div style={{ padding: 15 }}>
                                {this.displayElement('Customer email', order_details.client_email)}
                            </div>
                        </div>
                        <div>
                            <div style={{ float: "right" }}>
                                <b>Total price: </b> {this.getTotalPrice()} [{currency}]
                            </div>
                            <ClearBoth />
                        </div>
                    </div>
                </>
        )
    };

    displayElement = (name: string, value?: any) => {
        if (!value || value === null) {
            value = '----';
        }

        return (
            <>
                <div style={{ width: 120, float: 'left' }}>
                    {name}:
                </div>
                <div >
                    <b>{value}</b>
                </div>
            </>
        )
    }

    getOrdersDetailsPricingList = () => {
        const basket: IBasket[] = this.state.basket;

        let objects: IPricingDetails[] = [];
        let counter = 1;

        basket.map(item => {
            objects = [...objects, {
                key: counter++,
                id_product: item.pricing!.id_product!,
                base_price: item.pricing!.st_product_base_price! / 100,
                quantity: item.pricing!.quantity_st_product,
                discount: item.pricing!.p1_discount!
            }];

            if (item.pricing && item.pricing.quantity_nd_product) {
                objects = [...objects, {
                    key: counter++,
                    id_product: item.pricing.id_nd_product!,
                    base_price: item.pricing.nd_product_base_price! / 100,
                    quantity: item.pricing.quantity_nd_product!,
                    discount: item.pricing.p2_discount!
                }];
            }
            if (item.pricing && item.pricing.id_rd_product) {
                objects = [...objects, {
                    key: counter++,
                    id_product: item.pricing.id_rd_product!,
                    base_price: item.pricing.rd_product_base_price! / 100,
                    quantity: item.pricing.quantity_rd_product!,
                    discount: item.pricing.p3_discount!
                }];
            }

            return null;
        });

        return objects;
    }

    getTotalPrice = (): number => {
        const basket: IBasket[] = this.state.basket;

        let price = 0;

        basket.map(item => {
            price = price + item.end_price! / 100;

            return null;
        });

        return Number((price).toFixed(2));;
    }

    getTotalDiscount = () => {
        const basket: IBasket[] = this.state.basket;

        let discount = 0;

        basket.map(item => {
            discount = discount + (item.discount_used !== null ? item.discount_used! / 100 : 0);

            return null;
        });

        return Number((discount).toFixed(2));;
    }

    getProductsD = async () => {
        await getProductsD()
            .then(productsD => this._isMounted && this.setState({ productsD }));
    }

    getOrderDetails = async () => {
        this.props.id && await getOrderDerails(Number(this.props.id!))
            .then((response) => {
                this._isMounted && this.setState({
                    basket: response.baskets,
                    order_details: response.order
                });

                this.getCompanyInformations(response.order.country_id);
            });
    }

    getCompanyInformations = async (id: number) => {
        await getCompanyInformations(id)
            .then(result => {
                this._isMounted && this.setState({
                    company_inf: result
                });
            });
    }
}