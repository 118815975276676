import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProductCategoryDictionaryForm from '../forms/SalesChannelDictionaryForm';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { deleteProductCategoriesDictionary, updateProductCategoriesDictionary } from '../../../../services/dictionaries/ProductCategoriesDivtionaryService';
import { getSalesChannelsDictionaryList } from '../../../../services/dictionaries/SalesChannelsDivtionaryService';
import { ISalesChannel } from '../interfaces/ISalesChannel';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { SalesChannelDictionaryTable } from '../table/SalesChannelDictionaryTable';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class SalesChannelsDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        salesChannelsList: Array<ISalesChannel>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getSalesChannelsList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.salesChannels} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getSalesChannelsList}
                />
                <ProtectedModal permission={UserPermissionType.AddNewSalesChannelModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewChannel}
                            buttonTitle={resources.modalButtonsTitle.addNewChannel}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewChannel}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={this.state.loading}
                        >
                            <ProductCategoryDictionaryForm
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <SalesChannelDictionaryTable
                    loading={this.state.loading}
                    data={this.state.salesChannelsList}
                    deleteProductCategory={this.deleteProductCategory}
                    updateObjInArray={this.updateObjInArray}
                    changeStatus={this.changeStatus}
                />
            </TableContainer>
        )
    };

    getSalesChannelsList = () => {
        this.setState({ loading: true });

        getSalesChannelsDictionaryList()
            .then(salesChannelsList => { this._isMounted && this.setState({ salesChannelsList }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    deleteProductCategory = async (id: number) => {
        const query = `DELETE FROM li_sales_channels WHERE id = ${id}`;

        await deleteProductCategoriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_sales_channel);
                }
            })
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const salesChannelsList = this.state.salesChannelsList.filter(x => x.id !== id);

        this.setState({
            salesChannelsList,
            loading: false
        });

        openSuccessNotification(resources.statement.sales_channel_deleted);
    }

    changeStatus = async (event: any) => {
        const id = Number(event.target.value);
        const query = `UPDATE li_sales_channels SET is_active = !is_active WHERE id = ${id}`;

        await updateProductCategoriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.changeStatusInArray(id);
                    openSuccessNotification(resources.statement.status_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        const { salesChannelsList } = this.state;
        const index = salesChannelsList.findIndex(x => x.id === id);

        salesChannelsList[index].is_active = !salesChannelsList[index].is_active;

        this.setState({ salesChannelsList });
    }

    addObjToArray = (channel: ISalesChannel) => {
        this.setState({ salesChannelsList: [...this.state.salesChannelsList, channel] });
    }

    updateObjInArray = (obj: ISalesChannel): void => {
        const { salesChannelsList } = this.state;
        const index = salesChannelsList.findIndex(x => x.id === obj.id);

        salesChannelsList[index].name = obj.name;
        salesChannelsList[index].is_active = obj.is_active;
        salesChannelsList[index].updated_at = obj.updated_at;

        this.setState({ salesChannelsList });
    }
}