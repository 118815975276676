import React from 'react';
import { Table, Tooltip, Button } from 'antd';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { findInDictionaryAndReturnValueWithSpin, getDictionary } from '../../../../helpers/DisctionariesHelper';
import { convertBooleanYesOrNoReactElement } from '../../../../helpers/BooleanHelper';
import { getYesOrNoFilters } from '../../../../common/dictionaries/Filters';
import NewsForm from '../forms/NewsForm';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';

const { Column } = Table;

export class NewsTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='70px'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.title}
                dataIndex='title_en'
                key='title_en'
                align='center'
                width='150px'
                sorter={(a, b) => (a.title_en ? a.title_en : "").localeCompare(b.title_en ? b.title_en : "")}
                {...this.getColumnSearchProps('title_en')}
            />,
            <Column
                title={resources.columnTitle.title}
                dataIndex='title_ru'
                key='title_ru'
                align='center'
                width='150px'
                sorter={(a, b) => (a.title_ru ? a.title_ru : "").localeCompare(b.title_ru ? b.title_ru : "")}
                {...this.getColumnSearchProps('title_ru')}
            />,
            <Column
                title={resources.columnTitle.description}
                key='body_en'
                width='350px'
                align='center'
                sorter={(a, b) => (a.body_en ? a.body_en : "").localeCompare(b.body_en ? b.body_en : "")}
                {...this.getColumnSearchProps('body_en')}
                render={record => (
                    <Tooltip title={record.body_en}>
                        {record.body_en !== null && record.body_en.length > 44 ? record.body_en.substring(0, 40) + '...' : record.body_en}
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.description}
                key='body_ru'
                width='350px'
                align='center'
                sorter={(a, b) => (a.body_ru ? a.body_ru : "").localeCompare(b.body_ru ? b.body_ru : "")}
                {...this.getColumnSearchProps('body_ru')}
                render={record => (
                    <Tooltip title={record.body_ru}>
                        {record.body_ru !== null && record.body_ru.length > 44 ? record.body_ru.substring(0, 40) + '...' : record.body_ru}
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.type}
                key='id_news_type'
                width='150px'
                align='center'
                filters={getDictionary('newsTypesF')}
                onFilter={(value, record) => record.id_news_type === value}
                sorter={(a, b) => a.id_news_type - b.id_news_type}
                render={record =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.id_news_type, getDictionary('newsTypesD'))
                    )}
            />,
            <Column
                title={resources.columnTitle.product}
                key='id_product'
                width='250px'
                align='center'
                sorter={(a, b) => a.id_product - b.id_product}
                render={record =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.id_product, this.props.productsD)
                    )}
            />,
            <Column
                title={resources.columnTitle.user}
                key='id_user'
                align='center'
                width='140px'
                sorter={(a, b) => a.id_user - b.id_user}
                render={record =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.id_user, this.props.usersD)
                    )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.is_home_page, resources.columnTitle.is_home_page_short)}
                key='is_home_page'
                align='center'
                width='150px'
                sorter={(a, b) => a.is_home_page - b.is_home_page}
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_home_page === value}
                render={record => (
                    convertBooleanYesOrNoReactElement(record.is_home_page)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.date_from, resources.columnTitle.date_from_short)}
                key='date_from'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.date_from).diff(moment.utc(b.date_from))}
                render={record => (
                    convertNumberToDateTimeString(record.date_from)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.date_to, resources.columnTitle.date_to_short)}
                key='date_to'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.date_to).diff(moment.utc(b.date_to))}
                render={record => (
                    convertNumberToDateTimeString(record.date_to)
                )}
            />,
            < Column
                title={resources.columnTitle.deleted_at}
                key='deleted_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.deleted_at).diff(moment.utc(b.deleted_at))}
                render={record => (
                    convertNumberToDateTimeString(record.deleted_at)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={record => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={record => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                align='center'
                width='75px'
                fixed='right'
                render={record => {
                    return (
                        <>
                            <ProtectedModal permission={UserPermissionType.EditNewsModalButton}>
                                <Tooltip placement="rightTop" title={resources.tooltips.edit}>
                                    <InformationModalDialog
                                        modalTitle={resources.modalTitle.edit_news}
                                        icon={"form"}
                                        withHideMethod={true}
                                        width={800}
                                        disabled={record.deleted_at}
                                    >
                                        <NewsForm
                                            {...record}
                                            showSelectProduct
                                            product_id={record.product_id}
                                            updateObjInArray={this.props.updateObjInArray}
                                            productsD={this.props.productsD}
                                        />
                                    </InformationModalDialog>
                                </Tooltip>
                            </ProtectedModal>
                            <ProtectedComponent permission={UserPermissionType.DeleteNewsButton}>
                                <Tooltip
                                    placement='rightBottom'
                                    title={resources.tooltips.delete}
                                >
                                    <Button
                                        value={record.id}
                                        onClick={() => this.props.deleteNews(record.id)}
                                        icon='delete'
                                        disabled={record.deleted_at}
                                    />
                                </Tooltip>
                            </ProtectedComponent>
                        </>
                    )
                }} />
        ]

        let sortedData = this.props.data ? this.props.data.sort((a, b) => (a.id < b.id ? -1 : 1)) : [];

        return (
            <DataTable
                tableName='NewsesTable'
                columns={columns}
                data={sortedData}
                loading={this.props.loading}
            />
        )
    }
}