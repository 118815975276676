import React from "react";
import * as yup from "yup";
import { STATUSES_OPTIONS } from "../lib/constants";
import { FormikProps, setNestedObjectValues, withFormik } from "formik";
import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Icon,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  TimePicker,
} from "antd";
import { timezones } from "../../../../common/dictionaries/Timezones";
import { LEAD_SOURCE_NULL } from "../lib/hooks/remarketing-options";
import { openErrorNotification } from "../../../../helpers/NotificationHelper";
import { useMutation, useQuery } from "react-query";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import moment from "moment";
import { checkRemarketingSetting } from "../../../../services/m121_remarketing_settings/RemarketingService";
import { formatRemarketing } from "../lib/format-remarketing.helper";
import { Spin } from "antd";

const REMARKETING_MODE = Object.freeze({
  MYSQL: "mysql",
  MONGO: "mongo",
});

const validationSchema = yup.object().shape({
  isRangeDate: yup.boolean(),
  name: yup.string().required("Name is required"),
  days_after: yup.number().when("isRangeDate", {
    is: false,
    then: yup.number().required("Days after is required"),
  }),
  date_from: yup.string().when("isRangeDate", {
    is: true,
    then: yup.string().required("Items from is required"),
  }),
  date_to: yup.string().when("isRangeDate", {
    is: true,
    then: yup.string().required("Items to is required"),
  }),
  run_now: yup.number().required("Run now is required"),
  run_at_timezone: yup.number().when("run_now", {
    is: 0,
    then: yup.number().required("Timezone is required"),
  }),
  run_at_hour: yup.number().when("run_now", {
    is: 0,
    then: yup.number().required("Hour is required"),
  }),
  run_at_minute: yup.number().when("run_now", {
    is: 0,
    then: yup.number().required("Minute is required"),
  }),
  product_ids: yup.array().required("Products are required"),
  traffic_sources: yup.array().required("Remarketing is required"),
  order_statuses: yup.array().required("Statuses are required"),
  mode: yup.string().required("Mode is required"),
  remarketing_campaign: yup.number().required("Campaign is required"),
  orders_from: yup.number().required("Items from is required"),
  orders_to: yup
    .number()
    .max(99, "Should be less than 100")
    .required("Items to is required"),
});

const formErrorStyles = { color: "red", marginTop: "3px" };

const formFieldStyles = {
  flex: "1",
  marginBottom: "15px",
};

const formLabelStyles = {
  display: "block",
  marginBottom: "5px",
};

interface RemarketingFormValues {
  orders_from: number | undefined;
  orders_to: number | undefined;
  name: string;
  description: string;
  days_after: number | undefined;
  date_from: string;
  date_to: string;
  run_at_timezone: number | undefined;
  run_at_hour: number | undefined;
  run_at_minute: number | undefined;
  is_enabled: number;
  product_ids: number[];
  traffic_sources: number[];
  order_statuses: string[];
  run_now: number;
  isRangeDate: boolean;
  mode: string;
  remarketing_campaign: number | undefined;
}

interface RemarketingFormProps {
  countryId: number;
  remarketingOptions: any;
  onCreateRemarketing?: any;
  onEditRemarketing?: any;
  refetchRemarketing: any;
  remarketing?: any;
  createWithRemarketing?: boolean;
}

const RemarketingFormLayout = ({
  errors,
  values,
  status,
  touched,
  countryId,
  handleBlur,
  remarketing,
  handleSubmit,
  handleChange,
  validateForm,
  setTouched,
  setFieldValue,
  remarketingOptions,
  createWithRemarketing,
}: RemarketingFormProps & FormikProps<RemarketingFormValues>) => {
  const products = useQuery(["remarketing-products"], () =>
    getProducts({
      columns: ["id", "name", "is_available_sale"],
    }).then((products: any) =>
      products.filter((product: any) => product.is_available_sale)
    )
  );

  const checkRemarkentingMutation = useMutation(
    ["remarketing-check"],
    checkRemarketingSetting
  );

  const onChangeRemarketing = (
    isChecked: boolean,
    entityItem: any,
    field: any
  ) => {
    const updatedEntity = isChecked
      ? [...values[field], entityItem.id]
      : values[field].filter((id: any) => id !== entityItem.id);

    setFieldValue(field, updatedEntity);
  };

  const onCheckRemarketing = async () => {
    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      return setTouched(setNestedObjectValues(errors, true));
    }

    const updatedRemarketing = formatRemarketing(
      values,
      countryId,
      remarketing
    );

    await checkRemarkentingMutation.mutateAsync(updatedRemarketing);
  };

  const getRemarketingOption = (entityItem: any) =>
    values.traffic_sources.find((source) => source === entityItem.id);

  if (products.isLoading) return null;

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={20}>
        <Col span={12}>
          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="name">
              Name
            </label>
            <Input
              id="name"
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Name"
            />
            {touched.name && errors.name && (
              <div style={formErrorStyles}>{errors.name}</div>
            )}
          </div>
        </Col>

        <Col span={12} style={{ marginTop: "30px" }}>
          <div style={{ ...formFieldStyles, display: "flex" }}>
            <label style={{ marginRight: "10px" }}>Enabled</label>
            <Switch
              checked={Boolean(values.is_enabled)}
              onChange={(checked) =>
                setFieldValue("is_enabled", Number(checked))
              }
            />
          </div>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="description">
              Description
            </label>
            <Input.TextArea
              id="description"
              name="description"
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              style={{ marginBottom: 0 }}
              placeholder="Description"
            />
          </div>
        </Col>

        <Col span={12}>
          <span style={{ cursor: "pointer", marginBottom: "10px" }}>
            Choose mode
          </span>

          <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

          <Radio.Group
            onChange={(e) => setFieldValue("mode", e.target.value)}
            value={values.mode}
          >
            <Radio value={REMARKETING_MODE.MYSQL}>MySQL</Radio>
            <Radio value={REMARKETING_MODE.MONGO}>Mongo</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="orders_from">
              Items from
            </label>
            <Input
              id="orders_from"
              type="number"
              name="orders_from"
              value={values.orders_from}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Items from"
            />
            {touched.orders_from && errors.orders_from && (
              <div style={formErrorStyles}>{errors.orders_from}</div>
            )}
          </div>
        </Col>

        <Col span={12}>
          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="orders_to">
              Items to
            </label>
            <Input
              id="orders_to"
              type="number"
              name="orders_to"
              value={values.orders_to}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Items to"
            />
            {touched.orders_to && errors.orders_to && (
              <div style={formErrorStyles}>{errors.orders_to}</div>
            )}
          </div>
        </Col>
      </Row>

      <div style={{ ...formFieldStyles, display: "flex" }}>
        <label style={{ marginRight: "10px" }}>Range date</label>
        <Switch
          checked={values.isRangeDate}
          onChange={(checked) => setFieldValue("isRangeDate", checked)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "15px",
        }}
      >
        {!values.isRangeDate && (
          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="days_after">
              Days after
            </label>
            <Input
              id="days_after"
              type="number"
              value={values.days_after}
              name="days_after"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Days after"
            />
            {touched.days_after && errors.days_after && (
              <div style={formErrorStyles}>{errors.days_after}</div>
            )}
          </div>
        )}

        {values.isRangeDate && (
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={formFieldStyles}>
              <label style={formLabelStyles} htmlFor="date_from">
                Date from
              </label>

              <DatePicker
                name="date_from"
                value={
                  values.date_from ? moment.utc(values.date_from) : undefined
                }
                onChange={(date) =>
                  setFieldValue("date_from", date?.startOf("day").toString())
                }
                placeholder="Date from"
              />

              {touched.date_from && errors.date_from && (
                <div style={formErrorStyles}>{errors.date_from}</div>
              )}
            </div>

            <div style={formFieldStyles}>
              <label style={formLabelStyles} htmlFor="date_to">
                Date to
              </label>

              <DatePicker
                name="date_to"
                value={values.date_to ? moment.utc(values.date_to) : undefined}
                onChange={(date) =>
                  setFieldValue("date_to", date?.endOf("day").toString())
                }
                placeholder="Date to"
              />

              {touched.date_to && errors.date_to && (
                <div style={formErrorStyles}>{errors.date_to}</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div style={{ ...formFieldStyles, display: "flex" }}>
        <label style={{ marginRight: "10px" }}>Run now</label>
        <Switch
          checked={Boolean(values.run_now)}
          onChange={(checked) => setFieldValue("run_now", Number(checked))}
        />
      </div>

      {!values.run_now && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="run_at_timezone">
              Run at timezone
            </label>
            <Select
              id="run_at_timezone"
              value={values.run_at_timezone}
              onBlur={handleBlur}
              onChange={(value: number) =>
                setFieldValue("run_at_timezone", value)
              }
              placeholder="Run at timezone"
            >
              {timezones.map((timezone) => (
                <Select.Option key={timezone.id} value={timezone.id}>
                  {timezone.name}
                </Select.Option>
              ))}
            </Select>

            {touched.run_at_timezone && errors.run_at_timezone && (
              <div style={formErrorStyles}>{errors.run_at_timezone}</div>
            )}
          </div>

          <div style={formFieldStyles}>
            <label style={formLabelStyles} htmlFor="run_at_hour">
              Run at time
            </label>

            <TimePicker
              value={
                moment(
                  `${values.run_at_hour || 0}:${values.run_at_minute || 0}`,
                  "HH:mm"
                )
              }
              format="HH:mm"
              onChange={(time) => {
                setFieldValue("run_at_hour", time.hour());
                setFieldValue("run_at_minute", time.minute());
              }}
            />

            {((touched.run_at_hour && errors.run_at_hour) ||
              (touched.run_at_minute && errors.run_at_minute)) && (
              <div style={formErrorStyles}>Time is required</div>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "15px",
        }}
      >
        <div style={formFieldStyles}>
          <label style={formLabelStyles} htmlFor="days_after">
            Select to which remarketing campaign new leads will be sent
          </label>

          <Select
            id="remarketing_campaign"
            value={values.remarketing_campaign}
            onBlur={handleBlur}
            onChange={(value: number) =>
              setFieldValue("remarketing_campaign", value)
            }
            placeholder="Select remarketing campaign"
          >
            {remarketingOptions.map((remarketing: any) => (
              <Select.Option key={remarketing.id} value={remarketing.id}>
                {remarketing.name}
              </Select.Option>
            ))}
          </Select>

          {touched.remarketing_campaign && errors.remarketing_campaign && (
            <div style={formErrorStyles}>{errors.remarketing_campaign}</div>
          )}
        </div>
      </div>

      <Row gutter={20}>
        <Col span={12}>
          <div>
            <span style={{ cursor: "pointer", marginBottom: "10px" }}>
              Remarketing options
            </span>

            <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {remarketingOptions.map((entityItem: any) => (
                <label
                  key={entityItem.id}
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                >
                  <Checkbox
                    style={{ marginRight: "7px" }}
                    checked={!!getRemarketingOption(entityItem)}
                    onChange={(e: any) =>
                      onChangeRemarketing(
                        e.target.checked,
                        entityItem,
                        "traffic_sources"
                      )
                    }
                  />
                  {entityItem.name}
                </label>
              ))}
            </div>

            {touched.traffic_sources && errors.traffic_sources && (
              <div style={formErrorStyles}>{errors.traffic_sources}</div>
            )}
          </div>
        </Col>

        <Col span={12}>
          <div>
            <span style={{ cursor: "pointer", marginBottom: "10px" }}>
              Statuses
            </span>

            <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {STATUSES_OPTIONS.map((option: any) => (
                <label
                  key={option.id}
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                >
                  <Checkbox
                    style={{ marginRight: "7px" }}
                    checked={
                      !!values.order_statuses.find(
                        (status) => status === option.id
                      )
                    }
                    onChange={(e: any) =>
                      onChangeRemarketing(
                        e.target.checked,
                        option,
                        "order_statuses"
                      )
                    }
                  />
                  {option.name}
                </label>
              ))}
            </div>

            {touched.order_statuses && errors.order_statuses && (
              <div style={formErrorStyles}>{errors.order_statuses}</div>
            )}
          </div>

          <div>
            <span style={{ cursor: "pointer", marginBottom: "10px" }}>
              Choose product
            </span>

            <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

            <div
              style={{
                maxHeight: "200px",
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ cursor: "pointer", marginBottom: "10px" }}>
                <Checkbox
                  style={{ marginRight: "7px" }}
                  checked={products.data.length === values.product_ids.length}
                  onChange={(e) => {
                    e.target.checked
                      ? setFieldValue(
                        "product_ids",
                        products.data.map((p: any) => p.id)
                      )
                      : setFieldValue("product_ids", []);
                  }}
                />
                Select all
              </label>

              {products.data
                .sort((a: any, b: any) => {
                  const nameA = a.name.split(" ")[0];
                  const nameB = b.name.split(" ")[0];

                  return nameA.localeCompare(nameB);
                })
                .map((product: any) => (
                  <label
                    key={product.id}
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                  >
                    <Checkbox
                      style={{ marginRight: "7px" }}
                      checked={
                        !!values.product_ids.find((p: any) => p === product.id)
                      }
                      onChange={(e: any) =>
                        onChangeRemarketing(
                          e.target.checked,
                          product,
                          "product_ids"
                        )
                      }
                    />
                    {product.name}
                  </label>
                ))}
            </div>

            {touched.product_ids && errors.product_ids && (
              <div style={formErrorStyles}>{errors.product_ids}</div>
            )}
          </div>
        </Col>
      </Row>

      {!!values.run_now && !remarketing && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 15px",
            marginTop: "15px",
            backgroundColor: "#f0f2f5",
          }}
        >
          <Icon
            type="warning"
            style={{ marginRight: "5px", fontSize: "18px", color: "#ffa500" }}
          />
          Your remarketing query will start in 5 minutes from now. Do you want
          to save it?
        </div>
      )}

      {!!values.run_now && remarketing && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 15px",
            marginTop: "15px",
            backgroundColor: "#f0f2f5",
          }}
        >
          <Icon
            type="warning"
            style={{ marginRight: "5px", fontSize: "18px", color: "#ffa500" }}
          />
          You make changes in your remarketing query and it will be sent leads
          again in 5 minutes. Do you want to continue?
        </div>
      )}

      <div style={{ display: "flex", marginTop: "20px" }}>
        {checkRemarkentingMutation.data && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              marginRight: "10px",
              borderRadius: "4px",
              backgroundColor: "#f0f2f5",
            }}
          >
            {checkRemarkentingMutation.data.count}
          </div>
        )}

        <button
          type="button"
          style={{
            width: "100%",
            marginRight: "10px",
          }}
          className="ant-btn"
          onClick={onCheckRemarketing}
        >
          {checkRemarkentingMutation.isLoading ? (
            <Spin size="small" />
          ) : (
            "Check items"
          )}
        </button>

        <button
          className="ant-btn ant-btn-primary"
          type="submit"
          style={{ width: "100%" }}
          disabled={status}
        >
          {status ? <Spin size="small" /> : remarketing && !createWithRemarketing ? "Update" : "Create"}
        </button>
      </div>
    </form>
  );
};

const getDefaultNumberValue = (number: number) =>
  (number !== null && number !== undefined) ? number : undefined;

export const RemarketingForm = withFormik<
  RemarketingFormProps,
  RemarketingFormValues
>({
  mapPropsToValues: ({ remarketing }) => ({
    isRangeDate:
      remarketing?.days_after !== -1
        ? false
        : remarketing?.orders_from && remarketing?.orders_to
          ? true
          : !remarketing
            ? false
            : false,
    mode: remarketing?.mode || REMARKETING_MODE.MYSQL,
    name: remarketing?.name || "",
    description: remarketing?.description || "",
    days_after: getDefaultNumberValue(remarketing?.days_after),
    date_from: remarketing?.date_from || "",
    date_to: remarketing?.date_to || "",
    orders_from: getDefaultNumberValue(remarketing?.orders_from),
    orders_to: getDefaultNumberValue(remarketing?.orders_to),
    run_at_timezone: getDefaultNumberValue(remarketing?.run_at_timezone),
    run_at_hour: getDefaultNumberValue(remarketing?.run_at_hour),
    run_at_minute: getDefaultNumberValue(remarketing?.run_at_minute),
    is_enabled: remarketing?.is_enabled || 0,
    run_now: remarketing?.run_now || 0,
    product_ids: remarketing?.product_ids || [],
    traffic_sources:
      remarketing?.traffic_sources.map((item: any) =>
        typeof item === "object" ? LEAD_SOURCE_NULL : item
      ) || [],
    order_statuses: remarketing?.order_statuses || [],
    remarketing_campaign: getDefaultNumberValue(
      remarketing?.remarketing_campaign
    ),
  }),
  handleSubmit: async (values, formikBag) => {
    const remarketing = formatRemarketing(
      values,
      formikBag.props.countryId,
      formikBag.props.remarketing
    );

    formikBag.setStatus(true);

    try {
      if (formikBag.props.remarketing && !formikBag.props.createWithRemarketing) {
        await formikBag.props.onEditRemarketing(remarketing);
      } else {
        await formikBag.props.onCreateRemarketing(remarketing);
      }

      formikBag.setStatus(false);
      formikBag.props.refetchRemarketing();
      (formikBag.props as any).hideModal();
    } catch (e) {
      formikBag.setStatus(false);
      openErrorNotification((e as any).message);
    }
  },
  validationSchema,
})(RemarketingFormLayout);
