export enum Status {
    Row = 0,
    NewLead = 1,
    Validated = 2,
    SendToCc = 3,
    Awaiting = 4,
    Sold = 5,
    Rejected = 6,
    Excluded = 7,
    Trash = 8,
    Double = 9,
    Unrecognized = 99,
    OrderCreated = 100
}