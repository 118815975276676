import { useQuery } from "react-query";

import { FILTERS } from "../filters-data";
import { getListFromLibrary } from "../../../../services/m40_libraries/LibrariesService";
import { UPDATE_QTY_FILTER_ENTITIES, useFilters } from "../filters-state";

export const useRemarketingEntity = () => {
  const { dispatch } = useFilters();
  const { data, isLoading } = useQuery<any>("remarketing", () =>
    getListFromLibrary({
      name: "remarketing_campaigns",
      column: "id, name",
    }).then((response) => {
      const campaigns = [
        ...response.map((item: any) => ({
          id: item.id,
          name: `${item.name} [${item.id}]`,
        })),
        { id: [1, 5], name: "Long page - total [ 1, 5 ]" },
        { id: [null], name: "Lead source [ null ]" },
      ].sort((a, b) =>
        (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
      );

      dispatch({
        type: UPDATE_QTY_FILTER_ENTITIES,
        payload: { filter: FILTERS.TRAFFIC_SOURCE, qty: campaigns.length },
      });

      return campaigns;
    })
  );
  return { entity: data, isLoading };
};
