import { useQuery } from "react-query";
import { getListFromLibrary } from "../../../../../services/m40_libraries/LibrariesService";

export const LEAD_SOURCE_NULL = 666;

export const useRemarketingOptions = () =>
  useQuery<any>("remarketing-options", () =>
    getListFromLibrary({
      name: "remarketing_campaigns",
      column: "id, name",
    }).then((response) => {
      const campaigns = [
        ...response.map((item: any) => ({
          id: item.id,
          name: `${item.name} [${item.id}]`,
        })),
        { id: LEAD_SOURCE_NULL, name: "Lead source [null]" },
      ].sort((a, b) =>
        (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
      );
      return campaigns;
    })
  );
