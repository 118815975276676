import * as React from "react";
import ProtectedComponent from "../../../../components/authorizations/protectedComponents/ProtectedComponent";
import SelectOnChange from "../../../../components/forms/select/SelectOnChange";
import { CampaignsTable } from "../tables/CampaingsTable";
import { FieldInfo } from "../../../../components/common/fieldInfo/FieldInfo";
import { findInDictionaryAndReturnValue } from "../../../../helpers/DisctionariesHelper";
import { FoundRowsNumber } from "../../../../components/common/fountRowsNumber/FountRowsNumber";
import {
  getFocusCampaigns,
  updateFocusCampaigns,
} from "../../../../services/focus/FocusCampaigns";
import { getInstancesDictionaryWithCountries } from "../../../../helpers/InstancesDictionaryHelper";
import { ICallCenterCampaigns } from "../../../../models/ICallCenterCampaigns";
import { IDetailsParamsProps } from "../interfaces/IDetailsParamsProps";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { Left } from "../../../../components/controls/left/Left";
import { megaUpdate } from "../../../../services/callCenter/MegaUpdateService";
import { MegaUpdateButton } from "../../../../components/megaUpdateButton/MegaUpdateButton";
import { PageInf } from "../../../../components/common/pageInf/PageInf";
import { RefreshTableButton } from "../../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../../common/Resources";
import { Right } from "../../../../components/controls/right/Right";
import { SearchElements } from "../../../../components/common/searchElements/SearchElements";
import { TableContainer } from "../../../../components/containers/tableContainer/TableContainer";
import { UploadButton } from "../../../../components/megaUpdate/UploadButton";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";

export class CampaignsContent extends React.Component<IDetailsParamsProps> {
  _isMounted = false;

  state = {
    loading: false,
    loading_instances: false,
    id_instance: 0,
    campaignsList: Array<ICallCenterCampaigns>(),
    instancesList: Array<IDictionaryValue>(),
    search: undefined,
  };

  componentDidMount = async () => {
    this._isMounted = true;

    await this.setParamsFromUrl();

    this.getInstancesList();
    this.getCampaignsList();
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    const {
      id_instance,
      campaignsList,
      loading,
      loading_instances,
      instancesList,
      search,
    } = this.state;

    let filteredData = campaignsList;

    if (search)
      filteredData = filteredData.filter((x) =>
        (x.name ? x.name.toLocaleLowerCase() : "").includes(search!)
      );

    const { length: count } = filteredData;

    return (
      <TableContainer>
        <PageInf
          title={resources.pageTitle.campaigns}
          subTitle={findInDictionaryAndReturnValue(id_instance, instancesList)}
        />
        <Left>
          <RefreshTableButton
            className="marginTopRefreshButton23px marginBottom marginRigth"
            loading={this.state.loading}
            onClick={this.getCampaignsList}
          />
        </Left>
        <FieldInfo>{resources.labels.instance}</FieldInfo>
        <SelectOnChange
          onChange={this.onChangeSelect}
          name="id_instance"
          value={id_instance}
          items={instancesList}
          disabled={loading || loading_instances}
          loading={loading || loading_instances}
          width={200}
          allowClear
          showSearch
        />
        <ProtectedComponent permission={UserPermissionType.SeeMegaUploadButton}>
          <Right>
            <MegaUpdateButton
              buttonTitle="Mega upload"
              className="marginBottom marginLeft"
              loading={this.state.loading}
              onClick={this.megaUpdate}
            />
          </Right>
        </ProtectedComponent>
        <Right>
          <UploadButton
            buttonTitle={resources.buttons.upload}
            className="marginBottom"
            loading={this.state.loading}
            onClick={this.updateCampaignsAndRefreshDataTable}
          />
        </Right>
        <Right>
          <SearchElements
            className="marginRigth"
            onSearch={this.onGlobalSearch}
            loading={loading}
          />
        </Right>
        <Right>
          <FoundRowsNumber className="marginRigth" count={count} />
        </Right>
        <CampaignsTable
          loading={loading}
          loading_instances={loading_instances}
          data={filteredData}
          instances={instancesList}
        />
      </TableContainer>
    );
  }

  getCampaignsList = async () => {
    this.setState({ loading: true });

    const id_instance =
      this.state.id_instance !== 0 ? this.state.id_instance.toString() : "";

    await getFocusCampaigns(id_instance)
      .then(
        (response) =>
          this._isMounted && this.setState({ campaignsList: response })
      )
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };

  getInstancesList = async () => {
    this.setState({ loading_instances: true });
    await getInstancesDictionaryWithCountries()
      .then(
        (response) =>
          this._isMounted && this.setState({ instancesList: response })
      )
      .finally(
        () => this._isMounted && this.setState({ loading_instances: false })
      );
  };

  setParamsFromUrl = () => {
    if (this.props.id) this.setState({ id_instance: Number(this.props.id) });
  };

  onChangeSelect = (name: string, value: number): void => {
    let id = value ? value : 0;

    this.setState({ [name]: id }, () => {
      this.getCampaignsList();
      this.setUrl();
    });
  };

  setUrl = () => {
    let url = `/cc_campaigns`;

    if (this.state.id_instance) url = url + `/${this.state.id_instance}`;

    window.history.pushState("", "", url);
  };

  updateCampaignsAndRefreshDataTable = async () => {
    this.setState({ loading: true });

    const id_instance =
      this.state.id_instance !== 0 ? this.state.id_instance.toString() : "";

    await updateFocusCampaigns(id_instance)
      .then(
        (response) =>
          response.length > 0 &&
          this._isMounted &&
          this.setState({ campaignsList: response })
      )
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };

  onGlobalSearch = (event: any) =>
    this.setState({
      [event.target.name]: event.target.value.toLocaleLowerCase(),
    });

  megaUpdate = async () => {
    this.setState({ loading: true });

    await megaUpdate().then((response) => {
      response && this.getCampaignsList();
    });
  };
}
