import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';
import { TrafficSourcesDictionaryContent } from './contents/TrafficSourcesDictionaryContent';

class TrafficSourceDictionaryViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.traffic_sources);
    }

    render() {
        return (
            <TrafficSourcesDictionaryContent />
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const TrafficSourceDictionaryView: React.ComponentClass = connect(null, mapDispatchToProps)(TrafficSourceDictionaryViewInner);