import React, { useState } from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Divider, Input, Spin } from "antd";

import { FilterCheckbox } from "../filter-checkbox/filter-checkbox";
import { createFilterData } from "../../../lib/filters-data";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

const searchAsync = (text: string) => Promise.resolve(text);
const searchDebounced = AwesomeDebouncePromise(searchAsync, 500);

export const MultipleChoiceWithSearchFilter = ({
  filter,
  entityHook,
  dataPlaceholder,
}: {
  filter: string;
  entityHook: any;
  dataPlaceholder: string;
}) => {
  const [search, setSearch] = useState("");
  const { state, dispatch } = useFilters();
  const { entity, isLoading } = entityHook(search);

  const onChangeSearch = async (value: string) => {
    const search = await searchDebounced(value.toLowerCase());
    setSearch(search);
  };

  const onChangeEntity = (isChecked: boolean, entityItem: any) => {
    const updatedEntity = isChecked
      ? [
          ...state[filter],
          createFilterData(entityItem.name, entityItem.id, filter),
        ]
      : state[filter].filter((e: any) => e.value !== entityItem.id);
    dispatch({
      type: UPDATE_FILTERS,
      payload: { [filter]: updatedEntity },
    });
  };

  const onChangeSelectAll = (isChecked: boolean) =>
    dispatch({
      type: UPDATE_FILTERS,
      payload: {
        [filter]: isChecked
          ? entity.map((entityItem: any) =>
              createFilterData(entityItem.name, entityItem.id, filter)
            )
          : [],
      },
    });

  return (
    <div>
      <>
        <Input
          type="text"
          style={{ marginBottom: "12px" }}
          onChange={(event) => onChangeSearch(event.target.value)}
          placeholder="Search"
        />

        {(!entity || (entity && entity.length === 0)) && !isLoading && (
          <span>{dataPlaceholder}</span>
        )}

        {isLoading && <Spin />}
      </>

      {!isLoading && entity && entity.length > 0 && (
        <>
          <FilterCheckbox
            label="Select all"
            checked={entity.length === state[filter].length}
            onChange={(event) => onChangeSelectAll(event.target.checked)}
          />

          <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "350px",
              overflowY: "scroll",
            }}
          >
            {entity.map((entityItem: any) => (
              <FilterCheckbox
                key={entityItem.id}
                label={entityItem.name}
                style={{ marginLeft: "0", marginBottom: "10px" }}
                checked={
                  !!state[filter].find((e: any) => e.value === entityItem.id)
                }
                onChange={(event) =>
                  onChangeEntity(event.target.checked, entityItem)
                }
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
