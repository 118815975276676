import * as React from 'react';
import { ICountriesRadioButtonsProps } from './interfaces/ICountriesRadioButtonsProps';
import { IDictionaryValue } from '../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { Radio } from 'antd';

export class CountriesRadioButtons extends React.Component<ICountriesRadioButtonsProps> {
    state = {
        selected: this.props.countriesD[0].id,
    }

    onChange = (event: any) => {
        this.setState({ selected: event.target.value });
        
        this.props.onChange(event.target.value);
    }

    public render() {
        return (
            <Radio.Group
                disabled={this.props.loading}
                className={this.props.className}
                defaultValue={this.props.defaultValue}
                buttonStyle="solid"
                onChange={this.onChange}
            >
                {this.props.countriesD.map(item => (
                    this.renderOption(item)
                ))}
            </Radio.Group>
        )
    }

    renderOption = (dropdownItem: IDictionaryValue) => {
        return (
            <Radio.Button value={dropdownItem.id} key={dropdownItem.id}>
                {dropdownItem.name}
            </Radio.Button>
        );
    }
};