import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.GetFinanceChartData);

export const getFinanceChartData = (body: any) => axios.post(getUrl, body)
    .then((res) => { 
        return res.data.data; })
    .catch(() => {
        openErrorNotification("M47 service Error -> getFinanceChartData()");
        return {
            labels: [],
            data: {
                deposit: [],
                withdrawal: [],
            },
        };
    });