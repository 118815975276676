import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import React from 'react';
import styles from './styles/OffersListFiltersForm.module.scss';
import { hasPermission } from '../../../../authorizations/AuthorizationLogic';
import { IOffersListFiltersFormProps } from './interfaces/IOffersListFiltersFormProps';
import { IOnChangeFilters } from './interfaces/IOnChangeFilters';
import { PartnerViewCheckbox } from '../../../../components/partnerViewCheckbox/PartnerViewCheckbox';
import { resources } from '../../../../common/Resources';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class OffersListFiltersForm extends React.Component<IOnChangeFilters & IOffersListFiltersFormProps> {
    state = {
        beauty: this.props.beauty ? true : false,
        health: this.props.health ? true : false,
        slimming: this.props.slimming ? true : false,
        adult: this.props.adult ? true : false,
        other: this.props.other ? true : false,
        indonesia1: this.props.indonesia1 ? true : false,
        indonesia2: this.props.indonesia2 ? true : false,
        thailand: this.props.thailand ? true : false,
        vietnam: this.props.vietnam ? true : false,
        saudiArabia: this.props.saudiArabia ? true : false,
        cpa: this.props.cpa ? true : false,
        cpl: this.props.cpl ? true : false
    }

    handleChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value === 'true' ? true : false });
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.onChangeFilters!(this.state);
        this.props.toggle();
    }

    handleReset = () => {
        this.setState({
            beauty: false,
            health: false,
            slimming: false,
            adult: false,
            other: false,
            indonesia1: false,
            indonesia2: false,
            thailand: false,
            vietnam: false,
            saudiArabia: false,
            cpa: false,
            cpl: false,
        }, () => {
            this.props.onChangeFilters!(this.state);
        })
    }
    
    render() {
        return (
            <section className={styles.grid}>
                <h3 className={`h3`}>{resources.offersView.sortBy}</h3>
                <form onSubmit={this.handleSubmit}>
                    <div className={styles.formGrid}>
                        <section>
                            <h5 className={`h5__semi`}>{resources.offersView.category}</h5>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'beauty'}
                                    value={!this.state.beauty}
                                    checked={this.state.beauty} />
                                <label className={`h6`}>{resources.labels.beauty}</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'health'}
                                    value={!this.state.health}
                                    checked={this.state.health} />
                                <label className={`h6`}>{resources.labels.health}</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'slimming'}
                                    value={!this.state.slimming}
                                    checked={this.state.slimming} />
                                <label className={`h6`}>{resources.labels.slimming}</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'adult'}
                                    value={!this.state.adult}
                                    checked={this.state.adult} />
                                <label className={`h6`}>{resources.labels.adult}</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'other'}
                                    value={!this.state.other}
                                    checked={this.state.other} />
                                <label className={`h6`}>{resources.labels.other}</label>
                            </div>
                        </section>
                        <section>
                            <h5 className={`h5__semi`}>{resources.offersView.country}</h5>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'indonesia1'}
                                    value={!this.state.indonesia1}
                                    checked={this.state.indonesia1} />
                                <label className={`h6 ${hasPermission(UserPermissionType.SeeOriginalProductCountry)
                                ? styles.originalCountryLabel : null}`}>Indonesia</label>
                            </div>
                            <ProtectedComponent permission={UserPermissionType.SeeOriginalProductCountry}>
                                <div className={styles.formItem}>
                                    <PartnerViewCheckbox
                                        onChange={this.handleChange}
                                        name={'indonesia2'}
                                        value={!this.state.indonesia2}
                                        checked={this.state.indonesia2} />
                                    <label className={`h6 ${styles.originalCountryLabel}`}>Indonesia2</label>
                                </div>
                            </ProtectedComponent>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'thailand'}
                                    value={!this.state.thailand}
                                    checked={this.state.thailand} />
                                <label className={`h6`}>Thailand</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'vietnam'}
                                    value={!this.state.vietnam}
                                    checked={this.state.vietnam} />
                                <label className={`h6`}>Vietnam</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'saudiArabia'}
                                    value={!this.state.saudiArabia}
                                    checked={this.state.saudiArabia} />
                                <label className={`h6`}>Saudi Arabia</label>
                            </div>
                        </section>
                        <section>
                            <h5 className={`h5__semi`}>{resources.offersView.conversionType}</h5>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'cpa'}
                                    value={!this.state.cpa}
                                    checked={this.state.cpa} />
                                <label className={`h6`}>CPA</label>
                            </div>
                            <div className={styles.formItem}>
                                <PartnerViewCheckbox
                                    onChange={this.handleChange}
                                    name={'cpl'}
                                    value={!this.state.cpl}
                                    checked={this.state.cpl} />
                                <label className={`h6`}>CPL</label>
                            </div>
                        </section>
                    </div>
                    <section>
                        <button className={styles.submit} type="submit">{resources.labels.search}</button>
                        {/* <button className={styles.reset} type="reset" onClick={this.handleReset}>Reset</button> */}
                    </section>
                </form>
            </section>)
    }
}