import HtmlEditorF from "../../../../../../components/templateForm/form/thmlEditor/HtmlEditorF";
import IInputColumnOptions from "../../../../../../components/_baseView/interfaces/IInputColumnOptions";
import RadioButtonF from "../../../../../../components/templateForm/form/radioButtonList/RadioButtonListF";
import React from "react";
import styles from "../../../styles/ProductDetails.module.scss";
import SwitchF from "../../../../../../components/templateForm/form/switch/SwitchF";
import TextInputF from "../../../../../../components/templateForm/form/textInput/TextInputF";
import { Button } from "antd";
import {
  findInDictionaryAndReturnValue,
  getDictionary,
} from "../../../../../../helpers/DisctionariesHelper";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { IAffbayVisibilityFormDefaultProds } from "../interfaces/IAffbayVisibilityFormDefaultProds";
import { IAffbayVisibilityFormProps } from "../interfaces/IAffbayVisibilityFormProps";
import { ImagesList } from "../components/images-list";
import { openSuccessNotification } from "../../../../../../helpers/NotificationHelper";
import { resources } from "../../../../../../common/Resources";
import { updateProduct } from "../../../../../../services/m30_products/ProductsService";

export interface IMainInformationsFormMethods {}

const columnOptionsForSwitch: IInputColumnOptions = {
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 },
};

export class AffbayVisibilityFormInner extends React.Component<
  IAffbayVisibilityFormProps &
    IMainInformationsFormMethods &
    IAffbayVisibilityFormDefaultProds &
    FormikProps<IAffbayVisibilityFormProps>
> {
  public static defaultProps = {
    readOnly: false,
    disabled: false,
    allowOfflineSearch: false,
    allowClear: false,
    labelTextAlignLeft: true,
    boldLabel: true,
  };

  public render() {
    return (
      <article>
        <Form>
          <section>
            <div className={styles.grid_col_3_switch}>
              <div>
                <SwitchF
                  {...this.props}
                  name="is_visible_partners"
                  label={resources.productsFormMain.visibleForPartners}
                  columnOptions={columnOptionsForSwitch}
                  labelTextAlignLeft={true}
                />
              </div>
              <div>
                <SwitchF
                  {...this.props}
                  name="is_visible_home"
                  label={resources.productsFormMain.visibleOnHomePage}
                  columnOptions={columnOptionsForSwitch}
                  labelTextAlignLeft={true}
                />
              </div>
              <div>
                <SwitchF
                  {...this.props}
                  name="is_restricted"
                  label={resources.productsFormMain.isRestricted}
                  columnOptions={columnOptionsForSwitch}
                  labelTextAlignLeft={true}
                />
              </div>
            </div>
            <div className={styles.sectionDivider}></div>
            <div className={styles.grid_col_3}>
              <div>
                <TextInputF
                  {...this.props}
                  isNumeric={true}
                  name="default_payout"
                  label={resources.productsFormMain.defaultGlobalPayout}
                />
              </div>
              <div>
                <TextInputF
                  {...this.props}
                  isNumeric={true}
                  name="default_landing_price"
                  label={`${
                    resources.productsFormMain.landingPriceForConsumer
                  } [${findInDictionaryAndReturnValue(
                    this.props.country_id!,
                    getDictionary("currencyD")
                  )}]`}
                />
              </div>
              <div className={styles.radioButton}>
                <RadioButtonF
                  {...this.props}
                  name="default_payout_schema"
                  label={resources.productsFormMain.defaultPayoutScheme}
                  radioButtonItems={[
                    { key: "cpa", label: "CPA" },
                    { key: "cpl", label: "CPL" },
                  ]}
                />
              </div>
            </div>
            <div className={styles.grid_col_3}>
              <div>
                <TextInputF
                  {...this.props}
                  isNumeric={true}
                  name="approve_page"
                  label={resources.productsFormMain.approveVisibleOnPage}
                />
              </div>
              <div>
                <TextInputF
                  {...this.props}
                  name="payout_visible"
                  label={resources.productsFormMain.payoutVisibleOnPage}
                />
              </div>
            </div>
            <div className={styles.sectionDivider}></div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="description_en"
                label={resources.productsFormMain.descriptionEN}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="description_ru"
                label={resources.productsFormMain.descriptionRU}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="traffic_sources_en"
                label={resources.productsFormMain.traffic_sources_en}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="traffic_sources_ru"
                label={resources.productsFormMain.traffic_sources_ru}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="terms_en"
                label={resources.productsFormMain.terms_en}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="terms_ru"
                label={resources.productsFormMain.terms_ru}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="conditions_en"
                label={resources.productsFormMain.conditions_en}
              />
            </div>
            <div>
              <HtmlEditorF
                {...this.props}
                name="conditions_ru"
                label={resources.productsFormMain.conditions_ru}
              />
            </div>
          </section>
          <div className={styles.sectionDivider}></div>
          <div>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              {resources.productsFormMain.save}
            </Button>
          </div>
          <div className={styles.sectionDivider}></div>
        </Form>

        <ImagesList productID={Number(this.props.id)} />
      </article>
    );
  }
}

const AffbayVisibilityForm = withFormik<
  IAffbayVisibilityFormProps &
    IMainInformationsFormMethods &
    IAffbayVisibilityFormDefaultProds,
  IAffbayVisibilityFormProps
>({
  mapPropsToValues: (props: IAffbayVisibilityFormProps) => {
    return {
      is_visible_partners: props.is_visible_partners,
      is_visible_home: props.is_visible_home,
      is_restricted: props.is_restricted,
      default_payout_schema: props.default_payout_schema,
      default_payout: props.default_payout
        ? Number((props.default_payout! / 100).toFixed(2))
        : undefined,
      default_landing_price: props.default_landing_price
        ? Number((props.default_landing_price! / 100).toFixed(2))
        : undefined,
      approve_page: props.approve_page
        ? Number((props.approve_page! / 100).toFixed(2))
        : undefined,
      payout_visible: props.payout_visible,
      description_en: props.description_en,
      description_ru: props.description_ru,
      traffic_sources_en: props.traffic_sources_en,
      traffic_sources_ru: props.traffic_sources_ru,
      terms_en: props.terms_en,
      terms_ru: props.terms_ru,
      conditions_en: props.conditions_en,
      conditions_ru: props.conditions_ru,
      id: props.id,
    };
  },

  validate: (values: IAffbayVisibilityFormProps) => {
    const errors: FormikErrors<IAffbayVisibilityFormProps> = {};

    if (!values.default_payout)
      errors.default_payout = resources.validation.required;
    if (!values.default_landing_price)
      errors.default_landing_price = resources.validation.required;
    if (!values.approve_page)
      errors.approve_page = resources.validation.required;

    return errors;
  },

  handleSubmit: (
    values: IAffbayVisibilityFormProps,
    bag: FormikBag<
      IAffbayVisibilityFormProps & IMainInformationsFormMethods,
      IAffbayVisibilityFormProps
    >
  ) => {
    const object: IAffbayVisibilityFormProps = {
      is_visible_partners: Boolean(values.is_visible_partners),
      is_visible_home: Boolean(values.is_visible_home),
      is_restricted: Boolean(values.is_restricted),
      default_payout_schema: values.default_payout_schema,
      default_payout: Number((values.default_payout! * 100).toFixed()),
      default_landing_price: Number(
        (values.default_landing_price! * 100).toFixed()
      ),
      approve_page: Number((values.approve_page! * 100).toFixed()),
      payout_visible: values.payout_visible,
      description_en: values.description_en,
      description_ru: values.description_ru,
      traffic_sources_en: values.traffic_sources_en,
      traffic_sources_ru: values.traffic_sources_ru,
      terms_en: values.terms_en,
      terms_ru: values.terms_ru,
      conditions_en: values.conditions_en,
      conditions_ru: values.conditions_ru,
    };

    updateProductVisibility(values.id!, object);
    window.scrollTo(0, 0);
  },
  validateOnChange: true,
  enableReinitialize: true,
})(AffbayVisibilityFormInner);

export default AffbayVisibilityForm;

const updateProductVisibility = (
  id: string,
  object: IAffbayVisibilityFormProps
) => {
  updateProduct(Number(id), object).then((response) => {
    response && openSuccessNotification(resources.statement.updated);
  });
};
