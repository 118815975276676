import * as React from 'react';
import CountriesDictionaryForm from '../forms/CountriesDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { CountriesDictionaryTable } from '../tables/CountriesDictionaryTable';
import { deleteCountriesDictionary, getCountriesDictionaryList, updateCountriesDictionary } from '../../../../services/dictionaries/CountriesDivtionaryService';
import { ICountryDictionary } from '../interfaces/ICountryDictionary';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class CountriesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        countriesList: Array<ICountryDictionary>()
    }

    componentDidMount = () => {
        this._isMounted = false;

        this.getCountriesList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.countriesDictionary} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getCountriesList}
                />
                <ProtectedModal permission={UserPermissionType.AddNewCountryModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewCountry}
                            buttonTitle={resources.modalButtonsTitle.addNewCountry}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewCountry}
                            icon={"plus"}
                            withHideMethod={true}
                            width={800}
                            buttonClassName='addNew marginBottom'
                            disabled={this.state.loading}
                        >
                            <CountriesDictionaryForm
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <CountriesDictionaryTable
                    loading={this.state.loading}
                    data={this.state.countriesList}
                    changeStatus={this.changeStatus}
                    deleteCountry={this.deleteCountry}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getCountriesList = () => {
        this.setState({ loading: true });

        getCountriesDictionaryList()
            .then(countriesList => { this._isMounted && this.setState({ countriesList }); })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    changeStatus = async (event: any) => {
        const id = Number(event.target.value);
        const query = `UPDATE li_countries SET is_active = !is_active WHERE id = ${id}`;

        await updateCountriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.changeStatusInArray(id);
                    openSuccessNotification(resources.statement.qulifiedUpdate);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        const { countriesList } = this.state;
        const index = countriesList.findIndex(x => x.id === id);

        countriesList[index].is_active = !countriesList[index].is_active;

        this.setState({ countriesList });
    }

    deleteCountry = async (event: any) => {
        const id = Number(event.target.value);
        const query = `DELETE FROM li_countries WHERE id = ${id}`;

        await deleteCountriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_country);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const countriesList = this.state.countriesList.filter(x => x.id !== id);

        this.setState({
            countriesList,
            loading: false
        });

        openSuccessNotification(resources.statement.country_created);
    }

    addObjToArray = (country: ICountryDictionary) =>
        this.setState({ countriesList: [...this.state.countriesList, country] });

    updateObjInArray = (obj: ICountryDictionary): void => {
        const { countriesList } = this.state;
        const index = countriesList.findIndex(x => x.id === obj.id);

        countriesList[index].name = obj.name;
        countriesList[index].is_operative = obj.is_operative;
        countriesList[index].is_active = obj.is_active;
        countriesList[index].company_name = obj.company_name;
        countriesList[index].address = obj.address;
        countriesList[index].email = obj.email;
        countriesList[index].phone = obj.phone;
        countriesList[index].code = obj.code;
        countriesList[index].currency = obj.currency;
        countriesList[index].updated_at = obj.updated_at;

        this.setState({ countriesList });
    }
}