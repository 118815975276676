import React from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import ReactDragListView from "react-drag-listview";

import { DataTable } from "../../../../../components/others/data/DataTable";
import { getColumnByGroup } from "../../../lib/table-data";
import { converKeysIntoTableColumns } from "../../../lib/table-data/columns";
import { USER_ROLES } from "../../../../../common/enums/UserRoles";

const { Column } = Table as any;

class StatisticTableLayout extends DataTable {
  prepareTableData = () => {
    const { data } = this.props ? this.props : [];
    return data && data.length > 0
      ? [
          // First row in the data should be total one
          { key: 0, ...this.props.totalStatistic },
          ...data.map((el: any, index: number) => ({
            key: index + 1,
            ...el,
          })),
        ]
      : [];
  };

  onDragEnd = async (fromIndex: number, toIndex: number) => {
    const { columns, changeTableColumns } = this.props;
    if (toIndex === -1) {
      return;
    }
    const columnsCopy = columns.slice();
    const item = columnsCopy.splice(fromIndex, 1)[0];
    columnsCopy.splice(toIndex, 0, item);
    changeTableColumns(columnsCopy);
  };

  render() {
    const groupByColumn = getColumnByGroup(this.props.groupBy);
    const columns = [
      <Column
        key={groupByColumn?.index}
        width={window.innerWidth > 620 ? 250 : 150}
        fixed="left"
        align="center"
        title={groupByColumn?.label}
        render={groupByColumn?.value}
        sorter={groupByColumn?.sorter}
        dataIndex={groupByColumn?.index}
        className="sticky-column"
      />,
      ...converKeysIntoTableColumns(this.props.columns, this.props.isAdmin),
    ];

    return (
      <div className={"customAnt--productDetailsTable"}>
        <ReactDragListView.DragColumn
          onDragEnd={this.onDragEnd}
          nodeSelector="th"
          lineClassName="drag-line"
          ignoreSelector=".sticky-column"
        >
          <DataTable
            data={this.prepareTableData()}
            scrollX={1800}
            columns={columns}
            loading={this.props.isLoading}
            tableName={this.props.tableName}
            showTotal={false}
            pagination={false}
            showSizeChanger={true}
            showQuickJumper={false}
          />
        </ReactDragListView.DragColumn>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const userRole = state.commonState.appUserInfo.id_users_role;
  return {
    isAdmin: USER_ROLES.ADMIN || userRole === USER_ROLES.SUPERADMIN,
  };
};

export const StatisticTable = connect(mapStateToProps)(StatisticTableLayout);
