import CreateNewOrderContext from '../../../../contexts/createNewOrderContext';
import React, { Component } from 'react';
import styles from './StepThree.module.scss';
import { BasketItem } from '../basketItem/BasketItem';

export class StepThree extends Component {
    render() {
        return (<CreateNewOrderContext.Consumer>
            {value => {
                const calculatedBasket = value.calculateBasket();
                return <div style={{ width: '100%' }}>
                    <label
                        className={`h4__bold ${styles.label}`}
                        htmlFor="fcc_id"
                    >
                        Order Summary
                    </label>
                    <article>
                        {
                            value.basket.map((basketItem: any, idx: number) => {
                                return <BasketItem
                                    key={idx}
                                    name={basketItem.name}
                                    deleteItemFromBasket={value.removeFromBasket}
                                    idx={idx}
                                    orderValue={basketItem.total_order_value}
                                    discountValue={basketItem.total_discount_value}
                                    setStepTo={value.nextStep}
                                    basketLength={value.basket.length} />
                            }
                            )}
                    </article>
                    <article className={styles.cartSummary}>
                        <div className={styles.cartSummary__totalRow}>
                            <p>TOTAL:</p>
                            <p>{(calculatedBasket[0] + calculatedBasket[2]).toFixed(2)}</p>
                        </div>
                        <div className={styles.cartSummary__discountRow}>
                            <p>Discount price</p>
                            <p>{calculatedBasket[2].toFixed(2)}</p>
                        </div>
                        <div className={styles.cartSummary__totalRow}>
                            <p>FINAL PRICE:</p>
                            <p>{calculatedBasket[0].toFixed(2)}</p>
                        </div>
                    </article>
                </div>
            }
            }
        </CreateNewOrderContext.Consumer>
        )
    }
}
