import * as React from 'react';
import { Empty, Spin } from 'antd';
import { getActiveNews } from '../../../../services/m61_news/NewsService';
import { getLanguageVersion } from '../../../../helpers/LocaleHelper';
import { IId } from '../../../../common/interfaces/IId';
import { INews } from '../../../news/newsAdmin/interfaces/INews';
import { OfferDetailsContainer } from '../../../../components/containers/offerDetailsContainer/OfferDetailsContainer';
import { resources } from '../../../../common/Resources';
import { SingleNews } from './components/singleNews/SingleNews';

export class News extends React.Component<IId> {
    _isMounted = false;

    state = {
        loading: false,
        news_list: [] as INews[]
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.getNews();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <OfferDetailsContainer>
                {
                    this.state.loading ? <Spin tip={resources.spin.loading} style={{ margin: '0px 50%' }} /> :
                        <>
                            {
                                this.state.news_list.length === 0 ? <Empty /> :
                                    <>
                                        {this.state.news_list.map((news, index) => {
                                            return <SingleNews
                                                key={index}
                                                date_from={news.date_from}
                                                id_news_type={news.id_news_type}
                                                title={getLanguageVersion() === 1 ? news.title_en : news.title_ru}
                                                body={getLanguageVersion() === 1 ? news.body_en : news.body_ru}
                                            />
                                        })}
                                    </>
                            }
                        </>
                }
            </OfferDetailsContainer>
        )
    };

    getNews = () => {
        this.setState({ loading: true });

        const object = {
            id_product: this.props.id,
            columns: [
                'date_from',
                'title_en',
                'title_ru',
                'body_en',
                'body_ru'
            ]
        };

        getActiveNews(object)
            .then(news_list => { this._isMounted && this.setState({ news_list }); })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }
}