import React from 'react';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { Switch } from 'antd';
import { changeP3ResaleRunningStatus, getP3ResalesProducts, setP3ProductRulesForResale } from '../../../../services/m66_sales/SalesService';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IId } from '../../../../common/interfaces/IId';
import { IResaleProduct } from '../interfaces/IResaleProduct';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { ResaleTimer } from '../../components/resaleTimer/ResaleTimer';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { ResalesTable } from '../table/ResaleTable';

export class ResalesContent extends React.Component<IId> {
    _isMounted = false;

    state = {
        loading: false,
        isRunning: false,
        nextRunAt: '',
        countryId: 1,
        products: [] as IResaleProduct[],
        countriesNameD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getCountriesD();

        this.getList();
        window.scrollTo(0, 0);
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { products, countryId, loading, nextRunAt, isRunning, countriesNameD } = this.state;

        let filteredProducts: IResaleProduct[] = products;

        // Filter by country
        if (countryId)
            filteredProducts = filteredProducts.filter(x => x.country_id === countryId);

        return (
            <>
                <TableContainer>
                    <PageInf title={resources.pageTitle.resaleP3} />
                    <div style={{ textAlign: "justify" }} >
                        <ResaleTimer
                            nextRunTime={nextRunAt}
                            isRunning={isRunning}
                        />
                        <Left>
                            <RefreshTableButton
                                className='marginRigth marginBottom marginTopRefreshButton23px'
                                loading={loading}
                                onClick={this.getList}
                            />
                        </Left>
                        <Left>
                            <FieldInfo>{resources.labels.country}</FieldInfo>
                            <SelectOnChange
                                onChange={this.onCountryChange}
                                name='country_id'
                                className='marginRigth marginBottom'
                                value={countryId}
                                placeholder={resources.select.select_country}
                                items={countriesNameD}
                                disabled={loading}
                                width={200}
                            />
                        </Left>
                        <Right>
                            <div className='marginBottom marginLeft marginRigth' style={{ marginTop: 29 }}>
                                {resources.labels.create_leads_on_order_delivered_status_global}
                                <Switch checked={isRunning} onChange={value => this.onChangeIsRunning(value)} />
                            </div>
                        </Right>
                        <ClearBoth />
                    </div>
                    <ResalesTable
                        data={filteredProducts}
                        toogleResale={this.toogleResale}
                        loading={loading}
                        submit={this.setProductResale}
                    />
                </TableContainer>
            </>
        )
    };

    getCountriesD = () => {
        this.setState({ countriesNameD: getDictionary('countriesNameD') });
    }

    onCountryChange = (_: string, value: number) => {
        this.setState({
            countryId: value,
        });
    }

    onChangeIsRunning = (value: boolean) => {
        this.setState({ loading: true });

        changeP3ResaleRunningStatus()
            .then(response => {
                if (response) {
                    this._isMounted && this.setState({
                        isRunning: value,
                    });
                    openSuccessNotification(resources.statement.updated);
                }
                else
                    openErrorNotification(resources.statement.databaseConnectionError);
            })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    toogleResale = (product: IResaleProduct) => {
        this.setState({ loading: true });

        setP3ProductRulesForResale({
            products: [{
                ...product,
                is_active: !product.is_resale_active,
            }]
        })
        .finally(() => { this.getList() });
    }

    setProductResale = (product: any): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            try {
                await setP3ProductRulesForResale({
                    products: [{
                        ...product,
                    }]
                })
                await this.getList();
                resolve(true);
            }
            catch (err) {
                reject(false);
            }
        });
    }

    getList = () => {
        this.setState({ loading: true });
        getP3ResalesProducts()
            .then(response => {
                this._isMounted && response && this.setState({
                    products: response.products,
                    isRunning: response.is_running,
                    nextRunAt: response.next_run_at,
                });
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }
}