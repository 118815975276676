import { Button, InputNumber, Spin, Switch } from 'antd';
import * as React from 'react';
import styles from './styles/ClickIdSettings.module.scss';
import { getClickIdSettings, getClickIdSettingsDays, setClickIdSettings, setClickIdSettingsDays } from '../../../../../services/settings/SettingsService';
import { resources } from '../../../../../common/Resources';


export class ClickIdSettings extends React.Component {
    mounted = false;
    state = {
        isEnabled: false,
        days: 0,
        isLoading: true,
    }

    componentDidMount = async () => {
        this.mounted = true;
        const settings = await this.getClickIdSettings();
        this.setState({
            ...settings,
            isLoading: false,
        });
    }

    componentWillUnmount = () => {
        this.mounted = false;
    }

    getClickIdSettings = async () => {
        const isEnabled = await getClickIdSettings();
        const days = await getClickIdSettingsDays();
        return {
            isEnabled,
            days,
        }
    }

    toogleClickIdSetting = async () => {
        this.mounted && this.setState({
            isLoading: true,
        });
        const { isEnabled } = this.state;
        await setClickIdSettings(!isEnabled);
        const settings = await this.getClickIdSettings();
        this.mounted && this.setState({
            ...settings,
            isLoading: false,
        });
    }

    changeDays = async (value) => {
        const { days } = this.state;
        if (isNaN(value)) {
            this.mounted && this.setState({
                days,
            });
            return;
        }
        this.mounted && this.setState({
            days: value,
        });
    }

    submitDays = async () => {
        this.mounted && this.setState({
            isLoading: true,
        });
        const { days } = this.state;
        await setClickIdSettingsDays(days);
        const settings = await this.getClickIdSettings();
        this.mounted && this.setState({
            ...settings,
            isLoading: false,
        });
    }

    render() {
        const { isEnabled, days, isLoading } = this.state;

        return (
            <div className={styles.container}>
                <h3>{resources.setting_page.click_id}</h3>
                {!isLoading ? <>
                    <div className={styles.row}>
                        <div>{resources.setting_page.check_click_id}</div>
                        {isEnabled !== undefined ? <Switch
                            checked={Boolean(isEnabled)}
                            onChange={this.toogleClickIdSetting}
                        /> : resources.labels.undefined}
                    </div>
                    <div className={styles.row}>
                        <div>{resources.setting_page.click_id_days}</div>
                        <div>
                            <InputNumber
                                min={1}
                                max={90}
                                value={days}
                                onChange={this.changeDays}
                            />
                            <Button
                                onClick={this.submitDays}
                                disabled={isNaN(days)}>
                                    {resources.buttons.save}
                            </Button>
                        </div>
                    </div>
                </> : <Spin />}
            </div>
        )
    };
}