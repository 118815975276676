import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button, Tooltip } from 'antd';
import { resources } from '../../../../common/Resources';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import LocationDictionaryForm from '../forms/LocationDictionaryForm';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../helpers/DisctionariesHelper';
import { convertBooleanYesOrNoReactElement } from '../../../../helpers/BooleanHelper';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { getBankTransferFilters } from '../../../../common/dictionaries/Filters';

const { Column } = Table;

export class LocationsDictionaryTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='100px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.country}
                key='country_id'
                width='120px'
                align='center'
                sorter={(a, b) => a.country_id - b.country_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.country_id, this.props.countriesD)
                    )}
            />,
            <Column
                title={resources.columnTitle.city}
                key='city_id'
                width='110px'
                align='center'
                sorter={(a, b) => a.city_id - b.city_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.city_id, this.props.citiesD)
                    )}
            />,
            <Column
                title={resources.columnTitle.province}
                key='province_id'
                width='110px'
                align='center'
                sorter={(a, b) => a.province_id - b.province_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.province_id, this.props.provincesD)
                    )}
            />,
            <Column
                title={resources.columnTitle.district}
                key='district_id'
                width='110px'
                align='center'
                sorter={(a, b) => a.district_id - b.district_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.district_id, this.props.districtsD)
                    )}
            />,
            <Column
                title={resources.columnTitle.subdistrict}
                key='subdistrict_id'
                width='130px'
                align='center'
                sorter={(a, b) => a.subdistrict_id - b.subdistrict_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.subdistrict_id, this.props.subdistrictsD)
                    )}
            />,
            <Column
                title={resources.columnTitle.postCode}
                key='post_code_id'
                width='130px'
                align='center'
                sorter={(a, b) => a.post_code_id - b.post_code_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.post_code_id, this.props.postCodesD)
                    )}
            />,
            <Column
                title={resources.columnTitle.courier}
                key='courier_id'
                width='110px'
                align='center'
                sorter={(a, b) => a.courier_id - b.courier_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.courier_id, this.props.couriersD)
                    )}
            />,
            <Column
                title={resources.columnTitle.bank_transfer}
                key='bank_transfer'
                align='center'
                width='140px'
                sorter={(a, b) => a.bank_transfer - b.bank_transfer}
                filters={getBankTransferFilters()}
                onFilter={(value, record) => record.bank_transfer === value}
                render={(record) => (
                    convertBooleanYesOrNoReactElement(record.bank_transfer)
                )}
            />,
            <Column
                title={resources.columnTitle.lead_time}
                dataIndex='leadtime'
                key='leadtime'
                align='center'
                width='120px'
                sorter={(a, b) => (a.leadtime ? a.leadtime : "").localeCompare(b.leadtime ? b.leadtime : "")}
                {...this.getColumnSearchProps('leadtime')}
            />,
            <Column
                title={resources.columnTitle.lead_time_text}
                dataIndex='leadtime_text'
                key='leadtime_text'
                align='center'
                width='150px'
                sorter={(a, b) => (a.leadtime_text ? a.leadtime_text : "").localeCompare(b.leadtime_text ? b.leadtime_text : "")}
                {...this.getColumnSearchProps('leadtime_text')}
            />,
            <Column
                title={resources.columnTitle.district_code}
                dataIndex='destination_code'
                key='destination_code'
                align='center'
                width='140px'
                sorter={(a, b) => (a.destination_code ? a.destination_code : "").localeCompare(b.destination_code ? b.destination_code : "")}
                {...this.getColumnSearchProps('destination_code')}
            />,
            <Column
                title={resources.columnTitle.island}
                dataIndex='island'
                key='island'
                align='center'
                width='95px'
                sorter={(a, b) => (a.island ? a.island : "").localeCompare(b.island ? b.island : "")}
                {...this.getColumnSearchProps('island')}
            />,
            <Column
                title={resources.columnTitle.dalivery_cost}
                dataIndex='delivery_cost'
                key='delivery_cost'
                align='center'
                width='140px'
                sorter={(a, b) => (a.delivery_cost ? a.delivery_cost : "").localeCompare(b.delivery_cost ? b.delivery_cost : "")}
                {...this.getColumnSearchProps('delivery_cost')}
            />,
            <Column
                title={resources.columnTitle.resturn_cost}
                dataIndex='return_cost'
                key='return_cost'
                align='center'
                width='130px'
                sorter={(a, b) => (a.return_cost ? a.return_cost : "").localeCompare(b.return_cost ? b.return_cost : "")}
                {...this.getColumnSearchProps('return_cost')}
            />,
            <Column
                title={resources.columnTitle.insurance_fee}
                dataIndex='insurance_fee'
                key='insurance_fee'
                align='center'
                width='150px'
                sorter={(a, b) => (a.insurance_fee ? a.insurance_fee : "").localeCompare(b.insurance_fee ? b.insurance_fee : "")}
                {...this.getColumnSearchProps('insurance_fee')}
            />,
            <Column
                title={resources.columnTitle.code_fee}
                dataIndex='cod_fee'
                key='cod_fee'
                align='center'
                width='130px'
                sorter={(a, b) => (a.cod_fee ? a.cod_fee : "").localeCompare(b.cod_fee ? b.cod_fee : "")}
                {...this.getColumnSearchProps('cod_fee')}
            />,
            <Column
                title={resources.columnTitle.admin_fee}
                dataIndex='admin_fee'
                key='admin_fee'
                align='center'
                width='130px'
                sorter={(a, b) => (a.admin_fee ? a.admin_fee : "").localeCompare(b.admin_fee ? b.admin_fee : "")}
                {...this.getColumnSearchProps('admin_fee')}
            />,
            <Column
                title={resources.columnTitle.deleted_at}
                key='deleted_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.deleted_at).diff(moment.utc(b.deleted_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.deleted_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='75px'
                align='center'
                fixed= 'right'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditLocationModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editLocation}
                                buttonTooltip={resources.modalTooltips.editLocation}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={1000}
                            >
                                <LocationDictionaryForm
                                    id={record.id}
                                    country_id={record.country_id}
                                    city_id={record.city_id}
                                    province_id={record.province_id}
                                    district_id={record.district_id}
                                    subdistrict_id={record.subdistrict_id}
                                    post_code_id={record.post_code_id}
                                    courier_id={record.courier_id}
                                    bank_transfer={record.bank_transfer}
                                    deleted_at={record.deleted_at}
                                    created_at={record.created_at}
                                    updated_at={record.updated_at}
                                    leadtime={record.leadtime}
                                    destination_code={record.destination_code}
                                    island={record.island}
                                    leadtime_text={record.leadtime_text}
                                    delivery_cost={record.delivery_cost}
                                    return_cost={record.return_cost}
                                    insurance_fee={record.insurance_fee}
                                    cod_fee={record.cod_fee}
                                    admin_fee={record.admin_fee}
                                    updateObjInArray={this.props.updateObjInArray}
                                    countriesD={this.props.countriesD}
                                    citiesD={this.props.citiesD}
                                    districtsD={this.props.districtsD}
                                    subdistrictsD={this.props.subdistrictsD}
                                    postCodesD={this.props.postCodesD}
                                    couriersD={this.props.couriersD}
                                    provincesD={this.props.provincesD}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.DeleteLocationButton}>
                            <Tooltip placement='rightTop' title={'Delete location'}>
                                <Button icon='delete' onClick={() => this.props.deleteLocation(record.id)} />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='StatusesDictionaryTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }
}