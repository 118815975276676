import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { INewsCreate } from './interfaces/INewsCreate';
import { INewsUpdate } from './interfaces/INewsUpdate';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.NewsGet);
const createUrl = createGatewayAbsoluteUrl(resourceDef.NewsCreate);
const updateUrl = createGatewayAbsoluteUrl(resourceDef.NewsUpdate);
const deleteUrl = createGatewayAbsoluteUrl(resourceDef.NewsUpdate);
const activeNewsUrl = createGatewayAbsoluteUrl(resourceDef.ActiveNewsGet);

export const getNews = (object?: any) => axios.post(getUrl, { ...object })
    .then(response => { return response.data; })
    .catch(error => {
        console.log(error.response);
        openErrorNotification('getNews M61');
        return [];
    });

export const getActiveNews = (object?: any) => axios.post(activeNewsUrl, { ...object })
    .then(response => { return response.data; })
    .catch(error => {
        console.log(error.response);
        openErrorNotification('getActiveNews M61');
        return [];
    });

export const createNews = (object: INewsCreate) => axios.put(createUrl, { ...object })
    .then(response => { return response.data; })
    .catch(error => {
        console.log(error.response);
        openErrorNotification('createNews M61');
        return {};
    });

export const updateNews = (object: INewsUpdate, id_news: number) => axios.patch(updateUrl + id_news, { ...object })
    .then(() => { return true; })
    .catch(error => {
        console.log(error.response);
        openErrorNotification('updateNews M61');
        return false;
    });

export const deleteNews = (id_news: number) => axios.delete(deleteUrl + id_news)
    .then(() => { return true; })
    .catch(error => {
        console.log(error.response);
        openErrorNotification('deleteNews M61');
        return false;
    });