const initialValues = {
    city: '',
    province: '',
    district: '',
    subdistrict: '',
    postalCode: '',
    address: '',
    address2: '',
    locationId: -1,
};

export default initialValues;
