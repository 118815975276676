import React from 'react';
import styles from './styles/ResetPasswordForm.module.scss';
import TextInputF from '../../../components/templateForm/form/textInput/TextInputF';
import { createAbsoluteUrl } from '../../../helpers/UrlCreator';
import { emailIsValid } from '../../../helpers/ValidationHelpers';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IOpenModal } from './interfaces/IOpenModal';
import { IPasswordReset } from '../../../services/m29_users_management/interfaces/IPasswordReset';
import { IPasswordToken } from '../interfaces/IPasswordToken';
import { IResetPasswordFormValues as IFormValues } from './interfaces/IResetPasswordFormValues';
import { resetPassword, sendResetPasswordMail } from '../../../services/m29_users_management/PasswordService';
import { resources } from '../../../common/Resources';

export class InnerResetPasswordForm extends React.Component<IOpenModal & IPasswordToken & FormikProps<IFormValues>> {
    state = {
        disable_button: false
    }

    public render() {
        return (
            <section className={styles.grid}>
                <Form>
                    <div className={styles.formItem}>
                        <TextInputF
                            {...this.props}
                            name="email"
                            label={resources.resetPassword.email}
                            labelTextAlignLeft
                            allowClear
                            required
                        />
                    </div>
                    {
                        this.props.password_token &&
                        <div className={styles.formItem}>
                            <TextInputF
                                {...this.props}
                                name="new_password"
                                label={resources.resetPassword.new_password}
                                labelTextAlignLeft
                                isPassword
                                allowClear
                                required
                            />
                        </div>
                    }
                    <div className={styles.formFooter}>
                        <button
                            className={styles.submit}
                            type="submit"
                            disabled={this.state.disable_button}
                            onClick={() => { this.props.submitForm(); this.disableButton(); }}
                        >
                            {resources.resetPassword.set_new_password}
                        </button>
                        <p>
                            {resources.resetPassword.remember} <a className={`${styles.link} ${styles.link__inline}`} href={createAbsoluteUrl('login')}>{resources.resetPassword.logIn}</a>
                        </p>
                        {this.props.password_token &&
                            <p>
                                <a className={`h6__semibold ${styles.link}`} href={createAbsoluteUrl('resetPassword')}>{resources.loginPage.send_request_again}</a>
                            </p>
                        }
                    </div>
                </Form>
            </section>
        );
    };

    disableButton = () => {
        this.setState({ disable_button: true });
        setTimeout(() => this.setState({ disable_button: false }), 5000);
    }
}

const ResetPasswordForm = withFormik<IOpenModal & IPasswordToken, IFormValues>({
    mapPropsToValues: (props: IPasswordToken) => {
        return {
            email: undefined,
            new_password: undefined,
            password_token: props.password_token
        };
    },

    validate: (values: IFormValues, props: IPasswordToken) => {
        const errors: FormikErrors<IFormValues> = {};

        if (!values.email)
            errors.email = resources.validation.emailIsRequired;
        else if (values.email && !emailIsValid(values.email))
            errors.email = resources.validation.incorrectEmailAdres;
        else if (values.email && values.email.length > 255)
            errors.email = resources.validation.requiredMax255Characters;

        if (props.password_token) {
            if (!values.new_password)
                errors.new_password = resources.validation.required;
            else if (values.new_password) {
                const newPasswordError = validatePasswordRules(values.new_password)

                if (newPasswordError)
                    errors.new_password = newPasswordError;
            }
        }

        return errors;
    },

    handleSubmit: (values: IFormValues, bag: FormikBag<IOpenModal, IFormValues>) => {
        const object: IPasswordReset = {
            email: values.email!,
            newPassword: values.new_password
        }

        if (values.password_token) {
            resetPasswordAction(object, values.password_token, bag.props, bag.setFieldError, bag.resetForm);
        }
        else {
            sendResetPasswordAction(object, bag.props, bag.setFieldError, bag.resetForm);
        }
    },

    validateOnBlur: true,
    validateOnChange: true,
})(InnerResetPasswordForm);

export default ResetPasswordForm;

const validatePasswordRules = (new_password: string): string | undefined => {
    const reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    const specChar = new RegExp('[!@#$%^&*]', 'g');
    const specMatches = new_password.match(specChar);
    if (!reg.test(new_password) || !Array.isArray(specMatches) || specMatches.length < 2) {
        return resources.validation.passwordCondition;
    }
    return undefined;
}

const resetPasswordAction = (
    object: IPasswordReset,
    token: string,
    bag: IOpenModal & IFormValues,
    setFieldError: (field: any, errorMessage: string) => void,
    resetForm: () => void
) => {
    resetPassword(object, token)
        .then(response => {
            if (response.error) {
                switch (response.status) {
                    case 400:
                        setFieldError('email', `Parameter token or email or newPassword missing / Validation error.`);
                        break;
                    case 410:
                        setFieldError('new_password', `Token expired.`);
                        break;
                    case 422:
                        setFieldError('new_password', `Password Request not found.`);
                        break;
                    default:
                        setFieldError('email', 'Unrecognized error.');
                        break;
                }
            }
            else {
                bag.openModal();
                resetForm();
            }
        });
}

const sendResetPasswordAction = (
    object: IPasswordReset,
    bag: IOpenModal & IFormValues,
    setFieldError: (field: any, errorMessage: string) => void,
    resetForm: () => void
) => {

    sendResetPasswordMail(object)
        .then(response => {
            if (response.error) {
                switch (response.status) {
                    case 400:
                        setFieldError('email', `Parameter Email missing.`);
                        break;
                    case 422:
                        setFieldError('email', `User not found.`);
                        break;
                    default:
                        setFieldError('email', 'Unrecognized error');
                }
            }
            else {
                bag.openModal();
                resetForm();
            }
        });
}