import React from 'react';
import { IId } from '../../../../../../common/interfaces/IId';
import { PixelsContent } from '../components/pixels/contents/PixelsContent';

export class CnversionsContent extends React.Component<IId> {
    public render() {
        return (
            <PixelsContent id={this.props.id} />
        );
    };
}