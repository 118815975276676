import React from 'react';
import styles from './styles/TargetUrlForm.module.scss';
import { createTargetUrlCampaign } from '../../../../../services/TargetUrlCamaigns';
import { ITargetUrlForm } from './interfaces/ITargetUrlForm';
import { Modal, Row } from 'antd';
import { OfferDetailsContainer } from '../../../../../components/containers/offerDetailsContainer/OfferDetailsContainer';
import { resources } from '../../../../../common/Resources';
import { TargetUrlLanding } from './components/targetUrlLanding/TargetUrlLanding';

const { confirm } = Modal;

export class TargetUrlForm extends React.Component<ITargetUrlForm> {
    state = {
        landing: Array<number>(),
        prelanding: Array<number>(),
        pages: Array<any>(),
        campaignName: '',
        errorsVisible: 0
    }

    componentDidMount = () => {
        this.setState({ pages: this.props.pages });
    }

    idExistsInArr = (id: number, arr: number[]): number[] => {
        // check if given id in given arr - if true: delete this element | if false add this element and sort array
        return arr.indexOf(id) < 0 ? arr.concat(id).sort() : arr.filter(el => el !== id);
    }

    onLandingChange = (event: any): void => {
        const landingArr = this.state.landing;

        this.setState({ landing: this.idExistsInArr(Number(event.target.value), landingArr) });
    }

    onPreLandingChange = (event: any): void => {
        const prelandingArr = this.state.prelanding;

        this.setState({ prelanding: this.idExistsInArr(Number(event.target.value), prelandingArr) });
    }

    onInputChange = (event: any): void =>
        this.setState({ campaignName: event.target.value });

    handleSubmit = (e: any): void => {
        e.preventDefault();

        confirm({
            title: resources.labels.are_you_sure,
            content: resources.labels.do_you_want_to_generate_this_campaign,
            okText: resources.buttons.yes,
            okType: 'primary',
            cancelText: resources.buttons.no,
            onOk: () => { this.validation() ? this.submit() : this.displayErrors(); },
            onCancel() { },
        });

    }

    validation = (): boolean => {
        const con1 = this.state.landing.length > 0 ? true : false;
        // const con2 = this.state.prelanding.length > 0 ? true : false;
        const con2 = true;
        const con3 = this.state.campaignName.length > 0 ? true : false;

        return (con1 && con2 && con3);
    }

    submit = async () => {
        const data = {
            productId: Number(this.props.id),
            lp: this.state.landing!,
            pre: this.state.prelanding!,
            name: this.state.campaignName,
            redirectType: '302',
            redirectRation: 'auto'
        }

        await createTargetUrlCampaign(data)
            .then((response) => {
                response && this.props.reRenderTable!();
                this.setState({
                    landing: [],
                    prelanding: [],
                    campaignName: '',
                    errorsVisible: 0
                });
            });
    }

    displayErrors = (): void =>
        this.setState({ errorsVisible: 1 });

    public render() {
        const { pages } = this.state;

        return (
            <OfferDetailsContainer>
                {pages && pages.length > 0
                    ?
                    <>
                        <article className={styles.urlGeneratorContainer}>
                            <h6 className={`h6__bold ${styles.urlGenerator}`}>{resources.offersDetails.step_1}</h6>
                        </article>
                        <form onSubmit={this.handleSubmit}>
                            <article className={styles.grid}>
                                {/* landings list */}
                                <div>
                                    <p className={`h6__bold ${styles.counterLabel}`}>
                                        {resources.offersDetails.landings}
                                        <span className={styles.counter}>
                                            ({(pages.filter(pages => !pages['is_prelanding'])).length})
                                        </span>
                                    </p>
                                    {pages.filter(pages => !pages['is_prelanding']).map(page => {
                                        return <TargetUrlLanding
                                            key={page['id']}
                                            id={page['id']}
                                            name={page['name']}
                                            url={page['url']}
                                            is_top={page['is_top']}
                                            is_desktop={page['is_desktop']}
                                            is_mobile={page['is_mobile']}
                                            onChange={this.onLandingChange}
                                            selected={this.state.landing} />
                                    })}
                                </div>
                                <div>
                                    {/* prelandings list */}
                                    <p className={`h6__bold ${styles.counterLabel}`}>{resources.offersDetails.prelandings} <span className={styles.counter}>({(pages.filter(page => page['is_prelanding'])).length})</span></p>
                                    {pages.filter(pages => pages['is_prelanding']).map(page => {
                                        return <TargetUrlLanding
                                            key={page['id']}
                                            id={page['id']}
                                            name={page['name']}
                                            url={page['url']}
                                            is_top={page['is_top']}
                                            is_desktop={page['is_desktop']}
                                            is_mobile={page['is_mobile']}
                                            onChange={this.onPreLandingChange}
                                            selected={this.state.prelanding} />
                                    })}
                                </div>
                            </article>
                            <article className={styles.urlGeneratorContainer}>
                                <h6 className={`h6__bold ${styles.urlGenerator}`}>{resources.offersDetails.step_2}</h6>
                                <div className={`${styles.campaignNameContainer}`}>
                                    <div>
                                        <label className={`h6 ${styles.campaignName}`}>{resources.offersDetails.campaign_name}</label>
                                        <input className={styles.input} type="text" required value={this.state.campaignName} onChange={this.onInputChange} />
                                    </div>
                                    <button className={styles.submit} type="submit">{resources.offersDetails.generateURL}</button>
                                </div>
                            </article>
                            <article style={{ opacity: this.state.errorsVisible }} className={styles.errors}>
                                <p>{resources.offersDetails.youHaveTo}</p>
                            </article>
                            <Row></Row>
                        </form>
                    </>
                    :
                    <p>{resources.offersDetails.noLandings}</p>
                }
            </OfferDetailsContainer>
        );
    };
}