import * as React from 'react';
import { CampaignsContent } from './contents/CampaignsContent';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IDetailsParamsProps } from './interfaces/IDetailsParamsProps';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class CampaignsViewInner extends React.Component<ISetActiveMenuItem & RouteComponentProps<IDetailsParamsProps>> {
    componentDidMount = async () =>
        this.props.setActiveMenuItem(navPath.cc_campaigns);

    public render() {
        return (
            <CampaignsContent name={this.props.match.params.name} id={this.props.match.params.id} />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const CampaignsView: React.ComponentClass<RouteComponentProps<IDetailsParamsProps>> = connect(null, mapDispatchToProps)(CampaignsViewInner);
