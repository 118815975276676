import * as React from 'react';
import { connect } from 'react-redux';
import { IProtectedComponentProps } from '../../../authorizations/interfaces/IProtectedComponentProps';
import { IProtectedProps } from '../interfaces/IProtectedProps';
import { mapStateToProps } from '../../../authorizations/AuthorizationLogic';

class ProtectedComponentInner extends React.Component<IProtectedComponentProps & IProtectedProps> {
    public render() {
        return (
            <> {this.props.hasPermission ? this.props.children : ""} </>
        )
    }
}

const ProtectedComponent: React.ComponentClass<IProtectedComponentProps> = connect(mapStateToProps)(ProtectedComponentInner);

export default ProtectedComponent;