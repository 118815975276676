import Col, { ColProps } from 'antd/lib/grid/col';
import React, { ReactElement } from 'react';
import { IBaseViewComponentProps } from './interfaces/IBaseViewComponentProps';
import { StringBuilder } from 'typescript-string-operations';
import './styles/BaseViewComponent.scss';

export class BaseViewComponent extends React.Component<IBaseViewComponentProps>  {
    public render() {
        const labelCol: ColProps | undefined = this.props.columnOptions ? this.props.columnOptions.labelCol ? this.props.columnOptions.labelCol : { xs: 12 } : undefined;
        const wrapperCol: ColProps | undefined = this.props.columnOptions ? this.props.columnOptions.wrapperCol ? this.props.columnOptions.wrapperCol : { xs: 12 } : undefined;
        const labelClassNames = this.generateLabelClassName();
        const childrenClassName = this.props.inputWithoutBorders ? "wrapper input-without-borders" : "wrapper";
        const labelValue = this.props.label && this.props.label.length > 0 ? this.props.label : undefined;
        const requiredStar = <label style={{ color: 'red' }}> * </label>;

        return (
            <>
                {labelValue &&
                    <Col {...labelCol} className="form-control-column-label">
                        <label className={labelClassNames}>{labelValue} {this.props.required ? requiredStar : undefined}</label>
                    </Col>
                }
                <Col {...wrapperCol} /*className="form-control-column-input"*/>
                    {React.Children.map(this.props.children, child => React.cloneElement(child as ReactElement<any>, { className: childrenClassName }))}
                </Col>
            </>
        );
    }

    private generateLabelClassName(): string {
        const builder = new StringBuilder("label-control");

        if (this.props.labelTextAlignLeft) {
            builder.Append(" label-text-align-left");
        }
        if (this.props.boldLabel) {
            builder.Append(" font-weight-bold");
        }
        
        return builder.ToString();
    }
}