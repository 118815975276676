import React from "react";
import { Alert } from "antd";

import { VerificationForm } from "./verification-form";
import { useVerifyMutation } from "../../lib/hooks";
import { VerificationTrace } from "./verification-trace";

export const VerificationToolView = () => {
  const { response, mutate, reset } = useVerifyMutation();

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              display: "block",
              fontWeight: "bold",
              fontSize: "19px",
            }}
          >
            Phone number validation tool
          </span>

          <span
            style={{
              fontSize: "15px",
            }}
          >
            Perform phone number validation with our validation tool
          </span>
        </div>

        <VerificationForm
          onVerifyMutation={(data) => mutate(data)}
          onResetMutation={() => reset()}
        />

        {response && (
          <>
            <Alert
              type={response.error ? "error" : "success"}
              style={{ width: "100%", marginBottom: "20px" }}
              message={`${response.phone_original} ${
                response.error
                  ? "is not valid and was not accepted"
                  : "is valid and was accepted"
              }`}
              showIcon
              description={response.error}
            />

            <VerificationTrace trace={response.trace} />
          </>
        )}
      </div>
    </div>
  );
};
