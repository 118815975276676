import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createAffbayClassifier, updateAffbayClassifier } from '../../../../services/callCenter/AffbayClassifiersService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IMappingClassifiers } from '../interfaces/IMappingClassifiers';
import { IMappingClassifiersFormProps } from '../interfaces/IMappingClassifiersFormProps';
import { ISetLoading } from '../../../../common/interfaces/ISetLoading';
import { IUpdateArray } from '../../../../common/interfaces/IUpdateArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
export class TemplateMappingClassifiersForm extends React.Component<IMappingClassifiersFormProps & IHideModal & IAddObjToArray & IId & FormikProps<IMappingClassifiers>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        labelTextAlignLeft: true,
        boldLabel: true
    }

    public render() {
        return (
            <Form>
                {this.props.name && <div className='center'><b className='classifierName'>{this.props.name}</b></div>}
                <TextInputF
                    {...this.props}
                    name='name'
                    label={resources.labels.name}
                    disabled={Boolean(this.props.id)}
                    allowClear
                    required
                    boldLabel
                />
                <DictionaryF
                    {...this.props}
                    name='status'
                    label={resources.labels.status}
                    dictionaryValues={getDictionary('statusesD')}
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='is_qualified'
                    label={resources.labels.is_qualified}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                />
                <DictionaryF
                    {...this.props}
                    name='is_contacted'
                    label={resources.labels.is_contacted}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                />
                <TextAreaF
                    {...this.props}
                    name='postback_comment'
                    label={resources.labels.comment}
                    labelTextAlignLeft
                    boldLabel
                    allowClear
                />
                <Button
                    className='saveButton'
                    htmlType='submit'
                    type='primary'
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form>
        );
    };
}

const MappingClassifiersForm = withFormik<IMappingClassifiersFormProps & IAddObjToArray & ISetLoading, IMappingClassifiers>({
    mapPropsToValues: (props: IMappingClassifiersFormProps) => {
        return {
            name: props.name,
            status: props.status,
            is_qualified: Boolean(props.is_qualified) ? '1' : '0',
            is_contacted: Boolean(props.is_contacted) ? '1' : '0',
            postback_comment: props.postback_comment
        };
    },

    validate: (values: IMappingClassifiers) => {
        const errors: FormikErrors<IMappingClassifiers> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (!values.status) {
            errors.status = resources.validation.required;
        }

        else if (values.postback_comment && values.postback_comment.length > 255) {
            errors.postback_comment = resources.validation.requiredMax255Characters;
        }

        return errors;
    },

    handleSubmit: (values: IMappingClassifiers, bag: FormikBag<IMappingClassifiersFormProps & IHideModal & IId & IUpdateArray & IAddObjToArray & ISetLoading, IMappingClassifiers>) => {
        const object: IMappingClassifiers = {
            name: values.name,
            status: values.status,
            is_qualified: values.is_qualified,
            is_contacted: values.is_contacted,
            id: bag.props.id,
            postback_comment: values.postback_comment ? values.postback_comment : ''
        }

        createQuery(object, bag.props);
    }

})(TemplateMappingClassifiersForm);

export default MappingClassifiersForm;

const createQuery = async (obj: IMappingClassifiers, bag: IHideModal & IId & IUpdateArray & IAddObjToArray & ISetLoading) => {
    bag.setLoading!(true);

    if (bag.id) {
        const object = {
            id: obj.id,
            name: obj.name,
            lib_status: obj.status,
            lib_is_qualified: obj.is_qualified,
            lib_is_contacted: obj.is_contacted,
            postback_comment: obj.postback_comment
        }

        updateAffbayClassifier(object)
            .then((response) => {
                const result: any = response;

                if (result.status === 409)
                    openAlertNotification(result.message);
                else {
                    const object = {
                        id: obj.id,
                        key: obj.id,
                        name: obj.name,
                        status: obj.status,
                        is_qualified: obj.is_qualified,
                        is_contacted: obj.is_contacted,
                        postback_comment: obj.postback_comment,
                        updated_at: Date
                    };

                    bag.updateArray!(obj.id!, object);

                    openSuccessNotification(resources.statement.system_classifier_updated);
                }

                bag.setLoading!(false);
            });
    }
    else {
        const object = {
            name: obj.name,
            lib_status: obj.status,
            lib_is_qualified: obj.is_qualified,
            lib_is_contacted: obj.is_contacted,
            postback_comment: obj.postback_comment,
            li_classifiers_cc: true
        }

        createAffbayClassifier(object)
            .then((response) => {
             
                if (response) {
                    const object = {
                        id: response,
                        key: response,
                        name: obj.name,
                        status: obj.status,
                        is_qualified: obj.is_qualified,
                        is_contacted: obj.is_contacted,
                        postback_comment: obj.postback_comment,
                        updated_at: new Date(),
                        created_at: new Date()
                    };

                    bag.addObjToArray!(object);

                    openSuccessNotification(resources.statement.system_classifier_created);
                }

                bag.setLoading!(false);
            });
    }

    bag.hideModal!();
}