import React from 'react';
import { Table, Tooltip, Button } from 'antd';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../helpers/DisctionariesHelper';
import { LeadsRedirectionForm } from '../forms/LeadsRedirectionForm';
import { displayIconStatusWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import { getActivityFilters } from '../../../../common/dictionaries/Filters'

const { Column } = Table;

export class LeadsRedirectionTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='70px'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.is_active, resources.columnTitle.is_active_short)}
                key='is_active'
                align='center'
                width='100px'
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                sorter={(a, b) => a.is_active - b.is_active
                }
                render={(record) => (
                    displayIconStatusWithTooltip(Boolean(record.is_active))
                )}
            />,
            <Column
                title={resources.columnTitle.partner}
                key='id_partner'
                width='300px'
                align='center'
                sorter={(a, b) => a.id_partner - b.id_partner}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.partner_id, this.props.partnersD)
                    )}
            />,
            <Column
                title={resources.columnTitle.country}
                key='id_country'
                width='120px'
                align='center'
                sorter={(a, b) => a.country_id - b.country_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.country_id, this.props.countriesD)
                    )}
            />,
            <Column
                title={resources.columnTitle.campaign_name}
                key='campaign_id'
                width='250px'
                align='center'
                sorter={(a, b) => a.campaign_id - b.campaign_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.campaign_id, this.props.campaignsD)
                    )}
            />,
            <Column
                title={resources.columnTitle.product}
                key='product_id'
                width='250px'
                align='center'
                sorter={(a, b) => a.product_id - b.product_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.product_id, this.props.productsD)
                    )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                align='center'
                width='60px'
                render={(record) => {
                    return (
                        <>
                            <ProtectedModal permission={UserPermissionType.EditLeadsDirectionModalButton}>
                                <Tooltip placement="rightTop" title={resources.tooltips.editPage}>
                                    <InformationModalDialog
                                        modalTitle={resources.modalTitle.editLeadsDirection}
                                        icon={"form"}
                                        withHideMethod={true}
                                    >
                                        <LeadsRedirectionForm
                                            id={record.id}
                                            id_partner={record.partner_id}
                                            id_country={record.country_id}
                                            id_product={record.product_id}
                                            id_campaign={record.campaign_id}
                                            partnersD={this.props.partnersD}
                                            productsD={this.props.productsD}
                                            countriesD={this.props.countriesD}
                                            campaignsD={this.props.campaignsD}
                                            updateObjInArray={this.props.updateObjInArray}
                                        />
                                    </InformationModalDialog>
                                </Tooltip>
                            </ProtectedModal>
                            < ProtectedComponent permission={UserPermissionType.ChangeLeadsRedirectionStatusButton}>
                                {record.is_active === 1 ?
                                    <Tooltip
                                        placement='rightBottom'
                                        title={resources.tooltips.changeStatus}
                                    >
                                        <Button
                                            value={record.id}
                                            onClick={this.props.deleteLeadsDictionary}
                                            icon={record.is_active ? 'close' : 'check'}
                                            style={{ color: record.is_active ? 'red' : 'green' }}
                                        />
                                    </Tooltip>
                                    :
                                    <Button
                                        value={record.id}
                                        onClick={this.props.changeStatus}
                                        icon={record.is_active ? 'close' : 'check'}
                                        style={{ color: record.is_active ? 'red' : 'green' }}
                                    />
                                }
                            </ProtectedComponent>
                        </>
                    )
                }}
            />
        ]

        return (
            <DataTable
                tableName='LeadsRedirectionTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
            />
        )
    }
}