import React, { Fragment } from 'react';
import { FormikProps } from 'formik';
import styles from '../../../contents/styles/CreateNewOrderContent.module.scss';
import { ICourierInformation } from './ICourierInformation';
import TextInputF from '../../../../../components/templateForm/form/textInput/TextInputF';

export default class CourierInformation extends React.Component<FormikProps<ICourierInformation>> {

    render() {
        return (
            <Fragment>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='courierName'
                            label={'Courier Name'}
                            required
                            disabled
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='sourceWarehouse'
                            label={'Source Warehouse'}
                            required
                            disabled
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='leadTime'
                            label={'Lead Time'}
                            required
                            disabled
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='destinationCode'
                            label={'Destination Code'}
                            required
                            disabled
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}