import * as React from 'react';
import { IProductId } from '../../../../common/interfaces/IProductId'; 
import { PostbackSettingsContent } from '../../../_common/postbacksSettings/contents/PostbackSettingsContent';

export class Postbacks extends React.Component<IProductId> {
    public render() {
        return (
            <PostbackSettingsContent productId={this.props.productId} />
        )
    };
}