import axios from "axios";
import { createGatewayAbsoluteUrl } from "../../helpers/UrlCreator";
import {
  openAlertNotification,
  openErrorNotification,
} from "../../helpers/NotificationHelper";
import { resourceDef } from "../../common/ResourceDef";

const payoutsUrl = createGatewayAbsoluteUrl(resourceDef.Payouts);
const updatePayoutsUrl = createGatewayAbsoluteUrl(resourceDef.UpdatePayouts);
const payoutsHistoryUrl = createGatewayAbsoluteUrl(resourceDef.PayoutsHistory);
const payoutsFutureProxyUrl = createGatewayAbsoluteUrl(
  resourceDef.PayoutsFutureProxy
);
const payoutsActualProxyUrl = createGatewayAbsoluteUrl(
  resourceDef.PayoutsActualProxy
);

export const getPayouts = (userId: number) =>
  axios
    .get(payoutsUrl + `?userId=${userId}`)
    .then((response) => response.data)
    .catch((error) => {
      openErrorNotification(error.message);
      return [];
    });

export const getFutureProxyPayouts = (userId: number, productId: number) =>
  axios
    .post(payoutsFutureProxyUrl, { userId, productId })
    .then((response) => response.data)
    .catch((error) => {
      openErrorNotification(error.message);
      return [];
    });

export const getActualProxyPayouts = (userId: number, productId: number) =>
  axios
    .post(payoutsActualProxyUrl, { userId, productId })
    .then((response) => response.data)
    .catch((error) => {
      openErrorNotification(error.message);
      return [];
    });

export const getPayoutsHistory = (userId: number, productId: number) =>
  axios
    .post(payoutsHistoryUrl, { userId, productId })
    .then((response) => response.data)
    .catch((error) => {
      openErrorNotification(error.message);
      return [];
    });

export const updatePayouts = (data: any, userId: number) =>
  axios
    .post(updatePayoutsUrl + `?userId=${userId}`, [...data])
    .then((response) => {
      const result: any = response;

      if (result.data.status === 208) {
        openAlertNotification(result.data.message);
        return false;
      } else {
        return true;
      }
    })
    .catch((error) => {
      openErrorNotification(error.response.data.message);
      return false;
    });
