import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import React from 'react';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import RangePickerF from '../../../../components/templateForm/form/rangePicker/RangePickerF';
import { Button, Modal } from 'antd';
import { clearTransaction, rejectTransaction } from '../../../../services/m47_balance_operations/TransactionActionsService';
import { createDepositTransaction } from '../../../../services/m47_balance_operations/DepositTransactionService';
import { createWithdrawalTransaction } from '../../../../services/m47_balance_operations/WithdrowTransactionService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { ICreateTransaction } from '../../../../services/m47_balance_operations/interfaces/ICreateTransaction';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IRefreshList } from '../../../../common/interfaces/IRefreshList';
import { ITransactionProps } from './interfaces/ITransactionProps';
import { openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

const { confirm } = Modal;

export class TransactionFormInner extends React.Component<IHideModal & ITransactionProps & IRefreshList & FormikProps<ICreateTransaction>> {
    public render() {
        return (
            <Form>
                {this.props.id_transaction &&
                    <h3 style={{textTransform: 'capitalize'}}>{this.props.transaction_type} - Pending</h3>
                }
                {this.props.transaction_type === 'withdrow' ? (
                    <RangePickerF
                        {...this.props}
                        name="date"
                        label={resources.labels.dateRange}
                        labelTextAlignLeft
                        boldLabel
                        allowClear
                        withEmptyValue
                    />
                ) : null}
                <TextInputF
                    {...this.props}
                    name="amount"
                    label={resources.labels.amount}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    isCurrency
                    disabled={this.props.id_transaction ? true : false}
                />
                <TextAreaF
                    {...this.props}
                    textAreaRows={4}
                    name="description"
                    label={resources.labels.optional_description}
                    allowClear
                    labelTextAlignLeft
                    boldLabel
                    disabled={this.props.id_transaction ? true : false}
                />
                {this.props.id_transaction ?
                    <>
                        <ProtectedComponent permission={UserPermissionType.AcceptTransactionButton}>
                            <Button
                                className='saveButton'
                                type="primary"
                                onClick={() => acceptTransaction(this.props.id_transaction!, { ...this.props })}
                            >
                                Accept
                                </Button>
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.RejectTransactionButton}>
                            <Button
                                className='saveButton'
                                onClick={() => deleteTransaction(this.props.id_transaction!, { ...this.props })}
                            >
                                Reject
                            </Button>
                        </ProtectedComponent>
                    </>
                    :
                    <Button
                        className='saveButton'
                        htmlType="submit"
                        type="primary"
                    >
                        {resources.buttons.submit}
                    </Button>
                }
            </Form >
        );
    };
}

const TransactionForm = withFormik<ITransactionProps & IRefreshList, ICreateTransaction>({
    mapPropsToValues: (props: ICreateTransaction) => {
        return {
            amount: props.amount ? Number(props.amount) / 100 : undefined,
            id_company: props.id_company,
            description: props.description
        };
    },

    validate: (values: ICreateTransaction) => {
        const errors: FormikErrors<ICreateTransaction> = {};

        if (!values.amount)
            errors.amount = resources.validation.required;

        return errors;
    },

    handleSubmit: (values: ICreateTransaction, bag: FormikBag<IHideModal & IRefreshList & ITransactionProps, {}>) => {
        const dateRange: { date_from: string; date_to: string } | undefined =
            bag.props.transaction_type === 'withdrow' && values.date != null ? {
                date_from: values.date[0] ? values.date[0].format('YYYY-MM-DD HH:mm:ss') : '',
                date_to: values.date[1] ? values.date[1].format('YYYY-MM-DD HH:mm:ss') : ''
            } : undefined
        const object: ICreateTransaction = {
            amount: Number(values.amount) * 100,
            description: values.description,
            id_company: bag.props.id_company,
            ...dateRange
        }

        showConfirm(object, bag.props);
    }

})(TransactionFormInner);

export default TransactionForm;

const createTransaction = (object: ICreateTransaction, bag: IHideModal & IRefreshList & ITransactionProps) => {
    if (bag.transaction_type === 'deposit') {
        createDepositTransaction(object)
            .then(response => { response && openSuccessNotification(resources.statement.created); })
            .finally(() => {
                bag.hideModal!();
                bag.refreshList!();
            });
    }
    else {
        createWithdrawalTransaction(object)
            .then(response => { response && openSuccessNotification(resources.statement.created); })
            .finally(() => {
                bag.hideModal!();
                bag.refreshList!();
            });
    }
}

const showConfirm = (object: ICreateTransaction, bag: IHideModal & IRefreshList & ITransactionProps) => {
    confirm({
        title: 'Are you sure?',
        content: `You want to ${bag.transaction_type} $${Number(object.amount) / 100}. Do you want to continue? `,
        okText: 'Proceed',
        okType: 'primary',
        cancelText: 'Abord mission',
        onOk() { createTransaction(object, bag); },
        onCancel() { },
    });
}

const acceptTransaction = (id_transaction: number, bag: IHideModal & IRefreshList) => {
    clearTransaction(id_transaction)
        .then(response => { response && openSuccessNotification(resources.statement.success); })
        .finally(() => {
            bag.hideModal!();
            bag.refreshList!();
        });
}

const deleteTransaction = (id_transaction: number, bag: IHideModal & IRefreshList) => {
    rejectTransaction(id_transaction)
        .then(response => { response && openSuccessNotification(resources.statement.success); })
        .finally(() => {
            bag.hideModal!();
            bag.refreshList!();
        });
}
