import React from 'react';
import { Table, Tooltip } from 'antd';
import { convertNumberToDateTimeString2 } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import AffbayClassifiersForm from '../forms/MappingClassifiersForm';
import { displayIconStatusWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import { getDictionary, findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import { getQualifiedFilters, getContactedFilters } from '../../../../common/dictionaries/Filters'

const { Column } = Table;

export class MappingClassifiersTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                width='200px'
                align='center'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.status}
                key='status'
                width='150px'
                align='center'
                sorter={(a, b) => a.status - b.status}
                filters={getDictionary('statusesF')}
                onFilter={(value, record) => record.status === value}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValue(record.status, getDictionary('statusesD'))
                    )}
            />,
            <Column
                title={resources.columnTitle.comment}
                key='postback_comment'
                width='140px'
                align='center'
                sorter={(a, b) => (a.postback_comment ? a.postback_comment : "").localeCompare(b.postback_comment ? b.postback_comment : "")}
                {...this.getColumnSearchProps('postback_comment')}
                render={(record) => (
                    record.postback_comment &&
                    <Tooltip title={record.postback_comment}>
                        <label className='marginLeft'>
                            {record.postback_comment && record.postback_comment.length > 28 ? record.postback_comment.substring(0, 25) + '...' : record.postback_comment}
                        </label>
                    </Tooltip>
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.is_qualified, resources.columnTitle.is_qualified_short)}
                width='115px'
                key='is_qualified'
                align='center'
                sorter={(a, b) => a.is_qualified - b.is_qualified}
                filters={getQualifiedFilters()}
                onFilter={(value, record) => record.is_qualified === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_qualified)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.is_contacted, resources.columnTitle.is_contacted)}
                width='125px'
                key='is_contacted'
                align='center'
                sorter={(a, b) => a.is_contacted - b.is_contacted}
                filters={getContactedFilters()}
                onFilter={(value, record) => record.is_contacted === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_contacted)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='180px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    <>
                        {convertNumberToDateTimeString2(record.created_at)}
                    </>
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='75px'
                align='center'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditMappingClassifierModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.title.editClassifier}
                                buttonTooltip={resources.title.editClassifier}
                                icon={"edit"}
                                disabled={this.state.loading}
                                withHideMethod={true}
                            >
                                <AffbayClassifiersForm
                                    id={record.id}
                                    name={record.name}
                                    status={record.status}
                                    updateArray={this.props.updateArray}
                                    is_qualified={record.is_qualified}
                                    is_contacted={record.is_contacted}
                                    postback_comment={record.postback_comment}
                                    setLoading={this.props.setLoading}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='MappingClassifiersTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                defaultPageSize={100}
            // rowSelection={true} // TODO: Dodano selektów na tablicy
            />
        )
    }
}