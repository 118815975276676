import * as React from "react";
import { IClassName } from "../../../common/interfaces/IClassName";

export class TableContainer extends React.Component<IClassName> {
  public render() {
    return (
      <div
        className={
          this.props.className ? this.props.className : "tableContainer"
        }
      >
        {this.props.children}
      </div>
    );
  }
}
