import * as React from 'react';
import { ClearBoth } from '../../controls/clearBoth/ClearBoth';
import { IPageInfProps } from './interfaces/IPageInfProps';
import { Left } from '../../controls/left/Left';
import './styles/PageInfStyles.css';

export class PageInf extends React.Component<IPageInfProps> {
    public render() {
        return (
            <div className='pageInf'>
                <Left>
                    <label className="title" >
                        {this.props.title}
                    </label>
                    {this.props.subTitle &&
                        <label className='subTitle'>
                            [ {this.props.subTitle} ]
                    </label>
                    }
                </Left>
                <ClearBoth />
            </div>
        )
    };
}