import { combineReducers, createStore } from "redux";
import { commonReducer, ICommonState } from "./reducers/CommonReducer";

export interface IAppState {
  commonState: ICommonState;
}

const reducer = combineReducers<IAppState>({
  commonState: commonReducer,
});

export const store = createStore(reducer);
