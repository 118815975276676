import * as React from 'react';
import InformationModalDialog from '../../../../../components/others/modal/InformationModalDialog';
import styles from './styles/OrdersStateChanger.module.scss';
import { ChangeMultipleStatusesDialog } from '../changeMultipleStatusesDialog/ChangeMultipleStatusesDialog';
import { getPossibleStatuses } from '../../../../../helpers/OrderStatusHelper';
import { IOrder } from '../../../interfaces/IOrder';
import { IOrdersStateChanger } from './interfaces/IOrdersStateChanger';
import { IOrderStatus } from '../../../interfaces/IOrderStatus';

export class OrdersStateChanger extends React.Component<IOrdersStateChanger> {

    render() {
        const possibleStatuses = getPossibleStatuses(this.props.selectedOrders, this.props.statusesMap);
        
        if (possibleStatuses.length === 0) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.label}>Change seleteted orders to:</div>
                {possibleStatuses.map((status: IOrderStatus) => 
                    <InformationModalDialog
                        key={status.id}
                        buttonTooltip={status.name}
                        buttonTitle={`"${status.name}"`}
                        buttonClassName={styles.button}
                        withHideMethod={true}
                        width={500}
                    >
                        <ChangeMultipleStatusesDialog
                            ordersIds={this.props.selectedOrders.map((el: IOrder) => el.id)}
                            targetStatusId={status.id}
                            onStatusChange={this.props.onStatusChange}
                        />
                    </InformationModalDialog>
                )}
            </div>
        )
    }
};