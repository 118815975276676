import React from "react";
import { Select } from "antd";

export const CallCenter = ({
  label,
  campaigns,
  callCenters,
  trafficSource,
  setFieldValue,
}: {
  label: any;
  campaigns: any;
  callCenters: any;
  trafficSource: any;
  setFieldValue: any;
}) => {
  const getCampaignValue = () => {
    const campaignValue =
      callCenters &&
      callCenters.find((callCenter: any) => callCenter[0] === trafficSource.id);
    return (campaignValue && campaignValue[1]) ?? null;
  };

  const onChangeCallCenter = (campaignId: any) => {
    const updatedCallCenters = callCenters.filter(
      (callCenter: any) => callCenter[0] !== trafficSource.id
    );

    setFieldValue("call_centers", [
      ...updatedCallCenters,
      [trafficSource.id, campaignId],
    ]);
  };

  return (
    <>
      <div className="form-control-column-label">
        <label className="label-control label-text-align-left font-weight-bold">
          {label}
        </label>
      </div>

      <Select
        value={getCampaignValue()}
        onChange={onChangeCallCenter}
        placeholder="Select campaign"
      >
        {campaigns.map((campaign: any) => (
          <Select.Option value={campaign.id} key={campaign.id}>
            {campaign.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
