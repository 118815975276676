import { displaySuccessMessage } from './MessageHelper';

export const copyClipboard = (event: any,  value?:any): void => {
    let input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.value = value ? value : event.target.event;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    displaySuccessMessage("Copied!");
    document.body.removeChild(input);
}
