import moment from 'moment';
import React, { Component } from 'react';
import styles from './ResaleTimer.module.scss';

interface IResaleTimer {
    nextRunTime: string;
    isRunning: boolean;
}

export class ResaleTimer extends Component<IResaleTimer> {
    
    state = {
        diff: '-',
    }
    interval = null as any;

    componentDidMount = () => {
        this.interval = setInterval(() => {
            if (!this.props.nextRunTime) {
                return;
            }
            let diff = moment.utc(this.props.nextRunTime).diff(moment.utc());
            this.setState({
                diff: moment.utc(diff).format('HH:mm:ss')
            });
        }, 1000);
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    render() {
        if (!this.props.isRunning) {
            return (
                <div className={styles.container}>Resale disabled</div>
            );
        }
        let nextRun = '-';
        if (this.props.nextRunTime !== '') {
            nextRun = moment.utc(this.props.nextRunTime).format('MM-DD HH:mm:ss');
        }
        return (
            <div className={styles.container}>Next run at {nextRun} (UTC) in {this.state.diff}</div>
        );
    }
}
