import axios from "axios";
import { createGatewayAbsoluteUrl } from "../../../helpers/UrlCreator";
import { displayHttpStatusCodeInformation } from "../../../helpers/HttpStatusCodeHelper";
import { IProductImageCreate } from "./interfaces/IProductImageCreate";
import { IProductImageFilters } from "./interfaces/IProductImageFilters";
import { IProductImageUpdate } from "./interfaces/IProductImageUpdate";
import { resourceDef } from "../../../common/ResourceDef";

const productImagesUrl = createGatewayAbsoluteUrl(resourceDef.Images);

export const createProductImages = (
  product_id: number,
  obj: IProductImageCreate
) =>
  axios
    .put(productImagesUrl + `${product_id}/images`, { ...obj })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "createProductImages M30");
      return false;
    });

export const getProductImages = (
  product_id: number | string,
  obj?: IProductImageFilters
) =>
  axios
    .post(productImagesUrl + `${product_id}/images`, { ...obj })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getProductImages M30");
      return [];
    });

export const updateProductImage = (
  product_id: number,
  image_id: number,
  obj: IProductImageUpdate
) =>
  axios
    .patch(productImagesUrl + `${product_id}/images/${image_id}`, { ...obj })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "updateProductImage M30");
      return false;
    });

export const deleteProductImage = (product_id: number, image_id: number) =>
  axios
    .delete(productImagesUrl + `${product_id}/images/${image_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "deleteProductImage M30");
      return [];
    });
