import React from 'react';
import { Table, Tooltip } from 'antd';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { displayIconStatusWithTooltip, displayOnlineStatuswithBadge } from '../../../../helpers/IconsGeneratorHelper';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import { getActivityFilters, getOnlineFilters } from '../../../../common/dictionaries/Filters'

const { Column } = Table;

export class WebhooksTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                width='80px'
                align='center'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.domain}
                key='domain'
                width='180px'
                align='left'
                sorter={(a, b) => (a.domain ? a.domain : "").localeCompare(b.domain ? b.domain : "")}
                {...this.getColumnSearchProps('domain')}
                render={(record) => (
                    <Tooltip title={record.domain}>
                        <label className='marginLeft'>
                            {record.domain !== null && record.domain.length > 38 ? record.domain.substring(0, 35) + '...' : record.domain}
                        </label>
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.user}
                key='user'
                width='250px'
                align='left'
                sorter={(a, b) => (a.user ? a.user : "").localeCompare(b.user ? b.user : "")}
                {...this.getColumnSearchProps('user')}
                render={(record) => (
                    <Tooltip title={record.user}>
                        <label className='marginLeft'>
                            {record.user !== null && record.user.length > 28 ? record.user.substring(0, 25) + '...' : record.user}
                        </label>
                    </Tooltip>
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.active, resources.columnTitle.active_short)}
                width='110px'
                key='is_active'
                align='center'
                sorter={(a, b) => a.is_active - b.is_active}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_active)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.online, resources.columnTitle.online_short)}
                key='is_online'
                width='110px'
                align='center'
                sorter={(a, b) => a.is_online - b.is_online}
                filters={getOnlineFilters()}
                onFilter={(value, record) => record.is_online === Number(value)}
                render={(record) => (
                    displayOnlineStatuswithBadge(record.is_online)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.password, resources.columnTitle.password_short)}
                dataIndex='password'
                key='password'
                width='160px'
                align='center'
                sorter={(a, b) => a.password - b.password}
                {...this.getColumnSearchProps('password')}
            />
        ]

        return (
            <DataTable
                tableName='WebhooksTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
            />
        )
    }
}