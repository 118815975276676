import { Component } from 'react';
import { getCountriesDictionaryList } from '../../../services/dictionaries/CountriesDivtionaryService';
import { IBase } from '../../../components/common/interfaces/IBase';
import { IBasketItem } from '../../../contexts/createNewOrderContext';
import { ILeadWithFccId } from './interfaces/ILeadWithFccId';
import { INewOrderBody } from './interfaces/INewOrderBody';
import { INewOrderForm } from '../components/forms/mainForm/INewOrderForm';
import { IProductsWithPricings } from './interfaces/IProductsWithPricings';
import { isEmptyString } from '../../../helpers/TextTransformHelpers';
import {
    getLeads,
    getProductsPricings,
    createNewOrder,
    } from '../../../services/CreateNewOrderServices';

interface IurlParams {
    fcc_id?: string;
}

interface IOrderPermByCountry {
    orderPermByCountry: Array<number>;
}

export class CreateNewOrderLogic extends Component<IurlParams & IOrderPermByCountry> {
    state = {
        loading: false,
        // green highlight for step title
        activeStep: 1,
        // step content or image placeholder
        displayedSteps: [1],
        selectedLeadId: undefined,
        selectedProductId: undefined,
        typingTimeout: 0,
        productsList: Array<IProductsWithPricings>(),
        leadsList: Array<ILeadWithFccId>(),
        selectedPricingsOutsideBasket: Array<string>(),

        countryId: undefined as number | undefined,
        countriesList: [] as Array<IBase>,

        fcc_id: this.props.fcc_id,
        phone: '',
        basket: Array<IBasketItem>(),
    }

    componentDidMount = async () => {
        // get cc_id and phone from url
        const countriesList = (await getCountriesDictionaryList()).filter(
            (el:any) => this.props.orderPermByCountry.includes(el.id));
        
        this.setState({ 
            fcc_id: this.props.fcc_id,
            countriesList,
            countryId: countriesList[0] ? countriesList[0].id : undefined,
        }, () => {
            // get leads list matching cc_id 
            if (this.state.fcc_id) {
                this.getLeadsList(this.state.fcc_id!, this.state.phone)
            }
            this.getProductsAndPricings();
        });

    }

    countryChangeHandler = (name: string, value: number) => {
        this.setState({
            countryId: value,
            activeStep: 1,
            displayedSteps: [1],
            selectedLeadId: undefined,
            selectedProductId: undefined,
            typingTimeout: 0,
            productsList: Array<IProductsWithPricings>(),
            selectedPricingsOutsideBasket: Array<string>(),
            basket: Array<IBasketItem>(),
        }, this.getProductsAndPricings);
    }

    handleFccIdChange = (e: any) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            loading: true,
            fcc_id: e.target.value,
            selectedLeadId: undefined,
            typingTimeout: setTimeout(() => {
                this.getLeadsList(this.state.fcc_id!, this.state.phone)
            }, 2000)
        })
    }

    handlePhoneNumberInput = (e: any) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            loading: true,
            phone: e.target.value,
            selectedLeadId: undefined,
            typingTimeout: setTimeout(() => {
                this.getLeadsList(this.state.fcc_id!, this.state.phone)
            }, 2000)
        })
    }

    chooseLead = (value: any) => {
        const current = this.state.selectedLeadId;
        const decoded = JSON.parse(value);

        if (current === decoded.id) {
            this.setState({ selectedLeadId: undefined })
        } else {
            this.setState({ selectedLeadId: decoded.id })
        }
    }

    findProductWithLead = (lead_id: number): any => {
        const lead = this.state.leadsList.find((lead: any) => {
            return lead.id === lead_id;
        })

        return lead ? lead.product : {};
    }

    selectProduct = (prod_id: number) => {
        this.setState({ selectedProductId: prod_id });
    }

    chooseProduct = (e: number) => {
        this.setState({
            selectedProductId: e,
            // product change will remove selected pricings that are not added to the basket with add to basket button
            selectedPricingsOutsideBasket: []
        })
    }

    choosePricing = (value: any) => {
        const selected = this.state.selectedPricingsOutsideBasket;
        const idx = selected.indexOf(`${value}`);
        if (idx > -1) {
            selected.splice(idx, 1);
        } else {
            selected.push(`${value}`);
        }
        this.setState({ selectedPricingsOutsideBasket: selected });
    }

    findPricing = (pricing_id: string): any => {
        const product = this.state.productsList.find((el: any) => {
            return el.id === this.state.selectedProductId;
        })
        let pricing;
        if (product) {
            pricing = product.pricings.find((pricing: any): boolean => {
                return pricing.id === Number(pricing_id);
            })
        }

        return pricing;
    }

    isPricingChecked = (pricingValue: string): boolean => {
        return (this.state.selectedPricingsOutsideBasket.indexOf(pricingValue) > -1);
    }

    // I need this function to pass as a prop to other component
    resetState = (property: string, value: any) => {
        this.setState({
            [property]: value
        })
    }
    // =====================
    // --- steps display ---
    // =====================

    changeActiveStepTo = (step: number): void => {
        if (this.state.selectedLeadId) {
            const lead = this.state.leadsList.find(lead => lead.id === this.state.selectedLeadId);
            this.setState({ selectedProductId: lead ? lead.id_product : undefined })
        }
        this.setState({ activeStep: step });
        this.updateDisplayedStep(step);
        this.hideHigherSteps(step);
    }

    getStepActivity = (stepNumber: number): boolean => {
        return this.state.activeStep === stepNumber ? true : false;
    }

    updateDisplayedStep = (stepNumber: number): void => {
        const { displayedSteps } = this.state;

        if (displayedSteps.indexOf(stepNumber) > -1) {
            return;
        } else {
            displayedSteps.push(stepNumber);
        }

        this.setState({ displayedSteps: displayedSteps });
    }

    hideHigherSteps = (lastStep: number) => {
        const { displayedSteps } = this.state;

        const stepsAbove = displayedSteps.filter((step) => {
            return step > lastStep;
        });

        stepsAbove.forEach(step => {
            const idx = displayedSteps.indexOf(step);

            displayedSteps.splice(idx, 1);
        })

        this.setState({ displayedSteps: displayedSteps });
    }

    getStepVisibility = (stepNumber: number): boolean => {
        const { displayedSteps } = this.state;

        return displayedSteps.indexOf(stepNumber) > -1 ? true : false;
    }

    // =======================
    // --- handling basket ---
    // =======================

    addToBasket = () => {
        let basket = this.state.basket;
        this.state.selectedPricingsOutsideBasket.forEach((el: any) => {
            basket.push(this.findPricing(el));
        })

        if (this.state.selectedPricingsOutsideBasket.length > 0) {
            this.setState({
                basket,
                selectedPricingsOutsideBasket: []
            }, () => { this.changeActiveStepTo(3) })
        }
    }

    removeFromBasket = (pricing_idx: string | number) => {
        const { basket } = this.state;
        basket.splice(Number(pricing_idx), 1);
        this.setState({ basket })
    }

    calculateBasket = (): number[] => {
        const { basket } = this.state;

        const totalOrderValue = basket.reduce(
            (previousValue?: number, currentValue?: IBasketItem) => {
                return previousValue! + currentValue!.total_order_value!;
            }, 0);

        const totalPriceForCustomer = basket.reduce(
            (previousValue?: number, currentValue?: IBasketItem) => {
                return previousValue! + currentValue!.total_price_for_customer!;
            }, 0);
        
        const totalDiscount = basket.reduce(
            (previousValue?: number, currentValue?: IBasketItem) => {
                return previousValue! + currentValue!.total_discount_value!;
            }, 0);

        return [totalOrderValue / 100, totalPriceForCustomer / 100, totalDiscount / 100];
    }

    // =================
    // --- api calls ---
    // =================

    getLeadsList = async (fcc_id: string, phoneNumber: string) => {
        this.setState({ loading: true })
        if ((isEmptyString(fcc_id) && isEmptyString(phoneNumber)) || this.state.countryId === undefined) {
            this.setState({
                leadsList: [],
                loading: false
            });
            return;
        }
        await getLeads(this.state.countryId, fcc_id, phoneNumber)
            .then(response => {
                const leadsList = response.data.map((el: any) => ({
                    ...el,
                    product: this.state.productsList.find((product: IProductsWithPricings) => el.id_product === product.id),
                })).sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
                this.setState({
                    leadsList,
                    loading: false
                })
            })
            .catch(error => {
                console.error(error);
                this.setState({ loading: false })
            })
    }

    getProductsAndPricings = async () => {
        await getProductsPricings(this.state.countryId)
            .then(response => this.setState({ productsList: response.products }))
            .catch(error => console.error(error));
    }

    // submit order

    resetOrderPage = () => {
        this.setState({
            activeStep: 1,
            // step content or image placeholder
            displayedSteps: [1],
            selectedLeadId: undefined,
            selectedProductId: undefined,
            typingTimeout: 0,
            productsList: Array<IProductsWithPricings>(),
            selectedPricingsOutsideBasket: Array<string>(),
        
            basket: Array<IBasketItem>(),
        });
    }

    submitOrder = (formData: INewOrderForm): Promise<any> => {
        return new Promise((resolve, reject) => {
            const { basket, countryId } = this.state;
            if (countryId === undefined) {
                reject('Country not selected! Please select country!');
                return;
            }
    
    
            if (basket.length === 0) {
                reject('Basket is empty! Please select products!');
                return;
            }
    
            let countryName = '';
            const country = this.state.countriesList.find(el => el.id === countryId);
            if (country) {
                countryName = country.name;
            }
            const {
                salesChannel,
                paymentMethod,
                name,
                phone,
                phone2,
                email,
                sex,
                age,
                address,
                city,
                province,
                district,
                subdistrict,
                postalCode,
                locationId,
                courierName,
                destinationCode,
                reconciliationStatus,
                notes,
            } = formData;

            if (locationId === -1) {
                reject('Invalid location ID');
                return;
            }
    
            let requestData: INewOrderBody = {
                country_id: countryId,
                country_name: countryName,
                address_country: countryName,
                address_address: address,
                address_city: city,
                address_province: province,
                address_district: district,
                address_subdistrict: subdistrict,
                address_postcode: postalCode,
                id_sale_channel: salesChannel ? salesChannel : 0,
                id_payment_method: paymentMethod ? paymentMethod : 0,
                client_name: name,
                client_phone1: phone,
                client_phone2: phone2,
                client_email: email,
                client_age: age === '' ? Number(age) : undefined,
                client_gender: sex.toString(),
                courier_name: courierName,
                address_destination_code: destinationCode,
                id_reconciliation_status: reconciliationStatus,
                note: notes,
                ids_product_pricing: basket.map((el:any) => el.id),
                id_location: locationId,
            }
    
            if (this.props.fcc_id) {
                const leadData = this.state.leadsList.find(el => el.id === this.state.selectedLeadId);
                if (leadData) {
                    requestData = {
                        ...requestData,
                        focus_lead_id: Number(this.props.fcc_id),
                        id_lead: leadData.id,
                    }
                }
            }
    
            createNewOrder(requestData)
            .then(() => {
                this.resetOrderPage()
                resolve();
            }).catch((err) => {
                reject(err.message);
            })
        })
    }
}
