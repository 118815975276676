import { IOrder } from '../views/orders/interfaces/IOrder';
import { IOrderStatus } from '../views/orders/interfaces/IOrderStatus';
import { IOrderStatusesMap } from './interfaces/IOrderStatusesMap';

const statusesString = localStorage.getItem('orderStatuses');

const allStatuses = statusesString ? JSON.parse(statusesString) as Array<any> : [];
const allStatusesD = allStatuses.reduce((dict, status) => {
    dict[status.id] = status;
    return dict;
}, {});
const allStatusesIds = allStatuses.map(status => Number(status.id));

export const statusIdToStatus = (statusId: number): IOrderStatus => {
    return allStatusesD[statusId]
}

const intersection = (a: Array<any>, b: Array<any>) => {
    const setA = new Set(a);
    const setB = new Set(b);
    return Array.from(setA).filter(x => setB.has(x));
}

export const getPossibleStatuses = (orders: Array<IOrder>, statusesMap: IOrderStatusesMap): Array<IOrderStatus> => {
    if (orders.length === 0) {
        return [];
    }
    const possibleStatusesIdPerOrder = Array<Array<number>>();
    orders.forEach((order: IOrder) => {
        const { id_payment_method, histories } = order;
        if (histories.length > 0 && statusesMap[id_payment_method]) {
            const lastStatusId = histories[histories.length - 1].status_id;
            const statusesForOrder = statusesMap[id_payment_method][lastStatusId];
            possibleStatusesIdPerOrder.push(statusesForOrder);
        }
        else {
            possibleStatusesIdPerOrder.push([]);
        }
    });
    if (possibleStatusesIdPerOrder.length === 0) {
        return [];
    }
    const possibleStatuses = possibleStatusesIdPerOrder
    .reduce((interArray, currArray) => intersection(interArray, currArray), allStatusesIds)
    .map((statusId: number): IOrderStatus => statusIdToStatus(statusId));
    return possibleStatuses;
}