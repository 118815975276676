import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IAppState } from '../../../store';
import { IOrdersView } from './interfaces/IOrdersView';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { OrdersContent } from './contents/OrdersContent';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class OrdersViewInner extends React.Component<ISetActiveMenuItem & IOrdersView & RouteComponentProps> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.orders);
    }

    public render() {
        return (
            <OrdersContent  {...this.props} />
        )
    }
}

const mapStateToProps = (state: IAppState): IOrdersView => {
    if (state.commonState.appUserInfo.acc) {
        if (state.commonState.appUserInfo.acc.orders) {
            return { countryPermisionsForOrders: state.commonState.appUserInfo.acc.orders }
        }
    }

    return { countryPermisionsForOrders: [] }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const OrdersView: React.ComponentClass<RouteComponentProps> = connect(mapStateToProps, mapDispatchToProps)(OrdersViewInner);