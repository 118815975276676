import ProtectedComponent from '../../../../../../../../components/authorizations/protectedComponents/ProtectedComponent';
import React from 'react';
import styles from '../styles/Pixels.module.scss';
import { Button } from 'antd';
import { ClearBoth } from '../../../../../../../../components/controls/clearBoth/ClearBoth';
import { connect } from 'react-redux';
import { deleteProductPixels, getProductPixels } from '../../../../../../../../services/m30_products/pixels/ProductPixelsService';
import { getUsersWithEmail } from '../../../../../../../../services/m29_users_management/GeneralUserService';
import { IAppState } from '../../../../../../../../store';
import { IDictionaryValue } from '../../../../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IId } from '../../../../../../../../common/interfaces/IId';
import { IPixelContentProps } from '../interfaces/IPixelContentProps';
import { IPixels } from '../interfaces/IPixels';
import { IProductPixelsFilters } from '../interfaces/IProductPixelsFilters';
import { Left } from '../../../../../../../../components/controls/left/Left';
import { openSuccessNotification } from '../../../../../../../../helpers/NotificationHelper';
import { PixelsList } from '../lists/PixelsList';
import { RefreshTableButton } from '../../../../../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../../../../../common/Resources';
import { Right } from '../../../../../../../../components/controls/right/Right';
import { status_class_name } from '../../../../../../../../helpers/StylesHelper';
import { UserPermissionType } from '../../../../../../../../models/IAppUserInfoModel';

class PixelsContentInner extends React.Component<IId & IPixelContentProps> {
    _isMounted = false;

    state = {
        loading: false,
        counter: 1,
        pixelsList: Array<IPixels>(),
        usersD: Array<IDictionaryValue>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getPixelsList();
        this.getUsersD();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <>
                <Left>
                    <RefreshTableButton
                        className="marginRigth marginBottom"
                        loading={this.state.loading}
                        onClick={this.getPixelsList}
                    />
                </Left>
                <Left>
                    <label className={styles.pixels_title}>Pixels</label>
                </Left>
                <Right>
                    <ProtectedComponent permission={UserPermissionType.AddNewPixelButton}>
                        <Button
                            onClick={this.addPixel}
                            type='primary'
                            icon="plus"
                            loading={this.state.loading}
                        >
                            {resources.buttons.add_new}
                        </Button>
                    </ProtectedComponent>
                </Right>
                <ClearBoth />
                <PixelsList
                    data={this.state.pixelsList}
                    loading={this.state.loading}
                    usersD={this.state.usersD}
                    addPixel={this.addPixel}
                    changePixelStatus={this.changePixelStatus}
                    deletePixel={this.deletePixel}
                    updatePixel={this.updatePixel}
                />
            </>
        );
    };

    getPixelsList = async () => {
        this.setState({ loading: true });

        const object: IProductPixelsFilters = {
            columns: [
                "id",
                "no_pixel",
                "custom_script",
                "id_user",
                "id_product"
            ]
        };

        getProductPixels(Number(this.props.id), object)
            .then(response => {
                const pixelsList = response.map((item: any) => { return { ...item, row_key: item.id, form_status: undefined } });
                this._isMounted && this.setState({ pixelsList });
            })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    getUsersD = () => {
        getUsersWithEmail()
            .then(response => {
                const usersD = response.map((item: any) => { return { id: item.id, name: `${item.email} [${item.id}]` } });

                this._isMounted && this.setState({ usersD });
            });
    }

    addPixel = (): void => {
        const obj: IPixels = {
            id: undefined,
            row_key: 'newObject' + this.state.counter,
            id_user: this.props.appUserInfo.id,
            id_product: Number(this.props.id)!,
            no_pixel: undefined,
            custom_script: undefined,
            form_status: 'new'
        }

        this.setState({
            pixelsList: [...this.state.pixelsList, obj],
            counter: this.state.counter + 1
        });
    }

    updatePixel = (object: IPixels, created_new_flag?: boolean) => {
        const { pixelsList } = this.state;

        const index = pixelsList.findIndex(x => x.row_key === object.row_key);

        pixelsList[index].id = object.id;
        pixelsList[index].custom_script = object.custom_script;
        pixelsList[index].no_pixel = object.no_pixel;
        pixelsList[index].form_status = undefined;

        this.setState({ pixelsList });

        created_new_flag ? openSuccessNotification(resources.statement.created) : openSuccessNotification(resources.statement.updated);
    }

    deletePixel = (row_key: string, id?: string) => {
        if (id) {
            deleteProductPixels(Number(this.props.id), Number(id))
                .then(response => { response && this.deletePixelFromList(row_key); })
        }
        else {
            this.deletePixelFromList(row_key);
        }
    }

    deletePixelFromList = (row_key: string) => {
        const pixelsList = this.state.pixelsList.filter(x => x.row_key !== row_key);

        this.setState({ pixelsList });

        openSuccessNotification(resources.statement.deleted);
    }

    changePixelStatus = (row_key: string, form_status: status_class_name): void => {
        const { pixelsList } = this.state;

        const index = pixelsList.findIndex(x => x.row_key === row_key);

        pixelsList[index].form_status = form_status;

        this.setState({ pixelsList });
    }
}

const mapStateToProps = (state: IAppState): IPixelContentProps => {
    return {
        appUserInfo: state.commonState.appUserInfo
    }
}

export const PixelsContent = connect(mapStateToProps)(PixelsContentInner);