import * as React from 'react';
import styles from './styles/Tool.module.scss';
import { ITool } from './interfaces/ITool';
import { Link } from 'react-router-dom';

export const Tool = (props: ITool) => {
    const { title, description, buttonText, hyperlink } = props;
    return (
        <div className={styles.tool}>
            <h4 className={`h4__bold ${styles.header}`}>{title}</h4>
            <p className={`h5 ${styles.para}`}>{description}</p>
            {hyperlink.startsWith('/') ?
            <Link className={`h5__semi ${styles.button}`} to={hyperlink}>{buttonText}</Link> :
            <a className={`h5__semi ${styles.button}`} href={hyperlink}>{buttonText}</a>}
        </div>
    )
}

