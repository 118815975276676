import * as React from 'react';
import { IPasswordToken } from './interfaces/IPasswordToken';
import { ResetPasswordContent } from './content/ResetPasswordContent';
import { RouteComponentProps } from 'react-router-dom';

export class ResetPasswordView extends React.Component<RouteComponentProps<IPasswordToken>> {
    public render() {
        return (
            <ResetPasswordContent password_token={this.props.match.params.password_token} />
        )
    };
}