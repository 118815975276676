import * as React from 'react';
import styles from '../styles/SettingsContent.module.scss';
import { PageTitle } from '../../../../components/common/pageTitle/PageTitle';
import { resources } from '../../../../common/Resources';
import { ClickIdSettings } from '../components/clickIdSettings/ClickIdSettings';
import { Divider } from 'antd';



export class SettingsContent extends React.Component {
    state = {
        mounted: false,
    }

    componentDidMount = async () => {
        this.setState({
            mounted: true,
        });
    }

    componentWillUnmount = () => {
        this.setState({
            mounted: false
        });
    }

    
    public render() {

        return (
            <div className={styles.container}>
                <PageTitle>{resources.subNavigationItem.settings}</PageTitle>
                <Divider/>
                <ClickIdSettings />
                <Divider/>
            </div>
        )
    };
}