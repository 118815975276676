import * as React from 'react';
import { connect } from 'react-redux';
import { IProtectedComponentProps } from '../../../../authorizations/interfaces/IProtectedComponentProps';
import { IProtectedProps } from '../../interfaces/IProtectedProps';
import { mapStateToProps } from '../../../../authorizations/AuthorizationLogic';
import { ProtectedRouteBase } from './ProtectedRouteBase';
import { Route, RouteProps } from 'react-router-dom';

class ProtectedRouteInner extends Route<IProtectedComponentProps & RouteProps & IProtectedProps> {
    public render() {
        return (<ProtectedRouteBase {...this.props}/>)
    }
}

const ProtectedRoute: React.ComponentClass<IProtectedComponentProps & RouteProps> = connect(mapStateToProps)(ProtectedRouteInner);

export default ProtectedRoute;