import React from 'react';
import { DataTable } from '../../../components/others/data/DataTable';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { statusTestD, schemeTestD } from '../contents/FinanceContent';
import { findStringInDictionaryAndReturnValue } from '../../../helpers/DisctionariesHelper';
import { resources } from '../../../common/Resources';
import { LeadModal } from '../components/leadModal/LeadModal';
import { spanWithTitle } from '../../../components/others/SpanWithTitle';

const { Column } = Table;

export class FinanceTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.date}
                key='disposition_at'
                align='center'
                width={80}
                sorter={(a, b) => moment.utc(a.disposition_at).diff(moment.utc(b.disposition_at))}
                render={(record) => (
                    this.dateFormat(record.disposition_at)
                )}
            />,
            <Column
                title={resources.columnTitle.accepted}
                key='settled_at'
                align='center'
                width={80}
                sorter={(a, b) => moment.utc(a.settled_at).diff(moment.utc(b.settled_at))}
                render={(record) => (
                    this.dateFormat(record.settled_at)
                )}
            />,
            <Column
                title={resources.columnTitle.email}
                key='id_user'
                align='center'
                width='120px'
                sorter={(a, b) => a.id_user - b.id_user}
                render={(record) => {
                    const user_name = findStringInDictionaryAndReturnValue(record.id_user, this.props.usersD);

                    return (
                        user_name && <Tooltip title={user_name}>
                            {user_name && user_name.length > 24 ? user_name.substring(0, 20) + '...' : user_name}
                        </Tooltip>
                    )
                }}
            />,
            < Column
                title={resources.columnTitle.leadId}
                key='id_lead'
                align='center'
                width={70}
                sorter={(a, b) => a.id_lead - b.id_lead}
                render={(record) => (
                    <LeadModal
                        leadId={record.id_lead}
                        product={record.product}
                        timezoneOffset={this.props.timezoneOffset}
                    />
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='lead_created_at'
                align='center'
                width={80}
                sorter={(a, b) => moment.utc(a.disposition_at).diff(moment.utc(b.disposition_at))}
                render={(record) => (
                    this.dateFormat(record.lead_created_at)
                )}
            />,
            < Column
                title={resources.columnTitle.product}
                key='product'
                align='center'
                width={120}
                sorter={(a, b) => a.product.localeCompare(b.product)}
                // {...this.getColumnSearchProps('product')}
                render={(record) => (
                    record.product ? `${record.product}` : '-'
                )}
            />,
            < Column
                title={resources.labels.amount}
                key='amount'
                align='center'
                width={90}
                sorter={(a, b) => a.amount - b.amount}
                render={(record) => (
                    `$${record.amount / 100}`
                )}
            />,
            < Column
                title={resources.columnTitle.scheme}
                key='scheme'
                align='center'
                width={60}
                sorter={(a, b) => (a.scheme ? a.scheme : "").localeCompare(b.scheme ? b.scheme : "")}
                render={(record) => (
                    findStringInDictionaryAndReturnValue(record.scheme, schemeTestD)
                )}
            />,
            < Column
                title={resources.labels.status}
                key='status'
                align='center'
                width={60}
                sorter={(a, b) => (a.status ? a.status : "").localeCompare(b.status ? b.status : "")}
                render={(record) => (
                    <div style={{ color: this.getStatusColor(record.status) }}>
                        {findStringInDictionaryAndReturnValue(record.status, statusTestD)}
                    </div>
                )}
            />
        ];

        return (
            <div className={'customAnt--financeTable'}>
                <DataTable
                    tableName={'FinanceTable'}
                    columns={columns}
                    data={this.props.data}
                    loading={this.props.loading}
                    pagination={true}
                    showSizeChanger={false}
                    showQuickJumper={false}
                    showTotal={false}
                    position='bottom'
                    defaultPageSize={10}
                />
            </div>
        )
    }

    dateFormat = (value) => {
        if (value === null || value === undefined || !(value.isValid && value.isValid()))
            return '-';
        else {
            const date = new Date(moment(new Date(value)));
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

            return `${da} ${mo}, ${ye}`;
        }
    }

    getStatusColor = (id) => {
        switch (id) {
            case 'pending':
                return 'blue';
            case 'cleared':
                return '#238500';
            case 'rejected':
                return 'red';
            case 'paid':
                return '#238500';
            case 'awaiting':
                return '#EB7900';
            default:
                return 'purple';
        }
    }
}
