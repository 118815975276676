import React from "react";

import { useStatisticMutation } from "../../../lib/hooks";
import {
  useFilters,
  RESET_FILTERS,
  initialFiltersState,
} from "../../../lib/filters-state";

const buttonStyles = {
  cursor: "pointer",
  padding: "8px 22px",
  borderRadius: "4px",
};

export const ApplyFilters = ({
  setVisible,
}: {
  setVisible: (visible: boolean) => void;
}) => {
  const { state, dispatch } = useFilters();
  const statisticMutation = useStatisticMutation();

  const onResetFilters = () => {
    statisticMutation(initialFiltersState);
    dispatch({ type: RESET_FILTERS });
    setVisible(false);
  };

  const onApplyFilters = () => {
    statisticMutation(state);
    setVisible(false);
  };

  return (
    <div
      style={{
        width: "100%",
        paddingRight: "12px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <button
        style={{
          color: "#009EEA",
          border: "1px solid #DCDCE3",
          marginRight: "15px",
          backgroundColor: "white",
          ...buttonStyles,
        }}
        onClick={onResetFilters}
      >
        Reset
      </button>

      <button
        style={{
          color: "white",
          border: "1px solid transparent",
          backgroundColor: "#21B74B",
          ...buttonStyles,
        }}
        onClick={onApplyFilters}
      >
        Apply
      </button>
    </div>
  );
};
