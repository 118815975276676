import { ISubNavigationItem } from "./interfaces/ISubNavigationItem";
import { INavigationOptionalParams } from "../navigationItem/interfaces/INavigationOptionalParams";
import { NavigationItem } from "../navigationItem/NavigationItem";

export class SubNavigationItem implements ISubNavigationItem {
    public title: string;
    public isMenuSubItem: boolean;

    public navigationItems: NavigationItem[];
    public optionalParams?: INavigationOptionalParams | undefined;

    constructor(link: string, navigationItems: NavigationItem[], optionalParams?: INavigationOptionalParams) {
        this.title = link;
        this.isMenuSubItem = true;

        this.navigationItems = navigationItems;
        this.optionalParams = optionalParams;
    }
}