import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const statusesQuery = 'SELECT id, id as "key", name, created_at, updated_at FROM li_statuses';

export const getStatusesDictionaryList = () => axios.post(sourceUrl, { data: { sql: statusesQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getStatusesDictionaryList');
        return [];
    });


export const getStatuses = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getStatuses');
        return [];
    });


export const updateStatusesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateStatusesDictionary');
        return false;
    });

export const createStatusesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createStatusesDictionary');
        return false;
    });

export const deleteStatusesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createStatusesDictionary');
        return false;
    });