import React from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";

import { FILTERS } from "../../../lib/filters-data";
import { ApplyFilters } from "../apply-filters/apply-filters";
import { ProductFilter } from "../product-filter/product-filter";
import { SingleChoiceFilter } from "../single-choice-filter/single-choice-filter";
import { MultipleChoiceFilter } from "../multiple-choice-filter/multiple-choice-filter";
import { MultipleChoiceWithSearchFilter } from "../multiple-choice-with-search-filter/multiple-choice-with-search-filter";
import {
  usePubIdEntity,
  useStatusEntity,
  usePayoutEntity,
  useCountryEntity,
  usePartnerEntity,
  useCompanyEntity,
  useContactedEntity,
  useQualifiedEntity,
  useRemarketingEntity,
  useLpUrlEntity,
} from "../../../lib/hooks";
import { USER_ROLES } from "../../../../../common/enums/UserRoles";

export const FiltersCard = ({
  setVisible,
}: {
  setVisible: (visible: boolean) => void;
}) => {
  const isAdmin = useSelector((state: any) => {
    const userRole = state.commonState.appUserInfo.id_users_role;
    return userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.SUPERADMIN;
  });

  return (
    <div style={{ width: "400px" }}>
      <Tabs className="filters-tabs" tabPosition="left" defaultActiveKey="1">
        {isAdmin && (
          <Tabs.TabPane tab={<FiltersTab tab="Country" />} key="3">
            <SingleChoiceFilter
              filter={FILTERS.COUNTRY}
              entityHook={useCountryEntity}
            />
          </Tabs.TabPane>
        )}

        {/* We are using product filter cause it is unique */}
        <Tabs.TabPane tab={<FiltersTab tab="Product" />} key="4">
          <ProductFilter />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<FiltersTab tab="Status" />} key="5">
          <MultipleChoiceFilter
            filter={FILTERS.STATUS}
            entityHook={useStatusEntity}
          />
        </Tabs.TabPane>

        {isAdmin && (
          <Tabs.TabPane tab={<FiltersTab tab="Partner" />} key="6">
            <MultipleChoiceWithSearchFilter
              filter={FILTERS.PARTNER}
              entityHook={usePartnerEntity}
              dataPlaceholder="Type to load partners"
            />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab={<FiltersTab tab="Contacted" />} key="7">
          <SingleChoiceFilter
            filter={FILTERS.CONTACTED}
            entityHook={useContactedEntity}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<FiltersTab tab="Qualified" />} key="8">
          <SingleChoiceFilter
            filter={FILTERS.QUALIFIED}
            entityHook={useQualifiedEntity}
          />
        </Tabs.TabPane>

        {isAdmin && (
          <Tabs.TabPane tab={<FiltersTab tab="Remarketing" />} key="9">
            <MultipleChoiceFilter
              filter={FILTERS.TRAFFIC_SOURCE}
              entityHook={useRemarketingEntity}
            />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab={<FiltersTab tab="Payout" />} key="10">
          <SingleChoiceFilter
            filter={FILTERS.PAYOUT_SCHEME}
            entityHook={usePayoutEntity}
          />
        </Tabs.TabPane>

        {isAdmin && (
          <Tabs.TabPane tab={<FiltersTab tab="Company" />} key="11">
            <MultipleChoiceWithSearchFilter
              filter={FILTERS.COMPANY}
              entityHook={useCompanyEntity}
              dataPlaceholder="Type to load companies"
            />
          </Tabs.TabPane>
        )}

        {isAdmin && (
          <Tabs.TabPane tab={<FiltersTab tab="pub_id" />} key="1">
            <MultipleChoiceWithSearchFilter
              filter={FILTERS.PUB_ID}
              entityHook={usePubIdEntity}
              dataPlaceholder="Type to load pub_id"
            />
          </Tabs.TabPane>
        )}

        {isAdmin && (
          <Tabs.TabPane tab={<FiltersTab tab="lp_url" />} key="2">
            <MultipleChoiceWithSearchFilter
              filter={FILTERS.LP_URL}
              entityHook={useLpUrlEntity}
              dataPlaceholder="Type to load lp_url"
            />
          </Tabs.TabPane>
        )}
      </Tabs>

      <ApplyFilters setVisible={setVisible} />
    </div>
  );
};

const FiltersTab = ({ tab }: { tab: string }) => <span>{tab}</span>;
