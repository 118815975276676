import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { ResalesContent } from './contents/ResalesContent';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class SalesResalesViewInner extends React.Component<ISetActiveMenuItem>  {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.sales_resale);
    }

    public render() {
        return (
            <ResalesContent />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const SalesResalesView: React.ComponentClass = connect(null, mapDispatchToProps)(SalesResalesViewInner);