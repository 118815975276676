import DictionaryF from '../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/CompanieForm.module.scss';
import TextAreaF from '../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createCompany, updateCompany } from '../../../services/m29_users_management/CompaniesService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../common/dictionaries/Boolean';
import { ICompanieFormProps } from './interfaces/ICompanieFormProps';
import { ICompanyCreate } from '../../../services/m29_users_management/interfaces/ICompanyCreate';
import { ICompanyUpdate } from '../../../services/m29_users_management/interfaces/ICompanyUpdate';
import { IHideModal } from '../../../common/interfaces/IHideModal';
import { IId } from '../../../common/interfaces/IId';
import { INullableCompanie } from './interfaces/INullableCompanie';
import { IRefreshList } from '../../../common/interfaces/IRefreshList';
import { openAlertNotification, openSuccessNotification } from '../../../helpers/NotificationHelper';
import { resources } from '../../../common/Resources';

class TemplateCompanieForm extends React.Component<ICompanieFormProps & IHideModal & IRefreshList & IId & FormikProps<INullableCompanie>> {
    public static defaultProps = {
        labelTextAlignLeft: true,
        boldLabel: true,
        allowClear: true
    }

    public render() {
        return (
            <Form>
                <section>
                    {this.props.name && <>
                        <div className={styles.companieName}>
                            {this.props.name}
                        </div>
                        <div className={styles.sectionDivider} />
                    </>}
                    <div className={styles.grid_col_2}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="name"
                                required
                                label={resources.labels.name}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='city'
                                required
                                label={resources.labels.city}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_2}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="address_line1"
                                label={resources.labels.addressLine1}
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="address_line2"
                                label={resources.labels.addressLine2}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_2}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="state"
                                label={resources.labels.state}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="zip"
                                label={resources.labels.zipCode}
                            />
                        </div>
                    </div>
                    <div className={styles.sectionDivider}></div>
                    <div className={styles.grid_col_3}>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name="internal"
                                label={resources.labels.isInternal}
                                dictionaryValues={getBooleanValues()}
                                allowClear={false}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='is_active'
                                label={resources.labels.isActive}
                                dictionaryValues={getBooleanValues()}
                                allowClear={false}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name="id_country"
                                required
                                allowClear
                                label={resources.labels.country}
                                dictionaryValues={this.props.countriesD}
                            />
                        </div>
                    </div>
                    <div className={styles.sectionDivider}></div>
                    <TextAreaF
                        {...this.props}
                        textAreaRows={6}
                        name="description"
                        label={resources.labels.description}
                    />
                </section>
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const CompanieForm = withFormik<ICompanieFormProps & IRefreshList & IId, INullableCompanie>({
    mapPropsToValues: (props: ICompanieFormProps) => {
        return {
            name: props.name,
            description: props.description,
            address_line1: props.address_line1,
            address_line2: props.address_line2,
            city: props.city,
            state: props.state,
            zip: props.zip,
            id_country: props.id_country,
            internal: Boolean(props.internal) ? '1' : '0',
            is_active: Boolean(props.is_active) ? '1' : '0'
        };
    },

    validate: (values: INullableCompanie) => {
        const errors: FormikErrors<INullableCompanie> = {};

        if (!values.name) {
            errors.name = resources.validation.required;
        }
        else if (values.name.length > 191) {
            errors.name = resources.validation.requiredMax191Characters;
        }
        if (!values.city || values.city === '') {
            errors.city = resources.validation.required;
        }
        else if (values.city.length > 191) {
            errors.name = resources.validation.requiredMax191Characters;
        }
        if (!values.address_line1 || values.address_line1 === '') {
            errors.address_line1 = resources.validation.required;
        }
        if (!values.id_country) {
            errors.id_country = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: INullableCompanie, bag: FormikBag<ICompanieFormProps & IRefreshList & IId & IHideModal & IId, INullableCompanie>) => {
        if (
            bag.props.id
            && values.name === bag.props.name
            && values.description === bag.props.description
            && values.internal === bag.props.internal
            && values.address_line1 === bag.props.address_line1
            && values.address_line2 === bag.props.address_line2
            && values.city === bag.props.city
            && values.state === bag.props.state
            && values.zip === bag.props.zip
            && values.id_country === bag.props.id_country
            && values.is_active === bag.props.is_active
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            bag.props.id ? updateCompanyAction(values, bag.props) : createCompanyAction(values, bag.props)
        }
    }

})(TemplateCompanieForm);

export default CompanieForm;

const updateCompanyAction = (obj: INullableCompanie, bag: IRefreshList & IId & IHideModal & IId) => {
    const object: ICompanyUpdate = {
        name: obj.name,
        description: obj.description ? obj.description : null,
        address_line1: obj.address_line1!,
        address_line2: obj.address_line2 ? obj.address_line2 : null,
        city: obj.city!,
        state: obj.state ? obj.state : null,
        zip: obj.zip ? obj.zip : null,
        id_country: obj.id_country!,
        is_active: obj.is_active === '0' ? false : true,
        internal: obj.internal === '0' ? false : true
    }

    updateCompany(object, Number(bag.id))
        .then(response => {
            if (response) {
                bag.refreshList!();
                openSuccessNotification(resources.statement.updated);
                bag.hideModal!();
            }
        });
}

const createCompanyAction = (obj: INullableCompanie, bag: IRefreshList & IId & IHideModal & IId) => {
    const object: ICompanyCreate = {
        name: obj.name!,
        description: obj.description,
        address_line1: obj.address_line1!,
        address_line2: obj.address_line2,
        city: obj.city!,
        state: obj.state,
        zip: obj.zip,
        id_country: Number(obj.id_country),
        is_active: obj.is_active === '0' ? false : true,
        internal: obj.internal === '0' ? false : true
    }
    
    createCompany(object)
        .then(response => {
            if (response) {
                bag.refreshList!();
                openSuccessNotification(resources.statement.created);
                bag.hideModal!();
            }
        });
}