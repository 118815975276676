import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Col, Row, Spin } from "antd";
import { Empty } from "antd";

import { queryClient } from "../../../../../../App";
import { UploadImage } from "./upload-image";
import {
  getProductImages,
  deleteProductImage,
  updateProductImage,
} from "../../../../../../services/m30_products/images/ProductImagesService";

export const ImagesList = ({ productID }: { productID: number }) => {
  const { data, isLoading } = useQuery("product-images", () =>
    getProductImages(productID)
  );
  const uploadImageMutation = useMutation(
    "remove-product-image",
    (imageId: any) => deleteProductImage(productID, imageId),
    { onSuccess: () => queryClient.invalidateQueries("product-images") }
  );
  const patchImageMutation = useMutation(
    "main-product-image",
    ({ imageId, imageIsMain }: any) =>
      updateProductImage(productID, imageId, { is_main: imageIsMain }),
    { onSuccess: () => queryClient.invalidateQueries("product-images") }
  );

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: "21px", fontWeight: "bold" }}>Images</span>

        <UploadImage productId={productID} />
      </div>

      {data && data.length === 0 && <Empty />}

      {data && data.length > 0 && (
        <Row gutter={[16, 16]} style={{ padding: "0 20px" }}>
          {data.map((image: any) => (
            <Col key={image.id} span={8}>
              <div style={{ position: "relative" }}>
                <img
                  src={image.src}
                  alt={image.product_id}
                  style={{ maxWidth: "100%" }}
                />

                <Button
                  type={image.is_main ? "primary" : "default"}
                  shape="circle"
                  icon="star"
                  size="small"
                  style={{ position: "absolute", top: "-11px", right: "18px" }}
                  onClick={() =>
                    patchImageMutation.mutate({
                      imageId: image.id,
                      imageIsMain: !image.is_main,
                    })
                  }
                />

                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  size="small"
                  style={{ position: "absolute", top: "-11px", right: "-11px" }}
                  onClick={() => uploadImageMutation.mutate(image.id)}
                />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};
