import React, { Component } from 'react';
import styles from './OrderStepBox.module.scss';

interface IProps {
    image?: string;
    active?: boolean;
}

export class OrderStepBox extends Component<IProps> {
    state = {
        wasDisplayed: false,
    }

    render() {
        return (
            <div className={styles.step} style={{ justifyContent: !this.props.active ? 'center' : 'flex-start' }}>
                {
                    this.state.wasDisplayed || this.props.active ?
                        this.props.children
                        :
                        <img src={this.props.image} alt="step" />
                }
            </div>
        )
    }
}
