import * as React from 'react';
import { ClassifiersContent } from './contents/ClassifiersContent';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IClassifiersParamsProps } from './interfaces/IClassifiersParamsProps';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class ClassifiersViewInner extends React.Component<ISetActiveMenuItem & RouteComponentProps<IClassifiersParamsProps>> {
    componentDidMount = (): void => 
        this.props.setActiveMenuItem(navPath.cc_classifiers);

    public render() {
        return (
            <ClassifiersContent
                instance_id={this.props.match.params.instance_id}
                campaign_id={this.props.match.params.campaign_id}
            />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const ClassifiersView: React.ComponentClass<RouteComponentProps<IClassifiersParamsProps>> = connect(null, mapDispatchToProps)(ClassifiersViewInner);
