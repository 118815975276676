import React, { Component } from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table } from 'antd';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../helpers/DisctionariesHelper';

const { Column } = Table;

export class OrderDetailsTable extends Component {
    render() {
        const columns = [
            <Column
                title='Product ID'
                dataIndex='id_product'
                key='id'
                align='center'
                width='90px'
            />,
            <Column
                title='Product'
                key='product_name'
                align='center'
                width='230px'
                render={(record) => (
                    <div>{findInDictionaryAndReturnValueWithSpin(record.id_product, this.props.productsD)}</div>
                )}
            />,
            <Column
                title='Color'
                dataIndex='color'
                key='color'
                align='center'
                width='200px'
            />,
            <Column
                title='Size'
                dataIndex='size'
                key='size'
                align='center'
                width='200px'
            />,
            <Column
                title='Qty'
                dataIndex='quantity'
                key='quantity'
                align='center'
                width='200px'
            />,
            <Column
                title={`Price (${this.props.currency})`}
                dataIndex='base_price'
                key='base_price'
                align='center'
                width='200px'
            />,
            <standardColumnOptionsForFilters
                title={`Discount (${this.props.currency})`}
                key='discount'
                align='center'
                width='200px'
                render={(record) => (
                    record.base_price * record.discount * record.quantity / 100
                )}
            />,
            <Column
                title={`Sub total (${this.props.currency})`}
                key='sub_total'
                align='center'
                width='200px'
                render={(record) => (
                    record.base_price * record.quantity - record.base_price * record.discount * record.quantity / 100
                )}
            />
        ];

        return (
            <div className={'customAnt--ordersDetailsTable'}>
                <DataTable
                    tableName={'OrderDetailsTable'}
                    columns={columns}
                    data={this.props.data}
                    loading={false}
                    pagination={false}
                />
            </div>
        )
    }
}
