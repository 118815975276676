import * as React from 'react';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import styles from './styles/ResetPasswordContent.module.scss';
import { AuthenticationContainer } from '../../../components/containers/authenticationContainer/AuthenticationContainer';
import { createAbsoluteUrl } from '../../../helpers/UrlCreator';
import { IPasswordToken } from '../interfaces/IPasswordToken';
import { PopUpModal } from '../../../components/popUpModal/PopUpModal';
import { resources } from '../../../common/Resources';

export class ResetPasswordContent extends React.Component<IPasswordToken>{
    state = {
        visible: false,
    }

    openModal = (): void => {
        this.setState({ visible: true })
    }

    closeModal = (): void => {
        this.setState({ visible: false })
    }

    public render() {
        return (
            <AuthenticationContainer>
                <p className={styles.paragraph}>{this.props.password_token ? "Type the address linked to your account and we'll set up new password" : resources.resetPassword.typeTheAdress}</p>
                <ResetPasswordForm
                    openModal={this.openModal}
                    password_token={this.props.password_token}
                />
                <PopUpModal
                    visible={this.state.visible}
                    onCancel={this.closeModal}
                >
                    {this.props.password_token ?
                        <>
                            <h6>{resources.resetPassword.password_changed}</h6>
                            <a className={styles.submit} href={createAbsoluteUrl('login')}>{resources.pageTitle.loginContent}</a>
                        </>
                        :
                        <>
                            <h6>{resources.resetPassword.checkEmail}</h6>
                            <p>{resources.resetPassword.withinAFew}</p>
                            <a className={styles.submit} href={createAbsoluteUrl('login')}>{resources.pageTitle.loginContent}</a>
                        </>
                    }
                </PopUpModal>
            </AuthenticationContainer>
        )
    };
}