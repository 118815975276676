import React from 'react';
import { Table, Tooltip, Popover, Icon, Button } from 'antd';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { displayIconStatusWithTooltip, displayOnlineStatuswithBadge } from '../../../../helpers/IconsGeneratorHelper';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { DataTable } from '../../../../components/others/data/DataTable';
import { openAlertNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { createAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { Link } from 'react-router-dom';
import { copyClipboard } from '../../../../helpers/CopyHelpers';
import { getDictionary, findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import { getActivityFilters, getOnlineFilters } from '../../../../common/dictionaries/Filters'

const countriesNameD = getDictionary('countriesNameD');

// TODO: Dodać metode rejestrowania podglądu 
function onClick() {
    openAlertNotification("Dodac akcje rejestrowania podgldku klucza!");
};

const { Column } = Table;

export class InstancesTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                width='70px'
                align='center'
                sorter={(a, b) => a.id - b.id}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.type}
                key='type'
                width='100px'
                align='center'
                sorter={(a, b) => (a.type ? a.type : "").localeCompare(b.type ? b.type : "")}
                {...this.getColumnSearchProps('type')}
                render={(record) => (
                    <Tooltip title={record.type}>
                        {record.type !== null && record.type.length > 28 ? record.type.substring(0, 25) + '...' : record.type}
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.country}
                key='country_id'
                width='120px'
                align='center'
                sorter={(a, b) => a.country_id - b.country_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValue(record.country_id, countriesNameD)
                    )}
            />,
            <Column
                title={resources.columnTitle.name}
                key='name'
                width='200px'
                align='left'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
                render={(record) => (
                    record.is_active ?
                        <Tooltip placement="rightTop" title={"View " + record.name}>
                            <Link className='marginLeft' to={createAbsoluteUrl("cc_campaigns/") + record.id}>
                                {record.name !== null && record.name.length > 10 ? record.name.substring(0, 10) + '...' : record.name} <Icon type="link" />
                            </Link>
                        </Tooltip>
                        :
                        <div style={{ color: 'gray', marginLeft: 5 }}>{record.name}</div>
                )}
            />,
            <Column
                title={resources.columnTitle.domain}
                key='domain'
                width='180px'
                align='left'
                sorter={(a, b) => (a.domain ? a.domain : "").localeCompare(b.domain ? b.domain : "")}
                {...this.getColumnSearchProps('domain')}
                render={(record) => (
                    <Tooltip title={record.domain}>
                        <label className='marginLeft'>
                            {record.domain !== null && record.domain.length > 38 ? record.domain.substring(0, 35) + '...' : record.domain}
                        </label >
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.user}
                key='user'
                width='140px'
                align='left'
                sorter={(a, b) => (a.user ? a.user : "").localeCompare(b.user ? b.user : "")}
                {...this.getColumnSearchProps('user')}
                render={(record) => (
                    <Tooltip title={record.user}>
                        <label className='marginLeft'>
                            {record.user !== null && record.user.length > 28 ? record.user.substring(0, 25) + '...' : record.user}
                        </label>
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.key}
                key='key'
                width='60px'
                align='center'
                render={(record) => (
                    <Tooltip title={resources.informations.viewPassword}>
                        <Popover placement="rightTop" trigger="click" content={
                            <>
                                {record.password}
                                <Tooltip title={resources.tooltips.copyKey}>
                                    <Button value={record.password} icon="copy" onClick={copyClipboard} style={{ border: "0px" }} />
                                </Tooltip>
                            </>
                        }>
                            <Icon type="eye" onClick={onClick} />
                        </Popover>
                    </Tooltip>
                )
                }
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.url, resources.columnTitle.url_short)}
                key='base_url'
                width='250px'
                align='left'
                sorter={(a, b) => (a.base_url ? a.base_url : "").localeCompare(b.base_url ? b.base_url : "")}
                {...this.getColumnSearchProps('base_url')}
                render={(record) => (
                    <Tooltip title={record.base_url}>
                        <label className='marginLeft'>
                            {record.base_url !== null && record.base_url.length > 38 ? record.base_url.substring(0, 35) + '...' : record.base_url}
                        </label>
                    </Tooltip>
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.active, resources.columnTitle.active_short)}
                width='100px'
                key='is_active'
                align='center'
                sorter={(a, b) => a.is_active - b.is_active}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_active)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.online, resources.columnTitle.online_short)}
                key='is_online'
                width='100px'
                align='center'
                sorter={(a, b) => a.is_online - b.is_online}
                filters={getOnlineFilters()}
                onFilter={(value, record) => record.is_online === Number(value)}
                render={(record) => (
                    displayOnlineStatuswithBadge(record.is_online)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width={160}
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width={160}
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                width='60px'
                align='center'
                fixed='right'
                render={(record) => (
                    <ProtectedComponent permission={UserPermissionType.ChangeInstanceStatusButton}>
                        <Tooltip placement="rightTop" title={resources.tooltips.changeActiveStatus}>
                            <Button
                                value={record.id}
                                onClick={this.props.changeStatus}
                                icon={record.is_active ? 'close' : 'check'}
                                style={{ color: record.is_active ? 'red' : 'green' }}
                            />
                        </Tooltip>
                    </ProtectedComponent>
                )}
            />
        ];

        return (
            <DataTable
                tableName={this.props.tableName}
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                columnNameToCheck='is_active'
                valueToCheck={0}
            />
        )
    }
}