import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { FinanceAdminContent } from './contents/FinanceAdminContent';
import { IFinanceRouteProps } from './interfaces/IFinanceRouteProps';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../reducers/CommonReducer';

class FinanceViewInner extends React.Component<ISetActiveMenuItem & RouteComponentProps<IFinanceRouteProps>> {
    componentDidMount = () =>
        this.props.setActiveMenuItem(navPath.financeAdmin);

    render() {
        return (
            <FinanceAdminContent id={this.props.match.params.id} />
        )
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) =>
        dispatch(setActiveMenuItem(view))
})

export const FinanceAdminView: React.ComponentClass<RouteComponentProps<IFinanceRouteProps>> = connect(null, mapDispatchToProps)(FinanceViewInner);