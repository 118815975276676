import * as React from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { ISetActiveMenuItem } from "../../common/interfaces/ISetActiveMenuItem";
import { LeadsContent } from "./contents/LeadsContent";
import { navPath } from "../../common/ResourceDef";
import { RouteComponentProps } from "react-router-dom";
import { setActiveMenuItem } from "../../reducers/CommonReducer";
import { IAppState } from "../../store";
import { USER_ROLES } from "../../common/enums/UserRoles";

class LeadsViewInner extends React.Component<
  ISetActiveMenuItem & RouteComponentProps & { isAdmin: boolean }
> {
  componentDidMount = () => this.props.setActiveMenuItem(navPath.leads);

  public render() {
    return <LeadsContent {...this.props} />;
  }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (
  dispatch: Dispatch
) => ({
  setActiveMenuItem: (view: string) => dispatch(setActiveMenuItem(view)),
});

const mapStateToProps = (state: IAppState): { isAdmin: boolean } => {
  const userRole = state.commonState.appUserInfo.id_users_role;

  return {
    isAdmin:
      userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.SUPERADMIN,
  };
};

export const LeadsView: React.ComponentClass<RouteComponentProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsViewInner);
