import * as React from 'react';
import { CampaignsAdminContent } from './contents/CampaignsAdminContent';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { setActiveMenuItem } from '../../reducers/CommonReducer';

class CampaignsAdminViewInner extends React.Component<ISetActiveMenuItem>  {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.campaigns_admin);
    }

    render() {
        return (<CampaignsAdminContent />)
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const CampaignsAdminView: React.ComponentClass = connect(null, mapDispatchToProps)(CampaignsAdminViewInner);