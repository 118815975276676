import * as React from 'react';
import BasePriceForm from '../forms/basePrice/BasePriceForm';
import InformationModalDialog from '../../../../../../components/others/modal/InformationModalDialog';
import ProtectedComponent from '../../../../../../components/authorizations/protectedComponents/ProtectedComponent';
import ProtectedModal from '../../../../../../components/authorizations/protectedComponents/ProtectedModal';
import { Button } from 'antd';
import { ClearBoth } from '../../../../../../components/controls/clearBoth/ClearBoth';
import { deleteProductPricings, getProductPricings } from '../../../../../../services/m30_products/pricings/ProductPricingsService';
import { getProducts } from '../../../../../../services/m30_products/ProductsService';
import { IId } from '../../../../../../common/interfaces/IId';
import { IProductPriceForm } from '../forms/productPrice/interfaces/IProductPriceForm';
import { IProductPricing } from '../interfaces/IProductPricing';
import { IProductPricingsFilters } from '../../../../../../services/m30_products/pricings/interfaces/IProductPricingsFilters';
import { Left } from '../../../../../../components/controls/left/Left';
import { openSuccessNotification } from '../../../../../../helpers/NotificationHelper';
import { ProductPricingContentProps } from '../interfaces/ProductPricingContentProps';
import { ProductPricingList } from '../lists/ProductPricingList';
import { RefreshTableButton } from '../../../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../../../common/Resources';
import { Right } from '../../../../../../components/controls/right/Right';
import { TextInputF } from '../../../../../../components/forms/textInput/TextInputF';
import { UserPermissionType } from '../../../../../../models/IAppUserInfoModel';

export class ProductPricingsContent extends React.Component<IId & ProductPricingContentProps> {
    _isMounted = false;

    state = {
        loading: false,
        counter: 1,
        default_price: undefined,
        productPricingList: Array<IProductPricing>(),
        products_list: Array<any>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getProductsPricingList();
        await this.getProductsList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    render() {
        return (
            <>
                <Left>
                    <RefreshTableButton
                        className='marginRigth'
                        loading={this.state.loading}
                        onClick={this.getProductsPricingList}
                    />
                </Left>
                <Left>
                    <b>Base price</b> ( 1 item ) <TextInputF
                        onChange={() => null}
                        value={this.state.default_price}
                        name='default_price'
                        width={180}
                        disabled
                    />
                    <label
                    >
                        <ProtectedModal permission={UserPermissionType.EditDefaultPriceModalButton}>
                            <InformationModalDialog
                                modalTitle={`Change default price [${this.props.default_price_currency}]`}
                                buttonTooltip='Edit default price'
                                icon='edit'
                                disabled={this.state.loading}
                                withHideMethod={true}
                                buttonClassName='marginRigth'
                                width={400}
                            >
                                <BasePriceForm
                                    id={this.props.id}
                                    default_price={this.state.default_price}
                                    updateProductsList={this.updateProductsList}
                                />
                            </InformationModalDialog>
                            {this.props.default_price_currency}
                        </ProtectedModal>
                    </label>
                </Left>
                <Right>
                    <ProtectedComponent permission={UserPermissionType.AddNewProductPricingButton}>
                        <Button
                            icon='plus'
                            type='primary'
                            onClick={this.addNewProductPricing}
                            disabled={this.state.loading}
                        >
                            {resources.buttons.add_new}
                        </Button>
                    </ProtectedComponent>
                </Right>
                <ClearBoth />
                <ProductPricingList
                    productPricingList={this.state.productPricingList}
                    productsD={this.state.products_list}
                    loading={this.state.loading}
                    deleteProductPricing={this.deleteProductPricing}
                    updateProductPricingList={this.updateProductPricingList}
                />
            </>
        );
    }

    getProductsPricingList = () => {
        this.setState({
            loading: true,
            pricingList: []
        });

        const object: IProductPricingsFilters = {
            columns: [
                "id",
                "is_active",
                "id_product",
                "id_nd_product",
                "id_rd_product",
                "name",
                "for_sale_from",
                "for_sale_to",
                "quantity_st_product",
                "quantity_nd_product",
                "quantity_rd_product",
                "p1_discount",
                "p2_discount",
                "p3_discount",
                "description"
            ]
        };

        getProductPricings(Number(this.props.id), object)
            .then(response => {
                const productPricingList = response.map((item: any) => { return { ...item, row_key: item.id, form_status: undefined } });

                this._isMounted && this.setState({ productPricingList })
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    getProductsList = (): void => {
        const object = {
            columns: [
                "id",
                "name",
                "country_id",
                "default_price"
            ],
            country_id: this.props.country_id
        };

        getProducts(object)
            .then(response => {
                const products_list = response.map((item: any) => {
                    return {
                        id: item.id,
                        name: item.name,
                        value: item.default_price,
                        country_id: item.country_id
                    }
                });

                const search = products_list.filter((x: any) => x.id === Number(this.props.id));

                const default_price = search.length ? search[0].value / 100 : 0;

                this._isMounted && this.setState({
                    products_list,
                    default_price
                });
            });
    }

    addNewProductPricing = () => {
        const obj: IProductPricing = {
            id: undefined,
            row_key: 'newObject' + this.state.counter,
            id_product: Number(this.props.id)!,
            id_nd_product: undefined,
            id_rd_product: undefined,
            quantity_st_product: undefined,
            quantity_nd_product: undefined,
            quantity_rd_product: undefined,
            p1_discount: 0,
            p2_discount: 0,
            p3_discount: 0,
            name: undefined,
            description: undefined,
            form_status: 'new'
        }

        this.setState({
            productPricingList: [...this.state.productPricingList, obj],
            counter: this.state.counter + 1
        });
    }

    deleteProductPricing = (row_key: string, id?: number) => {
        if (id) {
            deleteProductPricings(Number(this.props.id), Number(id))
                .then(response => { response && this.deletePixelFromList(row_key); })
        }
        else {
            this.deletePixelFromList(row_key);
        }
    }

    deletePixelFromList = (row_key: string) => {
        const productPricingList = this.state.productPricingList.filter(x => x.row_key !== row_key);
        this.setState({ productPricingList });
        openSuccessNotification(resources.statement.deleted)
    }

    updateProductsList = (value: number) => {
        const { products_list } = this.state;
        const index = products_list.findIndex(x => x.id === Number(this.props.id));

        if (index >= 0)
            products_list[index].value = value;

        this.setState({ products_list, default_price: value / 100 });
    }

    updateProductPricingList = (object: IProductPriceForm) => {
        const { productPricingList } = this.state;
        const index = productPricingList.findIndex(x => x.row_key === object.row_key);

        productPricingList[index].id = object.id;
        productPricingList[index].row_key = object.row_key;
        productPricingList[index].id_nd_product = object.id_nd_product;
        productPricingList[index].id_rd_product = object.id_rd_product;
        productPricingList[index].quantity_st_product = object.quantity_st_product;
        productPricingList[index].quantity_nd_product = object.quantity_nd_product;
        productPricingList[index].quantity_rd_product = object.quantity_rd_product;
        productPricingList[index].p1_discount = object.p1_discount;
        productPricingList[index].p2_discount = object.p2_discount;
        productPricingList[index].p3_discount = object.p3_discount;
        productPricingList[index].name = object.name;
        productPricingList[index].description = object.description;
        productPricingList[index].form_status = undefined;
        productPricingList[index].for_sale_from = object.for_sale_from;
        productPricingList[index].for_sale_to = object.for_sale_to;

        this.setState({ productPricingList });

        openSuccessNotification(resources.statement.updated);
    }
}