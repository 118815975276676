import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/ProvincesDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createProvincesDictionary, updateProvincesDictionary } from '../../../../services/dictionaries/ProvincesDivtionaryService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IProvinces } from '../interfaces/IProvinces';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class TemplateProvincesDictionaryForm extends React.Component<IProvinces & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<IProvinces>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <TextInputF
                    {...this.props}
                    name="name_eng"
                    label={resources.labels.name_eng}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='country_id'
                    label={resources.labels.country}
                    dictionaryValues={this.props.countriesD}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                />
                 <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const ProvincesDictionaryForm = withFormik<IProvinces & IUpdateObjInArray & IAddObjToArray & IId, IProvinces>({
    mapPropsToValues: (props: IProvinces) => {
        return {
            name: props.name,
            name_eng: props.name_eng,
            country_id: props.country_id
        };
    },

    validate: (values: IProvinces) => {
        const errors: FormikErrors<IProvinces> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (values.name_eng && values.name_eng.length > 255) {
            errors.name_eng = resources.validation.requiredMax255Characters;
        }
        if (!values.name_eng) {
            errors.name_eng = resources.validation.required;
        }
        if (!values.country_id) {
            errors.country_id = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: IProvinces, bag: FormikBag<IProvinces & IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId, IProvinces>) => {
        const object: IProvinces = {
            name: values.name,
            name_eng: values.name_eng,
            country_id: values.country_id,
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.name === bag.props.name
            && values.name_eng === bag.props.name_eng
            && values.country_id === bag.props.country_id
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(TemplateProvincesDictionaryForm);

export default ProvincesDictionaryForm;

const createQuery = async (obj: IProvinces, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId) => {
    const createQuery =
        `INSERT INTO li_provinces
            (
                name,
                name_eng,
                country_id
            )
        VALUES
            (
                "${obj.name}",
                "${obj.name_eng}",
                ${obj.country_id}
            )`;

    const updateQuery =
        `UPDATE li_provinces SET
                name = "${obj.name}",
                name_eng = "${obj.name_eng}",
                country_id = ${obj.country_id}
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateProvincesDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.country_id = Number(obj.country_id);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);
                openSuccessNotification(resources.statement.province_updated);
            });
    }
    else {
        await createProvincesDictionary(createQuery)
            .then((response) => {
                obj.id = response;
                obj.key = response;
                obj.created_at = new Date();
                obj.updated_at = new Date();
                obj.deleted_at = null;

                bag.addObjToArray!(obj);
                openSuccessNotification(resources.statement.province_created);
            });
    }

    bag.hideModal!();
}