import * as React from 'react';
import flagEN from './images/flagEN.png';
import flagRU from './images/flagRU.png';
import styles from './styles/LangPicker.module.scss';
import { getLocale } from '../../../../helpers/LocaleHelper';
import { IChangeLang } from '../../../../models/IChangeLang';
import { Select } from 'antd';

const { Option } = Select;

export class LangPicker extends React.Component<IChangeLang> {
    render() {
        return (
            <div className="customAnt--langPicker">
                <Select defaultValue={getLocale()} onChange={this.props.changeLang}>
                    <Option value="en_GB">
                        <div className={styles.langPickerGrid}>
                            <img src={flagEN} alt="EN flag" />
                            <p className={`h4__semi`}>En</p>
                        </div>
                    </Option>
                    <Option value="ru_RU">
                        <div className={styles.langPickerGrid}>
                            <img src={flagRU} alt="RU flag" />
                            <p className={`h4__semi`}>Ru</p>
                        </div>
                    </Option>
                </Select>
            </div>
        );
    }
}