import { useQuery } from "react-query";

import { getProducts } from "../../../../services/m30_products/ProductsService";
import { FILTERS } from "../filters-data";
import { UPDATE_QTY_FILTER_ENTITIES, useFilters } from "../filters-state";

export const useProductEntity = () => {
  const { dispatch } = useFilters();
  const { data, isLoading } = useQuery("products", () => getProducts({}), {
    onSuccess: (data) =>
      dispatch({
        type: UPDATE_QTY_FILTER_ENTITIES,
        payload: { filter: FILTERS.PRODUCT, qty: data.length },
      }),
  });
  return { isLoading, products: data };
};
