import * as React from 'react';
import HeaderContent from './content/HeaderContent';
import { IChangeLang } from '../../models/IChangeLang';

export class HeaderView extends React.Component<IChangeLang> {
    public render() {
        return (
            <HeaderContent changeLang={this.props.changeLang} />
        )
    };
}