import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IId } from '../../common/interfaces/IId';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { PartnersContent } from './contents/PartnersContent';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveMenuItem } from '../../reducers/CommonReducer';

class PartnersViewInner extends React.Component<ISetActiveMenuItem & RouteComponentProps<IId>>  {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.partners_id);
    }

    public render() {
        return (
            <PartnersContent id={this.props.match.params.id} />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const PartnersView: React.ComponentClass<RouteComponentProps<IId>> = connect(null, mapDispatchToProps)(PartnersViewInner);