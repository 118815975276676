import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/SalesChannelDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createSystemVariableDictionary, updateSystemVariableDictionary } from '../../../../services/dictionaries/SystemVariablesDictionaryService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { ISystemVariables } from '../interfaces/ISystemVariables';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class InnerSystemVariableDictionaryFormDictionaryForm extends React.Component<ISystemVariables & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<ISystemVariables>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <TextInputF
                    {...this.props}
                    name="value"
                    label={resources.labels.value}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='is_active'
                    label={resources.labels.isActive}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                    labelTextAlignLeft
                    boldLabel
                />
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const SystemVariableDictionaryForm = withFormik<ISystemVariables & IUpdateObjInArray & IAddObjToArray & IId, ISystemVariables>({
    mapPropsToValues: (props: ISystemVariables) => {
        return {
            id: props.id,
            name: props.name,
            value: props.value,
            is_active: Boolean(props.is_active) ? '1' : '0',
        };
    },

    validate: (values: ISystemVariables) => {
        const errors: FormikErrors<ISystemVariables> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 30) {
            errors.name = resources.validation.requiredMax30Characters;
        }
        if (!values.value) {
            errors.value = resources.validation.required;
        }
        else if (values.value.length > 30) {
            errors.value = resources.validation.requiredMax30Characters;
        }

        return errors;
    },

    handleSubmit: (values: ISystemVariables, bag: FormikBag<ISystemVariables & IUpdateObjInArray & IAddObjToArray & IHideModal & IId, ISystemVariables>) => {
        const object: ISystemVariables = {
            id: values.id,
            name: values.name,
            value: values.value,
            is_active: values.is_active,
        }

        if (
            bag.props.id
            && values.name === bag.props.name
            && values.value === bag.props.value
            && values.is_active === bag.props.is_active
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(InnerSystemVariableDictionaryFormDictionaryForm);

export default SystemVariableDictionaryForm;

const createQuery = async (obj: ISystemVariables, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal) => {
    const createQuery =
        `INSERT INTO li_system_variables
            (
                name,
                value,
                is_active
            )
        VALUES
            (
                "${obj.name}",
                "${obj.value}",
                ${obj.is_active}
            )`;

    const updateQuery =
        `UPDATE li_system_variables SET
                name = "${obj.name}",
                value = "${obj.value}",
                is_active = ${obj.is_active}
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateSystemVariableDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.is_active = Number(obj.is_active);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);

                openSuccessNotification(resources.statement.system_variable_updated);
            });
    }
    else {
        await createSystemVariableDictionary(createQuery)
            .then(response => {
                if (response) {
                    obj.id = response;
                    obj.key = response;
                    obj.is_active = Number(obj.is_active);
                    obj.created_at = new Date();
                    obj.updated_at = new Date();

                    bag.addObjToArray!(obj);

                    openSuccessNotification(resources.statement.system_variable_created);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_system_variable);
                }
            });
    }

    bag.hideModal!();
}