import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { spanWithTitle } from '../../../components/others/SpanWithTitle';
import { displayIconStatusWithTooltip } from '../../../helpers/IconsGeneratorHelper';
import { convertNumberToDateTimeString } from '../../../helpers/DateHelpers';
import { getDictionary } from '../../../helpers/DisctionariesHelper';
import { findInDictionaryAndReturnValue } from '../../../helpers/DisctionariesHelper';
import { resources } from '../../../common/Resources';
import { DataTable } from '../../../components/others/data/DataTable';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import { Button, Tooltip } from 'antd';
import ProtectedComponent from '../../../components/authorizations/protectedComponents/ProtectedComponent';
import { Link } from 'react-router-dom';
import CampaignsAdminForm from '../forms/CampaignsAdminForm';

const { Column } = Table;

export class CampaingsAdminTable extends DataTable {
    updateCountryDate = () => {
        const instancesWithCountryD = this.props.instancesWithCountryD;

        if (this.props.data && this.props.data !== null) {
            const result = this.props.data.map(

                obj => ({
                    ...obj,
                    id_country: instancesWithCountryD.length > 0 ? instancesWithCountryD.find(x => x.id === obj.id_instance).country_id : undefined
                })
            );

            return result;
        }
    }

    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                width='100px'
                align='center'
                sorter={(a, b) => a.id - b.id}
            />,
            <Column
                title={resources.columnTitle.country}
                key='id_country'
                width='140px'
                align='center'
                sorter={(a, b) => a.id_country - b.id_country}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValue(record.id_country, getDictionary('countriesNameD'))
                    )}
            />,
            <Column
                title={resources.columnTitle.focusId}
                dataIndex='id_external'
                key='id_external'
                width='120px'
                align='center'
                sorter={(a, b) => a.id_external - b.id_external}
                {...this.getColumnSearchProps('id_external')}
            />,
            <Column
                title={resources.columnTitle.campaign_name}
                dataIndex='name'
                key='name'
                align='center'
                width='180px'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.instance}
                key='id_instance'
                width='140px'
                align='center'
                sorter={(a, b) => a.id_instance - b.id_instance}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValue(record.id_instance, this.props.instancesD)
                    )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.active, resources.columnTitle.active_short)}
                dataIndex='is_active'
                key='is_active'
                width='100px'
                align='center'
                sorter={(a, b) => a.is_active - b.is_active}
                render={(record) => (
                    <span>
                        {displayIconStatusWithTooltip(record)}
                    </span>
                )}
            />,
            < Column
                title="Fields"
                key='fields'
                width={470}
                align='center'
                sorter={(a, b) => (a.fields ? a.fields : "").localeCompare(b.fields ? b.fields : "")}
                render={(record) => (
                    <Tooltip title={record.fields ? record.fields : ''}>
                        {record.fields && record.fields !== null && record.fields.length > 46 ? record.fields.substring(0, 50) + '...' : record.fields}
                    </Tooltip>
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    <span>
                        {convertNumberToDateTimeString(record.created_at)}
                    </span >
                )}
            />,
            < Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    <span>
                        {convertNumberToDateTimeString(record.updated_at)}
                    </span >
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                width='145px'
                align='center'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditCampaignModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editCampaign}
                                buttonTooltip={resources.modalButtonsTitle.editCampaign}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={600}
                            >
                                <CampaignsAdminForm
                                    id={record.id}
                                    name={record.name}
                                    is_active={record.is_active}
                                    id_external={record.id_external}
                                    id_instance={record.id_instance}
                                    fields={record.fields}
                                    refreshList={this.props.updateArray}
                                    instancesD={this.props.instancesD}
                                    updateObjInArray={this.props.updateObjInArray}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.ChangeCampaignsStatusButton}>
                            <Tooltip placement='rightTop' title={resources.tooltips.changeActiveStatus}>
                                <Button
                                    value={record.id}
                                    onClick={this.props.changeCampaignStatus}
                                    icon={record.is_active ? 'close' : 'check'}
                                    style={{ color: record.is_active ? 'red' : 'green' }}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.SeeCampaignsDetailsLinkButton}>
                            <Tooltip placement='rightTop' title={resources.tooltips.editRole}>
                                <Link to={'/administration/permission/' + record.name + '/' + record.id}>
                                    <Button icon='arrow-right' />
                                </Link>
                            </Tooltip>
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.DeleteCampaignButton}>
                            <Tooltip placement='rightTop' title={resources.tooltips.deleteRole}>
                                <Button icon='delete' onClick={() => this.props.deleteCampaign(record.id)} />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];
        
        return (
            <DataTable
                tableName={'CampaignsAdminTable'}
                columns={columns}
                data={this.updateCountryDate()}
                loading={this.props.loading}
            />
        )
    }
}