import * as React from 'react';
import styles from './styles/BecomeAPartnerBtn.module.scss';
import { createAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { resources } from '../../../../common/Resources';

export class BecomeAPartnerBtn extends React.Component {
    render() {
        return (
            <a className={`h4__bold ${styles.becomeAPartnerBtn}`} href={createAbsoluteUrl('register')}>{resources.landingPage.becomeAPartner}</a>
        );
    }
}