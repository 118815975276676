import moment from "moment";
import { resources } from "../../../../common/Resources";
import { GROUP_BY_OPTIONS } from "./group-by-data";

type ColumnItem = {
  [key: string]: string;
};

const TOTAL_LABEL = "Total";

const sorterFunctionForName = (a: ColumnItem, b: ColumnItem) => {
  // This comparison we need to stick our "Total" column
  if (!a.key || !b.key) {
    return 0;
  }
  return a.name.localeCompare(b.name);
};

const sorterFunctionForDate = (a: ColumnItem, b: ColumnItem): number => {
  if (!a.key || !b.key) {
    return 0;
  }
  return moment(a.time).unix() - moment(b.time).unix();
};

const groupByColumn = [
  {
    name: GROUP_BY_OPTIONS.hour,
    value: (date: moment.Moment) =>
      // When we show total row then in groupBy column should be total label
      date ? moment.utc(date).format("DD MMM HH:mm") : TOTAL_LABEL,
    label: resources.labels.hour,
    sorter: sorterFunctionForDate,
    index: "time",
  },
  {
    name: GROUP_BY_OPTIONS.day,
    value: (date: moment.Moment, x: any) =>
      date ? moment.utc(date).format("DD MMM") : TOTAL_LABEL,
    label: resources.labels.day,
    sorter: sorterFunctionForDate,
    index: "time",
  },
  {
    name: GROUP_BY_OPTIONS.month,
    value: (date: moment.Moment) =>
      date ? moment.utc(date).format("MMM YYYY") : TOTAL_LABEL,
    label: resources.labels.month,
    sorter: sorterFunctionForDate,
    index: "time",
  },
  {
    name: GROUP_BY_OPTIONS.year,
    value: (date: moment.Moment) =>
      date ? moment.utc(date).format("YYYY") : TOTAL_LABEL,
    label: resources.labels.year,
    sorter: sorterFunctionForDate,
    index: "time",
  },
  {
    name: GROUP_BY_OPTIONS.country,
    value: (value: string) => value || TOTAL_LABEL,
    label: resources.labels.country,
    sorter: sorterFunctionForName,
    index: "name",
  },
  {
    name: GROUP_BY_OPTIONS.product,
    value: (value: string) => value || TOTAL_LABEL,
    label: resources.labels.product,
    sorter: sorterFunctionForName,
    index: "name",
  },
  {
    name: GROUP_BY_OPTIONS.partner,
    value: (value: string) => value || TOTAL_LABEL,
    label: resources.labels.partner,
    sorter: sorterFunctionForName,
    index: "name",
  },
  {
    name: GROUP_BY_OPTIONS.payout,
    value: (value: string) => value || TOTAL_LABEL,
    label: resources.labels.payoutScheme,
    sorter: sorterFunctionForName,
    index: "name",
  },
  {
    name: GROUP_BY_OPTIONS.pub_id,
    value: (value: string) => value || TOTAL_LABEL,
    label: resources.labels.pub_id,
    sorter: sorterFunctionForName,
    index: "name",
  },
  {
    name: GROUP_BY_OPTIONS.lp_url,
    value: (value: string) => value || TOTAL_LABEL,
    label: resources.labels.lp_url,
    sorter: sorterFunctionForName,
    index: "name",
  },
];

export const getColumnByGroup = (group: string) =>
  groupByColumn.find((column) => column.name === group);
