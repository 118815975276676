import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FILTERS } from "../filters-data";

import { UPDATE_QTY_FILTER_ENTITIES, useFilters } from "../filters-state";
import { USER_ROLES } from "../../../../common/enums/UserRoles";

const partnerStatusOptions = [
  { id: 4, name: "Awaiting", value: "awaiting" },
  { id: 9, name: "Double", value: "double" },
  { id: 7, name: "Excluded", value: "excluded" },
  { id: 6, name: "Rejected", value: "rejected" },
  { id: 5, name: "Sold", value: "sold" },
  { id: 8, name: "Trash", value: "trash" },
];
const adminStatusOptions = [
  ...partnerStatusOptions,
  { id: 3, name: "Send to CC", value: "send-to-cc" },
  { id: 2, name: "Validated", value: "validated" },
];

export const useStatusEntity = () => {
  const { dispatch } = useFilters();
  const isAdmin = useSelector((state: any) => {
    const userRole = state.commonState.appUserInfo.id_users_role;
    return USER_ROLES.ADMIN || userRole === USER_ROLES.SUPERADMIN;
  });
  const status = isAdmin ? adminStatusOptions : partnerStatusOptions;

  useEffect(() => {
    dispatch({
      type: UPDATE_QTY_FILTER_ENTITIES,
      payload: { filter: FILTERS.STATUS, qty: Object.keys(status).length },
    });
  }, [dispatch, status]);

  return { entity: status, isLoading: false };
};
