import * as React from 'react';
import { DataTable } from '../../../components/others/data/DataTable';
import { resources } from '../../../common/Resources';
import { Table, Tooltip, Button } from 'antd';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import AccountManagerForm from '../forms/AccountManagerForm';
import { findInDictionaryAndReturnValue } from '../../../helpers/DisctionariesHelper';
import { displayIconStatusWithTooltip } from '../../../helpers/IconsGeneratorHelper';
import { spanWithTitle } from '../../../components/others/SpanWithTitle';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../components/authorizations/protectedComponents/ProtectedComponent';
import { Link } from 'react-router-dom';
import { createAbsoluteUrl } from '../../../helpers/UrlCreator';
import { getActivityFilters } from '../../../common/dictionaries/Filters';

const { Column } = Table;

export class AccountManagersTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='70px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.email}
                key='email'
                align='center'
                width='200px'
                sorter={(a, b) => (a.email ? a.email : "").localeCompare(b.email ? b.email : "")}
                {...this.getColumnSearchProps('email')}
                render={(record) => (
                    <Tooltip title={record.email}>
                        {record.email !== null && record.email.length > 24 ? record.email.substring(0, 20) + '...' : record.email}
                    </Tooltip>
                )}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                width='200px'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.second_name}
                dataIndex='second_name'
                key='second_name'
                align='center'
                width='150px'
                sorter={(a, b) => (a.second_name ? a.second_name : "").localeCompare(b.second_name ? b.second_name : "")}
                {...this.getColumnSearchProps('second_name')}
            />,
            <Column
                title={resources.columnTitle.short_name}
                dataIndex='short_name'
                key='short_name'
                align='center'
                width='150px'
                sorter={(a, b) => (a.short_name ? a.short_name : "").localeCompare(b.short_name ? b.short_name : "")}
                {...this.getColumnSearchProps('short_name')}
            />,
            <Column
                title={resources.columnTitle.country}
                key='id_country'
                align='center'
                width='120px'
                sorter={(a, b) => a.id_country - b.id_country}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValue(record.id_country, this.props.countries)
                    )}
            />,
            <Column
                title={resources.columnTitle.description}
                key='description'
                align='center'
                width='200px'
                sorter={(a, b) => (a.description ? a.description : "").localeCompare(b.description ? b.description : "")}
                {...this.getColumnSearchProps('description')}
                render={(record) => (
                    <Tooltip title={record.description}>
                        {record.description && record.description !== null && record.description.length > 24 ? record.description.substring(0, 20) + '...' : record.description}
                    </Tooltip>
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.active, resources.columnTitle.active_short)}
                key='is_activated'
                align='center'
                width='95px'
                sorter={(a, b) => a.is_activated - b.is_activated}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_activated === value}
                render={(record) => (
                    displayIconStatusWithTooltip(record.is_activated)
                )}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                width='110px'
                align='center'
                fixed='right'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditAccountManagerModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.title.edit_account_manager}
                                buttonTooltip={resources.title.edit}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={600}
                            >
                                <AccountManagerForm
                                    id={record.id}
                                    email={record.email}
                                    name={record.name}
                                    second_name={record.second_name}
                                    short_name={record.short_name}
                                    id_country={record.id_country}
                                    description={record.description}
                                    application_access={record.application_access}
                                    refreshList={this.props.refreshList}
                                    countries={this.props.countries}
                                    updateObjInArray={this.props.updateObjInArray}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.AccountManagersTableChangeStatusButton}>
                            <Tooltip placement="rightTop" title={resources.title.change}>
                                <Button
                                    value={record.id}
                                    onClick={() => this.props.changeStatus(record.id, record.is_activated)}
                                    icon={record.is_activated ? "close" : "check"}
                                    style={{ color: record.is_activated ? 'red' : 'green' }}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.EditAccountManagerLinkButton}>
                            <Tooltip placement='rightTop' title={resources.tooltips.edit}>
                                <Link to={createAbsoluteUrl('users_information/' + record.id)}>
                                    <Button icon='arrow-right' />
                                </Link>
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName={'AccountManagerTable'}
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }
}