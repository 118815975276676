import * as React from "react";
import searchIcon from "../images/search.svg";
import sortByIcon from "../images/sort_by.svg";
import styles from "../styles/OffersList.module.scss";
import { getProductImages } from "../../../../services/m30_products/images/ProductImagesService";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import { hasPermission } from "../../../../authorizations/AuthorizationLogic";
import { IOffersListFiltersFormProps } from "../forms/interfaces/IOffersListFiltersFormProps";
import { IProductCardProps } from "../interfaces/IProductCardProps";
import { IProductImageFilters } from "../../../../services/m30_products/images/interfaces/IProductImageFilters";
import { List, Radio } from "antd";
import { OffersListFiltersForm } from "../forms/OffersListFiltersForm";
import { PopUpForm } from "../../../../components/popUpForm/PopUpForm";
import { ProductCard } from "../components/product/ProductCard";
import { resources } from "../../../../common/Resources";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";
import { ViewHeader } from "../../../../components/viewHeader/ViewHeader";

export class OffersListContent extends React.Component {
  _isMounted = false;

  state = {
    productsListBase: Array<IProductCardProps>(),
    productsList: Array<IProductCardProps>(),
    src_list: [],
    globalSearchString: "",
    pageSize: 18,
    loading: false,
    beauty: undefined,
    health: undefined,
    slimming: undefined,
    adult: undefined,
    other: undefined,
    indonesia1: undefined,
    indonesia2: undefined,
    thailand: undefined,
    vietnam: undefined,
    saudiArabia: undefined,
    cpa: undefined,
    cpl: undefined,
    globalSearchDisplay: "none",
    visibleSort: false,
    visibleRequest: false,
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.getProductsList();
    this.getProductImg();
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    const { length: count } = this.state.productsList
      ? this.state.productsList
      : [];

    return (
      <section className={`customAnt--offersView ${styles.offersList}`}>
        <div className={styles.header}>
          <ViewHeader title={resources.offersView.offers} />
          <div className={styles.filtersGrid}>
            <input
              style={{ display: this.state.globalSearchDisplay }}
              className={styles.globalSearchInput}
              onChange={this.onGlobalSearch}
              disabled={this.state.loading}
            />
            <button
              className={styles.showGlobalSearch}
              onClick={this.showGlobalSearch}
            >
              <img src={searchIcon} alt="search" />
            </button>
            <button className={styles.sortByIcon} onClick={this.toggleSort}>
              <img src={sortByIcon} alt="sort by" />
            </button>
            <PopUpForm
              visible={this.state.visibleSort}
              onCancel={this.toggleSort}
            >
              <OffersListFiltersForm
                onChangeFilters={this.onChangeFilters}
                toggle={this.toggleSort}
                {...this.state}
              />
            </PopUpForm>
          </div>
        </div>
        <div className={styles.counterGrid}>
          <p className={`${styles.counter}`}>
            {count > 0 ? count : 0} {resources.offersView.offersFound}
          </p>
          <div className={`customAnt--radioButton ${styles.radioGrid}`}>
            <label className={`h6__semibold`}>
              {resources.offersView.show}
            </label>
            <Radio.Group
              value={this.state.pageSize}
              name="pageSize"
              onChange={this.onChange}
            >
              <Radio.Button value={9}>9</Radio.Button>
              <Radio.Button value={12}>12</Radio.Button>
              <Radio.Button value={15}>15</Radio.Button>
              <Radio.Button value={18}>18</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className={`${styles.listGrid}`}>
          <List
            loading={this.state.loading}
            pagination={{
              pageSize: this.state.pageSize,
            }}
            grid={{ gutter: 20, md: 3, xs: 1 }}
            dataSource={this.state.productsList}
            renderItem={(item) => (
              <List.Item>
                <ProductCard {...item} src_list={this.state.src_list} />
              </List.Item>
            )}
          />
        </div>
      </section>
    );
  }

  getProductImg = () => {
    const object: IProductImageFilters = {
      is_main: true,
    };

    getProductImages("all", object).then((src_list) => {
      src_list !== null && this._isMounted && this.setState({ src_list });
    });
  };

  onChange = (event: any): void =>
    this.setState({ [event.target.name]: event.target.value });

  toggleSort = () => this.setState({ visibleSort: !this.state.visibleSort });

  onChangeFilters = (obj: IOffersListFiltersFormProps) => {
    this.setState(
      {
        beauty: obj.beauty,
        health: obj.health,
        slimming: obj.slimming,
        adult: obj.adult,
        other: obj.other,
        indonesia1: obj.indonesia1,
        indonesia2: obj.indonesia2,
        thailand: obj.thailand,
        vietnam: obj.vietnam,
        saudiArabia: obj.saudiArabia,
        cpa: obj.cpa,
        cpl: obj.cpl,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    const baseList = this.state.productsListBase;
    const globalString = this.state.globalSearchString;
    const beautyFilter = this.state.beauty;
    const healthyFilter = this.state.health;
    const slimmingFilter = this.state.slimming;
    const adultFilter = this.state.adult;
    // const otherFilter = this.state.other;
    const indonesia1Filter = this.state.indonesia1;
    const indonesia2Filter = this.state.indonesia2;
    const thailandFilter = this.state.thailand;
    const vietnamFilter = this.state.vietnam;
    const saudiFilter = this.state.saudiArabia;
    const cpaFilter = this.state.cpa;
    const cplFilter = this.state.cpl;

    // global search filter
    const globalSearchResult = baseList.filter((product) => {
      return (
        product.name_short &&
        product.name_short!.toLowerCase().includes(globalString)
      );
    });

    // cat filter
    const catFilterResult = globalSearchResult.filter((product) => {
      return (
        product.categories !== null &&
        ((beautyFilter ? product.categories!.includes("1") : false) ||
          (healthyFilter ? product.categories!.includes("2") : false) ||
          (slimmingFilter ? product.categories!.includes("3") : false) ||
          (adultFilter ? product.categories!.includes("4") : false) ||
          (!beautyFilter && !healthyFilter && !slimmingFilter && !adultFilter))
      );
    });

    // country filter
    const countryFilterResult = catFilterResult.filter((product) => {
      // Indonasia  and Indonasia2 filter
      // HOTFIX
      //:TODO move that logic to backend
      let indonesiaFilter = false;
      if (hasPermission(UserPermissionType.SeeOriginalProductCountry)) {
        indonesiaFilter =
          (indonesia1Filter ? product.country_id === 1 : false) ||
          (indonesia2Filter ? product.country_id === 7 : false);
      } else {
        indonesiaFilter = indonesia1Filter
          ? product.country_id === 1 || product.country_id === 7
          : false;
      }
      return (
        indonesiaFilter ||
        (thailandFilter ? product.country_id === 3 : false) ||
        (vietnamFilter ? product.country_id === 5 : false) ||
        (saudiFilter ? product.country_id === 4 : false) ||
        (!indonesia1Filter &&
          !indonesia2Filter &&
          !thailandFilter &&
          !vietnamFilter &&
          !saudiFilter)
      );
    });

    // conversion type filter
    const conversionTypeFilterResult = countryFilterResult.filter((product) => {
      return (
        (cpaFilter ? product.default_payout_schema === "cpa" : false) ||
        (cplFilter ? product.default_payout_schema === "cpl" : false) ||
        (!cpaFilter && !cplFilter)
      );
    });

    const result = conversionTypeFilterResult;

    this.setState({ productsList: result });
  };

  onGlobalSearch = (event: any) =>
    this.setState(
      { globalSearchString: event.target.value.toLowerCase() },
      () => this.applyFilters()
    );

  showGlobalSearch = () =>
    this.setState({
      globalSearchDisplay:
        this.state.globalSearchDisplay === "block" ? "none" : "block",
    });

  getProductsList = () => {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id",
        "name_short",
        "default_landing_price",
        "country_name",
        "default_payout",
        "categories",
        "default_payout_schema",
        "country_id",
      ],
      is_visible_affbay: 1,
      is_visible_partners: 1,
    };

    getProducts(object)
      .then((response) => {
        const productsList = response.sort((a: any, b: any) =>
          (a.name_short ? a.name_short : "").localeCompare(
            b.name_short ? b.name_short : ""
          )
        );

        this._isMounted &&
          this.setState({
            productsListBase: productsList,
            productsList,
          });
      })
      .finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
  };
}
