import moment from "moment";

import { intToTimezoneString } from "../../../../helpers/DateFormatersHelpers";
import { FilterItem, FiltersType } from "../filters-state";

const TIMEZONE_OFFSET = -13;

export const FILTERS = Object.freeze({
  STATUS: "status",
  COUNTRY: "countryId",
  PRODUCT: "productId",
  PARTNER: "partnerId",
  COMPANY: "companyId",
  END_DATE: "endDate",
  CONTACTED: "isContacted",
  QUALIFIED: "isQualified",
  START_DATE: "startDate",
  PAYOUT_SCHEME: "payoutScheme",
  TRAFFIC_SOURCE: "trafficSource",
  PUB_ID: "api_pub_id",
  LP_URL: "api_lp_url",
});

export const FILTERS_INDICATORS = Object.freeze({
  status: "Status",
  api_pub_id: "pub_id",
  api_lp_url: "lp_url",
  countryId: "Country",
  productId: "Product",
  partnerId: "Partner",
  companyId: "Company",
  isContacted: "Contacted",
  isQualified: "Qualified",
  payoutScheme: "Payout scheme",
  trafficSource: "Remarketing",
});

export const INITIAL_DATE_RANGE = Object.freeze({
  endDate: moment().endOf("day"),
  startDate: moment().subtract(6, "day").startOf("day"),
});

export const createFilterData = (
  name: string,
  value: number | string | boolean | undefined,
  filter: string
) => ({ name, value, filter });

const getFilterArrayValue = (value: FilterItem[]) =>
  value.length > 0 ? value.map((item) => item.value) : undefined;

export const createApiFiltersData = (
  state: FiltersType & { findBy: string }
) => ({
  // We have to use "created_at" by default
  findBy: state.findBy || "created_at",
  status: getFilterArrayValue(state.status),
  groupBy: state.groupBy,
  endDate: state.endDate.utcOffset(0, true).format(),
  timezone: intToTimezoneString(state.timezone + TIMEZONE_OFFSET),
  api_pub_id: getFilterArrayValue(state.api_pub_id),
  api_lp_url: getFilterArrayValue(state.api_lp_url),
  productId: getFilterArrayValue(state.productId),
  partnerId: getFilterArrayValue(state.partnerId),
  companyId: getFilterArrayValue(state.companyId),
  countryId: state.countryId?.value,
  startDate: state.startDate.utcOffset(0, true).format(),
  isContacted: state.isContacted?.value,
  isQualified: state.isQualified?.value,
  payoutScheme: state.payoutScheme?.value,
  trafficSource: getFilterArrayValue(state.trafficSource)?.flat(),
});
