import React from "react";
import { Upload, Button, Icon } from "antd";

import { getToken } from "../../../../../../helpers/LocalStorageHelpers";
import { resourceDef } from "../../../../../../common/ResourceDef";
import { createGatewayAbsoluteUrl } from "../../../../../../helpers/UrlCreator";
import { queryClient } from "../../../../../../App";

const productImagesUrl = createGatewayAbsoluteUrl(resourceDef.Images);

export const UploadImage = ({ productId }: { productId: number }) => {
  const onChange = (info: any) => {
    if (info.file.status === "done") {
      return queryClient.invalidateQueries("product-images");
    }
  };

  return (
    <Upload
      name="image"
      action={`${productImagesUrl}${productId}/imageUpload`}
      headers={{
        Authorization: `Bearer ${getToken()}`,
      }}
      onChange={onChange}
      showUploadList={false}
    >
      <Button type="primary">
        <Icon type="upload" /> Upload image
      </Button>
    </Upload>
  );
};
