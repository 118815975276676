import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const updateUrl = createGatewayAbsoluteUrl(resourceDef.FocusCampaignsUpdate);
const getUrl = createGatewayAbsoluteUrl(resourceDef.FocusCampaignsGet);
const libUrl = createGatewayAbsoluteUrl(resourceDef.FocusCampaignsLib);
const resetUrl = createGatewayAbsoluteUrl(resourceDef.FocusCampaignsReset);

export const updateFocusCampaigns = (id_instance?: string) => axios.post(`${updateUrl}/${id_instance}`)
    .then((response) => {
        if (response.status === 208) {
            openAlertNotification('Another process is running!');
            return [];
        }
        else {
            openSuccessNotification('Campaigns updated!');
            return response.data.data;
        }
    })
    .catch((error) => {
        if (error.response && error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            console.log(error);
            openErrorNotification('Timeout expired');
        }

        return false;
    });

export const getFocusCampaigns = (id_instance?: string) => axios.post(getUrl + id_instance)
    .then((response) => { return response.data.data; })
    .catch((error) => {
        console.log(error);

        if (error.response && error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            openErrorNotification('Timeout expired');
        }

        return false;
    });

export const libFocusCampaigns = () => axios.post(libUrl)
    .then(
        () => {
            openSuccessNotification('Campaigns libs updated!')
        })
    .catch((error) => {
        if (error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            openErrorNotification(' libFocusCampaigns undefined');
        }
    });

export const resetFocusCampaigns = () => axios.post(resetUrl)
    .then(
        () => {
            openSuccessNotification('Campaigns locked microservice reseted!')
        })
    .catch((error) => {
        if (error.response.status === 409) {
            openAlertNotification('Another process is running');
        }
        else {
            openErrorNotification(' resetFocusCampaigns undefined');
        }
    });