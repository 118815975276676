import * as React from 'react';
import noImg from '../../../../common/images/noImg.png';
import styles from './styles/ProductCard.module.scss';
import { createAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { displayPercent, displayPrice } from '../../../../helpers/DisplayHepler';
import { findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';
import { IProductCardProps } from './interfaces/IProductCardProps';
import { removeLastCharIfNumber } from '../../../../helpers/Encoders';
import { resources } from '../../../../common/Resources';

export class ProductCard extends React.Component<IProductCardProps> {
    state = {
        locale: 'en_GB'
    }

    componentDidMount() {
        this.setState({ locale: (localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en_GB') });
    }

    render() {
        const { name, default_landing_price, approve_page, default_payout, country_id } = this.props.product;
        const currencyDict = this.props.countries.map((country: any) => ({id: country.id, name: country.currency}))

        return (
            <div className={styles.productCard}>
                <h5 className={`h3 ${styles.productCard__title}`}>{name}</h5>
                {/* <p className={`h5 ${styles.productCard__description}`}>{
                    description_short_en !== null ?
                        (this.state.locale === 'en_GB' ?
                            description_short_en
                            :
                            description_short_ru
                        )
                        :
                        // (resources.informations.notAvailable)
                        ''
                }</p> */}
                <img className={styles.productCard__img} src={this.findProductImg()} alt={name} />
                <div className={styles.productCard__table}>
                    <div className={styles.productCard__table__row}>
                        <p className={`h4 ${styles.productCard__table__row__label}`}>{resources.landingPage.sitePrice}</p>
                        <p className={`h4__bold ${styles.productCard__table__row__value}`}>{displayPrice(default_landing_price, findInDictionaryAndReturnValue(country_id, currencyDict))}</p>
                    </div>
                    <div className={styles.productCard__table__row}>
                        <p className={`h4 ${styles.productCard__table__row__label}`}>{resources.landingPage.approve}</p>
                        <p className={`h4__bold ${styles.productCard__table__row__value}`}>{displayPercent(approve_page)}</p>
                    </div>
                    <div className={styles.productCard__table__row}>
                        <p className={`h4 ${styles.productCard__table__row__label}`}>{resources.landingPage.payouts}</p>
                        <p className={`h4__bold ${styles.productCard__table__row__value}`}>{displayPrice(default_payout)}</p>
                    </div>
                    <div className={styles.productCard__table__row}>
                        <p className={`h4 ${styles.productCard__table__row__label}`}>{resources.landingPage.country}</p>
                        <p className={`h4__bold ${styles.productCard__table__row__value}`}>{removeLastCharIfNumber(findInDictionaryAndReturnValue(country_id, this.props.countries))}</p>
                    </div>
                </div>
                <a className={`h4__bold ${styles.productCard__button}`} href={createAbsoluteUrl('register')}>{resources.landingPage.startEarning}</a>
            </div>
        )
    }

    findProductImg = () => {
        const obj: any[] = this.props.src_list.filter(x => x.id_product === this.props.product.id);
        const src = obj.length ? obj[0].src : undefined;

        return src ? src : noImg;
    }
}