import React from "react";
import { Radio, Spin } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

import { createFilterData } from "../../../lib/filters-data";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

export const SingleChoiceFilter = ({
  filter,
  entityHook,
}: {
  filter: string;
  entityHook: any;
}) => {
  const { state, dispatch } = useFilters();
  const { entity, isLoading } = entityHook();

  const onChangeFilter = (e: RadioChangeEvent) => {
    const entityItem = entity.find((c: any) => c.id === e.target.value);
    dispatch({
      type: UPDATE_FILTERS,
      payload: {
        [filter]: createFilterData(
          entityItem.name as string,
          entityItem.id as number | undefined,
          filter
        ),
      },
    });
  };

  if (isLoading) {
    return (
      <Spin
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(calc(-50% + 40px), -50%)",
        }}
      />
    );
  }

  return (
    <div>
      <Radio.Group
        style={{ display: "flex", flexDirection: "column" }}
        value={state[filter]?.value}
        onChange={onChangeFilter}
      >
        {entity.map((e: any, index: number) => (
          <Radio key={index} style={{ marginBottom: "10px" }} value={e.id}>
            {e.name}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};
