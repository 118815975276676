import axios from 'axios';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const TrafficSources = 'SELECT id, id as "key", name, lead_type, code_name, description, created_at, updated_at, exclude_from_double, is_active FROM remarketing_campaigns';

export const getTrafficSourcesDictionaryList = () => axios.post(sourceUrl, { data: { sql: TrafficSources } })
    .then(response => {  return response.data;  })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getTrafficSourcesDictionaryList');
        return [];
    });

export const createTrafficSourceDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => response.data.insertId)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'createTrafficSourceDictionary');
        return undefined;
    });

export const updateTrafficSourceDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true )
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'updateTrafficSourceDictionary');
        return false;
    });

export const deleteTrafficSourceDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'deleteTrafficSourceDictionary');
        return false;
    });