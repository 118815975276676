import * as React from "react";
import ProtectedComponent from "../../../../components/authorizations/protectedComponents/ProtectedComponent";
import SelectOnChange from "../../../../components/forms/select/SelectOnChange";
import { ClassifiersTable } from "../tables/ClassifiersTable";
import { ClearBoth } from "../../../../components/controls/clearBoth/ClearBoth";
import { FieldInfo } from "../../../../components/common/fieldInfo/FieldInfo";
import { FoundRowsNumber } from "../../../../components/common/fountRowsNumber/FountRowsNumber";
import {
  getCcInstancesD,
  getListFromLibrary,
} from "../../../../services/m40_libraries/LibrariesService";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import {
  getFocusClassifiers,
  updateFocusClassifiers,
} from "../../../../services/focus/FocusClassifiers";
import { getInstancesDictionaryWithCountries } from "../../../../helpers/InstancesDictionaryHelper";
import { ICallCenterClassifiers } from "../interfaces/ICallCenterClassifiers";
import { ICampaign } from "../interfaces/ICampaign";
import { IClassifierFilters } from "../../../../services/focus/interfaces/IClassifierFilters";
import { IClassifiersParamsProps } from "../interfaces/IClassifiersParamsProps";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IInstance } from "../../../../models/IInstance";
import { INullableClassifierForm } from "../forms/interfaces/INullableClassifierForm";
import { Left } from "../../../../components/controls/left/Left";
import { megaUpdate } from "../../../../services/callCenter/MegaUpdateService";
import { MegaUpdateButton } from "../../../../components/megaUpdateButton/MegaUpdateButton";
import { PageInf } from "../../../../components/common/pageInf/PageInf";
import { RefreshTableButton } from "../../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../../common/Resources";
import { Right } from "../../../../components/controls/right/Right";
import { SearchElements } from "../../../../components/common/searchElements/SearchElements";
import { TableContainer } from "../../../../components/containers/tableContainer/TableContainer";
import { UploadButton } from "../../../../components/megaUpdate/UploadButton";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";
import "../styles/ClassifiersStyle.css";

const obj = { id: 0, name: "All" };

export class ClassifiersContent extends React.Component<IClassifiersParamsProps> {
  _isMounted = false;

  state = {
    loading: false,
    id_instance: 0,
    id_campaign: 0,
    instancesD: Array<IInstance>(),
    campaignsD: Array<IDictionaryValue>(),
    classifiersList: Array<ICallCenterClassifiers>(),
    instancesList: Array<IInstance>(),
    campaignsList: Array<ICampaign>(),
    campaignsListBase: Array<ICampaign>(),
    statusesD: Array<IDictionaryValue>(),
    search: undefined,
  };

  componentDidMount = async () => {
    this._isMounted = true;

    await this.setParamsFromUrl();

    this.getClassifiersList();
    this.getInstancesDictionaryWithCountries();
    this.getInstancesD();
    this.getCampaignsD();
    this.getCampaignsList();

    this.setState({ statusesD: getDictionary("statusesD") });
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    const {
      classifiersList,
      id_instance,
      id_campaign,
      instancesList,
      campaignsList,
      loading,
      instancesD,
      statusesD,
      campaignsD,
      search,
    } = this.state;

    let filteredData = classifiersList;

    if (search)
      filteredData = filteredData.filter((x) =>
        (x.name ? x.name.toLocaleLowerCase() : "").includes(search!)
      );

    const { length: count } = filteredData;

    return (
      <TableContainer>
        <PageInf
          title={resources.pageTitle.classifiers}
          subTitle={this.getTitle(id_instance, id_campaign)}
        />
        <Left>
          <RefreshTableButton
            className="marginTopRefreshButton23px marginBottom marginRigth"
            loading={this.state.loading}
            onClick={this.getClassifiersList}
          />
        </Left>
        <Left>
          <FieldInfo>{resources.labels.instances}</FieldInfo>
          <SelectOnChange
            className="marginRigth"
            onChange={this.onChangeSelect}
            name="id_instance"
            value={Number(id_instance)}
            items={instancesList}
            disabled={loading}
            loading={loading}
            width={200}
            labelClassName="marginLeft marginBottom"
            allowClear
          />
        </Left>
        <FieldInfo>{resources.labels.campaigns}</FieldInfo>
        <SelectOnChange
          onChange={this.onChangeSelect}
          name="id_campaign"
          value={Number(id_campaign)}
          items={campaignsList}
          disabled={loading}
          loading={loading}
          width={200}
          labelClassName="marginLeft marginBottom"
          allowClear
        />
        <ProtectedComponent permission={UserPermissionType.SeeMegaUploadButton}>
          <Right>
            <MegaUpdateButton
              buttonTitle="Mega upload"
              className="marginBottom marginLeft"
              loading={this.state.loading}
              onClick={this.megaUpdate}
            />
          </Right>
        </ProtectedComponent>
        <Right>
          <UploadButton
            buttonTitle={resources.buttons.upload}
            className="marginBottom"
            loading={this.state.loading}
            onClick={this.updateClassifiersAndRefreshDataTable}
          />
        </Right>
        <Right>
          <SearchElements
            className="marginRigth"
            onSearch={this.onGlobalSearch}
            loading={loading}
          />
        </Right>
        <Right>
          <FoundRowsNumber className="marginRigth" count={count} />
        </Right>
        <ClearBoth />
        <ClassifiersTable
          data={filteredData}
          loading={loading}
          instancesD={instancesD}
          statusesD={statusesD}
          campaignsD={campaignsD}
          updateArray={this.updateArray}
          setLoading={this.setLoading}
          id_instance={id_instance}
          id_campaign={id_campaign}
        />
      </TableContainer>
    );
  }

  getClassifiersList = () => {
    this.setState({ loading: true });

    const { id_instance, id_campaign } = this.state;

    const obj: IClassifierFilters = {
      id_instance: Number(id_instance),
      id_campaign: Number(id_campaign),
    };

    getFocusClassifiers(obj)
      .then((classifiersList) => {
        this._isMounted && this.setState({ classifiersList });
      })
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };

  getCampaignsList = async () => {
    const object = {
      name: "cc_campaigns",
      column: "id, name, id_instance",
    };

    let campaignsList: ICampaign[] = await getListFromLibrary(object);

    campaignsList = [obj, ...campaignsList];

    this._isMounted &&
      this.setState({
        campaignsList,
        campaignsListBase: campaignsList,
      });
  };

  getInstancesDictionaryWithCountries = () => {
    getInstancesDictionaryWithCountries().then(
      (instancesList) => this._isMounted && this.setState({ instancesList })
    );
  };

  getInstancesD = () => {
    getCcInstancesD().then(
      (instancesD) => this._isMounted && this.setState({ instancesD })
    );
  };

  getCampaignsD = () => {
    const object = {
      name: "cc_campaigns",
      column: "id, name, id_instance, id_external",
    };

    getListFromLibrary(object).then(
      (campaignsD) => this._isMounted && this.setState({ campaignsD })
    );
  };

  setParamsFromUrl = () => {
    if (this.props.instance_id || this.props.campaign_id)
      this.setState({
        id_instance: this.props.instance_id ? this.props.instance_id : 0,
        id_campaign: this.props.campaign_id ? this.props.campaign_id : 0,
      });
  };

  onChangeSelect = (name: string, value: number): void => {
    if (value) {
      if (name === "id_instance") {
        let campaignsList = this.state.campaignsListBase.filter(
          (x) => x.id_instance === value
        );
        campaignsList = [obj, ...campaignsList];

        this.setState(
          {
            campaignsList,
            [name]: value,
            id_campaign: 0,
          },
          () => this.updatePage()
        );
      } else {
        this.setState({ [name]: value }, () => this.updatePage());
      }
    } else {
      if (name === "id_instance") {
        this.setState(
          {
            campaignsList: this.state.campaignsListBase,
            [name]: 0,
            id_campaign: 0,
          },
          () => this.updatePage()
        );
      } else {
        this.setState({ [name]: 0 }, () => this.updatePage());
      }
    }
  };

  updatePage = () => {
    this.setUrl();
    this.getClassifiersList();
  };

  setUrl = () => {
    let url = `/cc_classifiers`;

    if (this.state.id_instance)
      url = url + `/instance/${this.state.id_instance}`;
    if (this.state.id_campaign)
      url = url + `/campaign/${this.state.id_campaign}`;

    window.history.pushState("", "", url);
  };

  getTitle = (id_instance: number, id_campaign: number): string => {
    let title = "All";

    if (id_instance) {
      const instance = this.state.instancesList.filter(
        (x) => x.id === Number(id_instance)
      );

      if (instance.length > 0) title = instance[0].name;
    }
    if (id_campaign) {
      const campaign = this.state.campaignsList.filter(
        (x) => x.id === Number(id_campaign)
      );

      if (campaign.length > 0) title = title + " / " + campaign[0].name;
    }

    return title;
  };

  updateClassifiersAndRefreshDataTable = async () => {
    this.setState({ loading: true });

    const { id_instance, id_campaign } = this.state;

    const obj: IClassifierFilters = {
      id_instance: id_instance,
      id_campaign: id_campaign,
    };

    await updateFocusClassifiers(obj)
      .then((response) => {
        response &&
          this._isMounted &&
          this.setState({ classifiersList: response });
      })
      .finally(() => this.setState({ loading: false }));
  };

  updateArray = (a: any, classifiersList: INullableClassifierForm[]): void =>
    this.setState({ classifiersList });

  onGlobalSearch = (event: any) =>
    this.setState({
      [event.target.name]: event.target.value.toLocaleLowerCase(),
    });

  setLoading = (loading: boolean) => this.setState({ loading });

  megaUpdate = () => {
    this.setState({ loading: true });

    megaUpdate().then((response) => {
      response && this.getClassifiersList();
    });
  };
}
