import axios from "axios";
import { createGatewayAbsoluteUrl } from "../../helpers/UrlCreator";
import { displayHttpStatusCodeInformation } from "../../helpers/HttpStatusCodeHelper";
import { ICompanyCreate } from "./interfaces/ICompanyCreate";
import { ICompanyUpdate } from "./interfaces/ICompanyUpdate";
import { resourceDef } from "../../common/ResourceDef";

const companiesUrl = createGatewayAbsoluteUrl(resourceDef.Companies);
const updateUrl = createGatewayAbsoluteUrl(resourceDef.CompanyUpdate);
const createUrl = createGatewayAbsoluteUrl(resourceDef.CompanyCreate);

const companiesD = {
  columns: ["id", "name"],
};

export const getCompaniesList = (obj: any) =>
  axios
    .post(companiesUrl, { ...obj })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getCompanies M29");
      return [];
    });

export const getCompaniesListD = (name: string = "", params: any = {}) =>
  axios
    .post(companiesUrl, { ...companiesD, name, ...params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getCompaniesListD M29");
      return [];
    });

export const updateCompany = (object: ICompanyUpdate, id_companie: number) =>
  axios
    .patch(updateUrl + `/${id_companie}`, { ...object })
    .then((response) => {
      console.log("updateRole");
      console.log(response);

      return true;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "updateRole M29");
      return false;
    });

export const createCompany = (object: ICompanyCreate) =>
  axios
    .put(createUrl, { ...object })
    .then((response) => {
      console.log("updateRole");
      console.log(response);

      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "updateRole M29");
      return false;
    });
