import React from "react";
import moment from "moment";
import { useQuery } from "react-query";

import { getPayoutsHistory } from "../../../../../services/payouts/PayoutsServices";

export const PayoutsHistory = ({
  productId,
  userId,
}: {
  productId: number;
  userId: number;
}) => {
  const { data, isLoading } = useQuery("payouts-history", () =>
    getPayoutsHistory(userId, productId)
  );

  if (isLoading) return null;

  return (
    <div style={{ marginTop: "15px" }}>
      <span style={{ fontSize: "18px", display: "block", marginBottom: "5px" }}>
        Payout history for this product
      </span>

      {data.items.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th style={{ paddingRight: "10px" }}>Date</th>
              <th style={{ padding: "0 10px" }}>Scheme</th>
              <th style={{ paddingLeft: "10px" }}>Visible</th>
              <th style={{ paddingLeft: "10px" }}>Payout</th>
              <th style={{ paddingLeft: "10px" }}>Guaranteed approve</th>
              <th style={{ paddingLeft: "10px" }}>GA value</th>
            </tr>
          </thead>

          <tbody>
            {data.items.map((item: any) => (
              <tr key={item.id}>
                <td style={{ paddingRight: "10px" }}>
                  {moment
                    .utc(item.started_at)
                    .utcOffset(moment().format("Z"))
                    .format("DD-MM-YYYY HH:mm")}
                </td>

                <td style={{ padding: "0 10px" }}>
                  {item.payout_scheme.toUpperCase()}
                </td>

                <td style={{ paddingLeft: "10px" }}>
                  {item.is_visible ? "Visible" : "Hidden"}
                </td>

                <td style={{ paddingLeft: "10px" }}>
                  {item.payout_value / 100} USD
                </td>

                <td style={{ paddingLeft: "10px" }}>
                  {item.guaranteed_approve ? "ENABLED" : "DISABLED"}
                </td>

                <td style={{ paddingLeft: "10px" }}>{item.ga_value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span>No history items for this product</span>
      )}
    </div>
  );
};
