import axios from 'axios';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;

export const getCouriersStatusesDictionaryList = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getCouriersStatusesDictionaryList');
        return [];
    });

export const createCouriersStatusDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => response.data.insertId)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'createCouriersStatusDictionary');
        return undefined;
    });

export const updateCouriersStatusDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'updateCouriersStatusDictionary');
        return false;
    });

export const deleteCouriersStatusDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'deleteCouriersStatusDictionary');
        return false;
    });