import React, { Component } from 'react'
import { connect, MapDispatchToProps } from 'react-redux';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { Dispatch } from 'redux';
import { setActiveMenuItem } from '../../reducers/CommonReducer';
import { navPath } from '../../common/ResourceDef';
import { CourierStatusMappingContent } from './contents/CourierStatusMappingContent';

export class CourierStatusMappingViewInner extends Component<ISetActiveMenuItem> {

    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.courierStatusMapping);
    }

    render() {
        return (
            <CourierStatusMappingContent />
        )
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const CourierStatusMappingView: React.ComponentClass = connect(null, mapDispatchToProps)(CourierStatusMappingViewInner);

