import * as React from 'react';
import { getLocale } from '../../../../helpers/LocaleHelper';
import { IProduct } from '../../../../models/IProduct';
import { OfferDetailsContainer } from '../../../../components/containers/offerDetailsContainer/OfferDetailsContainer';

interface ITrafficSources {
    productData: IProduct;
}

export class TrafficSources extends React.Component<ITrafficSources> {
    content = () => {
        const traffic_sources = getLocale() === "en_GB" ? this.props.productData.traffic_sources_en : this.props.productData.traffic_sources_ru

        return traffic_sources ? traffic_sources : "";
    }

    public render() {
        return (
            <OfferDetailsContainer>
                <article className='' dangerouslySetInnerHTML={{ __html: this.content() }}></article>
            </OfferDetailsContainer>
        )
    };
}