import { useQuery } from "react-query";

import { FILTERS } from "../filters-data";
import { getPartnersByEmail } from "../../../../services/m29_users_management/GeneralUserService";
import { UPDATE_QTY_FILTER_ENTITIES, useFilters } from "../filters-state";

export const usePartnerEntity = (search: string) => {
  const { dispatch } = useFilters();
  const { data, isLoading } = useQuery(
    ["partners", search],
    () => getPartnersByEmail(search, { is_active: true }),
    {
      enabled: Boolean(search),
      onSuccess: (data) =>
        dispatch({
          type: UPDATE_QTY_FILTER_ENTITIES,
          payload: { filter: FILTERS.PARTNER, qty: data.length },
        }),
    }
  );
  return { entity: data, isLoading };
};
