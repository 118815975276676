import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/SalesChannelDictionaryForm.module.scss';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createCouriersStatusDictionary, updateCouriersStatusDictionary } from '../../../../services/dictionaries/CouriersStatusesDictionaryServic';
import { findInDictionaryAndReturnValue, getDictionary } from '../../../../helpers/DisctionariesHelper';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { ICouriersStatus } from '../interfaces/ICouriersStatus';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class InnerCouriersStatuseDictionaryForm extends React.Component<ICouriersStatus & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<ICouriersStatus>> {
    public render() {
        const couriersD: IDictionaryValue[] = this.props.couriersD ? this.props.couriersD : [];

        return (
            <Form>
                <section>
                    {this.props.courier_id &&
                        <div className={styles.companieName}>
                            {findInDictionaryAndReturnValue(this.props.courier_id, couriersD)}
                            <div className={styles.sectionDivider} />
                        </div>
                    }
                    <div className={styles.grid_col_3}>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='courier_id'
                                label={resources.labels.courier}
                                dictionaryValues={this.props.couriersD}
                                allowClear
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='status_id'
                                label={resources.labels.status}
                                dictionaryValues={getDictionary('statusesD')}
                                allowClear
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="courier_status"
                                label={resources.labels.courier_status}
                                labelTextAlignLeft
                                allowClear
                                boldLabel
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.sectionDivider} />
                    <TextAreaF
                        {...this.props}
                        textAreaRows={4}
                        name="description"
                        label={resources.labels.description}
                        allowClear
                        labelTextAlignLeft
                        boldLabel
                    />
                    <div className={styles.sectionDivider} />
                    <TextAreaF
                        {...this.props}
                        textAreaRows={4}
                        name="additional_info"
                        label={resources.labels.additional_info}
                        allowClear
                        labelTextAlignLeft
                        boldLabel
                    />
                    <Button
                        className='saveButton'
                        htmlType="submit"
                        type="primary"
                    >
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                </section>
            </Form >
        );
    };
}

const CouriersStatuseDictionaryForm = withFormik<ICouriersStatus & IUpdateObjInArray & IAddObjToArray & IId, ICouriersStatus>({
    mapPropsToValues: (props: ICouriersStatus) => {
        return {
            courier_id: props.courier_id,
            status_id: props.status_id,
            courier_status: props.courier_status,
            description: props.description,
            additional_info: props.additional_info,
        };
    },

    validate: (values: ICouriersStatus) => {
        const errors: FormikErrors<ICouriersStatus> = {};

        if (!values.courier_id) {
            errors.courier_id = resources.validation.required;
        }
        if (!values.status_id) {
            errors.status_id = resources.validation.required;
        }
        if (!values.courier_status) {
            errors.courier_status = resources.validation.required;
        }


        return errors;
    },

    handleSubmit: (values: ICouriersStatus, bag: FormikBag<ICouriersStatus & IUpdateObjInArray & IAddObjToArray & IHideModal & IId, ICouriersStatus>) => {
        const object: ICouriersStatus = {
            id: values.id,
            status_id: values.status_id,
            courier_id: values.courier_id,
            courier_status: values.courier_status,
            description: values.description,
            additional_info: values.additional_info,
        }

        if (
            bag.props.id
            && values.status_id === bag.props.status_id
            && values.courier_id === bag.props.courier_id
            && values.courier_status === bag.props.courier_status
            && values.description === bag.props.description
            && values.additional_info === bag.props.additional_info
        )
            openAlertNotification(resources.statement.thePropsAreTheSame);

        else
            createQuery(object, bag.props);
    }

})(InnerCouriersStatuseDictionaryForm);

export default CouriersStatuseDictionaryForm;

const createQuery = async (obj: ICouriersStatus, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal) => {
    const createQuery =
        `INSERT INTO li_couriers_statuses
            (
                status_id,
                courier_id,
                courier_status,
                description,
                additional_info
            )
        VALUES
            (
                ${obj.status_id},
                "${obj.courier_id}",
                "${obj.courier_status}",
                "${obj.description}",
                "${obj.additional_info}"
            )`;

    const updateQuery =
        `UPDATE li_couriers_statuses SET
                status_id = ${obj.status_id},
                courier_id = "${obj.courier_id}",
                courier_status = "${obj.courier_status}",
                description = "${obj.description}",
                additional_info = "${obj.additional_info}"
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateCouriersStatusDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);

                openSuccessNotification(resources.statement.couriers_api_mapping_updated);
            });
    }
    else {
        await createCouriersStatusDictionary(createQuery)
            .then(response => {
                if (response) {
                    obj.id = response;
                    obj.key = response;
                    obj.created_at = new Date();
                    obj.updated_at = new Date();

                    bag.addObjToArray!(obj);

                    openSuccessNotification(resources.statement.couriers_api_mapping_created);
                }
                else
                    openErrorNotification(resources.statement.databaseConnectionError);

            });
    }

    bag.hideModal!();
}