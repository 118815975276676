import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button, Tooltip } from 'antd';
import { resources } from '../../../../common/Resources';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import PostCodeDictionaryForm from '../forms/PostCodeDictionaryForm';
import { findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';

const { Column } = Table;

export class PostCodesDictionaryTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='100px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.country}
                key='country_id'
                width='120px'
                align='center'
                sorter={(a, b) => a.country_id - b.country_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValue(record.country_id, this.props.countriesD)
                    )}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title={resources.columnTitle.deleted_at}
                key='deleted_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.deleted_at).diff(moment.utc(b.deleted_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.deleted_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='70px'
                align='center'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditPostCodeModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editPostCode}
                                buttonTooltip={resources.modalTooltips.editPostCode}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={400}
                            >
                                <PostCodeDictionaryForm
                                    id={record.id}
                                    name={record.name}
                                    country_id={record.country_id}
                                    updateObjInArray={this.props.updateObjInArray}
                                    countriesD={this.props.countriesD}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.DeletePostCodeButton}>
                            <Tooltip placement='rightTop' title={resources.tooltips.deletePostCode}>
                                <Button icon='delete' onClick={() => this.props.deletePostCode(record.id)} />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='StatusesDictionaryTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
            />
        )
    }
}