import * as React from 'react';
import CouriersApiMappingDictionaryForm from '../forms/CouriersApiMappingDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { CouriersApiMappingDictionaryTable } from '../table/CouriersApiMappingDictionaryTable';
import { deleteCouriersApiMappingDictionary, getCouriersApiMappingDictionaryList } from '../../../../services/dictionaries/CouriersApiMappingDictionaryServic';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { ICouriersApiMapping } from '../interfaces/ICouriersApiMapping';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class CouriersApiMappinfDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        selected_country: undefined,
        couriersApiMappingList: Array<ICouriersApiMapping>(),
        countriesD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getCouriersApiMappingList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { couriersApiMappingList, loading, selected_country } = this.state;

        let filteredData = couriersApiMappingList;

        if (selected_country)
            filteredData = filteredData.filter(x => x.country_id === selected_country);

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.couriers_api_mapping} />
                <Left>
                    <RefreshTableButton
                        className='marginBottom marginTopRefreshButton marginRigth'
                        loading={loading}
                        onClick={this.getCouriersApiMappingList}
                    />
                </Left>
                <FieldInfo>{resources.labels.country}</FieldInfo>
                <SelectOnChange
                    name='selected_country'
                    labelClassName='marginLeft marginBottom'
                    onChange={this.onChangeSelect}
                    value={selected_country}
                    placeholder={resources.select.select}
                    items={getDictionary('countriesNameD')}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    allowClear
                />
                <ProtectedModal permission={UserPermissionType.AddNewCouriersApiMappingModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewCouriersApiMapping}
                            buttonTitle={resources.modalButtonsTitle.addNewCouriersApiMapping}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewCouriersApiMapping}
                            icon={"plus"}
                            withHideMethod={true}
                            width={800}
                            buttonClassName='addNew marginBottom'
                            disabled={loading}
                        >
                            <CouriersApiMappingDictionaryForm
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <CouriersApiMappingDictionaryTable
                    loading={loading}
                    data={filteredData}
                    deleteCouriersApiMapping={this.deleteCouriersApiMapping}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getCouriersApiMappingList = () => {
        this.setState({ loading: true });

        const couriersApiMappingQuery = `SELECT 
                                            id, 
                                            id as "key", 
                                            country_id, 
                                            courier_name, 
                                            status, 
                                            courier_status, 
                                            created_at, 
                                            updated_at, 
                                            deleted_at, 
                                            status_description, 
                                            notes, 
                                            user_name
                                        FROM li_couriers_api_mapping`;

        getCouriersApiMappingDictionaryList(couriersApiMappingQuery)
            .then(couriersApiMappingList => this._isMounted && this.setState({ couriersApiMappingList }))
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    deleteCouriersApiMapping = (id: number) => {
        const query = `DELETE FROM li_couriers_api_mapping WHERE id = ${id}`;

        deleteCouriersApiMappingDictionary(query)
            .then(response => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else
                    openErrorNotification(resources.statement.there_was_a_problem_removing_system_variable);

            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        let couriersApiMappingList: ICouriersApiMapping[] = this.state.couriersApiMappingList;

        couriersApiMappingList = couriersApiMappingList.filter(x => x.id !== id);

        this.setState({
            couriersApiMappingList,
            loading: false
        });

        openSuccessNotification(resources.statement.system_variable_deleted);
    }

    addObjToArray = (variable: ICouriersApiMapping) =>
        this.setState({ couriersApiMappingList: [...this.state.couriersApiMappingList, variable] });

    updateObjInArray = (obj: ICouriersApiMapping): void => {
        let couriersApiMappingList: ICouriersApiMapping[] = this.state.couriersApiMappingList;

        const index = couriersApiMappingList.findIndex(x => x.id === obj.id);

        couriersApiMappingList[index].country_id = obj.country_id;
        couriersApiMappingList[index].courier_name = obj.courier_name;
        couriersApiMappingList[index].status = obj.status;
        couriersApiMappingList[index].courier_status = obj.courier_status;
        couriersApiMappingList[index].updated_at = obj.updated_at;
        couriersApiMappingList[index].notes = obj.notes;
        couriersApiMappingList[index].user_name = obj.user_name;

        this.setState({ couriersApiMappingList });
    }

    onChangeSelect = (name: string, value: number): void =>
        this.setState({ [name]: value });
}