import * as React from 'react';
import { getWebhooks } from '../../../../services/Webhooks';
import { IWebhook } from '../interfaces/IWebhook';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { WebhooksTable } from '../tables/WebhooksTable';

export class WebhooksContent extends React.Component {
    _isMounted = false;
    state = {
        loading: false,
        webhooksList: Array<IWebhook>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getWebhooksList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    getWebhooksList = async () => {
        this.setState({ loading: true });

        const query = 'SELECT id, id as "key", domain, is_online, is_active, user, updated_at, created_at, password FROM cc_webhooks';

        await getWebhooks(query)
            .then((webhooksList) => { this._isMounted && this.setState({ webhooksList }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    public render() {
        const { webhooksList, loading } = this.state;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.webhooks} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getWebhooksList}
                />
                <WebhooksTable
                    data={webhooksList}
                    loading={loading}
                />
            </TableContainer>
        );
    };
}