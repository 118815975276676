import AdvancedUrlForm from './AdvancedUrlForm';
import BasicUrlForm from './BasicUrlForm';
import React from 'react';
import { IPostbackUrlForm } from '../interfaces/IPostbackUrlForm';

export enum MODE {
    BASIC,
    ADVANCED,
}

class PostbackUrlContent extends React.Component<IPostbackUrlForm> {

    state = {
        mode: MODE.BASIC,
        currentUrl: this.props.postbackUrl,
    }

    changeModeHandler = () => {
        const nextMode = this.state.mode === MODE.BASIC ? MODE.ADVANCED : MODE.BASIC;
        this.setState({
            mode: nextMode,
        });
    }

    changeUrlHandler = (currentUrl: string) => {
        this.setState({
            currentUrl,
        });
    }

    render() {
        if (this.state.mode === MODE.BASIC) {
            return <BasicUrlForm
                {...this.props}
                mode={this.state.mode}
                onModeChange={this.changeModeHandler}
                postbackUrl={this.state.currentUrl}
                onPostbackUrlChange={this.changeUrlHandler}
                wasChanged={this.props.postbackUrl !== this.state.currentUrl}/>;
        }
        return <AdvancedUrlForm
                {...this.props}
                mode={this.state.mode}
                onModeChange={this.changeModeHandler}
                postbackUrl={this.state.currentUrl}
                onPostbackUrlChange={this.changeUrlHandler}
                wasChanged={this.props.postbackUrl !== this.state.currentUrl}/>;
    };
}

export default PostbackUrlContent;
