import * as React from 'react';
import { DataTable } from '../../../components/others/data/DataTable';
import { Table, Button } from 'antd';

const { Column } = Table;

export class CourierStatusMappingTable extends DataTable {
    render() {
        const columns = [
            <Column
                title='eFirst'
                dataIndex='status'
                key="status"
                align='center'
                sorter={(a, b) => (a.status ? a.status : "").localeCompare(b.status ? b.status : "")}
                {...this.getColumnSearchProps('status')}
            />,
            <Column
                title='Courier'
                dataIndex='courier_status'
                key="courier_status"
                align='center'
                sorter={(a, b) => (a.courier_status ? a.courier_status : "").localeCompare(b.courier_status ? b.courier_status : "")}
                {...this.getColumnSearchProps('courier_status')}
            />,
            <Column
                title='Description'
                dataIndex='status_description'
                key="status_description"
                align='center'
                sorter={(a, b) => (a.status_description ? a.status_description : "").localeCompare(b.status_description ? b.status_description : "")}
                {...this.getColumnSearchProps('status_description')}
            />,
            <Column
                title='Edited by'
                dataIndex='user_name'
                key="unser_name"
                align='center'
                sorter={(a, b) => (a.user_name ? a.user_name : "").localeCompare(b.user_name ? b.user_name : "")}
                {...this.getColumnSearchProps('user_name')}
            />,
            <Column
                title='Options'
                key="Options"
                width="100px"
                align='center'
                render={(record) => (
                    <Button
                        icon='edit'
                        onClick={() => this.props.toggleModal(record)}
                    >
                    </Button>
                )}
            />
        ];

        return (
            <DataTable
                tableName='CourierStatusMappingTable'
                columns={columns}
                data={this.props.data}
                pagination={false}
                loading={this.props.loading}
            />
        )
    }
}
