import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/SubdistrictDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createSubdistrictsDictionary, updateSubdistrictsDictionary } from '../../../../services/dictionaries/SubDistrictsDivtionaryService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { ISubdistrict } from '../interfaces/ISubdistrict';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';

class TemplateSubdistrictDictionaryForm extends React.Component<ISubdistrict & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<ISubdistrict>> {
    public render() {
        return (
            <Form className='formStyle'>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <TextInputF
                    {...this.props}
                    name='name_eng'
                    label={resources.labels.name_eng}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='country_id'
                    label={resources.labels.country}
                    dictionaryValues={this.props.countriesD}
                    labelTextAlignLeft
                    allowClear={false}
                    boldLabel
                />
                <Right>
                    <Button type="primary" htmlType="submit" className='marginRigth formMarginTop'>
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                    <Button type="default" htmlType="reset">
                        {resources.buttons.reset}
                    </Button>
                </Right>
            </Form >
        );
    };
}

const SubdistrictDictionaryForm = withFormik<ISubdistrict & IUpdateObjInArray & IAddObjToArray & IId, ISubdistrict>({
    mapPropsToValues: (props: ISubdistrict) => {
        return {
            name: props.name,
            name_eng: props.name_eng,
            country_id: props.country_id
        };
    },

    validate: (values: ISubdistrict) => {
        const errors: FormikErrors<ISubdistrict> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (values.name_eng && values.name_eng.length > 255) {
            errors.name_eng = resources.validation.requiredMax255Characters;
        }
        if (!values.name_eng) {
            errors.name_eng = resources.validation.required;
        }
        if (!values.country_id) {
            errors.country_id = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: ISubdistrict, bag: FormikBag<ISubdistrict & IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId, ISubdistrict>) => {
        const object: ISubdistrict = {
            name: values.name,
            name_eng: values.name_eng,
            country_id: values.country_id,
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.name === bag.props.name
            && values.name_eng === bag.props.name_eng
            && values.country_id === bag.props.country_id
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(TemplateSubdistrictDictionaryForm);

export default SubdistrictDictionaryForm;

const createQuery = async (obj: ISubdistrict, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId) => {
    const createQuery =
        `INSERT INTO li_subdistricts
            (
                name,
                name_eng,
                country_id
            )
        VALUES
            (
                "${obj.name}",
                "${obj.name_eng}",
                ${obj.country_id}
            )`;

    const updateQuery =
        `UPDATE li_subdistricts SET
                name = "${obj.name}",
                name_eng = "${obj.name_eng}",
                country_id = ${obj.country_id}
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateSubdistrictsDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.country_id = Number(obj.country_id);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);
                openSuccessNotification(resources.statement.subdistrict_updated);
            });
    }
    else {
        await createSubdistrictsDictionary(createQuery)
            .then((response) => {
                obj.id = response;
                obj.key = response;
                obj.created_at = new Date();
                obj.updated_at = new Date();
                obj.deleted_at = null;

                bag.addObjToArray!(obj);
                openSuccessNotification(resources.statement.subdistrict_created);
            });
    }

    bag.hideModal!();
}