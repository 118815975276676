import * as React from "react";
import logo from "../../../../common/images/full-logo-light.svg";
import styles from "./styles/LandingFooter.module.scss";
import { resources } from "../../../../common/Resources";

export class LandingFooter extends React.Component {
  render() {
    return (
      <footer className={styles.footer}>
        <div className={`${styles.widthLimit1200} ${styles.footer__grid}`}>
          <div className={styles.footer__grid__left}>
            <img width="120" className={styles.logo} src={logo} alt="logo" />
            <p className={`h6__semibold`}>
              &copy;&nbsp;{new Date().getFullYear()}
              {resources.landingPage.affbayAsia}
            </p>
            <p className={`h6__semibold`}>{resources.landingPage.ownedBy}</p>
          </div>
          <div className={styles.footer__grid__right}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={`h6__semibold`}
              href="https://drive.google.com/file/d/1N2iv6PVjKl_kcrgPWrQkoQDI5NZQujqp/view?usp=sharing"
            >
              {resources.landingPage.privacyPolicy}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={`h6__semibold`}
              href="https://drive.google.com/file/d/16KHkPbDc8FdysBsxAvHEtMBcq-SNXq0i/view?usp=sharing"
            >
              {resources.landingPage.termsAndConditions}
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
