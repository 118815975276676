import React, { Component } from "react";
import { AffbayVisibilityContent } from "../components/affbayVisibility/contents/AffbayVisibilityContent";
import { CnversionsContent } from "../components/conversions/contents/ConversionsContent";
import { CustomTabNameWithSaveIcon } from "../../../../components/customTabName/CustomTabNameWithSaveIcon";
import {
  findInDictionaryAndReturnValue,
  getDictionary,
} from "../../../../helpers/DisctionariesHelper";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import { GoBackButton } from "../../../../components/goBackButton/GoBackButton";
import { Icon, Result, Spin, Tabs } from "antd";
import { IId } from "../../../../common/interfaces/IId";
import { IProduct } from "./interfaces/IProduct";
import { MainInformationsContent } from "../components/mainInformations/contents/MainInformationsContent";
import { ProductPagesContent } from "../components/pages/contents/ProductPagesContent";
import { ProductPricingsContent } from "../components/pricing/contents/ProductPricingsContent";
import { resources } from "../../../../common/Resources";
import { Title } from "../../../../components/header/Title";

const { TabPane } = Tabs;
const customSpiner = (
  <Icon type="loading" style={{ fontSize: 55, marginTop: 80 }} spin />
);

export class ProductDetailsContent extends Component<IId> {
  _isMounted = false;

  state = {
    loading: false,
    product_exists: "new",
    product: {} as IProduct,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this._isMounted = true;

    this.props.id && this.getProductDetails();
  };

  componentWillUnmount = () => (this._isMounted = false);

  render = () => {
    return (
      <Spin spinning={this.state.loading} indicator={customSpiner}>
        {!this.state.loading && this.generateTitle()}
        {this.renderContent()}
      </Spin>
    );
  };

  renderEditFoem = () => {
    return (
      <Tabs
        defaultActiveKey="1"
        animated={false}
        tabPosition="top"
        style={{ textAlign: "center" }}
      >
        <TabPane
          key="1"
          tab={
            <CustomTabNameWithSaveIcon title="General info" showIcon={false} />
          }
        >
          <MainInformationsContent
            {...this.state.product}
            changeAffayVisibility={this.changeAffayVisibility}
          />
        </TabPane>
        <TabPane
          key="2"
          tab={
            <CustomTabNameWithSaveIcon
              title="Affbay visibility"
              showIcon={false}
            />
          }
        >
          <AffbayVisibilityContent {...this.state.product} />
        </TabPane>
        <TabPane
          key="3"
          tab={
            <CustomTabNameWithSaveIcon title="Conversion" showIcon={false} />
          }
        >
          <CnversionsContent id={this.props.id} />
        </TabPane>
        <TabPane
          key="4"
          tab={<CustomTabNameWithSaveIcon title="Pages" showIcon={false} />}
        >
          <ProductPagesContent id={this.props.id} />
        </TabPane>
        <TabPane
          key="5"
          tab={<CustomTabNameWithSaveIcon title="Pricing" showIcon={false} />}
        >
          <ProductPricingsContent
            country_id={this.state.product.country_id}
            default_price_currency={this.state.product.default_price_currency}
            id={this.props.id}
          />
        </TabPane>
      </Tabs>
    );
  };

  changeAffayVisibility = () => {
    let product = this.state.product;
    product.is_visible_affbay = !product.is_available_sale;
    this.setState({ product });
  };

  renderCreateForm = () => {
    return (
      <MainInformationsContent
        {...this.state.product}
        setNewCreatedProductDetails={this.setNewCreatedProductDetails}
      />
    );
  };

  renderProductNotFound = () => {
    return (
      <Result
        status="403"
        title="Selected product doesn't exists"
        subTitle={`Sorry, but we couldn't find product ( ID=${this.props.id} ) in database.`}
        extra={
          <GoBackButton
            goTo={"products"}
            buttonClassName="marginBottom marginRigth"
          />
        }
      />
    );
  };

  generateTitle = () => {
    return (
      <header>
        {this.state.product_exists === "found" && (
          <Title
            title={`${this.state.product.name} ${findInDictionaryAndReturnValue(
              this.state.product.country_id!,
              getDictionary("currencyD")
            )}`}
          />
        )}
        {this.state.product_exists === "new" && (
          <Title title={resources.title.createNewProduct} />
        )}
      </header>
    );
  };

  renderContent = () => {
    switch (this.state.product_exists) {
      case "found":
        return this.renderEditFoem();
      case "not_found":
        return this.renderProductNotFound();
      default:
        return this.renderCreateForm();
    }
  };

  setNewCreatedProductDetails = (product: IProduct) => {
    this.setState({ product, product_exists: "found" });
  };

  getProductDetails = () => {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id", //
        "parent_product_id",
        "country_id", //
        "country_name",
        "product_sku", //
        "name", //
        "name_origin", //
        "name_print", //
        "name_short", //
        "categories", //
        "is_visible_affbay", //
        "is_visible_partners",
        "is_available_sale", //
        "is_active", //
        "is_restricted",
        "is_visible_home",
        "is_test_product", //
        "call_centers",
        "call_center_id", //
        "call_center_main", //
        "call_center_resell", //
        "call_center_upsell",
        "call_center_validation",
        "production_cost", //
        "production_cost_local", //
        "default_price_currency", //
        "default_price_currency_id", //
        "default_price", //
        "default_payout",
        "default_payout_schema",
        "default_landing_price",
        "approve_page",
        "payout_visible",
        "description_en", //
        "description_ru", //
        "description_short_ru",
        "description_short_en",
        "traffic_sources_en", //
        "traffic_sources_ru", //
        "created_at",
        "updated_at",
        "deleted_at",
        "id_old",
        "terms_en",
        "terms_ru",
        "conditions_en",
        "conditions_ru",
        "parent_sku",
        "parent_id",
      ],
      id: Number(this.props.id),
    };

    getProducts(object)
      .then((response) => {
        const product_exists = response.length ? "found" : "not_found";

        this._isMounted &&
          this.setState({
            product: response[0],
            product_exists,
          });
      })
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };
}
