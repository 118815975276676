import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const citiesQuery = 'SELECT id, id as "key", name, country_id, name_eng, deleted_at, created_at, updated_at FROM li_cities';
const citiesListQuery = 'SELECT id, name, country_id FROM li_cities';

export const getCitiesDictionaryList = () => axios.post(sourceUrl, { data: { sql: citiesQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getCitiesDictionaryList');
        return [];
    });

export const getCitiesListD = () => axios.post(sourceUrl, { data: { sql: citiesListQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getCitiesList');
        return [];
    });

export const updateCitiesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateCitiesDictionary');
        return false;
    });

export const createCitiesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createCitiesDictionary');
        return false;
    });

export const deleteCitiesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createCitiesDictionary');
        return false;
    });