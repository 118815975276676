import * as React from 'react';
import LoginForm from '../forms/login/LoginForm';
import { AuthenticationContainer } from '../../../components/containers/authenticationContainer/AuthenticationContainer';
import { LogRegNav } from '../../../components/logRegNav/LogRegNav';


export class LoginContent extends React.Component {
    public render() {
        return (
            <AuthenticationContainer>
                <LogRegNav activeTab='login'/>
                <LoginForm />
            </AuthenticationContainer>
        )
    };
}