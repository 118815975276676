import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/Location.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createLocationsDictionary, updateLocationsDictionary } from '../../../../services/dictionaries/LocationsDivtionaryService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IDefaultProps } from '../interfaces/IDefaultProps';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { ILocations } from '../interfaces/ILocations';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';

class TemplateLocationDictionaryForm extends React.Component<ILocations & IDefaultProps & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<ILocations>> {
    public static defaultProps = {
        labelTextAlignLeft: true,
        boldLabel: true,
        allowClear: true
    }

    public render() {
        return (
            <Form className='formStyle'>
                <section>
                    <div className={styles.grid_col_4}>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='country_id'
                                label={resources.labels.country}
                                dictionaryValues={this.props.countriesD}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='city_id'
                                label={resources.labels.city}
                                dictionaryValues={this.props.citiesD}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='district_id'
                                label={resources.labels.district}
                                dictionaryValues={this.props.districtsD}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='subdistrict_id'
                                label={resources.labels.subdistrict}
                                dictionaryValues={this.props.subdistrictsD}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name="island"
                                label={resources.labels.island}
                                dictionaryValues={getBooleanValues()}
                                labelTextAlignLeft
                                allowClear={false}
                                boldLabel
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='courier_id'
                                label={resources.labels.courier}
                                dictionaryValues={this.props.couriersD}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='post_code_id'
                                label={resources.labels.post_code}
                                dictionaryValues={this.props.postCodesD}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='destination_code'
                                label={resources.labels.destination_code}
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='leadtime'
                                label={resources.labels.lead_time}
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='leadtime_text'
                                label={resources.labels.lead_time}
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='province_id'
                                label={resources.labels.province}
                                dictionaryValues={this.props.provincesD}
                                labelTextAlignLeft
                                allowClear={false}
                                boldLabel
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name="bank_transfer"
                                label={resources.labels.bank_transfer}
                                dictionaryValues={getBooleanValues()}
                                labelTextAlignLeft
                                allowClear={false}
                                boldLabel
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='delivery_cost'
                                label={resources.labels.delivery_cost}
                                isNumeric
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='return_cost'
                                label={resources.labels.return_cost}
                                isNumeric
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='insurance_fee'
                                label={resources.labels.instance_fee}
                                isNumeric
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='cod_fee'
                                label={resources.labels.cod_fee}
                                isNumeric
                            />
                        </div>
                    </div>
                    <div className={styles.grid_col_4}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name='admin_fee'
                                label={resources.labels.admin_fee}
                                isNumeric
                            />
                        </div>
                        <div />
                        <div />
                        <div />
                    </div>
                </section>
                <Right>
                    <Button type='primary' htmlType='submit' className='marginRigth formMarginTop'>
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                    <Button type='default' htmlType='reset'>
                        {resources.buttons.reset}
                    </Button>
                </Right>
            </Form >
        );
    };
}

const LocationDictionaryForm = withFormik<ILocations & IUpdateObjInArray & IAddObjToArray & IId & IDefaultProps, ILocations>({
    mapPropsToValues: (props: ILocations) => {
        return {
            country_id: props.country_id,
            city_id: props.city_id,
            province_id: props.province_id,
            district_id: props.district_id,
            subdistrict_id: props.subdistrict_id,
            post_code_id: props.post_code_id,
            courier_id: props.courier_id,
            bank_transfer: Boolean(props.bank_transfer) ? '1' : '0',
            island: Boolean(props.island) ? '1' : '0',
            deleted_at: props.deleted_at,
            created_at: props.created_at,
            updated_at: props.updated_at,
            leadtime: props.leadtime,
            destination_code: props.destination_code,
            leadtime_text: props.leadtime_text,
            delivery_cost: props.delivery_cost,
            return_cost: props.return_cost,
            insurance_fee: props.insurance_fee,
            cod_fee: props.cod_fee,
            admin_fee: props.admin_fee
        };
    },

    validate: (values: ILocations) => {
        const errors: FormikErrors<ILocations> = {};

        if (!values.country_id) {
            errors.country_id = resources.validation.required;
        }
        if (!values.city_id) {
            errors.city_id = resources.validation.required;
        }
        if (!values.province_id) {
            errors.province_id = resources.validation.required;
        }
        if (!values.district_id) {
            errors.district_id = resources.validation.required;
        }
        if (!values.subdistrict_id) {
            errors.subdistrict_id = resources.validation.required;
        }
        if (!values.post_code_id) {
            errors.post_code_id = resources.validation.required;
        }
        if (!values.island) {
            errors.island = resources.validation.required;
        }
        if (!values.courier_id) {
            errors.courier_id = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: ILocations, bag: FormikBag<ILocations & IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId, ILocations>) => {
        const object: ILocations = {
            country_id: values.country_id,
            city_id: values.city_id,
            province_id: values.province_id,
            district_id: values.district_id,
            subdistrict_id: values.subdistrict_id,
            post_code_id: values.post_code_id,
            courier_id: values.courier_id,
            bank_transfer: values.bank_transfer,
            deleted_at: values.deleted_at,
            created_at: values.created_at,
            updated_at: values.updated_at,
            leadtime: values.leadtime,
            destination_code: values.destination_code,
            island: values.island,
            leadtime_text: values.leadtime_text,
            delivery_cost: values.delivery_cost,
            return_cost: values.return_cost,
            insurance_fee: values.insurance_fee,
            cod_fee: values.cod_fee,
            admin_fee: values.admin_fee
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.country_id === bag.props.country_id
            && values.city_id === bag.props.city_id
            && values.province_id === bag.props.province_id
            && values.district_id === bag.props.district_id
            && values.subdistrict_id === bag.props.subdistrict_id
            && values.post_code_id === bag.props.post_code_id
            && values.courier_id === bag.props.courier_id
            && values.bank_transfer === bag.props.bank_transfer
            && values.leadtime === bag.props.leadtime
            && values.destination_code === bag.props.destination_code
            && values.island === bag.props.island
            && values.leadtime_text === bag.props.leadtime_text
            && values.delivery_cost === bag.props.delivery_cost
            && values.return_cost === bag.props.return_cost
            && values.insurance_fee === bag.props.insurance_fee
            && values.cod_fee === bag.props.cod_fee
            && values.admin_fee === bag.props.admin_fee
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(TemplateLocationDictionaryForm);

export default LocationDictionaryForm;

const createQuery = async (obj: ILocations, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId) => {
    const createQuery =
        `INSERT INTO li_locations
            (
                country_id,
                city_id,
                province_id,
                district_id,
                subdistrict_id,
                post_code_id,
                courier_id,
                bank_transfer,
                lead_time,
                destination_code,
                island,
                lead_time_text,
                delivery_cost,
                return_cost,
                insurance_fee,
                cod_fee,
                admin_fee
            )
        VALUES
            (
                ${obj.country_id},
                ${obj.city_id},
                ${obj.province_id},
                ${obj.district_id},
                ${obj.subdistrict_id},
                ${obj.post_code_id},
                ${obj.courier_id},
                "${obj.bank_transfer}",
                "${obj.leadtime}",
                "${obj.destination_code}",
                "${obj.island}",
                "${obj.leadtime_text}",
                "${obj.delivery_cost}",
                "${obj.return_cost}",
                "${obj.insurance_fee}",
                "${obj.cod_fee}",
                "${obj.admin_fee}"
            )`;

    const updateQuery =
        `UPDATE li_locations SET
                country_id = ${obj.country_id},
                city_id = ${obj.city_id},
                province_id = ${obj.province_id},
                district_id = ${obj.district_id},
                subdistrict_id = ${obj.subdistrict_id},
                post_code_id = ${obj.post_code_id},
                courier_id = ${obj.courier_id},
                bank_transfer = "${obj.bank_transfer}",
                lead_time = "${obj.leadtime}",
                destination_code = "${obj.destination_code}",
                island = "${obj.island}",
                lead_time_text = "${obj.leadtime_text}",
                delivery_cost = "${obj.delivery_cost}",
                return_cost = "${obj.return_cost}",
                insurance_fee = "${obj.insurance_fee}",
                cod_fee = "${obj.cod_fee}",
                admin_fee = "${obj.admin_fee}"
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateLocationsDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.country_id = Number(obj.country_id);
                obj.city_id = Number(obj.city_id);
                obj.province_id = Number(obj.province_id);
                obj.district_id = Number(obj.district_id);
                obj.subdistrict_id = Number(obj.subdistrict_id);
                obj.island = Number(obj.island);
                obj.courier_id = Number(obj.courier_id);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);
                openSuccessNotification(resources.statement.location_updated);
            });
    }
    else {
        await createLocationsDictionary(createQuery)
            .then((response) => {
                obj.id = response;
                obj.key = response;
                obj.created_at = new Date();
                obj.updated_at = new Date();
                obj.deleted_at = null;
                obj.id = Number(bag.id!);
                obj.country_id = Number(obj.country_id);
                obj.city_id = Number(obj.city_id);
                obj.province_id = Number(obj.province_id);
                obj.district_id = Number(obj.district_id);
                obj.subdistrict_id = Number(obj.subdistrict_id);
                obj.island = Number(obj.island);
                obj.courier_id = Number(obj.courier_id);

                bag.addObjToArray!(obj);
                openSuccessNotification(resources.statement.location_created);
            });
    }

    bag.hideModal!();
}