import React, { Component } from 'react';
import styles from './styles/PartnerViewCheckbox.module.scss';
import tickSrc from '../../common/images/tick.svg';
import { IPartnerViewCheckbox } from './interfaces/IPartnerViewCheckbox';
import { IPartnerViewCheckboxMethods } from './interfaces/IPartnerViewChecboxMethods';

export class PartnerViewCheckbox extends Component<IPartnerViewCheckbox & IPartnerViewCheckboxMethods> {
    render() {
        return (
            <label className={styles.customCheckbox}>
                <input type="checkbox" checked={this.props.checked} name={this.props.name} value={this.props.value} onChange={this.props.onChange} />
                <span className={styles.overlay}>
                    <img className={styles.icon} src={tickSrc} alt="tick" />
                </span>
            </label>
        )
    }
}