import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/SalesChannelDictionaryForm.module.scss';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createCouriersApiMappingDictionary, updateCouriersApiMappingDictionary } from '../../../../services/dictionaries/CouriersApiMappingDictionaryServic';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { ICouriersApiMapping } from '../interfaces/ICouriersApiMapping';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class InnerCouriersApiMappingDictionaryForm extends React.Component<ICouriersApiMapping & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<ICouriersApiMapping>> {
    public render() {
        return (
            <Form>
                <section>
                    {this.props.courier_name &&
                        <>
                            <div className={styles.companieName}>
                                {this.props.courier_name}
                            </div>
                            <div className={styles.sectionDivider} />
                        </>
                    }
                    <div className={styles.grid_col_3}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="courier_name"
                                label={resources.labels.courier}
                                labelTextAlignLeft
                                allowClear
                                boldLabel
                            />
                        </div>
                        <div>
                            <DictionaryF
                                {...this.props}
                                name='country_id'
                                label={resources.labels.country}
                                dictionaryValues={getDictionary('countriesNameD')}
                                allowClear={false}
                                labelTextAlignLeft
                                boldLabel
                                required
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="user_name"
                                label={resources.labels.user}
                                labelTextAlignLeft
                                allowClear
                                boldLabel
                            />
                        </div>
                    </div>
                    <TextAreaF
                        {...this.props}
                        textAreaRows={4}
                        name="notes"
                        label={resources.labels.notes}
                        allowClear
                        labelTextAlignLeft
                        boldLabel
                    />
                    <div className={styles.sectionDivider} />
                    <div className={styles.grid_col_2}>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="status"
                                label={resources.labels.status}
                                labelTextAlignLeft
                                allowClear
                                boldLabel
                            />
                        </div>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="courier_status"
                                label={resources.labels.courier_status}
                                labelTextAlignLeft
                                allowClear
                                boldLabel
                            />
                        </div>
                    </div>
                    <TextAreaF
                        {...this.props}
                        textAreaRows={4}
                        name="status_description"
                        label={resources.labels.status_description}
                        allowClear
                        labelTextAlignLeft
                        boldLabel
                    />
                    <Button
                        className='saveButton'
                        htmlType="submit"
                        type="primary"
                    >
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                </section>
            </Form >
        );
    };
}

const CouriersApiMappingDictionaryForm = withFormik<ICouriersApiMapping & IUpdateObjInArray & IAddObjToArray & IId, ICouriersApiMapping>({
    mapPropsToValues: (props: ICouriersApiMapping) => {
        return {
            courier_name: props.courier_name,
            country_id: props.country_id,
            status: props.status,
            courier_status: props.courier_status,
            status_description: props.status_description,
            notes: props.notes,
            user_name: props.user_name
        };
    },

    validate: (values: ICouriersApiMapping) => {
        const errors: FormikErrors<ICouriersApiMapping> = {};

        if (!values.country_id) {
            errors.country_id = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: ICouriersApiMapping, bag: FormikBag<ICouriersApiMapping & IUpdateObjInArray & IAddObjToArray & IHideModal & IId, ICouriersApiMapping>) => {
        const object: ICouriersApiMapping = {
            id: values.id,
            country_id: values.country_id,
            courier_name: values.courier_name,
            status: values.status,
            courier_status: values.courier_status,
            status_description: values.status_description,
            notes: values.notes,
            user_name: values.user_name,
        }

        if (
            bag.props.id
            && values.country_id === bag.props.country_id
            && values.courier_name === bag.props.courier_name
            && values.status === bag.props.status
            && values.courier_status === bag.props.courier_status
            && values.status_description === bag.props.status_description
            && values.notes === bag.props.notes
            && values.user_name === bag.props.user_name

        )
            openAlertNotification(resources.statement.thePropsAreTheSame);

        else
            createQuery(object, bag.props);
    }

})(InnerCouriersApiMappingDictionaryForm);

export default CouriersApiMappingDictionaryForm;

const createQuery = async (obj: ICouriersApiMapping, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal) => {
    const createQuery =
        `INSERT INTO li_couriers_api_mapping
            (
                country_id,
                courier_name,
                status,
                courier_status,
                status_description,
                notes,
                user_name
            )
        VALUES
            (
                ${obj.country_id},
                "${obj.courier_name}",
                "${obj.status}",
                "${obj.courier_status}",
                "${obj.status_description}",
                "${obj.notes}",
                "${obj.user_name}"
            )`;

    const updateQuery =
        `UPDATE li_couriers_api_mapping SET
                country_id = ${obj.country_id},
                courier_name = "${obj.courier_name}",
                status = "${obj.status}",
                courier_status = "${obj.courier_status}",
                status_description = "${obj.status_description}",
                notes = "${obj.notes}",
                user_name = "${obj.user_name}"
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateCouriersApiMappingDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);

                openSuccessNotification(resources.statement.couriers_api_mapping_updated);
            });
    }
    else {
        await createCouriersApiMappingDictionary(createQuery)
            .then(response => {
                if (response) {
                    obj.id = response;
                    obj.key = response;
                    obj.created_at = new Date();
                    obj.updated_at = new Date();

                    bag.addObjToArray!(obj);

                    openSuccessNotification(resources.statement.couriers_api_mapping_created);
                }
                else
                    openErrorNotification(resources.statement.databaseConnectionError);

            });
    }

    bag.hideModal!();
}