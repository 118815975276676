import React, { Component } from 'react';
import styles from './styles/OrdersList.module.scss';
import { Checkbox, List } from 'antd';
import { IOrder } from '../../interfaces/IOrder';
import { IProps } from './interfaces/IProps';
import { OrderInformations } from '../components/orderInformations/OrderInformations';


export class OrdersList extends Component<IProps> {

    render() {

        return (
            <>
                {this.props.ordersListTotalCount > 0 ? <label className='marginLeft'>Found {this.props.ordersListTotalCount} items</label> : null}
                <div className={styles.header}>
                    <div className={styles.label}>
                        <Checkbox
                            onChange={() => this.props.onAllOrdersSelected()}
                            checked={this.props.selectedOrders.size === this.props.ordersList.length
                                && this.props.selectedOrders.size !== 0}
                        />
                    </div>
                    <div className={styles.label}>QR-code status</div>
                    <div className={styles.label}>Orders #</div>
                    <div className={styles.label}>Details</div>
                    <div className={styles.label}>Products</div>
                    <div className={styles.label}>Total Ammount (IDR)</div>
                    <div className={styles.label}>Actions</div>
                </div>
                <List
                    dataSource={this.props.ordersList}
                    loading={this.props.loading}
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.ordersListTotalCount,
                        onChange: this.props.onPageChange,
                    }}
                    renderItem={
                        (item: IOrder) => (
                            <List.Item className={styles.listItem}>
                                <OrderInformations
                                    order={item}
                                    loading={this.props.loading}
                                    productsD={this.props.productsD}
                                    selected={this.props.selectedOrders.has(item.id)}
                                    onOrderSelected={this.props.onOrderSelected}
                                    statusesMap={this.props.statusesMap}
                                    onStatusChange={this.props.onStatusChange}
                                />
                            </List.Item >
                        )
                    }
                />
            </>
        );
    }
}