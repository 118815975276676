import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const countriesQuery = 'SELECT id, id as "key", code, name, is_operative, company_name, address, email, phone, currency, updated_at, is_active FROM li_countries';

export const getCountriesDictionaryList = () => axios.post(sourceUrl, { data: { sql: countriesQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getCountriesDictionaryList');
        return [];
    });

export const updateCountriesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateCountriesDictionary');
        return false;
    });

export const createCountriesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createCountriesDictionary');
        return false;
    });

export const deleteCountriesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createCountriesDictionary');
        return false;
    });