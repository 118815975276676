import logo from "../../common/images/full-logo.svg";
import React from "react";

export const LogoAsLinkToLandingPage = () => {
  return (
    <a href={"/"}>
      {" "}
      <img width="130" src={logo} alt="affbay" />
    </a>
  );
};
