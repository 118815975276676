import * as React from 'react';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getListFromLibrary } from '../../../../services/m40_libraries/LibrariesService';
import { getPermissionsForRole, getPermissionsInf, updateRolePermission } from '../../../../services/m29_users_management/PermissionsService';
import { GoBackButton } from '../../../../components/goBackButton/GoBackButton';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IRolePermissions } from '../table/interfaces/IRolePermissions';
import { IRolePermissionsInfo } from '../interfaces/IRolePermissionsInfo';
import { IRouteProps } from '../interfaces/IRouteProps';
import { Left } from '../../../../components/controls/left/Left';
import { openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageTitle } from '../../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { RolePermissionsTable } from '../table/RolePermissionsTable';
import { SearchElements } from '../../../../components/common/searchElements/SearchElements';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';

export class RolePermissionContent extends React.Component<IRouteProps> {
    _isMounted = false;

    state = {
        loading: false,
        selected_view: undefined,
        selected_type: undefined,
        search: undefined,
        basePermissionsList: Array<IRolePermissions>(),
        permissionsList: Array<IRolePermissions>(),
        permissionsInf: Array<IRolePermissionsInfo>(),
        typesD: Array<IDictionaryValue>(),
        viewsD: Array<IDictionaryValue>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getRolePermissionsList();
        this.getPermissionsInf();
        this.getViewsD();
        this.getTypesD();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    public render() {
        const { permissionsList, permissionsInf, loading, selected_view, selected_type, search, typesD, viewsD } = this.state;

        let filteredData = permissionsList;

        if (selected_view)
            filteredData = filteredData.filter(x => x.id_permission_view === selected_view);
        if (selected_type)
            filteredData = filteredData.filter(x => x.id_permission_type === selected_type);
        if (search) {
            const permissionsInf: IDictionaryValue[] = this.state.permissionsInf.filter(x =>
                (x.name ? x.name.toLowerCase() : "").includes(search!)
                || (x.description ? x.description.toLowerCase() : "").includes(search!)
            );

            let list: IRolePermissions[] = [];

            permissionsInf.map(item => {
                let object = filteredData.filter(x => x.id_permission === item.id);

                if (object.length > 0)
                    list = [...list, object[0]];

                return null;
            });

            filteredData = list;
        }

        const { length: count } = filteredData ? filteredData : [];

        return (
            <TableContainer>
                <PageTitle>Administration - {this.props.name} permissions</PageTitle>
                <Left>
                    <RefreshTableButton
                        className='marginRigth marginBottom'
                        loading={loading}
                        onClick={this.getRolePermissionsList}
                    />
                </Left>
                <Left>
                    <GoBackButton
                        goTo={'administration/role'}
                        buttonClassName='marginBottom marginRigth'
                    />
                </Left>
                <Left>
                    <SelectOnChange
                        className="marginBottom marginRigth"
                        onChange={this.onChangeSelect}
                        name='selected_view'
                        value={selected_view}
                        items={viewsD}
                        loading={loading}
                        placeholder='Select View'
                        width={200}
                        allowClear
                    />
                </Left>
                <SelectOnChange
                    className="marginBottom"
                    onChange={this.onChangeSelect}
                    name='selected_type'
                    value={selected_type}
                    items={typesD}
                    loading={loading}
                    placeholder='Select Type'
                    width={200}
                    allowClear
                />
                <Right>
                    <SearchElements
                        className='marginRigth'
                        onSearch={this.onGlobalSearch}
                        loading={loading}
                    />
                </Right>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <RolePermissionsTable
                    data={filteredData}
                    loading={loading}
                    permissionsInf={permissionsInf}
                    changeStatus={this.changePermissionStatus}
                    typesD={typesD}
                    viewsD={viewsD}
                />
            </TableContainer>
        )
    };

    getRolePermissionsList = async () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                "id",
                "id_permission",
                "id_permission_view",
                "id_permission_type",
                "updated_at",
                "is_active"
            ]
        };

        await getPermissionsForRole(Number(this.props.id), object)
            .then((result) => {
                this._isMounted && this.setState({
                    permissionsList: result,
                    basePermissionsList: result
                });
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    getPermissionsInf = async () => {
        const object = {
            columns: [
                "id",
                "name",
                "description"
            ]
        };

        await getPermissionsInf(object)
            .then((result) => {
                this._isMounted && this.setState({
                    permissionsInf: result
                });
            });
    }

    changePermissionStatus = async (id: number, is_active: boolean) => {
        const object = {
            is_active: !is_active,
            id_users_role: Number(this.props.id)
        };

        await updateRolePermission(id, object)
            .then(response => { response && this.changeStatusInArray(id); })
    }

    changeStatusInArray = (id: number) => {
        const { permissionsList } = this.state;
        const index = permissionsList.findIndex(x => x.id === id);

        permissionsList[index].is_active = !permissionsList[index].is_active;
        permissionsList[index].updated_at = new Date();

        this.setState({ permissionsList });

        openSuccessNotification(resources.statement.status_updated);
    }

    onChangeSelect = (name: string, value: number): void => {
        this.setState({ [name]: value });
    }

    onGlobalSearch = (event: any) => {
        this.setState({ [event.target.name]: event.target.value.toLocaleLowerCase() });
    }

    getViewsD = () => {
        const data = {
            name: 'li_permissions_views',
            column: 'id, name'
        };

        getListFromLibrary(data)
            .then(viewsD => this.setState({ viewsD }));
    }

    getTypesD = () => {
        const data = {
            name: 'li_permissions_types',
            column: 'id, name'
        };

        getListFromLibrary(data)
            .then(typesD => this.setState({ typesD }));
    }
}