import React, { useState } from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Divider, Input, Spin } from "antd";

import { FilterCheckbox } from "../filter-checkbox/filter-checkbox";
import { useProductEntity } from "../../../lib/hooks";
import { createFilterData, FILTERS } from "../../../lib/filters-data";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

const searchAsync = (text: string) => Promise.resolve(text);
const searchDebounced = AwesomeDebouncePromise(searchAsync, 300);

export const ProductFilter = () => {
  const [search, setSearch] = useState("");
  const { state, dispatch } = useFilters();
  const { products, isLoading } = useProductEntity();

  const onChangeSearch = async (value: string) => {
    const search = await searchDebounced(value);
    setSearch(search);
  };

  const onChangeProduct = (isChecked: boolean, product: any) => {
    const updatedProductIds = isChecked
      ? [
        ...state.productId,
        createFilterData(product.name, product.id, FILTERS.PRODUCT),
      ]
      : state.productId.filter((p) => p.value !== product.id);
    dispatch({
      type: UPDATE_FILTERS,
      payload: { productId: updatedProductIds },
    });
  };

  const onChangeSelectAll = (isChecked: boolean) =>
    dispatch({
      type: UPDATE_FILTERS,
      payload: {
        productId: isChecked
          ? products.map((product: any) =>
            createFilterData(product.name, product.id, FILTERS.PRODUCT)
          )
          : [],
      },
    });

  return (
    <div>
      {isLoading ? (
        <Spin
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(calc(-50% + 40px), -50%)",
          }}
        />
      ) : (
        <>
          <Input
            type="text"
            style={{ marginBottom: "12px" }}
            onChange={(event) => onChangeSearch(event.target.value)}
            placeholder="Search"
          />

          <FilterCheckbox
            label="Select all"
            checked={products.length === state.productId.length}
            onChange={(event) => onChangeSelectAll(event.target.checked)}
          />

          <Divider style={{ marginTop: "5px", marginBottom: "12px" }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "350px",
              overflowY: "scroll",
            }}
          >
            {products
              // Filter by countryId
              .filter(
                (product: any) =>
                  product.country_id === state.countryId?.value ||
                  !state.countryId
              )
              // Filter by search input
              .filter((product: any) =>
                product.name.toLowerCase().includes(search.toLowerCase())
              )
              .sort((a: any, b: any) =>
                (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
              )
              .map((product: any) => (
                <FilterCheckbox
                  key={product.id}
                  label={product.name}
                  checked={
                    !!state.productId.find((p) => p.value === product.id)
                  }
                  onChange={(event) =>
                    onChangeProduct(event.target.checked, product)
                  }
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
