import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../../helpers/HttpStatusCodeHelper';
import { IProductPixelCreate } from './interfaces/IProductPixelCreate';
import { IProductPixelsFilters } from './interfaces/IProductPixelsFilters';
import { IProductPixelsUpdate } from './interfaces/IProductPixelsUpdate';
import { resourceDef } from '../../../common/ResourceDef';

const productPixelsUrl = createGatewayAbsoluteUrl(resourceDef.Pixels);

export const createProductPixels = (product_id: number, obj: IProductPixelCreate) => axios.put(productPixelsUrl + `${product_id}/pixels`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'createProductPixels M30');
        return [];
    });

export const getProductPixels = (product_id: number, obj?: IProductPixelsFilters) => axios.post(productPixelsUrl + `${product_id}/pixels`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getProductPixels M30');
        return [];
    });

export const updateProductPixels = (product_id: number, pixel_id: number, obj: IProductPixelsUpdate) => axios.patch(productPixelsUrl + `${product_id}/pixels/${pixel_id}`, { ...obj })
    .then(response => { return true })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updateProductPixels M30');
        return false;
    });

export const deleteProductPixels = (product_id: number, pixel_id: number) => axios.delete(productPixelsUrl + `${product_id}/pixels/${pixel_id}`)
    .then(response => { return true; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'deleteProductPixels M30');
        return false;
    });