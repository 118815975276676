import CreateNewOrderContext from '../../../../contexts/createNewOrderContext';
import React, { Component } from 'react';
import styles from './StepOne.module.scss';
import { ButtonCC } from '../../../../components/cc_employee/buttonCC/ButtonCC';
import { LeadsScrollingList } from '../leadsScrollingList/LeadsScrollingList';

export class StepOne extends Component {
    render() {
        return (
            <CreateNewOrderContext.Consumer>
                {
                    value => {
                        return <div className={styles.container}>
                            <label
                                className={`h4__bold ${styles.label}`}
                                htmlFor="fcc_id"
                            >
                                FCC ID and Phone number
                            </label>
                            <input
                                type="text"
                                name="fcc_id"
                                id="fcc_id"
                                placeholder="Focus Record ID"
                                className={styles.input}
                                value={value.fcc_id ? value.fcc_id : ''}
                                onChange={value.handleFccIdInput}
                            />
                            <input
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                placeholder="Phone Number"
                                className={styles.input}
                                value={value.phone ? value.phone : ''}
                                onChange={value.handlePhoneNumberInput}
                            />
                            <LeadsScrollingList />
                            <div className={`${styles.buttons}`}>
                                <ButtonCC
                                    type="green"
                                    width="140"
                                    disabled={value.selectedLeadId === undefined}
                                    onClick={() => {
                                        value.nextStep(2);
                                        value.resetState('selectedProductId', value.findProductWithLead(value.selectedLeadId!) ? value.findProductWithLead(value.selectedLeadId!).id : undefined);
                                        value.resetState('selectedPricingsOutsideBasket', []);
                                        value.resetState('basket', []);
                                        return
                                    }}
                                >
                                    Use this lead
                                </ButtonCC>
                                <p className={`h3 ${styles.p}`}>or</p>
                                <ButtonCC
                                    type="white"
                                    width="140"
                                    onClick={() => {
                                        value.nextStep(2);
                                        value.resetState('selectedProductId', undefined);
                                        value.resetState('selectedPricingsOutsideBasket', []);
                                        value.resetState('basket', []);
                                        value.resetState('fcc_id', undefined);
                                        value.resetState('selectedLeadId', undefined);
                                    }}
                                >
                                    No lead
                                </ButtonCC>
                            </div>
                        </div >
                    }
                }

            </CreateNewOrderContext.Consumer >
        )
    }
}

