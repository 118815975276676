import * as React from 'react';
import CitiesDictionaryForm from '../forms/SubdistrictDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { deleteSubdistrictsDictionary, getSubdistrictsDictionaryList } from '../../../../services/dictionaries/SubDistrictsDivtionaryService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { ISubdistrict } from '../interfaces/ISubdistrict';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { SubdistrictsDictionaryTable } from '../table/SubdistrictsDictionaryTable';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class SubdistrictsDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        selected_country: undefined,
        subdistrictsList: Array<ISubdistrict>(),
        countriesD: Array<IDictionaryValue>()
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getCountriesD();
        this.getSubdistrictsList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { subdistrictsList, loading, selected_country, countriesD } = this.state;

        let filteredData = subdistrictsList;

        if (selected_country)
            filteredData = filteredData.filter(x => x.country_id === selected_country);

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.subdistrictsDictionary} />
                <Left>
                    <RefreshTableButton
                        className='marginBottom marginTopRefreshButton marginRigth'
                        loading={loading}
                        onClick={this.getSubdistrictsList}
                    />
                </Left>
                <FieldInfo>{resources.labels.country}</FieldInfo>
                <SelectOnChange
                    name='selected_country'
                    labelClassName='marginLeft marginBottom'
                    onChange={this.onChangeSelect}
                    value={selected_country}
                    placeholder={resources.select.select}
                    items={getDictionary('countriesNameD')}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    allowClear
                />
                <ProtectedModal permission={UserPermissionType.AddNewSubdistrictModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewSubdistrict}
                            buttonTitle={resources.modalTitle.addNewSubdistrict}
                            buttonType='primary'
                            buttonTooltip={resources.modalTitle.addNewSubdistrict}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={loading}
                        >
                            <CitiesDictionaryForm
                                addObjToArray={this.addObjToArray}
                                countriesD={countriesD}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <SubdistrictsDictionaryTable
                    loading={loading}
                    data={filteredData}
                    countriesD={countriesD}
                    deleteSubdistrict={this.deleteSubdistrict}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getCountriesD = () => {
        const countriesD = localStorage.getItem('countriesNameD');

        if (countriesD) {
            this.setState({ countriesD: JSON.parse(countriesD) });
        }
    }

    getSubdistrictsList = () => {
        this.setState({ loading: true });

        getSubdistrictsDictionaryList()
            .then(subdistrictsList => { this._isMounted && this.setState({ subdistrictsList }); })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    deleteSubdistrict = async (event: any) => {
        const id = Number(event.target.value);
        const query = `DELETE FROM li_subdistricts WHERE id = ${id}`;

        await deleteSubdistrictsDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_subdistrict);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const subdistrictsList = this.state.subdistrictsList.filter(x => x.id !== id);

        this.setState({
            subdistrictsList,
            loading: false
        });

        openSuccessNotification(resources.statement.subdistrict_deleted);
    }

    addObjToArray = (subdistrict: ISubdistrict) => {
        this.setState({ subdistrictsList: [...this.state.subdistrictsList, subdistrict] });
    }

    updateObjInArray = (obj: ISubdistrict): void => {
        const { subdistrictsList } = this.state;
        const index = subdistrictsList.findIndex(x => x.id === obj.id);

        subdistrictsList[index].name = obj.name;
        subdistrictsList[index].name_eng = obj.name_eng;
        subdistrictsList[index].country_id = obj.country_id;
        subdistrictsList[index].created_at = obj.created_at;
        subdistrictsList[index].updated_at = obj.updated_at;

        this.setState({ subdistrictsList });
    }

    onChangeSelect = (name: string, value: number): void =>
        this.setState({ [name]: value });
}