import { IAppState, store } from "../store";
import {
  IAppUserInfoModel,
  UserPermissionType,
} from "../models/IAppUserInfoModel";
import { IAppUserInfoProps } from "./interfaces/IAppUserInfoModel";
import { IProtectedComponentProps } from "./interfaces/IProtectedComponentProps";
import { IProtectedProps } from "../components/authorizations/interfaces/IProtectedProps";

export const validatePermission = (
  permission: UserPermissionType,
  appUserInfo: IAppUserInfoModel | undefined
): boolean => {
  return appUserInfo &&
    appUserInfo.permissions &&
    appUserInfo.permissions.includes(permission)
    ? true
    : false;
};

export const mapStateToProps = (
  state: IAppState,
  ownProps: IProtectedComponentProps
): IProtectedProps => ({
  hasPermission: validateProtectedCondition(
    state.commonState.appUserInfo,
    ownProps
  ),
});

export const mapStateToAppUserInfoProps = (
  state: IAppState
): IAppUserInfoProps => {
  return {
    appUserInfo: state.commonState.appUserInfo,
  };
};

const validateProtectedCondition = (
  appUserInfo: IAppUserInfoModel | undefined,
  props: IProtectedComponentProps
): boolean => {
  if (!validatePermission(props.permission, appUserInfo)) {
    return false;
  }

  return true;
};

// check if user has permission
export const hasPermission = (permission: UserPermissionType) => {
  const state = store.getState();

  return (
    state &&
    state.commonState &&
    state.commonState.appUserInfo &&
    state.commonState.appUserInfo.permissions &&
    state.commonState.appUserInfo.permissions.includes(permission)
  );
};
