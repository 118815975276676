import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/SalesChannelDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createTrafficSourceDictionary, updateTrafficSourceDictionary } from '../../../../services/dictionaries/TrafficSourcesDictionaryService';

import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { ITrafficSources } from '../interfaces/ITrafficSource';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class InnerTrafficSourceDictionaryFormDictionaryForm extends React.Component<ITrafficSources & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<ITrafficSources>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                 <DictionaryF {...this.props}
                    name="lead_type"
                    label={resources.labels.lead_type}
                    dictionaryValues={this.props.leadsTypeD}
                    allowOfflineSearch={true}
                    labelTextAlignLeft
                    required
                />
                <TextInputF
                    {...this.props}
                    name="code_name"
                    label={resources.labels.codeName}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <TextInputF
                    {...this.props}
                    name="description"
                    label={resources.labels.description}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <DictionaryF
                    {...this.props}
                    name='exclude_from_double'
                    label={resources.labels.isExcluded}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                    labelTextAlignLeft
                    boldLabel
                />
                <DictionaryF
                    {...this.props}
                    name='is_active'
                    label={resources.labels.isActive}
                    dictionaryValues={getBooleanValues()}
                    allowClear={false}
                    labelTextAlignLeft
                    boldLabel
                />
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const TrafficSourceDictionaryForm = withFormik<ITrafficSources & IUpdateObjInArray & IAddObjToArray & IId, ITrafficSources>({
    mapPropsToValues: (props: ITrafficSources) => {
        return {
            id: props.id,
            name: props.name,
            lead_type: props.lead_type,
            code_name: props.code_name,
            description: props.description,
            exclude_from_double: Boolean(props.exclude_from_double) ? '1' : '0',
            is_active: Boolean(props.is_active) ? '1' : '0',
            
        };
    },

    validate: (values: ITrafficSources) => {
        const errors: FormikErrors<ITrafficSources> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 30) {
            errors.name = resources.validation.requiredMax30Characters;
        }
        if (!values.code_name) {
            errors.code_name = resources.validation.required;
        }
        else if (values.code_name.length > 30) {
            errors.code_name = resources.validation.requiredMax30Characters;
        }

        return errors;
    },

    handleSubmit: (values: ITrafficSources, bag: FormikBag<ITrafficSources & IUpdateObjInArray & IAddObjToArray & IHideModal & IId, ITrafficSources>) => {
        const object: ITrafficSources = {
            id: values.id,
            name: values.name,
            lead_type: values.lead_type,
            code_name: values.code_name,
            description: values.description,
            exclude_from_double: values.exclude_from_double,
            is_active: values.is_active,
        }

        if (
            bag.props.id
            && values.name === bag.props.name
            && values.lead_type === bag.props.lead_type
            && values.code_name === bag.props.code_name
            && values.exclude_from_double === bag.props.exclude_from_double
            && values.is_active === bag.props.is_active
            && values.description === bag.props.description
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(InnerTrafficSourceDictionaryFormDictionaryForm);

export default TrafficSourceDictionaryForm;

const createQuery = async (obj: ITrafficSources, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal) => {
    const createQuery =
        `INSERT INTO remarketing_campaigns
            (
                name,
                code_name,
                description,
                exclude_from_double,
                is_active,
                lead_type
            )
        VALUES
            (
                "${obj.name}",
                "${obj.code_name}",
                "${obj.description}",
                ${obj.exclude_from_double},
                ${obj.is_active},
                ${obj.lead_type}

            )`;

    const updateQuery =
        `UPDATE remarketing_campaigns SET
                name = "${obj.name}",
                code_name = "${obj.code_name}",
                description = "${obj.description}",
                exclude_from_double = ${obj.exclude_from_double},
                is_active = ${obj.is_active},
                lead_type = ${obj.lead_type}
                WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateTrafficSourceDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.exclude_from_double = Number(obj.exclude_from_double);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);

                openSuccessNotification(resources.statement.system_variable_updated);
            });
    }
    else {
        await createTrafficSourceDictionary(createQuery)
            .then(response => {
                if (response) {
                    obj.id = response;
                    obj.key = response;
                    obj.exclude_from_double = Number(obj.exclude_from_double);
                    obj.is_active = Number(obj.is_active);
                    obj.created_at = new Date();
                    obj.updated_at = new Date();

                    bag.addObjToArray!(obj);

                    openSuccessNotification(resources.statement.system_variable_created);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_system_variable);
                }
            });
    }

    bag.hideModal!();
}