import * as React from 'react';
import './styles/PageTitleStyles.css';

export class PageTitle extends React.Component {
    public render() {
        return (
            <div className="pageTitle" >
                {this.props.children}
            </div>
        )
    };
}