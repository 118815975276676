import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const postCodesQuery = 'SELECT id, id as "key", country_id, name, deleted_at, created_at, updated_at FROM li_post_codes';
const postCodesListQuery = 'SELECT id, name, country_id FROM li_post_codes';

export const getPostCodesDictionaryList = () => axios.post(sourceUrl, { data: { sql: postCodesQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getPostCodesDictionaryList');
        return [];
    });

export const getPostCodesListD = () => axios.post(sourceUrl, { data: { sql: postCodesListQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getPostCodesList');
        return [];
    });

export const updatePostCodesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updatePostCodesDictionary');
        return false;
    });

export const createPostCodesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createPostCodesDictionary');
        return false;
    });

export const deletePostCodesDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createPostCodesDictionary');
        return false;
    });