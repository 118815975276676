import { IDictionaryValue } from "../../components/templateForm/types/dictionaries/IDictionaryValue";

export const timezones: IDictionaryValue[] = [
  // {
  //     id: 1,
  //     name: 'UTC -12:00'
  // },
  // {
  //     id: 2,
  //     name: 'UTC -11:00'
  // },
  // {
  //     id: 3,
  //     name: 'UTC -10:00'
  // },
  // {
  //     id: 4,
  //     name: 'UTC -09:00'
  // },
  // {
  //     id: 5,
  //     name: 'UTC -08:00'
  // },
  // {
  //     id: 6,
  //     name: 'UTC -07:00'
  // },
  // {
  //     id: 7,
  //     name: 'UTC -06:00'
  // },
  // {
  //     id: 8,
  //     name: 'UTC -05:00'
  // },
  // {
  //     id: 9,
  //     name: 'UTC -04:00'
  // },
  // {
  //     id: 10,
  //     name: 'UTC -03:00'
  // },
  // {
  //     id: 11,
  //     name: 'UTC -02:00'
  // },
  // {
  //     id: 12,
  //     name: 'UTC -01:00'
  // },
  {
    id: 13,
    value: "+00:00",
    name: "UTC +00:00",
    intValue: 0,
  },
  {
    id: 14,
    value: "+01:00",
    name: "UTC +01:00",
    intValue: 1,
  },
  {
    id: 15,
    value: "+02:00",
    name: "UTC +02:00",
    intValue: 2,
  },
  {
    id: 16,
    value: "+03:00",
    name: "UTC +03:00",
    intValue: 3,
  },
  {
    id: 17,
    value: "+04:00",
    name: "UTC +04:00",
    intValue: 4,
  },
  {
    id: 18,
    value: "+05:00",
    name: "UTC +05:00",
    intValue: 5,
  },
  {
    id: 19,
    value: "+06:00",
    name: "UTC +06:00",
    intValue: 6,
  },
  {
    id: 20,
    value: "+07:00",
    name: "UTC +07:00",
    intValue: 7,
  },
  // {
  //     id: 21,
  //     name: 'UTC +08:00'
  // },
  // {
  //     id: 22,
  //     name: 'UTC +09:00'
  // },
  // {
  //     id: 23,
  //     name: 'UTC +10:00'
  // },
  // {
  //     id: 24,
  //     name: 'UTC +11:00'
  // },
  // {
  //     id: 25,
  //     name: 'UTC +12:00'
  // }
];
