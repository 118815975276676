import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';

interface IAuthenticatedViewProps {
    isAuthenticated: boolean;
}

class AuthenticatedViewInner extends React.Component<IAuthenticatedViewProps> {
    public render() {
        return (
            <>
                {this.props.isAuthenticated ? this.props.children : ""}
            </>
        )
    };
}

const mapStateToProps = (state: IAppState): IAuthenticatedViewProps => {
    return {
        isAuthenticated: state.commonState.isAuthenticated
    }
}

const AuthenticatedView = connect(mapStateToProps)(AuthenticatedViewInner);

export default AuthenticatedView;