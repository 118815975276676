import React, { ReactElement } from 'react';
import { BaseViewComponent } from '../../../_baseView/BaseViewComponent';
import { IBaseFormikProps } from './interfaces/IBaseFormikProps';
import './styles/BaseForm.scss';
import {
    FormikErrors,
    FormikTouched,
    FormikValues,
} from 'formik';

export class BaseForm extends React.Component<IBaseFormikProps>  {
    UNSAFE_componentWillReceiveProps = () => {
        this.forceUpdate();
    }
    
    public static defaultProps = {
        shouldUpdateComponentCheckInChildren: false
    }

    public shouldComponentUpdate(nextProps: IBaseFormikProps) {
        return this.isControlPropsChanged(nextProps) || this.forceUpdateKeyChanged(nextProps.forceUpdateKey) || this.props.shouldUpdateComponentCheckInChildren!;
    }

    public render() {
        const errorClassName = this.hasErrors() ? "error-input" : "";
        // const errorTooltipClassName = this.hasErrors() ? "default-error-tooltip error-tooltip" : "";
        const childrenClassName = this.props.inputWithoutBorders ? "input-without-borders wrapper" : errorClassName;

        return (
            <BaseViewComponent {...this.props} key={this.props.forceUpdateKey}>
                {/* <Tooltip overlayClassName={errorTooltipClassName} placement="bottomLeft" visible={this.hasErrors()} title={this.props.errors[this.props.name]}> */}
                <>
                    {React.Children.map(this.props.children, child => React.cloneElement(child as ReactElement<any>, { className: childrenClassName }))}
                    {this.hasErrors() && <div style={{ color: 'red' }}>{this.props.errors[this.props.name]}</div>}
                </>
                {/* </Tooltip> */}
            </BaseViewComponent>
        );
    }

    private isValuesChanged(values: FormikValues) {
        return this.props.values[this.props.name] !== values[this.props.name];
    }

    private isErrorsChanged(errors: FormikErrors<FormikValues>) {
        return this.props.errors[this.props.name] !== errors[this.props.name];
    }

    private isTouchedChanged(touched: FormikTouched<FormikValues>) {
        return this.props.touched[this.props.name] !== touched[this.props.name];
    }

    private isReadonlyChanged(readOnly: boolean | undefined) {
        return this.props.readOnly !== readOnly;
    }

    private isDisabledChanged(disabled: boolean | undefined) {
        return this.props.disabled !== disabled;
    }

    private isControlPropsChanged(nextProps: IBaseFormikProps) {
        return this.isValuesChanged(nextProps.values) || this.isErrorsChanged(nextProps.errors) ||
            this.isTouchedChanged(nextProps.touched) || this.isReadonlyChanged(nextProps.readOnly) || this.isDisabledChanged(nextProps.disabled);
    }

    private forceUpdateKeyChanged(forceUpdateKey: number | undefined): boolean {
        return this.props.forceUpdateKey !== forceUpdateKey;
    }

    private hasErrors(): boolean {
        return this.props.errors[this.props.name] && this.props.touched[this.props.name] ? true : false;
    }
}