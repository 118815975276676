import * as React from 'react';
import styles from './styles/SurveyContent.module.scss';
import { getSurveys } from '../../../../services/m29_users_management/SurveysService';
import { ISurveyContentProps } from './interfaces/ISurveyContentProps';
import { ISurveyQuestion } from '../../../../models/ISurveyQuestion';
import { resources } from '../../../../common/Resources';
import { SurveyForm } from './forms/SurveyForm';

export class SurveyContent extends React.Component<ISurveyContentProps> {
    state = {
        questions: Array<ISurveyQuestion>()
    }

    componentDidMount() {
        this.getQuestions();
    }

    getQuestions = (): void => {
        getSurveys()
            .then(questions => { this.setState({ questions }); })
    }

    render() {
        return (
            <>
                <h6 className={`h3 ${styles.uppercase}`}>{resources.registrationPage.thxForReg}</h6>
                <p className={`h5 ${styles.black}`}>{resources.registrationPage.toStartWork}</p>
                {this.state.questions.length > 0 ? <SurveyForm id={this.props.id} questions={this.state.questions} nextStep={this.props.nextStep} /> : resources.registrationPage.surveyIsNotAvailable}
            </>
        );
    }
}