import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const query = `SELECT company_name, address, email, phone FROM li_countries WHERE id = `;

export const getCompanyInformations = (id: number) => axios.post(sourceUrl, { data: { sql: query + id } })
    .then(response => { return response.data[0]; })
    .catch(() => {
        openErrorNotification(' getCompanyInformations');
        return null;
    });