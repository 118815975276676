import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { resourceDef } from '../../common/ResourceDef';

const changeOrderStatusURL = createGatewayAbsoluteUrl(resourceDef.ChangeOrderStatus);

export const changeOrderStatus = (orderId: number, targetStatusId: number, comment: string) => {
    return axios.post(changeOrderStatusURL, {
        statuses: [{
            order_id: orderId,
            status_id: targetStatusId,
            comment,
        }]
    })
}