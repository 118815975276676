import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../../helpers/HttpStatusCodeHelper';
import { IProductPricingCreate } from './interfaces/IProductPricingCreate';
import { IProductPricingsFilters } from './interfaces/IProductPricingsFilters';
import { IProductPricingUpdate } from './interfaces/IProductPricingUpdate';
import { resourceDef } from '../../../common/ResourceDef';

const productPricingsUrl = createGatewayAbsoluteUrl(resourceDef.Pricings);

export const createProductPricings = (product_id: number, obj: IProductPricingCreate) => axios.put(productPricingsUrl + `${product_id}/pricings`, { ...obj })
    .then(response => response.data)
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'createProductPricings M30');
        return undefined;
    });

export const getProductPricings = (product_id: number, obj?: IProductPricingsFilters) => axios.post(productPricingsUrl + `${product_id}/pricings`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getProductPricings M30');
        return [];
    });

export const updateProductPricings = (product_id: number, pricings_id: number, obj: IProductPricingUpdate) => axios.patch(productPricingsUrl + `${product_id}/pricings/${pricings_id}`, { ...obj })
    .then(response => { return true; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updateProductPricings M30');
        return false;
    });

export const deleteProductPricings = (product_id: number, pricings_id: number) => axios.delete(productPricingsUrl + `${product_id}/pricings/${pricings_id}`)
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'deleteProductPricings M30');
        return [];
    });