import React, { Component, ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { IInformationModalDialogProps } from './interfaces/IInformationModalDialogProps';
import { IModalProps } from './interfaces/IModalProps';

export default class InformationModalDialog<T extends IInformationModalDialogProps & IModalProps> extends Component<T, {}> {
    public static defaultProps = {
        withHideMethod: false,
        disabled: false,
        smallButtons: false,
        destroyOnClose: true,
    }
    
    public state = {
        modalVisible: false,
    }

    public setModalVisible(modalVisible: any) {
        this.setState({ modalVisible });
    }

    public render() {
        return (
            <>
                <Button
                    size={this.props.smallButtons ? "small" : "default"}
                    title={this.props.buttonTooltip}
                    className={this.props.buttonClassName}
                    disabled={this.props.disabled}
                    icon={this.props.icon}
                    type={this.props.buttonType ? this.props.buttonType : "default"}
                    onClick={() => this.setModalVisible(true)}
                    loading={this.props.loading}
                >
                    {this.props.buttonTitle}
                </Button>
                {this.state.modalVisible && this.renderModalWithChildren()}
            </>
        );
    }

    private renderModalWithChildren() {
        return (
            <Modal
                title={this.props.modalTitle}
                centered
                visible={this.state.modalVisible}
                onCancel={() => this.setModalVisible(false)}
                closable={true}
                footer={null}
                width={this.props.width}
                destroyOnClose={this.props.destroyOnClose}
            >
                {this.props.withHideMethod && React.cloneElement(this.props.children as ReactElement<any>, { hideModal: () => { this.setModalVisible(false) } })}
                {!this.props.withHideMethod && this.props.children}
            </Modal>);
    }
}