import React from 'react'
import { FormikProps } from 'formik'
import { IAddressInformation } from './IAddressInformation'
import { ICountry } from '../../../../../common/interfaces/ICountry'
import { IAddressChange } from './IAddressChange';
import AddressInfoInd from './addressInfoInd/AddressInfoInd';
import UnknownCountry from './unknownCountry/UnknownCountry';
import initialValues from './AddressInformationInitialValues';


export default class AddressInformation extends React.Component<ICountry & IAddressChange & FormikProps<IAddressInformation>> {

    componentDidUpdate = (prevProps: ICountry & IAddressChange & FormikProps<IAddressInformation>) => {
        if (this.props.countryId !== prevProps.countryId) {
            this.resetAddressForm();
        }
    }

    resetAddressForm = () => {
        const { setFieldValue, setFieldTouched } = this.props;
        ['address', 'address2', 'city', 'district', 'subdistrict', 'postalCode', 'province']
        .forEach(formField => {
            setFieldValue(formField, initialValues.address);
            setFieldTouched(formField, false);
        });
    }

    render() {
        switch (this.props.countryId) {
            case 1: // Indonesia
                return <AddressInfoInd {...this.props} />;
            default:
                return <UnknownCountry />;
        }
    }
}