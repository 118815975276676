import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.SurveysGet);
const putUrl = createGatewayAbsoluteUrl(resourceDef.SurveysPUT);

export const getSurveys = () => axios.post(getUrl)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.log(error, 'getSurveys M29');
        return [];
    });

export const postSurveys = (data: any, id_user: number) => axios.put(`${putUrl}/${id_user}/answer`, [...data])
    .then(() => { return true; })
    .catch(error => {
        console.log(error, 'postSurveys M29');
        return false;
    });