import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const subdistrictsQuery = 'SELECT id, id as "key", name, country_id, name_eng, deleted_at, created_at, updated_at FROM li_subdistricts';
const subdistrictsListQuery = 'SELECT id, name, country_id FROM li_subdistricts';

export const getSubdistrictsDictionaryList = () => axios.post(sourceUrl, { data: { sql: subdistrictsQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getSubdistrictsDictionaryList');
        return [];
    });

export const getSubdistrictsListD = () => axios.post(sourceUrl, { data: { sql: subdistrictsListQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getSubdistrictsList');
        return [];
    });

export const updateSubdistrictsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateSubdistrictsDictionary');
        return false;
    });

export const createSubdistrictsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createSubdistrictsDictionary');
        return false;
    });

export const deleteSubdistrictsDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createSubdistrictsDictionary');
        return false;
    });