import * as React from 'react';
import { ILeadWithFccId } from './../views/createNewOrder/contents/interfaces/ILeadWithFccId';
import { IProductPricing } from './../models/IProductPricing';
import { IProductsWithPricings } from './../views/createNewOrder/contents/interfaces/IProductsWithPricings';

export interface ICreateNewOrderContext {
    loading: boolean;
    activeStep: number;
    displayedSteps: number[];
    fcc_id?: string | number;
    phone?: string | number;
    selectedLeadId?: number;
    selectedProductId?: number;
    selectedPricingsIds?: number[];
    basketTotal?: number;
    leadsList: ILeadWithFccId[];
    productsList: IProductsWithPricings[];
    selectedPricingsOutsideBasket: number[] | string[];
    basket: IBasketItem[];
    handleFccIdInput: (e: any) => void;
    handlePhoneNumberInput: (e: any) => void;
    chooseLead: (e: any) => void;
    nextStep: (e: any) => void;
    chooseProduct: (e: any) => void;
    resetState: (property: string, value: any) => void;
    findProductWithLead: (lead_id: number) => any;
    choosePricing: (e: any) => void;
    addToBasket: () => void;
    removeFromBasket: (pircing_idx: string | number) => void;
    isPricingChecked: (e: string) => boolean;
    calculateBasket: () => number[];
}

const CreateNewOrderContext = React.createContext({} as ICreateNewOrderContext);

export default CreateNewOrderContext;

export type IBasketItem = IProductPricing | undefined;
