import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import NewsForm from '../forms/NewsForm';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { deleteNews, getNews } from '../../../../services/m61_news/NewsService';
import { getProducts } from '../../../../services/m30_products/ProductsService';
import { getUsersList } from '../../../../services/m29_users_management/GeneralUserService';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { INews } from '../interfaces/INews';
import { NewsTable } from '../tables/NewsTable';
import { openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class NewsAdminContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        selected_news_type: undefined,
        news_list: [] as INews[],
        productsD: [] as IDictionaryValue[],
        usersD: [] as IDictionaryValue[]
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.getNewsList();
        this.getProductsList();
        this.getUsersD();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.news_admin} />
                <RefreshTableButton
                    className=' marginBottom marginRigth'
                    loading={this.state.loading}
                    onClick={this.getNewsList}
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.SeeButtonAddNews}>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNew}
                            buttonTitle={resources.modalButtonsTitle.addNew}
                            buttonType='primary'
                            buttonTooltip={resources.title.addNew}
                            icon={"plus"}
                            disabled={this.state.loading}
                            withHideMethod={true}
                            width={800}
                            buttonClassName='addNew'
                        >
                            <NewsForm
                                productsD={this.state.productsD}
                                updateObjInArray={this.updateObjectInArray}
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <ClearBoth />
                <NewsTable
                    data={this.state.news_list}
                    loading={this.state.loading}
                    productsD={this.state.productsD}
                    usersD={this.state.usersD}
                    deleteNews={this.deleteNews}
                    updateObjInArray={this.updateObjectInArray}
                />
            </TableContainer>
        )
    };

    getNewsList = () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                'created_at',
                'date_from',
                'date_to',
                'deleted_at',
                'id',
                'id_news_type',
                'id_product',
                'id_user',
                'is_home_page',
                'title_en',
                'title_ru',
                'updated_at',
                'body_en',
                'body_ru'
            ]
        };

        getNews(object)
            .then(response => {
                const news_list = response.map((item: any) => { return { ...item, key: item.id } });
                this._isMounted && this.setState({ news_list });
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    getProductsList = (): void => {
        const object = {
            columns: [
                "id",
                "name",
                "country_id"
            ]
        };

        getProducts(object)
            .then(response => {
                const productsD = response.map((item: any) => {
                    return {
                        id: item.id,
                        name: item.name,
                        value: item.default_price,
                        country_id: item.country_id
                    }
                });

                this._isMounted && this.setState({ productsD });
            });
    }

    getUsersD = () => {
        const object = {
            columns: [
                "id",
                "name",
                "second_name",
            ]
        };

        getUsersList(object)
            .then((response) => {
                const usersD = response.map((item: any) => { return { name: `${item.name} ${item.second_name}`, id: item.id } });
                this._isMounted && this.setState({ usersD });
            });
    }

    updateObjectInArray = (object: any) => {
        // let news_list: INews[] = this.state.news_list;

        // const index = news_list.findIndex(x => x.id === object.id);

        // console.log('object');
        // console.log(object);

        // news_list[index].date_from = moment(new Date(object.date_from!)).startOf('day').format('YYYY/MM/DD HH:mm:ss').toString();
        // news_list[index].date_to = moment(new Date(object.date_to!)).endOf('day').format('YYYY/MM/DD HH:mm:ss').toString();
        // news_list[index].id_news_type = object.id_news_type;
        // news_list[index].id_product = object.id_product;
        // news_list[index].title = object.title;
        // news_list[index].body = object.body;
        // news_list[index].is_home_page = Number(object.is_home_page);
        // news_list[index].updated_at = new Date().toString();

        // console.log('news_list[index]');
        // console.log(news_list[index]);

        // this.setState({ news_list });
        this.getNewsList();
        openSuccessNotification(resources.statement.updated);
    }

    addObjToArray = (object: any) => {
        const news_list = [...this.state.news_list, { ...object, key: object.id }];
        this.setState({ news_list });
        openSuccessNotification(resources.statement.created);
    }

    deleteNews = (id_news: number) => {
        deleteNews(id_news)
            .then(response => {
                response && this.markNewsAsDeletedInArray(id_news);
            });
    }

    markNewsAsDeletedInArray = (id_news: number) => {
        let news_list: INews[] = this.state.news_list;

        const index = news_list.findIndex(x => x.id === id_news);

        news_list[index].deleted_at = new Date().toString();
        this.setState({ news_list });
        openSuccessNotification(resources.statement.deleted);
    }
} 