import React from 'react';
import { ReactElement } from 'react';

export const convertBooleanToString = (val: boolean): string => {
    return val ? "Yes" : "No";
}

export const convertBooleanYesOrNoReactElement = (booleanValue: boolean | undefined): ReactElement => {
    if (booleanValue === undefined) return <label style={{ color: 'orange' }}>Undefined</label>;
    return booleanValue ? <label style={{ color: 'green' }}>Yes</label> : <label style={{ color: 'red' }}>No</label>;
}

export const BoolToNum = (bool?: boolean): number => {
    return bool === true ? 1 : 0;
}

export const NumToBool = (num?: number): boolean => {
    return num === 1 ? true : false;
}

export const stringToBool = (num?: string): boolean => {
    return num === "1" ? true : false;
}