import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { resourceDef } from '../../common/ResourceDef';

const activateAccountUrl = createGatewayAbsoluteUrl(resourceDef.ActivateAccount);

export const activateAccount = (token: string) => axios.post(activateAccountUrl + token)
    .then(() => { return true })
    .catch(error => {
        console.log(error, 'activateAccount M29');
        console.log(error.response);
        return false;
    });