import * as React from 'react';
import RegisterForm from '../forms/register/RegisterForm';
import { AfterRegisterContents } from './components/afterRegister/AfterRegisterContent';
import { AuthenticationContainer } from '../../../components/containers/authenticationContainer/AuthenticationContainer';
import { IReferrer } from '../interfaces/IReferrer';
import { LogRegNav } from '../../../components/logRegNav/LogRegNav';
import { SurveyContent } from '../forms/survey/SurveyContent';

export class RegisterContent extends React.Component<IReferrer> {
    state = {
        stage: 1,
        createdUserId: null,
    }

    secondStep = (id: number) => {
        this.setState({ stage: 2, createdUserId: id })
    }

    thirdStep = () => {
        this.setState({ stage: 3 })
    }

    public render() {
        return (
            <AuthenticationContainer>
                {this.state.stage === 3 ?
                    (
                        <AfterRegisterContents />
                    )
                    :
                    (
                        <>
                            <LogRegNav activeTab='registration' />
                            {this.state.stage === 1 ? <RegisterForm nextStep={this.secondStep} referrer={this.props.referrer} /> : <SurveyContent id={this.state.createdUserId} nextStep={this.thirdStep} />}
                        </>
                    )
                }

            </AuthenticationContainer>
        )
    };
}