import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../../helpers/HttpStatusCodeHelper';
import { resourceDef } from '../../../common/ResourceDef';

const productTopOffersUrl = createGatewayAbsoluteUrl(resourceDef.TopOffers);

export const getProductTopOffers = () => axios.get(productTopOffersUrl )
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getProductTopOffers M30');
        return [];
    });