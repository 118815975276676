import * as React from 'react';
import { getLocale } from '../../../../helpers/LocaleHelper';
import { ITermsAndConditions } from './interfaces/ITermsAndConditions';

export class TermsAndConditions extends React.Component<ITermsAndConditions> {
    termsContent = () => {
        const terms = getLocale() === "en_GB" ? this.props.terms_en : this.props.terms_ru;

        return terms ? terms : "";
    }

    conditionsContent = () => {
        const conditions = getLocale() === "en_GB" ? this.props.conditions_en : this.props.conditions_ru;

        return conditions ? conditions : "";
    }

    public render() {
        return (
            <>
                <article className='productDetailsDescription' dangerouslySetInnerHTML={{ __html: this.termsContent() }}></article>
                <article className='productDetailsDescription' dangerouslySetInnerHTML={{ __html: this.conditionsContent() }}></article>
            </>
        )
    };
}