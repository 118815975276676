import * as React from 'react';
import styles from './styles/TopOffers.module.scss';
import { Empty } from 'antd';
import { IDashboardTopOffers } from './interfaces/IDashboardTopOffers';
import { ProductCard } from './ProductCard';

export class TopOffers extends React.Component<IDashboardTopOffers> {
    public render() {
        const { topOffers } = this.props;

        return (
            <>
                {
                    topOffers && topOffers.length === 0
                        ?
                        <Empty />
                        :
                        <div className={styles.grid_col_3}>
                            {topOffers.map((item, index) =>
                                <div key={index}>
                                    <ProductCard
                                        {...item}
                                        src_list={this.props.src_list}
                                    />
                                </div>
                            )}
                        </div>
                }
            </>
        )
    };
}