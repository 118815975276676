import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { ICreateRole } from './interfaces/ICreateRole';
import { IUpdateRole } from './interfaces/IUpdateRole';
import { resourceDef } from '../../common/ResourceDef';

const roleUrl = createGatewayAbsoluteUrl(resourceDef.Role);
const permissionsUrl = createGatewayAbsoluteUrl(resourceDef.Role);

export const getRolesListD = (object?: any) => axios.post(permissionsUrl, { ...object })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getUsersList M29');
    });

export const createRole = (object: ICreateRole) => axios.put(roleUrl, { ...object })
    .then(response => {
        console.log('createRole');
        console.log(response);

        return response.data;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'createRole M29');
    });

export const updateRole = (id: number, object: IUpdateRole) => axios.patch(roleUrl + `/${id}`, { ...object })
    .then(response => {
        console.log('updateRole');
        console.log(response);

        return true;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updateRole M29');
        return false;
    });

export const deleteRole = (id: number) => axios.delete(roleUrl + `/${id}`)
    .then(response => {
        console.log('deleteRole');
        console.log(response);

        return true;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'deleteRole M29');
        console.log(error);
        return false;
    });