import moment, { Moment } from 'moment';
import { dateToUrlString, urlStringToDate } from './DateHelpers';
import { hasPermission } from '../authorizations/AuthorizationLogic';

export const deleteFirstSlash = (str: string): string => {
    return str.substring(1, str.length);
}

export const deleteSlashAndWhatsAfter = (str: string): string => {
    return str.indexOf('/') > -1 ? str.substring(0, str.indexOf('/')) : str;
}

const stringToType = (value:string, type: string): number | string | Moment => {
    if (type === 'int') {
        return Number(value);
    }
    else if (type === 'date') {
        return urlStringToDate(value);
    }
    return value;
}

const typeToString = (value: number | string | Moment): string => {
    if (moment.isMoment(value)) {
        return dateToUrlString(value);
    }
    return value.toString();
}

export const urlParamsToObject = (urlParamsString: string, schema: object) => {
    const urlParams = new URLSearchParams(urlParamsString);

    return Object.keys(schema)
        .reduce((dict, filterKey) => {
            const paramValueString = urlParams.get(filterKey);
            const { type, isArray, permission } = schema[filterKey];
            if (permission && !hasPermission(permission)) {
                return dict;
            }
            if (paramValueString) {
                let paramValue;
                if (isArray) {
                    paramValue = paramValueString.split(',')
                    .map((value: string) => {
                        return stringToType(value, type);
                    });
                }
                else {
                    paramValue = stringToType(paramValueString, type);
                }
                dict[filterKey] = paramValue;
            }
            return dict;
        }, {});
}

export const objectToUrlParams = (object: object, schema: object): string => {
    const urlSearch = Object.keys(schema)
        .map((filterKey: string) => {
            const filterValue = object[filterKey];
            if (filterValue === undefined || filterValue.length === 0) {
                return null;
            }
            let filterValueString;
            if (schema[filterKey].isArray) {
                filterValueString = filterValue
                .map((el: any) => typeToString(el))
                .join(',');
            }
            else {
                filterValueString = typeToString(filterValue);
            }
            return `${filterKey}=${encodeURIComponent(filterValueString)}`;
        }).filter((el: string | null) => el !== null).join('&');
    return urlSearch.length > 0 ? `?${urlSearch}` : '';
}