import React from 'react';

export const ToLongStringToDots = (props: any) => {
    return (
        <>
            {props.children !== null && props.children.length > props.limitTo ? props.children.substring(0, props.limitTo - 3) + '...' : props.children}
        </>
    )
}



