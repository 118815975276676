import moment from "moment";

import { INITIAL_DATE_RANGE } from "../filters-data";

export const GROUP_BY_OPTIONS = Object.freeze({
  day: "day",
  hour: "hour",
  year: "year",
  month: "month",
  payout: "payout",
  country: "country",
  product: "product",
  partner: "partner",
  pub_id: "pub_id",
  lp_url: "lp_url",
});

const { startDate, endDate } = INITIAL_DATE_RANGE;
export const getDateBasedOnGroup = (dateFromState: {
  endDate: moment.Moment;
  startDate: moment.Moment;
}) =>
  Object.freeze({
    [GROUP_BY_OPTIONS.hour]: {
      startDate,
      endDate: moment(startDate).endOf("day"),
    },
    [GROUP_BY_OPTIONS.day]: {
      startDate,
      endDate,
    },
    [GROUP_BY_OPTIONS.month]: {
      startDate: moment(startDate).startOf("month"),
      endDate: moment(endDate).endOf("month"),
    },
    [GROUP_BY_OPTIONS.year]: {
      startDate: moment(startDate).startOf("year"),
      endDate: moment(endDate).endOf("year"),
    },
    [GROUP_BY_OPTIONS.country]: {
      startDate: dateFromState.startDate,
      endDate: dateFromState.endDate,
    },
    [GROUP_BY_OPTIONS.product]: {
      startDate: dateFromState.startDate,
      endDate: dateFromState.endDate,
    },
    [GROUP_BY_OPTIONS.partner]: {
      startDate: dateFromState.startDate,
      endDate: dateFromState.endDate,
    },
    [GROUP_BY_OPTIONS.payout]: {
      startDate: dateFromState.startDate,
      endDate: dateFromState.endDate,
    },
    [GROUP_BY_OPTIONS.pub_id]: {
      startDate: dateFromState.startDate,
      endDate: dateFromState.endDate,
    },
    [GROUP_BY_OPTIONS.lp_url]: {
      startDate: dateFromState.startDate,
      endDate: dateFromState.endDate,
    },
  });

export const GROUP_BY_PICKER_MAP = Object.freeze({
  day: "date",
  hour: "date",
  year: "year",
  month: "month",
  payout: "date",
  country: "date",
  product: "date",
  partner: "date",
  pub_id: "date",
  lp_url: "date",
});

export const PICKER_MODE_FORMAT_MAP = Object.freeze({
  date: "DD-MM-YYYY",
  time: "DD-MM-YYYY",
  year: "YYYY",
  month: "MM-YYYY",
});

const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getGroupByData = (group: string) => [
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.hour),
    key: GROUP_BY_OPTIONS.hour,
    selectable: group !== GROUP_BY_OPTIONS.hour,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.day),
    key: GROUP_BY_OPTIONS.day,
    selectable: group !== GROUP_BY_OPTIONS.day,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.month),
    key: GROUP_BY_OPTIONS.month,
    selectable: group !== GROUP_BY_OPTIONS.month,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.year),
    key: GROUP_BY_OPTIONS.year,
    selectable: group !== GROUP_BY_OPTIONS.year,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.country),
    key: GROUP_BY_OPTIONS.country,
    selectable: group !== GROUP_BY_OPTIONS.country,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.product),
    key: GROUP_BY_OPTIONS.product,
    selectable: group !== GROUP_BY_OPTIONS.product,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.partner),
    key: GROUP_BY_OPTIONS.partner,
    selectable: group !== GROUP_BY_OPTIONS.partner,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.payout),
    key: GROUP_BY_OPTIONS.payout,
    selectable: group !== GROUP_BY_OPTIONS.payout,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.pub_id),
    key: GROUP_BY_OPTIONS.pub_id,
    selectable: group !== GROUP_BY_OPTIONS.pub_id,
  },
  {
    title: capitalizeFirstLetter(GROUP_BY_OPTIONS.lp_url),
    key: GROUP_BY_OPTIONS.lp_url,
    selectable: group !== GROUP_BY_OPTIONS.lp_url,
  },
];
