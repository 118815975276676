import * as React from "react";
import axios from "axios";
import InformationModalDialog from "../../../../components/others/modal/InformationModalDialog";
import InstanceForm from "../forms/InstanceForm";
import ProtectedComponent from "../../../../components/authorizations/protectedComponents/ProtectedComponent";
import ProtectedModal from "../../../../components/authorizations/protectedComponents/ProtectedModal";
import SelectOnChange from "../../../../components/forms/select/SelectOnChange";
import { ClearBoth } from "../../../../components/controls/clearBoth/ClearBoth";
import { FieldInfo } from "../../../../components/common/fieldInfo/FieldInfo";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { getListFromLibrary } from "../../../../services/m40_libraries/LibrariesService";
import { ICallCenterInstances } from "../../../../models/ICallCenterInstances";
import { InstancesTable } from "../tables/InstancesTable";
import { Left } from "../../../../components/controls/left/Left";
import { megaUpdate } from "../../../../services/callCenter/MegaUpdateService";
import { MegaUpdateButton } from "../../../../components/megaUpdateButton/MegaUpdateButton";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/NotificationHelper";
import { PageInf } from "../../../../components/common/pageInf/PageInf";
import { RefreshTableButton } from "../../../../components/refreshTableButton/RefreshTableButton";
import { ResourceDef } from "../../../../common/ResourceDef";
import { resources } from "../../../../common/Resources";
import { Right } from "../../../../components/controls/right/Right";
import { SearchElements } from "../../../../components/common/searchElements/SearchElements";
import { TableContainer } from "../../../../components/containers/tableContainer/TableContainer";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";

const sourceUrl = ResourceDef.TemplateGateway;

export class InstancesContent extends React.Component<{
  isCCSimplified: boolean;
}> {
  _isMounted = false;

  state = {
    instancesList: Array<ICallCenterInstances>(),
    loading: true,
    search: undefined,
    selected_country: undefined,
  };

  componentDidMount = (): void => {
    this._isMounted = true;

    this.getInstancesList();

    this.setState({ countriesD: getDictionary("countriesNameD") });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  public render() {
    const { loading, selected_country, search, instancesList } = this.state;

    let filteredData = instancesList;

    if (selected_country)
      filteredData = filteredData.filter(
        (x) => x.country_id === selected_country
      );
    if (search)
      filteredData = filteredData.filter(
        (x) =>
          (x.type ? x.type.toLocaleLowerCase() : "").includes(search!) ||
          (x.name ? x.name.toLocaleLowerCase() : "").includes(search!) ||
          (x.domain ? x.domain.toLocaleLowerCase() : "").includes(search!) ||
          (x.user ? x.user.toLocaleLowerCase() : "").includes(search!) ||
          (x.base_url ? x.base_url.toLocaleLowerCase() : "").includes(search!)
      );

    return (
      <TableContainer>
        <PageInf
          title={
            this.props.isCCSimplified
              ? (resources.pageTitle.instancesForCCSimplified as string)
              : resources.pageTitle.instances
          }
        />
        <Left>
          <RefreshTableButton
            className="marginTopRefreshButton23px marginBottom marginRigth"
            loading={this.state.loading}
            onClick={this.refreshDataTable}
          />
        </Left>
        <FieldInfo>{resources.labels.country}</FieldInfo>
        <SelectOnChange
          name="selected_country"
          labelClassName="marginLeft marginBottom"
          onChange={this.onChangeSelect}
          value={selected_country}
          placeholder={resources.select.select}
          items={getDictionary("countriesNameD")}
          disabled={loading}
          loading={loading}
          width={200}
          allowClear
        />
        <ProtectedComponent permission={UserPermissionType.SeeMegaUploadButton}>
          <Right>
            <MegaUpdateButton
              buttonTitle="Mega upload"
              className="marginBottom marginLeft"
              loading={this.state.loading}
              onClick={this.megaUpdate}
            />
          </Right>
        </ProtectedComponent>
        <ProtectedModal
          permission={UserPermissionType.AddNewInstanceModalButton}
        >
          <Right>
            <InformationModalDialog
              modalTitle={resources.title.createNewInstance}
              icon={"plus"}
              buttonTitle={resources.modalButtonsTitle.createNewInstance}
              disabled={loading}
              withHideMethod={true}
              buttonType="primary"
            >
              <InstanceForm
                countries={getDictionary("countriesNameD")}
                addObjToArray={this.addObjToArray}
              />
            </InformationModalDialog>
          </Right>
        </ProtectedModal>
        <Right>
          <SearchElements
            className="marginRigth"
            onSearch={this.onGlobalSearch}
            loading={loading}
          />
        </Right>
        <ClearBoth />
        <InstancesTable
          data={filteredData}
          loading={loading}
          tableName={resources.buttons.search}
          generateDataBaseQuery={this.getInstancesList}
          changeStatus={this.changeStatus}
        />
      </TableContainer>
    );
  }

  onGlobalSearch = (event: any) =>
    this.setState({
      [event.target.name]: event.target.value.toLocaleLowerCase(),
    });

  onChange = (name: string, value: any): void =>
    this.setState({ [name]: value });

  getInstancesList = () => {
    this.setState({ loading: true });

    const object = {
      name: "cc_instances",
      column:
        "id, country_id, type, name, domain, user, key, base_url, is_active, is_online, created_at, updated_at",
    };

    getListFromLibrary(object)
      .then((response) => {
        const instancesList = response.map((item: any) => {
          return {
            id: item.id,
            key: item.id,
            country_id: item.country_id,
            type: item.type,
            name: item.name,
            domain: item.domain,
            user: item.user,
            password: item.key,
            base_url: item.base_url,
            is_active: item.is_active,
            is_online: item.is_online,
            created_at: item.created_at,
            updated_at: item.updated_at,
          };
        });

        this._isMounted && this.setState({ instancesList });
      })
      .finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
  };

  // TODO: Podpiąć service na select
  changeStatus = (event: any): void => {
    const instanceId = Number(event.target.value);
    const query = `UPDATE cc_instances set is_active = !is_active where id = ${instanceId}`;

    axios
      .post(sourceUrl, {
        data: {
          sql: query,
        },
      })
      .then(() => this.changeStatusInArray(instanceId))
      .catch(() =>
        openErrorNotification(resources.statement.databaseConnectionError)
      )
      .finally(() => this.setState({ loading: false }));
  };

  changeStatusInArray = (id: number) => {
    const { instancesList } = this.state;
    const index = instancesList.findIndex((x) => x.id === id);

    instancesList[index].is_active = !instancesList[index].is_active;
    instancesList[index].updated_at = new Date();

    this.setState({ instancesList });

    openSuccessNotification(resources.statement.status_updated);
  };

  refreshDataTable = () => this.getInstancesList();

  addObjToArray = (instance: ICallCenterInstances) =>
    this.setState({ instancesList: [...this.state.instancesList, instance] });

  onChangeSelect = (name: string, value: number): void =>
    this.setState({ [name]: value });

  megaUpdate = async () => {
    this.setState({ loading: true });

    await megaUpdate().then((response) => {
      response && this.getInstancesList();
    });
  };
}
