import * as React from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import styles from './styles/UserSelector.module.scss';
import { getRolesListD } from '../../../../../services/m29_users_management/RoleService';
import { getUsersList } from '../../../../../services/m29_users_management/GeneralUserService';
import { IDictionaryValue } from '../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IUser } from '../../../../orders/interfaces/IUser';
import { Select, Spin } from 'antd';

const { Option } = Select;

interface UserSelectorProps {
    onUsersSelected: (usersIds: Array<number>) => void;
}

const getUsersDebounced = AwesomeDebouncePromise((name) => getUsersList({
    columns: [
        "id",
        "name",
        "second_name"
    ],
    name,
})
    , 1000);

export class UserSelector extends React.Component<UserSelectorProps> {
    state = {
        loading: true,
        fetchedUsers: [] as Array<IDictionaryValue>,
        roles: [] as Array<IDictionaryValue>
    }

    componentDidMount = async () => {
        const roles = await getRolesListD({
            columns: [
                "id",
                "name",
                "description",
                "is_active",
                "created_at",
                "updated_at",
            ],
            is_active: true,
        });
        this.setState({
            roles,
        });
    }

    getUsers = async (name: string) => {
        this.setState({
            insertedText: name,
            loading: true,
        });
        if (name.length > 2) {
            const usersList = await getUsersDebounced(name);
            const userDict = [] as Array<IDictionaryValue>;
            usersList.forEach((user: IUser) => {
                if (user.name && user.id) {
                    const userName = user.second_name && user.second_name !== 'null' ? `${user.name} ${user.second_name}` : user.name;
                    userDict.push({
                        name: `${userName} - ${getUserRoleName(this.state.roles, user.id_users_role)}`,
                        id: user.id,
                    });
                }
            });
            this.setState({
                fetchedUsers: userDict,
                loading: false,
            });
        }
        else {
            this.setState({
                fetchedUsers: [],
                loading: false,
            });
        }
    }

    public render() {

        const { fetchedUsers, loading } = this.state;
        return (
            <div className={styles.container}>
                <p>Selected users</p>
                <Select
                    mode="multiple"
                    labelInValue
                    placeholder="Select users"
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.getUsers}
                    onChange={(selectedElements: any) => this.props.onUsersSelected(
                        selectedElements.map((el: any) => el.key)
                    )}
                    style={{ width: 630 }}
                >
                    {fetchedUsers.map((user: IDictionaryValue) => 
                        <Option key={user.id} value={user.id}>{`${user.name}`}</Option>
                    )}
                </Select>
            </div>
        )
    };
}

const getUserRoleName = (roleList: Array<IDictionaryValue>, roleId: number | undefined): string => {
    const role = roleList.find((role: IDictionaryValue) => role.id === roleId);
    if (role) {
        return role.name;
    }
    return 'Unknown';
}