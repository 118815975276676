import { useQuery } from "react-query";
import React, { useState } from "react";
import { Button, Input, Select, Spin } from "antd";

import { getCountriesList } from "../../../../services/m55_leads_statistics/LeadsStatisticsService";

const { Option } = Select;

// We have to use only Indonesia, Saudi Arabia and Thailand for verification
const AVAILABLE_COUNTRIES_FOR_VERIFICATION = [1, 3, 4];

export const VerificationForm = ({
  onResetMutation,
  onVerifyMutation,
}: {
  onResetMutation: () => void;
  onVerifyMutation: ({
    phone,
    countryCode,
  }: {
    phone: string;
    countryCode: string;
  }) => void;
}) => {
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  const { data, isLoading } = useQuery("countries", getCountriesList, {
    select: (data) =>
      data.filter((country: any) =>
        AVAILABLE_COUNTRIES_FOR_VERIFICATION.includes(country.id)
      ),
  });

  const resetForm = () => {
    setPhone("");
    setCountry("");
    setSubmitted(false);
    onResetMutation();
  };

  const onVerifyPhone = () => {
    setSubmitted(true);

    if (country && phone) {
      onVerifyMutation({ phone, countryCode: country });
    }
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div style={{ maxWidth: "250px", marginBottom: "20px" }}>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          addonBefore="Phone"
        />

        {isSubmitted && !phone && (
          <ValidationError>Please enter the phone number</ValidationError>
        )}
      </div>

      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Select
          value={country || undefined}
          onChange={(value: any) => setCountry(value)}
          placeholder="Select a country"
        >
          {data.map((country: any) => (
            <Option key={country.id} value={country.id.toString()}>
              {country.name}
            </Option>
          ))}
        </Select>

        {isSubmitted && !country && (
          <ValidationError>Please choose the country</ValidationError>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            color: "white",
            border: "1px solid transparent",
            backgroundColor: "#21B74B",
            marginRight: "10px",
          }}
          onClick={onVerifyPhone}
        >
          Check
        </Button>

        <Button onClick={resetForm}>Reset</Button>
      </div>
    </div>
  );
};

const ValidationError = (props: any) => (
  <span style={{ color: "red" }} {...props} />
);
