import { openErrorNotification } from './NotificationHelper';

/// TODO: Ustalić jedną wersje zwrotek i dopasować: 
export const displayHttpStatusCodeInformation = (error: any, methodName?: string): void => {
    // const code = error.response.status;
    // if (code && code >= 400 && code < 500) {
    //     openErrorNotification(`Client errors <400, 500) ${methodName}`);
    // }
    // else if (code && code >= 500) {
    //     openErrorNotification(`Server errors <500, ...) ${methodName}`);
    // }
    // else {
        openErrorNotification(`Server errors out of scope ${methodName}`);
    // }
}