import moment from 'moment';
import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { DatePicker } from 'antd';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';

interface ISeperateRangePicker {
    maxRangeInDays: number;
}

class SeperateRangePicker extends React.Component<IBaseFormikProps & ISeperateRangePicker> {

    disabledEndDate = (endValue: moment.Moment | undefined) => {
        if (endValue === undefined) return true;
        const startValue = this.props.values['date'][0] as moment.Moment | undefined;
        if (startValue === undefined) return true;
        if (endValue.valueOf() <= startValue.valueOf()) return true;
        const startCopy = moment(startValue);
        if (endValue.valueOf() >= startCopy.add(this.props.maxRangeInDays, 'day').valueOf()) return true;
        return false;
    };

    onStartChange = (value: moment.Moment | null) => {
        if (!value) return;
        let endDate = this.props.values['date'][1] as moment.Moment;
        const startCopyAdded = moment(value).add(this.props.maxRangeInDays - 1, 'day').endOf('day');
        if (value.valueOf() > endDate.valueOf()) {
            endDate = moment(value).endOf('day');
        }
        else if (startCopyAdded.valueOf() < endDate.valueOf()) {
            endDate = startCopyAdded;
        }
        this.props.setFieldValue(this.props.name, [value, endDate]);
    };

    onEndChange = (value: moment.Moment | null) => {
        value && this.props.setFieldValue(this.props.name, [this.props.values['date'][0], value]);
    };


    public render() {
        const [ startValue, endValue ] = this.props.values['date'];

        return (
            <BaseForm {...this.props} shouldUpdateComponentCheckInChildren={true}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={startValue}
                        placeholder="Start"
                        onChange={this.onStartChange}
                        
                    />
                    <DatePicker
                        disabledDate={this.disabledEndDate}
                        format="YYYY-MM-DD"
                        value={endValue}
                        placeholder="End"
                        onChange={this.onEndChange}
                    />
                </div>
            </BaseForm>)
    }

    private onChange(dateTime: any) {
        this.props.setFieldValue(this.props.name, dateTime);
    }
};

export default SeperateRangePicker;
