import { ICallCenterCampaigns } from '../models/ICallCenterCampaigns';
import { ICategoriesDictionary } from './interfaces/ICategoriesDictionary';
import { ICountries } from '../models/ICountries';
import { resources } from '../common/Resources';

export const encodeCampaign = (code: number, campaignArr: Array<ICallCenterCampaigns>): string => {
    const campaign = campaignArr.find(campaign => {
        return campaign.id === code;
    });
    return campaign ? campaign.name : resources.informations.notAvailable;
}

export const encodeCountry = (countryID: number, countriesArr: Array<ICountries>): string => {
    const country = countriesArr.find(coun => {
        return coun.id === countryID;
    });

    return country ? country.code : resources.informations.notAvailable;
}

export const decodeCountryFullName = (countryID: number, countriesArr: Array<ICountries>): string => {
    const country = countriesArr.find(coun => {
        return coun.id === countryID;
    });

    return country ? country.name : resources.informations.notAvailable;
}

export const removeLastCharIfNumber = (name: string): string => {
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    numbers.forEach(num => {
        if (name[name.length - 1] === num) {
            name = name.substring(0, name.length - 1);
        }
    });

    return name;
}

export const decodeCategoriesArray = (selectedCategoriesArr: Array<string>, categoriesDictionary: Array<ICategoriesDictionary>): string => {
    let cats: Array<any> = [];

    selectedCategoriesArr.forEach(el => {
        let cat: any = categoriesDictionary.find(category => {
            let result: any = category.id === Number(el);

            return result;
        });

        if (cat && cat.name && cat.name !== '')
            cats.push(cat ? cat.name : '');
    });

    const result = cats.length > 1 ? cats.slice(0, -1).join(', ') + ', ' + cats[cats.length - 1] : cats.toString();
    if (result === '') {
        return resources.informations.notAvailable;
    }

    return result;
}

export const decodeLandingName = (id: string | number, landingsArr: Array<any>): string => {
    const landing = landingsArr.find(land => {
        return land.id === id;
    })
    return landing ? landing.name : 'n/a';
}