import * as React from 'react';
import { Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { getOriginalToken } from '../../../../helpers/LocalStorageHelpers';
import { resources } from '../../../../common/Resources';
import { switchToDifferentUser, switchToOriginalUser } from '../../../../helpers/Authentication';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getUsersList } from '../../../../services/m29_users_management/GeneralUserService';
import styles from './styles/SwitchUser.module.scss';
// import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
// import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
// import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
// const { Option } = Select;


class SwitchUserInner extends React.Component {
    _isMounted = false;

    state = {
        loading: true,
        fetchedUsers: [],
        selectedUserId: undefined as number | undefined,
        loggedIn: false,
    }

    componentDidMount = () => {
        this._isMounted = true;
        const originalToken = getOriginalToken();
        this.setState({
            loading: false,
            loggedIn: originalToken !== null && originalToken !== '',
        });
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    switchToOriginal = async () => {
        await switchToOriginalUser();
        this.setState({ loggedIn: false, loading: true });
        window.location.reload();
    }

    switchToDiff = async () => {
        const { selectedUserId } = this.state;
        if (selectedUserId) {
            const success = await switchToDifferentUser(selectedUserId);
            success && this._isMounted && this.setState({ loggedIn: true, loading: true });
            success && window.location.reload();
        }
    }

    getUsersD = async (searchPhrase: string) => {
        if (searchPhrase.length <= 3) {
            return [];
        }
        const res = await getUsersList({
            email: searchPhrase,
            columns: ['email', 'id'],
        });
        this._isMounted && this.setState({
            fetchedUsers: res ? res.sort((a: any, b: any) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map((element: any) => ({
                ...element,
                name: `${element.email}[${element.id}]`,
            })) : [],
        });
    }

    getPartnersDDebounced = AwesomeDebouncePromise((searchPhrase: string) => this.getUsersD(searchPhrase), 1500);

    public render() {
        const { loggedIn, loading } = this.state;
        if (loading) {
            return (
                <Spin />
            );
        }
        if (loggedIn) {
            return (
                <div className={styles.container}>
                    <Button
                        className={styles.btn}
                        loading={this.state.loading}
                        onClick={this.switchToOriginal}
                    >
                        {resources.buttons.backToAdminAccount}
                    </Button>
                </div>
            );
        }
        else {
            return (
                // tmp disabled
                // <ProtectedComponent permission={UserPermissionType.ImpersonateAction}>
                // <div className={styles.container}>
                //     <Select
                //         className={styles.userSelect}
                //         labelInValue
                //         defaultActiveFirstOption={false}
                //         placeholder="Select users"
                //         filterOption={false}
                //         showSearch
                //         onSearch={this.getPartnersDDebounced}
                //         onChange={(selectedElements: any) => this._isMounted && this.setState({ selectedUserId: selectedElements.key })}
                //     >
                //         {this.state.fetchedUsers.map((user: IDictionaryValue) => 
                //             <Option key={user.id} value={user.id}>{`${user.name}`}</Option>
                //         )}
                //     </Select>
                //     <Button
                //         className={styles.btn}
                //         loading={this.state.loading}
                //         onClick={this.switchToDiff}
                //     >
                //         {resources.buttons.switchToUser}
                //     </Button>
                // </div>
                // </ProtectedComponent>
                null
            );
        }
    };
}

const SwitchUser = connect(null, null)(SwitchUserInner);

export default SwitchUser;