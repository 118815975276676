import * as React from 'react';
import styles from './styles/ViewHeader.module.scss';

interface IViewHeader {
    title: string;
}

export const ViewHeader = (props: IViewHeader) => {
    return (
        <p className={`h2 ${styles.header}`}>{props.title}</p>
    );
}