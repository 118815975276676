import * as React from 'react';
import { ClearBoth } from '../../../../components/controls/clearBoth/ClearBoth';
import { getK8HealthPodsCheck, getK8MikroInf } from '../../../../services/k8/K8Service';
import { K8HealthPodsCheckTable } from '../table/K8HealthPodsCheckTable';
import { K8MicroInfTable } from '../table/K8MicroInfTable';
import { PageTitle } from '../../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';

export class K8ServicesContent extends React.Component {
    _isMounted = true;

    state = {
        k8_micro_inf: [],
        k8_health_check_pod: [],
        loading_micro_inf: false,
        loading_health_pods_check: false
    }

    componentDidMount = () => {
        this.setState({
            loading_micro_inf: true,
            loading_health_pods_check: true
        });

        window.scrollTo(0, 0);
        this.getMikroInf();
        this.getHealthCheckPod();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    public render() {
        return (
            <TableContainer>
                <PageTitle>{resources.pageTitle.k8_services}</PageTitle>
                <h3><b>Micro informations</b></h3>
                <RefreshTableButton
                    className="marginBottom"
                    loading={this.state.loading_micro_inf}
                    onClick={this.getMikroInf}
                />
                <ClearBoth />
                <K8MicroInfTable
                    data={this.state.k8_micro_inf}
                    loading={this.state.loading_micro_inf}
                />
                <h3><b>Health pods check</b></h3>
                <RefreshTableButton
                    className="marginBottom"
                    loading={this.state.loading_health_pods_check}
                    onClick={this.getHealthCheckPod}
                />
                <ClearBoth />
                <K8HealthPodsCheckTable
                    data={this.state.k8_health_check_pod}
                    loading={this.state.loading_health_pods_check}
                />
            </TableContainer>
        )
    };

    getMikroInf = () => {
        this.setState({ loading_micro_inf: true });

        getK8MikroInf()
            .then(response => {
                let count = 1;
                const k8_micro_inf = response.map((item: any) => { return { key: count++, ...item, image: item.image.split("/").pop() } });
                this._isMounted && this.setState({ k8_micro_inf });
            })
            .finally(() => { this._isMounted && this.setState({ loading_micro_inf: false }); window.scrollTo(0, 0); });
    }

    getHealthCheckPod = () => {
        this.setState({ loading_health_pods_check: true });

        getK8HealthPodsCheck()
            .then(response => {
                let count = 1;
                const with_ok_status = response.ok ? response.ok.map((item: any) => { return { key: count++, status: 'OK', is_active: 1, name: item.split(":").pop() } }) : [];
                const with_error_status = response.error[0] ? response.error[0].map((item: any) => { return { key: count++, status: 'KANKEL', is_active: 0, name: item.POD, error: JSON.stringify(item.error) } }) : [];
                let k8_health_check_pod = [...with_ok_status, ...with_error_status];
                this._isMounted && this.setState({ k8_health_check_pod });
            })
            .finally(() => { this._isMounted && this.setState({ loading_health_pods_check: false }) });
    }
}