import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import TrafficSourceDictionaryForm from '../forms/TrafficSourceDictionaryForm';
import { deleteTrafficSourceDictionary, getTrafficSourcesDictionaryList } from '../../../../services/dictionaries/TrafficSourcesDictionaryService';
import { ITrafficSources } from '../interfaces/ITrafficSource';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TrafficSourceDictionaryTable } from '../table/TrafficSourceDictionaryTable';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { updateProductCategoriesDictionary } from '../../../../services/dictionaries/ProductCategoriesDivtionaryService';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { getListFromLibrary } from "../../../../services/m40_libraries/LibrariesService";


export class TrafficSourcesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        TrafficSourcesList: [],
        leadsTypeD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getTrafficSourcesList();

        getListFromLibrary({
            name: "li_leads_type",
            column: "id, name",
        }).then((leadsTypeD)=> {
            console.log(leadsTypeD);
            this._isMounted && this.setState({ leadsTypeD });
        })
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        let leadsTypeD = this.state.leadsTypeD;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.traffic_sources} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getTrafficSourcesList}
                />
                <ProtectedModal permission={UserPermissionType.AddNewTrafficSourcesModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewTrafficSource}
                            buttonTitle={resources.modalButtonsTitle.addNewTrafficSource}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewTrafficSource}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={this.state.loading}
                        >
                            <TrafficSourceDictionaryForm
                                leadsTypeD={leadsTypeD}
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <TrafficSourceDictionaryTable
                    loading={this.state.loading}
                    data={this.state.TrafficSourcesList}
                    deleteTrafficSource={this.deleteTrafficSource}
                    updateObjInArray={this.updateObjInArray}
                    changeExcludeStatus={this.changeExcludeStatus}
                    changeStatus={this.changeStatus}
                    leadsTypeD={leadsTypeD}
                />
            </TableContainer>
        )
    };

    getTrafficSourcesList = () => {
        this.setState({ loading: true });

        getTrafficSourcesDictionaryList()
            .then(TrafficSourcesList => this._isMounted && this.setState({ TrafficSourcesList }))
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    deleteTrafficSource = async (id: number) => {
        const query = `DELETE FROM remarketing_campaigns WHERE id = ${id}`;

        await deleteTrafficSourceDictionary(query)
            .then(response => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_system_variable);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        let TrafficSourcesList: ITrafficSources[] = this.state.TrafficSourcesList;

        TrafficSourcesList = TrafficSourcesList.filter(x => x.id !== id);

        this.setState({
            TrafficSourcesList,
            loading: false
        });

        openSuccessNotification(resources.statement.system_variable_deleted);
    }

    changeStatus = async (event: any) => {
        const id = Number(event.target.value);
        const query = `UPDATE remarketing_campaigns SET is_active = !is_active WHERE id = ${id}`;

        await updateProductCategoriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.changeStatusInArray(id);
                    openSuccessNotification(resources.statement.status_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        let trafficSourcesList: ITrafficSources[] = this.state.TrafficSourcesList;

        const index = trafficSourcesList.findIndex(x => x.id === id);

        trafficSourcesList[index].is_active = !trafficSourcesList[index].is_active;

        this.setState({ trafficSourcesList });
    }

    changeExcludeStatus = async (event: any) => {
        const id = Number(event.target.value);
        const query = `UPDATE remarketing_campaigns SET exclude_from_double = !exclude_from_double WHERE id = ${id}`;

        await updateProductCategoriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.changeExcludeStatusInArray(id);
                    openSuccessNotification(resources.statement.status_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeExcludeStatusInArray = (id: number) => {
        let TrafficSourcesList: ITrafficSources[] = this.state.TrafficSourcesList;

        const index = TrafficSourcesList.findIndex(x => x.id === id);

        TrafficSourcesList[index].exclude_from_double = !TrafficSourcesList[index].exclude_from_double;

        this.setState({ TrafficSourcesList });
    }

    addObjToArray = (variable: ITrafficSources) => {
        this.setState({ TrafficSourcesList: [...this.state.TrafficSourcesList, variable] });
    }

    updateObjInArray = (obj: ITrafficSources): void => {
        let TrafficSourcesList: ITrafficSources[] = this.state.TrafficSourcesList;

        const index = TrafficSourcesList.findIndex(x => x.id === obj.id);

        TrafficSourcesList[index].name = obj.name;
        TrafficSourcesList[index].code_name = obj.code_name;
        TrafficSourcesList[index].is_active = obj.is_active;
        TrafficSourcesList[index].exclude_from_double = obj.exclude_from_double;
        TrafficSourcesList[index].lead_type = obj.lead_type;
        TrafficSourcesList[index].description = obj.description;
        TrafficSourcesList[index].updated_at = obj.updated_at;

        this.setState({ TrafficSourcesList });
    }
}