import * as React from 'react';
import { Icon, Input } from 'antd';
import { ISearchElements } from './interfaces/ISearchElements';
import { resources } from '../../../common/Resources';

export class SearchElements extends React.Component<ISearchElements> {
    public render() {
        return (
            <Input
                className={`globalSearch ${this.props.className}`}
                placeholder={this.props.placeholder ? this.props.placeholder : resources.placeholders.search}
                type={this.props.type}
                prefix={
                    this.props.showIcon && <Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                name="search"
                onChange={this.props.onSearch}
                allowClear
                disabled={this.props.loading}
            />
        )
    };
}