import * as React from 'react';
import { IChangeLang } from '../../models/IChangeLang';
import { LandingPageContent } from './contents/LandingPageContent';

export class LandingPageView extends React.Component<IChangeLang> {
    render() {
        return (
            <LandingPageContent changeLang={this.props.changeLang} />
        );
    }
}