import * as React from 'react';
import styles from './styles/SendFeedback.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from 'antd';
import { IAppUserInfoModel } from '../../../../models/IAppUserInfoModel';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { sendSupportMessage } from '../../../../services/m62_support/SupportService';

export class SendFeedback extends React.Component<IHideModal & IAppUserInfoModel> {
    _isMounted = false;

    state = {
        message: '',
        loading: false
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    submitHandler = () => {
        this.setState({ loading: true });

        const { message } = this.state;
        const url = window.location.href;
        const obj = {
            name: this.props.id?.toString(),
            email: this.props.email,
            subject: url,
            message: message
        };


        sendSupportMessage(obj)
            .then(response => {
                if (response) {
                    openSuccessNotification(resources.statement.success);
                    this.props.hideModal && this.props.hideModal();
                }
                else {
                    openErrorNotification('Connection error. Please try again late.');
                }
            })
            .catch((error) => { console.error(error); })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    public render() {
        return (
            <>
                <h3 className={styles.h3}>{resources.modalTitle.sendFeedback}</h3>
                <p>{resources.supportView.message}</p>
                <TextArea
                    disabled={this.state.loading}
                    autosize={{ minRows: 5 }}
                    value={this.state.message}
                    onChange={(e: any) => this.setState({ message: e.target.value })}
                />
                <Button
                    loading={this.state.loading}
                    onClick={this.submitHandler}
                    className={styles.submitButton}
                >
                    {resources.buttons.save}
                </Button>
            </>
        )
    };
}