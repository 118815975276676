import RadioButtonF from '../../../../../components/templateForm/form/radioButtonList/RadioButtonListF';
import React, { Fragment } from 'react';
import styles from '../../../contents/styles/CreateNewOrderContent.module.scss';
import { FormikProps } from 'formik';
import { getPaymentMethods, getSalesChannels } from '../../../../../services/CreateNewOrderServices';
import { IPaymentInformation } from './IPaymentInformation';
import { RadioButtonItem } from '../../../../../components/templateForm/types/RadioButtonItem';
import { resources } from '../../../../../common/Resources';
import { Spin } from 'antd';

export default class PaymentInformation extends React.Component<FormikProps<IPaymentInformation>> {

    state = {
        isLoading: true,
        salesChannelsList: [] as Array<RadioButtonItem>,
        paymentMethodsList: [] as Array<RadioButtonItem>,
    }

    componentDidMount = async () => {
        const salesChannelsList = await getSalesChannels().then((data: any) => {
            return data.map((el: any) => new RadioButtonItem(el.id, el.name));
        });
        const paymentMethodsList = await getPaymentMethods().then((data: any) => {
            return data.map((el: any) => new RadioButtonItem(el.id, el.name));
        });
        this.setState({
            salesChannelsList,
            paymentMethodsList,
            isLoading: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin tip={resources.spin.loading} />
            </div>;
        }
        return (
            <Fragment>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <RadioButtonF
                            {...this.props}
                            name='salesChannel'
                            label={'Sales Channel'}
                            radioButtonItems={this.state.salesChannelsList}
                            flexDirectionColumn
                            required
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <RadioButtonF
                            {...this.props}
                            name='paymentMethod'
                            label={'Payment Method'}
                            radioButtonItems={this.state.paymentMethodsList}
                            flexDirectionColumn
                            required
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}
