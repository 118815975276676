import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';
import { ICourierInformation } from '../views/createNewOrder/components/forms/courierInformation/ICourierInformation';
import { resourceDef } from '../common/ResourceDef';

const liTables = createGatewayAbsoluteUrl(resourceDef.LibrariesGet)

// FIXME
// just some dummy data from database, incorrect relations between locations 

export const getPostalCodes = (countryId: number) => {
    return axios.post(
        liTables,
        {
            name: `li_post_codes_${countryId}`,
            column: 'id,name'
        }
    )
        .then(response => response.data.slice(0,10))
        .catch(error => error);
}

export const getProvinces = (countryId: number) => {
    return axios.post(
        liTables,
        {
            name: `li_provinces_${countryId}`,
            column: 'id,name'
        }
    )
        .then(response => response.data.slice(0,10))
        .catch(error => error);
}

export const getCities = (countryId: number, provinceId?: number) => {
    return axios.post(
        liTables,
        {
            name: `li_cities_${countryId}`,
            column: 'id,name'
        }
    )
        .then(response => response.data.slice(0,10))
        .catch(error => error);
}

export const getDistricts = (countryId: number, provinceId?: number, cityId?: number) => {
    return axios.post(
        liTables,
        {
            name: `li_districts_${countryId}`,
            column: 'id,name'
        }
    )
        .then(response => response.data.slice(0,10))
        .catch(error => error);
}

export const getSubdistricts = (countryId: number, provinceId?: number, cityId?: number, district?: number) => {
    return axios.post(
        liTables,
        {
            name: `li_subdistricts_${countryId}`,
            column: 'id,name'
        }
    )
        .then(response => response.data.slice(0,10))
        .catch(error => error);
}

export const getPostalCode = (countryId: number, provinceId?: number, cityId?: number, district?: number, subdistrict?: number): Promise<any> => {
    return new Promise(resolve => {
        resolve({
            postalCode: {
                id: 1,
                name: '1234',
            },
            locationId: 1,
        });
    });
}

export const getAddressByPostalCode = (postalCodeId: number): Promise<any> => {
    return new Promise(resolve => {
        resolve({
            addressData: {
                province: {
                    id: 0,
                    name: 'test1'
                },
                city: {
                    id: 1,
                    name: 'test2'
                },
                district: {
                    id: 2,
                    name: 'test3'
                },
                subdistrict: {
                    id: 3,
                    name: 'test4'
                },
                address: '',
            },
            locationId: 1,
        });
    })
}

export const getCourierInfo = (locationId: number): ICourierInformation => {
    const courierInfo = {
        courierName: 'test1',
        sourceWarehouse: 'test2',
        leadTime: 'test3',
        destinationCode: 'test4',
    }
    return courierInfo;
}