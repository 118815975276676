import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.AffbayClassifiersGet);
const createUrl = createGatewayAbsoluteUrl(resourceDef.AffbayClassifiersCreate);
const updateUrl = createGatewayAbsoluteUrl(resourceDef.AffbayClassifiersUpdate);

export const getAffbayClassifiers = () => axios.post(getUrl)
    .then((response) => { return response.data.data; })
    .catch(() => {
        openErrorNotification(' getAffbayClassifiers');
        return [];
    });

export const createAffbayClassifier = (obj: any) => axios.post(createUrl, { ...obj })
    .then((response) => {
        const result: any = response;

        if (result.status === 208) {
            openErrorNotification(result.data.message);
            return undefined;
        }
        else {
            return response.data.data.insertId;
        }
    })
    .catch(() => {
        openErrorNotification(' createSystemClassifiers');
        return undefined;
    });


export const updateAffbayClassifier = (obj: any) => axios.post(updateUrl, { ...obj })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateAffbayClassifier');
        return false;
    });