import * as React from 'react';
import moment from 'moment';
import styles from './styles/OrderDetails.module.scss';
import { IOrderDetails } from './interfaces/IOrderDetails';

const EMPTY_VALUE = '-----';

export class OrderDetails extends React.Component<IOrderDetails> {

    render() {
        const { order } = this.props;
        return (
            <div className={styles.detailsBox}>
                            <div className={styles.grid_col_3}>
                                <div>
                                    {displayDateElement('Created date', order.created_at)}
                                    {displayElement('Invoice', checkIfExists(order.invoice))}
                                    {displayElement('Credit Note', checkIfExists(order.credit_note))}
                                    {displayElement('SMS', checkIfExists(order.invoice))}
                                    {displayElement('FCC ID', checkIfExists(order.focus_lead_id))}
                                </div>
                                <div>
                                    {displayElement('3PL Status', order.courier_name)}
                                    {displayElement('3PL', order.courier_status)}
                                </div>
                                <div>
                                    {displayElement('Created By', order.user ? order.user.name : null)}
                                    <div>
                                        {order.user ? order.user.email : null}
                                    </div>
                                    {displayElement('Validated By', 'Brak')}
                                </div>
                            </div>
                        </div>
        )
    }
};

const displayElement = (name: string, value?: any) => {
    if (!value || value === null) {
        value = EMPTY_VALUE;
    }

    return (
        <>
            <div style={{ width: 90, float: 'left' }}>
                {name}:
            </div>
            <div >
                <b>{value}</b>
            </div>
        </>
    )
}

const displayDateElement = (name: string, date?: Date) => {
    return (
        <>
            <div style={{ width: 90, float: 'left' }}>
                {name}:
            </div>
            {date &&
                <div >
                    <b>{moment(new Date(date)).format("DD-MM-YYYY")}</b> {moment(new Date(date)).format("HH:mm:ss")}
                </div>
            }
        </>
    )
}

const checkIfExists = (ivoice: string | null) => {
    return ivoice && ivoice != null && ivoice.length > 0 ? <label style={{ color: 'green' }}>Yes</label> : <label style={{ color: '#ff0000' }}>No</label>;
}