import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../../helpers/HttpStatusCodeHelper';
import { IAllProductPagesFilters } from './interfaces/IAllProductPagesFilters';
import { IProductPageCreate } from './interfaces/IProductPageCreate';
import { IProductPagesFilters } from './interfaces/IProductPagesFilters';
import { IProductPageUpdate } from './interfaces/IProductPageUpdate';
import { resourceDef } from '../../../common/ResourceDef';

const productPagesUrl = createGatewayAbsoluteUrl(resourceDef.Pages);

export const createProductPages = (product_id: number, obj: IProductPageCreate) => axios.put(productPagesUrl + `${product_id}/pages`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'createProductPages M30');
        return undefined;
    });

export const getAllProductPages = (product_id: number, obj?: IProductPagesFilters) => axios.post(productPagesUrl + `${product_id}/pages`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getAllProductPages M30');
        return [];
    });

export const getListOfAllProductPages = (obj?: IAllProductPagesFilters) => axios.post(productPagesUrl + `/pages`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getListOfAllProductPages M30');
        return [];
    });

export const updateProductPages = (product_id: number, page_id: number, obj: IProductPageUpdate) => axios.patch(productPagesUrl + `${product_id}/pages/${page_id}`, { ...obj })
    .then(response => { return true; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updateProductPages M30');
        return false;
    });

export const deleteProductPages = (product_id: number, page_id: number) => axios.delete(productPagesUrl + `${product_id}/pages/${page_id}`)
    .then(response => {
        return true;
    })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updateProductPages M30');
        return false;
    });