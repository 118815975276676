export function getActivityFilters() {
    const filters = [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 0 }
    ];

    return filters;
}

export function getExcludeFilters() {
    const filters = [
        { text: "Excluded", value: 1 },
        { text: "NotExcluded", value: 0 }
    ];

    return filters;
}

export function getOnlineFilters() {
    const filters = [
        { text: "Online", value: 1 },
        { text: "Offline", value: 0 }
    ];

    return filters;
}

export function getQualifiedFilters() {
    const filters = [
        { text: "Qualified", value: 1 },
        { text: "Unqualified", value: 0 }
    ];

    return filters;
}

export function getContactedFilters() {
    const filters = [
        { text: "Contacted", value: 1 },
        { text: "Uncontacted", value: 0 }
    ];

    return filters;
}

export function getPayoutAccessVisibilityF() {
    const filters = [
        { text: 'Default', value: 2 },
        { text: 'Visible', value: 1 },
        { text: 'Hidden', value: 0 }
    ];

    return filters;
}

export function getAvailableInAffbayFilters() {
    const filters = [
        { text: "Available", value: 1 },
        { text: "Unavailable", value: 0 }
    ];

    return filters;
}

export function getVisibleFilters() {
    const filters = [
        { text: "Visible", value: 1 },
        { text: "Unavisible", value: 0 }
    ];

    return filters;
}

export function getPagesFilters() {
    const filters = [
        { text: "Landing", value: 0 },
        { text: "Prelanding", value: 1 }
    ];

    return filters;
}

export function getYesOrNoFilters() {
    const filters = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
    ];

    return filters;
}

export function getAvailableForSaleFilters() {
    const filters = [
        { text: "Available", value: 1 },
        { text: "Unavailable", value: 0 }
    ];

    return filters;
}

export function getVisibleForPartnersFilters() {
    const filters = [
        { text: "Visible", value: 1 },
        { text: "Unavisible", value: 0 }
    ];

    return filters;
}

export function getOperativeFilters() {
    const filters = [
        { text: "Operative", value: 1 },
        { text: "Inoperative", value: 0 }
    ];

    return filters;
}

export function getBankTransferFilters() {
    const filters = [
        { text: "With bank transfer", value: 1 },
        { text: "Without bank transfer", value: 0 }
    ];

    return filters;
}

export function getPaymentSchemeFilters() {
    const filters = [
        { text: "CPA", value: 'cpa' },
        { text: "CPL", value: 'cpl' }
    ];

    return filters;
}