import * as React from 'react';
import CitiesDictionaryForm from '../forms/CitiesDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { CitiesDictionaryTable } from '../table/CitiesDictionaryTable';
import { deleteCitiesDictionary, getCitiesDictionaryList } from '../../../../services/dictionaries/CitiesDivtionaryService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { ICities } from '../interfaces/ICities';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class CitiesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        selected_country: undefined,
        search: undefined,
        citiesList: Array<ICities>(),
        countriesD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getCitiesList();
        this.getCountriesD();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { citiesList, loading, selected_country, countriesD } = this.state;

        let filteredData = citiesList;

        if (selected_country)
            filteredData = filteredData.filter(x => x.country_id === selected_country);

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.citiesDictionary} />
                <Left>
                    <RefreshTableButton
                        className='marginBottom marginTopRefreshButton marginRigth'
                        loading={this.state.loading}
                        onClick={this.getCitiesList}
                    />
                </Left>
                <FieldInfo>{resources.labels.country}</FieldInfo>
                <SelectOnChange
                    name='selected_country'
                    labelClassName='marginLeft marginBottom'
                    onChange={this.onChangeSelect}
                    value={selected_country}
                    placeholder={resources.select.select}
                    items={getDictionary('countriesNameD')}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    allowClear
                />
                <ProtectedModal permission={UserPermissionType.AddNewCityModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewCity}
                            buttonTitle={resources.modalButtonsTitle.addNewCity}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewCity}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={loading}
                        >
                            <CitiesDictionaryForm
                                countriesD={countriesD}
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <CitiesDictionaryTable
                    loading={loading}
                    data={filteredData}
                    countriesD={countriesD}
                    deleteCity={this.deleteCity}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getCitiesList = () => {
        this.setState({ loading: true });

        getCitiesDictionaryList()
            .then(citiesList => { this._isMounted && this.setState({ citiesList }); })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    getCountriesD = () => {
        const countriesD = localStorage.getItem('countriesNameD');

        if (countriesD) {
            this.setState({ countriesD: JSON.parse(countriesD) });
        }
    }

    deleteCity = async (id: number) => {
        const query = `DELETE FROM li_cities WHERE id = ${id}`;

        await deleteCitiesDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_city);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const citiesList = this.state.citiesList.filter(x => x.id !== id);

        this.setState({
            citiesList,
            loading: false
        });

        openSuccessNotification(resources.statement.city_deleted);
    }

    addObjToArray = (city: ICities) => {
        this.setState({ citiesList: [...this.state.citiesList, city] });
    }

    updateObjInArray = (obj: ICities): void => {
        const { citiesList } = this.state;
        const index = citiesList.findIndex(x => x.id === obj.id);

        citiesList[index].name = obj.name;
        citiesList[index].name_eng = obj.name_eng;
        citiesList[index].country_id = obj.country_id;
        citiesList[index].updated_at = obj.updated_at;

        this.setState({ citiesList });
    }

    onChangeSelect = (name: string, value: number): void =>
        this.setState({ [name]: value });
}