export const setToken = (value: string): void => {
    localStorage.setItem('accessToken', value);
}

export const getToken = (): string | null => {
    return localStorage.getItem('accessToken');
}

export const setOriginalToken = (value: string): void => {
    localStorage.setItem('originalAccessToken', value);
}

export const getOriginalToken = (): string | null => {
    return localStorage.getItem('originalAccessToken');
}