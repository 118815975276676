import React from 'react';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
} from 'formik';
import { IResaleForm } from './IResaleForm';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { IResaleProduct } from '../interfaces/IResaleProduct';
import { Button } from 'antd';
import { resources } from '../../../../common/Resources';

const DAYS = ['7', '14', '21', '28', '38', '53'];

const getTrueValues = (obj: any, key: string): Array<number> => {
    const value = obj[key];
    if (Array.isArray(value)) {
        return value;
    }
    if (typeof value === 'string') {
        return value.split(',').map((el: string) => +el).filter((el: number) => el !== 0);
    }
    return [];
}

class TemplateP3Form extends React.Component<IResaleForm & FormikProps<IResaleProduct>> {

    componentDidMount() {
        const { touched } = this.props;
        for (const day of DAYS) {
            touched[day] = true;
        }
        this.props.setTouched(touched);
    }

    public render() {
        return (
            <Form>
                <h2>{this.props.product.product_name}</h2>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='7'
                        label={'7th day'}
                        placeholder={'not set'}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='14'
                        label={'14th day'}
                        placeholder={'not set'}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='21'
                        label={'21st day'}
                        placeholder={'not set'}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='28'
                        label={'28th day'}
                        placeholder={'not set'}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='38'
                        label={'38th day'}
                        placeholder={'not set'}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='53'
                        label={'53th day'}
                        placeholder={'not set'}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        labelTextAlignLeft
                        name='partner_token'
                        label={'Token'}
                        required
                    />
                </div>
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                    disabled={!this.props.isValid || this.props.isSubmitting}
                >{resources.buttons.submit}</Button>
            </Form>
        );
    };


}

const P3Form = withFormik<IResaleForm & IHideModal, IResaleProduct>({

    mapPropsToValues: props => ({
        ...(props.product),
    }),

    validate: (values: IResaleProduct, props: IResaleForm & IHideModal): FormikErrors<IResaleProduct> => {
        const errors = {} as FormikErrors<IResaleProduct>;
        if (values.partner_token === '' || values.partner_token === null) {
            errors.partner_token = 'Required!';
        }
        const valuesSet = new Set();
        for (const day of DAYS) {
            const config = values[day];
            if (Array.isArray(config)) {
                for (const value of config) {
                    if (valuesSet.has(value)) {
                        errors[day] = `Invalid config. Value "${value}" appeared twice"`;
                    }
                    valuesSet.add(+value);
                }
            }
            else {
                let configArray = config.split(',');
                for (const value of configArray) {
                    if (isNaN(value)) {
                        errors[day] = `Invalid config. Value "${value}" is not a number"`;
                    }
                    else if (valuesSet.has(value)) {
                        errors[day] = `Invalid config. Value "${value}" appeared twice"`;
                    }
                    valuesSet.add(+value);
                }
            }
        }
        return errors;
    },

    handleSubmit: async (values: IResaleProduct, bag: FormikBag<IResaleForm & IHideModal, IResaleProduct>) => {
        const obj = {
            ...values,
            days: {
                '7': getTrueValues(values, '7'),
                '14': getTrueValues(values, '14'),
                '21': getTrueValues(values, '21'),
                '28': getTrueValues(values, '28'),
                '38': getTrueValues(values, '38'),
                '53': getTrueValues(values, '53'),
            }
        }
        await bag.props.submit(obj);
        bag.props.hideModal && bag.props.hideModal();
    },
    enableReinitialize: true
})(TemplateP3Form);

export default P3Form;