import DictionaryF from '../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/CampaignsAdminForm.module.scss';
import TextAreaF from '../../../components/templateForm/form/textArea/TextAreaF';
import TextInputF from '../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../common/interfaces/IAddObjToArray';
import { ICampaignsAdminFormProps } from './interfaces/ICampaignsAdminFormProps';
import { IHideModal } from '../../../common/interfaces/IHideModal';
import { IId } from '../../../common/interfaces/IId';
import { IUpdateObjInArray } from '../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification } from '../../../helpers/NotificationHelper';
import { resources } from '../../../common/Resources';
import { Right } from '../../../components/controls/right/Right';

export class InnerCampaignsAdminForm extends React.Component<ICampaignsAdminFormProps & IHideModal & IId & IUpdateObjInArray & IAddObjToArray & FormikProps<ICampaignsAdminFormProps>> {
    public render() {
        return (
            <Form className='formStyle'>
                {this.props.name && <div className={styles.campaignsName}>{this.props.name}</div>}
                <section>
                    <div>
                        <TextInputF
                            {...this.props}
                            name='name'
                            label={resources.labels.name}
                            labelTextAlignLeft
                            boldLabel
                            required
                        />
                    </div>
                    <div>
                        <DictionaryF
                            {...this.props}
                            name='is_active'
                            label={resources.labels.isActive}
                            dictionaryValues={getBooleanValues()}
                            labelTextAlignLeft
                            boldLabel
                        />
                    </div>
                    <div>
                        <TextInputF
                            {...this.props}
                            name='id_external'
                            label={resources.labels.focus_ID}
                            labelTextAlignLeft
                            boldLabel
                            isNumeric
                            required
                        />
                    </div>
                    <div>
                        <DictionaryF    
                            {...this.props}
                            name='id_instance'
                            label={resources.labels.instance}
                            dictionaryValues={this.props.instancesD}
                            labelTextAlignLeft
                            boldLabel
                        />
                    </div>
                    <TextAreaF
                        {...this.props}
                        name='fields'
                        label='Fields'
                        textAreaRows={6}
                        labelTextAlignLeft
                        boldLabel
                    />
                </section>
                <Right>
                    <Button type='primary' htmlType='submit' className='marginRigth formMarginTop'>
                        {this.props.id ? resources.buttons.save : resources.buttons.create}
                    </Button>
                    <Button type='default' htmlType='reset'>
                        {resources.buttons.reset}
                    </Button>
                </Right>
            </Form>
        );
    };
}

const CampaignsAdminForm = withFormik<ICampaignsAdminFormProps & IUpdateObjInArray & IAddObjToArray, ICampaignsAdminFormProps & IUpdateObjInArray & IAddObjToArray>({
    mapPropsToValues: (props: ICampaignsAdminFormProps) => {
        return {
            name: props.name,
            is_active: Boolean(props.is_active) ? '1' : '0',
            id_external: props.id_external,
            id_instance: props.id_instance,
            fields: props.fields
        };
    },

    validate: (values: ICampaignsAdminFormProps) => {
        const errors: FormikErrors<ICampaignsAdminFormProps> = {};

        if (!values.name) {
            errors.name = resources.validation.required;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }
        if (!values.id_instance) {
            errors.id_instance = resources.validation.required;
        }
        if (!values.id_external) {
            errors.id_external = resources.validation.required;
        }

        return errors;
    },

    handleSubmit: (values: ICampaignsAdminFormProps, bag: FormikBag<ICampaignsAdminFormProps & IHideModal & IId & IUpdateObjInArray & IAddObjToArray, ICampaignsAdminFormProps>) => {
        const object: ICampaignsAdminFormProps = {
            id: bag.props.id,
            name: values.name,
            is_active: values.is_active,
            id_external: values.id_external,
            id_instance: values.id_instance,
            fields: values.fields,
        }

        // Sprawdzenie, czy te same vartości
        if (
            object.id
            && values.name === bag.props.name
            && values.is_active === bag.props.is_active
            && values.id_external === bag.props.id_external
            && values.id_instance === bag.props.id_instance
            && values.fields === bag.props.fields
        )
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else {
            createQuery(object, bag.props);
        }
    }

})(InnerCampaignsAdminForm);

export default CampaignsAdminForm;

const createQuery = async (obj: ICampaignsAdminFormProps, bag: IHideModal & IId & IUpdateObjInArray & IAddObjToArray) => {


    if (bag.id) {
        // await updateCampaign(updateQuery)
        //     .then((response) => {
        //         if (response) {
        //             obj.updated_at = new Date();
                    
        //             bag.updateObjInArray!(obj);

        //             openSuccessNotification(resources.statement.companie_updated);
        //         }
        //         else {
        //             openErrorNotification(resources.statement.databaseConnectionError)
        //         }
        //     });
    }
    else {
        // await createCampaign(createQuery)
        //     .then((response) => {
        //         if (response) {
        //             obj.id = response;
        //             obj.key = response;
        //             obj.created_at = new Date();
        //             obj.updated_at = new Date();

        //             bag.addObjToArray!(obj);

        //             openSuccessNotification(resources.statement.companie_created);
        //         }
        //         else {
        //             openErrorNotification(resources.statement.there_was_a_problem_creating_campaign);
        //         }
        //     });
    }

    bag.hideModal!();
}