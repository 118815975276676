import * as React from 'react';
import email from './images/email.svg';
import skype from './images/skype.svg';
import styles from './styles/PartnerHoverPopUpItem.module.scss';
import telegram from './images/telegram.svg';
import { copyClipboard } from '../../../../helpers/CopyHelpers';
import { createAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { IPartnerHoverPopUpItemProps } from './interfaces/IPartnerHoverPopUpItemProps';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';


export const PartnerHoverPopUpItem = (props: IPartnerHoverPopUpItemProps) => {
    let src;
    if (props.type === 'skype') {
        src = skype;
    } else if (props.type === 'telegram') {
        src = telegram;
    } else if (props.type === 'email') {
        src = email;
    } else {
        src = '';
    }

    const handleOnClick = (e: any) => {
        copyClipboard(e, props.manager);
    }

    return (
        <button
            onClick={handleOnClick}
            style={{ padding: '5px 0' }}>
            <section className={styles.grid}>
                <img src={src} alt="contact icon" />
                {props.type === 'telegram' &&
                    < a target='_blank' href={`https://telegram.me/${props.manager}`} rel="noopener noreferrer">
                        <Tooltip title={props.manager}>
                            {props.manager !== null && props.manager.length > 22 ? props.manager.substring(0, 19) + '...' : props.manager}
                        </Tooltip>
                    </a>
                }
                {props.type === 'skype' &&
                    <a target='_blank' href={`skype:${props.manager}?userinfo`} rel="noopener noreferrer">
                        <Tooltip title={props.manager}>
                            {props.manager !== null && props.manager.length > 22 ? props.manager.substring(0, 19) + '...' : props.manager}
                        </Tooltip>
                    </a>
                }
                {props.type === 'email' &&
                    <Link to={createAbsoluteUrl('support')}>
                        <Tooltip title={props.manager}>
                            {props.manager !== null && props.manager.length > 22 ? props.manager.substring(0, 19) + '...' : props.manager}
                        </Tooltip>
                    </Link>
                }
            </section>
        </button >

    );
}