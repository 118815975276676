import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import moment from "moment";
import React, { Component } from "react";
import SelectOnChange from "../../../components/forms/select/SelectOnChange";
import styles from "./styles/FinanceContent.module.scss";
import { Button, Descriptions, Icon } from "antd";
import { ClearBoth } from "../../../components/controls/clearBoth/ClearBoth";
import { FieldInfo } from "../../../components/common/fieldInfo/FieldInfo";
import { FinanceTable } from "../tables/FinanceTable";
import { findInDictionaryAndReturnValue } from "../../../helpers/DisctionariesHelper";
import {
  getBalance,
  getFinance,
  getTotalBalance,
} from "../../../services/finance/FinanceService";
import {
  getCurrentDateTime,
  getDateTimeDayAgo,
} from "../../../helpers/DateHelpers";
import { getFinanceChartData } from "../../../services/m47_balance_operations/ChartDataService";
import { getProductsD } from "../../../services/m30_products/ProductsService";
import { getUsersList } from "../../../services/m29_users_management/GeneralUserService";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IFinance } from "../interfaces/IFinance";
import { Left } from "../../../components/controls/left/Left";
import { RangePickerF } from "../../../components/forms/rangePicker/DatePicker";
import { resources } from "../../../common/Resources";
import { Right } from "../../../components/controls/right/Right";
import { timezones } from "../../../helpers/timezones";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";

export const statusTestD = [
  { id: "pending", name: "Pending" },
  { id: "cleared", name: "Cleared" },
  { id: "rejected", name: "Rejected" },
];

export const schemeTestD = [
  { id: "cpa", name: "CPA" },
  { id: "cpl", name: "CPL" },
];

const TIMEZONE_OFFSET = 13;

export class FinanceContent extends Component {
  _isMounted = false;

  state = {
    loading: false,
    total_balance_loading: false,
    accepted_balance_loading: false,
    pending_balance_loading: true,
    disable_filter_button: true,
    id_user: undefined,
    timezone: 20,
    transaction_type: "deposit",
    transaction_status: undefined,
    total_balance: 0,
    accepted_balance: { withdrawals: 0, deposits: 0 },
    pending_balance: { withdrawals: 0, deposits: 0 },
    generated_at: undefined,
    financeList: Array<IFinance>(),
    usersD: Array<IDictionaryValue>(),
    productsD: Array<IDictionaryValue>(),
    dateFrom: getDateTimeDayAgo(),
    dateTo: getCurrentDateTime(),
    xData: [] as Array<string>,
    yData: [] as Array<Array<number>>,
  };

  componentDidMount = () => {
    this._isMounted = true;
    getProductsD().then((productsD) => {
      this._isMounted &&
        this.setState({ productsD }, () => {
          this.getFinanceInf();
          window.scrollTo(0, 0);
        });
    });
  };

  componentWillUnmount = () => (this._isMounted = false);

  render() {
    const {
      loading,
      total_balance_loading,
      accepted_balance_loading,
      pending_balance_loading,
      disable_filter_button,
      financeList,
      id_user,
      timezone,
      transaction_type,
      transaction_status,
      dateFrom,
      dateTo,
      total_balance,
      accepted_balance,
      pending_balance,
      generated_at,
      usersD,
      xData,
      yData,
    } = this.state;

    const balanceChartData = [] as Array<number>;
    for (let i = 0; i < xData.length; i++) {
      balanceChartData.push(yData[0][i] - yData[1][i]);
    }

    return (
      <>
        <div className={styles.header}>
          <ViewHeader title={resources.pageTitle.finance} />
          <Descriptions style={{ textAlign: "right", width: "30%" }}>
            <Descriptions.Item label={resources.labels.generatedOn}>
              {this.displayDate(loading, generated_at)}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div className={styles.balanceInfoContainer}>
          <div className={styles.balanceInfo}>
            <div className={styles.balanceRecord}>
              <div>
                <div className={styles.balanceLabel}>
                  {resources.labels.acceptedBalance}
                </div>
                <div className={styles.balanceLabelDesc}>
                  {resources.labels.acceptedBalanceExplanation}
                </div>
              </div>
              <div className={styles.balanceValue}>
                {this.displayBalance(
                  accepted_balance_loading,
                  transaction_type === "withdrawal"
                    ? accepted_balance.withdrawals
                    : accepted_balance.deposits
                )}
              </div>
            </div>
            <div className={styles.balanceRecord}>
              <div className={styles.balanceLabel}>
                {resources.labels.actualBalance}
              </div>
              <div className={styles.balanceValue}>
                {this.displayBalance(total_balance_loading, total_balance)}
              </div>
            </div>
            <InformationModalDialog
              buttonTitle={resources.labels.requestPayout}
              buttonClassName={styles.payoutButton}
              withHideMethod={true}
              width={400}
            >
              <h3>SERVICE TEMPORARILY UNAVAILABLE</h3>
            </InformationModalDialog>
            <div className={styles.pendingTransactionsConatainer}>
              {resources.labels.notAcceptedBalance}
              <div className={styles.pendingTransactionRecord}>
                <div className={styles.pendingTransactionValuesRed}>
                  {`(${resources.modalButtonsTitle.withdraw})`}{" "}
                  {this.displayBalance(
                    pending_balance_loading,
                    pending_balance.withdrawals
                  )}
                </div>
              </div>
              <div className={styles.pendingTransactionRecord}>
                <div className={styles.pendingTransactionValuesGreen}>
                  {`(${resources.modalButtonsTitle.deposit})`}{" "}
                  {this.displayBalance(
                    pending_balance_loading,
                    pending_balance.deposits
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.balanceGraph}>
            <CommonChart
              title={resources.labels.earningsChart}
              height={250}
              isLoading={xData.length === 0}
              xData={xData}
              yData={[
                {
                  name: resources.modalButtonsTitle.deposit,
                  color: "#50B9E5",
                  data: yData[0],
                  type: "bar",
                  formatter: (el) =>
                    `${el.seriesName}<br />${el.name}:${this.displayBalance(
                      false,
                      el.value
                    )}`,
                  showAxisLabels: {
                    formatter: (value) => {
                      value /= 100;
                      if (value >= 10 ** 6) {
                        return `$${(value / 10 ** 6).toFixed(1)}M`;
                      } else if (value >= 10 ** 3) {
                        return `$${(value / 10 ** 3).toFixed(1)}K`;
                      }
                      return `$${value}`;
                    },
                  },
                },
                {
                  name: resources.modalButtonsTitle.withdraw,
                  color: "#5D95E8",
                  data: yData[1],
                  type: "bar",
                  formatter: (el) =>
                    `${el.seriesName}<br />${el.name}:${this.displayBalance(
                      false,
                      el.value
                    )}`,
                  showAxisLabels: {},
                },
                {
                  name: resources.labels.balance,
                  color: "#FBBC06",
                  data: balanceChartData,
                  type: "line",
                  formatter: (el) =>
                    `${el.seriesName}<br />${el.name}:${this.displayBalance(
                      false,
                      el.value
                    )}`,
                },
              ]}
            />
          </div> */}
        </div>
        <div>
          <Left>
            <FieldInfo>{resources.labels.dateRange}</FieldInfo>
            <RangePickerF
              onChange={this.onDateChange}
              className="marginRigth marginBottom customAnt--newsSelect"
              value={[moment(new Date(dateFrom)), moment(new Date(dateTo))]}
              width={220}
              disabled={loading}
              dateMode
            />
          </Left>
          <Left>
            <FieldInfo className="marginLeft">
              {resources.labels.transactionStatus}
            </FieldInfo>
            <SelectOnChange
              onChange={this.onChangeSelect}
              className="marginLeft customAnt--newsSelect"
              name="transaction_status"
              value={transaction_status}
              items={statusTestD}
              loading={loading}
              width={150}
              allowClear
              showSearch
            />
          </Left>
          <Left>
            <FieldInfo className="marginLeft">
              {resources.labels.email}
            </FieldInfo>
            <SelectOnChange
              onChange={this.onChangeSelect}
              className="marginLeft customAnt--newsSelect"
              name="id_user"
              value={id_user}
              items={usersD}
              loading={loading}
              width={150}
              allowClear
              showSearch
            />
          </Left>
          <Left>
            <FieldInfo className="marginLeft">
              {resources.labels.timezone}
            </FieldInfo>
            <SelectOnChange
              onChange={this.onChangeSelect}
              className="marginLeft customAnt--newsSelect"
              name="timezone"
              value={timezone}
              items={timezones.map((timezone) => ({
                ...timezone,
                name: timezone.value,
              }))}
              loading={loading}
              width={150}
            />
          </Left>
          <Left>
            <Button
              className={`marginLeft filterButton ${styles.filterButton}`}
              disabled={loading || disable_filter_button}
              onClick={this.getFinanceInf}
              type="primary"
            >
              {resources.buttons.filter}
            </Button>
          </Left>
          <Right>
            <div className={styles.transactionTypeControls}>
              <div
                className={
                  transaction_type === "deposit"
                    ? styles.transactionTypeLabelSelected
                    : styles.transactionTypeLabel
                }
                onClick={() => this.onTypeChange("deposit")}
              >
                {resources.modalButtonsTitle.deposit}
              </div>
              <div
                className={
                  transaction_type === "withdrawal"
                    ? styles.transactionTypeLabelSelected
                    : styles.transactionTypeLabel
                }
                onClick={() => this.onTypeChange("withdrawal")}
              >
                {resources.modalButtonsTitle.withdraw}
              </div>
            </div>
          </Right>
          <ClearBoth />
        </div>
        <FinanceTable
          loading={loading}
          data={financeList}
          usersD={usersD}
          timezoneOffset={
            this.state.timezone ? this.state.timezone - TIMEZONE_OFFSET : 0
          }
        />
      </>
    );
  }

  onTypeChange = (value: string) => {
    this.setState(
      {
        transaction_type: value,
        disable_filter_button: true,
      },
      this.getFinanceInf
    );
  };

  onChangeSelect = (name: string, value?: number): void =>
    this.setState({
      [name]: value,
      disable_filter_button: false,
    });

  onDateChange = (date: any, dateTime: any) => {
    this.setState({
      dateFrom: dateTime[0].toString(),
      dateTo: dateTime[1].toString(),
      disable_filter_button: false,
    });
  };

  getFinanceInf = () => {
    this.setState({ loading: true });

    this.getPendingBalance();
    this.getAcceptedBalance();
    this.getTotalBalance();
    this.getChartData();

    const timezoneOffset = this.state.timezone
      ? TIMEZONE_OFFSET - this.state.timezone
      : 0;

    const obj = {
      id_user: this.state.id_user,
      status: this.state.transaction_status,
      type: this.state.transaction_type,
      dateFrom: moment
        .utc(this.state.dateFrom)
        .startOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
      dateTo: moment
        .utc(this.state.dateTo)
        .endOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
    };

    getFinance(obj)
      .then((response) => {
        const { operations } = response;
        const financeList: any[] = operations.map((item: any) => {
          return {
            ...item,
            key: item.id,
            disposition_at: moment
              .utc(item.disposition_at)
              .add(this.state.timezone - TIMEZONE_OFFSET, "hours"),
            settled_at: moment
              .utc(item.settled_at)
              .add(this.state.timezone - TIMEZONE_OFFSET, "hours"),
            lead_created_at: moment
              .utc(item.lead_created_at)
              .add(this.state.timezone - TIMEZONE_OFFSET, "hours"),
            product: findInDictionaryAndReturnValue(
              item.id_product,
              this.state.productsD
            ),
          };
        });

        this._isMounted &&
          this.setState({
            financeList,
            generated_at: new Date(Date.now()).toLocaleString(),
          });
      })
      .finally(
        () =>
          this._isMounted &&
          this.setState(
            {
              loading: false,
              disable_filter_button: true,
            },
            () => this.getUsers()
          )
      );
  };

  getUsers = () => {
    const object = {
      id: this.getUniqueUsersIdList(),
      columns: ["id", "email"],
    };

    getUsersList(object).then((response) => {
      const usersD = response.map((item: any) => {
        return {
          id: item.id,
          name: item.email,
        };
      });

      this._isMounted && this.setState({ usersD });
    });
  };

  getAcceptedBalance = () => {
    this.setState({ accepted_balance_loading: true });

    const timezoneOffset = this.state.timezone
      ? TIMEZONE_OFFSET - this.state.timezone
      : 0;

    const data = {
      id_user: this.state.id_user,
      status: this.state.transaction_status,
      type: this.state.transaction_type,
      dateFrom: moment
        .utc(this.state.dateFrom)
        .startOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
      dateTo: moment
        .utc(this.state.dateTo)
        .endOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
    };

    getBalance(data)
      .then(
        (accepted_balance) =>
          this._isMounted && this.setState({ accepted_balance })
      )
      .finally(
        () =>
          this._isMounted && this.setState({ accepted_balance_loading: false })
      );
  };

  getPendingBalance = () => {
    this.setState({ pending_balance_loading: true });

    const timezoneOffset = this.state.timezone
      ? TIMEZONE_OFFSET - this.state.timezone
      : 0;

    const data = {
      id_user: this.state.id_user,
      status: "pending",
      type: this.state.transaction_type,
      dateFrom: moment
        .utc(this.state.dateFrom)
        .startOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
      dateTo: moment
        .utc(this.state.dateTo)
        .endOf("day")
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss"),
    };

    getBalance(data)
      .then(
        (pending_balance) =>
          this._isMounted && this.setState({ pending_balance })
      )
      .finally(
        () =>
          this._isMounted && this.setState({ pending_balance_loading: false })
      );
  };

  getTotalBalance = () => {
    this.setState({ total_balance_loading: true });

    getTotalBalance("")
      .then(
        (total_balance) => this._isMounted && this.setState({ total_balance })
      )
      .finally(
        () => this._isMounted && this.setState({ total_balance_loading: false })
      );
  };

  getUniqueUsersIdList = () => {
    let list: number[] = [];

    this.state.financeList.map((item: IFinance) => {
      if (item.id_user && list.findIndex((x) => x === item.id_user) === -1)
        list.push(item.id_user!);

      return null;
    });

    return list;
  };

  getChartData = async () => {
    const chartData = await getFinanceChartData({
      groupBy: "month",
      status: "cleared",
      dateFrom: moment().subtract(5, "months").format("YYYY-MM-DD HH:mm:ss"),
      dateTo: moment().format("YYYY-MM-DD HH:mm:ss"),
    });

    const xData = chartData.labels.map((label: string) =>
      moment(label).format("MMM")
    );
    const yData = [chartData.data.deposit, chartData.data.withdrawal];

    this._isMounted &&
      this.setState({
        xData,
        yData,
      });
  };

  displayBalance = (loading: boolean, value: number) =>
    loading ? (
      <Icon type="sync" spin={loading} />
    ) : (
      `${(value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`
    );

  displayDate = (loading: boolean, value?: string) =>
    loading ? (
      <Icon type="sync" spin={loading} />
    ) : (
      <div style={{ color: "black", fontWeight: "bold" }}>{value}</div>
    );
}
