export const isAlphaNumeric = (value: string): boolean => {
    return value.match(/^[a-z0-9]+$/i) !== null ? true : false;
}

export const emailIsValid = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const checkIfUTF8 = (input: string): boolean => {
    var output = "";

    for (var i = 0; i < input.length; i++) {
        if (input.charCodeAt(i) <= 127) {
            output += input.charAt(i);
        }
    }

    return output === input;
}

export const checkIfNumeric = (value: string): boolean => {
    return isNaN(Number(value)) ? true : false;
}