import React from 'react';
import { Select } from 'antd';

const SelectOnChange = ({
    name,
    label = undefined,
    items,
    onChange,
    margin = undefined,
    defaultValue = undefined,
    width = undefined,
    placeholder = undefined,
    required = undefined,
    labelClassName = undefined,
    className = undefined,
    id = undefined,
    ...rest
}) => {
    return (
        <>
            {label ? <label className={labelClassName} htmlFor={label}><b>{label}</b></label> : null}
            {required ? <label style={{ color: 'red' }}> * </label> : null}
            <Select
                {...rest}
                id={id}
                className={className}
                showSearch
                defaultActiveFirstOption
                defaultValue={defaultValue}
                placeholder={placeholder}
                maxTagCount={4}
                style={{ width: width, margin: margin }}
                onChange={(value) => onChange(name, value)}
                filterOption={(input, option) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {rest.mode === 'multiple' && <Select.Option key="all" value="all" >Select All</Select.Option>}
                {
                    items &&
                    items.length > 0 &&
                    items.map(item => (
                        <Select.Option key={item.id} value={item.id} >
                            {item.name}
                        </Select.Option>
                    ))
                }
            </Select>
        </>
    );
}
export default SelectOnChange;