import * as React from "react";
import styles from "./styles/SupportContent.module.scss";
import SupportForm from "../forms/SupportForm";
import { ISupportProps } from "../forms/interfaces/ISupportProps";
import { PopUpModal } from "../../../components/popUpModal/PopUpModal";
import { resources } from "../../../common/Resources";
import { Typography } from "antd";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";

const { Text } = Typography;

export class SupportContent extends React.Component<ISupportProps> {
  state = {
    visible: false,
  };

  toggleModal = (): void => {
    this.setState({ visible: !this.state.visible });
  };

  public render() {
    // const { account_manager } = this.props;
    return (
      <>
        <ViewHeader title={resources.supportView.support} />
        <p className={`h5 ${styles.para}`}>
          {resources.supportView.ifYouNeedHelp}
        </p>
        {/* {account_manager &&
                        <Descriptions title={account_manager.name} column={1} size='small'>
                            {account_manager.skype && <Descriptions.Item label="Skype">{account_manager.skype}</Descriptions.Item>}
                            {account_manager.telegram && <Descriptions.Item label="Telegram">{account_manager.telegram}</Descriptions.Item>}
                            {account_manager.email && <Descriptions.Item label="Email">{account_manager.email}</Descriptions.Item>}
                        </Descriptions>
                    } */}
        <Text copyable={{ text: "info@affbay.asia" }}>info@affbay.asia</Text>
        <SupportForm toggleModal={this.toggleModal} />
        <PopUpModal visible={this.state.visible} onCancel={this.toggleModal}>
          <h6>{resources.landingPage.thankYou}</h6>
          <p>{resources.landingPage.youWillBeHearing}</p>
        </PopUpModal>
      </>
    );
  }
}
