import * as serviceWorker from "./serviceWorker";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { history } from "../src/helpers/History";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { store } from "./store";
import "./index.scss";
import ScrollToTop from "./components/common/ScrollToTop";

const root = document.getElementById("root");

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Provider>
  </Router>,
  root
);

serviceWorker.unregister();
