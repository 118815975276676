import * as React from 'react';
import styles from './styles/ChangeMultipleStatusesDialog.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Progress } from 'antd';
import { IChangeMultipleStatusesDialog } from './interfaces/IChangeMultipleStatusesDialog';
import { IHideModal } from '../../../../../common/interfaces/IHideModal';

interface State {
    isConfirmed: boolean;
    successes: number;
    fails: Array<number>;
    comment: string;
}

export class ChangeMultipleStatusesDialog extends React.Component<IChangeMultipleStatusesDialog & IHideModal, State> {

    state = {
        isConfirmed: false,
        successes: 0,
        fails: Array<number>(),
        comment: '',
    }

    confirmHandler = () => {
        this.setState({
            isConfirmed: true,
        });
        this.props.ordersIds.forEach(async (orderId: number) => {
            await this.props.onStatusChange(orderId, this.props.targetStatusId, this.state.comment)
            .then((res) => {
                const orderResponse = res.data.find((el: any) => el.order_id === orderId);
                if (orderResponse && orderResponse.success) {
                    this.setState((prevState => ({
                        ...prevState,
                        successes: prevState.successes + 1,
                    })));
                }
                else {
                    throw new Error('update failed')
                }
            }).catch(() => {
                this.setState(prevState => {
                    const newFails = prevState.fails;
                    newFails.push(orderId);
                    return {
                    ...prevState,
                    fails: newFails,
                }});
            });
        })
    }

    cancelHandler = () => {
        this.props.hideModal && this.props.hideModal();
    }

    render() {
        const { isConfirmed, successes, fails } = this.state;
        const { ordersIds } = this.props;

        if (!isConfirmed) {
            return (
                <div className={styles.container}>
                    <p>Orders to change: {ordersIds.length}. Are you sure?</p>
                    <TextArea
                        placeholder="Comment..."
                        className={styles.commentContainer}
                        onChange={(e:any) => this.setState({ comment: e.target.value})}
                    />
                    <div className={styles.buttonContainer}>
                        <Button className={styles.cancelButton} onClick={this.cancelHandler}>Cancel</Button>
                        <Button className={styles.confirmButton} onClick={this.confirmHandler}>Confirm</Button>
                    </div>
                </div>
            );
        }

        const isCompleted = fails.length + successes === this.props.ordersIds.length;
        return (
            <div className={styles.container}>
                <div className={styles.progressBar}>
                    <Progress
                        type='line'
                        format={() => `${successes + fails.length} / ${this.props.ordersIds.length}`}
                        percent={(successes + fails.length) / this.props.ordersIds.length * 100}
                        successPercent={successes / this.props.ordersIds.length * 100}
                    />
                </div>
                <div>
                    <p>Success: {successes}</p>
                    <p>Fails: {fails.length}</p>
                </div>
                <div className={styles.buttonContainer}>
                    {isCompleted && fails.length > 0 && <Button className={styles.confirmButton} >Show failed</Button>}
                    {isCompleted && <Button className={styles.cancelButton} onClick={this.cancelHandler}>Close</Button>}
                </div>
            </div>
        )
    }
};