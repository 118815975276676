import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { SalesChannelsDictionaryContent } from './contents/SalesChannelsDictionaryContent';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class SalesChannelsDictionaryViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.sales_channels_dictionary);
    }

    render() {
        return (
            <SalesChannelsDictionaryContent />
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const SalesChannelsDictionaryView: React.ComponentClass = connect(null, mapDispatchToProps)(SalesChannelsDictionaryViewInner);