import * as React from 'react';
import { Button } from 'antd';
import { IRefreshTableButtonProps } from './interfaces/IRefreshTableButtonProps';

export const UploadButton = (props: IRefreshTableButtonProps) => {
    return (
        <Button
            type="primary"
            className={props.className}
            loading={props.loading}
            onClick={props.onClick}
        >
            {props.buttonTitle ? props.buttonTitle : 'Upload'}
        </Button>
    );
}