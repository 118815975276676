import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button } from 'antd';
import { resources } from '../../../../common/Resources';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { Tooltip } from 'antd';
import P2Form from '../forms/ResaleForm';


const { Column } = Table;

export class ResalesTable extends DataTable {


    render() {
        const columns = [
            <Column
                title={resources.columnTitle.product}
                dataIndex='name'
                key='name'
                align='center'
            />,
            <Column
                title={'Female product'}
                dataIndex='femaleProduct'
                key='femaleProduct'
                align='center'
            />,
            <Column
                title={'Female campaign'}
                dataIndex='femaleCampaign'
                key='femaleCampaign'
                align='center'
            />,
            <Column
                title={'Male product'}
                dataIndex='maleProduct'
                key='maleProduct'
                align='center'
            />,
            <Column
                title={'Female campaign'}
                dataIndex='maleCampaign'
                key='maleCampaign'
                align='center'
            />,
            <Column
                title={'Token'}
                key='partner_token'
                align='center'
                width={350}
                render={(record) => (<>{record.partner_token ? record.partner_token : 'not set'}</>)}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                align='center'
                width={'100px'}
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.SalesP2View}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.edit}
                                buttonTooltip={resources.modalButtonsTitle.edit}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={800}
                            >
                                <P2Form
                                    campaigns={this.props.campaigns}
                                    product={record}
                                    submit={this.props.postConfig}
                                    products={this.props.allProducts}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.SalesP2View}>
                            <Tooltip
                                placement="rightBottom"
                                title={record.is_active ? resources.buttons.disable : resources.buttons.enable}
                            >
                                <Button
                                    value={record.id}
                                    onClick={() => this.props.toogleResale(record)}
                                    icon={!record.is_active ? 'close' : 'check'}
                                    style={{ color: !record.is_active ? 'red' : 'green' }}
                                    disabled={!record.isValid && !record.is_active}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        let sortedData = this.props.data ? this.props.data.sort((a, b) => (a.id < b.id ? 1 : -1)) : [];
        sortedData = sortedData.map(d => {
            let isValid = true;
            let femaleProduct = { name: 'not set' };
            if (d.female_product_id) {
                femaleProduct = this.props.allProducts.find(p => p.newId === d.female_product_id);
            }
            let femaleCampaign = { name: 'not set' };
            if (d.female_campaign_id) {
                femaleCampaign = this.props.campaigns.find(c => c.id === d.female_campaign_id);
            }
            let maleProduct = { name: 'not set' };
            if (d.male_product_id) {
                maleProduct = this.props.allProducts.find(p => p.newId === d.male_product_id);
            }
            let maleCampaign = { name: 'not set' };
            if (d.male_campaign_id) {
                maleCampaign = this.props.campaigns.find(c => c.id === d.male_campaign_id);
            }
            if (!d.female_product_id ||
                !d.female_campaign_id ||
                !d.male_product_id ||
                !d.male_campaign_id ||
                !femaleProduct ||
                !femaleCampaign ||
                !maleProduct ||
                !maleCampaign) {
                isValid = false;
            }
            return {
                ...d,
                femaleProduct: femaleProduct ? femaleProduct.name : 'incorrect',
                femaleCampaign: femaleCampaign ? femaleCampaign.name : 'incorrect',
                maleProduct: maleProduct ? maleProduct.name : 'incorrect',
                maleCampaign: maleCampaign ? maleCampaign.name : 'incorrect',
                key: d.newId,
                isValid,
            }
        });

        return (
            <DataTable
                tableName='ProductsTable'
                columns={columns}
                data={sortedData}
                loading={this.props.loading}
                pagination={false}
                defaultPageSize={100}
            />
        )
    }
}