import * as React from 'react';
import moment from 'moment';
import styles from './styles/StatusesHistory.module.scss';
import { IStatusesHistory } from './interfaces/IStatusesHistory';
import { statusIdToStatus } from '../../../../../helpers/OrderStatusHelper';
import { Tooltip } from 'antd';

// bit hacky - hardcoded IDs of statuses
enum STATUSES {
    CANCEL = 18,
    DELETE = 17,
}


export class StatusesHistory extends React.Component<IStatusesHistory> {

    render() {
        const { statuses, possibleStatuses } = this.props;
        const possibleStatusesWithoutCancelDelete = possibleStatuses
            .filter(status => !(status.id === STATUSES.CANCEL || status.id === STATUSES.DELETE));
        return (
            <div className={styles.container}>
                {statuses.map((status, index) => {
                    const statusName = statusIdToStatus(status.status_id).name;
                    return (
                        <div key={status.id} className={index === statuses.length - 1 ? styles.currStatus : styles.prevStatus}>
                            <Tooltip
                                title={`Comment: ${status.comment !== null ? status.comment : 'No comment' }`}
                            >
                                <div className={styles.label}>
                                    {statusName}
                                </div>
                                <div className={styles.commonText}>
                                    {moment(status.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                </div>
                                <div className={styles.commonText}>
                                    {status[status.user_type].name}
                                </div>
                            </Tooltip>
                        </div>
                    )
                })}
                {possibleStatusesWithoutCancelDelete.length > 0 &&
                    <div className={styles.arrow}>
                        <div>&rarr;</div>
                    </div>
                }
                {possibleStatusesWithoutCancelDelete.map((status) => {
                    return (
                        <div key={status.id} className={styles.nextStatus}>
                            <div className={styles.label}>
                                {status.name}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
};