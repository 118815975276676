import React from 'react';
import styles from '../styles/PostbackUrlForm.module.scss';
import TextInputF from '../../../../../components/templateForm/form/textInput/TextInputF';
import { Button, Select } from 'antd';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  FormikBag,
  FormikErrors,
  FormikProps,
  withFormik
} from 'formik';
import {
  IBasicUrlFormValues,
  IChangeCurrentPostbackUrl,
  IChangePostbackFormMode,
  IPostbackUrlForm
} from '../interfaces/IPostbackUrlForm';
import { IHideModal } from '../../../../../common/interfaces/IHideModal';
import { IParam } from '../interfaces/IPostbackUrlForm';
import { IPostbackConfig } from '../../interfaces/IPostbackSettingsState';
import { resources } from '../../../../../common/Resources';
import { validateParams, validatePath, validateProtocol } from '../../../../../helpers/PostbackUrlValidation';
import {
  openAlertNotification,
  openErrorNotification,
  openSuccessNotification,
} from '../../../../../helpers/NotificationHelper';

const { Option, OptGroup } = Select;

const systemVariables = [
  { name: 'Comment', value: '{comment}' },
  { name: 'click_id', value: '{click_id}' },
  { name: 'Public Contact', value: '{pub_id}' },
  { name: 'Lead ID (Contact ID)', value: '{contact_id}' },
  { name: 'Affiliate ID', value: '{aff_id}' },
  { name: 'Payout', value: '{payout}' },
  { name: 'Scheme', value: '{scheme}' },
  { name: 'IsQualified', value: '{is_qualified}' }
];

let error = undefined as string | undefined;

class PostbackUrlContentTemplate extends React.Component<IPostbackUrlForm &
  IChangePostbackFormMode &
  IChangeCurrentPostbackUrl &
  FormikProps<IBasicUrlFormValues>> {

  state = {
    newVariable: {
      name: '',
      value: '',
    },
  }

  componentDidMount = () => {
    if (this.props.wasChanged) {
      const values = { ...this.props.values };
      values.random = Math.random();
      this.props.setValues(values);
    }
  }

  addNewVariable = (value: string) => {
    if (value !== '') {
      const newVariable = {
        value: value,
        name: value,
      }
      this.setState({
        newVariable,
      });
    }
  }

  customVariableSelect = (props: FieldProps<any>) => {
    const { newVariable } = this.state;
    const { customVariables } = this.props;
    let counter = 0;
    return (<Select
      {...props.field}
      onChange={(value: string) => {
        props.form.setFieldValue(props.field.name, value)
        props.form.setFieldTouched(props.field.name);
      }}
      value={props.field.value}
      onSearch={this.addNewVariable}
      showSearch
    >
      <OptGroup key='system_variables' label={resources.labels.systemVariables}>
        {systemVariables.map(item => {
          return (
            <Option key={counter++} value={item.value}>{item.name}</Option>
          )
        })}
      </OptGroup>
      {customVariables.length > 0 &&
        <OptGroup key='custom_variable' label={resources.labels.customVariables}>
          {customVariables.map((customVariable: string) => (
            <Option key={counter++} value={`{${customVariable}}`}>{customVariable}</Option>
          ))}
        </OptGroup>
      }
      {newVariable && newVariable.name !== '' &&
        <OptGroup key='new_variable' label={resources.labels.newVariable}>
          <Option key={counter++} value={newVariable!.value}>{newVariable!.name}</Option>
        </OptGroup>
      }
    </Select>);
  }

  customStatusSelect = (props: FieldProps<any>) => {
    return (
      <Select
        {...props.field}
        onChange={(value: string) => {
          props.form.setFieldValue(props.field.name, value)
          props.form.setFieldTouched(props.field.name);
        }}
        value={props.field.value}
        disabled={!this.props.canStatusChange}
      >
        <Option key={-1} value={''}>{String.fromCharCode(160)}</Option>
        {this.props.statuses.map((postbackConfig: IPostbackConfig) => (
          <Option key={postbackConfig.id} value={postbackConfig.type}>{postbackConfig.name}</Option>
        ))}
      </Select>
    )
  }

  customProtocleSelect = (props: FieldProps<any>) => {
    return (
      <Select
        {...props.field}
        onChange={(value: string) => {
          props.form.setFieldValue(props.field.name, value)
          props.form.setFieldTouched(props.field.name);
        }}
        value={props.field.value}
      >
        <Option key='https' value='https:'>HTTPS</Option>
        <Option key='http' value='http:'>HTTP</Option>
      </Select>
    )
  }

  generateParamError = (props: any, index: number) => {
    const { errors } = props;
    if (errors.parameters
      && errors.parameters[index]) {
      if (errors.parameters[index].parameterName) {
        return (<div style={{ color: 'red' }}>{errors.parameters[index].parameterName}</div>);
      }
      else if (errors.parameters[index].value) {
        return (<div style={{ color: 'red' }}>{errors.parameters[index].value}</div>);
      }
    }
  }

  render() {
    const { errors, touched } = this.props;
    const url = generateUrl({ ...this.props.values });
    const submitButtonStyle = this.props.isValid ? styles.green : styles.gray;
    return (
      <div className={styles.grid}>
        <h3 className={`h3 ${styles.title}`}>
          {this.props.postbackId ? resources.offersDetails.updatePostback : resources.offersDetails.createPostback}
        </h3>
        {!this.props.postbackId ? <p className={`h6 ${styles.subtitle}`}>{resources.offersDetails.fillInTheData}</p> : null}
        <Form>
          <section className={styles.grid}>
            {this.props.canStatusChange ?
              <div className={`${styles.row}`}>
                <div className={`${styles.formItem}`}>
                  <label className={`h6`} htmlFor="">{resources.labels.status}</label>
                  <div className='customAnt--userEditForm'>
                    <Field
                      name='status'
                      component={this.customStatusSelect}
                    />
                    {errors.status && touched.status && <div style={{ color: 'red' }}>{errors.status}</div>}
                  </div>
                </div>
                <div className={styles.dividerLine}></div>
              </div> : null}
            <div className={`${styles.row} ${styles.firstRowGrid}`}>
              <div className={`${styles.formItem}`}>
                <label className={`h6`} htmlFor="">{resources.offersDetails.protocol}</label>
                <div className='customAnt--userEditForm'>
                  <Field
                    name='protocol'
                    component={this.customProtocleSelect}
                  />
                  {errors.protocol && touched.protocol && <div style={{ color: 'red' }}>{errors.protocol}</div>}
                </div>
              </div>
              <div className={`${styles.formItem}`}>
                <label className={`h6`} htmlFor="path">
                  {resources.offersDetails.domain}
                </label>
                <TextInputF
                  {...this.props}
                  name='path'
                  placeholder='example.com'
                />
              </div>
            </div>
            <div className={`${styles.row} ${styles.secondRowGrid}`}>
              <div className={styles.secondRowHeaders}>
                <label className={`h6`}>
                  {resources.offersDetails.parameter}
                </label>
              </div>
              <FieldArray
                name='parameters'
                render={arrayHelpers => (
                  <div className={styles.secondRowGrid__form}>
                    <div className={styles.inputs}>
                      {this.props.values.parameters && this.props.values.parameters.map((parameter: IParam, index: number) => {
                        return (
                          <div key={index}>
                            <div className={styles.inputsGrid}>
                              <div className={styles.formItem}>
                                <div className='customAnt--userEditForm'>
                                  <Field
                                    name={`parameters.${index}.parameterName`}
                                    placeholder='Parameter name'
                                  />
                                </div>
                              </div>
                              <div className={`${styles.customSelect} ${styles.formItemDelete}`}>
                                <div className='customAnt--userEditForm'>
                                  <Field
                                    name={`parameters.${index}.value`}
                                    component={this.customVariableSelect}
                                  />
                                </div>
                                <Button
                                  icon='delete'
                                  className={styles.deleteBtn}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                </Button>
                              </div>
                            </div>
                            {this.generateParamError(this.props, index)}
                          </div>
                        )
                      })}
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push({
                          id: this.props.values.parameters.length + 1,
                          name: '',
                          value: '',
                          parameterName: ''
                        })}
                        className={styles.addNewBtn}
                      >
                        {resources.offersDetails.addNew} +
                      </button>
                    </div>
                  </div>)}
              />
            </div>
            <div className={`${styles.row} ${styles.thirdRowGrid}`}>
              <div className={styles.fakeFormItem}>
                <h6 className={`h6 ${styles.label}`}>
                  {resources.offersDetails.postbackURLPreview}
                </h6>
                <div className={styles.fakeInput} style={{ height: 100 }}>{url}</div>
              </div>
            </div>
            <div className={styles.customError}>
              {error}
            </div>
            <div className={styles.btnContainer}>
              <button
                type='submit'
                className={`${styles.submitBtn}  ${submitButtonStyle}`}
                disabled={!this.props.isValid}
              >
                {resources.offersDetails.save}
              </button>
              <div
                className={`${styles.changeModeBtn}  ${styles.green}`}
                onClick={this.props.onModeChange}
              >
                {resources.offersDetails.advanced}
              </div>
            </div>
          </section>
        </Form>
      </div >
    );
  };
}

const BasicUrlForm = withFormik<IPostbackUrlForm & IChangePostbackFormMode & IChangeCurrentPostbackUrl, IBasicUrlFormValues>({
  enableReinitialize: false,

  mapPropsToValues: (props: IPostbackUrlForm): IBasicUrlFormValues => {
    const urlInfo = getUrlInformations(props.postbackUrl);
    let path = '';
    if (urlInfo.host !== '') {
      path += urlInfo.host;
    }
    if (urlInfo.pathname !== '') {
      path = `${path}${urlInfo.pathname}`;
    }
    return {
      path,
      protocol: urlInfo.protocol,
      parameters: urlInfo.parameters,
      status: props.status ? props.status : '',
      random: Math.random(),
    };
  },

  validate: (values: IBasicUrlFormValues, props: IChangeCurrentPostbackUrl): FormikErrors<IBasicUrlFormValues> => {
    let errors: FormikErrors<IBasicUrlFormValues> = {};
    //:FIXME validation temporarily disabled
    if (values.path === '') {
      return errors;
    }
    const protocolError = validateProtocol(values.protocol);
    if (protocolError) {
      errors.protocol = protocolError;
    }
    const pathError = validatePath(values.path);
    if (pathError) {
      errors.path = pathError;
    }
    const paramsError = validateParams(values.parameters);
    if (paramsError) {
      errors.parameters = paramsError;
    }
    const statusError = validateStatus(values.status);
    if (statusError) {
      errors.status = statusError;
    }
    const url = generateUrl(values);
    props.onPostbackUrlChange(url);
    if (Object.keys(errors).length > 0) {
      // errors.postbackUrl = resources.validation.invalidUrl;
      error = resources.validation.customInvalidUrlError;
    }
    else {
      error = undefined;
    }
    errors = {};
    return errors;
  },

  handleSubmit: (values: IBasicUrlFormValues, bag: FormikBag<IPostbackUrlForm & IHideModal, IBasicUrlFormValues>) => {
    const url = generateUrl(values)

    if (bag.props.onUpsert) {
      bag.props.onUpsert(url, values.status, bag.props.postbackId)
        .then(() => {
          bag.props.hideModal && bag.props.hideModal();
          openSuccessNotification(resources.offersDetails.postbackSavedSuccessfully);
        })
        .catch((err: any) => {
          const { status } = err.response;
          if (status === 400) {
            // postback saved with error
            bag.props.hideModal && bag.props.hideModal();
            openAlertNotification(resources.offersDetails.postbackSavedWithWarning);
          }
          else if (status === 403) {
            // unauthorized
            openErrorNotification(resources.offersDetails.postbackNotSaved);
          }
          else if (status === 422) {
            // postback not saved
            openErrorNotification(resources.offersDetails.postbackNotSaved);
          }
          else {
            openErrorNotification(err.response.message);
          }
        });
    }

  }

})(PostbackUrlContentTemplate);

export default BasicUrlForm;

export const getUrlInformations = (postbackUrl: string) => {
  let parameters: IParam[] = [];
  const urlInfo = {
    protocol: '',
    host: '',
    pathname: '',
    parameters
  };
  if (validURL(postbackUrl)) {
    let urlInf;
    try {
      urlInf = new URL(postbackUrl);
    }
    catch {
      urlInf = {
        search: '',
        protocol: '',
        host: '',
        pathname: '',
      };
    }
    parameters = getUrlParams(urlInf.search);

    urlInfo.protocol = urlInf.protocol;
    urlInfo.host = urlInf.host;
    urlInfo.pathname = urlInf.pathname;
    urlInfo.parameters = parameters;
  }
  return urlInfo;
}

const validURL = (url: string): boolean => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'  // OR ip (v4) address
  , 'i'
  );

  return !!pattern.test(url);
}

const getUrlParams = (search: string): IParam[] => {
  let counter = 1;
  let params: IParam[] = [];

  const searchString = search.slice(search.indexOf('?') + 1)

  if (searchString === '') {
    return params;
  }

  const hashes = searchString.split('&');
  hashes.map(hash => {
    const [key, val] = hash.split('=');

    params = [...params, { id: counter++, name: key, value: val, parameterName: key }];

    return null;
  });

  return params;
}

const generateUrl = (urlInfo: IBasicUrlFormValues): string => {
  let url = '';
  if (urlInfo.path === '') {
    return '';
  }
  if (urlInfo.protocol) {
    url = url + urlInfo.protocol + '//';
  }
  if (urlInfo.path) {
    url = url + urlInfo.path;
  }
  if (urlInfo.parameters.length > 0) {
    let first = true;

    urlInfo.parameters.map((item: IParam) => {
      if (first) {
        if (item.parameterName !== '' && item.value !== '') {
          url = `${url}?${item.parameterName}=${item.value}`;
          first = false;
        }
      }
      else {
        if (item.parameterName !== '' && item.value !== '')
          url = `${url}&${item.parameterName}=${item.value}`
      }

      return null;
    });
  }

  return url;
}

const validateStatus = (status: string): string | null => {
  if (status === '') {
    return resources.validation.required;
  }
  return null;
}

