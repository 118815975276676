import * as React from 'react';
import { Button } from 'antd';
import { IMegaUpdateButtonProps } from './interfaces/IMegaUpdateButtonProps';

export const MegaUpdateButton = (props: IMegaUpdateButtonProps) => {
    return (
        <Button
            type="danger"
            className={props.className}
            loading={props.loading}
            onClick={props.onClick}
        >
            {props.buttonTitle ? props.buttonTitle : 'Upload'}
        </Button>
    );
}