import * as React from 'react';
import styles from './styles/InProgressView.module.scss';
import { Button, Result } from 'antd';
import { connect } from 'react-redux';
import { resources } from '../../common/Resources';
import { RouteComponentProps, withRouter } from 'react-router';

class InProgressViewInner extends React.Component<RouteComponentProps> {

    public render() {
        return (
            <Result
                status="500"
                title={resources.title.inProgressTitle}
                subTitle={resources.title.inProgressDesc}
                extra={<Button
                    type='primary'
                    className={styles.btn}
                    onClick={() => this.props.history.replace('/')}>
                    {resources.buttons.goToMainPage}
                </Button>}
            />
        )
    };
}

export const InProgressView: React.ComponentClass = connect(null, null)(withRouter(InProgressViewInner));
