import React from "react";
import { Select } from "antd";

import { timezones } from "../../../../../common/dictionaries/Timezones";
import { UPDATE_FILTERS, useFilters } from "../../../lib/filters-state";

export const TimezoneFilter = () => {
  const { state, dispatch } = useFilters();
  return (
    <Select
      size="small"
      value={state.timezone}
      onChange={(value: number) =>
        dispatch({
          type: UPDATE_FILTERS,
          payload: { timezone: value },
        })
      }
      className="timezone-filter"
      dropdownClassName="timezone-filter-options"
    >
      {timezones.map((timezone) => (
        <Select.Option key={timezone.id} value={timezone.id}>
          {timezone.name}
        </Select.Option>
      ))}
    </Select>
  );
};
