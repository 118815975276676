import React from 'react';
import { Table, Icon, Tooltip } from 'antd';
import { DataTable } from '../../../../components/others/data/DataTable';
import styles from './styles/TargetUrlTable.module.scss';
import { decodeLandingName } from '../../../../helpers/Encoders';
import { resources } from '../../../../common/Resources';
import { copyClipboard } from '../../../../helpers/CopyHelpers';
import { findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';

const { Column } = Table;

export class TargetUrlTable extends DataTable {
    state = {
        addParamsVisible: false,
        deleteVisible: false
    }

    toggleAddParamsVisible = () =>
        this.setState({ addParamsVisible: !this.state.addParamsVisible });

    toggleDeleteVisible = () =>
        this.setState({ deleteVisible: !this.state.deleteVisible });

    render() {
        const columns = [
            <Column
                title={resources.offersDetails.name}
                dataIndex='name'
                key='name'
                width='90px'
                align='center'
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
            />,
            <Column
                title={resources.offersDetails.prelanding}
                key='prelanding'
                width='90px'
                align='center'
                render={(record) => {
                    return record.pre.map(item => <div key={item}>{findInDictionaryAndReturnValue(item, this.props.pagesList)}</div>);
                }}
            />,
            <Column
                title={resources.offersDetails.landing}
                key='landing'
                width='90px'
                align='center'
                render={(record) => {
                    return record.lp.map(item => <div key={item}>{findInDictionaryAndReturnValue(item, this.props.pagesList)}</div>);
                }}
            />,
            <Column
                title={resources.offersDetails.URL}
                key='url'
                width='60px'
                align='center'
                render={(record) => (
                    <Tooltip title={record.url}>
                        {record.url !== null && record.url.length > 24 ? record.url.substring(0, 20) + '...' : record.url}
                        <Icon type="copy" style={{ fontSize: 20 }} onClick={(event) => copyClipboard(event, record.url)} />
                    </Tooltip>
                )}
            />
        ];

        this.props.active && columns.push(
            <Column
                title=''
                key='actions'
                width='20px'
                align='center'
                render={(record) => {
                    return (
                        <div className={`customAnt--moreOptsIcon`}>
                            <Tooltip title={resources.tooltips.delete}>
                                <Icon type="delete" style={{ fontSize: 20 }} onClick={() => this.props.deleteTargetUrlCampaigns(record.id)} />
                            </Tooltip>
                        </div>
                    )
                }}
            />
        );

        return (
            <section className={`customAnt--productDetailsTable ${styles.grid}`}>
                {/* <h6 className={`h6__bold ${styles.header}`}>{resources.offersDetails.allProductCampaigns}</h6> */}
                <div className={styles.tableWidth}>
                    <DataTable
                        columns={columns}
                        data={this.props.data}
                        loading={this.props.loading}
                        pagination={false}
                    />
                </div>
            </section>
        )
    }

    displayList = (list, array) => {
        const result = <> {list.map(item => { return <>{decodeLandingName(item, array)}</> })}</>

        return result;
    }
}