import * as React from 'react';
import { getLanguageVersion } from '../../../../helpers/LocaleHelper';
import { ILoading } from '../../../../common/interfaces/ILoading';
import { INews } from '../../../news/newsAdmin/interfaces/INews';
import { List } from 'antd';
import { SingleNews } from '../../../news/news/singleNews/SingleNews';
import '../../styles/NewsListStyle.css';

interface INewsListProps {
    data?: INews[];
    pagination?: boolean;
    pageSize?: number;
}
export class NewsList extends React.Component<INewsListProps & ILoading> {
    public render() {
        return (
            <div className={'customAnt--newsList'}>
                <List
                    grid={{ gutter: 20, column: 2 }}
                    loading={this.props.loading}
                    dataSource={this.props.data}
                    pagination={(this.props.pagination === false) ? false : {
                        defaultPageSize: this.props.pageSize ? this.props.pageSize : 6
                    }}
                    renderItem={item => (
                        <List.Item>
                            <SingleNews
                                key={item.id}
                                color={this.generateStyle(item.id_news_type)}
                                id_news_type={item.id_news_type}
                                date_from={item.date_from}
                                title={getLanguageVersion() === 1 ? item.title_en : item.title_ru}
                                body={getLanguageVersion() === 1 ? item.body_en : item.body_ru}
                            />
                        </List.Item>
                    )}
                />
            </div>
        )
    };

    generateStyle = (status: number): string => {
        if (status === 1)
            return '#29C05C';
        if (status === 2)
            return '#FBBC06';

        return '#2C50ED';
    }
}