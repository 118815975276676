import * as React from 'react';
import styles from './styles/QrStatus.module.scss';
import { IQrStatus } from './interfaces/IQrStatus';

export class QrStatus extends React.Component<IQrStatus> {

    render() {
        let printedLabel = this.props.printed ?
            <div className={styles.greenLabel}>
                <label>printed</label>
            </div>
            : <div className={styles.redLabel}>
                <label>not printed</label>
            </div>;
        let scanedLabel = this.props.scaned ?
            <div className={styles.greenLabel}>
                <label>scanned</label>
            </div>
            : <div className={styles.redLabel}>
                <label>not scanned</label>
            </div>;
        return (
            <div className={styles.container}>
                {printedLabel}
                {scanedLabel}
            </div>
        )
    }
};