import * as React from 'react';
import styles from './styles/RoleSelector.module.scss';
import { getDictionary } from '../../../../../helpers/DisctionariesHelper';
import { getRolesListD } from '../../../../../services/m29_users_management/RoleService';
import { IDictionaryValue } from '../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { Select } from 'antd';

const { Option } = Select;

interface RoleSelectorProps {
    onRoleSelected: (usersIds: Array<number>) => void;
    onCountrySelected: (countriesIds: Array<number>) => void;
}

export class RoleSelector extends React.Component<RoleSelectorProps> {
    state = {
        loading: true,
        countries: [] as Array<IDictionaryValue>,
        roles: [] as Array<IDictionaryValue>,
    }

    componentDidMount = async () => {
        const countries = getDictionary('countriesNameD');
        const roles = await getRolesListD({
            columns: [
                "id",
                "name",
                "description",
                "is_active",
                "created_at",
                "updated_at",
            ],
            is_active: true,
        });

        this.setState({
            countries,
            loading: false,
            roles,
        })
    }

    public render() {
        const { countries, roles } = this.state;
        return (
            <div className={styles.container}>
                <div className={styles.selectContainer}>
                    <p>Selected roles</p>
                    <Select
                        mode="multiple"
                        labelInValue
                        placeholder="Select role"
                        onChange={(selectedElements: any) => this.props.onRoleSelected(
                            selectedElements.map((el: any) => el.key)
                        )}
                        style={{ width: 300 }}
                    >
                        {roles.map((role: IDictionaryValue) =>
                            <Option key={role.id} value={role.id}>{role.name}</Option>
                        )}
                    </Select>
                </div>
                <div className={styles.selectContainer}>
                    <p>Selected countries</p>
                    <Select
                        mode="multiple"
                        labelInValue
                        placeholder="Select countries"
                        onChange={(selectedElements: any) => this.props.onCountrySelected(
                            selectedElements.map((el: any) => el.key)
                        )}
                        style={{ width: 300 }}
                    >
                        {countries.map((country: IDictionaryValue) =>
                            <Option key={country.id} value={country.id}>{country.name}</Option>
                        )}
                    </Select>
                </div>
            </div>
        )
    };
}