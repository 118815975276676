import * as React from 'react';
import img from '../../common/images/success.svg';
import styles from './styles/PopUpModal.module.scss';
import { IPopUpModalMethods } from './interfaces/IPopUpModalMethods';
import { IPopUpModalProps } from './interfaces/IPopUpModalProps';
import { Modal } from 'antd';

const bodyStyle = {
    width: '100%',
    background: '#e5e5e5',
    padding: '40px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center'
}

export class PopUpModal extends React.Component<IPopUpModalProps & IPopUpModalMethods> {
    render() {
        return (
            <Modal
                centered={true}
                footer={null}
                bodyStyle={bodyStyle}
                width={'380px'}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
            >
                <img className={styles.img} src={img} alt="success icon" />
                <section className={styles.children}>
                    {this.props.children}
                </section>
            </Modal>

        );
    }
}