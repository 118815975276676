import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { resourceDef } from '../../common/ResourceDef';

const permissionsUrl = createGatewayAbsoluteUrl(resourceDef.Permissions);

export const getPermissionsInf = (obj?: any) => axios.post(permissionsUrl, { ...obj })
    .then(response => { return response.data; })
    .catch(error => { displayHttpStatusCodeInformation(error, 'getPermissions M29'); });

export const getPermissionsForRole = (role_id: number, obj?: any) => axios.post(permissionsUrl + `/${role_id}`, { ...obj })
    .then(response => { return response.data; })
    .catch(error => { displayHttpStatusCodeInformation(error, 'getPermissionsForRole M29'); });

export const updateRolePermission = (role_id: number, obj: any) => axios.patch(permissionsUrl + `/${role_id}`, { ...obj })
    .then(() => { return true; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updatePermission M29');
        return false;
    });
