import DictionaryF from '../../../../../components/templateForm/form/dropDown/DictionaryF';
import React, { Fragment } from 'react';
import styles from '../../../contents/styles/CreateNewOrderContent.module.scss';
import TextInputF from '../../../../../components/templateForm/form/textInput/TextInputF';
import { FormikProps } from 'formik';
import { ICustomerInformation } from './ICustomerInformation';

export default class CustomerInformation extends React.Component<FormikProps<ICustomerInformation>> {

    render() {
        return (
            <Fragment>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='name'
                            label={'Name'}
                            required
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <DictionaryF
                            {...this.props}
                            name='sex'
                            label={'Sex'}
                            required
                            dictionaryValues={[{ id: 1, name: 'Male' }, { id: 2, name: 'Female' }]}
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='phone'
                            label={'Phone'}
                            required
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='phone2'
                            label={'Phone 2'}
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='email'
                            label={'Email'}
                            required
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='age'
                            label={'Age'}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}