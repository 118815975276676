import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const updateUrl = createGatewayAbsoluteUrl(resourceDef.MegaUpdate);

export const megaUpdate = () => axios.post(updateUrl)
    .then(() => true)
    .catch(() => {
        openErrorNotification('megaUpdate');
        return false;
    });