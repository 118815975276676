import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createInstance } from '../../../../services/InstancesService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { ICallCenterInstances } from '../../../../models/ICallCenterInstances';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IInstanceFormProps as IProps } from '../interfaces/IInstanceFormProps';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class TemplateInstanceForm extends React.Component<IProps & IHideModal & IAddObjToArray & FormikProps<ICallCenterInstances>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        allowOfflineSearch: false,
        allowClear: true,
        labelTextAlignLeft: true,
        boldLabel: true,
        required: true
    }

    public render() {
        return (
            <Form>
                <TextInputF
                    {...this.props}
                    name="type"
                    label={resources.labels.type}
                />
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                />
                <TextInputF
                    {...this.props}
                    name="domain"
                    label={resources.labels.domain}
                />
                <TextInputF
                    {...this.props}
                    name="user"
                    label={resources.labels.user}
                />
                <TextInputF
                    {...this.props}
                    name="key"
                    label={resources.labels.password}
                />
                <TextInputF
                    {...this.props}
                    name="base_url"
                    label={resources.labels.baseUrl}
                />
                <DictionaryF
                    {...this.props}
                    name="country_id"
                    label={resources.labels.country}
                    dictionaryValues={this.props.countries}
                />
                <DictionaryF
                    {...this.props}
                    name="is_active"
                    label={resources.labels.isActive}
                    dictionaryValues={getBooleanValues()}
                />
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {resources.buttons.create}
                </Button>
            </Form>
        );
    };
}

const InstanceForm = withFormik<IProps & IAddObjToArray, ICallCenterInstances>({
    mapPropsToValues: () => {
        return {
            type: undefined,
            name: undefined,
            domain: undefined,
            user: undefined,
            key: undefined,
            base_url: undefined,
            country_id: undefined,
            is_active: undefined
        };
    },

    validate: (values: ICallCenterInstances) => {
        const errors: FormikErrors<ICallCenterInstances> = {};

        if (!values.type) {
            errors.type = resources.validation.required;
        }
        else if (values.type && values.type.length > 20) {
            errors.type = resources.validation.requiredMax20Characters;
        }
        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name && values.name.length > 50) {
            errors.name = resources.validation.requiredMax50Characters;
        }
        if (!values.domain) {
            errors.domain = resources.validation.required;
        }
        else if (values.domain && values.domain.length > 191) {
            errors.domain = resources.validation.requiredMax191Characters;
        }
        if (!values.user) {
            errors.user = resources.validation.required;
        }
        else if (values.user && values.user.length > 191) {
            errors.user = resources.validation.requiredMax191Characters;
        }
        if (!values.key) {
            errors.key = resources.validation.passwordIsRequired;
        }
        else if (values.key && values.key.length > 191) {
            errors.key = resources.validation.requiredMax191Characters;
        }
        if (!values.base_url) {
            errors.base_url = resources.validation.required;
        }
        else if (values.base_url && values.base_url.length > 191) {
            errors.base_url = resources.validation.requiredMax255Characters;
        }
        if (!values.country_id) {
            errors.country_id = resources.validation.countryIsRequired;
        }
        if (!values.is_active) {
            errors.is_active = resources.validation.required
        }

        return errors;
    },

    handleSubmit: (values: ICallCenterInstances, bag: FormikBag<IHideModal & IAddObjToArray, ICallCenterInstances & IAddObjToArray>) => {
        const object: ICallCenterInstances = {
            type: values.type,
            name: values.name,
            domain: values.domain,
            user: values.user,
            key: values.key,
            base_url: values.base_url,
            country_id: values.country_id,
            is_active: values.is_active,
        }

        // checkIfUnicalKey(object.key!);
        createQuery(object, bag.props);
    }

})(TemplateInstanceForm);

export default InstanceForm;

const createQuery = async (obj: ICallCenterInstances, bag: IHideModal & IAddObjToArray) => {
    const query = `
    INSERT INTO cc_instances
    (
        cc_instances.type, 
        name, 
        cc_instances.domain, 
        cc_instances.user, 
        cc_instances.key, 
        base_url, 
        country_id, 
        is_active, 
        is_online
    )
    VALUES 
    (
        "${obj.type}", 
        "${obj.name}", 
        "${obj.domain}", 
        "${obj.user}", 
        "${obj.key}", 
        "${obj.base_url}", 
        "${obj.country_id}", 
        ${obj.is_active}, 
        0
    )`;


    await createInstance(query)
        .then((response) => {
            if (response) {
                obj.id = response;
                obj.key = response;
                obj.created_at = new Date();
                obj.updated_at = new Date();

                bag.addObjToArray!(obj);

                openSuccessNotification(resources.statement.instanceCreated);
            }
            else {
                openErrorNotification(resources.statement.databaseConnectionError);
            }
        });

    bag.hideModal!();
}