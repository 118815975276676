import * as React from 'react';
import { IProtectedProps } from '../../interfaces/IProtectedProps';
import { Route, RouteProps } from 'react-router-dom';
import { UnathorizedView } from './UnathorizedView';

interface IProtectedRouteBaseProps {
    unathorizedView: React.ComponentType<any>;
}

export class ProtectedRouteBase extends Route<IProtectedProps & RouteProps & IProtectedRouteBaseProps> {
    public static defaultProps = {
        unathorizedView: UnathorizedView
    }

    public render() {
        if (this.props.hasPermission) {
            return <Route {...this.props}/>;
        } else {
            return <Route {...this.props} component={this.props.unathorizedView} render={undefined}/>;
        }
    }
}