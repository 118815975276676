import DictionaryF from '../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import TextAreaF from '../../../../components/templateForm/form/textArea/TextAreaF';
import { Button } from 'antd';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { getBooleanValues } from '../../../../common/dictionaries/Boolean';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { IAffbayStatusId } from './interfaces/IAffbayStatusId';
import { IClassifiersFormProps } from './interfaces/IClassifiersFormProps';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { insertClassifierLib } from '../../../../services/library/ClassifiersServices';
import { INullableClassifierForm } from './interfaces/INullableClassifierForm';
import { IRefreshList } from '../../../../common/interfaces/IRefreshList';
import { ISetLoading } from '../../../../common/interfaces/ISetLoading';
import { IUpdateArray } from '../../../../common/interfaces/IUpdateArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import '../styles/ClassifiersStyle.css';

export class TemplateClassifiersForm extends React.Component<IClassifiersFormProps & IHideModal & IId & IAffbayStatusId & IUpdateArray & IRefreshList & FormikProps<INullableClassifierForm>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className='center'>{resources.labels.name}: <b className='classifierName'>{this.props.name}</b></div>}
                <DictionaryF
                    {...this.props}
                    name='is_qualified'
                    label={resources.labels.is_qualified}
                    boldLabel
                    dictionaryValues={getBooleanValues()}
                    labelTextAlignLeft
                />
                <DictionaryF
                    {...this.props}
                    name='status'
                    label={resources.labels.affbay_status}
                    boldLabel
                    dictionaryValues={this.removeUnrecognizeStatusFormStatusesD()}
                    labelTextAlignLeft
                    required
                />
                <TextAreaF
                    {...this.props}
                    name='postback_comment'
                    label={resources.labels.comment}
                    labelTextAlignLeft
                    boldLabel
                    allowClear
                />
                <Button
                    className='saveButton'
                    htmlType='submit'
                    type='primary'
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };

    removeUnrecognizeStatusFormStatusesD = () => {
        const statusesD: IDictionaryValue[] = getDictionary('statusesD');
        const statuses = statusesD.filter(x => x.id !== 99);

        return statuses;
    }
}

const ClassifiersForm = withFormik<IClassifiersFormProps & IUpdateArray & IRefreshList & ISetLoading, INullableClassifierForm & IUpdateArray>({
    mapPropsToValues: (props: IClassifiersFormProps) => {
        return {
            is_qualified: Boolean(props.is_qualified) ? '1' : '0',
            status: Number(props.status) === 99 ? undefined : props.status,
            postback_comment: props.postback_comment,
            addToAffbayClassifiers: undefined
        };
    },

    validate: (values: INullableClassifierForm) => {
        const errors: FormikErrors<INullableClassifierForm> = {};

        if (!values.status)
            errors.status = resources.validation.required;

        else if (values.postback_comment && values.postback_comment.length > 255) {
            errors.postback_comment = resources.validation.requiredMax255Characters;
        }

        return errors;
    },

    handleSubmit: (values: INullableClassifierForm, bag: FormikBag<IClassifiersFormProps & IUpdateArray & IRefreshList & IHideModal & IId & IAffbayStatusId & ISetLoading, INullableClassifierForm>) => {
        const object: INullableClassifierForm = {
            name: bag.props.name === null ? '' : bag.props.name,
            is_qualified: values.is_qualified,
            status: values.status,
            postback_comment: values.postback_comment ? values.postback_comment : '',
            id: bag.props.id
        }

        // Sprawdzenie, czy te same vartości
        if (
            object.id
            && values.is_qualified === bag.props.is_qualified
            && values.status === bag.props.status
        )
            openAlertNotification(resources.statement.thePropsAreTheSame);
        else
            updateClassifierLibrary(object, bag.props);
    }

})(TemplateClassifiersForm);

export default ClassifiersForm;

const updateClassifierLibrary = async (obj: INullableClassifierForm, bag: IUpdateArray & IHideModal & IClassifiersFormProps & ISetLoading) => {
    const object: any = {
        name: obj.name,
        lib_is_qualified: obj.is_qualified,
        lib_status: obj.status,
        id_instance: bag.id_instance,
        id_campaign: bag.id_campaign,
        postback_comment: obj.postback_comment
    }
    
    bag.setLoading!(true);

    insertClassifierLib(object)
        .then(response => {
            if (response) {
                bag.updateArray!('0', response);

                openSuccessNotification(resources.statement.classifierUpdated);
            }

            bag.setLoading!(false);
        });

    bag.hideModal!();
}