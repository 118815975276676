import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import moment from 'moment';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { Table } from 'antd';

const { Column } = Table;

export class K8MicroInfTable extends DataTable {
    render() {
        const columns = [
            <Column
                title='Pod IP'
                dataIndex='ip_pod'
                key='ip_pod'
                align='center'
                width={150}
                sorter={(a, b) => a.ip_pod - b.ip_pod}
                {...this.getColumnSearchProps('ip_pod')}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                width={300}
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title='Node name'
                dataIndex='node_name'
                key='node_name'
                align='center'
                width={250}
                sorter={(a, b) => (a.node_name ? a.node_name : "").localeCompare(b.node_name ? b.node_name : "")}
                {...this.getColumnSearchProps('node_name')}
            />,
            <Column
                title='Image'
                dataIndex='image'
                key='image'
                align='center'
                width={330}
                sorter={(a, b) => (a.image ? a.image : "").localeCompare(b.image ? b.image : "")}
                {...this.getColumnSearchProps('image')}
            />,
            <Column
                title='Start time'
                key='start_time'
                width={150}
                align='center'
                sorter={(a, b) => moment.utc(a.start_time).diff(moment.utc(b.start_time))}
                render={(record) => (
                    convertNumberToDateTimeString(record.start_time)
                )}
            />
        ];

        return (
            <DataTable
                tableName='K8MicroInfTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
                scrollY={1150}
            />
        )
    }
}