import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button, Tooltip } from 'antd';
import { resources } from '../../../../common/Resources';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import CouriersApiMappingDictionaryForm from '../forms/CouriersApiMappingDictionaryForm';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { findInDictionaryAndReturnValueWithSpin, getDictionary } from '../../../../helpers/DisctionariesHelper';

const { Column } = Table;

export class CouriersApiMappingDictionaryTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={resources.columnTitle.id}
                dataIndex='id'
                key='id'
                align='center'
                width='100px'
                sorter={(a, b) => a.id - b.id}
                onFilter={(value, record) => record.id === Number(value)}
                {...this.getColumnSearchProps('id')}
            />,
            <Column
                title={resources.columnTitle.country}
                key='id_country'
                width='120px'
                align='center'
                sorter={(a, b) => a.country_id - b.country_id}
                render={(record) =>
                    (
                        findInDictionaryAndReturnValueWithSpin(record.country_id, getDictionary('countriesNameD'))
                    )}
            />,
            <Column
                title={resources.columnTitle.user}
                dataIndex='user_name'
                key='user_name'
                width='250px'
                align='center'
                sorter={(a, b) => (a.user_name ? a.user_name : "").localeCompare(b.user_name ? b.user_name : "")}
                {...this.getColumnSearchProps('user_name')}
            />,
            <Column
                title={resources.columnTitle.notes}
                dataIndex='notes'
                key='notes'
                width='450px'
                align='center'
                sorter={(a, b) => (a.notes ? a.notes : "").localeCompare(b.notes ? b.notes : "")}
                {...this.getColumnSearchProps('notes')}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.courier_name, resources.columnTitle.courier_name_short)}
                dataIndex='courier_name'
                key='courier_name'
                width='110px'
                align='center'
                sorter={(a, b) => (a.courier_name ? a.courier_name : "").localeCompare(b.courier_name ? b.courier_name : "")}
                {...this.getColumnSearchProps('courier_name')}
            />,
            <Column
                title={resources.columnTitle.status}
                dataIndex='status'
                key='status'
                width='250px'
                align='center'
                sorter={(a, b) => (a.status ? a.status : "").localeCompare(b.status ? b.status : "")}
                {...this.getColumnSearchProps('status')}
            />,
            <Column
                title={resources.columnTitle.courier_status}
                dataIndex='courier_status'
                key='courier_status'
                width='350px'
                align='center'
                sorter={(a, b) => (a.courier_status ? a.courier_status : "").localeCompare(b.courier_status ? b.courier_status : "")}
                {...this.getColumnSearchProps('courier_status')}
            />,
            <Column
                title={resources.columnTitle.status_description}
                dataIndex='status_description'
                key='status_description'
                width='450px'
                align='center'
                sorter={(a, b) => (a.status_description ? a.status_description : "").localeCompare(b.status_description ? b.status_description : "")}
                {...this.getColumnSearchProps('status_description')}
            />,
            <Column
                title={resources.columnTitle.deleted_at}
                key='deleted_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.deleted_at).diff(moment.utc(b.deleted_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.deleted_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
                key='updated_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.updated_at)
                )}
            />,
            <Column
                title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
                key='created_at'
                width='160px'
                align='center'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => (
                    convertNumberToDateTimeString(record.created_at)
                )}
            />,
            < Column
                title={resources.columnTitle.options}
                key='options'
                width='80px'
                align='center'
                fixed= 'right'
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.EditCouriersApiMappingModalButton}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.editCouriersApiMapping}
                                buttonTooltip={resources.modalTooltips.editCouriersApiMapping}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={800}
                            >
                                <CouriersApiMappingDictionaryForm
                                    id={record.id}
                                    country_id={record.country_id}
                                    courier_name={record.courier_name}
                                    status={record.status}
                                    courier_status={record.courier_status}
                                    status_description={record.status_description}
                                    notes={record.notes}
                                    user_name={record.user_name}
                                    updateObjInArray={this.props.updateObjInArray}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.DeleteCouriersApiMappingButton}>
                            <Tooltip placement='rightTop' title={'Delete category'}>
                                <Button icon='delete' onClick={() => this.props.deleteCouriersApiMapping(record.id)} />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        return (
            <DataTable
                tableName='CouriersApiMappingDictionaryTable'
                columns={columns}
                data={this.props.data}
                loading={this.props.loading}
                pagination={true}
                defaultPageSize={100}
            />
        )
    }
}