import * as React from 'react';
import styles from './styles/LogRegNav.module.scss';
import { createAbsoluteUrl } from '../../helpers/UrlCreator';
import { resources } from '../../common/Resources';

interface props {
    activeTab?: string
}

export const LogRegNav = (props: props) => {
    return (
        <nav className={styles.grid}>
            <a className={`h5__semi ${styles.navItem} ${props.activeTab === 'registration' ? styles.navItem__active : ''}`} href={createAbsoluteUrl('register')}>{resources.loginPage.registration}</a>
            <a className={`h5__semi ${styles.navItem} ${props.activeTab === 'login' ? styles.navItem__active : ''}`} href={createAbsoluteUrl('login')}>{resources.loginPage.logIn}</a>
        </nav>
    );
}