import * as React from 'react';
import { Button, Icon, Tooltip } from 'antd';
import { IRefreshTableButtonProps } from './interfaces/IRefreshTableButtonProps';
import { resources } from '../../common/Resources';

export const RefreshTableButton = (props: IRefreshTableButtonProps) => {
    return (
        <Tooltip title={props.buttonTitle ? props.buttonTitle : resources.buttons.refreshTable}>
            <Button
                className={props.className}
                onClick={props.onClick}
                disabled={props.loading}
            >
                <Icon type="sync" spin={props.loading} />
            </Button>
        </Tooltip>
    );
}