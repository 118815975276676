export function getPayoutScheme() {
    const payoutScheme = [
        { id: 'cpa', name: 'CPA', value: "cpa" },
        { id: 'cpl', name: 'CPL', value: "cpl" },
    ]

    return payoutScheme;
}

export function getPayoutSchemeD() {
    const payoutScheme = [
        { id: 'cpa', name: 'CPA' },
        { id: 'cpl', name: 'CPL' },
    ]

    return payoutScheme;
}