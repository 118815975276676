import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { EmployeesContent } from './contents/EmployeesContent';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { setActiveMenuItem } from '../../reducers/CommonReducer';
import { IAppUserInfoModel } from '../../models/IAppUserInfoModel';
import { IAppState } from '../../store';

class EmployeesViewInner extends React.Component<ISetActiveMenuItem & IAppUserInfoModel>  {
   
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.employees);
    }

    public render() {
        return (
            <EmployeesContent  {...this.props} />
        )
    };
}


const mapStateToProps = (state: IAppState): IAppUserInfoModel => {
    return {
        ...state.commonState.appUserInfo
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const EmployeesView: React.ComponentClass = connect(mapStateToProps, mapDispatchToProps)(EmployeesViewInner);