import * as React from 'react';
import { connect } from 'react-redux';
import { Result, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { resources } from '../../common/Resources';
import styles from './styles/NotFoundView.module.scss';

class NotFoundInner extends React.Component<RouteComponentProps> {

    public render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle={resources.title.notFoundLabel}
                extra={<Button
                    type='primary'
                    className={styles.btn}
                    onClick={() => this.props.history.replace('/')}>
                    {resources.buttons.goToMainPage}
                </Button>}
            />
        )
    };
}

export const NotFoundView: React.ComponentClass = connect(null, null)(withRouter(NotFoundInner));
