import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { CouriersApiMappinfDictionaryContent } from './contents/CouriersApiMappinfDictionaryContent';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class CouriersApiMappingDictionaryViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.couriers_api_mapping);
    }

    render() {
        return (
            <>
                <CouriersApiMappinfDictionaryContent />
            </>
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const CouriersApiMappingDictionaryView: React.ComponentClass = connect(null, mapDispatchToProps)(CouriersApiMappingDictionaryViewInner);