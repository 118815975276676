import * as React from 'react';
import styles from './styles/NewsList.module.scss';
import { Empty } from 'antd';
import { getLanguageVersion } from '../../../../helpers/LocaleHelper';
import { INewsListProps } from './interfaces/INewsListProps';
import { SingleNews } from '../../../news/news/singleNews/SingleNews';

export class NewsListDashboard extends React.Component<INewsListProps> {
    public render() {
        return (
            <>
                {
                    this.props.data!.length === 0 ? <Empty /> :
                        <div className={styles.newsGrid}>
                            {this.props.data!.map((news, index) => {
                                return <SingleNews
                                    key={index}
                                    date_from={news.date_from}
                                    id_news_type={news.id_news_type}
                                    title={getLanguageVersion() === 1 ? news.title_en : news.title_ru}
                                    body={getLanguageVersion() === 1 ? news.body_en : news.body_ru}
                                    color={this.generateStyle(news.id_news_type)}
                                />
                            })}
                        </div>
                }
            </>
        )
    };

    generateStyle = (status: number): string => {
        if (status === 1)
            return '#29C05C';
        if (status === 2)
            return '#FBBC06';

        return '#2C50ED';
    }
}