import * as React from 'react';
import styles from '../styles/ActivationAccount.module.scss';
import { activateAccount } from '../../../services/m29_users_management/ActivateAccountService';
import { createAbsoluteUrl } from '../../../helpers/UrlCreator';
import { IActivateToken } from '../interfaces/IActiveToken';
import { Loading } from '../../../components/loading/Loading';
import { LogoAsLinkToLandingPage } from '../../../components/logoAsLinkToLandingPage/LogoAsLinkToLandingPage';
import { resources } from '../../../common/Resources';
import { Result } from 'antd';

export class ActivateAccountContent extends React.Component<IActivateToken>{
    state = {
        loading: true,
        status: undefined
    }

    componentDidMount = () => {
        this.props.activate_token && activateAccount(this.props.activate_token)
            .then(response => { response ? this.setState({ status: 'success' }) : this.setState({ status: 'error' }); })
            .finally(() => this.setState({ loading: false }));
    }

    public render() {
        return (
            <div className={styles.grid}>
                {this.state.loading ? <Loading /> :
                    <>
                        {this.state.status === 'success' &&
                            <Result
                                icon={<LogoAsLinkToLandingPage />}
                                title={resources.statement.your_account_has_been_created}
                                subTitle={resources.statement.your_account_has_been_created_message}
                                extra={[
                                    <button className={`h4__bold ${styles.submit}`} type="submit">
                                        <a href={createAbsoluteUrl('login')}>{resources.pageTitle.loginContent}</a>
                                    </button>
                                ]}
                            />
                        }
                        {this.state.status === 'error' &&
                            <Result
                                icon={<LogoAsLinkToLandingPage />}
                                title='Activation error'
                                subTitle='Your message.'
                                extra={[
                                    <button className={`h4__bold ${styles.submit}`} type="submit">
                                        <a href={createAbsoluteUrl('register')}>{resources.pageTitle.registerContent}</a>
                                    </button>
                                ]}
                            />
                        }
                    </>
                }
            </div>
        )
    };
}