import moment from 'moment';
import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { DatePicker } from 'antd';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { IRangePickerProps } from './interfaces/IRangePickerProps';

const ranges = {
    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
    'Today': [moment().startOf('day'), moment().endOf('day')],
    'Last 7 days': [moment().subtract(7, 'd').startOf('day'), moment().endOf('day')],
    'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
}
class RangePickerF extends React.Component<IBaseFormikProps & IRangePickerProps> {

    disabledDate = (current: moment.Moment | undefined) => {
        if (!this.props.minmaxRange) {
            return false;
        }
        const [rangeStart, rangeEnd] = this.props.minmaxRange;
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }

    genereteRanges = () => {
        if (!this.props.minmaxRange) {
            return ranges;
        }
        const [rangeStart, rangeEnd] = this.props.minmaxRange;
        const correctRanges = Object.keys(ranges).filter(rangeKey => {
            const range = ranges[rangeKey];
            return !(range[0].isSameOrBefore(rangeStart) || range[1].isAfter(rangeEnd))
        }).reduce((map, key) => {
            map[key] = ranges[key];
            return map;
        }, {});
        return correctRanges;
    }

    public render() {
        const { RangePicker } = DatePicker;
        const emptyValue = this.props.withEmptyValue ? undefined : [moment().subtract(1, 'd').startOf('day'), moment().endOf('day')]

        return (
            <BaseForm {...this.props} shouldUpdateComponentCheckInChildren={true}>
                <RangePicker
                    disabled={this.props.disabled}
                    disabledDate={this.disabledDate}
                    allowClear={this.props.allowClear}
                    ranges={this.genereteRanges()}
                    // showTime
                    format="DD/MM/YYYY HH:mm:ss"
                    value={this.props.values[this.props.name] ? this.props.values[this.props.name] : emptyValue}
                    onChange={(value) => this.onChange(value)}
                />
            </BaseForm>)
    }

    private onChange(dateTime: any) {
        if (this.props.customOnChange)
            this.props.customOnChange(dateTime, this.props.name);
        else
            this.props.setFieldValue(this.props.name, dateTime);
    }
};

export default RangePickerF;
