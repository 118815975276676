import React from "react";
import { Icon, notification } from "antd";
import "antd/dist/antd.css";

export const openSuccessNotification = (note: string) => {
  const args = {
    style: { backgroundColor: "#d9f2d9", opacity: 0.95 },
    message: "Success!",
    description: note,
    icon: <Icon type="check-circle" style={{ color: "green" }} />,
  };
  notification.open(args);
};

export const openAlertNotification = (note: string, options?: any) => {
  const args = {
    icon: <Icon type="exclamation-circle" style={{ color: "#ff9900" }} />,
    style: { backgroundColor: "#ffff99", opacity: 0.95 },
    message: "Alert!",
    description: note,
    duration: 300,
    ...options,
  };
  notification.open(args);
};

export const openErrorNotification = (note: string) => {
  const args = {
    style: { backgroundColor: "#ffcccc", opacity: 0.95 },
    message: "Error!",
    description: note,
    duration: 0,
    icon: <Icon type="close-circle" style={{ color: "red" }} />,
  };
  notification.open(args);
};
