import React from 'react';
import styles from './ArrowHeader.module.scss';

interface IProps {
    activeStep: boolean;
    text: string;
    noArrow?: boolean;
}

export const ArrowHeader = (props: IProps) => {
    const splitTextWithNBSP = (): any => {
        const splitted = props.text.split(' ');
        const newArray = splitted.reduce(addNbsp, []);
        return { __html: newArray.join('') };
    }

    const addNbsp = (accumulator: any, currentElement: any): any => {
        const fixed = currentElement.concat('&nbsp;');
        return [...accumulator, fixed];
    }


    return (
        <>
            <div className={`${styles.arrowHeader} ${props.activeStep ? styles.arrowHeader__active : ''}`}>
                <h5 className={`h6 ${styles.arrowHeader__text}`} dangerouslySetInnerHTML={splitTextWithNBSP()}></h5>
                <div className={styles.arrowHeader__arrow} style={{ display: props.noArrow ? 'none' : 'block' }}></div>
            </div>
        </>
    )
}
