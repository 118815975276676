import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { IPasswordReset } from './interfaces/IPasswordReset';
import { resourceDef } from '../../common/ResourceDef';

const resetPasswordUrl = createGatewayAbsoluteUrl(resourceDef.ResetPassword);

export const sendResetPasswordMail = (obj: IPasswordReset) => axios.post(resetPasswordUrl, { ...obj })
    .then(response => { return { error: false, status: response.status, message: '' }; })
    .catch(error => {
        console.log('sendResetPasswordMail M29');
        console.log(error.response);
        return { error: true, status: error.response.status, message: error.response.data };
    });

export const resetPassword = (obj: IPasswordReset, token: string) => axios.patch(resetPasswordUrl + `/${token}`, { ...obj })
    .then(response => { return { error: false, status: response.status, message: '' }; })
    .catch(error => {
        console.log(error, 'resetPassword M29');
        console.log(error.response);
        return { error: true, status: error.response.status, message: error.response.data };
    });