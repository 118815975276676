import React from 'react';

export class UnathorizedView extends React.Component {
    public render() {
        return (
            <div style={{ textAlign: 'center', marginTop: 50 }}>
                <div >
                    <h2 style={{ color: '#ff0000' }}>No permissions</h2>
                </div>
                <div>
                    <span><h4>You don't have sufficient permissions.</h4></span>
                </div>
            </div>
        );
    }
}
