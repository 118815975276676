import * as React from "react";
import { LoginContent } from "./contents/LoginContent";
import { _onSetLanguage } from "../../common/Resources";

export class LoginCCView extends React.Component {
  componentDidMount(): void {
    _onSetLanguage("pl_PL");
    localStorage.setItem("locale", "pl_PL");
  }

  public render() {
    return <LoginContent />;
  }
}
