import * as React from "react";
import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import PageForm from "../../_common/components/pages/forms/PageForm";
import ProtectedModal from "../../../components/authorizations/protectedComponents/ProtectedModal";
import SelectOnChange from "../../../components/forms/select/SelectOnChange";
import {
  deleteProductPages,
  getListOfAllProductPages,
} from "../../../services/m30_products/pages/ProductPagesService";
import { FieldInfo } from "../../../components/common/fieldInfo/FieldInfo";
import { getDictionary } from "../../../helpers/DisctionariesHelper";
import { getProductsD } from "../../../services/m30_products/ProductsService";
import { IAllProductPagesFilters } from "../../../services/m30_products/pages/interfaces/IAllProductPagesFilters";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IPage } from "../../_common/components/pages/forms/interfaces/IPage";
import { IPages } from "../interfaces/IPages";
import { Left } from "../../../components/controls/left/Left";
import { openSuccessNotification } from "../../../helpers/NotificationHelper";
import { PageInf } from "../../../components/common/pageInf/PageInf";
import { PagesTable } from "../tables/PagesTable";
import { RefreshTableButton } from "../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../common/Resources";
import { Right } from "../../../components/controls/right/Right";
import { TableContainer } from "../../../components/containers/tableContainer/TableContainer";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";

const pagesD = [
  { id: 99, name: "All" },
  { id: 0, name: "Landings" },
  { id: 1, name: "Prelandings" },
];

const visibilityD = [
  { id: 99, name: "All" },
  { id: 1, name: "True", value: 1 },
  { id: 0, name: "False", value: 0 },
];

const obj = { id: 0, name: "All" };

export class PagesContent extends React.Component {
  _isMounted = false;

  state = {
    page_type: 99,
    products_type: 0,
    loading: false,
    selected_country: 0,
    selected_visibility: 1,
    pagesList: Array<IPages>(),
    productsD: Array<IDictionaryValue>(),
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.getProductsD();
    this.getListOfPages();
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    const {
      loading,
      page_type,
      products_type,
      selected_country,
      selected_visibility,
    } = this.state;

    let pagesList = this.state.pagesList;
    let productsD = this.state.productsD;

    if (page_type !== 99)
      pagesList = pagesList.filter((x) => x.is_prelanding === page_type);
    if (products_type !== 0)
      pagesList = pagesList.filter((x) => x.product_id === products_type);
    if (selected_country)
      pagesList = pagesList.filter((x) => x.country_id === selected_country);
    if (selected_visibility !== 99)
      pagesList = pagesList.filter((x) => x.is_visible === selected_visibility);

    productsD = productsD ? [obj, ...productsD] : [];

    return (
      <TableContainer>
        <PageInf
          title={resources.pageTitle.pages}
          subTitle={this.getTitle(page_type, products_type)}
        />
        <Left>
          <RefreshTableButton
            className="marginTopRefreshButton23px marginBottom marginRigth"
            loading={this.state.loading}
            onClick={this.getListOfPages}
          />
        </Left>
        <Left>
          <FieldInfo>{resources.labels.visibility}</FieldInfo>
          <SelectOnChange
            name="selected_visibility"
            className="marginRigth"
            labelClassName="marginLeft marginBottom"
            onChange={this.onChangeSelect}
            value={selected_visibility}
            placeholder={resources.select.select}
            items={visibilityD}
            loading={loading}
            width={200}
            allowClear
          />
        </Left>
        <Left>
          <FieldInfo>{resources.labels.country}</FieldInfo>
          <SelectOnChange
            name="selected_country"
            className="marginRigth"
            labelClassName="marginLeft marginBottom"
            onChange={this.onChangeSelect}
            value={selected_country}
            placeholder={resources.select.select}
            items={[obj, ...getDictionary("countriesNameD")]}
            loading={loading}
            width={200}
            allowClear
          />
        </Left>
        <Left>
          <FieldInfo>{resources.labels.pages}</FieldInfo>
          <SelectOnChange
            className="marginRigth"
            labelClassName="marginLeft marginBottom"
            name="page_type"
            onChange={this.onChangeSelect}
            value={Number(page_type)}
            items={pagesD}
            loading={loading}
            width={200}
            allowClear
          />
        </Left>
        <FieldInfo>{resources.labels.products}</FieldInfo>
        <SelectOnChange
          labelClassName="marginLeft marginBottom"
          name="products_type"
          onChange={this.onChangeSelect}
          value={Number(products_type)}
          items={productsD}
          loading={loading}
          width={200}
          allowClear
        />
        <Right>
          <ProtectedModal permission={UserPermissionType.AddNewPageModalButton}>
            <InformationModalDialog
              buttonClassName="addNew"
              modalTitle={resources.title.addNewPage}
              icon={"form"}
              buttonTitle={resources.modalButtonsTitle.addNewPage}
              disabled={this.state.loading}
              withHideMethod={true}
              buttonType="primary"
            >
              <PageForm
                showSelectProduct
                addNewPage={this.addNewPage}
                productsD={productsD}
              />
            </InformationModalDialog>
          </ProtectedModal>
        </Right>
        <PagesTable
          loading={loading}
          data={pagesList}
          tableName={"PagesTable"}
          productsD={productsD}
          updatePage={this.updatePage}
          deletePage={this.deletePage}
        />
      </TableContainer>
    );
  }

  onChangeSelect = (name: string, value: number): void => {
    if (value === undefined) {
      switch (name) {
        case "page_type":
          this.setState({ [name]: 99 });
          break;
        case "products_type":
          this.setState({ [name]: 0 });
          break;
        case "selected_visibility":
          this.setState({ [name]: 99 });
          break;
        case "selected_country":
          this.setState({
            [name]: 0,
            products_type: 0,
          });
          break;
        default:
          this.setState({ [name]: value });
      }
    } else {
      switch (name) {
        case "selected_country":
          this.setState({
            [name]: value,
            products_type: 0,
          });
          break;
        default:
          this.setState({ [name]: value });
      }
    }
  };

  getProductsD = () => {
    getProductsD().then((productsD) => {
      this._isMounted && this.setState({ productsD });
    });
  };

  getListOfPages = async () => {
    this.setState({ loading: true });

    const object: IAllProductPagesFilters = {
      columns: [
        "id",
        "product_id",
        "is_prelanding",
        "name",
        "url",
        "created_at",
        "updated_at",
        "deleted_at",
        "is_visible",
        "is_mobile",
        "is_desktop",
        "is_top",
      ],
    };

    await getListOfAllProductPages(object)
      .then((response) => {
        const pagesList = response.map((item: any) => {
          return { ...item, key: item.id };
        });

        this._isMounted &&
          this.setState(
            {
              pagesList,
            },
            () => this.updateCountryDate()
          );
      })
      .finally(() => this._isMounted && this.setState({ loading: false }));
  };

  deletePage = async (id_product: number, id_page: number) => {
    await deleteProductPages(id_product, id_page).then((response) => {
      response && this._isMounted && this.changePageStatus(id_page);
    });
  };

  changePageStatus = (id_page: number) => {
    const { pagesList } = this.state;

    const index = pagesList.findIndex((x) => x.id === id_page);

    pagesList[index].is_visible = !pagesList[index].is_visible;
    pagesList[index].deleted_at = new Date();

    this.setState({ pagesList });

    openSuccessNotification(resources.statement.deleted);
  };

  getTitle = (page_type: number, products_type: number): string => {
    let title = "All";

    if (page_type !== undefined) {
      const page = pagesD.filter((x) => x.id === page_type);
      title = page[0].name;
    }
    if (products_type) {
      const product = this.state.productsD.filter(
        (x) => x.id === Number(products_type)
      );
      title = title + " / " + product[0].name;
    }

    return title;
  };

  updateCountryDate = () => {
    let productsD = this.state.productsD.filter((x) => x.id !== 0);

    if (this.state.pagesList && this.state.pagesList !== null) {
      const result = this.state.pagesList.map((page) => ({
        ...page,
        country_id:
          productsD.length > 0
            ? productsD.find((x) => x.id === page.product_id)
              ? productsD.find((x) => x.id === page.product_id)!.country_id
              : undefined
            : undefined,
      }));

      this.setState({ pagesList: result });
    }
  };

  addNewPage = (page: IPage) => {
    page.created_at = new Date();
    page.updated_at = new Date();
    page.deleted_at = null;

    page.country_id = this.findCountryIdByProduct(page.product_id);

    const pagesList = [...this.state.pagesList, page];

    this.setState({ pagesList });

    openSuccessNotification(resources.statement.created);
  };

  updatePage = (page: IPage) => {
    const { pagesList } = this.state;

    page.updated_at = new Date();
    page.deleted_at = null;
    page.country_id = this.findCountryIdByProduct(page.product_id);

    const index = pagesList.findIndex((x) => x.id === page.id);

    pagesList[index] = page;

    this.setState({ pagesList });

    openSuccessNotification(resources.statement.updated);
  };

  findCountryIdByProduct = (product_id: number) => {
    const productsD = this.state.productsD.filter((x) => x.id !== 0);

    return productsD.length > 0
      ? productsD.find((x) => x.id === product_id)
        ? productsD.find((x) => x.id === product_id)!.country_id
        : undefined
      : undefined;
  };
}
