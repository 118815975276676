import { getDictionary } from './DisctionariesHelper';
import { getListFromLibrary } from '../services/m40_libraries/LibrariesService';
import { IDictionaryValue } from '../components/templateForm/types/dictionaries/IDictionaryValue';
import { IInstance } from '../models/IInstance';

export const getInstancesDictionaryWithCountries = async () => {
    let instances: IInstance[] = [];

    const object = {
        name: 'cc_instances',
        column: 'id, country_id, name, is_active'
    };

    instances = await getListFromLibrary(object);

    if (instances)
        instances = instances.filter(x => x.is_active === 1);

    const countries: IDictionaryValue[] = await getDictionary('countriesNameD');

    instances.map(instance => {
        const country: IDictionaryValue = countries.find(x => x.id === instance.country_id)!;

        if (country)
            instance.name = country.name + ' / ' + instance.name;

        return null;
    });

    instances = [{ id: 0, name: "All" }, ...instances];

    return instances;
}