import React from "react";
import { Table } from "antd";
import { resources } from "../../../../common/Resources";

const { Column } = Table as any;

type ColumnItem = {
  [key: string]: string | number;
};

const sorterFunctionForNumbers = (
  a: string | number,
  b: string | number,
  keyA: number,
  keyB: number
) => {
  // This comparison we need to stick our "Total" column
  if (!keyA || !keyB) {
    return 0;
  }
  return parseInt(a as string) - parseInt(b as string);
};

export const tableColumns = [
  <Column
    title={resources.statistics.total}
    dataIndex="leads"
    key="leads"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.leads,
        b.leads,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.sold}
    dataIndex="sold"
    key="sold"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(a.sold, b.sold, a.key as number, b.key as number)
    }
  />,
  <Column
    title={`${resources.statistics.approvedRate} %`}
    dataIndex="approved_percentage"
    key="approved_percentage"
    align="center"
    width={200}
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.approved_percentage,
        b.approved_percentage,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={`${resources.statistics.verifiedApprovrdRate} %`}
    dataIndex="potential_approve_rate"
    key="potential_approve_rate"
    width={200}
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.potential_approve_rate,
        b.potential_approve_rate,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.awaiting}
    dataIndex="awaiting"
    key="awaiting"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.awaiting,
        b.awaiting,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.awaitingAll}
    dataIndex="awaiting_all"
    key="awaiting_all"
    align="center"
    width={150}
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.awaiting_all,
        b.awaiting_all,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.rejected}
    dataIndex="rejected"
    key="rejected"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.rejected,
        b.rejected,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.excluded}
    dataIndex="excluded"
    key="excluded"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.excluded,
        b.excluded,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.double}
    dataIndex="double"
    key="double"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.double,
        b.double,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.trash}
    dataIndex="trash"
    key="trash"
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.trash,
        b.trash,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.sentcc}
    dataIndex="sentcc"
    key="sentcc"
    width={150}
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.sentcc,
        b.sentcc,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.noMoney}
    dataIndex="no_money"
    key="no_money"
    width={150}
    align="center"
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.no_money,
        b.no_money,
        a.key as number,
        b.key as number
      )
    }
  />,
  <Column
    title={resources.statistics.orderCreated}
    dataIndex="order_created"
    key="order_created"
    align="center"
    width={150}
    sorter={(a: ColumnItem, b: ColumnItem) =>
      sorterFunctionForNumbers(
        a.order_created,
        b.order_created,
        a.key as number,
        b.key as number
      )
    }
  />,
];

const tableColumnForAdminOnly = [
  <Column
    title={resources.statistics.payoutAmount}
    dataIndex="payout"
    key="payout"
    width={120}
    align="center"
    sorter={(a: any, b: any) =>
      sorterFunctionForNumbers(
        a.payout,
        b.payout,
        a.key as number,
        b.key as number
      )
    }
  />,
];

export const getColumnTitleByKey = (key: string): string =>
  tableColumns.find((column) => column.key === key)?.props.title;

export const getTableColumnsKeys = (): string[] =>
  tableColumns.map((column) => column.key) as string[];

export const converKeysIntoTableColumns = (
  columns: string[],
  isAdmin: boolean
) => {
  const defaultColumns = columns.map((column) =>
    tableColumns.find((columnFromState) => columnFromState.key === column)
  );
  return [...defaultColumns, ...(isAdmin ? tableColumnForAdminOnly : [])];
};
