import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { useTable } from "./lib/table-state";
import { queryClient } from "../../App";
import { getGroupByData } from "./lib/table-data";
import { setTablesOrder } from "../../services/TablesSettingsService";
import { setActiveMenuItem } from "../../reducers/CommonReducer";
import { ColumnsVisibility } from "./components/columns-visibility/columns-visivility";
import { useStatisticQuery } from "./lib/hooks";
import { useFilters, UPDATE_FILTERS } from "./lib/filters-state";
import {
  getDateBasedOnGroup,
  GROUP_BY_PICKER_MAP,
} from "./lib/table-data/group-by-data";
import {
  TableSidebar,
  StatisticTable,
  LeadsStatistic,
  StatisticControls,
} from "./components/";

export const StatisticsView = () => {
  const { param } = useParams<{ param: string }>();
  const dispatchRx = useDispatch();

  // API mutation for changing table settings
  const tableMutation = useMutation(
    (columnOrder: string[]) => setTablesOrder(columnOrder),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData("table-settings", { columnOrder: variables });
      },
    }
  );

  const tableData = useTable();
  const filtersData = useFilters();
  // Load statistic data
  useStatisticQuery(param);

  const onChangeGroupBy = (groupBy: string) =>
    filtersData.dispatch({
      type: UPDATE_FILTERS,
      payload: {
        groupBy,
        endDate: getDateBasedOnGroup({
          endDate: filtersData.state.endDate,
          startDate: filtersData.state.startDate,
        })[groupBy].endDate,
        startDate: getDateBasedOnGroup({
          endDate: filtersData.state.endDate,
          startDate: filtersData.state.startDate,
        })[groupBy].startDate,
        pickerMode: GROUP_BY_PICKER_MAP[groupBy],
      },
    });

  useEffect(() => {
    const activeMenuItem = param ? `statistics/${param}` : "statistics";
    dispatchRx(setActiveMenuItem(activeMenuItem));
  }, [dispatchRx, param]);

  return (
    <>
      <h2 style={{ marginBottom: "20px", fontSize: "24px" }}>
        {param
          ? "Finance statistics - date approved at"
          : "Statistics - date created at"}
      </h2>

      <LeadsStatistic
        isLoading={tableData.state.isLoadingData}
        totalStatistic={(tableData.state.statisticData as any).total}
      />

      <StatisticControls />

      <Row type="flex" justify="end" style={{ marginBottom: "10px" }}>
        <ColumnsVisibility
          columns={(tableData.state.tableSettings as any).columnOrder}
          isLoading={tableData.state.isLoadingData}
          changeTableColumns={(columns: string[]) =>
            tableMutation.mutate(columns)
          }
        />
      </Row>

      <Row>
        <TableSidebar
          groupBy={[filtersData.state.groupBy]}
          groupByData={getGroupByData(filtersData.state.groupBy)}
          onChangeGroupBy={onChangeGroupBy}
        />

        <Col xs={18} sm={20} xxl={22}>
          <StatisticTable
            data={(tableData.state.statisticData as any).items}
            columns={(tableData.state.tableSettings as any).columnOrder}
            groupBy={filtersData.state.groupBy}
            isLoading={tableData.state.isLoadingData}
            totalStatistic={(tableData.state.statisticData as any).total}
            changeTableColumns={(columns: string[]) =>
              tableMutation.mutate(columns)
            }
          />
        </Col>
      </Row>
    </>
  );
};
