import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { Table } from 'antd';
import { displayOnlineStatuswithBadge } from '../../../../helpers/IconsGeneratorHelper';
import { getActivityFilters } from '../../../../common/dictionaries/Filters'

const { Column } = Table;

export class K8HealthPodsCheckTable extends DataTable {
    render() {
        const columns = [
            <Column
                title={spanWithTitle(resources.columnTitle.is_active, resources.columnTitle.is_active_short)}
                key='is_active'
                align='center'
                width={100}
                sorter={(a, b) => a.is_active - b.is_active}
                filters={getActivityFilters()}
                onFilter={(value, record) => record.is_active === value}
                render={(record) => (
                    displayOnlineStatuswithBadge(record.is_active)
                )}
            />,
            <Column
                title={resources.columnTitle.status}
                key='status'
                align='center'
                width={100}
                sorter={(a, b) => (a.status ? a.status : "").localeCompare(b.status ? b.status : "")}
                {...this.getColumnSearchProps('status')}
                render={(record) => (
                    <b style={{ color: record.status === 'OK' ? 'green' : 'red' }}>{record.status}</b>
                )}
            />,
            <Column
                title={resources.columnTitle.name}
                dataIndex='name'
                key='name'
                align='center'
                width={350}
                sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title='Error'
                dataIndex='error'
                key='error'
                align='center'
                width={550}
                sorter={(a, b) => (a.error ? a.error : "").localeCompare(b.error ? b.error : "")}
                {...this.getColumnSearchProps('error')}
            />
        ];

        let sortedData = this.props.data ? this.props.data.sort((a, b) => (a.is_active < b.is_active ? -1 : 1)) : [];

        return (
            <DataTable
                tableName='K8HealthPodsCheckTable'
                columns={columns}
                data={sortedData}
                loading={this.props.loading}
                pagination={true}
                columnNameToCheck='is_active'
                valueToCheck={0}
                scrollY={1100}
            />
        )
    }
}