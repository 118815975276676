import * as React from 'react';
import EmployeeForm from '../forms/EmployeeForm';
import InformationModalDialog from '../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../components/authorizations/protectedComponents/ProtectedModal';
import { ClearBoth } from '../../../components/controls/clearBoth/ClearBoth';
import { EmployeesTable } from '../tables/EmployeesTable';
import { getDictionary } from '../../../helpers/DisctionariesHelper';
import { getRolesListD } from '../../../services/m29_users_management/RoleService';
import { getUsersList, updateUser } from '../../../services/m29_users_management/GeneralUserService';
import { IDictionaryValue } from '../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IUser } from '../../../models/IUsers';
import { openErrorNotification, openSuccessNotification } from '../../../helpers/NotificationHelper';
import { PageTitle } from '../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../common/Resources';
import { Right } from '../../../components/controls/right/Right';
import { TableContainer } from '../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../models/IAppUserInfoModel';

const countryManagerRolesList = [4, 5, 232, 234, 235, 236, 237, 238, 239];
const employeeRolesList = [4, 5];

export class EmployeesContent extends React.Component<any,any> {


    _isMounted = false;

    state = {
        employeesList: Array<IUser>(),
        countriesNameD: Array<IDictionaryValue>(),
        rolesD: Array<IDictionaryValue>(),
        rolesDforms: Array<IDictionaryValue>(),
        loading: false
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.setState({ countriesNameD: getDictionary('countriesNameD') });

        this.getUsersRolesD();
        this.getEmployeesList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { employeesList, loading, countriesNameD, rolesD, rolesDforms } = this.state;
        
        return (
            <TableContainer>
                <PageTitle>{resources.pageTitle.employees}</PageTitle>
                <RefreshTableButton
                    loading={this.state.loading}
                    onClick={this.getEmployeesList}
                    className='marginBottom'
                />
                <Right>
                    <ProtectedModal permission={UserPermissionType.AddNewEmployeeModalButton}>
                        <InformationModalDialog
                            modalTitle={resources.title.addNewEmployee}
                            buttonTitle={resources.buttons.add_new}
                            buttonType='primary'
                            buttonTooltip={resources.title.addNewEmployee}
                            icon={"user-add"}
                            disabled={loading}
                            withHideMethod={true}
                            width={600}
                            buttonClassName='addNew marginBottom'
                        >
                            <EmployeeForm
                                addObjToArray={this.addObjToArray}
                                countries={countriesNameD}
                                rolesD={rolesDforms}
                            />
                        </InformationModalDialog>
                    </ProtectedModal>
                </Right>
                <ClearBoth />
                <EmployeesTable
                    data={employeesList}
                    loading={loading}
                    tableName={'EmployeesTable'}
                    refreshList={this.getEmployeesList}
                    countries={countriesNameD}
                    rolesD={rolesD}
                    changeStatus={this.changeStatus}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getEmployeesList = async () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                "id",
                "email",
                "name",
                "second_name",
                "short_name",
                "id_country",
                "description",
                "id_users_role",
                "is_activated"
            ],
            id_users_role: UserPermissionType.CountryManagerRole === this.props.id_users_role ? false : employeeRolesList 
        };

        await getUsersList(object)
            .then(
                (response) => {
                    const employeesList = response.map((item: any) => { return { ...item, key: item.id } });

                    this._isMounted && this.setState({ employeesList });
                })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    getUsersRolesD = async () => {
        const object = {
            columns: [
                "id",
                "name"
            ]
        };

        await getRolesListD(object)
            .then((response: any) => {
                const fullList = response ? response : [];
                const rolesD = UserPermissionType.CountryManagerRole !== this.props.id_users_role ? fullList.filter((x: any) => employeeRolesList.indexOf(x.id) !== -1) : fullList;
                const rolesDforms = UserPermissionType.CountryManagerRole !== this.props.id_users_role ? rolesD: rolesD.filter((x: any) => countryManagerRolesList.indexOf(x.id) !== -1);

                this._isMounted && this.setState({ rolesD, rolesDforms }) ;
            });
    }

    changeStatus = async (id: number, is_activated: boolean) => {
        const object = {
            is_activated: !is_activated
        }

        await updateUser(id, object)
            .then((response) => {
                if (response) {
                    this._isMounted && this.changeStatusInArray(id);
                    openSuccessNotification(resources.statement.status_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        const { employeesList } = this.state;
        const index = employeesList.findIndex(x => x.id === id);

        employeesList[index].is_activated = !employeesList[index].is_activated;

        this.setState({ employeesList });
    }

    addObjToArray = (employees: IUser) => {
        this.setState({ employeesList: [...this.state.employeesList, employees] });
    }

    updateObjInArray = (user: IUser): void => {
        const { employeesList } = this.state;
        const index = employeesList.findIndex(x => x.id === user.id);

        employeesList[index].name = user.name;
        employeesList[index].second_name = user.second_name;
        employeesList[index].short_name = user.short_name;
        employeesList[index].email = user.email;
        employeesList[index].id_country = user.id_country;
        employeesList[index].id_users_role = user.id_users_role;
        employeesList[index].description = user.description;

        this.setState({ employeesList });
    }
}