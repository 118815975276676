export const getLocale = () => {
    const locale = localStorage.getItem('locale');

    if (locale && locale !== null)
        return locale;
    else
        return "en_GB";
}

export const getLanguageVersion = () => {
    return getLocale() === "en_GB" ? 1 : 2;
}