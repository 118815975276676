import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { BaseForm } from '../base/BaseForm';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';

export interface ITextInputFProps {
    textAreaRows?: number;
    textAreaCols?: number;
    autoSize?: boolean;
}

const TextAreaF: React.SFC<IBaseFormikProps & ITextInputFProps> = (props) => {
    function onChange(value: string) {
        return value;
    }

    return (
        <BaseForm {...props} >
            {renderTextArea()}
        </BaseForm>)

    function renderTextArea() {
        return (
            <TextArea
                placeholder=""
                rows={props.textAreaRows}
                cols={props.textAreaCols}
                readOnly={props.readOnly}
                value={props.values[props.name]}
                onChange={event => props.setFieldValue(props.name, onChange(event.target.value))}
                onBlur={() => props.setFieldTouched(props.name)}
                disabled={props.disabled}
                autosize={props.autoSize}
            />
        );
    }
};
export default TextAreaF;