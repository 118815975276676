const qualifiedData = [
  {
    id: undefined,
    name: "Select all",
  },
  {
    id: true,
    name: "Qualified",
  },
  {
    id: false,
    name: "Not qualified",
  },
];

export const useQualifiedEntity = () => ({
  entity: qualifiedData,
  isLoading: false,
});
