import React, { useEffect, useState } from "react";
import { Icon, Popover, Checkbox } from "antd";

import styles from "./columns-visibility.module.scss";
import { tableColumns } from "../../lib/table-data/columns";

type VisibleColumns = Array<{ key: string; title: string; selected: boolean }>;

export const ColumnsVisibility = ({
  columns,
  isLoading,
  changeTableColumns,
}: ColumnsVisibilityProps) => {
  const [isVisible, setVisible] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>([]);

  const onChangeColumnsVisibility = (visibleColumns: VisibleColumns) => {
    const newColumns = visibleColumns
      .filter((column) => !!column.selected)
      .map((column) => column.key);
    setVisibleColumns(visibleColumns);
    changeTableColumns(newColumns);
  };

  useEffect(() => {
    if (!isLoading) {
      const visibleColumns = tableColumns.map((column) => ({
        key: column.key as string,
        title: column.props.title as string,
        selected: columns.includes(column.key as string),
      }));
      setVisibleColumns(visibleColumns);
    }
  }, [columns, isLoading]);

  return (
    <Popover
      visible={isVisible}
      trigger="click"
      content={
        <ColumnsList
          visibleColumns={visibleColumns}
          onChangeColumnsVisibility={onChangeColumnsVisibility}
        />
      }
      placement="bottomRight"
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <div
        className={`${styles["open-popover"]} ${
          isLoading && styles["open-popover--disabled"]
        }`}
      >
        <span className={styles["open-popover__title"]}>Columns</span>

        <Icon
          type={isVisible ? "up" : "down"}
          className={styles["open-popover__trigger"]}
        />
      </div>
    </Popover>
  );
};

type ColumnsListProps = {
  visibleColumns: VisibleColumns;
  onChangeColumnsVisibility: (visibleColumns: VisibleColumns) => void;
};

const ColumnsList = ({
  visibleColumns,
  onChangeColumnsVisibility,
}: ColumnsListProps) => {
  const onChangeItemVisibility = (key: string, checked: boolean) => {
    const updatedColumns = visibleColumns.map((column) => {
      if (column.key === key) {
        column.selected = checked;
      }
      return column;
    });
    onChangeColumnsVisibility(updatedColumns);
  };

  return (
    <ul className={styles["columns-list"]}>
      {visibleColumns.map((item, index) => (
        <li key={index} className={styles["columns-list__item"]}>
          <Checkbox
            checked={item.selected}
            onChange={(e) => onChangeItemVisibility(item.key, e.target.checked)}
          >
            {item.title}
          </Checkbox>
        </li>
      ))}
    </ul>
  );
};

type ColumnsVisibilityProps = {
  columns: string[];
  isLoading: boolean;
  changeTableColumns: (columns: string[]) => void;
};
