import * as React from 'react';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SystemVariableDictionaryForm from '../forms/SystemVariableDictionaryForm';
import { deleteSystemVariableDictionary, getSystemVariablesDictionaryList } from '../../../../services/dictionaries/SystemVariablesDictionaryService';
import { ISystemVariables } from '../interfaces/ISystemVariables';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { SystemVariablesDictionaryTable } from '../table/SystemVariablesDictionaryTable';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { updateProductCategoriesDictionary } from '../../../../services/dictionaries/ProductCategoriesDivtionaryService';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class SystemVariablesDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        systemVariablesList: []
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getSystemVariablesList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.system_variables} />
                <RefreshTableButton
                    className='marginBottom'
                    loading={this.state.loading}
                    onClick={this.getSystemVariablesList}
                />
                <ProtectedModal permission={UserPermissionType.AddNewSystemVariablesModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewSystemVariable}
                            buttonTitle={resources.modalButtonsTitle.addNewSystemVariable}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewSystemVariable}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={this.state.loading}
                        >
                            <SystemVariableDictionaryForm
                                addObjToArray={this.addObjToArray}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <SystemVariablesDictionaryTable
                    loading={this.state.loading}
                    data={this.state.systemVariablesList}
                    deleteSystemVariable={this.deleteSystemVariable}
                    updateObjInArray={this.updateObjInArray}
                    changeStatus={this.changeStatus}
                />
            </TableContainer>
        )
    };

    getSystemVariablesList = () => {
        this.setState({ loading: true });

        getSystemVariablesDictionaryList()
            .then(systemVariablesList => this._isMounted && this.setState({ systemVariablesList }))
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }

    deleteSystemVariable = async (id: number) => {
        const query = `DELETE FROM li_system_variables WHERE id = ${id}`;

        await deleteSystemVariableDictionary(query)
            .then(response => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_system_variable);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        let systemVariablesList: ISystemVariables[] = this.state.systemVariablesList;

        systemVariablesList = systemVariablesList.filter(x => x.id !== id);

        this.setState({
            systemVariablesList,
            loading: false
        });

        openSuccessNotification(resources.statement.system_variable_deleted);
    }

    changeStatus = async (event: any) => {
        const id = Number(event.target.value);
        const query = `UPDATE li_system_variables SET is_active = !is_active WHERE id = ${id}`;

        await updateProductCategoriesDictionary(query)
            .then((response) => {
                if (response) {
                    this.changeStatusInArray(id);
                    openSuccessNotification(resources.statement.status_updated);
                }
                else {
                    openErrorNotification(resources.statement.databaseConnectionError);
                }
            });
    }

    changeStatusInArray = (id: number) => {
        let systemVariablesList: ISystemVariables[] = this.state.systemVariablesList;

        const index = systemVariablesList.findIndex(x => x.id === id);

        systemVariablesList[index].is_active = !systemVariablesList[index].is_active;

        this.setState({ systemVariablesList });
    }

    addObjToArray = (variable: ISystemVariables) => {
        this.setState({ systemVariablesList: [...this.state.systemVariablesList, variable] });
    }

    updateObjInArray = (obj: ISystemVariables): void => {
        let systemVariablesList: ISystemVariables[] = this.state.systemVariablesList;

        const index = systemVariablesList.findIndex(x => x.id === obj.id);

        systemVariablesList[index].name = obj.name;
        systemVariablesList[index].value = obj.value;
        systemVariablesList[index].is_active = obj.is_active;
        systemVariablesList[index].updated_at = obj.updated_at;

        this.setState({ systemVariablesList });
    }
}