import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { Table, Button } from 'antd';
import { resources } from '../../../../common/Resources';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { Tooltip } from 'antd';
import P3Form from '../forms/ResaleForm';

const displayValue = (value) => {
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return "not set";
        }
        return value.join(',');
    }
    return value;
}

const { Column } = Table;

export class ResalesTable extends DataTable {


    render() {
        const columns = [
            <Column
                title={resources.columnTitle.product}
                dataIndex='product_name'
                key='product_name'
                align='center'
                />,
                <Column
                title={'7th day'}
                dataIndex='7'
                key='7'
                align='center'
                render={(value) => {
                    return displayValue(value);
                }}
            />,
            <Column
                title={'14th days'}
                dataIndex='14'
                key='14'
                align='center'
                render={(value) => {
                    return displayValue(value);
                }}
            />,
            <Column
                title={'21st days'}
                dataIndex='21'
                key='21'
                align='center'
                render={(value) => {
                    return displayValue(value);
                }}
            />,
            <Column
                title={'28th days'}
                dataIndex='28'
                key='28'
                align='center'
                render={(value) => {
                    return displayValue(value);
                }}
            />,
            <Column
                title={'38th days'}
                dataIndex='38'
                key='38'
                align='center'
                render={(value) => {
                    return displayValue(value);
                }}
            />,
            <Column
                title={'53th days'}
                dataIndex='53'
                key='53'
                align='center'
                render={(value) => {
                    return displayValue(value);
                }}
            />,
            <Column
                title={'Token'}
                key='partner_token'
                align='center'
                width={350}
                render={(record) => (<>{record.partner_token ? record.partner_token : 'not set'}</>)}
            />,
            <Column
                title={resources.columnTitle.options}
                key='options'
                align='center'
                width={'100px'}
                render={(record) => (
                    <>
                        <ProtectedModal permission={UserPermissionType.SalesP2View}>
                            <InformationModalDialog
                                modalTitle={resources.modalTitle.edit}
                                buttonTooltip={resources.modalButtonsTitle.edit}
                                icon='edit'
                                disabled={this.props.loading}
                                withHideMethod={true}
                                width={800}
                            >
                                <P3Form
                                    product={record}
                                    submit={this.props.submit}
                                />
                            </InformationModalDialog>
                        </ProtectedModal>
                        <ProtectedComponent permission={UserPermissionType.SalesP2View}>
                            <Tooltip
                                placement="rightBottom"
                                title={record.is_active ? resources.buttons.disable : resources.buttons.enable}
                            >
                                <Button
                                    value={record.id}
                                    onClick={() => this.props.toogleResale(record)}
                                    icon={!record.is_resale_active ? 'close' : 'check'}
                                    style={{ color: !record.is_resale_active ? 'red' : 'green' }}
                                    disabled={!record.isValid}
                                />
                            </Tooltip>
                        </ProtectedComponent>
                    </>
                )}
            />
        ];

        let sortedData = this.props.data ? this.props.data.sort((a, b) => (a.id < b.id ? 1 : -1)) : [];
        sortedData = sortedData.map(d => {
            let isValid = false;
            if ((d.days['7'] && d.days['7'] !== "") ||
                (d.days['14'] && d.days['14'] !== "") ||
                (d.days['21'] && d.days['21'] !== "") ||
                (d.days['28'] && d.days['28'] !== "") ||
                (d.days['38'] && d.days['38'] !== "") ||
                (d.days['53'] && d.days['53'] !== "")) {
                isValid = true;
            }
            return {
                ...d,
                ...d.days,
                key: d.product_id,
                isValid,
            }
        });

        return (
            <DataTable
                tableName='ProductsTable'
                columns={columns}
                data={sortedData}
                loading={this.props.loading}
                pagination={false}
                defaultPageSize={100}
            />
        )
    }
}