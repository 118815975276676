import React from "react";
import styles from "./classifiers-statistic-item.module.scss";

type Props = {
  title: string;
  value: string;
};

export const ClassifiersStatisticItem = ({ title, value }: Props) => (
  <div className={styles.statistic}>
    <p className={styles["statistic__title"]}>{title}</p>
    <p className={styles["statistic__value"]}>{value}</p>
  </div>
);
