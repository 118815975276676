import * as React from 'react';
import { getLocale } from '../../../../helpers/LocaleHelper';
import { IProductDescription } from './interfaces/IProductDescription';

export class Description extends React.Component<IProductDescription> {
    content = () => {
        const description = getLocale() === "en_GB" ? this.props.description_en : this.props.description_ru;

        return description ? description : "";
    }

    public render() {
        return (
            <article className='productDetailsDescription' dangerouslySetInnerHTML={{ __html: this.content() }}></article>
        )
    };
}