import { createAbsoluteUrl } from './UrlCreator';
import { NavigationItem } from '../components/navigation/navigationItem/NavigationItem';
import { SubNavigationItem } from '../components/navigation/subNavigationItem/SubNavigationItem';

type IconTypes = 'custom' | 'ant';

interface IIcon {
    type: IconTypes;
    source: string;
}

interface ISubIcon {
    source: string;
    rotate?: number;
}

export const generateMenuItem = (absoluteURL: string, buttonTitle: string, menuItemKey: string, icon: IIcon) => {
    return (
        new NavigationItem(
            createAbsoluteUrl(absoluteURL),
            buttonTitle,
            menuItemKey,
            icon.type === 'custom' ?
                {
                    component: icon.source
                }
                :
                {
                    icon: icon.source
                }
        )
    )
}

export const generateSubMenu = (buttonTitle: string, submenu: any, icon: ISubIcon) => {
    return (
        new SubNavigationItem(
            buttonTitle,
            submenu,
            {
                icon: icon.source,
                rotate: icon.rotate
            }
        )
    )
}