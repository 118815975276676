import * as React from 'react';
import desktopSrc from './images/desktop.svg';
import mobileSrc from './images/mobile.svg';
import styles from './styles/TargetUrlLanding.module.scss';
import { PartnerViewCheckbox } from '../../../../../../../components/partnerViewCheckbox/PartnerViewCheckbox';

export const TargetUrlLanding = (props: any) => {
    const ifSelected = (id: number, selectedArr: number[]): boolean => {
        return selectedArr.indexOf(id) >= 0 ? true : false;
    }

    let icons;
    if (props.is_top || props.is_desktop || props.is_mobile) {
        icons = <div className={styles.icons}>
            {props.is_top === 1 ? <div className={`chart ${styles.status}`}>TOP</div> : null}
            {props.is_desktop === 1 ? <img src={desktopSrc} alt="desktop" /> : null}
            {props.is_mobile === 1 ? <img style={{ opacity: props.is_mobile }} src={mobileSrc} alt="mobile" /> : null}
        </div>;
    }

    return (
        <div className={styles.grid}>
            <PartnerViewCheckbox checked={ifSelected(props.id, props.selected)} value={props.id} onChange={props.onChange} />
            <div>
                <a href={props.url} className={`h6 ${styles.landingName}`} target="_blank" rel="noopener noreferrer">{props.name}</a>
                {icons}
            </div>
        </div>
    );
}