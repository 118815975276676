import CreateNewOrderContext from '../../../../contexts/createNewOrderContext';
import React, { Component } from 'react';
import styles from './StepTwo.module.scss';
import { ButtonCC } from '../../../../components/cc_employee/buttonCC/ButtonCC';
import { IProductsWithPricings } from '../../contents/interfaces/IProductsWithPricings';
import { PricingItem } from '../pricingItem/PricingItem';
import { Select } from 'antd';

interface IProps {
    data: IProductsWithPricings[]
}

export class StepTwo extends Component<IProps> {
    findProductId = (value: any, lead_id?: number, ): number => {
        const lead = value.leadsList.find((el: any) => el.id === lead_id);
        return lead ? lead.product.id : null;
    }
    findPricings = (value: any, prod_id?: number): any[] => {
        const product = value.productsList.find((el: any) => el.id === prod_id);
        return product ? product.pricings : [];
    }
    render() {
        return (
            <CreateNewOrderContext.Consumer>
                {value => {
                    return <div className={`customAnt--userEditForm`}>
                        <label className={`h4__bold ${styles.label}`}>Choose a product</label>
                        {
                            value.productsList && value.productsList.length > 0 ?
                                <>
                                    <Select placeholder={'Choose product'} value={value.selectedProductId} onChange={value.chooseProduct}>
                                        {
                                            value.productsList.sort((a, b) => {
                                                const tmpA = a.name.toLowerCase();
                                                const tmpB = b.name.toLowerCase();
                                                return tmpA > tmpB ? tmpA < tmpB ? 1 : 0 : -1;
                                            }).map(product => {
                                                return (
                                                    <Select.Option key={product.id} value={product.id}>{product.name}</Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                    <label className={`h4__bold ${styles.label} ${styles.label__discount}`}>Choose a discount</label>
                                    <section className={styles.pricingsList}>
                                        {
                                            value.selectedProductId && value.productsList ?
                                                this.findPricings(value, value.selectedProductId).length > 0 ?
                                                    this.findPricings(value, value.selectedProductId!).map((pricing: any) => {
                                                        return (
                                                            <PricingItem
                                                                key={pricing.id}
                                                                id={pricing.id}
                                                                name={pricing.name}
                                                                choosePricing={value.choosePricing}
                                                                isChecked={value.isPricingChecked}
                                                                total_order_value={pricing.total_order_value}
                                                                total_customer_price={pricing.total_customer_price}
                                                            />
                                                        )
                                                    })
                                                    :
                                                    <p>There aren't any pricings for this product yet</p>
                                                :
                                                <p>Select product to display pricings</p>
                                        }
                                    </section>
                                    <ButtonCC type="green" onClick={value.addToBasket} width="300">Add to basket</ButtonCC>
                                </>
                                :
                                <p>Can not fetch products list</p>
                        }
                    </div>
                }}
            </CreateNewOrderContext.Consumer>
        )
    }
}
