import axios from 'axios';
import { openErrorNotification } from '../helpers/NotificationHelper';
import { resourceDef } from '../common/ResourceDef';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';

const postbacksURL = resourceDef.PostbacksInfo;

export const getPostbackConfig = async (productId?: number, userId?: number) => {
    const requestUrl = productId ? `${postbacksURL}/${productId}` : postbacksURL;
    const params = userId ? { userId } : {};
    return axios.get(createGatewayAbsoluteUrl(requestUrl), { params })
    .then((response) => {
        return response.data;
    })
    .catch(() => {
        openErrorNotification(' getPostbackConfig');
    });
}

export const updatePostbackConfig = async (urlValue: string, urlType: string, urlId?: number, productId?: number, userId?: number): Promise<void> => {
    const requestUrl = productId ? `${postbacksURL}/${productId}` : postbacksURL;
    const params = userId ? { userId } : {};
    const data = {
        url: urlValue,
        type: urlType,
        id: urlId,
    }
    return axios.post(createGatewayAbsoluteUrl(requestUrl), { ...data }, { params });
}

export const deletePostbackConfig = async (urlId: number, userId?: number): Promise<void> => {
    const requestUrl = `${postbacksURL}/${urlId}`;
    const params = userId ? { userId } : {};
    return axios.delete(createGatewayAbsoluteUrl(requestUrl), { params })
}