import { Popover } from "antd";
import React, { useState } from "react";

import { FiltersCard } from "../filters-card/filters-card";

import styles from "./add-filters.module.scss";

export const AddFilters = () => {
  const [isVisible, setVisible] = useState(false);
  return (
    <Popover
      content={<FiltersCard setVisible={setVisible} />}
      visible={isVisible}
      trigger="click"
      placement="bottomLeft"
      onVisibleChange={setVisible}
    >
      <div className={styles["add-filters"]}>
        <span className={styles["add-filters__text"]}>+ Add more filters</span>
      </div>
    </Popover>
  );
};
