import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { findInDictionaryAndReturnValue } from '../../../../helpers/DisctionariesHelper';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { IDictionaryValue } from '../../types/dictionaries/IDictionaryValue';
import { IMultipleSelectFProp as IProps } from './interfaces/IMultipleSelectFProp';
import { IMultipleSelectFState } from './interfaces/IMultipleSelectFState';
import { resources } from '../../../../common/Resources';
import { Select, Spin } from 'antd';

type MultipleSelectFJoinedProps = IProps & IBaseFormikProps;

export default class MultipleSelectF extends React.Component<MultipleSelectFJoinedProps, IMultipleSelectFState> {
    public state: IMultipleSelectFState = {
        dictionaryValues: [],
        selectedValues: [],
        loading: false,
    }

    public componentDidMount() {
        const results = this.getSelectedValues()
        
        this.setState({ loading: true })
        this.setState({
            dictionaryValues: this.props.dictionaryValues!,
            selectedValues: results
        });
        this.setState({ loading: false });
    }

    getSelectedValues = () => {
        const elements: [] = this.props.values[this.props.name];

        if (elements && elements.length > 0) {
            const result = elements.map(
                obj => ({
                    key: obj,
                    label: findInDictionaryAndReturnValue(obj, this.props.dictionaryValues!)
                })
            );


            return result;
        }
        return [];
    }

    public render() {
        const dictionaryValues: IDictionaryValue[] = this.state.dictionaryValues.filter(x => !this.state.selectedValues.find(s => s.key === x.id));
        return (
            <>
                {this.state.loading ? <div style={{ textAlign: "center" }}><Spin tip={resources.spin.loading} /></div> :
                    <BaseForm {...this.props} shouldUpdateComponentCheckInChildren={true}>
                        <Select
                            value={this.state.selectedValues}
                            filterOption={false}
                            onChange={this.handleChange}
                            optionFilterProp="children"
                            labelInValue={true}
                            mode="multiple"
                            allowClear={true}
                            className="multiselect"
                        >
                            {dictionaryValues.map(item => (
                                this.renderOption(item)
                            ))}
                        </Select>
                    </BaseForm>
                }
            </>
        )
    };

    handleChange = (value: any[]) => {
        this.setState({ selectedValues: value });
        this.props.setFieldValue(this.props.name, value.map(x => x.key));
    }

    private renderOption(dropdownItem: IDictionaryValue) {
        return (
            <Select.Option value={dropdownItem.id} key={dropdownItem.id.toString()}>{dropdownItem.name}</Select.Option>
        );
    }
}