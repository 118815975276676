import * as React from 'react';
import moment from 'moment';
import styles from './styles/SingleNews.module.scss';
import { findInDictionaryAndReturnValue, getDictionary } from '../../../../helpers/DisctionariesHelper';

interface INews {
    color?: string;
    key: number;
    date_from: string;
    title: string;
    id_news_type: number;
    body: string;
}

export const SingleNews = (props: INews) => {
    return (
        <section className={styles.grid}>
            <header>
                <div style={{ background: `${props.color}` }} className={`table__reg ${styles.status}`}>{findInDictionaryAndReturnValue(props.id_news_type, getDictionary('newsTypesD'))}</div>
                <div className={`h6 ${styles.date}`}>{moment(new Date(props.date_from)).format('YYYY/MM/DD')}</div>
            </header>
            <h4 className={`h4__bold`}>{props.title}</h4>
            <p className={`h5`}>{props.body}</p>
        </section>
    );
}