import DictionaryF from '../../../../../components/templateForm/form/dropDown/DictionaryF';
import React from 'react';
import styles from './styles/AddLandingForm.module.scss';
import SwitchF from '../../../../../components/templateForm/form/switch/SwitchF';
import TextInputF from '../../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createProductPages, updateProductPages } from '../../../../../services/m30_products/pages/ProductPagesService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IHideModal } from '../../../../../common/interfaces/IHideModal';
import { IId } from '../../../../../common/interfaces/IId';
import { IPage } from './interfaces/IPage';
import { IPageForm } from './interfaces/IPageForm';
import { IPageFormMethods } from './interfaces/IPageFormMethods';
import { IProductPageCreate } from '../../../../../services/m30_products/pages/interfaces/IProductPageCreate';
import { IProductPageUpdate } from '../../../../../services/m30_products/pages/interfaces/IProductPageUpdate';
import { IUpdateArray } from '../../../../../common/interfaces/IUpdateArray';
import { resources } from '../../../../../common/Resources';

class PageFormInner extends React.Component<IPageForm & IPageFormMethods & IHideModal & IId & FormikProps<IPageForm>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        labelTextAlignLeft: true,
        boldLabel: true
    }

    public render() {
        return (
            <Form>
                <div className={styles.col_4}>
                    <div style={{ justifySelf: 'center' }}>
                        <SwitchF {...this.props}
                            name="is_visible"
                            label={resources.labels.is_visible}
                            labelTextAlignLeft={true}
                        />
                    </div>
                    <div style={{ justifySelf: 'center' }}>
                        <SwitchF {...this.props}
                            name="is_top"
                            label={resources.labels.is_top}
                            labelTextAlignLeft={true}
                        />
                    </div>
                    <div style={{ justifySelf: 'center' }}>
                        <SwitchF {...this.props}
                            name="is_mobile"
                            label={resources.labels.is_mobile}
                            labelTextAlignLeft={true}
                        />
                    </div>
                    <div style={{ justifySelf: 'center' }}>
                        <SwitchF {...this.props}
                            name="is_desktop"
                            label={resources.labels.is_desktop}
                            labelTextAlignLeft={true}
                        />
                    </div>
                </div>
                <div>
                    <DictionaryF {...this.props}
                        name="is_prelanding"
                        label={resources.labels.type}
                        dictionaryValues={[
                            { id: 1, value: 'prelandings', name: 'PRELANDING' },
                            { id: 2, value: 'landings', name: 'LANDING' }]}
                        allowOfflineSearch={true}
                    />
                </div>
                <div>
                    <TextInputF
                        {...this.props}
                        name="name"
                        label={resources.labels.name}
                        required
                    />
                </div>
                {this.props.showSelectProduct &&
                    <div>
                        <DictionaryF {...this.props}
                            name="product_id"
                            label={resources.labels.product}
                            dictionaryValues={this.props.productsD}
                            allowOfflineSearch={true}
                            required
                        />
                    </div>
                }
                <div>
                    <TextInputF
                        {...this.props}
                        name="url"
                        label={resources.labels.url}
                        required
                    />
                </div>
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form>
        );
    };
}

const PageForm = withFormik<IPageForm & IPageFormMethods & IId, IPageForm>({
    mapPropsToValues: (props: IPageForm) => {
        return {
            is_prelanding: props.is_prelanding ? 1 : 2,
            is_visible: props.is_visible,
            is_top: props.is_top,
            is_desktop: props.is_desktop,
            is_mobile: props.is_mobile,
            product_id: props.product_id,
            name: props.name,
            url: props.url,
            id: props.id,
            created_at: props.created_at
        };
    },
    validate: (values: IPageForm, props: IPageForm) => {
        const errors: FormikErrors<IPageForm> = {};
        if (!values.is_prelanding)
            errors.is_prelanding = resources.validation.required;
        if (!values.name)
            errors.name = resources.validation.lastNameIsRequired
        if (props.showSelectProduct && !values.product_id)
            errors.product_id = resources.validation.required;
        if (!values.url)
            errors.url = resources.validation.required;
        else
            if (!values.url.match("(http://|https://).*"))
                errors.url = 'URL must start with http:// or https://'

        return errors;
    },
    handleSubmit: (values: IPageForm, bag: FormikBag<IPageForm & IPageFormMethods & IHideModal & IUpdateArray, IPageForm>) => {
        values.id ? updatePage(values, bag.props) : createPage(values, bag.props);
    }
})(PageFormInner);

export default PageForm;

const createPage = (data: IPageForm, bag: IHideModal & IPageFormMethods) => {
    const createProduct: IProductPageCreate = {
        product_id: data.product_id!,
        name: data.name!,
        url: data.url!,
        is_prelanding: data.is_prelanding === 1 ? true : false,
        is_visible: Boolean(data.is_visible),
        is_desktop: Boolean(data.is_desktop),
        is_mobile: Boolean(data.is_mobile),
        is_top: Boolean(data.is_top)
    };

    createProductPages(createProduct.product_id, createProduct)
        .then(response => {
            if (response) {
                const page: IPage = {
                    id: response.id,
                    key: response.id,
                    product_id: data.product_id!,
                    name: data.name!,
                    url: data.url!,
                    is_prelanding: data.is_prelanding === 2 ? 0 : 1,
                    is_visible: Boolean(data.is_visible),
                    is_desktop: Boolean(data.is_desktop),
                    is_mobile: Boolean(data.is_mobile),
                    is_top: Boolean(data.is_top),
                    created_at: new Date()
                };

                bag.hideModal!();
                bag.addNewPage!(page);
            }
        });
}

const updatePage = (data: IPageForm, bag: IHideModal & IPageFormMethods) => {
    const updatePage: IProductPageUpdate = {
        product_id: data.product_id,
        name: data.name,
        url: data.url,
        is_prelanding: data.is_prelanding === 1 ? true : false,
        is_visible: Boolean(data.is_visible),
        is_desktop: Boolean(data.is_desktop),
        is_mobile: Boolean(data.is_mobile),
        is_top: Boolean(data.is_top)
    };

    updateProductPages(data.product_id!, Number(data.id), updatePage)
        .then(response => {
            if (response) {
                const page: IPage = {
                    id: Number(data.id),
                    key: Number(data.id),
                    product_id: data.product_id!,
                    name: data.name!,
                    url: data.url!,
                    is_prelanding: data.is_prelanding === 1 ? 1 : 0,
                    is_visible: Boolean(data.is_visible),
                    is_desktop: Boolean(data.is_desktop),
                    is_mobile: Boolean(data.is_mobile),
                    is_top: Boolean(data.is_top),
                    created_at: data.created_at
                };
                
                bag.hideModal!();
                bag.updatePage!(page);
            }
        });
}