import React, { Component } from 'react';
import { ChangePasswordForm } from '../../forms/changePassword/ChangePasswordForm';
import { OfferDetailsContainer } from '../../../../components/containers/offerDetailsContainer/OfferDetailsContainer';
import { IUserId } from '../../../../common/interfaces/IUserId';

export class ChangePassword extends Component<IUserId> {
    render() {
        return (
            <OfferDetailsContainer>
                <ChangePasswordForm userId={this.props.userId} />
            </OfferDetailsContainer>
        )
    }
}
