import DictionaryF from '../../../../../../../../components/templateForm/form/dropDown/DictionaryF';
import ProtectedComponent from '../../../../../../../../components/authorizations/protectedComponents/ProtectedComponent';
import React from 'react';
import styles from '../styles/Pixels.module.scss';
import TextInputF from '../../../../../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createProductPixels, updateProductPixels } from '../../../../../../../../services/m30_products/pixels/ProductPixelsService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { formStatusClassName } from '../../../../../../../../helpers/StylesHelper';
import { IPixels } from '../interfaces/IPixels';
import { IPixelsFormDefaultProps } from './interfaces/IPixelsFormDefaultProps';
import { IPixelsMethods } from '../interfaces/IPixelsMethods';
import { IProductPixelCreate } from '../../../../../../../../services/m30_products/pixels/interfaces/IProductPixelCreate';
import { IProductPixelsUpdate } from '../../../../../../../../services/m30_products/pixels/interfaces/IProductPixelsUpdate';
import { resources } from '../../../../../../../../common/Resources';
import { UserPermissionType } from '../../../../../../../../models/IAppUserInfoModel';

export class InnerPixelsForm extends React.Component<IPixels & IPixelsMethods & IPixelsFormDefaultProps & FormikProps<IPixels>> {
    public static defaultProps = {
        readOnly: false,
        disabled: false,
        allowClear: true,
        labelTextAlignLeft: true,
    }

    public render() {
        const disable_buttons = !this.props.form_status;

        return (
            <Form className={styles.fixel_form}>
                <div className={styles.grid_pixels}>
                    <div>
                        <TextInputF
                            {...this.props}
                            name="no_pixel"
                            label='Pixel number'
                            required
                        />
                    </div>
                    <div>
                        <TextInputF
                            {...this.props}
                            name="custom_script"
                            label='Custom script'
                            required
                        />
                    </div>
                    <div>
                        <DictionaryF
                            {...this.props}
                            name="id_user"
                            label='Affbay username'
                            dictionaryValues={this.props.usersD}
                            disabled
                        />
                    </div>
                    <div>
                        <ProtectedComponent permission={UserPermissionType.SavePixelsChangesButton}>
                            <Button
                                icon='save'
                                htmlType="submit"
                                disabled={disable_buttons}
                                className={formStatusClassName(this.props.form_status)}
                            />
                        </ProtectedComponent>
                    </div>
                    <div>
                        <ProtectedComponent permission={UserPermissionType.DeletePixelButton}>
                            <Button
                                icon='delete'
                                onClick={() => this.props.deletePixel(this.props.row_key, this.props.id)}
                            />
                        </ProtectedComponent>
                    </div>
                </div>
            </Form>
        );
    };
}

const PixelsForm = withFormik<IPixels & IPixelsFormDefaultProps & IPixelsMethods, IPixels>({
    mapPropsToValues: (props: IPixels) => {
        return {
            id: props.id,
            row_key: props.row_key,
            id_user: props.id_user,
            id_product: props.id_product,
            no_pixel: props.no_pixel,
            custom_script: props.custom_script,
        };
    },

    validate: (values: IPixels, props: IPixels & IPixelsMethods) => {
        const errors: FormikErrors<IPixels> = {};

        if (values.no_pixel !== props.no_pixel || values.custom_script !== props.custom_script)
            props.changePixelStatus(props.row_key, props.id ? 'edited' : 'new');
        else {
            props.changePixelStatus(props.row_key, undefined);
        }

        if (!values.no_pixel)
            errors.no_pixel = resources.validation.required;
        if (!values.custom_script)
            errors.custom_script = resources.validation.required;

        return errors;
    },

    handleSubmit: (values: IPixels, bag: FormikBag<IPixels & IPixelsMethods, IPixels>) => {
        bag.props.id ? updatePixel(values, bag.props) : createPixel(values, bag.props);
    },
    enableReinitialize: true,
    validateOnChange: true,
})(InnerPixelsForm);

export default PixelsForm;

const createPixel = (obj: IPixels, bag: IPixelsMethods) => {
    const object: IProductPixelCreate = {
        id_product: obj.id_product,
        no_pixel: obj.no_pixel!,
        custom_script: obj.custom_script!
    }

    createProductPixels(obj.id_product, object)
        .then(response => {
            obj.id = response.id;
            bag.updatePixel(obj, true);
        });
}

const updatePixel = (object: IPixels, bag: IPixelsMethods) => {
    const updatePixel: IProductPixelsUpdate = {
        id_product: object.id_product,
        no_pixel: object.no_pixel,
        custom_script: object.custom_script
    }

    updateProductPixels(Number(object.id_product), Number(object.id), updatePixel)
        .then(response => { response && bag.updatePixel(object); });
}