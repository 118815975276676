import React, { Fragment } from 'react'
import { FormikProps } from 'formik'
import styles from '../../../../contents/styles/CreateNewOrderContent.module.scss'
import { IAddressInformation, IAddressSelect } from '../IAddressInformation'
import TextInputF from '../../../../../../components/templateForm/form/textInput/TextInputF'
import DictionaryF from '../../../../../../components/templateForm/form/dropDown/DictionaryF'
import { ICountry } from '../../../../../../common/interfaces/ICountry'
import {
    getCities,
    getPostalCodes,
    getProvinces,
    getDistricts,
    getSubdistricts,
    getPostalCode,
    getAddressByPostalCode
} from '../../../../../../services/LocationService';
import { IAddressChange } from '../IAddressChange'

const getIdFromName = (list: IAddressSelect[], value: string): number => {
    return list.filter(el => el.id === value)[0].dbId;
}
export default class AddressInfoInd extends React.Component<ICountry & IAddressChange & FormikProps<IAddressInformation>> {

    state = {
        isLoading: true,
        citiesList: [] as Array<IAddressSelect>,
        provincesList: [] as Array<IAddressSelect>,
        districtsList: [] as Array<IAddressSelect>,
        subdistrictsList: [] as Array<IAddressSelect>,
        postalCodesList: [] as Array<IAddressSelect>,
    }

    componentDidMount = async () => {
        this.getInitialData();
    }

    getInitialData = async () => {
        this.setState({
            isLoading: true,
        });
        const [provincesList, postalCodesList] = await Promise.all([getProvinces(this.props.countryId), getPostalCodes(this.props.countryId)]);
        this.setState({
            provincesList: provincesList.map((el: any): IAddressSelect => ({
                id: el.name,
                name: el.name,
                dbId: el.id,
            })),
            postalCodesList: postalCodesList.map((el: any): IAddressSelect => ({
                id: el.name,
                name: el.name,
                dbId: el.id,
            })),
            isLoading: false,
        });
    }

    updateCitiesList = async (province: IAddressSelect) => {
        const citiesList = await getCities(this.props.countryId, province.dbId);
        this.props.setFieldValue('city', '');
        this.props.setFieldValue('district', '');
        this.props.setFieldValue('subdistrict', '');
        this.setState({
            citiesList: citiesList.map((el: any): IAddressSelect => ({
                id: el.name,
                name: el.name,
                dbId: el.id,
            })),
            districtsList: [],
            subdistrictsList: [],
        });
    }

    updateDistrictsList = async (city: IAddressSelect) => {
        const provinceName = this.props.values.province;
        if (provinceName) {
            const provinceId = getIdFromName(this.state.provincesList, provinceName);
            const districtsList = await getDistricts(this.props.countryId, provinceId, city.dbId);
            this.props.setFieldValue('subdistrict', '');
            this.setState({
                districtsList: districtsList.map((el: any): IAddressSelect => ({
                    id: el.name,
                    name: el.name,
                    dbId: el.id,
                })),
                subdistrictsList: [],
            });
        }
    }

    updateSubdistrictsList = async (district: IAddressSelect) => {
        const provinceName = this.props.values.province;
        const cityName = this.props.values.city;
        if (provinceName && cityName) {
            const provinceId = getIdFromName(this.state.provincesList, provinceName);
            const cityId = getIdFromName(this.state.citiesList, cityName);
            const subdistrictsList = await getSubdistricts(this.props.countryId, provinceId, cityId, district.dbId);
            this.setState({
                subdistrictsList: subdistrictsList.map((el: any): IAddressSelect => ({
                    id: el.name,
                    name: el.name,
                    dbId: el.id,
                })),
            });
        }
    }

    setPostalCode = async (subdistrict: IAddressSelect) => {
        const provinceName = this.props.values.province;
        const cityName = this.props.values.city;
        const districtName= this.props.values.district;
        if (provinceName && cityName && districtName) {
            const provinceId = getIdFromName(this.state.provincesList, provinceName);
            const cityId = getIdFromName(this.state.citiesList, cityName);
            const districtId = getIdFromName(this.state.districtsList, districtName);
            const locationData = await getPostalCode(this.props.countryId, provinceId, cityId, districtId, subdistrict.dbId);
            this.props.setFieldValue('postalCode', locationData.postalCode.name);
            this.props.onAddressChange(locationData.locationId);
        }
    }

    setAddressByPostalCode = async (postalCode: IAddressSelect) => {
        const locationData = await getAddressByPostalCode(Number(postalCode.id));
        this.props.setFieldValue('city', locationData.addressData.city.name);
        this.props.setFieldValue('district', locationData.addressData.district.name);
        this.props.setFieldValue('subdistrict', locationData.addressData.subdistrict.name);
        this.props.setFieldValue('province', locationData.addressData.province.name);
        this.setState({
            citiesList: [],
            districtsList: [],
            subdistrictsList: [],
        })
        this.props.onAddressChange(locationData.locationId);
    }

    render() {
        const { citiesList, provincesList, districtsList, subdistrictsList, postalCodesList } = this.state;
        return (
            <Fragment>
                <div className={`${styles.row}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <TextInputF
                            {...this.props}
                            name='address'
                            label={'Address'}
                            required
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <DictionaryF
                            {...this.props}
                            name='province'
                            label={'Province'}
                            dictionaryValues={provincesList}
                            loading={false}
                            customOnChange={this.updateCitiesList}
                            disabled={provincesList.length === 0}
                            required
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <DictionaryF
                            {...this.props}
                            name='city'
                            label={'City'}
                            dictionaryValues={citiesList}
                            loading={false}
                            customOnChange={this.updateDistrictsList}
                            disabled={citiesList.length === 0}
                            required
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <DictionaryF
                            {...this.props}
                            name='district'
                            label={'District'}
                            dictionaryValues={districtsList}
                            loading={false}
                            customOnChange={this.updateSubdistrictsList}
                            disabled={districtsList.length === 0}
                            required
                        />
                    </div>
                    <div className={'customAnt--newOrderForm'}>
                        <DictionaryF
                            {...this.props}
                            name='subdistrict'
                            label={'Subdistrict'}
                            loading={false}
                            dictionaryValues={subdistrictsList}
                            customOnChange={this.setPostalCode}
                            disabled={subdistrictsList.length === 0}
                            required
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.row__2col}`}>
                    <div className={'customAnt--newOrderForm'}>
                        <DictionaryF
                            {...this.props}
                            name='postalCode'
                            label={'Postal Code'}
                            loading={false}
                            dictionaryValues={postalCodesList}
                            customOnChange={this.setAddressByPostalCode}
                            disabled={postalCodesList.length === 0}
                            required
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}