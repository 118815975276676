import * as React from 'react';
import { DataTable } from '../../../../components/others/data/DataTable';
import { resources } from '../../../../common/Resources';
import moment from 'moment';
import { spanWithTitle } from '../../../../components/others/SpanWithTitle';
import { convertNumberToDateTimeString } from '../../../../helpers/DateHelpers';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../helpers/DisctionariesHelper';
import { Table, Tooltip, Button } from 'antd';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import { displayIconStatusWithTooltip } from '../../../../helpers/IconsGeneratorHelper';
import AppUserForm from '../forms/AppUserForm';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import ProtectedComponent from '../../../../components/authorizations/protectedComponents/ProtectedComponent';
import { Link } from 'react-router-dom';
import { createAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { getActivityFilters } from '../../../../common/dictionaries/Filters'
import { Modal } from 'antd';
import { switchToDifferentUser } from '../../../../helpers/Authentication';

const { Column } = Table;
const { confirm } = Modal;

export class UsersTable extends DataTable {
    showConfirm = (id_user, name) => {
      confirm({
        title: 'Are you sure?',
        content: `You want to delete ${name}? `,
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: () => { this.props.deleteUser(id_user); },
        onCancel() { },
      });
    }

    render() {
      const columns = [
        <Column
          title={resources.columnTitle.id}
          dataIndex='id'
          key='id'
          align='center'
          width='90px'
          sorter={(a, b) => a.key - b.key}
          {...this.getColumnSearchProps('id')}
        />,
        <Column
          title={resources.columnTitle.name}
          dataIndex='name'
          key='name'
          align='center'
          width='350px'
          sorter={(a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")}
          {...this.getColumnSearchProps('name')}
        />,
        <Column
          title={resources.columnTitle.second_name}
          dataIndex='second_name'
          key='second_name'
          align='center'
          width='350px'
          sorter={(a, b) => (a.second_name ? a.second_name : "").localeCompare(b.second_name ? b.second_name : "")}
          {...this.getColumnSearchProps('second_name')}
        />,
        <Column
          title={resources.columnTitle.email}
          dataIndex='email'
          key='email'
          align='center'
          width='350px'
          sorter={(a, b) => (a.email ? a.email : "").localeCompare(b.email ? b.email : "")}
          {...this.getColumnSearchProps('email')}
        />,
        <Column
          title={resources.columnTitle.role}
          key='id_users_role'
          align='center'
          width='200px'
          sorter={(a, b) => a.id_users_role - b.id_users_role}
          render={(record) =>
            (
              findInDictionaryAndReturnValueWithSpin(Number(record.id_users_role), this.props.rolesD)
            )}
        />,
        <Column
          title={resources.columnTitle.partnerToken}
          dataIndex='partner_token'
          key='partner_token'
          align='center'
          width='350px'
          sorter={(a, b) => (a.partner_token ? a.partner_token : "").localeCompare(b.partner_token ? b.partner_token : "")}
          {...this.getColumnSearchProps('partner_token')}
        />,
        <Column
          title={spanWithTitle(resources.columnTitle.active, resources.columnTitle.is_active_short)}
          key='is_activated'
          align='center'
          width='100px'
          sorter={(a, b) => a.is_activated - b.is_activated}
          filters={getActivityFilters()}
          onFilter={(value, record) => record.is_activated === value}
          render={(record) => (
            displayIconStatusWithTooltip(record.is_activated)
          )}
        />,
        <Column
          title={spanWithTitle(resources.columnTitle.created, resources.columnTitle.created_short)}
          key='created_at'
          width='160px'
          align='center'
          sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
          render={(record) => (
            convertNumberToDateTimeString(record.created_at)
          )}
        />,
        <Column
          title={spanWithTitle(resources.columnTitle.updated, resources.columnTitle.updated_short)}
          key='updated_at'
          width='160px'
          align='center'
          sorter={(a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))}
          render={(record) => (
            convertNumberToDateTimeString(record.updated_at)
          )}
        />,
        <Column
          title={resources.columnTitle.options}
          key='options'
          width='189px'
          align='center'
          fixed='right'
          render={(record) => (
            <>
              <ProtectedModal permission={UserPermissionType.EditUserAdministrationModalButton}>
                <InformationModalDialog
                  modalTitle={resources.modalTitle.editUser}
                  buttonTooltip={resources.modalButtonsTitle.editUser}
                  icon='edit'
                  disabled={this.props.loading || !record.is_activated}
                  withHideMethod={true}
                  width={800}
                >
                  <AppUserForm
                    id={record.id}
                    id_users_role={record.id_users_role}
                    email={record.email}
                    name={record.name}
                    is_activated={record.is_activated}
                    second_name={record.second_name}
                    id_account_manager={record.id_account_manager}
                    rowKey={record.rowKey}
                    partner_token={record.partner_token}
                    id_company={record.id_company}
                    skype={record.skype}
                    telegram={record.telegram}
                    id_country={record.id_country}
                    short_name={record.short_name}
                    description={record.description}
                    rolesD={this.props.rolesD}
                    companiesD={this.props.companiesD}
                    accountManagersD={this.props.accountManagersD}
                    updateObjInArray={this.props.updateObjInArray}
                  />
                </InformationModalDialog>
              </ProtectedModal>
              <ProtectedComponent permission={UserPermissionType.ChangeUserStatusAdministrationButton}>
                <Tooltip
                  placement="rightBottom"
                  title={resources.tooltips.userActivityStatus}
                >
                  <Button
                    value={record.id}
                    onClick={() => this.props.changeUserStatus(record.id, record.is_activated)}
                    icon={record.is_activated ? 'close' : 'check'}
                    style={{ color: record.is_activated ? 'red' : 'green' }}
                    disabled={record.deleted_at}
                  />
                </Tooltip>
              </ProtectedComponent>
              <ProtectedComponent permission={UserPermissionType.DeleteUserAdministrationButton}>
                <Tooltip
                  placement='rightBottom'
                  title={(record.id_users_role === 1 || record.id_users_role === 2) ? resources.tooltips.cantDeleteAdminSuperAdmin : resources.tooltips.deleteUser}
                >
                  <Button
                    value={record.id}
                    onClick={() => this.showConfirm(record.id, record.name)}
                    icon='delete'
                    disabled={(record.id_users_role === 1 || record.id_users_role === 2 || !record.is_activated)} // Czy admin / super admin / usunięte
                  />
                </Tooltip>
              </ProtectedComponent>
              {record.id_users_role !== 1 &&
                            <ProtectedComponent permission={UserPermissionType.ImpersonateAction}>
                              <Tooltip
                                placement='rightBottom'
                                title={resources.buttons.switchToUser}
                              >
                                <Button
                                  value={record.id}
                                  onClick={async () => {
                                    const success = await switchToDifferentUser(record.id);
                                    success && window.location.reload();
                                  }}
                                  icon='login'
                                />
                              </Tooltip>
                            </ProtectedComponent>
              }
              <ProtectedComponent permission={UserPermissionType.EditRoleAdministrationLinkButton}>
                <Tooltip placement='rightTop' title={resources.tooltips.editRole}>
                  <Link permission={UserPermissionType.EditRoleAdministrationLinkButton} to={createAbsoluteUrl('users_information/' + record.id)}>
                    <Button icon='arrow-right' disabled={!record.is_activated} />
                  </Link>
                </Tooltip>
              </ProtectedComponent>
            </>
          )}
        />
      ];

      return (
        <DataTable
          tableName='AppUsersTable'
          columns={columns}
          data={this.props.data}
          loading={this.props.loading}
          pagination={true}
          columnNameToCheck='is_activated'
          valueToCheck={0}
        />
      )
    }
}

