import React from 'react';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button, Tooltip } from 'antd';
import { copyClipboard } from '../../../../helpers/CopyHelpers';
import { createAppAbsoluteUrl } from '../../../../helpers/UrlCreator';
import { Form, FormikProps, withFormik } from 'formik';
import { IRegistrationLinkForm } from '../../interfaces/IRegistrationLinkForm';
import { Left } from '../../../../components/controls/left/Left';
import { resources } from '../../../../common/Resources';

export class RegistrationLinkFormInner extends React.Component<IRegistrationLinkForm & FormikProps<IRegistrationLinkForm>> {
    public render() {
        return (
            <Form className='customAnt--registrationLinkForm'>
                <div className='grid'>
                    <Left>
                        <div>
                            <TextInputF
                                {...this.props}
                                name="referral_url"
                                label="Link"
                                labelTextAlignLeft
                                boldLabel
                                allowClear
                                disabled
                            />
                        </div>
                    </Left>
                    <div>
                        <Tooltip title='Copy link'>
                            <Button
                                icon='copy'
                                className='copy_button'
                                onClick={(event: any) => { copyClipboard(event, this.props.values.referral_url) }}
                            />
                        </Tooltip>
                    </div>
                </div>
                <p>
                    {resources.labels.partners_that_registerd_from_your_ref_link} <b>{this.props.referrals ? this.props.referrals : '-'}</b>
                </p>
            </Form>
        );
    };
}

const RegistrationLinkForm = withFormik<IRegistrationLinkForm, IRegistrationLinkForm>({
    mapPropsToValues: (props: IRegistrationLinkForm) => {
        return {
            referral_url: props.referral_url ? createAppAbsoluteUrl(`register/${props.referral_url}`) : ''
        };
    },

    validate: () => { },
    handleSubmit: () => { },
    enableReinitialize: true

})(RegistrationLinkFormInner);

export default RegistrationLinkForm;