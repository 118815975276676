import moment from "moment";
import { LEAD_SOURCE_NULL } from "./hooks/remarketing-options";

export const formatRemarketing = (values, countryId, oldRemarketing) => {
  const runOptions = values.run_now
    ? { run_now: values.run_now }
    : {
        run_now: values.run_now,
        run_at_hour: values.run_at_hour,
        run_at_minute: values.run_at_minute,
        run_at_timezone: values.run_at_timezone,
      };

  const remarketing = {
    ...runOptions,
    date_from: values.isRangeDate
      ? moment.utc(values.date_from).format()
      : null,
    date_to: values.isRangeDate ? moment.utc(values.date_to).format() : null,
    days_after: !values.isRangeDate ? values.days_after : -1,
    mode: values.mode,
    name: values.name,
    orders_from: values.orders_from,
    orders_to: values.orders_to,
    is_enabled: values.is_enabled,
    country_id: countryId,
    product_ids: values.product_ids,
    description: values.description,
    order_statuses: values.order_statuses,
    remarketing_campaign: values.remarketing_campaign,
    traffic_sources: values.traffic_sources.map((item) =>
      item === LEAD_SOURCE_NULL ? null : item
    ),
  };

  if (oldRemarketing) {
    const {
      date_from,
      date_to,
      days_after,
      run_now,
      run_at_hour,
      run_at_minute,
      run_at_timezone,
      ...updatingRemarketing
    } = oldRemarketing;

    return { ...updatingRemarketing, ...remarketing };
  } else {
    return { ...remarketing, is_archived: 0 };
  }
};
