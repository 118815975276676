import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { resourceDef } from '../../common/ResourceDef';

const getUrl = createGatewayAbsoluteUrl(resourceDef.Postbacks);

export const getPostbacks = (id_lead: number) => axios.get(getUrl + id_lead)
    .then(response => { return response.data.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getUserInf M3');
        return [];
    });

export const sendPostback = (id_lead: number) => axios.patch(getUrl + id_lead);