import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { PagesContent } from './contents/PagesContent';
import { setActiveMenuItem } from '../../reducers/CommonReducer';

class PagesViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.pages);
    }

    public render() {
        return (
            <PagesContent />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const PagesView: React.ComponentClass = connect(null, mapDispatchToProps)(PagesViewInner);