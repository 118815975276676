import * as React from 'react';
import CourierDictionaryForm from '../forms/CourierDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { CouriersDictionaryTable } from '../table/CouriersDictionaryTable';
import { deleteCouriersDictionary, getCouriersDictionaryList } from '../../../../services/dictionaries/CouriersDivtionaryService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { ICourier } from '../interfaces/ICourier';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { Left } from '../../../../components/controls/left/Left';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class CouriersDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        selected_country: undefined,
        couriersList: Array<ICourier>(),
        countriesD: Array<IDictionaryValue>()
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getCouriersList();
        this.getCountriesD();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { couriersList, loading, selected_country, countriesD } = this.state;

        let filteredData = couriersList;

        if (selected_country)
            filteredData = filteredData.filter(x => x.country_id === selected_country);

        const { length: count } = filteredData;

        return (
            <TableContainer>
                <PageInf title={resources.pageTitle.couriersDictionary} />
                <Left>
                    <RefreshTableButton
                        className='marginBottom marginTopRefreshButton marginRigth'
                        loading={loading}
                        onClick={this.getCouriersList}
                    />
                </Left>
                <FieldInfo>{resources.labels.country}</FieldInfo>
                <SelectOnChange
                    name='selected_country'
                    labelClassName='marginLeft marginBottom'
                    onChange={this.onChangeSelect}
                    value={selected_country}
                    placeholder={resources.select.select}
                    items={getDictionary('countriesNameD')}
                    disabled={loading}
                    loading={loading}
                    width={200}
                    allowClear
                />
                <ProtectedModal permission={UserPermissionType.AddNewCourierModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewCourier}
                            buttonTitle={resources.modalButtonsTitle.addNewCourier}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewCourier}
                            icon={"plus"}
                            withHideMethod={true}
                            width={400}
                            buttonClassName='addNew marginBottom'
                            disabled={loading}
                        >
                            <CourierDictionaryForm
                                addObjToArray={this.addObjToArray}
                                countriesD={countriesD}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <CouriersDictionaryTable
                    loading={loading}
                    data={filteredData}
                    countriesD={countriesD}
                    deleteCourier={this.deleteCourier}
                    updateObjInArray={this.updateObjInArray}
                />
            </TableContainer>
        )
    };

    getCouriersList = async () => {
        this.setState({ loading: true });

        await getCouriersDictionaryList()
            .then(couriersList => { this._isMounted && this.setState({ couriersList }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    getCountriesD = () => {
        const countriesD = localStorage.getItem('countriesNameD');

        if (countriesD) {
            this.setState({ countriesD: JSON.parse(countriesD) });
        }
    }

    deleteCourier = async (event: any) => {
        const id = Number(event.target.value);
        const query = `DELETE FROM li_couriers WHERE id = ${id}`;

        await deleteCouriersDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_courier);
                }
            });
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const couriersList = this.state.couriersList.filter(x => x.id !== id);

        this.setState({
            couriersList,
            loading: false
        });

        openSuccessNotification(resources.statement.courier_deleted);
    }

    addObjToArray = (courier: ICourier) => {
        this.setState({ couriersList: [...this.state.couriersList, courier] });
    }

    updateObjInArray = (obj: ICourier): void => {
        const { couriersList } = this.state;
        const index = couriersList.findIndex(x => x.id === obj.id);

        couriersList[index].name = obj.name;
        couriersList[index].country_id = obj.country_id;
        couriersList[index].bank_transfer = obj.bank_transfer;
        couriersList[index].updated_at = obj.updated_at;

        this.setState({ couriersList });
    }

    onChangeSelect = (name: string, value: number): void =>
        this.setState({ [name]: value });
}