import axios from 'axios';
import jsonpipe from 'jsonpipe';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { ILeadsContent } from '../../views/leads/interfaces/ILeadsContent';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';


const leadStreamURL = createGatewayAbsoluteUrl(resourceDef.LeadsGet);
const leadByIdURL = createGatewayAbsoluteUrl(resourceDef.LeadGetById);
const leadSearch = createGatewayAbsoluteUrl(resourceDef.LeadSearch);
const validateLeadByIdURL = createGatewayAbsoluteUrl(resourceDef.LeadValidateById);

export const getLeadsStream = (filters: any, onSuccessCb: (data: any) => void, onComplete: () => void, onError: (errMsg: string) => void) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    return;
  }

  jsonpipe.flow(leadStreamURL, {
    delimiter: '\n\n',
    success: onSuccessCb,
    error: onError,
    complete: onComplete,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": 'application/json'
    },
    data: JSON.stringify({
      ...filters,
    }),
    disableContentType: true,
    withCredentials: false
  });
}

export const getLeadById = (leadId: number): Promise<ILeadsContent> => axios.get(leadByIdURL + leadId)
  .then((res) => {
    return res.data;
  })
  .catch(() => {
    openErrorNotification("M56 service Error -> getLeadById");
    return null;
  });

export const validateLeadById = (leadId: number): Promise<any> => axios.get(validateLeadByIdURL + leadId)
  .then((res) => {
    return res.data;
  })
  .catch(() => {
    openErrorNotification("M56 service Error -> getLeadById");
    return null;
  });

export const getLeadSearch = (search: any): Promise<any> => axios.post(leadSearch, search)
  .then((res) => {
    return res.data.map((item: any) => {
      const [value] = Object.values(item);
      return { id: value, name: value };
    })
  })
  .catch(() => {
    openErrorNotification("M56 service Error -> getLeadById");
    return null;
  });
