import React from "react";
import { BaseForm } from "../base/BaseForm";
import { IBaseFormikProps } from "../base/interfaces/IBaseFormikProps";
import { Icon, Input } from "antd";

export interface ITextInputFProps {
  isNumeric?: boolean;
  isPassword?: boolean;
  isCurrency?: boolean;
  iconType?: string;
  type?: string;
  placeholder?: string;
}

const TextInputF: React.SFC<IBaseFormikProps & ITextInputFProps> = (props) => {
  function onChange(value: string) {
    props.customOnChangeCb && props.customOnChangeCb(value);
    if (props.isNumeric) return value.replace(/\D/g, "");
    if (props.isCurrency) {
      if (!value.includes(".") && !value.includes(",")) return value;
      if (value.includes("."))
        return `${value.split(".")[0]}.${value.split(".")[1].substring(0, 2)}`;
      else
        return `${value.split(",")[0]}.${value.split(",")[1].substring(0, 2)}`;
    }
    return value;
  }

  const inputValue = props.values[props.name];
  const titleValue = props.isPassword ? "" : inputValue;
  const type = props.isPassword ? { type: "password" } : {};
  const placeholder = props.placeholder;

  return (
    <BaseForm {...props}>
      <Input
        placeholder={placeholder}
        value={inputValue}
        title={titleValue}
        type={props.type}
        onChange={(event) =>
          props.setFieldValue(props.name, onChange(event.target.value))
        }
        onBlur={() => props.setFieldTouched(props.name)}
        disabled={props.disabled}
        readOnly={props.readOnly}
        allowClear={props.allowClear}
        prefix={props.iconType ? generateIcon(props.iconType) : null}
        {...type}
      />
    </BaseForm>
  );
};

export default TextInputF;

const generateIcon = (iconType: string): JSX.Element => {
  return <Icon type={iconType} style={{ color: "rgba(0,0,0,.25)" }} />;
};
