import axios from 'axios';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;
const couriersQuery = 'SELECT id, id as "key", name, country_id, bank_transfer, deleted_at, created_at, updated_at FROM li_couriers';
const couriersListQuery = 'SELECT id, name, country_id FROM li_couriers';

export const getCouriersDictionaryList = () => axios.post(sourceUrl, { data: { sql: couriersQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getCouriersDictionaryList');
        return [];
    });


export const getCouriers = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getCountriesDictionaryList');
        return [];
    });

export const getCouriersListD = () => axios.post(sourceUrl, { data: { sql: couriersListQuery } })
    .then((response) => { return response.data; })
    .catch(() => {
        openErrorNotification(' getCouriersListD');
        return [];
    });

export const updateCouriersDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' updateCouriersDictionary');
        return false;
    });

export const createCouriersDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then((response) => { return response.data.insertId; })
    .catch(() => {
        openErrorNotification(' createCouriersDictionary');
        return false;
    });

export const deleteCouriersDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification(' createCouriersDictionary');
        return false;
    });