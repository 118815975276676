import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const clearUrl = createGatewayAbsoluteUrl(resourceDef.ClearTransaction);
const rejectUrl = createGatewayAbsoluteUrl(resourceDef.RejectTransaction);

export const clearTransaction = (transaction_id: number) => axios.patch(clearUrl + `/${transaction_id}`)
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification("M49 service Error -> clearTransaction");
        return false;
    });

export const rejectTransaction = (transaction_id: number) => axios.delete(rejectUrl + `/${transaction_id}`)
    .then(() => { return true; })
    .catch(() => {
        openErrorNotification("M49 service Error -> rejectTransaction");
        return false;
    });