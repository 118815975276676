import AccountInformationsForm from '../forms/userInformations/UserInformationsForm';
import PasswordForm from '../forms/userPassword/UserPasswordForm';
import ProtectedComponent from '../../../../../components/authorizations/protectedComponents/ProtectedComponent';
import React from 'react';
import { getCompaniesListD } from '../../../../../services/m29_users_management/CompaniesService';
import { getManagersD, getUsersList } from '../../../../../services/m29_users_management/GeneralUserService';
import { getRolesListD } from '../../../../../services/m29_users_management/RoleService';
import { IDictionaryValue } from '../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IId } from '../../../../../common/interfaces/IId';
import { resources } from '../../../../../common/Resources';
import { Spin } from 'antd';
import { UserPermissionType } from '../../../../../models/IAppUserInfoModel';

export class UserInformationsContent extends React.Component<IId> {
    _isMounted = false;

    state = {
        loading: false,
        accountInformations: undefined,
        rolesD: Array<IDictionaryValue>(),
        companiesD: Array<IDictionaryValue>(),
        managersD: Array<IDictionaryValue>(),
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.setState({ loading: true });

        window.scrollTo(0, 0);
        this.getRolesD();
        this.getCompaniesD();
        this.getManagersD();

        this.getAccountInformations();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    public render() {
        return (
            <>
                {this.state.loading ? <div style={{ textAlign: "center" }}><Spin tip={resources.spin.loading} /></div> :
                    <div style={{ marginLeft: "10%", marginRight: "10%", marginTop: 10 }}>
                        <ProtectedComponent permission={UserPermissionType.ViewAccountInformationsForm}>
                            <AccountInformationsForm
                                id={this.props.id}
                                rolesD={this.state.rolesD}
                                managersD={this.state.managersD}
                                companiesD={this.state.companiesD}
                                {...this.state.accountInformations}
                            />
                        </ProtectedComponent>
                        <ProtectedComponent permission={UserPermissionType.ViewPasswordForm}>
                            <PasswordForm id={this.props.id} />
                        </ProtectedComponent>
                    </div>
                }
            </>
        )
    };

    getRolesD = async (): Promise<void> => {
        const object = {
            columns: [
                "id",
                "name"
            ],
            is_activate: 1
        };

        const rolesD = await getRolesListD(object);

        this.setState({ rolesD });
    }

    getCompaniesD = async (): Promise<void> => {
        const companiesD = await getCompaniesListD();

        this.setState({ companiesD });
    }

    getManagersD = async (): Promise<void> => {
        const managersD = await getManagersD();

        this.setState({ managersD });
    }

    getAccountInformations = async () => {
        this.setState({ loading: true });

        const object = {
            columns: [
                "name",
                "second_name",
                "short_name",
                "signature",
                "id_users_role",
                "is_activated",
                "id_account_manager",
                "id_company",
                "partner_token",
                "email",
                "skype",
                "telegram",
                "phone",
                "id_country",
                "description"
            ],
            id: this.props.id
        };

        await getUsersList(object)
            .then(response => {
                if (response)
                    this._isMounted && this.setState({ accountInformations: response[0] });
            })
            .finally(() => this._isMounted && this.setState({ loading: false }));
    }
}