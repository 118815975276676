import { useQuery } from "react-query";
import { getCountriesList } from "../../../../../services/m55_leads_statistics/LeadsStatisticsService";

const AVAILABLE_COUNTRIES = [1, 3, 7];

export const useCountryOptions = () =>
  useQuery("countries", () =>
    getCountriesList().then((countries) =>
      countries.filter((country: any) =>
        AVAILABLE_COUNTRIES.includes(country.id)
      )
    )
  );
