import * as React from 'react';
import { ISurveyQuestionMethods } from './interfaces/ISurveyQuestionMethods';
import { ISurveyQuestionProps } from './interfaces/ISurveyQuestionProps';
import { Select } from 'antd';

const { Option } = Select;

export const SurveyQuestion = (props: ISurveyQuestionProps & ISurveyQuestionMethods) => {
    const { id, question, question_type, options } = props;
    return (
        <>
            <p>{question}</p>
            {question_type === 'INPUT' ?
                (
                    <>
                        <input
                            type="text"
                            name={`${id}`}
                            onChange={(e: any) => props.handleChangeInput(e)}
                            required
                        />
                        <p style={{ color: 'red', marginTop: '5px' }}>{props.isAnswered === true || props.isAnswered === undefined ? '' : 'Answer is required'}</p>
                    </>
                )
                :
                (
                    <>
                        <Select
                            onChange={(e: any, v: any) => props.handleChangeSelect(e, v)}
                        >
                            {JSON.parse(options).map((option: string) => {
                                return (
                                    <Option
                                        key={option}
                                        title={`${id}`}>
                                        {option}
                                    </Option>
                                )
                            })}
                        </Select>
                        <p style={{ color: 'red', marginTop: '5px' }}>{props.isAnswered === true || props.isAnswered === undefined ? '' : 'Answer is required'}</p>
                    </>
                )}
        </>
    );
}