import * as React from 'react';
import { Button } from 'antd';
import { createAbsoluteUrl } from '../../helpers/UrlCreator';
import { IGoBackButtonProps } from './interfaces/IGoBackButtonProps';
import { Link } from 'react-router-dom';

export const GoBackButton = (props: IGoBackButtonProps) => {
    return (
        <Link to={createAbsoluteUrl(props.goTo)}>
            <Button className={props.buttonClassName} type='danger'>Back</Button>
        </Link>
    );
}