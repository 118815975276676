import * as React from 'react';
import { IHeroItemProps } from './interfaces/IHeroItemProps';

export const HeroItem = (props: IHeroItemProps) => {
    const { stat, descr } = props;
    return (
        <>
            <h4 className={`h1`}>{stat}</h4>
            <p className={`h5`}>{descr}</p>
        </>
    );
}