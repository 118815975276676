import * as React from "react";
import { Collapse } from "antd";
import { resources } from "../../../common/Resources";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";
import "../styles/Faq.scss";

const { Panel } = Collapse;

export class FaqContent extends React.Component {
  public render() {
    return (
      <article>
        <ViewHeader title="FAQ" />
        <Collapse expandIconPosition="right" accordion>
          <Panel header={resources.faq.header_1} key="1">
            <p>{resources.faq.content_1}</p>
          </Panel>
          <Panel header={resources.faq.header_2} key="2">
            <p>{resources.faq.content_2}</p>
          </Panel>
          <Panel header={resources.faq.header_3} key="3">
            <p>{resources.faq.content_3}</p>
          </Panel>
          <Panel header={resources.faq.header_4} key="4">
            <p>{resources.faq.content_4}</p>
          </Panel>
          <Panel header={resources.faq.header_5} key="5">
            <p>{resources.faq.content_5}</p>
          </Panel>
          <Panel header={resources.faq.header_6} key="6">
            {Array.isArray(resources.faq.content_6) ? (
              resources.faq.content_6.map((p: string, idx: number) => (
                <p key={idx}>{p}</p>
              ))
            ) : (
              <p>{resources.faq.content_6}</p>
            )}
          </Panel>
          <Panel header={resources.faq.header_7} key="7">
            <p>{resources.faq.content_7}</p>
          </Panel>
          <Panel header={resources.faq.header_8} key="8">
            <p>{resources.faq.content_8}</p>
          </Panel>
          <Panel header={resources.faq.header_9} key="9">
            <p>{resources.faq.content_9}</p>
          </Panel>
          <Panel header={resources.faq.header_10} key="10">
            <p>{resources.faq.content_10}</p>
          </Panel>
          <Panel header={resources.faq.header_11} key="11">
            <p>{resources.faq.content_11}</p>
          </Panel>
        </Collapse>
      </article>
    );
  }
}
