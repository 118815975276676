import * as React from 'react';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../../helpers/DisctionariesHelper';
import { IBasket } from '../../../interfaces/IBasket';
import { IDictionaryValue } from '../../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { IOrderInfo } from './interfaces/IOrderInfo';

const EMPTY_VALUE = '-----';

export class OrderInfo extends React.Component<IOrderInfo> {

    render() {
        const { ref, client_name, client_email, client_phone1, client_phone2, baskets } = this.props.order;
        return (
            <React.Fragment>
                <div>
                    {displayElement('REF', ref)}
                </div>
                <div>
                    {displayElement('Name', client_name)}
                    {displayElement('E-mail', client_email)}
                    {displayElement('Phone', client_phone1)}
                    {displayElement('Phone2', client_phone2)}
                </div>
                {displayProductInfo(baskets, this.props.productsD)}
            </React.Fragment>
        )
    }
};

const displayElement = (name: string, value?: any) => {
    if (!value || value === null) {
        value = EMPTY_VALUE;
    }

    return (
        <>
            <div style={{ width: 90, float: 'left' }}>
                {name}:
            </div>
            <div >
                <b>{value}</b>
            </div>
        </>
    )
}

const displayProductInfo = (basket: IBasket[], productsD: IDictionaryValue[]) => {
    let total_value = 0;
    if (basket.length > 0) {
        return (
            <>
                <div>
                    {basket.map(element => {
                        return (
                            <label key={element.id}>
                                <div>{findInDictionaryAndReturnValueWithSpin(element.id_product, productsD)}</div>
                                <div>Qut: {element.quantity}</div>
                            </label>
                        )
                    })}
                </div>
                <div>
                    {basket.map(element => {
                        total_value = total_value + element.subtotal_customer_price;
                        return (
                            <label key={element.id}>
                                <div>{element.subtotal_customer_price}</div>
                                <br />
                            </label>
                        )
                    })}
                    <div style={{ color: 'green' }}>{total_value}</div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div>
                    <b>{EMPTY_VALUE}</b>
                </div>
                <div>
                    {EMPTY_VALUE}
                </div>
            </>
        )
    }
}