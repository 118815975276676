export type status_class_name = "new" | "edited" | undefined;

export const formStatusClassName = (status: status_class_name): string => {
    switch (status) {
        case 'new':
            return 'new_form';
        case 'edited':
            return 'edited_form';
        default:
            return '';
    }
}