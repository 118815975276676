import React from 'react';
import { BaseForm } from '../base/BaseForm';
import { IBaseFormikProps } from '../base/interfaces/IBaseFormikProps';
import { Switch } from 'antd';

const SwitchF: React.SFC<IBaseFormikProps> = (props) => {
    function checkedFunction(event: any): void {
        props.setFieldValue(props.name, !props.values[props.name]);
        if (props.handleChange) props.handleChange(event);
    }

    return (
        <BaseForm {...props} >
            <Switch
                checked={Boolean(props.values[props.name])}
                onChange={checkedFunction}
            />
        </BaseForm>)
};

export default SwitchF;