import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { openErrorNotification } from '../../helpers/NotificationHelper';
import { resourceDef } from '../../common/ResourceDef';

const clickIdCheckDoubleUrl = createGatewayAbsoluteUrl(resourceDef.ClickIdCheckDouble);
const clickIdCheckDoubleDaysUrl = createGatewayAbsoluteUrl(resourceDef.ClickIdCheckDoubleDays);

export const getClickIdSettings = () => axios.get(clickIdCheckDoubleUrl)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error.response.status === 400) {
            return false;
        }
        console.log(error.response)
        openErrorNotification(error.message);
        return undefined;
    });

export const getClickIdSettingsDays = () => axios.get(clickIdCheckDoubleDaysUrl)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error.response.status === 400) {
            return undefined;
        }
        console.log(error.response)
        openErrorNotification(error.message);
        return undefined;
    });

export const setClickIdSettings = (enable: boolean) => axios.post(clickIdCheckDoubleUrl, enable)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        openErrorNotification(error.message);
        return [];
    });

export const setClickIdSettingsDays = (days: number) => axios.post(clickIdCheckDoubleDaysUrl, days)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        openErrorNotification(error.message);
        return [];
    });
    
