import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { CouriersDictionaryContent } from './contents/CouriersDictionaryContent';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class CouriersDictionaryViewInner extends React.Component<ISetActiveMenuItem> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.couriers_dictionary);
    }

    render() {
        return (
            <CouriersDictionaryContent />
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const CouriersDictionaryView: React.ComponentClass = connect(null, mapDispatchToProps)(CouriersDictionaryViewInner);