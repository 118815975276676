import React from "react";

type FilterCheckboxProps = {
  style?: any;
  label: string;
  checked: boolean;
  onChange: (event: any) => void;
};

export const FilterCheckbox = ({
  style,
  label,
  checked,
  onChange,
}: FilterCheckboxProps) => (
  <label style={{ cursor: "pointer", marginBottom: "10px", ...style }}>
    <input
      type="checkbox"
      style={{ marginRight: "5px", position: "relative", top: "2px" }}
      checked={checked}
      onChange={onChange}
    />
    {label}
  </label>
);
