import React from "react";
import { Table, Button, Input, Icon, Select, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { resources } from "../../../common/Resources";
import { hasPermission } from "../../../authorizations/AuthorizationLogic";
import "./styles/DataTableStyle.css";

export class DataTable extends React.Component {
  state = {
    selectedRowKeys: [],
    expandedKeys: [],
    loading: false,
    searchText: "",
    selectedAction: null,
    currentDataSource: [],
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        selectedRowKeys: [],
        expandedKeys: [],
        selectedAction: null,
        currentDataSource: this.props.data ? this.props.data : [],
      });
    }
  };

  selectedRowKeysAction = () => {
    this.setState({ selectedRowKeys: [] });
  };

  onRow = ({ key }) =>
    this.state.expandedKeys.includes(key) && { className: "expand-parent" };

  onExpand = (expanded, { key }) => {
    const keys = this.state.expandedKeys;
    const expandedKeys = !expanded
      ? keys.concat(key)
      : keys.filter((k) => k !== key);
    this.setState({ expandedKeys });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, width: 214 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8 }}
        />
        <br />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90 }}
        >
          {resources.buttons.search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90, marginLeft: 8 }}
        >
          {resources.buttons.reset}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] ? record[dataIndex] : "")
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  // Metoda do podkreślania wierszy w tabeli o szukanych parametrach:
  // columnNameToCheck - nazwa kolumny (string)
  // valueToCheck - wartość do sprawdzenia (typ taki jak w sprawdzanej kolumnie)
  findInvalidRow = (record) => {
    if (
      record[this.props.columnNameToCheck] ||
      this.props.valueToCheck ||
      record[this.props.columnNameToCheck] === 0
    ) {
      let result = false;
      if (this.props.columnNameToCheck === "deleted_at") {
        result =
          record[this.props.columnNameToCheck] !== this.props.valueToCheck;
      } else {
        result =
          record[this.props.columnNameToCheck] === this.props.valueToCheck;
      }

      return result ? "alert" : "";
    } else {
      return "";
    }
  };

  selectAll = () => {
    if (
      this.state.currentDataSource.length === this.state.selectedRowKeys.length
    ) {
      this.setState({
        selectedRowKeys: [],
      });
    } else {
      this.setState({
        selectedRowKeys: this.state.currentDataSource.map((row) => row.id),
      });
    }
  };

  handleActionChange = (key) => {
    this.setState({
      selectedAction: key ? key : undefined,
    });
  };

  handleActionSubmit = () => {
    const action = this.props.actions.find(
      (action) => action.key === this.state.selectedAction
    );
    if (action) {
      action.callback(this.state.selectedRowKeys);
    }
  };

  handleChange = (_pagination, _filters, _sorter, extra) => {
    const { currentDataSource } = extra;
    this.setState({
      currentDataSource,
    });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelectAll: this.selectAll,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const loadingIcon = this.props.loading;

    const actions = this.props.actions
      ? this.props.actions.filter((action) => hasPermission(action.permission))
      : [];

    return (
      <>
        {this.props.rowSelection &&
          hasSelected &&
          !loading &&
          actions.length > 0 && (
            <div className={"actionsContainer"}>
              <span className={"actionSpan"}>
                {resources.labels.global_actions}:
              </span>
              <Select
                allowClear
                className={"actionsSelect"}
                onChange={this.handleActionChange}
                value={this.state.selectedAction}
              >
                {actions.map((action) => (
                  <Select.Option key={action.key} value={action.key}>
                    {action.name}
                  </Select.Option>
                ))}
              </Select>
              {this.state.selectedAction && (
                <Button
                  className={"actionsConfirmBtn"}
                  onClick={this.handleActionSubmit}
                >
                  {resources.buttons.confirm}
                </Button>
              )}
              <span style={{ marginLeft: 8 }}>
                {hasSelected
                  ? `${resources.labels.selected} ${selectedRowKeys.length} ${resources.labels.items}`
                  : ""}
              </span>
            </div>
          )}
        <Table
          scroll={{
            x: this.props.scrollX ? this.props.scrollX : true,
            y: this.props.scrollY,
          }}
          bordered={true}
          size="small"
          dataSource={this.props.data}
          loading={loadingIcon}
          key={this.props.tableName}
          onRow={this.onRow}
          onChange={this.handleChange}
          expandIcon={({ expanded, record }) => {
            if (!this.props.expandedRowRender) {
              return;
            }
            const iconSybol = expanded ? "-" : "+";
            const iconElement = (
              <div
                className={"expandIcon"}
                onClick={(e) => this.onExpand(expanded, record)}
              >
                {iconSybol}
              </div>
            );
            const { expandTooltip } = this.props;
            if (expandTooltip) {
              return (
                <Tooltip
                  title={expanded ? expandTooltip.hide : expandTooltip.show}
                >
                  {iconElement}
                </Tooltip>
              );
            }
            return iconElement;
          }}
          expandedRowKeys={this.state.expandedKeys}
          expandedRowRender={this.props.expandedRowRender}
          expandRowByClick={this.props.expandRowByClick}
          pagination={
            this.props.pagination === false
              ? false
              : {
                  defaultPageSize: this.props.defaultPageSize
                    ? this.props.defaultPageSize
                    : 20,
                  pageSizeOptions: ["10", "20", "30", "40", "50", "100", "200"],
                  showSizeChanger:
                    this.props.showSizeChanger === undefined
                      ? true
                      : this.props.showSizeChanger,
                  showQuickJumper:
                    this.props.showQuickJumper === undefined
                      ? true
                      : this.props.showQuickJumper,
                  hideOnSinglePage: this.props.hideOnSinglePage,
                  simple: this.props.simple,
                  position: this.props.position
                    ? this.props.position
                    : "bottom",
                  showTotal:
                    this.props.showTotal === undefined
                      ? (total, range) =>
                          `${range[0]}-${range[1]} ${resources.others.of} ${total} ${resources.others.rows}`
                      : null,
                }
          }
          rowClassName={(record) => this.findInvalidRow(record)}
          rowSelection={this.props.rowSelection ? rowSelection : undefined}
        >
          {this.props.columns}
        </Table>
      </>
    );
  }
}
