import axios from 'axios';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { ResourceDef } from '../../common/ResourceDef';

const sourceUrl = ResourceDef.TemplateGateway;

export const getCouriersApiMappingDictionaryList = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getCouriersApiMappingDictionaryList');
        return [];
    });

export const createCouriersApiMappingDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(response => response.data.insertId)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'createCouriersApiMappingDictionary');
        return undefined;
    });

export const updateCouriersApiMappingDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'updateCouriersApiMappingDictionary');
        return false;
    });

export const deleteCouriersApiMappingDictionary = (query: string) => axios.post(sourceUrl, { data: { sql: query } })
    .then(() => true)
    .catch((error) => {
        displayHttpStatusCodeInformation(error, 'deleteCouriersApiMappingDictionary');
        return false;
    });