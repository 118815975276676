import * as React from 'react';
import { Table, Tooltip, Icon } from 'antd';
import InformationModalDialog from '../../../../../../components/others/modal/InformationModalDialog';
import { DataTable } from '../../../../../../components/others/data/DataTable';
import PageForm from '../../../../../_common/components/pages/forms/PageForm';
import { convertNumberToDateTimeString } from '../../../../../../helpers/DateHelpers';
import { displayIconIsVisibleWithTooltip } from '../../../../../../helpers/IconsGeneratorHelper';
import { convertBooleanYesOrNoReactElement } from '../../../../../../helpers/BooleanHelper';
import { UserPermissionType } from '../../../../../../models/IAppUserInfoModel';
import ProtectedModal from '../../../../../../components/authorizations/protectedComponents/ProtectedModal';
import moment from 'moment';
import { getVisibleFilters, getYesOrNoFilters } from '../../../../../../common/dictionaries/Filters';

const { Column } = Table;

export class PagesTable extends DataTable {
    render() {
        const columns = [
            <Column
                title='Name'
                dataIndex='name'
                key='name'
                align='center'
                width='200px'
                render={(record) => { return record }}
                {...this.getColumnSearchProps('name')}
            />,
            <Column
                title='Preview'
                dataIndex='url'
                key='preview'
                align='center'
                render={(record) => { return <a href={record} target="_blank" rel="noopener noreferrer">View&nbsp;<Icon type="link" /></a> }}
            />,
            <Column
                title='Visible'
                key='is_visible'
                align='center'
                filters={getVisibleFilters()}
                onFilter={(value, record) => record.is_visible === value}
                render={(record) => {
                    return (
                        displayIconIsVisibleWithTooltip(record.is_visible)
                    )
                }}
            />,
            <Column
                title='Created at'
                key='created_at'
                align='center'
                width='160px'
                sorter={(a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at))}
                render={(record) => {
                    return (
                        <p style={{ margin: 'auto auto' }}>{convertNumberToDateTimeString(record.created_at)}</p>
                    )
                }}
            />,
            <Column
                title='Top'
                key='is_top'
                align='center'
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_top === value}
                render={(record) => (
                    convertBooleanYesOrNoReactElement(record.is_top)
                )}
            />,
            <Column
                title='Mobile'
                key='is_mobile'
                align='center'
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_mobile === value}
                render={(record) => (
                    convertBooleanYesOrNoReactElement(record.is_mobile)
                )}
            />,
            <Column
                title='Desktop'
                key='is_desktop'
                align='center'
                filters={getYesOrNoFilters()}
                onFilter={(value, record) => record.is_desktop === value}
                render={(record) => (
                    convertBooleanYesOrNoReactElement(record.is_desktop)
                )}
            />,
            <Column
                title='Options'
                key='options'
                align='center'
                width='80px'
                render={(record) => {
                    return (
                        <ProtectedModal permission={UserPermissionType.EditLandingOrPrelandingModalButton}>
                            <Tooltip placement="rightTop" title="Edit">
                                <div>
                                    <InformationModalDialog
                                        modalTitle={'Edit page'}
                                        icon={"form"}
                                        withHideMethod={true}
                                        //disabled={record.deleted_at !== null}
                                    >
                                        <PageForm
                                            {...record}
                                            product_id={this.props.productId}
                                            updatePage={this.props.updatePage}
                                        />
                                    </InformationModalDialog>
                                </div>
                            </Tooltip>
                        </ProtectedModal>
                    )
                }} />
        ];
        return (
            <DataTable
                tableName={'pages'}
                columns={columns}
                data={this.props.data}
                pagination={false}
                loading={this.props.loading}
            />
        )
    }
}