import * as React from "react";
import { DataTable } from "../../../components/others/data/DataTable";
import { resources } from "../../../common/Resources";
import { Table, Tooltip, Button, Icon } from "antd";
import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import CompanieForm from "../forms/CompanieForm";
import { convertBooleanYesOrNoReactElement } from "../../../helpers/BooleanHelper";
import ProtectedModal from "../../../components/authorizations/protectedComponents/ProtectedModal";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";
import { Link } from "react-router-dom";
import { createAbsoluteUrl } from "../../../helpers/UrlCreator";

const { Column } = Table;

export class CompaniesTable extends DataTable {
  render() {
    const columns = [
      <Column
        title={resources.columnTitle.id}
        dataIndex="id"
        key="id"
        align="center"
        width={80}
        sorter={(a, b) => a.id - b.id}
        {...this.getColumnSearchProps("id")}
      />,
      <Column
        title={resources.columnTitle.name}
        dataIndex="name"
        key="name"
        align="center"
        width={300}
        sorter={(a, b) =>
          (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
        }
        {...this.getColumnSearchProps("name")}
      />,
      <Column
        title={resources.columnTitle.accountCounter}
        key="accountCounter"
        align="center"
        width={160}
        sorter={(a, b) => a.accountCounter - b.accountCounter}
        {...this.getColumnSearchProps("accountCounter")}
        render={(record) =>
          this.props.loading_accounts ? (
            <Icon type="loading" spin={true} />
          ) : (
            record.accountCounter
          )
        }
      />,
      <Column
        title={resources.columnTitle.description}
        key="description"
        align="center"
        sorter={(a, b) =>
          (a.description ? a.description : "").localeCompare(
            b.description ? b.description : ""
          )
        }
        {...this.getColumnSearchProps("description")}
        render={(record) => (
          <Tooltip title={record.description}>
            {record.description !== null && record.description.length > 45
              ? record.description.substring(0, 50) + "..."
              : record.description}
          </Tooltip>
        )}
      />,
      <Column
        title={resources.columnTitle.isInternal}
        key="internal"
        width={120}
        align="center"
        sorter={(a, b) => a.internal - b.internal}
        render={(record) => convertBooleanYesOrNoReactElement(record.internal)}
      />,
      <Column
        title={resources.columnTitle.options}
        key="options"
        width={110}
        align="center"
        render={(record) => (
          <>
            <ProtectedModal
              permission={UserPermissionType.EditCompanieModalButton}
            >
              <InformationModalDialog
                modalTitle={resources.title.editCompany}
                buttonTooltip={resources.title.editCompany}
                icon={"edit"}
                disabled={this.props.loading}
                withHideMethod={true}
                width={800}
              >
                <CompanieForm
                  id={record.id}
                  name={record.name}
                  description={record.description}
                  internal={record.internal}
                  address_line1={record.address_line1}
                  address_line2={record.address_line2}
                  city={record.city}
                  state={record.state}
                  zip={record.zip}
                  id_country={record.id_country}
                  is_active={record.is_active}
                  countriesD={this.props.countriesD}
                  refreshList={this.props.refreshList}
                />
              </InformationModalDialog>
            </ProtectedModal>
            <Tooltip
              placement="rightTop"
              title={resources.tooltips.viewCompany}
            >
              <Link
                permission={UserPermissionType.EditEmployeesRoleLinkButton}
                to={createAbsoluteUrl("finance_admin/" + record.id)}
              >
                <Button icon="arrow-right" />
              </Link>
            </Tooltip>
            <ProtectedComponent
              permission={UserPermissionType.SeeCompanyMembersLinkButton}
            >
              <Tooltip
                placement="rightTop"
                title={resources.tooltips.see_company_members}
              >
                <Link to={createAbsoluteUrl("partners/" + record.id)}>
                  <Button icon="team" />
                </Link>
              </Tooltip>
            </ProtectedComponent>
          </>
        )}
      />,
    ];

    const updated_data = this.props.data.map((item) => {
      return { ...item, accountCounter: this.getNumberOfAccounts(item.id) };
    });

    return (
      <DataTable
        tableName={this.props.tableName}
        columns={columns}
        data={updated_data}
        loading={this.props.loading}
        pagination={true}
      />
    );
  }

  getNumberOfAccounts = (id) => {
    const { length: count } = this.props.accounts.filter(
      (x) => x.id_company === id
    );

    return count;
  };
}
