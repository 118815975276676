import axios from "axios";
import { createGatewayAbsoluteUrl } from "../../helpers/UrlCreator";
import { displayHttpStatusCodeInformation } from "../../helpers/HttpStatusCodeHelper";
import { openErrorNotification } from "../../helpers/NotificationHelper";
import { resourceDef } from "../../common/ResourceDef";

const userInfUrl = createGatewayAbsoluteUrl(resourceDef.UserInfo);
const getUsersListUrl = createGatewayAbsoluteUrl(resourceDef.GetUsersList);
const usersUrl = createGatewayAbsoluteUrl(resourceDef.Users);

const managersD = {
  columns: ["id", "name"],
  id_users_role: 7,
};

const usersWithEmail = {
  columns: ["email", "id", "id_company"],
};

const partnersWithEmail = {
  columns: ["email", "id"],
  id_users_role: 3,
};

export const getUserInf = (obj?: any) =>
  axios
    .post(userInfUrl, { ...obj })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getUserInf M29");
      return undefined;
    });

export const getUsersList = (object: any) =>
  axios
    .post(getUsersListUrl, { ...object })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getUsersList M29");
    });

export const createUser = (obj: any) =>
  axios
    .put(usersUrl, { ...obj })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.data.data.message ===
        `The 'email' field fails to be unique`
      )
        openErrorNotification(
          "This email is already take. Please use different email"
        );

      console.log("createUser M29");
      console.log(error.response.data);
    });

export const updateUserPassword = (id: number, obj: any) =>
  axios
    .patch(usersUrl + `/${id}`, { ...obj })
    .then((response) => {
      return { error: false, status: response.status, message: "" };
    })
    .catch((error) => {
      console.log(error, "updateUserPassword M29");
      return {
        error: true,
        status: error.response.data.status,
        message: error.response.data.message,
      };
    });

export const updateUser = (id: number, obj: any) =>
  axios
    .patch(usersUrl + `/${id}`, { ...obj })
    .then(() => {
      return true;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "updateUser M29");
      return false;
    });

export const deleteUser = (id: number) =>
  axios
    .delete(usersUrl + `/${id}`)
    .then(() => {
      return true;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "deleteUser M29");
      return false;
    });

export const getManagersD = () =>
  axios
    .post(getUsersListUrl, { ...managersD })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getUsersList M29");
    });

export const getUsersWithEmail = () =>
  axios
    .post(getUsersListUrl, { ...usersWithEmail })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getUsersList M29");
    });

export const getPartnersByEmail = (searchPhrase: string, params: any = {}) =>
  axios
    .post(getUsersListUrl, {
      ...partnersWithEmail,
      email: searchPhrase,
      ...params,
    })
    .then((response: any) => {
      const partnersD = response.data.map((item: any) => {
        return { id: item.id, name: `${item.email} [${item.id}]` };
      });
      return partnersD;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getUsersList M29");
    });

export const getPartnersByIds = (ids: Array<number>) =>
  axios
    .post(getUsersListUrl, { ...usersWithEmail, id: ids })
    .then((response: any) => {
      const partnersD = response.data.map((item: any) => {
        return { id: item.id, name: `${item.email} [${item.id}]` };
      });
      return partnersD;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getUsersList M29");
    });
