import React from "react";

type TraceItem = {
  title: string;
  desc: string;
  value: string;
  error: boolean;
};

export const VerificationTrace = ({ trace }: { trace: Array<TraceItem> }) => {
  return (
    <div>
      <VerificationTraceHeader />

      {trace.map((traceItem, index) => (
        <VerificationTraceItem key={index} traceItem={traceItem} />
      ))}
    </div>
  );
};

const VerificationTraceHeader = () => (
  <div
    style={{
      display: "flex",
      marginBottom: "10px",
      borderBottom: "1px solid #dbdbdb",
    }}
  >
    <div style={{ width: "110px", fontWeight: "bold", marginRight: "10px" }}>
      Validation steps
    </div>
    <div style={{ width: "150px", fontWeight: "bold", marginRight: "10px" }}>
      Description
    </div>
    <div style={{ width: "110px", fontWeight: "bold", marginRight: "10px" }}>
      Output value
    </div>
    <div style={{ width: "75px", fontWeight: "bold" }}>Has error</div>
  </div>
);

const VerificationTraceItem = ({ traceItem }: { traceItem: TraceItem }) => (
  <div style={{ display: "flex", marginBottom: "10px" }}>
    <div style={{ width: "110px", marginRight: "10px" }}>{traceItem.title}</div>
    <div style={{ width: "150px", marginRight: "10px" }}>{traceItem.desc}</div>
    <div
      style={{ width: "110px", marginRight: "10px", wordBreak: "break-all" }}
    >
      {traceItem.value}
    </div>
    <div style={{ width: "75px" }}>{traceItem.error ? "Yes" : "No"}</div>
  </div>
);
