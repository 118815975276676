import * as React from 'react';
import CitiesDictionaryForm from '../forms/LocationDictionaryForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import { deleteLocationsDictionary, getLocationsDictionaryList } from '../../../../services/dictionaries/LocationsDivtionaryService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { FoundRowsNumber } from '../../../../components/common/fountRowsNumber/FountRowsNumber';
import { getCitiesListD } from '../../../../services/dictionaries/CitiesDivtionaryService';
import { getCouriersListD } from '../../../../services/dictionaries/CouriersDivtionaryService';
import { getDictionary } from '../../../../helpers/DisctionariesHelper';
import { getDistrictsListD } from '../../../../services/dictionaries/DistrictsDivtionaryService';
import { getPostCodesListD } from '../../../../services/dictionaries/PostCodesDivtionaryService';
import { getProvincesListD } from '../../../../services/dictionaries/ProvincesDivtionaryService';
import { getSubdistrictsListD } from '../../../../services/dictionaries/SubDistrictsDivtionaryService';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { ILocations } from '../interfaces/ILocations';
import { Left } from '../../../../components/controls/left/Left';
import { LocationsDictionaryTable } from '../table/LocationsDictionaryTable';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageInf } from '../../../../components/common/pageInf/PageInf';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { Right } from '../../../../components/controls/right/Right';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';

export class LocationsDictionaryContent extends React.Component {
    _isMounted = false;

    state = {
        loading: false,
        locationsList: Array<ILocations>(),
        countriesD: Array<IDictionaryValue>(),
        citiesD: Array<IDictionaryValue>(),
        districtsD: Array<IDictionaryValue>(),
        subdistrictsD: Array<IDictionaryValue>(),
        postCodesD: Array<IDictionaryValue>(),
        couriersD: Array<IDictionaryValue>(),
        provincesD: Array<IDictionaryValue>(),
        selected_country: undefined,

        id: undefined,
        city_id: undefined,
        province_id: undefined,
        district_id: undefined,
        subdistrict_id: undefined,
        post_code_id: undefined,
        courier_id: undefined,
        bank_transfer: undefined,
        destination_code: undefined,
        island: undefined,
        lead_time: undefined,
        lead_time_text: undefined,
        delivery_cost: undefined,
        return_cost: undefined,
        insurance_fee: undefined,
        cod_fee: undefined,
        admin_fee: undefined,
    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.getCitiesList();
        this.getCouriersList();
        this.getDistrictsList();
        this.getSubdistrictsList();
        this.getPostCodesList();
        this.getProvincesList();

        this.setState({ countriesD: getDictionary('countriesNameD') });

        this.getLocationsList();
    }

    componentWillUnmount = () =>
        this._isMounted = false;

    public render() {
        const { locationsList, loading, selected_country, countriesD, citiesD, districtsD, subdistrictsD, postCodesD, couriersD, provincesD } = this.state;

        let filteredData = locationsList;

        if (selected_country)
            filteredData = filteredData.filter(x => x.country_id === selected_country);

        const { length: count } = filteredData;

        return (
            <TableContainer>
                {/* TODO: Filtry do ustalenia */}
                {/* <LocationsFiltersForm
                    // Dictionaries
                    countriesD={this.state.countriesD}
                    citiesD={this.state.citiesD}
                    districtsD={this.state.districtsD}
                    subdistrictsD={this.state.subdistrictsD}
                    postCodesD={this.state.postCodesD}
                    couriersD={this.state.couriersD}
                    provincesD={this.state.provincesD}
                    // Props
                    loading={this.state.loading}
                    id={this.state.id}
                    country_id={this.state.country_id}
                    city_id={this.state.city_id}
                    province_id={this.state.province_id}
                    district_id={this.state.district_id}
                    subdistrict_id={this.state.subdistrict_id}
                    post_code_id={this.state.post_code_id}
                    courier_id={this.state.courier_id}
                    bank_transfer={this.state.bank_transfer}
                    destination_code={this.state.destination_code}
                    island={this.state.island}
                    lead_time={this.state.lead_time}
                    lead_time_text={this.state.lead_time_text}
                    delivery_cost={this.state.delivery_cost}
                    return_cost={this.state.return_cost}
                    insurance_fee={this.state.insurance_fee}
                    cod_fee={this.state.cod_fee}
                    admin_fee={this.state.admin_fee}
                    // Methods
                    onChange={this.onChange}
                    onChangeSelect={this.onChangeSelect}
                /> */}
                <PageInf title={resources.pageTitle.locationsDictionary} />
                <Left>
                    <RefreshTableButton
                        className='marginRigth marginBottom marginTopRefreshButton'
                        loading={loading}
                        onClick={this.getLocationsList}
                    />
                </Left>
                <FieldInfo>{resources.labels.country}</FieldInfo>
                <SelectOnChange
                    className='marginBottom'
                    onChange={this.onChangeSelect}
                    name='selected_country'
                    placeholder={resources.placeholders.select}
                    value={selected_country}
                    items={countriesD}
                    disabled={loading}
                    width={180}
                    allowClear
                />
                <ProtectedModal permission={UserPermissionType.AddNewLocationModalButton}>
                    <Right>
                        <InformationModalDialog
                            modalTitle={resources.modalTitle.addNewLocation}
                            buttonTitle={resources.modalButtonsTitle.addNewLocation}
                            buttonType='primary'
                            buttonTooltip={resources.modalTooltips.addNewLocation}
                            icon={"plus"}
                            withHideMethod={true}
                            width={1000}
                            buttonClassName='addNew marginBottom'
                            disabled={loading}
                        >
                            <CitiesDictionaryForm
                                addObjToArray={this.addObjToArray}
                                countriesD={countriesD}
                                citiesD={citiesD}
                                districtsD={districtsD}
                                subdistrictsD={subdistrictsD}
                                postCodesD={postCodesD}
                                couriersD={couriersD}
                                provincesD={provincesD}
                            />
                        </InformationModalDialog>
                    </Right>
                </ProtectedModal>
                <Right>
                    <FoundRowsNumber
                        className="marginRigth"
                        count={count}
                    />
                </Right>
                <LocationsDictionaryTable
                    loading={loading}
                    data={filteredData}
                    deleteLocation={this.deleteLocation}
                    updateObjInArray={this.updateObjInArray}
                    countriesD={countriesD}
                    citiesD={citiesD}
                    districtsD={districtsD}
                    subdistrictsD={subdistrictsD}
                    postCodesD={postCodesD}
                    couriersD={couriersD}
                    provincesD={provincesD}
                />
            </TableContainer>
        )
    };

    onChange = (event: any) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeSelect = (name: string, value: number): void =>
        this.setState({ [name]: value });

    getLocationsList = () => {
        this.setState({ loading: true });

        getLocationsDictionaryList()
            .then(locationsList => { this._isMounted && this.setState({ locationsList }); })
            .finally(() => { this._isMounted && this.setState({ loading: false }) })
    }


    getCitiesList = () => {
        getCitiesListD()
            .then(citiesD => { this._isMounted && this.setState({ citiesD }); });
    }

    getDistrictsList = () => {
        getDistrictsListD()
            .then(districtsD => { this._isMounted && this.setState({ districtsD }); });
    }

    getSubdistrictsList = () => {
        getSubdistrictsListD()
            .then(subdistrictsD => { this._isMounted && this.setState({ subdistrictsD }); });
    }

    getCouriersList = () => {
        getCouriersListD()
            .then(couriersD => { this._isMounted && this.setState({ couriersD }); });
    }

    getPostCodesList = () => {
        getPostCodesListD()
            .then(postCodesD => { this._isMounted && this.setState({ postCodesD }); });
    }

    getProvincesList = () => {
        getProvincesListD()
            .then(provincesD => { this._isMounted && this.setState({ provincesD }); });
    }

    deleteLocation = async (id: number) => {
        const query = `DELETE FROM li_locations WHERE id = ${id}`;

        await deleteLocationsDictionary(query)
            .then((response) => {
                if (response) {
                    this.deleteFromArray(id);
                }
                else {
                    openErrorNotification(resources.statement.there_was_a_problem_removing_location);
                }
            })
    }

    deleteFromArray = (id: number) => {
        this.setState({ loading: true });

        const locationsList = this.state.locationsList.filter(x => x.id !== id);

        this.setState({
            locationsList,
            loading: false
        });

        openSuccessNotification(resources.statement.location_deleted);
    }

    addObjToArray = (location: ILocations) => {
        this.setState({ locationsList: [...this.state.locationsList, location] });
    }

    updateObjInArray = (obj: ILocations): void => {
        const { locationsList } = this.state;
        const index = locationsList.findIndex(x => x.id === obj.id);

        locationsList[index].country_id = obj.country_id;
        locationsList[index].city_id = obj.city_id;
        locationsList[index].province_id = obj.province_id;
        locationsList[index].district_id = obj.district_id;
        locationsList[index].subdistrict_id = obj.subdistrict_id;
        locationsList[index].post_code_id = obj.post_code_id;
        locationsList[index].courier_id = obj.courier_id;
        locationsList[index].bank_transfer = obj.bank_transfer;
        locationsList[index].leadtime = obj.leadtime;
        locationsList[index].leadtime_text = obj.leadtime_text;
        locationsList[index].destination_code = obj.destination_code;
        locationsList[index].island = obj.island;
        locationsList[index].delivery_cost = obj.delivery_cost;
        locationsList[index].return_cost = obj.return_cost;
        locationsList[index].insurance_fee = obj.insurance_fee;
        locationsList[index].cod_fee = obj.cod_fee;
        locationsList[index].admin_fee = obj.admin_fee;
        locationsList[index].updated_at = obj.updated_at;

        this.setState({ locationsList });
    }
}