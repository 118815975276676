import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../../helpers/UrlCreator';
import { displayHttpStatusCodeInformation } from '../../helpers/HttpStatusCodeHelper';
import { resourceDef } from '../../common/ResourceDef';

const productsUrl = createGatewayAbsoluteUrl(resourceDef.Products);

const productsD = { columns: ['name', 'country_id', 'id'] };

export const createProduct = (obj: any) => axios.put(productsUrl, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'createProduct M30');
        return undefined;
    });

export const getProducts = (obj: any) => axios.post(productsUrl, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getProducts M30');
        return [];
    });

export const getProductDetails = (obj: any) => axios.post(productsUrl, { ...obj })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getProductDetails M30');
        return [];
    });

export const getProductsD = () => axios.post(productsUrl, { ...productsD })
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'getProductsD M30');
        return [];
    });

export const deleteProducts = (id: number) => axios.delete(productsUrl + `/${id}`)
    .then(response => { return response.data; })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'deleteProducts M30');
        return [];
    });

export const updateProduct = (id: number, obj: any) => axios.patch(productsUrl + `${id}`, { ...obj })
    .then(response => { return true })
    .catch(error => {
        displayHttpStatusCodeInformation(error, 'updateProducts M30');
        return false;
    });