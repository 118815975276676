import * as React from "react";
import greenWave from "./images/greenWave.png";
import highPayouts from "./images/highPayouts.png";
import logo from "../../../common/images/full-logo-light.svg";
import ownCallCenter from "./images/ownCallCenter.png";
import personalManager from "./images/personalManager.png";
import rectangleDots from "./images/rectangleDots.png";
import rectangleDotsBlue from "./images/rectangleDotsBlue.png";
import regularPayouts from "./images/regularPayouts.png";
import screen from "./images/screen.png";
import Slider from "react-slick";
import squareDots from "./images/squareDots.png";
import squareDotsBlue from "./images/squareDotsBlue.png";
import styles from "./styles/LandingPageContent.module.scss";
import waveBg from "./images/wave_bg.png";
import { AboutItem } from "../components/aboutItem/AboutItem";
import { BecomeAPartnerBtn } from "../components/becomeAPartnerBtn/BecomeAPartnerBtn";
import { createAbsoluteUrl } from "../../../helpers/UrlCreator";
import { fetchDictNoAuth } from "../../../helpers/DisctionariesHelper";
import { getProductImages } from "../../../services/m30_products/images/ProductImagesService";
import { getProductTopOffers } from "../../../services/m30_products/topOffers/ProductTopOffersService";
import { HeroItem } from "../components/heroItem/HeroItem";
import { IChangeLang } from "../../../models/IChangeLang";
import { IProductImageFilters } from "../../../services/m30_products/images/interfaces/IProductImageFilters";
import { LandingFooter } from "../components/landingFooter/LandingFooter";
import { LandingForm } from "../components/landingForm/LandingForm";
import { LandingNav } from "../components/landingNav/LandingNav";
import { LandingSliderArrow } from "../components/landingSliderArrow/LandingSliderArrow";
import { LangPicker } from "../components/langPicker/LangPicker";
import { PopUpModal } from "../../../components/popUpModal/PopUpModal";
import { ProductCard } from "../components/productCard/ProductCard";
import { resources } from "../../../common/Resources";
import "./styles/customAnt.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class LandingPageContent extends React.Component<IChangeLang> {
  _isMounted = true;

  state = {
    topOffers: [],
    countries: [],
    src_list: [],
    visible: false,
  };

  componentDidMount = () => {
    this.getTopOffers();
    this.getCountries();
  };

  componentWillUnmount = () => (this._isMounted = false);

  render() {
    return (
      <div className={styles.landingPageContainer}>
        {/* header logo / navigation / language picker / link to login page */}
        <header className={`${styles.maxWidth1200} ${styles.headerGrid}`}>
          <img width="120" src={logo} alt="logo" />
          <LandingNav />
          <div className={styles.headerLangLoginBlock}>
            <LangPicker changeLang={this.props.changeLang} />
            <a href={createAbsoluteUrl("login")} className={`h4__semi`}>
              {resources.landingPage.logIn}
            </a>
          </div>
        </header>
        {/* end header */}
        <main>
          {/* hero */}
          <section className={styles.s_hero}>
            {/* screen image */}
            <div className={styles.screen}>
              <img src={screen} alt="screen from the app" />
            </div>
            <h3 className={`h4__semi`}>
              {resources.landingPage.certifiedProducts}
            </h3>
            <h2 className={`h1`}>
              {resources.landingPage.afiliateNetwork}
              <br />
              {resources.landingPage.saudiArabAndIndonesia}
            </h2>
            <BecomeAPartnerBtn />
            {/* stats */}
            <article className={styles.statsGrid}>
              <div className={styles.statsGrid__item}>
                <HeroItem
                  stat="1700+"
                  descr={resources.landingPage.activePartners}
                />
              </div>
              <div className={styles.statsGrid__item}>
                <HeroItem
                  stat="37"
                  descr={resources.landingPage.offersInAsia}
                />
              </div>
              <div className={styles.statsGrid__item}>
                <HeroItem
                  stat="4"
                  descr={resources.landingPage.yearsOnTheMarket}
                />
              </div>
              <div className={styles.statsGrid__item}>
                <HeroItem stat="5" descr={resources.landingPage.countries} />
              </div>
              {/* wave in the background */}
              <img src={waveBg} className={styles.waveBg} alt="wave" />
            </article>
            {/* stats end */}
          </section>
          {/* end hero */}
          {/* about */}
          <section className={`${styles.s_about}`} id="about">
            {/* dots with absolute position - relative to s_about, visible on mobile */}
            <img
              className={styles.s_about__left__squareDots__mobile}
              src={squareDots}
              alt="green dots"
            />
            <img
              className={styles.s_about__left__rectangleDots__mobile}
              src={rectangleDots}
              alt="green dots"
            />
            {/* two columns grid */}
            <article
              className={`${styles.s_about__grid} ${styles.maxWidth1200}`}
            >
              {/* left column */}
              <div className={styles.s_about__left}>
                <h3 className={`h2`}>{resources.landingPage.about}</h3>
                <p className={`h5__text`}>{resources.landingPage.aboutText}</p>
                {/* dots with absolute position - relative to s_about__left, visible on desktop */}
                <img
                  className={styles.s_about__left__squareDots}
                  src={squareDots}
                  alt="green dots"
                />
                <img
                  className={styles.s_about__left__rectangleDots}
                  src={rectangleDots}
                  alt="green dots"
                />
              </div>
              {/* right column */}
              <div className={styles.s_about__right}>
                <AboutItem
                  img={regularPayouts}
                  title={resources.landingPage.regularPayouts}
                  text={resources.landingPage.regularPayoutsText}
                />
                <AboutItem
                  img={highPayouts}
                  title={resources.landingPage.highPayouts}
                  text={resources.landingPage.highPayoutsText}
                />
                <AboutItem
                  img={ownCallCenter}
                  title={resources.landingPage.ownCallCenter}
                  text={resources.landingPage.ownCallCenterText}
                />
                <AboutItem
                  img={personalManager}
                  title={resources.landingPage.personalManager}
                  text={resources.landingPage.personalManagerText}
                />
              </div>
            </article>
          </section>
          {/* end about */}
          {/* top offers */}
          <section className={styles.s_topOffers} id="topOffers">
            <h3 className={`h2`}>{resources.landingPage.topOffers}</h3>
            <div className={`${styles.sliderContainer} customSlick--landing`}>
              {/* dots with absolute position - relative to sliderContainer */}
              <img
                className={styles.sliderContainer__squareDots}
                src={squareDotsBlue}
                alt="square dots"
              />
              <img
                className={styles.sliderContainer__rectangleDots}
                src={rectangleDotsBlue}
                alt="rectangle dots"
              />
              {this.state.topOffers.length > 0 ? (
                <Slider {...this.sliderSettings()}>
                  {this.state.topOffers.map((offer, index) => {
                    return (
                      <div key={index} className={styles.sliderItem}>
                        <ProductCard
                          product={offer}
                          countries={this.state.countries}
                          src_list={this.state.src_list}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <div className={styles.noOffers}>
                  <p className={`h4__bold`}>
                    {resources.landingPage.weDontHaveTopOffers}
                  </p>
                </div>
              )}
            </div>
          </section>
          {/* end top offers */}
          {/* contact */}
          <section className={styles.s_contact} id="contact">
            <article
              className={`${styles.s_contact__grid} ${styles.maxWidth1200}`}
            >
              <div className={styles.s_contact__grid__left}>
                <img src={greenWave} alt="green wave" />
              </div>
              <div className={styles.s_contact__grid__right}>
                <h3 className={`h2`}>{resources.landingPage.contact}</h3>
                <p className={`h5`}>{resources.landingPage.howCanWeHelpYou}</p>
                <p className={`h5`}>{resources.landingPage.writeUs}</p>
                <LandingForm openModal={this.toggleModal} />
              </div>
            </article>
          </section>
          {/* end contact */}
          {/* become a partner */}
          <section className={styles.s_becomeAPartner}>
            <h3 className={`h3`}>{resources.landingPage.becomeOurPartner}</h3>
            <BecomeAPartnerBtn />
          </section>
          {/* end become a partner */}
        </main>
        {/* footer - html tags inside */}
        <LandingFooter />
        {/* end footer */}
        <PopUpModal visible={this.state.visible} onCancel={this.toggleModal}>
          <h6>{resources.landingPage.thankYou}</h6>
          <p>{resources.landingPage.youWillBeHearing}</p>
        </PopUpModal>
      </div>
    );
  }

  toggleModal = (): void => this.setState({ visible: !this.state.visible });

  getTopOffers() {
    getProductTopOffers().then((response) => {
      if (response) {
        let products: any[] = [];
        const topOffers = response.slice(0, 9).map((item: any) => {
          products.push(item.id);
          return item;
        });

        this._isMounted && this.setState({ topOffers });

        products.length && this.getProductImg(products);
      }
    });
  }

  getProductImg = (products: number[]) => {
    const object: IProductImageFilters = {
      is_main: true,
      id_product: products,
    };

    getProductImages("all", object).then((src_list) => {
      src_list !== null && this._isMounted && this.setState({ src_list });
    });
  };

  getCountries = () => {
    fetchDictNoAuth({
      name: "li_countries",
      column: "id,name,is_active,currency",
    }).then((res) => {
      const countries = res.filter((country: any) => country.is_active === 1);
      this.setState({
        countries,
      });
    });
  };

  generateArrayOf3 = (givenArray: any[]) => {
    const length = givenArray.length;

    if (length < 3) {
      for (let i = givenArray.length + 1; i < 4; i++) {
        givenArray.push(givenArray[i - length - 1]);
      }
      return givenArray;
    } else {
      return givenArray;
    }
  };

  sliderSettings = () => {
    // slider settings
    const sliderSettings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow:
        this.state.topOffers.length < 4 ? this.state.topOffers.length : 3,
      speed: 500,
      swipeToSlide: true,
      dots: true,
      nextArrow: <LandingSliderArrow dir={"next"} />,
      prevArrow: <LandingSliderArrow dir={"prev"} />,
      responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return sliderSettings;
  };
}
