import React from "react";
import styles from "./main-statistic-item.module.scss";

type Props = {
  icon: string;
  title: string;
  value: string;
};

export const MainStatisticItem = ({ icon, title, value }: Props) => (
  <div className={styles.item}>
    <img
      alt="statistic-item-icon"
      src={icon}
      style={{ marginRight: "10px" }}
      className={styles.icon}
    />

    <div className={styles.statistic}>
      <p className={styles["statistic__title"]}>{title}</p>
      <p className={styles["statistic__value"]}>{value}</p>
    </div>
  </div>
);
