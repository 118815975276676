import * as React from 'react';
import styles from './styles/LandingForm.module.scss';
import { ILandingForm } from './interfaces/ILandingForm';
import { IOpenModal } from '../../../resetPassword/forms/interfaces/IOpenModal';
import { openErrorNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';
import { sendSupportMessage } from '../../../../services/m62_support/SupportService';

export class LandingForm extends React.Component<IOpenModal, ILandingForm> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    handleChange = (event: any): void => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event: any): void => {
        event.preventDefault();

        const { name, email, object, message } = this.state;

        const obj = {
            name: name,
            email: email,
            subject: object,
            message: message
        };

        sendSupportMessage(obj)
            .then(response => {
                if (response) {
                    this.setState({ name: '', email: '', object: '', message: '' });
                    this.props.openModal();
                }
                else {
                    openErrorNotification('Connection error. Please try again late.');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        return (
            <form className={styles.contactForm} onSubmit={this.handleSubmit}>
                <input type="hidden" value="something" />
                <div className={styles.formItem}>
                    <label
                        className={`h5__semi ${styles.formItem__label}`}
                        htmlFor="name">
                        {resources.landingPage.name}
                    </label>
                    <input
                        name="name"
                        id="name"
                        value={this.state.name || ''}
                        onChange={this.handleChange}
                        className={styles.formItem__input}
                        type="text"
                        autoComplete="off"
                        required />
                </div>
                <div className={styles.formItem}>
                    <label
                        className={`h5__semi ${styles.formItem__label}`}
                        htmlFor="email">
                        {resources.landingPage.email}
                    </label>
                    <input
                        name="email"
                        id="email"
                        value={this.state.email || ''}
                        onChange={this.handleChange}
                        className={styles.formItem__input}
                        type="email"
                        autoComplete="off"
                        required />
                </div>
                <div className={styles.formItem}>
                    <label
                        className={`h5__semi ${styles.formItem__label}`}
                        htmlFor="object">
                        {resources.landingPage.object}
                    </label>
                    <input
                        name="object"
                        id="object"
                        value={this.state.object || ''}
                        onChange={this.handleChange}
                        className={styles.formItem__input}
                        type="text"
                        autoComplete="off"
                        required />
                </div>
                <div className={styles.formItem}>
                    <label
                        className={`h5__semi ${styles.formItem__label}`}
                        htmlFor="message">
                        {resources.landingPage.message}
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        value={this.state.message || ''}
                        onChange={this.handleChange}
                        className={styles.formItem__textarea}
                        autoComplete="off"
                        required />
                </div>
                <button
                    className={`h4__bold ${styles.formItem__submit}`}
                    type="submit">
                    {resources.landingPage.send}
                </button>
            </form>
        );
    }
}