import React from "react";
import SelectOnChange from "../../../../../components/forms/select/SelectOnChange";
import { ClearBoth } from "../../../../../components/controls/clearBoth/ClearBoth";
import { FoundRowsNumber } from "../../../../../components/common/fountRowsNumber/FountRowsNumber";
import { getDictionary } from "../../../../../helpers/DisctionariesHelper";
import { getPayouts } from "../../../../../services/payouts/PayoutsServices";
import { getPayoutScheme } from "../../../../../common/dictionaries/FakePayoutScheme";
import { getProducts } from "../../../../../services/m30_products/ProductsService";
import { Icon, Input } from "antd";
import { IDictionaryValue } from "../../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IId } from "../../../../../common/interfaces/IId";
import { IProductProxies } from "../interfaces/IProductProxies";
import { PayoutAccessTable } from "../table/PayoutAccessTable";
import { RefreshTableButton } from "../../../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../../../common/Resources";
import { Right } from "../../../../../components/controls/right/Right";

const obj = { id: 0, name: "All" };

const visibilityD = [
  { id: 2, name: "Default" },
  { id: 1, name: "Visible" },
  { id: 0, name: "Hidden" },
];

export class PayoutAccessContent extends React.Component<IId> {
  _isMounted = false;
  state = {
    loading: false,
    productsProxiesList: [],
    payoutSchemaD: [],
    countriesNameD: [],
    productsD: [],
    country_id: 0,
    is_visible: 1,
    search: "",
  };

  componentDidMount = async () => {
    this._isMounted = true;

    this.setState({
      loading: true,
      payoutSchemaD: getPayoutScheme(),
    });

    window.scrollTo(0, 0);
    await this.getProductsD();
    this.getCountriesD();
    this.getProductsProxies();
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    let productsProxiesList: IProductProxies[] = this.state.productsProxiesList;

    if (this.state.country_id !== 0 && productsProxiesList.length > 0)
      productsProxiesList = productsProxiesList.filter(
        (x) => x.country_id === this.state.country_id
      );
    if (this.state.is_visible || this.state.is_visible === 0)
      productsProxiesList = productsProxiesList.filter(
        (x) => x.is_visible === this.state.is_visible
      );

    if (this.state.search.length > 0)
      productsProxiesList = this.filterProductsName(productsProxiesList);

    const { length: count } = productsProxiesList ? productsProxiesList : [];

    return (
      <>
        <div style={{ textAlign: "justify" }}>
          <RefreshTableButton
            className="marginLeft"
            loading={this.state.loading}
            onClick={this.getProductsProxies}
          />
          <SelectOnChange
            onChange={this.onChangeSelect}
            name="country_id"
            value={Number(this.state.country_id)}
            label={resources.labels.country}
            items={this.state.countriesNameD}
            disabled={this.state.loading}
            width={200}
            labelClassName="marginLeft marginRigth"
            allowClear
          />
          <SelectOnChange
            onChange={this.onChangeSelect}
            name="is_visible"
            value={this.state.is_visible}
            label={resources.labels.visibility}
            items={visibilityD}
            disabled={this.state.loading}
            width={200}
            labelClassName="marginLeft marginRigth"
            allowClear
          />
          <Right>
            <Input
              className="globalSearch marginBottom marginRigth"
              placeholder={resources.placeholders.findProduct}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              name="search"
              onChange={this.onGlobalSearch}
              disabled={this.state.loading}
              allowClear
            />
          </Right>
          <Right>
            <FoundRowsNumber className="marginRigth" count={count} />
          </Right>
          <ClearBoth />
        </div>
        <div style={{ margin: "0px 10px 10px 10px" }}>
          <PayoutAccessTable
            data={productsProxiesList}
            loading={this.state.loading}
            countriesNameD={this.state.countriesNameD}
            payoutSchemaD={this.state.payoutSchemaD}
            user_id={this.props.id}
          />
        </div>
      </>
    );
  }

  getProductsProxies = async () => {
    this.setState({ loading: true });

    this.props.id &&
      (await getPayouts(Number(this.props.id))
        .then((response) => {
          const productsD = this.state.productsD;
          const productsProxiesList = response.map((item: any) => {
            const productDetails: any = productsD.find(
              (x: any) => x.id === item.product_id
            );

            if (productDetails)
              return {
                ...item,
                product_sku: productDetails.product_sku,
                name: productDetails.name,
                country_id: productDetails.country_id,
                key: item.product_id,
              };
            else return null;
          });

          this.setState(
            {
              productsProxiesList: productsProxiesList.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              ),
            },
            () => this.updateProductsProxiesData()
          );
        })
        .finally(() => this.setState({ loading: false })));
  };

  getProductsD = () => {
    const object = {
      columns: ["name", "country_id", "id", "product_sku"],
    };

    getProducts(object)
      .then((productsD) => this._isMounted && this.setState({ productsD }))
      .finally(
        () => this._isMounted && this.setState({ loading_top_offers: false })
      );
  };

  getCountriesD = async () => {
    let countriesNameD: IDictionaryValue[] = await getDictionary(
      "countriesNameD"
    );

    countriesNameD = [obj, ...countriesNameD];

    this.setState({ countriesNameD });
  };

  updateProductsProxiesData = () => {
    const productsProxiesList: IProductProxies[] =
      this.state.productsProxiesList;
    const productsD: IDictionaryValue[] = this.state.productsD;

    if (productsD && productsD.length > 0) {
      let count = 1;
      let result = productsProxiesList.map((item) => {
        const obj = productsD
          ? productsD.find((x) => x.id === item.product_id)
          : undefined;

        if (obj)
          return (item = {
            ...item,
            country_id: Number(obj.country_id),
            index: count++,
          });

        return null;
      });

      this.setState({ productsProxiesList: result });
    }
  };

  onGlobalSearch = (event: any) => {
    const value = event.target.value.toLowerCase();

    this.setState({ search: value });
  };

  filterProductsName = (
    productsProxiesList: IProductProxies[]
  ): IProductProxies[] => {
    let products: IProductProxies[] = [];

    this.findProductsNameInDictionary().map((item) => {
      let element = productsProxiesList.filter((x) => x.product_id === item.id);

      if (element) products = [...products, ...element];

      return null;
    });

    return products;
  };

  findProductsNameInDictionary = () => {
    const productsD: any[] = this.state.productsD;
    const search: string = this.state.search;

    return productsD.filter(
      (x) =>
        x.name.toLowerCase().includes(search) ||
        x.product_sku.toLowerCase().includes(search) ||
        x.id.toString().includes(search)
    );
  };

  onChangeSelect = (name: string, value: number): void => {
    if (name === "is_visible") this.setState({ [name]: value });
    else
      value === undefined
        ? this.setState({ [name]: 0 })
        : this.setState({ [name]: value });
  };

  updateObjInArray = (obj: any): void => {
    const productsProxiesList: IProductProxies[] = [
      ...this.state.productsProxiesList,
    ];

    const index = productsProxiesList.findIndex(
      (x) => Number(x.product_id) === obj.product_id
    );

    productsProxiesList[index].is_visible = Number(obj.is_visible);
    productsProxiesList[index].payout_cpa = obj.payout_cpa;
    productsProxiesList[index].payout_cpl = obj.payout_cpl;
    productsProxiesList[index].payout_scheme = obj.payout_scheme;
    productsProxiesList[index].started_at = obj.started_at;
    productsProxiesList[index].timezone = obj.timezone;

    this.setState({ productsProxiesList });
  };
}
