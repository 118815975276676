import * as React from "react";
import styles from "./styles/DashboardContent.module.scss";
import { createAbsoluteUrl } from "../../../helpers/UrlCreator";
import { getActiveNews } from "../../../services/m61_news/NewsService";
import { getProductImages } from "../../../services/m30_products/images/ProductImagesService";
import { getProducts } from "../../../services/m30_products/ProductsService";
import { IProductImageFilters } from "../../../services/m30_products/images/interfaces/IProductImageFilters";
import { Link } from "react-router-dom";
import { NewsListDashboard } from "../../_common/components/newsList/NewsListDashboard";
import { resources } from "../../../common/Resources";
import { Spin } from "antd";
import { TopOffers } from "../components/TopOffers";
import { ViewHeader } from "../../../components/viewHeader/ViewHeader";
import { ViewSubHeader } from "../../../components/viewSubheader/ViewSubHeader";

export class DashboardContent extends React.Component {
  _isMounted = false;

  state = {
    top_offers: [],
    news_list: [],
    src_list: [],
    loading_news: false,
    loading_top_offers: false,
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.getTopOffers();
    this.getProductImg();
    this.getLatestNews();
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    return (
      <>
        <ViewHeader title={resources.pageTitle.dashboard} />

        {/* Hided in SOL-284 */}
        {/* <div>
          <DashboardSummary />
        </div> */}

        <div className={styles.topOffersGrid}>
          <ViewSubHeader title={resources.labels.top_offers} />
          {this.state.loading_top_offers ? (
            <Spin tip={resources.spin.loading} style={{ margin: "0px 50%" }} />
          ) : (
            <TopOffers
              topOffers={this.state.top_offers}
              src_list={this.state.src_list}
            />
          )}
        </div>
        <div className={styles.newsGrid}>
          <ViewSubHeader title={resources.labels.latest_news} />
          {this.state.loading_news ? (
            <Spin tip={resources.spin.loading} style={{ margin: "0px 50%" }} />
          ) : (
            <>
              <NewsListDashboard data={this.state.news_list} />
              <Link
                className={styles.button__allNews}
                to={createAbsoluteUrl("news")}
              >
                {resources.links.all_news}
              </Link>
            </>
          )}
        </div>
      </>
    );
  }

  getTopOffers = () => {
    this.setState({ loading_top_offers: true });

    const object = {
      columns: [
        "id",
        "name",
        "default_landing_price",
        "default_payout_schema",
        "default_payout",
        "country_name",
        "approve_page",
        "country_id",
        "categories",
      ],
      is_visible_home: true,
      is_visible_affbay: 1,
      is_visible_partners: 1,
    };

    getProducts(object)
      .then((top_offers) => {
        this._isMounted && this.setState({ top_offers });
      })
      .finally(
        () => this._isMounted && this.setState({ loading_top_offers: false })
      );
  };

  getProductImg = () => {
    const object: IProductImageFilters = {
      is_main: true,
    };

    getProductImages("all", object).then((src_list) => {
      src_list !== null && this._isMounted && this.setState({ src_list });
    });
  };

  getLatestNews = () => {
    this.setState({ loading_news: true });

    const object = {
      is_home_page: true,
      columns: [
        "date_from",
        "title_en",
        "title_ru",
        "id_news_type",
        "body_en",
        "body_ru",
      ],
    };

    getActiveNews(object)
      .then((news_list) => {
        this._isMounted && this.setState({ news_list });
      })
      .finally(() => this._isMounted && this.setState({ loading_news: false }));
  };
}
