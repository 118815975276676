import axios from "axios";
import { resourceDef } from "../../common/ResourceDef";
import { createGatewayAbsoluteUrl } from "../../helpers/UrlCreator";
import { displayHttpStatusCodeInformation } from "../../helpers/HttpStatusCodeHelper";

const remarketingSettingsUrl = createGatewayAbsoluteUrl(
  resourceDef.RemarketingSettings
);

export const getRemarketingSettings = (country: any, archived: any) => {
  const params = new URLSearchParams({
    ...(country && { country }),
    is_archived: archived,
  });
  return axios
    .get(remarketingSettingsUrl, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      displayHttpStatusCodeInformation(error, "getRemarketingSettings M121");
      return undefined;
    });
};

export const createRemarketingSetting = (obj: any) =>
  axios
    .post(remarketingSettingsUrl, obj)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("createRemarketingService M29");
      throw error.response.data;
    });

export const checkRemarketingSetting = (obj: any) =>
  axios
    .post(`${remarketingSettingsUrl}/check`, obj)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("checkRemarketingService M29");
      throw error.response.data;
    });

export const archiveRemarketingSetting = (id: any) =>
  axios
    .put(`${remarketingSettingsUrl}${id}/archive`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("archiveRemarketingService M29");
      throw error.response.data;
    });

export const editRemarketingSetting = (remarketing: any) =>
  axios
    .put(`${remarketingSettingsUrl}${remarketing.id}`, remarketing)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("editRemarketingService M29");
      throw error.response.data;
    });
