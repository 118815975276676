import * as React from 'react';
import AppUserForm from '../forms/AppUserForm';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import ProtectedModal from '../../../../components/authorizations/protectedComponents/ProtectedModal';
import SelectOnChange from '../../../../components/forms/select/SelectOnChange';
import {
  deleteUser,
  getManagersD,
  getUsersList,
  updateUser
} from '../../../../services/m29_users_management/GeneralUserService';
import { FieldInfo } from '../../../../components/common/fieldInfo/FieldInfo';
import { getCompaniesListD } from '../../../../services/m29_users_management/CompaniesService';
import { getRolesListD } from '../../../../services/m29_users_management/RoleService';
import { IAppUserFormProps } from '../forms/interfaces/IAppUserFormProps';
import { IAppUsers } from '../interfaces/IAppUsers';
import { IDictionaryValue } from '../../../../components/templateForm/types/dictionaries/IDictionaryValue';
import { openErrorNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { PageTitle } from '../../../../components/common/pageTitle/PageTitle';
import { RefreshTableButton } from '../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../common/Resources';
import { TableContainer } from '../../../../components/containers/tableContainer/TableContainer';
import { UserPermissionType } from '../../../../models/IAppUserInfoModel';
import { UsersTable } from '../table/UsersTable';

export class UsersContent extends React.Component {
    _isMounted = false;

    state = {
      appUsersList: Array<IAppUsers>(),
      rolesD: Array<IDictionaryValue>(),
      companiesD: Array<IDictionaryValue>(),
      accountManagersD: Array<IDictionaryValue>(),
      selected_role: undefined,
      selected_show: 1,
      loading: false
    }

    componentDidMount = () => {
      this._isMounted = true;
      this.geDictionaries();
      this.getAppUsersList();
    }

    componentWillUnmount = () =>
      this._isMounted = false;

    public render() {
      let data = this.state.appUsersList;

      if (this.state.appUsersList.length > 0) {
        if (this.state.selected_role) {
          data = data.filter(x => x.id_users_role === this.state.selected_role);
        }
        if (this.state.selected_show) {
          data = data.filter(x => x.is_activated as unknown === this.state.selected_show);
        }
      }

      return (
        <TableContainer>
          <PageTitle>{resources.pageTitle.administrationUsers}</PageTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <RefreshTableButton
                className="marginTopRefreshButton23px marginBottom"
                loading={this.state.loading}
                onClick={this.getAppUsersList}
              />
              <div>
                <FieldInfo>{resources.labels.role}</FieldInfo>
                <SelectOnChange
                  className="marginBottom"
                  onChange={this.onChangeSelect}
                  name='selected_role'
                  value={this.state.selected_role}
                  items={this.state.rolesD}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  placeholder={resources.select.select}
                  width={200}
                  allowClear
                />
              </div>
              <div>
                <FieldInfo>Show</FieldInfo>
                <SelectOnChange
                  className="marginBottom"
                  onChange={this.onChangeSelect}
                  name='selected_show'
                  value={this.state.selected_show}
                  items={[{ name: 'Hide deleted', id: 1 }, { name: 'All', id: 0 }]}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  placeholder={resources.select.select}
                  width={200}
                  allowClear
                />
              </div>
            </div>

            <ProtectedModal permission={UserPermissionType.AddNewUserAdministrationModalButton}>
              <InformationModalDialog
                modalTitle={resources.modalTitle.addNewUser}
                buttonTitle={resources.modalButtonsTitle.addNewUser}
                buttonType='primary'
                buttonTooltip={resources.modalTooltips.addNewUser}
                icon={"user-add"}
                withHideMethod={true}
                width={800}
                buttonClassName='addNew marginBottom'
                disabled={this.state.loading}
              >
                <AppUserForm
                  rolesD={this.state.rolesD}
                  companiesD={this.state.companiesD}
                  accountManagersD={this.state.accountManagersD}
                  addObjToArray={this.addObjToArray}
                />
              </InformationModalDialog>
            </ProtectedModal>
          </div>
          <UsersTable
            data={data}
            loading={this.state.loading}
            rolesD={this.state.rolesD}
            companiesD={this.state.companiesD}
            accountManagersD={this.state.accountManagersD}
            changeUserStatus={this.changeUserStatus}
            updateObjInArray={this.updateObjInArray}
            deleteUser={this.deleteUser}
          />
        </TableContainer>
      )
    };

    getAppUsersList = () => {
      this.setState({ loading: true });

      const object = {
        columns: [
          'id',
          'name',
          'second_name',
          'short_name',
          'id_users_role',
          'is_activated',
          'created_at',
          'updated_at',
          'deleted_at',
          'email',
          'id_account_manager',
          'id_company',
          'telegram',
          'id_country',
          'skype',
          'description',
          'partner_token'
        ]
      };

      getUsersList(object)
        .then((response) => {
          if (response) {
            const appUsersList = response.map((object: any) => { return { ...object, key: object.id } });

            this._isMounted && this.setState({ appUsersList });
          }
        })
        .finally(() => { this._isMounted && this.setState({ loading: false }) });
    }

    geDictionaries = async () => {
      const rolesD = await getRolesListD({ is_active: true });
      const companiesD = await getCompaniesListD();
      const accountManagersD = await getManagersD();

      this._isMounted && this.setState({
        rolesD,
        companiesD,
        accountManagersD
      });
    }

    changeUserStatus = async (id: number, is_activated: boolean) => {
      const object = {
        is_activated: !is_activated
      };

      await updateUser(id, object)
        .then(() => {
          this.changeStatus(id);

          openSuccessNotification(resources.statement.status_updated);
        })
        .catch(() => {
          openErrorNotification(resources.statement.databaseConnectionError);
        });
    }

    deleteUser = async (id: number) => {
      await deleteUser(id)
        .then((response) => {
          if (response) {
            this.markDeleteUserInArray(id);
          }
          else {
            openErrorNotification(resources.statement.there_was_a_problem_removing_app_user);
          }
        })
        .catch(() => {
          openErrorNotification(resources.statement.databaseConnectionError);
        });
    }

    markDeleteUserInArray = (id: number) => {
      const { appUsersList } = this.state;
      const index = appUsersList.findIndex(x => x.id === id);

      appUsersList[index].updated_at = new Date();
      appUsersList[index].deleted_at = new Date();
      appUsersList[index].is_activated = false;

      this.setState({ appUsersList });

      openSuccessNotification(resources.statement.app_user_deleted);
    }

    changeStatus = (id: number) => {
      const { appUsersList } = this.state;
      const index = appUsersList.findIndex(x => x.id === id);

      appUsersList[index].is_activated = !appUsersList[index].is_activated;

      this.setState({ appUsersList });
    }

    changeRole = (id: number, obj: IAppUserFormProps) => {
      const { appUsersList } = this.state;
      const index = appUsersList.findIndex(x => x.id === id);

      appUsersList[index].id_users_role = obj.id_users_role!;

      this.setState({ appUsersList });
    }

    addObjToArray = (appUser: IAppUserFormProps) => {
      this.setState({ appUsersList: [...this.state.appUsersList, appUser] });
    }

    updateObjInArray = (appUser: IAppUserFormProps): void => {
      const { appUsersList } = this.state;
      const index = appUsersList.findIndex(x => x.id === Number(appUser.id));

      appUsersList[index].name = appUser.name!;
      appUsersList[index].second_name = appUser.second_name;
      appUsersList[index].short_name = appUser.short_name;
      appUsersList[index].email = appUser.email;
      appUsersList[index].id_account_manager = appUser.id_account_manager!;
      appUsersList[index].id_company = appUser.id_company!;
      appUsersList[index].skype = appUser.skype;
      appUsersList[index].telegram = appUser.telegram;
      appUsersList[index].id_country = appUser.id_country!;
      appUsersList[index].description = appUser.description;
      appUsersList[index].updated_at = appUser.updated_at;
        appUsersList[index].id_users_role! = appUser.id_users_role!;
        appUsersList[index].is_activated = appUser.is_activated === '0' ? false : true;

        this.setState({ appUsersList });
    }

    onChangeSelect = (name: string, value: number): void => {
      this.setState({ [name]: value });
    }
}
