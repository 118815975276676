import axios from "axios";

import { resourceDef } from "../common/ResourceDef";
import { createGatewayAbsoluteUrl } from "../helpers/UrlCreator";

const verificationToolUrl = createGatewayAbsoluteUrl(
  resourceDef.VerificationTool
);

export const verifyPhone = (data: { phone: string; countryCode: string }) =>
  axios.post(verificationToolUrl, data);
