import * as React from 'react';
import { CampaignsPartnerContent } from './content/CampaignsPartnerContent';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ISetActiveMenuItem } from '../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../common/ResourceDef';
import { setActiveMenuItem } from '../../reducers/CommonReducer';

class CampaignsPartnerViewInner extends React.Component<ISetActiveMenuItem>  {
    componentDidMount = () => 
        this.props.setActiveMenuItem(navPath.campaigns_partner);

    render() {
        return (<CampaignsPartnerContent />)
    }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const CampaignsPartnerView: React.ComponentClass = connect(null, mapDispatchToProps)(CampaignsPartnerViewInner);