import React, { Component } from 'react';
import styles from './FormSectionHeader.module.scss';

export class FormSectionHeader extends Component {
    render() {
        return (
            <h4 className={`h4__bold ${styles.header}`}>{this.props.children}</h4>
        )
    }
}
