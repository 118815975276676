import React from 'react';
import styles from './styles/StatusesDictionaryForm.module.scss';
import TextInputF from '../../../../components/templateForm/form/textInput/TextInputF';
import { Button } from 'antd';
import { createStatusesDictionary, updateStatusesDictionary } from '../../../../services/dictionaries/StatusesDivtionaryService';
import {
    Form,
    FormikBag,
    FormikErrors,
    FormikProps,
    withFormik
    } from 'formik';
import { IAddObjToArray } from '../../../../common/interfaces/IAddObjToArray';
import { IHideModal } from '../../../../common/interfaces/IHideModal';
import { IId } from '../../../../common/interfaces/IId';
import { IStatus } from '../interfaces/IStatus';
import { IUpdateObjInArray } from '../../../../common/interfaces/IUpdateObjInArray';
import { openAlertNotification, openSuccessNotification } from '../../../../helpers/NotificationHelper';
import { resources } from '../../../../common/Resources';

class TemplateStatusesDictionaryForm extends React.Component<IStatus & IHideModal & IUpdateObjInArray & IAddObjToArray & IId & FormikProps<IStatus>> {
    public render() {
        return (
            <Form>
                {this.props.name && <div className={styles.companieName}>{this.props.name}</div>}
                <TextInputF
                    {...this.props}
                    name="name"
                    label={resources.labels.name}
                    labelTextAlignLeft
                    allowClear
                    boldLabel
                    required
                />
                <Button
                    className='saveButton'
                    htmlType="submit"
                    type="primary"
                >
                    {this.props.id ? resources.buttons.save : resources.buttons.create}
                </Button>
            </Form >
        );
    };
}

const StatusesDictionaryForm = withFormik<IStatus & IUpdateObjInArray & IAddObjToArray & IId, IStatus>({
    mapPropsToValues: (props: IStatus) => {
        return {
            name: props.name
        };
    },

    validate: (values: IStatus) => {
        const errors: FormikErrors<IStatus> = {};

        if (!values.name) {
            errors.name = resources.validation.nameIsRequired;
        }
        else if (values.name.length > 255) {
            errors.name = resources.validation.requiredMax255Characters;
        }

        return errors;
    },

    handleSubmit: (values: IStatus, bag: FormikBag<IStatus & IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId, IStatus>) => {
        const object: IStatus = {
            name: values.name
        }

        // Sprawdzenie, czy te same vartości
        if (
            bag.props.id
            && values.name === bag.props.name
        ) {
            openAlertNotification(resources.statement.thePropsAreTheSame);
        }
        else {
            createQuery(object, bag.props);
        }
    }

})(TemplateStatusesDictionaryForm);

export default StatusesDictionaryForm;

const createQuery = async (obj: IStatus, bag: IUpdateObjInArray & IAddObjToArray & IId & IHideModal & IId) => {
    const createQuery =
        `INSERT INTO li_statuses
            (
                name
            )
        VALUES
            (
                "${obj.name}"
            )`;

    const updateQuery =
        `UPDATE li_statuses SET
                name = "${obj.name}"
         WHERE id = ${bag.id}`;

    if (bag.id) {
        await updateStatusesDictionary(updateQuery)
            .then(() => {
                obj.id = Number(bag.id!);
                obj.updated_at = new Date();

                bag.updateObjInArray!(obj);
                openSuccessNotification(resources.statement.status_updated);
            });
    }
    else {
        await createStatusesDictionary(createQuery)
            .then((response) => {
                obj.id = response;
                obj.key = response;
                obj.created_at = new Date();
                obj.updated_at = new Date();

                bag.addObjToArray!(obj);
                openSuccessNotification(resources.statement.status_created);
            });
    }

    bag.hideModal!();
}