export const generateRandomStringUniversal = (length: number): string => {

  let result1: string[] = [];
  let result2: string[] = [];

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*(){}|":?><';
  const charactersLength = characters.length;

  const rules = [
    ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 1],
    ['abcdefghijklmnopqrstuvwxyz', 1],
    ['!@#$%^&*', 2],
    ['0123456789', 1]
  ];

  rules.forEach((rule) => {
    for (let i = 0; i < rule[1]; i++) {
      const rule_str = rule[0].toString();
      const rule_length = rule_str.length;
      const res = rule_str.charAt(Math.floor(Math.random() * rule_length));
      result1.push(res);
    }
  });

  for (var i = 0; i < length - result1.length; i++)
    result2.push(characters.charAt(Math.floor(Math.random() * charactersLength)));

  while (result1.length > 0) {
    let el: any = "";

    if (Math.round(Math.random())) {
      el = result1.pop();
    } else {
      el = result1.shift();
    }

    if (el) { result2.push(el) };
  }

  return result2.join('');
};