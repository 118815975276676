import * as React from "react";
import { DataTable } from "../../../components/others/data/DataTable";
import { resources } from "../../../common/Resources";
import moment from "moment";
import { spanWithTitle } from "../../../components/others/SpanWithTitle";
import { convertNumberToDateTimeString } from "../../../helpers/DateHelpers";
import { Table, Button, Tooltip, Typography, Modal } from "antd";
import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import PartnersForm from "../forms/PartnersForm";
import { findInDictionaryAndReturnValueWithSpin } from "../../../helpers/DisctionariesHelper";
import ProtectedModal from "../../../components/authorizations/protectedComponents/ProtectedModal";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import { Link } from "react-router-dom";
import { switchToDifferentUser } from "../../../helpers/Authentication";

const { Paragraph } = Typography;
const { Column } = Table;
const { confirm } = Modal;

export class PartnersTable extends DataTable {
  showRejectConfirm = (id_user, name) => {
    confirm({
      title: "Are you sure?",
      content: `You want to reject ${name}? `,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: () => {
        this.props.rejectPartner(id_user);
      },
      onCancel() {},
    });
  };

  showAcceptConfirm = (id_user, name) => {
    confirm({
      title: "Are you sure?",
      content: `You want to accept ${name}? `,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: () => {
        this.props.acceptPartner(id_user);
      },
      onCancel() {},
    });
  };

  render() {
    const columns = [
      <Column
        title={resources.columnTitle.email}
        key="email"
        align="center"
        width="200px"
        sorter={(a, b) =>
          (a.email ? a.email : "").localeCompare(b.email ? b.email : "")
        }
        {...this.getColumnSearchProps("email")}
        render={(record) => (
          <Tooltip title={record.email}>
            {record.email !== null && record.email.length > 22
              ? record.email.substring(0, 18) + "..."
              : record.email}
          </Tooltip>
        )}
      />,
      <Column
        title={resources.columnTitle.name}
        dataIndex="name"
        key="name"
        align="center"
        width="200px"
        sorter={(a, b) =>
          (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
        }
        {...this.getColumnSearchProps("name")}
      />,
      <Column
        title={resources.columnTitle.second_name}
        dataIndex="second_name"
        key="second_name"
        align="center"
        width="150px"
        sorter={(a, b) =>
          (a.second_name ? a.second_name : "").localeCompare(
            b.second_name ? b.second_name : ""
          )
        }
        {...this.getColumnSearchProps("second_name")}
      />,
      <Column
        title={resources.columnTitle.partnerToken}
        key="partner_token"
        align="center"
        width="200px"
        sorter={(a, b) =>
          (a.partner_token ? a.partner_token : "").localeCompare(
            b.partner_token ? b.partner_token : ""
          )
        }
        {...this.getColumnSearchProps("partner_token")}
        render={(record) =>
          record.partner_token ? (
            <Tooltip
              title={
                <Paragraph
                  style={{ color: "white", textAlign: "center" }}
                  copyable
                >
                  {record.partner_token}
                </Paragraph>
              }
            >
              <label className="marginLeft">
                {record.partner_token !== null &&
                record.partner_token.length > 24
                  ? record.partner_token.substring(0, 20) + "..."
                  : record.partner_token}
              </label>
            </Tooltip>
          ) : (
            "-"
          )
        }
      />,
      <Column
        title={resources.columnTitle.manager}
        key="id_account_manager"
        align="center"
        width="200px"
        sorter={(a, b) => a.id_account_manager - b.id_account_manager}
        render={(record) =>
          record.id_account_manager
            ? findInDictionaryAndReturnValueWithSpin(
                record.id_account_manager,
                this.props.managers
              )
            : "-"
        }
      />,
      <Column
        title={resources.columnTitle.company}
        key="id_company"
        align="center"
        width="150px"
        sorter={(a, b) => a.id_company - b.id_company}
        render={(record) =>
          findInDictionaryAndReturnValueWithSpin(
            record.id_company,
            this.props.companies
          )
        }
      />,
      <Column
        title={resources.columnTitle.skype}
        dataIndex="skype"
        key="skype"
        align="center"
        width="150px"
        sorter={(a, b) =>
          (a.skype ? a.skype : "").localeCompare(b.skype ? b.skype : "")
        }
        {...this.getColumnSearchProps("skype")}
      />,
      <Column
        title={resources.columnTitle.telegram}
        dataIndex="telegram"
        key="telegram"
        align="center"
        width="120px"
        sorter={(a, b) =>
          (a.telegram ? a.telegram : "").localeCompare(
            b.telegram ? b.telegram : ""
          )
        }
        {...this.getColumnSearchProps("telegram")}
      />,
      <Column
        title={resources.columnTitle.country}
        key="id_country"
        align="center"
        width="120px"
        sorter={(a, b) => a.id_country - b.id_country}
        render={(record) =>
          findInDictionaryAndReturnValueWithSpin(
            Number(record.id_country),
            this.props.countries
          )
        }
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.created,
          resources.columnTitle.created_short
        )}
        key="created_at"
        width="160px"
        align="center"
        sorter={(a, b) =>
          moment.utc(a.created_at).diff(moment.utc(b.created_at))
        }
        render={(record) => convertNumberToDateTimeString(record.created_at)}
      />,
      <Column
        title={resources.columnTitle.options}
        key="options"
        width={this.props.width}
        align="center"
        fixed="right"
        render={(record) => (
          <>
            <ProtectedModal
              permission={UserPermissionType.EditPartnerModalButton}
            >
              <InformationModalDialog
                modalTitle={resources.title.editPartner}
                buttonTooltip={resources.title.editPartner}
                icon="edit"
                disabled={this.props.loading}
                withHideMethod={true}
                width={800}
              >
                <PartnersForm
                  id={record.id}
                  rowKey={record.rowKey}
                  name={record.name}
                  email={record.email}
                  second_name={record.second_name}
                  partner_token={record.partner_token}
                  id_account_manager={record.id_account_manager}
                  id_company={record.id_company}
                  skype={record.skype}
                  telegram={record.telegram}
                  id_country={record.id_country}
                  updateObjInArray={this.props.updateObjInArray}
                />
              </InformationModalDialog>
            </ProtectedModal>
            {!record.is_activated && (
              <ProtectedComponent
                permission={UserPermissionType.GrantPartnerButton}
              >
                <Tooltip placement="rightTop" title={resources.tooltips.accept}>
                  <Button
                    value={record.id}
                    onClick={() =>
                      this.showAcceptConfirm(record.id, record.name)
                    }
                    icon={"check"}
                    style={{ color: "green" }}
                  />
                </Tooltip>
              </ProtectedComponent>
            )}
            {!record.is_activated && (
              <ProtectedComponent
                permission={UserPermissionType.DenyPartnerButton}
              >
                <Tooltip placement="rightTop" title={resources.tooltips.reject}>
                  <Button
                    value={record.id}
                    onClick={() =>
                      this.showRejectConfirm(record.id, record.name)
                    }
                    icon={"close"}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </ProtectedComponent>
            )}
            {Boolean(record.is_activated) && (
              <ProtectedComponent
                permission={UserPermissionType.ImpersonateAction}
              >
                <Tooltip
                  placement="rightTop"
                  title={resources.buttons.switchToUser}
                >
                  <Button
                    value={record.id}
                    onClick={async () => {
                      const success = await switchToDifferentUser(record.id);
                      success && window.location.reload();
                    }}
                    icon={"login"}
                  />
                </Tooltip>
              </ProtectedComponent>
            )}
            <ProtectedComponent
              permission={UserPermissionType.EditRoleAdministrationLinkButton}
            >
              <Tooltip placement="rightTop" title={resources.tooltips.editRole}>
                <Link
                  permission={
                    UserPermissionType.EditRoleAdministrationLinkButton
                  }
                  to={"users_information/" + record.id}
                >
                  <Button icon="arrow-right" />
                </Link>
              </Tooltip>
            </ProtectedComponent>
          </>
        )}
      />,
    ];
    return (
      <DataTable
        tableName={this.props.tableName}
        columns={columns}
        data={this.props.data}
        loading={this.props.loading}
        pagination={true}
      />
    );
  }
}
