import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IRouteProps } from './interfaces/IRouteProps';
import { ISetActiveMenuItem } from '../../../common/interfaces/ISetActiveMenuItem';
import { navPath } from '../../../common/ResourceDef';
import { RolePermissionContent } from './contents/RolePermissionContent';
import { RouteComponentProps } from 'react-router';
import { setActiveMenuItem } from '../../../reducers/CommonReducer';

class RolePermissionsViewInner extends React.Component<ISetActiveMenuItem & RouteComponentProps<IRouteProps>> {
    componentDidMount = () => {
        this.props.setActiveMenuItem(navPath.administrationRole);
        this.props.setActiveMenuItem('');
    }

    public render() {
        return (
            <RolePermissionContent name={this.props.match.params.name} id={this.props.match.params.id} />
        )
    };
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (dispatch: Dispatch) => ({
    setActiveMenuItem: (view: string) => {
        dispatch(setActiveMenuItem(view))
    }
})

export const RolePermissionsView: React.ComponentClass<RouteComponentProps<IRouteProps>> = connect(null, mapDispatchToProps)(RolePermissionsViewInner);