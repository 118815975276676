import React from 'react';
import { checkIfTokenIsActive } from '../../helpers/Authentication';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IAppState } from '../../store';
import { IAppUserInfoModel } from '../../models/IAppUserInfoModel';
import { ILogoutAppUser } from '../../common/interfaces/ILogoutAppUser';
import { ISetAppUserInfo } from '../../common/interfaces/ISetAppUserInfo';
import { ISetBlockUi } from '../../common/interfaces/ISetBlockUi';
import { ISetIsAuthenticated } from '../../common/interfaces/ISetIsAuthenticated';
import { ISpinPageProps } from './interfaces/ISpinPageProps';
import { Loading } from '../../components/loading/Loading';
import {
    logoutAppUser,
    setAppUserInfo,
    setBlockUi,
    setIsAuthenticated
    } from '../../reducers/CommonReducer';

class SpinPageInner extends React.Component<ISpinPageProps & ILogoutAppUser & ISetAppUserInfo & ISetBlockUi & ISetIsAuthenticated> {
    public render() {
        this.props.blockUi && checkIfTokenIsActive(
            this.props.logoutAppUser,
            this.props.setAppUserInfo,
            this.props.setBlockUi,
            this.props.setIsAuthenticated
        );

        return (
            <>
                {this.props.blockUi ? <Loading /> : this.props.children}
            </>
        )
    };
}

const mapStateToProps = (state: IAppState): ISpinPageProps => {
    return {
        blockUi: state.commonState.blockUi
    }
}

const mapDispatchToProps: MapDispatchToProps<ILogoutAppUser & ISetAppUserInfo & ISetBlockUi & ISetIsAuthenticated, {}> = (
    dispatch: Dispatch,
) => ({
    logoutAppUser: () => {
        dispatch(logoutAppUser());
    },
    setAppUserInfo: (appUserInfoModel: IAppUserInfoModel) => {
        dispatch(setAppUserInfo(appUserInfoModel));
    },
    setBlockUi: (blockUi: boolean) => {
        dispatch(setBlockUi(blockUi));
    },
    setIsAuthenticated: (isAuthenticated: boolean) => {
        dispatch(setIsAuthenticated(isAuthenticated));
    }
});

const SpinPage = connect(mapStateToProps, mapDispatchToProps)(SpinPageInner);

export default SpinPage;