import * as React from 'react';
import styles from './styles/AboutItem.module.scss';
import { IAboutItem } from './interfaces/IAboutItem';

export class AboutItem extends React.Component<IAboutItem> {
    render() {
        const {img, title, text} = this.props;
        return(
        <div className={styles.container}>
            <img src={img} alt={title}/>
            <div className={styles.texts}>
                <h3 className={styles.h3}>{title}</h3>
                <p className={styles.p}>{text}</p>
            </div>
        </div>
            
        );
    }
}