import * as Yup from 'yup';

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

const validation = {
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    sex: Yup.number()
        .integer()
        .min(1)
        .max(2)
        .required('Required'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Invalid phone number')
        .required('Required'),
    phone2: Yup.string()
        .matches(phoneRegExp, 'Invalid phone number'),
    email: Yup.string()
        .email()
        .required('Required'),
    age: Yup.number().typeError('Must be a number')
        .integer('Must be a number')
        .min(1)
};

export default validation;